import React, { Component } from "react";
import ReactGA from "react-ga";
import ScrollUpButton from "react-scroll-up-button";
import { Container } from "reactstrap";
import { Helmet } from "react-helmet";

import Mainbanner from "./MarketingBanner";
import DigitalCurrency from "./DigitalCurrency";
import Tokens from "./Tokens";
import UtilityTokensSecurityTokens from "./UtilityTokensSecurityTokens";
import CryptocurrencySecurityTokens from "./CryptocurrencySecurityTokens";
import ICOSTO from "./ICOSTO";
import CryptocurrencyExchange from "./CryptocurrencyExchange";
import ValuationSecurityToken from "./ValuationSecurityToken";
import AssetbasedFactors from "./AssetbasedFactors";
import BenefitsSecurityTokens from "./BenefitsSecurityTokens";
import LimitationsSecurityTokens from "./LimitationsSecurityTokens";
import SecurityTokenExchanges from "./SecurityTokenExchanges";
import KeyComponentsSTE from "./KeyComponentsSTE";
import STEEcosystem from "./STEEcosystem";
import STCLDifferentCountries from "./STCLDifferentCountries";
import ListCountries from "./ListCountries";
import SecurityTokenExchangeEcosystem from "./SecurityTokenExchangeEcosystem";
import StartSecurityTokenExchange from "./StartSecurityTokenExchange";
import FutureUsageSTE from "./FutureUsageSTE";
import LimitationsSTEM from "./LimitationsSTEM";
import PotentialAvailableMarket from "./PotentialAvailableMarket";
import TotalServiceableMarket from "./TotalServiceableMarket";
import WorldwideAcceptance from "./WorldwideAcceptance";
import CurrentMarketPerformance from "./CurrentMarketPerformance";
import RelatedRevenueStreams from "./RelatedRevenueStreams";
import KeyPlayersSTEM from "./KeyPlayersSTEM";
import LaunchingSTE from "./LaunchingSTE";
import PartingWords from "./PartingWords";

export default class index extends Component {
  componentDidMount() {
    ReactGA.initialize("UA-125025994-1");
    ReactGA.pageview(document.location.pathname);
    ReactGA.pageview(document.location.path);
  }

  scrollPositions = {};
  componentWillMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {this.props.children}
        <Helmet>
          <title>Espay Exchange</title>
          <meta name="description" content="" />
          <meta property="og:title" content="" />
          <meta property="og:description" content="" />
          <meta name="twitter:title" content="" />
          <meta name="twitter:description" content="" />
          <link
            rel="canonical"
            href="https://www.espay.exchange/hybrid-crypto-exchange-software"
          />
        </Helmet>

        <Container fluid={true} className="MarketingResearchBannermain">
          <Container>
            <Mainbanner />
          </Container>
        </Container>

        <Container>
          <DigitalCurrency />
        </Container>

        <Container fluid={true} className="tokens-bg">
          <Container>
            <Tokens />
          </Container>
        </Container>

        <Container>
          <UtilityTokensSecurityTokens />
        </Container>

        <Container>
          <CryptocurrencySecurityTokens />
        </Container>

        <Container>
          <ICOSTO />
        </Container>

        <Container className="CryptocurrencyExchange-bg">
          <CryptocurrencyExchange />
        </Container>

        <Container className="CryptocurrencyExchange-bg">
          <CryptocurrencyExchange />
        </Container>

        <Container className="Valuationtoken-bg">
          <ValuationSecurityToken />
        </Container>

        <Container className="assetbasedfactors-bg">
          <AssetbasedFactors />
        </Container>

        <Container>
          <CryptocurrencySecurityTokens />
        </Container>

        <Container fluid={true} className="benefitssecuritytokens-bg">
          <Container>
            <BenefitsSecurityTokens />
          </Container>
        </Container>

        <Container fluid={true} className="lisecuritytokens_bg">
          <Container>
            <LimitationsSecurityTokens />
          </Container>
        </Container>

        <Container className="este_bg">
          <SecurityTokenExchanges />
        </Container>

        <Container className="keycomponents_bg">
          <KeyComponentsSTE />
        </Container>

        <Container className="ecosystem_bg">
          <STEEcosystem />
        </Container>

        <Container className="STLCompliance-bg">
          <STCLDifferentCountries />
        </Container>

        <Container className="liscountrie-bg">
          <ListCountries />
        </Container>

        <Container className="stecosystem-bg">
          <SecurityTokenExchangeEcosystem />
        </Container>

        <Container className="startste-bg">
          <StartSecurityTokenExchange />
        </Container>

        <Container fluid={true} className="futureusage-bg">
          <Container>
            <FutureUsageSTE />
          </Container>
        </Container>

        <Container>
          <LimitationsSTEM />
        </Container>

        <Container fluid={true} className="PotentialAvailableMarket-bg">
          <Container>
            <PotentialAvailableMarket />
          </Container>
        </Container>

        <Container fluid={true} className="totalservices-bg">
          <Container>
            <TotalServiceableMarket />
          </Container>
        </Container>

        <Container fluid={true} className="worldwideacceptance-bg">
          <Container>
            <WorldwideAcceptance />
          </Container>
        </Container>

        <Container className="currentmarket-bg">
          <CurrentMarketPerformance />
        </Container>

        <Container fluid={true} className="relate-bg">
          <Container>
            <RelatedRevenueStreams />
          </Container>
        </Container>

        <Container className="keyplayers-bg">
          <KeyPlayersSTEM />
        </Container>

        <Container className="launchingste-bg">
          <LaunchingSTE />
        </Container>

        <Container fluid={true} className="partingwords-bg">
          <Container>
            <PartingWords />
          </Container>
        </Container>

        <ScrollUpButton className="ScrollUpButton" />
      </div>
    );
  }
}
