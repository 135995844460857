import React, { Component,Fragment } from 'react';
import {Container, Row, Col} from 'reactstrap';

export default class WhywriteEspay extends Component {
    render() {
        return (
            <Fragment>
                <Container className="whywriteexchange">
                    <Row>
                        <Col md={12}>
                            <span>Why Write For Espay Exchange</span>
                            <ul>
                                <li>Make a name for yourself in the writing world</li>
                                <li>Reach a wider global audience both on the site and social media</li>
                                <li>The chance to work with one of the most recognized brands in the cryptocurrency blog community worldwide</li>
                                <li>Use articles as reference for future writing jobs</li>
                                <li>Improve your writing skills and learn new trends</li>
                            </ul>
                            <p>If you would like to write for us, send over your article to <strong>"<a href="mailto:pr@espay.exchange">pr@espay.exchange</a>" Or “<a target="_blank" href="https://espay.exchange/blog/wp-login.php?action=register">Register</a>”.</strong> We will let you know in max 72h if your contribution is accepted for publishing. We look forward to sharing your knowledge, views and opinions.</p>
                            <h5><b>Note:</b> Please don’t send us Press Releases or Sales Pitch articles.</h5>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <h4>Last searched words to find this page</h4>
                            <ul>
                                <li>Crypto exchange write for us</li>
                                <li>Derivative exchange write for us</li>
                                <li>IEO guest posting site</li>
                                <li>Defi + write for us</li>
                                <li>Crypto exchange guest post Contributing website</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
