import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import cryicon1 from '../../Images/MarketingResearch/df-icon1.png';
import cryicon2 from '../../Images/MarketingResearch/df-icon2.png';
import cryicon3 from '../../Images/MarketingResearch/df-icon3.png';
import cryicon4 from '../../Images/MarketingResearch/df-icon4.png';
import cryicon5 from '../../Images/MarketingResearch/df-icon5.png';
import cryicon6 from '../../Images/MarketingResearch/df-icon6.png';

export default class CryptocurrencySecurityTokens extends Component {
    render() {
        return (
            <div className="utilitysecuritytokens-bg">
                <Row>
                    <Col sm={12}>                    
                        <div className="heading-title">
                            <h2>Difference between Cryptocurrency and Security Tokens</h2>
                        </div>
                    </Col>
                </Row> 
                <Row>
                    <Col sm={6}>                    
                        <div className="diff-subtitle">
                            <h4>Cryptocurrency</h4>
                        </div>
                    </Col>
                    <Col sm={6}>   
                        <div className="diff-subtitle">
                            <h4>Security Tokens</h4>
                        </div>
                    </Col>
                </Row> 
                <Row className="difference-tokens-bg">
                    <Col sm={12}>
                        <div className="df-icon1 df-common">
                            <div className="df-icon">
                                <img className="img-fluid" src={cryicon1} alt="" title=""/>
                            </div>
                            <div className="df-title">
                                <p>It is blockchain-based electronic money</p>
                            </div>
                        </div>
                        <div className="df-icon2 df-common">
                            <div className="df-icon">
                                <img className="img-fluid" src={cryicon2} alt="" title=""/>
                            </div>
                            <div className="df-title">
                                <p>Each cryptocurrency has its own Blockchain</p>
                            </div>
                        </div>
                        <div className="df-icon3 df-common">
                            <div className="df-icon">
                                <img className="img-fluid" src={cryicon3} alt="" title=""/>
                            </div>
                            <div className="df-title">
                                <p>Cryptocurrency is convertible across different currencies</p>
                            </div>
                        </div>
                        <div className="df-icon4 df-common">
                            <div className="df-icon">
                                <img className="img-fluid" src={cryicon4} alt="" title=""/>
                            </div>
                            <div className="df-title">
                                <p>It represents an asset or a utility</p>
                            </div>
                        </div>
                        <div className="df-icon5 df-common">
                            <div className="df-icon">
                                <img className="img-fluid" src={cryicon5} alt="" title=""/>
                            </div>
                            <div className="df-title">
                                <p>It resides on top of another Blockchain</p>
                            </div>
                        </div>
                        <div className="df-icon6 df-common">
                            <div className="df-icon">
                                <img className="img-fluid" src={cryicon6} alt="" title=""/>
                            </div>
                            <div className="df-title">
                                <p>Security tokens need to be traded, sold or bought in their own ecosystem. However, you can use fiat and crypto to purchase them for the first time.</p>
                            </div>
                        </div>
                    </Col>
                </Row>    
            </div>
        )
    }
}
