import React, { Component } from 'react';
import { Container, Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

export default class BenefitsP2Pexchange extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">Benefits of P2P Exchange</h2> 
                            <ul>
                                <li>
                                    <b>Transaction Censorship</b>
                                    <p>The decentralized system allows everyone to access the same copy of ledgers. Regular exchanges are run by humans. They are susceptible to getting hacked or manipulated. Authority control can intervene and tamper the ledger. Moreover, they can freeze your funds. P2P system doesn’t have the central authority to control and there are almost no chances of tampering.  Even if the system is hacked, the person must either hack 51% of the nodes to change the ledger of each account or take that 51% of his confidence. Now, that sounds impossible.</p>
                                </li>
                                <li>
                                    <b>Cheaper Cost of Operation</b>
                                    <p>Again, the influence of people in regular exchange would mean extra spending. On the other hand, P2P exchanges are run by the software. The software doesn’t demand the transaction or brokerage fees. The transactional fee is optional in trading cryptocurrencies. It is optional when a user wants its transaction to be validated by the miner urgently.</p>
                                </li>
                                <li>
                                    <b>Privacy Sustenance</b>
                                    <p>The central exchange system asks every minute details about the enroller. The government has been enforcing KYC regulations which mean that they have all the details. In the case of P2P, the government can’t apply brute force to KYC process. So, all the transactions can be conducted anonymously.</p>
                                </li>
                                <li>
                                    <b>Secure Channel</b>
                                    <p>The P2P exchange is secure because it doesn’t store the cryptocurrencies for its users. It just makes the sellers and buyers meet. This minimizes the counterparty risk.</p>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
