import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

export default class decentralizedexchange extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is decentralized exchange ?</h2> 
                            <p>A decentralized exchange (DEX) is a cryptocurrency exchange which operates in a decentralized way, i.e., without a central authority, without relying on a third-party service to hold the customer's funds. Instead, trades occur directly between users (peer to peer) through an automated process. This system can be achieved by creating proxy tokens (crypto assets that represent a certain fiat or crypto currency) or assets (that can represent shares in a company for example) or through a decentralized multi-signature escrow system, among other solutions that are currently being developed.</p>
                            <p>Because users do not need to transfer their assets to the exchange, decentralized exchanges reduce the risk of theft from hacking of exchanges. Decentralized exchanges can also prevent price manipulation or faked trading volume through wash trading and are more anonymous than exchanges which implement know your customer requirements.</p>
                            <p>A decentralized exchange can still have centralized components, whereby some control of the exchange is still in the hands of a central authority. A notable example being IDEX blocking New York State users from placing orders on the platform.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
