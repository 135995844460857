import React, { Component } from 'react';
import {Row,Col} from 'reactstrap';

import revenueimg1 from '../../Images/MarketingResearch/revenue-1.png';
import revenueimg2 from '../../Images/MarketingResearch/revenue-2.png';
import revenueimg3 from '../../Images/MarketingResearch/revenue-3.png';
import revenueimg4 from '../../Images/MarketingResearch/revenue-4.png';
import revenueimg5 from '../../Images/MarketingResearch/revenue-5.png';

export default class RelatedRevenueStreams extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm={12}>
                        <div className="relate-title">
                            <span>Security Token Exchange-Related Revenue Streams</span>
                        </div>
                    </Col>
                </Row>
                <Row className="revenue-box">
                    <Col sm={12} lg={9} md={8} className="order-2 order-md-1">
                        <span>Market Listing</span>
                        <p>As an exchange owner, your primary source of income will be market listings. You can charge the security token issuing company with a fee for listing their security tokens on your exchange. You can also enter into strategic partnerships with various issuance platforms such as Ethereum or Polymath to get exclusive trading rights for the security tokens they have developed.</p>
                    </Col>
                    <Col sm={12} lg={3} md={4} className="order-1">
                        <img className="img-fluid" src={revenueimg1} alt="Market Listing" title="Market Listing" />
                    </Col>
                </Row>

                <Row className="revenue-box">
                    <Col sm={12} lg={9} md={8} className="order-2 order-md-1">
                        <span>Trading Commission</span>
                        <p>You can also charge trading commission (or spread) on each trade carried out on your exchange. It can be a flat fee or a fixed percentage of every trade volume.</p>
                    </Col>
                    <Col sm={12} lg={3} md={4} className="order-1">
                        <img className="img-fluid" src={revenueimg2} alt="Trading Commission" title="Trading Commission" />
                    </Col>
                </Row>

                <Row className="revenue-box">
                    <Col sm={12} lg={9} md={8} className="order-2 order-md-1">
                        <span>Digital Marketing</span>
                        <p>Alternatively, you can also help STE owners to market their exchanges. They will need to promote their offerings aggressively as it is still a nascent market. Plus, many regulatory bodies are yet to define the legal framework in their countries, adding more confusion to marketing your STE.</p>
                        <p>You can use your expertise to put together a social media and programmatic advertising strategy for the STEs. You can also help create content, identify the right target audience, as well as recognize potential compliance liabilities for your social media campaign.</p>
                    </Col>
                    <Col sm={12} lg={3} md={4} className="order-1">
                        <img className="img-fluid" src={revenueimg3} alt="Digital Marketing" title="Digital Marketing" />
                    </Col>
                </Row>
                <Row className="revenue-box">
                    <Col sm={12} lg={9} md={8} className="order-2 order-md-1">
                        <span>Guest Blogging and User-Generated Content</span>
                        <p>Another significant income source would be guest blogging. The global crypto community is supported by several passionate bloggers ranging from novices to experts. These bloggers are also highly likely to become investors or exchange platform stakeholders.</p>
                        <p>There is a strong potential to earn money in cryptocurrency as well as fiat money via blogging. The blockchain-based social platform Steem is already offering compensation for creating and sharing content.</p>
                    </Col>
                    <Col sm={12} lg={3} md={4} className="order-1">
                        <img className="img-fluid" src={revenueimg4} alt="Guest Blogging" title="Guest Blogging" />
                    </Col>
                </Row>

                <Row className="revenue-box">
                    <Col sm={12} lg={9} md={8} className="order-2 order-md-1">
                        <span>Digital PR</span>
                        <p>This revenue stream is analogous to guest blogging. Creating, hosting and sharing press releases and the latest news from various STEs can also be a great source of income for a crypto enthusiast. Given the volatile nature of the security token market and the rapidly evolving market place, you have plenty of opportunities to promote an exchange via strategic digital PR marketing.</p>
                    </Col>
                    <Col sm={12} lg={3} md={4} className="order-1">
                        <img className="img-fluid" src={revenueimg5} alt="Digital PR" title="Digital PR" />
                    </Col>
                </Row>
            </div>
        )
    }
}
