import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

export default class P2PExchange2 extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col sm="12">
            <div className="P2Ptitle">
              <h2>Peer to Peer Market Place Features</h2>
              <p>
                Espay offers seamless peer-to-peer marketplace and
                doesn't require any third party. But that's not all. The platform
                is powerpacked with a whole lot of features.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="6" lg="4">
            <div className="PeerToPeerBox">
              <h3>Preferred trader selection</h3>
              <p>
                Buyers have the flexibility to choose their preferred sellers,
                and vice versa, for a more confident and trusted trading
                experience.
              </p>
            </div>
          </Col>
          <Col sm="12" md="6" lg="4">
            <div className="PeerToPeerBox">
              <h3>Cheaper Cost of Operation</h3>
              <p>
                P2P exchanges are run by the trading software which doesn’t
                demand a transaction/brokerage fees. It is optional if a trader
                needs its transaction to be authenticated by a miner.
              </p>
            </div>
          </Col>
          <Col sm="12" md="6" lg="4">
            <div className="PeerToPeerBox">
              <h3>Privacy Sustenance</h3>
              <p>
                In P2P exchange, the government can’t force for the KYC
                procedure. So, each single transaction can be executed
                anonymously.
              </p>
            </div>
          </Col>
          <Col sm="12" md="6" lg="4">
            <div className="PeerToPeerBox">
              <h3>Escrow for Security of Transaction</h3>
              <p>
                It aid to secure trading transactions and avoid fraud. Not only
                make P2P exchange safe by sending/receiving escrow but analyze
                the entire market & community.
              </p>
            </div>
          </Col>
          <Col sm="12" md="6" lg="4">
            <div className="PeerToPeerBox">
              <h3>No Third Parties</h3>
              <p>
                There is no existence and involvement of middle-man or difficult
                registration procedures in any transaction.
              </p>
            </div>
          </Col>
          <Col sm="12" md="6" lg="4">
            <div className="PeerToPeerBox">
              <h3>Multiple modes of payments</h3>
              <p>
                Peer-to-Peer payments enable traders to send, receive, or
                request a payment with multiple modes and industry leaders such
                as PayPal, Venmo, Square, Inc., among myriad others.
              </p>
            </div>
          </Col>
          <Col sm="12" md="6" lg="4">
            <div className="PeerToPeerBox">
              <h3>Multicurrency P2P Trading Platform</h3>
              <p>
                The multi-currency P2P exchange aid your traders to trade
                Bitcoin & altcoins, and can streamline all digital coin related
                operations in a single medium.
              </p>
            </div>
          </Col>
          <Col sm="12" md="6" lg="4">
            <div className="PeerToPeerBox">
              <h3>Multi-layer Security</h3>
              <p>SSL security and two-factor authentication, such as email authentication and Google authentication, add an essential layer of security to our P2P exchange platform.       </p>
            </div>
          </Col>
          <Col sm="12" md="6" lg="4">
            <div className="PeerToPeerBox">
              <h3>Individuals (buyers & sellers) are the only participants</h3>
              <p>
                In P2P networks where buyers are also sellers, you could make a
                network perform with less members because of multiple product or
                service variety with just a few participants.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
