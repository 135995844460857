import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import yearsexperience from '../../Images/centralized/10yearsexperience.png';
import Dedicated from '../../Images/centralized/100Dedicated.png';
import Bankinggradesecurity from '../../Images/centralized/Bankinggradesecurity.png';
import ConvergedExchange from '../../Images/centralized/ConvergedExchange.png';

class exchange_software_standard_section6 extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm={12}>
                    <div className="standardtitle">
                        <h2>Why Choose Espay For Crypto Exchange Software?</h2>
                    </div>
                </Col>
            </Row>         
            <Row>
                <Col sm={3}>
                    <div className="whychoosebox">
                        <img src={Dedicated} alt="100+ Dedicated IT Team" title="100+ Dedicated IT Team" />
                        <span>100+ Dedicated IT Team</span>
                    </div>
                </Col>
                <Col sm={3}>
                    <div className="whychoosebox">
                        <img src={Bankinggradesecurity} alt="Banking Grade Security" title="Banking Grade Security" />
                        <span>Banking Grade Security</span>
                    </div>
                </Col>
                <Col sm={3}>
                    <div className="whychoosebox">
                        <img src={yearsexperience} alt="10+ Years of Experience" title="10+ Years of Experience" />
                        <span>10+ Years of Experience</span>
                    </div>
                </Col>
                <Col sm={3}>
                    <div className="whychoosebox">
                        <img src={ConvergedExchange} alt="Converged Exchange Solution" title="Converged Exchange Solution" />
                        <span>Converged Exchange Solution</span>
                    </div>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default exchange_software_standard_section6;