import React from 'react';
import { Container,  Col, Row } from 'reactstrap';

import Faqleftpanel from "../Faqleftpanel";

class Faqcryptoexchanges extends React.Component {
  render() {
    return (
      <div className="main">
        <Container fluid={true}>
            <Row>
                <Col md={4}>
                <Faqleftpanel {...this.props} />
                </Col>
                <Col md={7} className="mx-auto">
                    <h2 className="topinnertitle">How do centralized crypto exchanges work?</h2>
                    <p>The centralized exchange solution is one of the most critical mechanisms for transaction for most digital currency investors. Centralized cryptocurrency exchanges are sites for buying and selling cryptocurrencies online. They are the most common means investors use to purchase and sell their holdings in cryptocurrency.</p>
                    <p>Some investors may consider the idea of a centralized cryptocurrency exchange solution somewhat confusing, as digital currencies themselves are often advertised as "decentralized." What does it mean to "centralize" an exchange of this kind, and why are such exchanges so vital to the success of the entire cryptocurrency industry?</p>
                </Col>
            </Row>
        </Container>
      </div>
    );
  }
}

export default Faqcryptoexchanges;