import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import bannertoken from '../../Images/MarketingResearch/bannertoken.png';

export default class DigitalCurrency extends Component {
    render() {
        return (
            <div>
                <Row className="tokenboxmain">
                    <Col sm={12} lg={7}>                    
                        <p>While the world continues to debate if security tokens are a fad or not, the overall security token ecosystem is expanding swiftly. The downfall of ICOs paved the way for the tokenization of various real-world assets, giving rise to STOs.</p>
                        <p>Further, STOs lead to the development of security token exchanges or STEs. Touted as the best possible way to provide liquidity, security token exchange platforms are expected to result in the rapid growth of security token trading.</p>
                    </Col>
                    <Col sm={12} lg={{size:4, offset:1}}> 
                        <img className="img-fluid" src={bannertoken} alt="Security Token Exchanges" title="Security Token Exchanges" />
                    </Col>
                    <Col sm={12} className="mt-10">
                        <p>However, being in its infancy, there are various gray areas associated with security token exchange development. In this comprehensive guide, we have tried to explain the fundamental concepts related to STE development, cast light on the present state of the global security token exchange ecosystem, and examine its scope in the foreseeable future.</p>
                    </Col>
                </Row> 

                <Row className="differentareasmain">
                    <Col sm={12}> 
                        <div className="differentareasbox">
                            <h2>Understanding the Different Areas of Digital Currency</h2>
                            <p>To understand the concept of security token exchanges better, let’s revise a few key terms associated with the crypto world.</p>
                        </div>
                    </Col>
                </Row>


                <Row>
                    <Col sm={12}> 
                    <section className="area-of-currency">
                        <div className="area-of-currency-img wow zoomIn" data-wow-delay="0.5s">
                            <div className="area-boxs area-box-ico wow fadeIn" data-wow-delay="1s">
                                <div className="area-heading">
                                    <h3>ICO</h3>
                                </div>
                                <div className="area-box-content">
                                    <p>An Initial Coin Offering or ICO sells utility tokens in exchange for cryptocurrency to raise capital. They are unregulated securities.</p>
                                </div>
                            </div>
                            <div className="area-boxs area-box-Exchange wow fadeIn" data-wow-delay="1.2s">
                                <div className="area-heading">
                                    <h3>Cryptocurrency Exchange </h3>
                                </div>
                                <div className="area-box-content">
                                    <p>A cryptocurrency exchange is where you can buy, sell or trade cryptocurrencies using another cryptocurrency or a fiat currency such as USD, Yen, or Euro.</p>
                                </div>
                            </div>
                            <div className="area-boxs area-box-Sto wow fadeIn" data-wow-delay="1.2s">
                                <div className="area-heading">
                                    <h3>STO</h3>
                                </div>
                                <div className="area-box-content">
                                    <p>A security token offering sells security tokens that, unlike utility tokens, are backed by tangible assets. STO investors also get voting rights or revenue distribution rights.</p>
                                </div>
                            </div>
                            <div className="area-boxs area-box-Cryptocurrency wow fadeIn" data-wow-delay="1.4s">
                                <div className="area-heading">
                                    <h3>Cryptocurrency</h3>
                                </div>
                                <div className="area-box-content">
                                    <p>Cryptocurrency is a digital currency. Blockchain is the bedrock of all cryptocurrencies. It is globally accessible, decentralized, irreversible, anonymous, and extremely secure. There are more than 2000 cryptocurrencies with Bitcoin dominating half of the market share.</p>
                                </div>
                            </div>
                            <div className="area-boxs area-box-Ste wow fadeIn" data-wow-delay="1.4s">
                                <div className="area-heading">
                                    <h3>STE</h3>
                                </div>
                                <div className="area-box-content">
                                    <p>A security token exchange platform or STE allows investors to sell, buy, and trade security tokens in a fashion similar to the conventional stock exchange.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    </Col>
                </Row>
            </div>
        )
    }
}
