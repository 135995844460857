import React, { Component } from 'react';
import { Row, Col} from 'reactstrap';
import { Link } from "react-router-dom";
import WhiteLabelOffering_new1 from '../../Images/WhiteLabelOffering_new1.png';

class offering_lable_Section4 extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm="12">
                    <div className="Whitelabeltitle">
                        <h2>Exchange White Label Offering</h2>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="7">
                    <img className="img-fluid" src={WhiteLabelOffering_new1} alt="White Label Exchange Solution" title="White Label Exchange Solution" />
                </Col>
                <Col sm="5">
                    <div className="whiteLabelexchange">
                        <h3>White Label Exchange Solution</h3>
                        <hr/>
                        <ul>
                            <li>Exchange as a Service</li>
                            <li>Own Brand Name & Logo</li>
                            <li>Liquidity & Merchant API</li>
                            <li>Web Wallet</li>
                            <li>Multiple Assets</li>
                            <li>24 X 7 Support</li>                                   
                        </ul>
                        <Link to="/contact-us">Ask For Quote</Link>
                    </div>
                </Col>     
            </Row> 
        </div>
      );
    }
}

export default offering_lable_Section4;