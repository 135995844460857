import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

export default class ValuationSecurityToken extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm={12}>                    
                        <div className="heading-title">
                            <h2>Valuation of a Security Token</h2>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <div className="valuation-token-content">
                            <p>To make a security token tradable, it must render a value. However, as the entire security token ecosystem is in its infancy, there is a lack of universal valuation process. Right now, everyone uses their own set of rules to create a valuation.</p>
                            <p>As a security token is a combination of crypto and an actual asset, you must consider both attributes when determining the value of an asset.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <div className="cryptohead">
                           <h2>Crypto Factors</h2>
                           <p>You can use any of the crypto asset valuation frameworks in circulation today to determine the value of various factors associated with the crypto nature of a security token. Some of the leading valuation models use a simple formula: M =PQ/V It takes into account the following crypto factors -</p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <div className="cypto-bg">
                            <ul className="cypto-title">
                                <li>Size of the asset</li>
                                <li>Velocity of the asset</li>
                                <li>Price of the tokens being provisioned</li>
                                <li>Quantity of the tokens being provisioned</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
