import React, { Component } from 'react';
import {Row, Col} from 'reactstrap';
import { Link } from "react-router-dom";

import WhiteLabelExchangeSolution from '../../Images/WhiteLabelExchangeSolution.png';

class packages_centralized_section5 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12">
                    <div className="Centralizedtitle">
                        <h2>Our Centralized Exchange Development Packages</h2>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="5">
                    <div className="CentralizedWhiteLabel">
                        <h3>White Label Exchange Solution</h3>
                        <hr/>
                        <ul>
                            <li>Exchange as a Service</li>
                            <li>Own Brand Name & Logo</li>
                            <li>Liquidity & Merchant API</li>
                            <li>Web Wallet</li>
                            <li>Multiple Assets</li>
                            <li>24 X 7 Support</li>
                        </ul>
                        <Link to="/contact-us">Get Started</Link>
                    </div>
                </Col>
                <Col sm="7">
                    <img className="img-fluid centrlized-feature-WhiteLabel-img" src={WhiteLabelExchangeSolution}  alt="White Label Exchange Solution"
                     title="White Label Exchange Solution" />
                </Col>
            </Row>
        </div>
      );
    }
}

export default packages_centralized_section5;