import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";
import { Card, CardImg,CardBody } from 'reactstrap';
import blog1  from '../../Images/blog/new-security.png';
import blog2  from '../../Images/blog/IMG.png';
import blog3  from '../../Images/blog/Future_Of_STO.png';

class blog extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12"> 
                    <div className="blogcommontitle">
                        <h2>Our Insight</h2>
                    </div>                      
                </Col>
            </Row>  
            <Row className="commonblog">
                <Col sm="4">                         
                    <Card className="carddiv">
                        <CardImg top width="100%" src={blog1} alt="How Is Security Token Exchange Solution A Smart Innovation?"  title="How Is Security Token Exchange Solution A Smart Innovation?" />
                        <CardBody>
                            <a 
                                target="_blank" 
                                rel="noopener noreferrer"
                                href="https://espay.exchange/blog/how-is-security-token-exchange-solution-a-smart-innovation/" 
                                className="card-title">
                                How Is Security Token Exchange Solution A Smart Innovation?
                            </a>                        
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="4">                         
                    <Card className="carddiv">
                        <CardImg top width="100%" src={blog2} alt="How Security Token Exchange Work?" title="How Security Token Exchange Work?" />
                        <CardBody>
                        <a 
                            target="_blank" 
                            rel="noopener noreferrer"
                            href="https://espay.exchange/blog/security-token-exchange-work/" 
                            className="card-title">How Security Token Exchange Work?
                        </a>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="4">                         
                    <Card className="carddiv">
                        <CardImg top width="100%" src={blog3} alt="Security Tokens Offerings Exchange – Extremely Potential or Short Lived?"  title="Security Tokens Offerings Exchange – Extremely Potential or Short Lived?"/>
                        <CardBody>
                        <a 
                            target="_blank" 
                            rel="noopener noreferrer"
                            href="https://espay.exchange/blog/security-tokens-offerings-exchange-is-it-extremely-potential-or-short-lived/" 
                            className="card-title">Security Tokens Offerings Exchange – Extremely Potential or Short Lived?
                        </a>
                        </CardBody>                    
                    </Card>
                </Col>
            </Row>           
        </div>
      );
    }
}

export default blog;