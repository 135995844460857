import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

import IEOconsultation  from "../../Images/IEO/Initialexchangeofferingconsultationfromespayx.png";

export default class STOWebPage extends Component {
  render() {
    return (
      <div>
        <Row>   
            <Col sm="7">
                <div className="stoadviceitle">
                    <h2>Initial exchange offering consultation from Espay-X</h2>
                    <p>Initial Exchange Offering (IEO) is a fundraising tool where issuers build tokens for their ventures and collect funds using the exchange's user base. </p>
                    <p>Fundraising via IEO is considered safer by investors as the crypto-exchange ensures due diligence before any project is approved. Initial Exchange Offering is a growing phenomenon, which has the potential to change the way we look at fundraising based on token. This funding method gives the investors liquidity trust while providing the projects with a ready-made user base to target their marketing efforts. It also reduces the burden on issuers of the KYC / AML process, as the exchanges take responsibility for verifying investors and projects.</p>
                    <p>At Espay-X, for your initial exchange offering (IEO) platform development, we have the right kind of experience, staff, and skills in guiding your IEO campaign. Ask for our strategic alliances with leading cryptocurrency exchanges and we will get you to launch your token crowdfunding campaign instantly on our network.</p>
                </div>    
            </Col>
            <Col sm="5">
                <img src={IEOconsultation} alt="IEOconsultation" title="IEOconsultation" className="img-fluid ieoconsultation-image" />
            </Col>
        </Row>  
       

       
      </div>
    )
  }
}
