import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import WeKnowMarket from '../../Images/Landing/Investor/WeKnowMarket.jpg';
import Expertise from '../../Images/Landing/Investor/Expertise.jpg';
import CountryRegulations from '../../Images/Landing/Investor/CountryRegulations.jpg';
import revenuestreams from '../../Images/Landing/Investor/revenuestreams.jpg';
import businessmodel from '../../Images/Landing/Investor/businessmodel.jpg';
import launchexchange from '../../Images/Landing/Investor/launchexchange.jpg';


class Tp_section11 extends Component {
    render() {
      return (
        <div> 
            <Row className="sec-11-heading">
                <h2>Your Benefit With Espay’s Cryptocurrency Exchange Software Development</h2>
                <p>Want to know why we have become the preferred technology partners for established bitcoin, cryptocurrency exchanges. Check out why exchanges trust us and use our cryptocurrency exchange software for their business.</p>
            </Row>
            <div className="benefit-web">
                <Row className="sec-11-section">
                    <Col sm={{size:6}} className="spark-img">  
                    <div className="box">
                            <img className="img-fluid spark" src={WeKnowMarket} alt="We Know The Market" title="We Know The Market" />
                        </div>                   
                    </Col>
                    <Col sm={{size:6}}>
                        <div >
                            <h3>We Know The Market</h3>
                            <p>Your branded exchange should present you as an authority in the cryptocurrency space. That’s why you need Espay’s expertise that not only gives your technical, but also administrative knowledge to sustain in the market. We know about 
                            </p>
                            
                            <ul>
                                <li> The existing gaps in the system</li>
                                <li>Country regulations</li>
                                <li>User sentiment towards the coin</li>
                                <li>Functionalities of a crypto exchange</li>
                            </ul>
                        </div>
                    </Col>
                </Row> 

                <Row className="sec-11-section">
                    <Col sm={{size:6}} >
                        <div >
                            <h3>We Have Expertise</h3>
                            <p>Building a cryptocurrency exchange software requires someone who understands the decentralized nature of the digital currency. Our team of crypto exchange consultants, crypto researchers, and crypto exchange programmers are all you need to enter into the crypto market.
                            </p>
                        </div>
                    </Col>
                    <Col sm={{size:6}} className="spark-img">    
                    <div className="box">
                        <img className="img-fluid spark" src={Expertise} alt="We Have Expertise" title="We Have Expertise" />
                        </div>
                    </Col>
                </Row> 

                <Row className="sec-11-section">
                    <Col sm={{size:6}} className="spark-img">    
                    <div className="box">
                        <img className="img-fluid spark" src={CountryRegulations} alt="We Guide With Country Regulations" title="We Guide With Country Regulations" />
                    </div>
                    </Col>
                    <Col sm={{size:6}}>
                        <div >
                        <h3>We Guide With Country Regulations</h3>
                            <p>We help you stay on the right side of the law. With our extensive knowledge on the cryptocurrency regulations around the world, we guide you which country should be your operating grounds. We advise on which country is the friendliest, what the government regulations are, how much investment is required, what taxes you might need to pay and more.
                            </p>
                        </div>
                    </Col>
                </Row> 


                

                <Row className="sec-11-section">
                    <Col sm={{size:6}} >
                        <div >
                            <h3>We build your revenue streams</h3>
                            <p>We build a revenue machine out of your cryptocurrency exchange. Trading commission is just part of the income from exchange. Espay opens up a huge range of options for you, including bounty and crypto listing to ensure that you get huge ROI from your branded exchange.</p>
                        </div>
                    </Col>
                    <Col sm={{size:6}} className="spark-img">    
                    <div className="box">
                        <img className="img-fluid spark" src={revenuestreams} alt="We build your revenue streams" title="We build your revenue streams" />
                    </div>
                    </Col>
                </Row> 

                <Row className="sec-11-section">
                    <Col sm={{size:6}} className="spark-img">   
                    <div className="box">
                        <img className="img-fluid spark" src={businessmodel} alt="We build your business model" title="We build your business model" />
                    </div>
                    </Col>
                    <Col sm={{size:6}}>
                        <div >
                            <h3>We build your business model</h3>
                            <p>With centralized exchange, decentralized exchange, security tokens exchange, hybrid exchange and more offerings we ensure that your exchange has the perfect product-market fit.
                            </p>
                            
                            <ul>
                                <li> Your exchange will target the current market challenges.</li>
                                <li>We help you disrupt with your own USP.</li>
                                <li>We build your revenue model.</li>
                                <li>Your exchange is built with compliance to country regulations.</li>
                                <li>We define the executable business strategies.</li>
                            </ul>
                        </div>
                    </Col>
                </Row>

                <Row className="sec-11-section">
                    <Col sm={{size:6}} >
                        <div >
                            <h3>We launch your exchange</h3>
                            <p>Your final cryptocurrency exchange is an out-of-the-box solution that offers scalable architecture, a super-fast matching engine that supports several cryptocurrencies and enhanced security. Strong backend and frontend features will ensure that you stay the market leader in your niche.</p>
                        </div>
                    </Col>
                    <Col sm={{size:6}} className="spark-img">                       
                    <div className="box">
                        <img className="img-fluid spark" src={launchexchange} alt="We launch your exchange" title="We launch your exchange" />
                    </div>
                    </Col>
                </Row> 
            </div>


            <div className="benefit-mob">
                <Row className="sec-11-section">
                    <Col sm={{size:6}} className="spark-img">  
                    <div className="box">
                            <img className="img-fluid spark" src={WeKnowMarket} alt="We Know The Market" title="We Know The Market" />
                        </div>                   
                    </Col>
                    <Col sm={{size:6}}>
                        <div >
                            <h3>We Know The Market</h3>
                            <p>Your branded exchange should present you as an authority in the cryptocurrency space. That’s why you need Espay’s expertise that not only gives your technical, but also administrative knowledge to sustain in the market. We know about 

                            </p>
                            
                            <ul>
                                <li> The existing gaps in the system</li>
                                <li>Country regulations</li>
                                <li>User sentiment towards the coin</li>
                                <li>Functionalities of a crypto exchange</li>
                            </ul>
                        </div>
                    </Col>
                </Row> 

                <Row className="sec-11-section">
                     <Col sm={{size:6}} className="spark-img">    
                    <div className="box">
                                        
                        <img className="img-fluid spark" src={Expertise} alt="We Have Expertise" title="We Have Expertise" />
                        </div>
                    </Col>
                    <Col sm={{size:6}} >
                        <div >
                            <h3>We Have Expertise</h3>
                            <p>Building a cryptocurrency exchange software requires someone who understands the decentralized nature of the digital currency. Our team of crypto exchange consultants, crypto researchers, and crypto exchange programmers are all you need to enter into the crypto market. With our extensive research in centralized, decentralized, P2P and security token exchanges space, we easily become a trusted partner for all our clients.                </p>
                        </div>
                    </Col>
                </Row> 

                <Row className="sec-11-section">
                    <Col sm={{size:6}} className="spark-img">    
                    <div className="box">
                        <img className="img-fluid spark" src={CountryRegulations} alt="We Guide With Country Regulations" title="We Guide With Country Regulations" />
                    </div>
                    </Col>
                    <Col sm={{size:6}}>
                        <div >
                        <h3>We Guide With Country Regulations</h3>
                            <p>We help you stay on the right side of the law. With our extensive knowledge on the cryptocurrency regulations around the world, we guide you which country should be your operating grounds. We advise on which country is the friendliest, what the government regulations are, how much investment is required, what taxes you might need to pay and more. For eg: we know the reason why Malta is the hot destination for setting up a cryptocurrency exchange.  </p>
                        </div>
                    </Col>
                </Row> 


                

                <Row className="sec-11-section">
                <Col sm={{size:6}} className="spark-img">    
                    <div className="box">
                        <img className="img-fluid spark" src={revenuestreams} alt="We build your revenue streams" title="We build your revenue streams" />
                    </div>
                    </Col>
                    <Col sm={{size:6}} >
                        <div >
                            <h3>We build your revenue streams</h3>
                            <p>We build a revenue machine out of your cryptocurrency exchange. Trading commission is just part of the income from exchange. Espay opens up a huge range of options for you, including bounty and crypto listing to ensure that you get huge ROI from your branded exchange. According to a report from Accenture, revenue from cryptocurrency exchanges is now almost in-sync with traditional exchanges</p>
                        </div>
                    </Col>
                </Row> 

                <Row className="sec-11-section">
                    <Col sm={{size:6}} className="spark-img">   
                    <div className="box">
                        <img className="img-fluid spark" src={businessmodel} alt="We build your business model" title="We build your business model" />
                    </div>
                    </Col>
                    <Col sm={{size:6}}>
                        <div >
                            <h3>We build your business model</h3>
                            <p>With centralized exchange, decentralized exchange, security tokens exchange, hybrid exchange and more offerings we ensure that your exchange has the perfect product-market fit.
                            </p>
                            
                            <ul>
                                <li> Your exchange will target the current market challenges.</li>
                                <li>We help you disrupt with your own USP.</li>
                                <li>We build your revenue model.</li>
                                <li>Your exchange is built with compliance to country regulations.</li>
                                <li>We define the executable business strategies.</li>
                            </ul>
                        </div>
                    </Col>
                </Row>

                <Row className="sec-11-section">
                <Col sm={{size:6}} className="spark-img">                       
                    <div className="box">
                        <img className="img-fluid spark" src={launchexchange} alt="We launch your exchange" title="We launch your exchange" />
                    </div>
                    </Col>
                    <Col sm={{size:6}} >
                        <div >
                            <h3>We launch your exchange</h3>
                            <p>Your final cryptocurrency exchange is an out-of-the-box solution that offers scalable architecture, a super-fast matching engine that supports several cryptocurrencies and enhanced security. Strong backend and frontend features will ensure that you stay the market leader in your niche. From concept to launch, our sales and technical support will take care of all the activities to ensure your disruptive entry into the cryptocurrency sphere.  </p>
                        </div>
                    </Col>
                </Row> 
            </div>
        </div>
      );
    }
}

export default Tp_section11;