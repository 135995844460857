import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

export default class BenefitsDecentralized extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What are the benefits of decentralized exchange ?</h2> 
                            <ul>
                                <li>Trustless nature</li>
                                <li>Funds are held by you in your personal wallet and not by a third party</li>
                                <li>Users are not required to disclose their personal details (except if the exchange method involves bank transfers, in this case only the buyer or seller from which you will buy\sell will only know your details)</li>
                                <li>No Hacker activity</li>
                                <li>No Server Downtime</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
