import React, { Component } from 'react';
import { Row, Col} from 'reactstrap';

import Traders from '../../Images/DerivativesTrading/Traders.png';

class Institutionaltraders extends Component {
    render() {
      return (
        <div className="institutional-traders">  
            <Row>
                <Col sm="4">
                    <div className="">
                        <h2>What do <br/>we have in store <br/>for retail and <br/>institutional traders?</h2>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="institutional-box">
                        <p>With the successful completion of a number of projects in multiple administrations for brokers, we are pleased to offer our creative and knowledgeable expertise to the retail and institutional traders.</p>
                    </div>
                </Col>

                <Col sm="4">
                    <div className="institutional-box">
                        <img className="img-fluid Traders-img" src={Traders} alt="institutional traders" title="institutional traders" />
                    </div>
                </Col>
            </Row>          
            
        </div>
      );
    }
}

export default Institutionaltraders;