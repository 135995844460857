import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";

class services_token_section1 extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm="12">   
                    <div className="SecurityTokentitle" >
                        <h2>Custom Security Token Exchange Services</h2>
                        <p>Security Token Exchange Development Team of Espay has created an Exchange
                            solution for tokenized assets, liabilities, securities which is compliant with guidelines of SEC. This
                            <strong> Security Token Exchange</strong> Solution is secured, reliable and safe for your users to trade
                            on.</p>
                        <p>With the emergence of new technologies, this new Asset trading platform will come under the bracket of 
                            alternate trading system for
                            <strong> <Link to="/crypto-exchange-software">Cryptocurrency Exchanges</Link> </strong>.Espay Security Token Exchange Software is compatible
                            with utilities as well as securities.</p>
                    </div>                    
                </Col>
            </Row> 
        </div>
      );
    }
}

export default services_token_section1;