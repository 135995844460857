import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class welcome_section1 extends Component {
    render() {
      return (
        <div className="Termstitle"> 
                <Row>
                    <Col sm="12">                       
                        <h2>Welcome to Espay Pty Ltd.</h2>
                        <p>These terms and conditions outline the rules and regulations for the use of Espay Pty Ltd.’s Website.</p>
                        <p>Espay Pty Ltd. is located at: Level 13/50 Carrington Street, SydneyNSW – 2000, Australia</p>
                        <p>By accessing this website we assume you accept these terms and conditions in full. Do not continue to use 
                            Espay Pty Ltd.’s website if you do not accept all of the terms and conditions stated on this page.</p>
                        <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice
                            and any or all Agreements: “Client”, “You” and “Your” refers to you, the person accessing this website
                            and accepting the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, 
                            refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or either 
                            the Client or ourselves. All terms refer to the offer, acceptance and consideration of payment
                            necessary to undertake the process of our assistance to the Client in the most appropriate manner,
                            whether by formal meetings of a fixed duration, or any other means, for the express purpose of
                            meeting the Client’s needs in respect of provision of the Company’s stated services/products, in 
                            accordance with and subject to, prevailing law of Australia. Any use of the above terminology or 
                            other words in the singular, plural, capitalisation and/or he/she or they, are taken as 
                            interchangeable and therefore as referring to same.</p>

                        <h2>Cookies</h2>
                        <p>We employ the use of cookies. By using Espay Pty Ltd.’s website you consent to the use of cookies in 
                           accordance with Espay Pty Ltd.’s privacy policy.Most of the modern day interactive web sites use cookies to 
                           nable us to retrieve user details for each visit. Cookies are used in some areas of our site to enable the
                           functionality of this area and ease of use for those people visiting. Some of our affiliate / advertising
                           partners may also use cookies.</p>

                        <h2>Product Information</h2>
                        <p>While Espay Pty. Ltd. has endeavoured to ensure that all information, representations, illustrations, 
                           specifications, terms and conditions and prices, fees or charges contained on this website are accurate at 
                           the time of publication, the information on this website is general in nature only and may show overseas 
                           product features, specifications or models. Product features, specifications, models and prices, and 
                           service terms and conditions, fees and charges, are subject to change without notice. The general 
                           information on this website should always be read in conjunction with other specific materials and 
                           information available from Espay Pty. Ltd.</p>
                        <p>Actual prices may vary according to individual circumstances and may vary between authorised businesses 
                               and states/territories/countries.</p>
                        <p>Nothing on this website should be taken as constituting a quote or offer of finance or insurance, or the 
                            provision of financial or taxation advice</p>  

                        <h3>License</h3>  
                        <p>Unless otherwise stated, Espay Pty Ltd. and/or it’s licensors own the intellectual property rights for all 
                           material on Espay Pty Ltd.. All intellectual property rights are reserved. You may view and/or print pages
                           from https://www.espay.exchange for your own personal use subject to restrictions set in these terms and 
                           conditions.</p>
                        <p>You must not:</p>
                        <ul>
                            <li>Republish material from https://www.espay.exchange</li>
                            <li>Sell, rent or sub-license material from https://www.espay.exchange</li>
                            <li>Reproduce, duplicate or copy material from https://www.espay.exchange</li>
                            <li>Redistribute content from Espay Pty Ltd. (unless content is specifically made for redistribution).</li>
                        </ul>

                        <h3>Intellectual Property Rights</h3>
                        <p>Unless otherwise indicated, all copyright and other intellectual property rights in all information, data, 
                            text, graphics, images, logos, trade marks and other materials on this website are the property of Espay 
                            Pty. Ltd. or are included with the permission of the relevant owner.</p>
                        <p>Except as expressly prohibited in relation to particular content, you are permitted to browse this website, 
                            reproduce extracts by way of printing, downloading to a hard disk or for the purposes of distribution to 
                            other individuals. This is only to be done on the proviso that you keep intact all copyright and other 
                            proprietary notices. No reproduction of any part of this website may be sold or distributed for commercial
                            gain nor shall it be modified or incorporated in any other work, publication or website.</p>
                        <p>You must not use any trade marks or logos which appear or are used on this website, nor do anything to 
                            prejudice the rights of the owner or licensee of such trademarks or logos. Nothing contained on this 
                            website should be construed as granting any licence or right to use any trademark displayed on this 
                            website. Your use/misuse of the trade marks displayed on this website, or on any other content on this 
                            website, except as provided for in these Terms and Conditions, is strictly prohibited. Espay Pty. Ltd. 
                            reserves all its rights to enforce its intellectual property rights to the fullest extent of the law.</p>

                        <h3>User Comments</h3>
                        <p>This Agreement shall begin on the date hereof. Certain parts of this website offer the opportunity for 
                            users to post and exchange opinions, information, material and data (‘Comments’) in areas of the website. 
                            Pty Ltd. does not screen, edit, publish or review Comments prior to their appearance on the website and 
                            Comments do not reflect the views or opinions of Espay Pty Ltd., its agents or affiliates. Comments reflect 
                            the view and opinion of the person who posts such view or opinion. To the extent permitted by applicable laws 
                            Espay Pty Ltd.shall not be responsible or liable for the Comments or for any loss cost, liability, damages or 
                            expenses caused and or suffered as a result of any use of and/or posting of and/or appearance of the Comments 
                            on this website. Espay Pty Ltd.reserves the right to monitor all Comments and to remove any Comments which it 
                            considers in its absolute discretion to be inappropriate, offensive or otherwise in breach of these Terms and 
                            Conditions.</p>
                        <p>You warrant and represent that:</p>
                        <p>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so.</p>
                        <p>The Comments do not infringe any intellectual property right, including without limitation copyright, 
                            patent or trademark, or other proprietary right of any third party.</p>
                        <p>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material or 
                            material which is an invasion of privacy.</p>
                        <p>The Comments will not be used to solicit or promote business or custom or present commercial activities or 
                            unlawful activity.</p>
                        <p>You hereby grant to Espay Pty Ltd. a non-exclusive royalty-free license to use, reproduce, edit and 
                            authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</p>
                    
                        <h4>Hyperlinking to our Content</h4>
                        <p>The following organizations may link to our Web site without prior written approval:</p>
                        <p>Government agencies;<br/>
                            Search engines;<br/>
                            News organizations;<br/>
                            Online directory distributors when they list us in the directory may link to our Web site in the same manner as they hyperlink to the Web sites of other listed businesses; and<br/>
                            Systemwide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Website.</p>
                        <p>These organizations may link to our home page, to publications or to other Web site information so long as 
                            the link:</p>
                        <p>(a) is not in any way misleading;<br/>
                            (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and<br/>
                            (c) fits within the context of the linking party’s site.</p>
                        <p>We may consider and approve in our sole discretion other link requests from the following types of 
                            organizations:</p>
                        <p>Commonly-known consumer and/or business information sources such as Chambers of Commerce, American 
                            Automobile Association, AARP and Consumers Union;</p>
                        <p>dot.com community sites; associations or other groups representing charities, including charity giving sites,</p>
                        <p>online directory distributors;</p>
                        <p>internet portals;</p>
                        <p>accounting, law and consulting firms whose primary clients are businesses; and</p>
                        <p>educational institutions and trade associations.</p>
                        <p>We will approve link requests from these organizations if we determine that:</p>
                        <p>(a) the link would not reflect unfavorably on us or our accredited businesses (for example, trade 
                            associations or other organizations representing inherently suspect types of business, such as 
                            work-at-home opportunities, shall not be allowed to link);</p>
                        <p>(b)the organization does not have an unsatisfactory record with us;</p>
                        <p>(c) the benefit to us from the visibility associated with the hyperlink outweighs the absence of ; and</p>
                        <p>(d) where the link is in the context of general resource information or is otherwise consistent with 
                            editorial content in a newsletter or similar product furthering the mission of the organization.</p>
                        <p>These organizations may link to our home page, to publications or to other Web site information so long as the link:</p>
                        <p>(a) is not in any way misleading;<br/>
                           (b) does not falsely imply sponsorship, endorsement or approval of the linking party and it products or services; and<br/>
                           (c) fits within the context of the linking party’s site.</p>
                        <p>If you are among the organizations listed in paragraph 2 above and are interested in linking to our 
                            website, you must notify us by sending an e-mail to hello@espay.io. Please include your name, your 
                            organization name, contact information (such as a phone number and/or e-mail address) as well as the URL 
                            of your site, a list of any URLs from which you intend to link to our Web site, and a list of the URL(s) 
                            on our site to which you would like to link. Allow 2-3 weeks for a response.</p>
                        <p>Approved organizations may hyperlink to our Web site as follows:</p>
                        <p>By use of our corporate name; or<br/>
                           By use of the uniform resource locator (Web address) being linked to; or<br/>
                           By use of any other description of our Web site or material being linked to that makes sense within the 
                           context and format of content on the linking party’s site.</p>
                        <p>No use of Espay Pty Ltd.’s logo or other artwork will be allowed for linking absent a trademark license 
                            agreement.</p>

                        <h4>Iframes</h4>
                        <p>Without prior approval and express written permission, you may not create frames around our Web pages or 
                            use other techniques that alter in any way the visual presentation or appearance of our Web site</p>

                        <h5>Reservation of Rights</h5>
                        <p>We reserve the right at any time and in its sole discretion to request that you remove all links or any 
                            particular link to our Website. You agree to immediately remove all links to our Web site upon such 
                            request. We also reserve the right to amend these terms and conditions and its linking policy at any time. 
                            By continuing to link to our Web site, you agree to be bound to and abide by these linking terms and 
                            conditions.</p>

                        <h5>Removal of links from our website</h5>
                        <p>If you find any link on our Web site or any linked web site objectionable for any reason, you may contact 
                            us about this. We will consider requests to remove links but will have no obligation to do so or to 
                            respond directly to you.</p>
                        <p>Whilst we endeavour to ensure that the information on this website is correct, we do not warrant its 
                            completeness or accuracy; nor do we commit to ensuring that the website remains available or that the 
                            material on the website is kept up to date.</p>

                        <h5>Content Liability</h5>
                        <p>We shall have no responsibility or liability for any content appearing on your Web site. You agree to 
                            indemnify and defend us against all claims arising out of or based upon your Website. No link(s) may 
                            appear on any page on your Web site or within any context containing content or materials that may be 
                            interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the 
                            infringement or other violation of, any third party rights.</p>

                        <h5>Prohibited Activity</h5>
                        <p>You are prohibited from doing any act that espay.exchange, in its reasonable discretion, may deem to be 
                            inappropriate and/or would be deemed to be an unlawful act or is prohibited by any laws, ordinances, 
                            rules, regulations, codes and codes of all regulatory bodies applicable to this website including but 
                            not limited to:</p>
                        <ul>
                            <li>Any act that would constitute a breach of either the privacy (including uploading private information 
                                without the concerned individual’s consent) or any other of the legal rights of individuals;</li>
                            <li>Using this website to defame Espay Pty Ltd., or its employees or other individuals or acting in such 
                                a way that brings into disrepute the good name of our organization.</li>
                            <li>Uploading files that contain viruses that may cause damage to the property of Espay Pty. Ltd. or the 
                                property of other individuals.</li>
                        </ul>
                        <p>You and Espay Pty. Ltd. agree that any controversy or claim arising from or pertaining to the use of this 
                            website shall be governed by the laws of New South Wales. You submit to the non-exclusive jurisdiction 
                            of the courts of New South Wales.</p>

                        <h6>Disclaimer</h6>
                        <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and 
                            conditions relating to our website and the use of this website (including, without limitation, any 
                            warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of 
                            reasonable care and skill). Nothing in this disclaimer will:</p>
                        <ul>
                            <li>limit or exclude our or your liability for death or personal injury resulting from negligence;</li>
                            <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                            <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                            <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                        </ul>


                        <h6>Termination</h6>
                        <p>We reserve the right to restrict or terminate your access to this website or any feature of this website 
                            or any feature or part thereof at any time. Any indemnities given by you and limitations on our liability 
                            will survive such termination. Any termination of your right to use or access any part of this website 
                            will not affect any rights which have accrued to or have been accrued by either you or us prior to 
                            termination.</p>

                        <h6>Contact Information</h6>
                        <p>If you have any questions or queries in relation to this website or these Terms and Conditions, please 
                            contact us (our contact details are set out on our Contact Us page.</p>

                    </Col>
                </Row> 
        </div>
      );
    }
}

export default welcome_section1;