import React, { Component } from 'react';
import { Row, Col, Jumbotron} from 'reactstrap';
import { Link } from "react-router-dom";
//import ExchangeDevelopment1 from '../../Images/ExchangeDevelopment1.png';

class Banner extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm={{size:9,offset:1}} xs={{size:11,offset:0}}>                             
                    <Jumbotron>
                        <div className="bannerdetails">
                            <h1>Decentralized Crypto Exchange Software</h1>
                            <p>Real-time Cryptocurrency Trading experience for your customers with a   
                               Decentralized Exchange platform from Espay. </p>
                            <Link to="/talk-to-crypto-exchange-expert">Talk To Our Experts</Link>
                        </div>
                    </Jumbotron>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default Banner;