import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import TheFinancialCenter from '../../../Images/faq/TheFinancialCenter.png';

export default class STFinancialcenter extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">How Does The Security Token Work With The Financial Center?</h2> 
                            <img src={TheFinancialCenter} alt="Financial Center" title="Financial Center" className="img-fluid faqimg"/>
                            <p>The hype behind security tokens these days is immense. Whether or not they are worth your time and the noise (hype) is dependent on how well they serve within the tenets to the value of a token- that is role, features, and purpose. A security token is simply a crypto token which passes the Howey Test, and their value is mostly derived from a tradable and external asset. As long as they can meet every concerned regulation, they use-cases remain incredible.</p>
                            <p>Someone might now want to ask what this has to do with financial centers. Really, their characteristic to represent assets in the real world make them some kind of bridge between the blockchain world and legacy finance. Specifically, the actual changes being brought to the world of finance are: restoration of credibility, fast execution and improved traditional finance. Ideally, traditional financial transactions can be somewhat expensive because of the associated fees.</p>
                            <p>Moreover, exposure to free market and increase in number of investors signals security token use cases in the financial context. That is, tokenizing securities is capable of turning non-securities into securities. With 2019 being a year of anticipated blockchain infusion, security tokens investment are increasing.</p>
                            <p>Since financial services form about 15% of the world economy, it is expected that significant part of the sector will undergo necessary digitization and integration. And in few months time from now, activities of issuers and investors under some of the known global securities regulation, disclosure practices, and evolving regulation of the blockchain system as well as smart contract-auditing procedures would be enormously stepped up.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
