import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import tradingimg from '../../../../Images/IMG_13112018_154123_0.png';

class trading_chart_section1 extends Component {
    render() {
      return (
        <div> 
            <Row> 
                <Col sm="6">
                    <div className="tradingcharttitle">                     
                        <p>Espay is an advanced exchange development solution provider with the ease of use of an advanced trading platform. Whether you need at basic price charts/plotting complex spread symbols with overlayed strategy backtesting, Espay has the tools and data you require.</p>
                        <p>Communicate with millions of traders from each corner of the world, talk over trading ideas as well as place live orders with <Link to="/" title="Espay Exchange"> <strong>Espay’s digital asset exchange platform. </strong></Link> Deliver an unparalleled trading experience to your customers with different devices.</p>                       
                    </div>
                </Col>
                <Col sm="6" className="mt-30">
                    <img className="img-fluid" src={tradingimg} alt="TRADING CHART FOR EXCHANGE" 
                    title="TRADING CHART FOR EXCHANGE" />
                </Col>                  
            </Row>          
        </div>
      );
    }
}

export default trading_chart_section1;