import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import Futuresimg from '../../Images/Derivatives/FuturesTrading.png';

class DerivativeFeature extends Component {
    render() {
        return (
            <div className="">
                <Row>
                    <Col sm="12">
                        <h2>The Core Set of Features for your end users:</h2>
                    </Col>
                </Row>
                <Row>
                    <Col sm="3" className="offset-sm-1">
                        <div className="futures-ueser">
                            <p className="future-circle">
                                <span>1</span>
                            </p>
                            <p className="futures-text">Having a better risk control and margin calculation for all derivatives products alongside partial orders and executions.</p>
                        </div>
                    </Col>

                    <Col sm="3">
                        <div className="futures-ueser">
                            <p className="future-circle">
                                <span>2</span>
                            </p>
                            <p className="futures-text">It allows you to run these calculations in real-time to avoid the margin calls and liquidation changes</p>
                        </div>
                    </Col>

                    <Col sm="3">
                        <div className="futures-ueser">
                            <p className="future-circle">
                                <span>3</span>
                            </p>
                            <p className="futures-text">A realtime VAR computing system for all the portfolios.</p>
                        </div>
                    </Col>
                </Row>
                <Row>

                    <Col sm="3"  className="offset-sm-1">
                        <div className="futures-ueser">
                            <p className="future-circle">
                                <span>4</span>
                            </p>
                            <p className="futures-text">A complete cloud-enabled solution for optimum scalability and easy management.</p>
                        </div>
                    </Col>

                    <Col sm="3">
                        <div className="futures-ueser">
                            <p className="future-circle">
                                <span>5</span>
                            </p>
                            <p className="futures-text">Provides a real-time rules engine to regulate trades.</p>
                        </div>
                    </Col>

                    <Col sm="3">
                        <div className="futures-ueser">
                            <p className="future-circle">
                                <span>6</span>
                            </p>
                            <p className="futures-text">A standalone calculation engine with modular capability for integration in any system.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="3"  className="offset-sm-1">
                        <div className="futures-ueser">
                            <p className="future-circle">
                                <span>7</span>
                            </p>
                            <p className="futures-text">Grid computing enabled the engine to ensure the rapid results for even the most complex calculations.</p>
                        </div>
                    </Col>

                    <Col sm="3">
                        <div className="futures-ueser">
                            <p className="future-circle">
                                <span>8</span>
                            </p>
                            <p className="futures-text">On the fly risk analytics to traders and market makers.</p>
                        </div>
                    </Col>

                    <Col sm="3">
                        <div className="futures-ueser">
                            <p className="future-circle">
                                <span>9</span>
                            </p>
                            <p className="futures-text">Ability to calculate the “Worst Net Position”, a difference between total long positions and total short positions in the same asset.</p>
                        </div>
                    </Col>

                </Row>
                <p className="text-white Core-Features-p"><b>Who can avail the most out of us? We are apropos for the enterprises that have a unique yet innovative financial markets technology to stay ahead of the competitors. </b></p>

            </div>
        );
    }
}

export default DerivativeFeature;