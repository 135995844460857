import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";
import { Card, CardImg,CardBody } from 'reactstrap';
import blog1  from '../../../../Images/blog/Blockchain-Empowered-Equity-Exchange.png';
import blog2  from '../../../../Images/blog/center.png';
import blog3  from '../../../../Images/blog/Untitled.png'

class blog extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12"> 
                    <div className="blogcommontitle">
                        <h3>Our Insight</h3>
                    </div>                      
                </Col>
            </Row>  
            <Row className="commonblog">
                <Col sm="4">                         
                    <Card className="carddiv">
                        <CardImg top width="100%" src={blog1} alt="Introduction To Blockchain Empowered Equity Exchange"  title="Introduction To Blockchain Empowered Equity Exchange" />
                        <CardBody>
                            <a target="_blank" rel="noopener noreferrer" href="https://espay.exchange/blog/guidelines-for-cryptocurrency-exchange-development-in-asian-countries/" className="card-title">Introduction To Blockchain Empowered Equity Exchange</a>                        
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="4">                         
                    <Card className="carddiv">
                        <CardImg top width="100%" src={blog2} alt="Evolution of Cryptocurrency Exchange" title="Evolution of Cryptocurrency Exchange" />
                        <CardBody>
                            <a target="_blank" rel="noopener noreferrer" href="https://espay.exchange/blog/undisputable-benefits-centralized-exchange/" className="card-title">Evolution of Cryptocurrency Exchange</a>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="4">                         
                    <Card className="carddiv">
                        <CardImg top width="100%" src={blog3} alt="History of Cryptocurrency Exchange"  title="History of Cryptocurrency Exchange"/>
                        <CardBody>
                         <a target="_blank" rel="noopener noreferrer" href="https://espay.exchange/blog/crypto-currency-exchange-market/" className="card-title">History of Cryptocurrency Exchange</a>
                        </CardBody>                    
                    </Card>
                </Col>
            </Row>         
        </div>
      );
    }
}

export default blog;