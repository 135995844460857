import React, { Component } from 'react';
import {Row,Col} from 'reactstrap';

import FutureUsageimg from '../../Images/MarketingResearch/10-trillion-img.png';

export default class FutureUsageSTE extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm={12}>
                        <div className="futureusage-title">
                            <span>Future Usage of Security Token Exchanges</span>
                        </div>
                    </Col>
                </Row>
                <Row className="futureusage-box">
                    <Col sm={8} className="order-2 order-md-1">
                        <div className="futureusage-details">
                            <span>Expected Trading Volume</span>
                            <p>Various experts have projected different growth expectations from the security token exchange market. Lou Kerner, Founding Partner of CryptoOracle and a notable angel investor known for investing in Facebook, thinks that security token market cap will top $10 trillion over the next seven to ten years.</p>
                            <p>On the other hand, Carlos Domingo, CEO of Securitize, a security token development company, believes the security token market will continue to expand in 2019, possibly growing 10 times its current size.</p>
                        </div>
                    </Col>
                    <Col sm={4} className="order-md-2">
                        <img className="img-fluid" src={FutureUsageimg} alt="Expected Trading Volume" title="Expected Trading Volume" />
                    </Col>
                </Row>
                <Row className="futureusage-box">
                    <Col sm={4}>
                        <img className="img-fluid" src={FutureUsageimg} alt="Market Capitalization" title="Market Capitalization" />
                    </Col>
                    <Col sm={8}>
                        <div className="futureusage-details">
                            <span>Market Capitalization</span>
                            <p>Currently, the global stock exchange market is estimated to have a capitalization of around $80 trillion. If the security token market captures about 10% of this traditional market, we are looking at a security token exchange market of worth $8 trillion at least.</p>
                            <p>When it comes to the tokenization of different sectors, the global real estate market alone was worth $280.6 trillion in 2017. Tokenization of just one-fourth (25%) of this market alone will introduce nearly $70 trillion in the security token market cap.</p>
                        </div>
                    </Col>
                </Row>
                <Row className="futureusage-box">
                    <Col sm={8} className="order-2 order-md-1">
                        <div className="futureusage-details">
                            <span>Expected CAGR for the Next 5 Years</span>
                            <p>The acceptance of tokenization of various assets and services is on the rise despite the lack of knowledge about security tokens. According to a recent report by Research & Markets, the tokenization market size is estimated to grow from $983 million in 2018 to $2,670 million by 2023, at a Compound Annual Growth Rate (CAGR) of 22.1% during the forecast period.</p>
                            <p>Essentially, as the number of tokenized assets grows, the demand for exchanges to list and trade these security tokens will also increase.</p>
                        </div>
                    </Col>
                    <Col sm={4} className="order-md-2">
                        <img className="img-fluid" src={FutureUsageimg} alt="Expected CAGR for the Next 5 Years" title="Expected CAGR for the Next 5 Years" />
                    </Col>
                </Row>
            </div>
        )
    }
}
