import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import Ecuador from '../../../Images/faq/Ecuador.jpg';

export default class STEcuador extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is the state of security tokens in Ecuador?</h2> 
                            <img src={Ecuador} alt="Ecuador" title="Ecuador" className="img-fluid faqimg"/>
                            <p>The Central Bank of Ecuador has stated that Bitcoin is not an authorized payment method in Ecuador. It further clarified that the bitcoin, as a cryptocurrency, is not backed by any authority, because its value is based merely on speculation.Furthermore, financial transactions with bitcoins are not controlled, supervised, or regulated by any Ecuadoran entity, and therefore they represent a financial risk for those who invest in them.</p>
                            <p>The Central Bank also stated, however, that the purchase and sale of cryptocurrencies such as bitcoin through the internet are not forbidden, but it reiterated that bitcoin is not legal tender and is not an authorized payment method for goods and services according to the Código Orgánico Monetario y Financiero (Organic Monetary and Financial Code).</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
