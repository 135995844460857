import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TradingSummary from '../../../../Images/Trading-Summary.png';
import margintrading from '../../../../Images/margin_trading.png';
import Deposition from '../../../../Images/Deposition.png';
import withdraw from '../../../../Images/withdraw.png';
import NEWSFEEDS from '../../../../Images/NEWS-FEEDS.png';
import decentralizedtrading from '../../../../Images/decentralized_trading.png';
import Earning from '../../../../Images/Earning.png';
import SettledOrder from '../../../../Images/Settled-Order.png'; 
import wallet from '../../../../Images/wallet.png';
import PushNotificationQueue from '../../../../Images/Push-Notification-Queue.png';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserPlus, faHome, faShoppingCart, faEnvelope, faEnvelopeOpen, faAddressBook} from '@fortawesome/free-solid-svg-icons';
library.add(faUserPlus, faHome, faShoppingCart, faEnvelope, faEnvelopeOpen, faAddressBook);
   
class Trading_Section2 extends Component {
    render() {
      return (
        <div> 
            <Row> 
                <Col sm="12" md="6" lg="4">
                    <div className="Trading_Service">
                        <Row>
                            <Col sm="2"> 
                                <FontAwesomeIcon icon="user-plus" size="2x" />
                            </Col>
                            <Col sm="10">
                                <h4>Sign Up report</h4>
                                <p>View a list of all the sign-ups performed by traders on your crypto trading platform with the help of Espay’s exchange development services.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <div className="Trading_Service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={TradingSummary} alt="TradingSummary" title="TradingSummary" /> 
                            </Col>
                            <Col sm="10">
                                <h4>Trading Summary</h4>
                                <p>Espay provides a daily trading summary for your reference that will aid you to identify all purchases and dispositions, list of purchase/sales prices, dates and much more occurring on your trading platform.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <div className="Trading_Service">
                        <Row>
                            <Col sm="2"> 
                               <img className="img-fluid" src={margintrading} alt="margintrading" title="margintrading" /> 
                            </Col>
                            <Col sm="10">
                                <h4>Trading Ledger</h4>
                                <p>Trading ledger from Espay let you store number of buy & sell orders created by your traders and merchants on crypto trading platform.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <div className="Trading_Service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={Deposition} alt="Deposition" title="Deposition" />
                            </Col>
                            <Col sm="10">
                                <h4>Deposition Report</h4>
                                <p>View a list of all the deposited funds to your traders’ trading accounts on your crypto trading platform with the help of digital asset exchange software from Espay.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <div className="Trading_Service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={withdraw} alt="withdraw" title="withdraw" /> 
                            </Col>
                            <Col sm="10">
                                <h4>Withdrawal Report</h4>
                                <p>View a list of all the fund withdrawal from your trader’s trading account with the help pf withdrawal report offered by Espay.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <div className="Trading_Service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={NEWSFEEDS} alt="NEWSFEEDS" title="NEWSFEEDS" />
                            </Col>
                            <Col sm="10">
                                <h4>Referral Reports</h4>
                                <p>View the report of all your referral history, see how many referrals you are getting by utilizing crypto exchange software delivered by exchange development company Espay.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <div className="Trading_Service">
                        <Row>
                            <Col sm="2"> 
                                <FontAwesomeIcon icon="home" size="2x" />
                            </Col>
                            <Col sm="10">
                                <h4>Charges Collected</h4>
                                <p>Espay let you view the report on overall exchange transaction charges collected by your crypto trading platform.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <div className="Trading_Service">
                        <Row>
                            <Col sm="2"> 
                                <FontAwesomeIcon icon="shopping-cart" size="2x" />
                            </Col>
                            <Col sm="10">
                                <h4>Open Orders</h4>
                                <p>Leverage best out of Espay’s crypto exchange software and let your traders make open orders to buy/sell a security that remains in effect until get cancelled/executed/expired.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <div className="Trading_Service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={decentralizedtrading} alt="decentralizedtrading" title="decentralizedtrading" /> 
                            </Col>
                            <Col sm="10">
                                <h4>Organization Ledger</h4>
                                <p>Manage all the accounts for recording transactions relating to your crypto assets, liabilities, expenses as well as revenue into organization ledger offered by Espay.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <div className="Trading_Service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={wallet} alt="wallet" title="wallet" />  
                            </Col>
                            <Col sm="10">
                                <h4>Wallet Report</h4>
                                <p>Let your traders/merchants to view and analyze the performance of their crypto wallet by providing wallet report with Espay’s exchange development solutions.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <div className="Trading_Service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={PushNotificationQueue} alt="PushNotificationQueue" title="PushNotificationQueue" /> 
                            </Col>
                            <Col sm="10">
                                <h4>Push Notification Queue</h4>
                                <p>By utilizing crypto trading platform from Espay, any push notification that is not delivered immediately was queued for the future.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <div className="Trading_Service">
                        <Row>
                            <Col sm="2"> 
                                <FontAwesomeIcon icon="envelope" size="2x" />
                            </Col>
                            <Col sm="10">
                                <h4>Emails Queue</h4>
                                <p>Get email queuing ability with Espay’s cryptocurrency exchange solutions that save emails in a queue for the future if traders can’t associate with each other at the exact time.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <div className="Trading_Service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={SettledOrder} alt="SettledOrder" title="SettledOrder" /> 
                            </Col>
                            <Col sm="10">
                                <h4>Settled Order</h4>
                                <p>Rules of trading, clearing and settlement of transactions should be very clear to avoid misunderstandings. Espay ensures rapid dealings and prompt settlements by its crypto trading platform.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <div className="Trading_Service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={Earning} alt="Earning" title="Earning" /> 
                            </Col>
                            <Col sm="10">
                                <h4>Earning Ledger</h4>
                                <p>Serve your traders with the earning ledger and let them store their earning within the secure ledger offered by Espay’s digital asset exchange platform.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <div className="Trading_Service">
                        <Row>
                            <Col sm="2"> 
                                <FontAwesomeIcon icon="address-book" size="2x" />
                            </Col>
                            <Col sm="10">
                                <h4>List Daemon Address</h4>
                                <p>List out all the daemon address that aid your users to send and receive blockchain based wallet key offered by Espay’s crypto trading platform.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>          
        </div>
      );
    }
}

export default Trading_Section2;