import React from "react";
import ReactGA from 'react-ga';
import { Navbar, NavbarBrand, NavbarNav, NavItem, NavLink, NavbarToggler, Collapse } from "mdbreact";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import EspayExchangeLogo from '../Images/EspayExchangeLogo.png';
//import profileheader from '../Images/profileheader.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowDown, faSortDown, faMobileAlt, faEnvelope, } from '@fortawesome/free-solid-svg-icons';
library.add(faArrowDown, faSortDown, faMobileAlt, faEnvelope);



class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: ""
    }
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.STOtoggle = this.STOtoggle.bind(this);
    this.IEOtoggle = this.IEOtoggle.bind(this);
    this.ICOtoggle = this.ICOtoggle.bind(this);
    this.exchangetoggle = this.exchangetoggle.bind(this);
    this.featurestoggle = this.featurestoggle.bind(this);
    this.companytoggle = this.companytoggle.bind(this);
    this.Derivativetoggle = this.Derivativetoggle.bind(this);

    this.state = {
      collapsed: true,
      collapsedexchange: true,
      collapsedfeatures: true,
      collapsedcompany: true,
      collapsedSTO: true,
      collapsedIEO: true,
      collapsedICO: true,
      collapsedDerivative: true,
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }
  exchangetoggle() {
    this.setState({
      collapsedexchange: !this.state.collapsedexchange
    });
  }
  STOtoggle() {
    this.setState({
      collapsedSTO: !this.state.collapsedSTO
    });
  }

  IEOtoggle() {
    this.setState({
      collapsedIEO: !this.state.collapsedIEO
    });
  }
  ICOtoggle() {
    this.setState({
      collapsedICO: !this.state.collapsedICO
    })
  }
  Derivativetoggle() {
    this.setState({
      collapsedDerivative: !this.state.collapsedDerivative
    })
  }


  featurestoggle() {
    this.setState({
      collapsedfeatures: !this.state.collapsedfeatures
    });
  }
  companytoggle() {
    this.setState({
      collapsedcompany: !this.state.collapsedcompany
    });
  }

  componentWillMount() {
    var str = window.location.href
    var res = str.split("/");
    this.setState({
      url: res[3]
    })
  }

  handleClick() {
    ReactGA.event({
      category: 'Click to Email',
      action: 'mailto:hello@espay.exchange',
      label: 'Clicked on Header email - hello@espay.exchange',
    });
  }
  contactClick() {
    ReactGA.event({
      category: 'Click to Call',
      action: 'tel://+61 (04) 8884-3353',
      label: 'Clicked on Top Header call - +61 (04) 8884-3353',
    });
  }

  render() {
    return (
      <div>
        {/* <div className={(this.state.url === 'crypto-exchange-software' 
       || this.state.url === 'crypto-exchange-software#TalkToExpert') 
       ? "CryptoExchangeSolutionMenu" : "allmenu"}> */}

        <div className="headermenu">
          <div className="topheader">
            <Container>
              <Row>
                <Col md={5} sm={7}>
                  <div className="headerinfo">
                    <FontAwesomeIcon icon="envelope" size="1x" />
                    <a href="mailto:hello@espay.exchange" className="footerbtn" onClick={() => { this.handleClick() }} >
                      hello@espay.exchange
                          </a>
                  </div>
                  <div className="headerinfo">
                    <FontAwesomeIcon icon="mobile-alt" size="1x" />
                    <a href="tel://+61 (04) 8884-3353" className="footerbtn" onClick={() => { this.contactClick() }} >
                      +61 (04) 8884-3353
                          </a>
                  </div>
                </Col>
                <Col md={5} sm={7}>

                </Col>
                <Col md="2 text-right" sm={5}>
                  <div className="CryptoExchangeSolutionfreebtn">
                    {/* <div className="userheadricon">
                              <img src={profileheader} alt="Book FREE Demo" title="Book FREE Demo" />
                            </div> */}
                    <Link className="" to="/contact-us">Book FREE Demo</Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Container>
            {/* <Navbar dark expand="md" className="allpagemenu">
                <NavbarBrand>
                  <img itemProp="logo" src={EspayExchangeLogo} alt="Espay Exchange" title="Espay Exchange" width="200px" />
                </NavbarBrand>
                <NavbarNav right className="bookdemobtnheader">
                  <a href="#TalkToExpert">Book Free Demo with Expert</a>
                </NavbarNav>
              </Navbar> */}
            <Navbar dark expand="md" className="dropmenu">
              <NavbarBrand itemScope itemType="http://schema.org/Organization">
                <NavLink itemProp="url" to="/"><img itemProp="logo" src={EspayExchangeLogo} alt="Espay Exchange" title="Espay Exchange" /></NavLink>
              </NavbarBrand>

              <NavbarNav right className="CryptoExchangeMenu" itemScope itemType="http://www.schema.org/SiteNavigationElement">
                {/* <NavItem active>
                    <NavLink to="/">Home</NavLink>
                  </NavItem> */}
                <NavItem className="submenumain">

                  <NavLink to="/">Exchange<i /></NavLink>
                  <div className="innermenu ">
                    {/* className="innermenu innermenufirst" */}
                    <Row>
                      <Col sm={12}>
                        <ul>
                          <li itemProp="name"><Link itemProp="url" to="/crypto-exchange-software">Crypto Exchange</Link></li>
                          <li>
                            <ul>
                              <li itemProp="name" className="innertitle"><Link itemProp="url" to="/centralized-exchange-solutions">Centralized</Link></li>
                              <li>
                                <ul>
                                  <li itemProp="name"><Link itemProp="url" to="/white-label-crypto-exchange-software">WhiteLabel</Link></li>
                                  <li itemProp="name"><Link itemProp="url" to="/centralized-business-exchange-software">Standard</Link></li>
                                  <li itemProp="name"><Link itemProp="url" to="/centralized-enterprise-exchange-software">Enterprise</Link></li>
                                  <li itemProp="name"><Link itemProp="url" to="/leverage-margin-trading-exchange">Leverage Margin Trading Exchange</Link></li>
                                </ul>
                              </li>
                              <li itemProp="name" className="innertitle"><Link itemProp="url" to="/decentralized-crypto-exchange-software">Decentralized</Link></li>
                              <li itemProp="name" className="innertitle"><Link itemProp="url" to="/hybrid-crypto-exchange-software">Hybrid</Link></li>
                              {/* <li>
                                <ul>
                                  <li itemProp="name"><Link itemProp="url" to="/hybrid-crypto-exchange-software">Hybrid</Link></li>
                                </ul>
                              </li> */}
                              <li itemProp="name" className="innertitle"><Link itemProp="url" to="/p2p-exchange-software-development">P2P Exchange</Link></li>
                              <li itemProp="name" className="innertitle"><Link itemProp="url" to="/otc-trading-desk-development">OTC Desk</Link></li>
                              <li itemProp="name" className="innertitle"><Link itemProp="url" to="/mobile-trading-terminal">Mobile Trading Terminal</Link></li>
                            </ul>
                          </li>
                        </ul>
                      </Col>

                    </Row>
                  </div>
                </NavItem>
                <NavItem itemProp="name" className="submenumain">
                  <NavLink to="/">STO<i /></NavLink>
                  <div className="innermenu">
                    <Row>
                      <Col sm={12}>
                        <ul>
                          <li></li>
                          <li>
                            <ul>
                              <li itemProp="name" className="innertitle"><Link itemProp="url" to="/security-token-exchange">Security Token Exchange</Link></li>
                              <li itemProp="name" className="innertitle"><Link itemProp="url" to="/security-token-offering-advice">STO Advice</Link></li>
                              <li itemProp="name" className="innertitle"><Link itemProp="url" to="/security-token-offering-development">STO Programming</Link></li>
                              <li itemProp="name" className="innertitle"><Link itemProp="url" to="/security-token-offering-marketing">STO Marketing</Link></li>
                              <li itemProp="name" className="innertitle"><Link itemProp="url" to="/security-token-offering-legal">STO Legal</Link></li>
                            </ul>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </NavItem>


                {/* <NavItem itemProp="name" className="submenumain">
                  <NavLink to="/">IEO<i /></NavLink>
                  <div className="innermenu">
                    <Row>
                      <Col sm={12}>
                        <ul>
                          <li></li>
                          <li>
                            <ul>
                              <li itemProp="name" className="innertitle"><Link itemProp="url" to="/ieo-consulting-services">IEO Consulting Services</Link></li>
                            </ul>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </NavItem> */}

                {/* <NavItem itemProp="name" className="submenumain">
                  <NavLink to="/">ICO<i /></NavLink>
                  <div className="innermenu">
                    <Row>
                      <Col sm={12}>
                        <ul>
                          <li></li>
                          <li>
                            <ul>
                              <li itemProp="name" className="innertitle"><Link itemProp="url" to="/ico-marketing">ICO Marketing</Link></li>
                            </ul>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </NavItem> */}



                <NavItem itemProp="name" className="submenumain">
                  <NavLink to="/">Derivative<i /></NavLink>
                  <div className="innermenu">
                    <Row>
                      <Col sm={12}>
                        <ul>
                          <li></li>
                          <li>
                            <ul>
                            <li itemProp="name" className="innertitle"><Link itemProp="url" to="/cryptocurrency-derivatives-exchange-development">Derivative Exchange Software</Link></li>                              
                            <li itemProp="name" className="innertitle"><Link itemProp="url" to="/derivatives-trading-platform">Derivatives Trading Platform</Link></li>                              
                              {/* <li itemProp="name" className="innertitle"><Link itemProp="url" to="/crypto-payment-gateway">Crypto Payment Gateway</Link></li> */}                              
                            </ul>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </NavItem>

                <NavItem itemProp="name" className="submenumain">
                  <NavLink itemProp="url" to="/">Features<i /></NavLink>
                  <div className="innermenu">
                    <Row>
                      <Col sm={12}>
                        <ul>
                          <li></li>
                          <li>
                            <ul>
                              <li itemProp="name" className="innertitle"><Link itemProp="url" to="">Front End Features</Link></li>
                              <li>
                                <ul>
                                  <li itemProp="name"><Link itemProp="url" to="/security-modules-and-features">Security Modules And Features</Link></li>
                                  <li itemProp="name"><Link itemProp="url" to="/exchange-ui-design">Exchange UI Design</Link></li>
                                  <li itemProp="name"><Link itemProp="url" to="/Trading-dashboard">Trading Dashboard</Link></li>
                                  <li itemProp="name"><Link itemProp="url" to="/dyanmic-cms-for-managing">Dynamic CMS Management</Link></li>
                                  <li itemProp="name"><Link itemProp="url" to="/mobile-trading-platform">Mobile Trading Platform</Link></li>
                                  <li itemProp="name"><Link itemProp="url" to="/mass-communication-features">Mass Communication</Link></li>
                                  <li itemProp="name"><Link itemProp="url" to="/trading-chart-for-exchange">Trading Chart</Link></li>
                                  <li itemProp="name"><Link itemProp="url" to="/wallet-management">Wallet Management</Link></li>
                                  <li itemProp="name"><Link itemProp="url" to="/marketing-features">Marketing Features</Link></li>
                                  <li itemProp="name"><Link itemProp="url" to="/trading-market-setup">Trading Market Setup</Link></li>
                                </ul>
                              </li>
                              <li itemProp="name" className="innertitle"><Link itemProp="url" to="">Back End Features</Link></li>
                              <li>
                                <ul>
                                  <li itemProp="name"><Link itemProp="url" to="/integrations">API Integrations</Link></li>
                                  <li itemProp="name"><Link itemProp="url" to="/trading-report-management">Trading Report Management</Link></li>
                                  <li itemProp="name"><Link itemProp="url" to="/currency-and-pairs">Currency Pair Managment</Link></li>
                                  <li itemProp="name"><Link itemProp="url" to="/trading-and-reconciliation">Trading And Reconciliation</Link></li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </NavItem>

                <NavItem itemProp="name" className="submenumain">
                  <NavLink itemProp="url" to="/">Company<i /></NavLink>
                  <div className="innermenu">
                    <Link itemProp="url" to="/vision-mission">Vision & Mission</Link>
                    <Link itemProp="url" to="/about-us">About</Link>
                  </div>
                </NavItem>



                <NavItem itemProp="name">
                  <a itemProp="url" href="https://espay.exchange/blog/">Blog</a>
                </NavItem>

                <NavItem itemProp="name">
                  <Link itemProp="url" to="/invest-in-whitelabel-cryptocurrency-exchange">Investor</Link>
                </NavItem>

                <NavItem itemProp="name">
                  <NavLink itemProp="url" to="/contact-us">Contact Us</NavLink>
                </NavItem>
              </NavbarNav>
            </Navbar>
          </Container>
        </div>



        <div className="mobileheadermenu">
          <div className="topheader">
            <Container>
              <Row>
                <Col sm={12}>
                  <div className="headerinfo">
                    <FontAwesomeIcon icon="envelope" size="1x" />
                    <a href="mailto:hello@espay.exchange" className="footerbtn" onClick={() => { this.handleClick() }} >
                      hello@espay.exchange
                              </a>
                  </div>
                  <div className="headerinfo">
                    <FontAwesomeIcon icon="mobile-alt" size="1x" />
                    <a href="tel://(+61) 421-514-353" className="footerbtn" onClick={() => { this.contactClick() }} >
                      (+61) 421-514-353
                              </a>
                  </div>
                </Col>
                <Col md="12 text-center" >
                  <div className="CryptoExchangeSolutionfreebtn">
                    {/* <div className="usericonmobile">
                                  <img src={profileheader} alt="Book FREE Demo With Expert" title="Book FREE Demo With Expert" />
                                </div> */}
                    <Link className="" to="/contact-us">Book FREE Demo</Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Navbar dark expand="md" className="dropmenu">
            <NavbarBrand itemScope itemType="http://schema.org/Organization">
              <NavLink itemProp="url" to="/"><img itemProp="logo" src={EspayExchangeLogo} alt="Espay Exchange" title="Espay Exchange" /></NavLink>
            </NavbarBrand>

            <NavbarToggler
              onClick={this.toggleNavbar}
            />
          </Navbar>
          <Collapse
            isOpen={!this.state.collapsed}
            navbar
          >
            <NavbarNav right itemScope itemType="http://www.schema.org/SiteNavigationElement">

              {/* <NavItem>
                  <NavLink to="/">Home</NavLink>
                </NavItem> */}
              <NavItem itemProp="name" className="submenumain">
                <NavLink itemProp="url" to="/" onClick={this.exchangetoggle}>Exchange <FontAwesomeIcon icon="sort-down" size="1x" /></NavLink>
                <Collapse
                  isOpen={!this.state.collapsedexchange}
                >
                  <Row>
                    <Col sm={6}>
                      <ul>
                        <li itemProp="name"><Link itemProp="url" to="/crypto-exchange-software" onClick={this.toggleNavbar}>Crypto Exchange</Link></li>
                        <li>
                          <ul>
                            <li itemProp="name" className="innertitle"><Link itemProp="url" to="/centralized-exchange-solutions" onClick={this.toggleNavbar}>Centralized</Link></li>
                            <li>
                              <ul>
                                <li itemProp="name"><Link itemProp="url" to="/white-label-crypto-exchange-software" onClick={this.toggleNavbar}>WhiteLabel</Link></li>
                                <li itemProp="name"><Link itemProp="url" to="/centralized-business-exchange-software" onClick={this.toggleNavbar}>Standard</Link></li>
                                <li itemProp="name"><Link itemProp="url" to="/centralized-enterprise-exchange-software" onClick={this.toggleNavbar}>Enterprise</Link></li>
                                <li itemProp="name"><Link itemProp="url" to="/leverage-margin-trading-exchange" onClick={this.toggleNavbar}>Leverage Margin Trading Exchange</Link></li>
                              </ul>
                            </li>
                            <li itemProp="name" className="innertitle"><Link to="/decentralized-crypto-exchange-software" onClick={this.toggleNavbar}>Decentralized</Link></li>


                            <li itemProp="name" className="innertitle"><Link to="/hybrid-crypto-exchange-software" onClick={this.toggleNavbar}>Hybrid</Link></li>


                            <li itemProp="name" className="innertitle"><Link to="/p2p-exchange-software-development" onClick={this.toggleNavbar}>P2P Exchange</Link></li>
                          </ul>
                        </li>
                      </ul>
                    </Col>
                    
                  </Row>

                </Collapse>

                <NavLink itemProp="url" to="/" onClick={this.STOtoggle} >STO <FontAwesomeIcon icon="sort-down" size="1x" /> </NavLink>
                <Collapse
                  isOpen={!this.state.collapsedSTO}
                >
                  <Row>
                    <Col sm={12}>
                      <ul>
                        <li></li>
                        <li>
                          <ul>
                            <li itemProp="name" className="innertitle"><Link itemProp="url" onClick={this.toggleNavbar} to="/security-token-exchange" >Security Token Exchange</Link></li>
                            <li itemProp="name" className="innertitle"><Link itemProp="url" onClick={this.toggleNavbar} to="/security-token-offering-advice">STO Advice</Link></li>
                            <li itemProp="name" className="innertitle"><Link itemProp="url" onClick={this.toggleNavbar} to="/security-token-offering-development">STO Programming</Link></li>
                            <li itemProp="name" className="innertitle"><Link itemProp="url" onClick={this.toggleNavbar} to="/security-token-offering-marketing">STO Marketing</Link></li>
                            <li itemProp="name" className="innertitle"><Link itemProp="url" onClick={this.toggleNavbar} to="/security-token-offering-legal">STO Legal</Link></li>
                          </ul>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Collapse>
              </NavItem>

              <NavItem itemProp="name" className="submenumain">
                <NavLink to="/" onClick={this.IEOtoggle}>IEO <FontAwesomeIcon icon="sort-down" size="1x" /></NavLink>
                <Collapse
                  isOpen={!this.state.collapsedIEO}
                >
                  <Row>
                    <Col sm={12}>
                      <ul>
                        <li itemProp="name"><Link itemProp="url" to="/ieo-consulting-services" onClick={this.toggleNavbar}>IEO Consulting Services</Link></li>
                      </ul>
                    </Col>
                  </Row>
                </Collapse>
              </NavItem>

              <NavItem itemProp="name" className="submenumain">
                <NavLink to="/" onClick={this.ICOtoggle}>ICO <FontAwesomeIcon icon="sort-down" size="1x" /></NavLink>
                <Collapse
                  isOpen={!this.state.collapsedICO}
                >
                  <Row>
                    <Col sm={12}>
                      <ul>
                        <li itemProp="name"><Link itemProp="url" to="/ico-marketing" onClick={this.toggleNavbar}>ICO Marketing</Link></li>
                      </ul>
                    </Col>
                  </Row>
                </Collapse>
              </NavItem>


              <NavItem itemProp="name" className="submenumain">
                <NavLink to="/" onClick={this.Derivativetoggle}>Derivative <FontAwesomeIcon icon="sort-down" size="1x" /></NavLink>
                <Collapse
                  isOpen={!this.state.collapsedDerivative}
                >
                  <Row>
                    <Col sm={12}>
                      <ul>
                        <li itemProp="name"><Link itemProp="url" to="/cryptocurrency-derivatives-exchange-development" onClick={this.toggleNavbar}>Derivative Exchange Software</Link></li>
                        <li itemProp="name"><Link itemProp="url" to="/derivatives-trading-platform" onClick={this.toggleNavbar}>Derivatives Trading Platform</Link></li>                
                      </ul>
                    </Col>
                  </Row>
                </Collapse>
              </NavItem>



              <NavItem itemProp="name" className="submenumain">
                <NavLink itemProp="url" to="/" onClick={this.featurestoggle}>Features <FontAwesomeIcon icon="sort-down" size="1x" /></NavLink>
                <Collapse
                  isOpen={!this.state.collapsedfeatures}
                >
                  <Row>
                    <Col sm={12}>
                      <ul>
                        <li></li>
                        <li>
                          <ul>
                            <li itemProp="name" className="innertitle"><Link to="/centralized-exchange-solutions" onClick={this.toggleNavbar}>Front End Features</Link></li>
                            <li>
                              <ul>
                                <li itemProp="name"><Link itemProp="url" to="/security-modules-and-features" onClick={this.toggleNavbar}>Security Modules And Features</Link></li>
                                <li itemProp="name"><Link itemProp="url" to="/exchange-ui-design" onClick={this.toggleNavbar}>Exchange UI Design</Link></li>
                                <li itemProp="name"><Link itemProp="url" to="/Trading-dashboard" onClick={this.toggleNavbar}>Trading Dashboard</Link></li>
                                <li itemProp="name"><Link itemProp="url" to="/dyanmic-cms-for-managing" onClick={this.toggleNavbar}>Dynamic CMS Management</Link></li>
                                <li itemProp="name"><Link itemProp="url" to="/mobile-trading-platform" onClick={this.toggleNavbar}>Mobile Trading Platform</Link></li>
                                <li itemProp="name"><Link itemProp="url" to="/mass-communication-features" onClick={this.toggleNavbar}>Mass Communication</Link></li>
                                <li itemProp="name"><Link itemProp="url" to="/trading-chart-for-exchange" onClick={this.toggleNavbar}>Trading Chart</Link></li>
                                <li itemProp="name"><Link itemProp="url" to="/wallet-management" onClick={this.toggleNavbar}>Wallet Management</Link></li>
                                <li itemProp="name"><Link itemProp="url" to="/marketing-features" onClick={this.toggleNavbar}>Marketing Features</Link></li>
                                <li itemProp="name"><Link itemProp="url" to="/trading-market-setup" onClick={this.toggleNavbar}>Trading Market Setup</Link></li>
                              </ul>
                            </li>
                            <li itemProp="name" className="innertitle"><Link to="/decentralized-crypto-exchange-software" onClick={this.toggleNavbar}>Back End Features</Link></li>
                            <li>
                              <ul>
                                <li itemProp="name"><Link itemProp="url" to="/integrations" onClick={this.toggleNavbar}>API Integrations</Link></li>
                                <li itemProp="name"><Link itemProp="url" to="/trading-report-management" onClick={this.toggleNavbar}>Trading Report Management</Link></li>
                                <li itemProp="name"><Link itemProp="url" to="/currency-and-pairs" onClick={this.toggleNavbar}>Currency Pair Managment</Link></li>
                                <li itemProp="name"><Link itemProp="url" to="/trading-and-reconciliation" onClick={this.toggleNavbar}>Trading And Reconciliation</Link></li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Collapse>
              </NavItem>

              <NavItem itemProp="name" className="submenumain">
                <NavLink to="/" onClick={this.companytoggle}>Company <FontAwesomeIcon icon="sort-down" size="1x" /></NavLink>
                <Collapse
                  isOpen={!this.state.collapsedcompany}
                >
                  <Row>
                    <Col sm={12}>
                      <ul>
                        <li itemProp="name"><Link itemProp="url" to="/vision-mission" onClick={this.toggleNavbar}>Vision & Mission</Link></li>
                        <li itemProp="name"><Link itemProp="url" to="/about-us" onClick={this.toggleNavbar}>About</Link></li>
                      </ul>
                    </Col>
                  </Row>
                </Collapse>
              </NavItem>





              <NavItem itemProp="name">
                <a itemProp="url" href="https://espay.exchange/blog/">Blog</a>
              </NavItem>

              <NavItem itemProp="name">
                <Link itemProp="url" to="/invest-in-whitelabel-cryptocurrency-exchange" onClick={this.toggleNavbar}>Investor</Link>
              </NavItem>

              <NavItem itemProp="name">
                <NavLink itemProp="url" to="/contact-us" onClick={this.toggleNavbar}>Contact Us</NavLink>
              </NavItem>
            </NavbarNav>
          </Collapse>
        </div>

      </div>
    );
  }
}

export default Header;