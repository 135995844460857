import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";

import Mainsite from './SiteMap';

export default class index extends Component {

  componentDidMount() {
    ReactGA.initialize('UA-125025994-1')
    ReactGA.pageview(document.location.pathname)
    ReactGA.pageview(document.location.path)
  }
  
  scrollPositions = {}
  componentWillMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"
    }
    window.scrollTo(0, 0)
  }
  
  render() {
    return (
      <div>
        {this.props.children}
        <Helmet>
            <title>User Sitemap - Espay Exchange</title>
            <meta name="description" content="No need to confuse to our website. 
            If you find any our product or services then click here. It will be help us to browse more."/>
            <meta property="og:title" content="User Sitemap - Espay Exchange" />
            <meta property="og:description" content="No need to confuse to our website. 
            If you find any our product or services then click here. It will be help us to browse more." />
            <meta name="twitter:title" content="User Sitemap - Espay Exchange" />
            <meta name="twitter:description" content="No need to confuse to our website. 
            If you find any our product or services then click here. It will be help us to browse more." />
            <link rel="canonical" href="https://www.espay.exchange/sitemap" />
        </Helmet>

        <Container fluid={true} className="sitemap-bg">
          <Container>
            <Mainsite />
          </Container>
        </Container>

        <ScrollUpButton className="ScrollUpButton"/>
      </div>
    )
  }
}

