import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";
import Derivativesbanner from './Derivativesbanner';
// import Derivativesdetails from './Derivativesdetails';
// import Derivativesfeatures from './Derivativesfeatures';
// import Securityfeatures from './Derivativessecurityfeatures';
import Focussedtabs from './FocussedExpertise';
import TradingPlatform from './DerivativesTradingPlatform';
import InvestorsPlatform from './Investors';
import Buildtraders from './Buildtraders';
import Faq from './Faq';
import Roadmap from './Roadmap';
import Technology from './Technology';
import DerivativesCta from './DerivativesCta';
import Derivativesfrm from './DerivativesForm';



export default class index extends Component {
  componentDidMount() {
    ReactGA.initialize('UA-125025994-1')
    ReactGA.pageview(document.location.pathname)
    ReactGA.pageview(document.location.path)
  }

  scrollPositions = {}
  componentWillMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"
    }
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        {this.props.children}
        <Helmet>
            <title>Exchange Traded Cryptocurrency Derivatives Development</title>
            <meta name="description" content="Espay-X licenses, customizes and white-labels crypto derivatives trading platforms for retail and institutional brokers, trading firms, hedge funds and more."/>
            <meta property="og:title" content="Exchange Traded Cryptocurrency Derivatives Development" />
            <meta property="og:description" content="Espay-X licenses, customizes and white-labels crypto derivatives trading platforms for retail and institutional brokers, trading firms, hedge funds and more." />
            <meta name="twitter:title" content="Exchange Traded Cryptocurrency Derivatives Development" />
            <meta name="twitter:description" content="Espay-X licenses, customizes and white-labels crypto derivatives trading platforms for retail and institutional brokers, trading firms, hedge funds and more." />
        </Helmet>

        <Container fluid={true} className="cryderivativesbanner">
            <Container>
                <Derivativesbanner />
            </Container>
        </Container>

        <Container fluid={true}>
            <Focussedtabs />
        </Container>

        <Container fluid={true} className="investorsbg">
            <InvestorsPlatform />
        </Container>
        <TradingPlatform />
        <Buildtraders />
        <DerivativesCta />
        <Faq />
        <Container fluid={true} className="derivativesroadbg">
            <Roadmap />
        </Container>
        
        <Technology />

        <Container fluid={true} className="contactbg">
          <Derivativesfrm />
        </Container>  
       
        {/* <Derivativesdetails />
        <Derivativesfeatures />  
        <Securityfeatures /> */}
        <ScrollUpButton className="ScrollUpButton"/>
      </div>
    )
  }
}