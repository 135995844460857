import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

export default class STOListing extends Component {
  render() {
    return (
      <div>
        <Row className="mt-30 mb-30">   
            <Col sm="12">
                <div className="marketingmaintitle">
                    <h3>STO Public Relation</h3>
                    <p>Our marketing services include the creation of a PR strategy for your STO. Our STO marketing agency in Australia has a broad network of cooperation partners in the most important crypto and blockchain media, journalists, youtubers, social media influencers and many more. The goal is to establish your brand and achieve the lasting positive results of a successful STO PR strategy.</p>
                </div>
            </Col>
        </Row>

        <Row>
            <Col sm="12">
                <div className="marketingbox">
                    <h3>STO Influencer</h3>
                    <p>Crypto and Blockchain influencers who publish their content on crypto websites or YouTube are opinion leaders and are followed by many crypto investors. We already know many high-reach STO Influencers, to whom we will present your STO.</p>
                </div>
            </Col>
        </Row>

        <Row className="mt-30 mb-30">   
            <Col sm="12">
                <div className="marketingmaintitle">
                    <h3>STO Listing</h3>
                </div>
            </Col>
        </Row>
        <Row>
            <Col sm="6">
                <div className="marketingbox">
                    <h3>Free STO Listings</h3>
                    <p>The target audience of ICO Listings are crypto and blockchain investors. Your STO must be present on all free STO listings to maximize your chances of success. We take care of creating the profile of your STO and communicating it to all STO listings visited by millions of potential investors every day.</p>
                </div>
            </Col>
            <Col sm="6">
                <div className="marketingbox">
                    <h3>Payable STO Listings</h3>
                    <p>A large number of STO listings are chargeable, but most of them do less than many free ICO listings. Through our many years of experience, we are able to find those paid STO listings that meet the high demands of our customers.</p>
                </div>
            </Col>
        </Row>

      </div>
    )
  }
}
