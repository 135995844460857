import React, { Component,Fragment } from 'react';
import { Container, Row, Col } from "reactstrap";
import ExchangeIntegrations from '../../Images/Derivatives/ExchangeIntegrations.png';
import TradingExecution from '../../Images/Derivatives/TradingExecution.png';
import InvestorFriendly from '../../Images/Derivatives/InvestorFriendly.png';
import OrderStrategies from '../../Images/Derivatives/OrderStrategies.png';
import ExchangeSystem from '../../Images/Derivatives/ExchangeSystem.png';
import IndicesCalculator from '../../Images/Derivatives/IndicesCalculatortick.png';
import MarginEnginetick from '../../Images/Derivatives/MarginEnginetick.png';
import RiskEnginetickicon from '../../Images/Derivatives/riskenginetick.png';
import MatchSettlement from '../../Images/Derivatives/MatchSettlement.png';
import LiquidationEngine from '../../Images/Derivatives/LiquidationEnginetick.png';
import Backendtick from '../../Images/Derivatives/Backendtick.png';
import Pricecalculatortick from '../../Images/Derivatives/Pricecalculatortick.png';




export default class Technology extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="cryderivativestitle">
                                <h2>Technology</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <div className="technologybox">
                                <img src={ExchangeIntegrations} alt="Exchange Integrations" title="Exchange Integrations" />
                                <h3>Exchange Integrations</h3>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="technologybox">
                                <img src={TradingExecution} alt="Trading Execution Middleware" title="Trading Execution Middleware" />
                                <h3>Trading Execution Middleware</h3>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="technologybox">
                                <img src={InvestorFriendly} alt="Investor-Friendly Frontends" title="Investor-Friendly Frontends" />
                                <h3>Investor-Friendly Frontends</h3>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="technologybox">
                                <img src={OrderStrategies} alt="Order Strategies" title="Order Strategies" />
                                <h3>Order Strategies</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <div className="technologybox">
                                <img src={ExchangeSystem} alt="Exchange System" title="Exchange System" />
                                <h3>Exchange System</h3>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="technologybox">
                                <img src={IndicesCalculator} alt="Indices Calculator" title="Indices Calculator" />
                                <h3>Indices Calculator</h3>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="technologybox">
                                <img src={Pricecalculatortick} alt="Prices Calculator" title="Prices Calculator" />
                                <h3>Prices Calculator</h3>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="technologybox">
                                <img src={MarginEnginetick} alt="Margin Engine" title="Margin Engine" />
                                <h3>Margin Engine</h3>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <div className="technologybox">
                                <img src={RiskEnginetickicon} alt="Risk Engine" title="Risk Engine" />
                                <h3>Risk Engine</h3>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="technologybox">
                                <img src={MatchSettlement} alt="Match & Settlement Engine" title="Match & Settlement Engine" />
                                <h3>Match & Settlement Engine</h3>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="technologybox">
                                <img src={LiquidationEngine} alt="Liquidation Engine" title="Liquidation Engine" />
                                <h3>Liquidation Engine</h3>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="technologybox">
                                <img src={Backendtick} alt="Back End" title="Back End" />
                                <h3>Back End</h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
