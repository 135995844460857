import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

export default class Atomicswapdevelopment extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is atomic swap and how it is used in decentralized exchange development ?</h2> 
                            <p>Atomic swap is a smart contract technology that enables exchange of one cryptocurrency for another without using centralized intermediaries, such as exchanges.</p>
                            <p>Atomic swaps can take place directly between blockchains of different cryptocurrencies or they can be conducted off-chain, away from the main blockchain.</p>
                            <p>Atomic swaps solve the problem of time, complexity and interoperability between all currency blockchains through the use of Hash Timelock Contracts (HTLC). As its name denotes, HTLC is a time-bound smart contract between parties that involves the generation of a cryptographic hash function, which can be verified between them. </p>
                            <p>Simply, atomic swaps require both parties to acknowledge receipt of funds within a specified timeframe using a cryptographic hash function. If one of the involved parties fails to confirm the transaction within the timeframe, then the entire transaction is voided, and funds are not exchanged. The latter action helps remove counterparty risk.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
