export default [
    {
        "Bannerdata": {
            "fields": {
                "Banner_h1": "<h1>GuestPost Submission Guideline for Contribution</h1>",
                "Banner_Description": "<p></p>",
                "Banner_linkpath": "/",
                "Banner_linktitle": "Talk To Our Experts",
                "Banner_tag": "Guestpost Submission Guideline",
            }
        }
    },
    {
        "Guestpotabout": {
            "fielsds": {
                "title": "<span>Are you passionate about Exchange or someone who has experience contributing to blogs, blockchain community, crypto community?</span>",
                "Description": "<p>Espay Exchange is always looking for newsworthy articles relevant to the digital economy such as Cryptocurrency, Crypto exchanges, Exchange Platform, Derivative Exchange, Defi, Commodities, Blockchain, Crypto-assets, Crypto-tokens, Altcoins, Bitcoin, ICOs, STO, IEO, Crypto wallets, Forex trading or CFD trading, etc. to share with our audience.  Do you want to become a guest writer?</p>",
            }
        }
    },
    {
        "Contributors": {
            "fielsds": {
                "Contitle": "<h2>Contributors</h2>",
                "ConDescription": "<p>We always welcome article contributions, provided they follow our editorial guidelines. Being a contributor is a non-paid position but you will receive full credit for all the articles you submit and get published on our site. This is a good way to get a feel for the work we do and prove to us that you deserve to be offered a paid position. <br><br>We are accepting guest posts from external writers. <b>You don’t have to pay anything to get accepted.</b> If you want to share your thoughts, strategies or tips let us know. <b>We accept articles written that pass the following criteria:</b></p>",
                "Rulestitle": "<span>Rules</span>",
                "Ruleslist": "<ul><li>Contain 1000 words or more. (We prefer 1500+ word quality articles)</li><li>Your post must be original and must have never been published before on the Internet </li><li>You agree to not publish the post anywhere else (i.e., in your own blog or as a guest post in other blogs)</li><li>You can promote your website only in the author’s byline – we allow up to two do follow links in there. Promotional links are not allowed in the body of the post.</li><li>However, we do welcome links to other sources in the body as long as they improve readers’ experience. Link references will greatly increase both credibility and readership of your post.</li></ul>",
                "Formattingtitle": "<span>Formatting</span>",
                "Formattinglist": "<ul><li>Send us your posts as clean HTML/DOC documents</li><li>Divide your post into short, easy-to-skim paragraphs</li><li>3.Use subheadings for each meaningful point and mark them with h2 tags</li><li>4.End your post with a powerful conclusion and an open question to engage readers and encourage comments</li><li>Attach images</li></ul>",
                "ConDescriptionnote": "<p class='notedes'>*We reserve the right to modify the article you send us: this includes modifying the text, the title, the time, the URL or the link it contains.</p>",
            }
        }
    },
]