import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

export default class HavingaSTOExchange extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">Benefits of Having A STO Exchange</h2>
                            <ul>
                                <li>
                                    <b>Extremely Secure</b>
                                    <p>Every security exchange consists of layers of separated layers of servers, encrypted access to users, two-factors authentications, DDoS protection, X-XSS protection, communications protocol public key and Content Security Policy (CSP) protection.</p>
                                </li>
                                <li>
                                    <b>99.9% Uptime</b>
                                    <p>Security exchanges will keep running despite any failure, because of its inbuilt features: DNS failure, Geo IP Routing and Distributed Server design. this may make sure that the plus exchange platform remains on-line perpetually.</p>
                                </li>
                                <li>
                                    <b>Escrow System</b>
                                    <p>Any group action between the customer and marketer employing a third party or a wise contract is that the written agreement system. Once the accountable parties comply with the payment details, the written agreement releases the plus with none delays.</p>
                                </li>
                                <li>
                                    <b>Customizable Exchange Platform</b>
                                    <p>A customizable exchange platform permits the users to form their security token exchange and connect it to the first network. This ensures the liquidity of the exchange is maintained throughout.</p>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
