import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import liquidity from '../../../../Images/liquidity.png';
import daemon from '../../../../Images/daemon.png';
import wallet from '../../../../Images/wallet.png';
import KYCC from '../../../../Images/KYCC.png';
import crm from '../../../../Images/crm.png';
import socialicon from '../../../../Images/socialicon.png';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faEnvelopeOpen, faCreditCard, faUser} from '@fortawesome/free-solid-svg-icons';
library.add(faEnvelope, faEnvelopeOpen, faCreditCard, faUser);
    
class integration_section2 extends Component {
    render() {
      return (
        <div> 
            <Row> 
                <Col sm="6">
                    <div className="integration_service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={liquidity} alt="Liquidity" title="Liquidity" />
                            </Col>
                            <Col sm="10">
                                <h4>Liquidity API Manager</h4>
                                <p>Espay offers real-time liquidity API manager with its exchange development services 
                                    that offer accuracy to manage liquidity across your crypto trading platform.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="integration_service">
                        <Row>
                            <Col sm="2"> 
                                <FontAwesomeIcon icon="envelope" size="2x" />
                            </Col>
                            <Col sm="10">
                                <h4>Email API Manager</h4>
                                <p>Send, receive and track your Emails within minutes with the help of Email API manager 
                                    offered by Espay’s crypto exchange solutions.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="integration_service">
                        <Row>
                            <Col sm="2"> 
                                <FontAwesomeIcon icon="envelope-open" size="2x" />
                            </Col>
                            <Col sm="10">
                                <h4>SMS API Manager</h4>
                                <p>Leverage a sophisticated approach to manage SMS communication with your traders by 
                                    integrating SMS API on your crypto exchange software provided by Espay.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="integration_service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={daemon} alt="Daemon" title="Daemon" /> 
                            </Col>
                            <Col sm="10">
                                <h4>Daemon API Manager</h4>
                                <p>Connect different exchange port and let your users send/receive wallet key with Espay’s 
                                    crypto exchange solutions that serve daemon API manager for the blockchain based 
                                    wallet.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="integration_service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={wallet} alt="Wallet" title="Wallet" />
                            </Col>
                            <Col sm="10">
                                <h4>Wallet API Manager</h4>
                                <p>Discover the Wallet API manager and enable your users to send and receive digital coins
                                    with the Blockchain Wallet API delivered by exchange development solutions from Espay.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="integration_service">
                        <Row>
                            <Col sm="2"> 
                               <FontAwesomeIcon icon="credit-card" size="2x" />
                            </Col>
                            <Col sm="10">
                                <h4>Payment API Manager</h4>
                                <p>Let your traders securely accept crypto coin payments with powerful Payment API manager
                                    from Espay. It aids your customers to accept mobile payments online and in-app.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="integration_service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={KYCC} alt="KYCC" title="KYCC" />
                            </Col>
                            <Col sm="10">
                                <h4>KYC API Manager</h4>
                                <p>Maximize your potential trading transactions by best-in-class KYC API manager offered by
                                    Espay. It automates 90% of routine tasks and accelerates your business processes.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="integration_service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={socialicon} alt="Socialicon" title="Socialicon" />
                            </Col>
                            <Col sm="10">
                                <h4>Social Media Integration</h4>
                                <p>Leverage the tight integration between your crypto exchange and social media channels 
                                   with Espay. It empowers your trading business and helps to connect with traders daily.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="integration_service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={crm} alt="CRM" title="CRM" />
                            </Col>
                            <Col sm="10">
                                <h4>CRM API Manager</h4>
                                <p>Level up your business value as well as functionality by integrating a CRM API to your 
                                    crypto exchange solutions. It automates business relationships with your customers.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="integration_service">
                        <Row>
                            <Col sm="2"> 
                             <FontAwesomeIcon icon="user" size="2x" />
                            </Col>
                            <Col sm="10">
                                <h4>Helpdesk API Manager</h4>
                                <p>Deliver an effortless service experience & support teams anywhere to your traders with 
                                    the help of Espay’s digital asset exchange software.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>          
        </div>
      );
    }
}

export default integration_section2;