import React from 'react';
import { Container,  Col, Row } from 'reactstrap';

import Faqleftpanel from "../Faqleftpanel";

class CryptotradingPairswork extends React.Component {

  render() {
    return (
      <div className="main">
        <Container fluid={true}>
            <Row>
                <Col md={4}>
                <Faqleftpanel {...this.props} />
                </Col>
                <Col md={7} className="mx-auto">
                    <h2 className="topinnertitle">How do crypto trading pairs work?</h2>
                    <p>The word "trading pairs" in cryptocurrency describes a exchange between one form of crypto-currency and another. For eg, the ETH / BTC "trading pair"</p>
                    <p>You may purchase Ethereum with Bitcoin, or Sell Ethereum for Bitcoin, with ETH / BTC. After all, these types of cryptocurrencies are monies!</p>
                    <p>In other terms, you can not only exchange cash for cryptocurrency, but also you can exchange cryptocurrency for cryptocurrency. And in fact certain cryptocurrencies can only be purchased with other cryptos, so if you want to extend your crypto holdings beyond the big coins, learning about trading pairs becomes very important!</p>
                    <p>For example, BTCPHP is a trading pair where the first currency is the BTC while the second currency is the PHP. Which means you can use your PHP balance to purchase BTC.</p>
                    <p>You can buy BTC when you place an order in Order Entry by specifying the quantity in Order Quantity. That number may be in either BTC or PHP. The price per BTC area (in Limit and Stop Orders) below, however, should only be in PHP, as that is the trading pair's second currency.</p>
                    <p>The price graph gives the value of 1 BTC so the market rate is given in the value of PHP. </p>
                    <p>This definition also refers to the pairs dealing in cryptocurrencies on centralized cryptocurrency exchange solutions or decentralized solutions.</p>
                </Col>
            </Row>
        </Container>
      </div>
    );
  }
}

export default CryptotradingPairswork;