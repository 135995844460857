import React, { Component } from 'react';
import { Container, Row, Col, Jumbotron} from 'reactstrap';
//import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import ExchangeDevelopment1 from '../../Images/CryptoExchange-Development-Company.png';
import { Link } from "react-router-dom";

import HomeBannerData from './HomeData';
import ReactHtmlParser from "react-html-parser";

class Banner extends Component {

    constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

    render() {
      const homebanner_h1   = HomeBannerData[0].homebannerdata.fields.homebanner_h1;
      const homebanner_dis  = HomeBannerData[0].homebannerdata.fields.homebanner_dis;
      

      return (
        <div className="HomeBanner">
            <Container>           
                <Row>
                    <Col sm="6">                       
                        <Jumbotron>
                            <div className="bannerdetails">
                                {/* <h1>Exchange Development For Entrepreneur & Financial Institution</h1> */}
                                {/* <h1>Cryptocurrency Exchange Development company For Entrepreneur & Financial Institution</h1> */}
                                {ReactHtmlParser(homebanner_h1)}
                                <p>{homebanner_dis}</p>
                                {/* <Button onClick={this.toggle}>{this.props.buttonLabel}Talk To Our Experts</Button> */}
                                <Link to="/talk-to-crypto-exchange-expert">Talk To Our Experts</Link>
                            </div>
                        </Jumbotron>
                    </Col>
                    <Col sm="6" className="homeheaderimage"> 
                        <img alt="Exchange Development" title="Exchange Development" src={ExchangeDevelopment1} className="banner_img" />  
                    </Col>
                </Row> 

                 {/* <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                  <ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
                  <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label for="exampleEmail">Email</Label>
                      <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" />
                    </FormGroup>
                    <FormGroup>
                      <Label for="examplePassword">Password</Label>
                      <Input type="password" name="password" id="examplePassword" placeholder="password placeholder" />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleSelect">Select</Label>
                      <Input type="select" name="select" id="exampleSelect">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                    </FormGroup>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={this.toggle}>Send</Button>{' '}
                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                  </ModalFooter>
                </Modal> */}

            </Container>              
        </div>
      );
    }
}

export default Banner;