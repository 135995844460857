import React, { Component } from 'react';
import { Row, Col} from 'reactstrap';

class IEOValidationBanner extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col md={8} className="bannerdetails mx-auto">                       
                    <h1>Pre ICO launch – Why Pre ICO/Presale is important for your startup</h1>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default IEOValidationBanner;