import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Form, FormGroup} from 'reactstrap';
import axios from 'axios';

class about_us_section1 extends Component {

    constructor() {
        super();
        this.state = {
          fields: {},
          errors: {},
        }
    
        this.handleChange = this.handleChange.bind(this);
        this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
    
      };
    
      handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
          fields
        });
    
      }
    
      submituserRegistrationForm(e) {
        e.preventDefault();
        if (this.validateForm()) {
            let fields = {};
            fields["name"] = "";
            fields["emailid"] = "";
            fields["mobileno"] = "";
            fields["service"] = "";
            fields["company"] = "";
            fields["country"] = "";
            fields["message"] = "";
    
            const contactUsRequest = {
              "Name":this.state.fields.name,
              "EmailID":this.state.fields.emailid,
              "MobileNo":this.state.fields.mobileno,
              "Service":this.state.fields.service,
              "Company":this.state.fields.company,
              "Country":this.state.fields.country,
              "Message":this.state.fields.message
            }
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            axios.post('https://espaynode.espay.exchange/api/sendContactForm',contactUsRequest)
            .then((result) => {
              this.setState({fields:fields});
              //alert("Form submitted");
              window.location.href = "https://www.espay.exchange/thank-you-for-booking-exchange-demo/";
            });
        }
      }
    
      validateForm() {
    
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
    
        if (!fields["name"]) {
          formIsValid = false;
          errors["name"] = "*Please Enter Your Name.";
        }
    
        if (typeof fields["name"] !== "undefined") {
          if (!fields["name"].match(/^[a-zA-Z ]*$/)) {
            formIsValid = false;
            errors["name"] = "*Please Enter Alphabet Characters only.";
          }
        }
    
        if (!fields["emailid"]) {
          formIsValid = false;
          errors["emailid"] = "*Please enter your Email-ID.";
        }
    
        if (typeof fields["emailid"] !== "undefined") {
          //regular expression for email validation
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(fields["emailid"])) {
            formIsValid = false;
            errors["emailid"] = "*Please enter valid Email-ID.";
          }
        }
    
        if (!fields["mobileno"]) {
          formIsValid = false;
          errors["mobileno"] = "*Please Enter Your Mobile no.";
        }
    
        if (typeof fields["mobileno"] !== "undefined") {
          if (!fields["mobileno"].match(/^[0-9]{10}$/)) {
            formIsValid = false;
            errors["mobileno"] = "*Please Enter Valid Mobile no.";
          }
        }
    
          if (!fields["company"]) {
            formIsValid = false;
            errors["company"] = "*Please Enter your company.";
          }
    
          if (typeof fields["company"] !== "undefined") {
            if (!fields["company"].match(/^[a-zA-Z ]*$/)) {
              formIsValid = false;
              errors["company"] = "*Please Enter Alphabet Characters only.";
            }
          }
    
          if (!fields["service"]) {
            formIsValid = false;
            errors["service"] = "*Please Select Any Service.";
          }
    
          if (!fields["country"]) {
            formIsValid = false;
            errors["country"] = "*Please Enter Your Country.";
          }
    
        this.setState({
          errors: errors
        });
        return formIsValid;
      }
    
    
        render() {
          return (
            <div> 
                <Row>
                    <Col sm="7">
                        <div className="banner-heading">
                          <h1 className="title">See Our Exchange Software In Action</h1>
                          <p>The best way to understand how our innovative exchange technology helps you disrupt the cryptocurrency landscape is by witnessing the platform in action.</p>
                        </div>
                        <div className="banner-content">
                          
                          {/* <p>1. </p>
                          <p>2. </p>
                          <p>3. Specify your requirements.</p>
                          <p>4. Our exchange expert resolve your queries.</p> */}
                          <ul>
                            <li>Walkthrough front end/user panel.</li>
                          <li>Walkthrough back end/admin panel.</li>
                          <li>Specify your requirements.</li>
                          <li>Our exchange expert resolve your queries.</li>
                          </ul>
                         
                        </div>
                    </Col>
    
    
                    <Col sm={{size:4, offset:1}}>
                    
                    
                   
                    <Form method="post" name="userRegistrationForm" onSubmit= {this.submituserRegistrationForm}  className="landing-form">
                        <Row className="">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" name="name" class="form-control" placeholder="Your Name *" value={this.state.fields.name} onChange={this.handleChange} />
                                    <div className="errorMsg" style={{ fontSize : '12px' , color : 'red' , fontweight: '600'  }}>{this.state.errors.name}</div>                          
                                </div>
                                <div class="form-group">
                                    <input type="text" name="mobileno" class="form-control" placeholder="Your Phone Number *" value={this.state.fields.mobileno} onChange={this.handleChange} />
                                    <div className="errorMsg" style={{ fontSize : '12px' , color : 'red' , fontweight: '600'  }}>{this.state.errors.emailid}</div>
                                </div>
                                <div class="form-group">
                                    <input type="text" name="country" class="form-control" placeholder="Your Country *" value={this.state.fields.country} onChange={this.handleChange} />
                                    <div className="errorMsg" style={{ fontSize : '12px' , color : 'red' , fontweight: '600' }}>{this.state.errors.country}</div>
                                </div>  
                                <div class="form-group">
                                  <FormGroup>
                                    <select placeholder="Which Service Are You Interested ?" class="form-control" name="service" value={this.state.fields.service} onChange={this.handleChange}>
                                      <option >Select Platform</option>
                                      <option value="Centralized Exchange">Centralized Exchange</option>
                                      <option value="Decentralized Exchange">Decentralized Exchange</option>
                                      <option value="P2P Exchange">P2P Exchange</option>
                                      <option value="Security Token / Forex Exchange">Security Token / Forex Exchange</option>
                                    </select>
                                    <div className="errorMsg" style={{ fontSize : '12px' , color : 'red' , fontweight: '600'  }}>{this.state.errors.service}</div>
                                  </FormGroup>
                                </div>  
                                
    
                            
                            </div>
    
                            <div class="col-md-12">
                              <div class="form-group">
                                      <input type="text" name="emailid" class="form-control" placeholder="Your Email *" value={this.state.fields.emailid} onChange={this.handleChange} />
                                      <div className="errorMsg" style={{ fontSize : '12px' , color : 'red' , fontweight: '600'  }}>{this.state.errors.emailid}</div>
                                  </div>
                                  <div class="form-group">
                                      <input type="text" name="company" class="form-control" placeholder="Your Company *" value={this.state.fields.company} onChange={this.handleChange} />
                                      <div className="errorMsg" style={{ fontSize : '12px' , color : 'red' , fontweight: '600'  }}>{this.state.errors.company}</div>
                                  </div>
    
                                  <div class="form-group">
                                      <textarea name="message" class="form-control" placeholder="Your Message *" style={{ height: '90px' }} value={this.state.fields.message} onChange={this.handleChange} ></textarea>
                                  </div>
                                  
                              </div>
    
                              
    
                              <div class="col-md-12 text-center">
                                <div class="form-group">
                                  <input type="submit" className="button contactsendbutton" value="Send"/>
                                </div>
                              </div>
                        </Row>
    
                </Form>
               
              
                    </Col>
                   
                </Row>
                
                
    
                
               
                
            </div>
          );
        }
    }
    

export default about_us_section1;