import React, { Component } from 'react'
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import ImgpshFullsizeAnim from '../../../Images/faq/ImgpshFullsizeAnim.jpg';

export default class Securitytokenliquidize extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">How Security token can liquidize the illiquid assets/securities?</h2>
                            <img className="img-fluid faqimg" src={ImgpshFullsizeAnim} alt="the illiquid assets/securities?" title="the illiquid assets/securities?" />                            
                           <p>You may have a good investment hypothesis that an apartment building down the street is going to appreciate, but you may not have a couple million USD to put up to buy it — and if you do, you better be really confident because it’s going to be hard to sell. Because it’s difficult to sell, that is how the asset or securities are illiquid. Further more, it is difficult for people to invest in the asset or security in other countries.</p>
                            <ul>
                                <li>
                                    <p>You can invest in a restaurant, but there’s no way to get that money out if you need it for anything else: you have to wait until the restaurant is sold or pays dividends.</p>
                                    <p>By contrast, if you want to buy stock in a company, you can buy a single share for a couple hundred bucks and sell it in minutes if you change your mind.</p>
                                </li>
                                <li>
                                    <p>Tokenized securities allow for businesses to lock in funds without locking investors in because the tokens are tradable on a secondary market. The business still gets to use the capital, but investors can exchange shares with each other.</p>
                                    <p>With a token, you can code restrictions, enabling buyers and sellers to trade in a secondary market as long as the trade doesn’t violate the coded restrictions, making it easier for the cross border tradinng and even removing the need of central authority.</p>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
