import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";

class Tp_section10 extends Component {
    render() {
      return (
        <div className="mt-30 mb-30"> 
            <Row>
            <Col sm={{size:12}}>
                <div className="crypto_exchange">
                    <h2>What Should Be My First Step To Build A Whitelabel Cryptocurrency Exchange ?</h2>
                    
                    <Row className="mt-30">
                        <Col sm={{size:6}}>
                            <p>Is there any future for cryptocurrency ?</p>
                        </Col>
                        <Col sm={{size:6}}>
                            <p>What are the challenges for cryptocurrency exchange ?</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={{size:6}}>
                            <p>Can I enter the market with a solution to these challenges ?</p>
                        </Col>
                        <Col sm={{size:6}}>
                            <p>What features should my cryptocurrency exchange ideally have ?</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={{size:12}}>
                            <p>Can I build my branded cryptocurrency exchange ?</p>
                        </Col>
                    </Row>
                </div>

                <div className="devlopment_last">
                    <Link to="/contact-us">Book An Appointment</Link>
                </div>

            </Col>
            </Row> 
            
        </div>
      );
    }
}

export default Tp_section10;