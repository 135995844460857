import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import SecurityTokensInGermany from '../../../Images/faq/SecurityTokensInGermany.jpg';

export default class STGermany extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">The State Of Security Tokens In Germany</h2> 
                            <img src={SecurityTokensInGermany} alt="Security Tokens In Germany" title="Security Tokens In Germany" className="img-fluid faqimg"/>
                            <p>Germany does not consider cryptocurrencies as financial instruments. In September 2018, the country determined that Bitcoin traders don’t need any licensing and that trading cryptocurrency should remain legal. This was a major ruling in favor of crypto investors. This ruling meant that crypto investors did not need to adhere to Germany’s securities regulations.</p>
                            <p>In December 2018, Germany’s second largest stock exchange announced plans to launch a crypto token exchange. The Boerse Stuttgart Group partnered with the local Fintech firm SolarisBank to make the project a reality. The exchange’s release date is Q3 2019. According to developers, a variety of cryptocurrencies including utility, security, and exchange tokens will be hosted.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
