import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';

import Faqleftpanel from "../Faqleftpanel";

export default class WhatWhitelabelcryptoexchange extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is white label crypto exchange?</h2>
                            <p>White label crypto exchange software is a market ready platform with already built-in core backend and database features. The interface can be customized as per business requirements–it can be seamlessly given a unique identity, such as incorporating business logo and color themes.</p>
                            <p>The volatile crypto-currency market encourages businesses to opt for bitcoin exchange software to create centralized or decentralized crypto exchange software to accelerate time-to-market for their crypto-exchange platforms and start earning profits, especially when the market is bullish.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
