import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import France from '../../../Images/faq/France.jpg';

export default class STFrance extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is the state of security tokens in France?</h2> 
                            <img src={France} alt="France" title="France" className="img-fluid faqimg"/>
                            <p>France took a much different approach regarding cryptocurrency firms. The Authorité des Marchés Financiers (AMF) released ICO guidelines last year. The new regulations require all ICOs to provide full transparency regarding their offerings. Companies must define their tokens use prior to approval. This demand is required so that officials can determine what type of token the company plans to issue.</p>
                            <p>France’s stance on ICOs is mimicked by a plethora of other countries around the globe. By making all ICOs operate in full disclosure, the country managed to simultaneously welcome STOs while reducing the number of ICOs in operation. STOs provide a much safer way for investors to participate in blockchain fundraising raising campaigns.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
