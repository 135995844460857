import React, { Component,Fragment } from 'react';
import { Container, Row, Col} from 'reactstrap';
//import { Link } from "react-router-dom";
import IndicesCalculator from '../../Images/Derivatives/IndicesCalculator.png';
import Pricecalculator from '../../Images/Derivatives/Pricecalculator.png';
import Marginengine from '../../Images/Derivatives/Marginengine.png';
import Ordertypes from '../../Images/Derivatives/Ordertypes.png';
import RiskEngine from '../../Images/Derivatives/RiskEngine.png';
import MatchSetEngine from '../../Images/Derivatives/MatchSetEngine.png';
import LiquidationEngine from '../../Images/Derivatives/LiquidationEngine.png';
import Securitycontrol from '../../Images/Derivatives/Securitycontrol.png';
import Wallet from '../../Images/Derivatives/Wallet.png';

export default class DerivativesTradingPlatform extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col md={12}>  
                            <div className="cryderivativestitle">
                                <h2>Disrupt With A Risk Mitigating Cryptocurrency Derivatives Trading Platform</h2>
                                <p>Our software enables the most important derivative features to mitigate risks, grow business and drive operational efficiency.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <div className="risktradingbox">
                                <img src={IndicesCalculator} alt="Indices Calculator" title="Indices Calculator" />
                                <h3>Indices Calculator</h3>
                                <p>The EspayDerivatives system synthesizes the indices with several sources for the underlying assets to comply with the market consensus prices.</p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="risktradingbox">
                                <img src={Pricecalculator} alt="Price Calculator" title="Price Calculator" />
                                <h3>Price Calculator</h3>
                                <p>Our proprietary price calculator automatically calculates the weighted index spot price of the underlying asset across multiple exchanges.</p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="risktradingbox">
                                <img src={Marginengine} alt="Margin Engine" title="Margin Engine" />
                                <h3>Margin Engine</h3>
                                <p>Espay Margin engine is a complete modular solution which serves as a real-time rule engine and risk analytics to regulated rates and traders for all the derivatives product.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <div className="risktradingbox">
                                <img src={Wallet} alt="Wallet" title="Wallet" />
                                <h3>Wallet</h3>
                                <p>EspayDerivatives Solution comes with a wallet module to enable users to track their funds, transactions, and portfolio.</p>
                            </div>
                        </Col>  
                        
                        <Col md={4}>
                            <div className="risktradingbox">
                                <img src={RiskEngine} alt="Risk Engine" title="Risk Engine" />
                                <h3>Risk Engine</h3>
                                <p>EspayX risk engine includes a complete suit of real time risk analytics, match engine, and a guided back-office for easy integrations.</p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="risktradingbox">
                                <img src={MatchSetEngine} alt="Match & Settlement Engine" title="Match & Settlement Engine" />
                                <h3>Match & Settlement Engine</h3>
                                <p>Our match engine is configured with a different set of algorithms for the order matching and execution.</p>
                            </div>
                        </Col>  
                    </Row>
                    <Row>
                        <Col md={4}>
                            <div className="risktradingbox">
                                <img src={LiquidationEngine} alt="Liquidation Engine" title="Liquidation Engine" />
                                <h3>Liquidation Engine</h3>
                                <p>The liquidation engine of the Espay-X derivatives exchange takes over and liquidates the positions of traders whose account balances have dropped below the required maintenance margin.</p>
                            </div>
                        </Col>  
                        <Col md={4}>
                            <div className="risktradingbox">
                                <img src={Securitycontrol} alt="Security & Control" title="Security & Control" />
                                <h3>Security & Control</h3>
                                <p>EspayDerivatives solution is equipped with best-in-class security control measurements and parameters in line with the industry best practices.</p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="risktradingbox">
                                <img src={Ordertypes} alt="Order Types" title="Order Types" />
                                <h3>Order Types</h3>
                                <p>EspayDerivatives System is an Order Driven market system that has a pre-configured set of order to meet the requirements of every trader’s style.</p>
                            </div>
                        </Col>  
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
