import React, { Component,Fragment } from 'react';
import {Row, Col, Container} from 'reactstrap';

import Clutchlogo from '../AwardCertificate/images/clutchlogo.png';
import Goodfirmslogo from '../AwardCertificate/images/goodfirms.png';

export default class index extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="awardcertificate_bg">
                    <Container> 
                        <Row>
                            <Col md={12}>
                                <h2>Featured in</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} sm={4} md={2}>
                                <a target="_blank" className="awardbox" href="https://clutch.co/profile/espay-pty" rel="noopener noreferrer">
                                    <img className="img-fluid" src={Clutchlogo} alt="Clutch.co" title="Clutch.co" />
                                    <span>Clutch.co</span>
                                </a>
                            </Col>
                            <Col xs={6} sm={4} md={2}>
                                <a target="_blank" className="awardbox" href="https://www.goodfirms.co/company/espay-exchange" rel="noopener noreferrer">
                                    <img className="img-fluid" src={Goodfirmslogo} alt="GoodFirms Badge" title="GoodFirms Badge" />
                                    <span>Good Firms</span>
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Fragment>
        )
    }
}
