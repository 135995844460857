import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import Liechtenstein from '../../../Images/faq/Liechtenstein.jpg';

export default class STLiechtenstein extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is the state of security token in Liechtenstein?</h2> 
                            <img src={Liechtenstein} alt="Liechtenstein" title="Liechtenstein" className="img-fluid faqimg"/>
                            <p>The sixth smallest country in the world, Liechtenstein continues to develop their blockchain sector at an impressive rate. Despite being only 62 square miles in total, this tiny country ranks among the highest in terms of GDP per capita. In October 2018, the country released a public draft titled the “National Blockchain Act.”</p>
                            <p>The document highlighted the counties desire to further their blockchain aspirations. The move was quickly followed by an announcement by the country’s Union Bank AG. Here, the bank described their intention to be the first regulated bank issuing a security token. The bank looks to create a token for interbank activities such as transferring large amounts of funds internationally.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
