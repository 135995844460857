import React, { Component } from 'react';
import {Row, Col} from 'reactstrap';
//import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faCreditCard, faServer,faExchangeAlt, faAddressCard, faCubes,faSitemap,faHeadphones} from '@fortawesome/free-solid-svg-icons';
library.add(faCreditCard,faExchangeAlt,faServer,faAddressCard, faCubes,faSitemap,faHeadphones);

export default class Solutions_crypto_Section1 extends Component {
    render() {
      return (
        <div> 
             {/* <Row>
                <div className="mobilebtnces">
                    <Link to="/talk-to-crypto-exchange-expert">Book Demo With Expert</Link>
                </div>
            </Row> */}
            <Row>
                <Col sm="12">
                    <div className="CryptoSoluctiontitle">
                        <h2>One Stop Crypto Exchange Solutions</h2>
                        <p>Deliver exchanges that are easy and suitable for traders to buy and sell virtual currencies on the go.</p>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="4">                         
                    <div className="OneStopCryptobox">
                        <FontAwesomeIcon icon="address-card" size="3x"/>
                        <h3>Centralized Exchange</h3>
                        <p>Leverage real-time centralized exchanges for digital assets that delivers unmatched trading experience
                            combined with a variety of back-end solutions.
                        </p>
                        {/* <Link to="/centralized-exchange-solutions">Read More</Link> */}
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="OneStopCryptobox">
                        <FontAwesomeIcon icon="server" size="3x"/>
                        <h3>Decentralized Exchange</h3>
                        <p>Revolutionize your business with Espay’s decentralized exchange development that makes your
                                whole business completely distributed on a decentralized network.
                        </p>
                        {/* <Link to="/decentralized-crypto-exchange-software">Read More</Link> */}
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="OneStopCryptobox">
                        <FontAwesomeIcon icon="exchange-alt" size="3x"/>
                        <h3>P2P Marketplace Software</h3>
                        <p>We are offering various white-label platform models to set-up your own peer-to-peer marketplace
                                with the ability to trade safely on a global level.
                        </p>
                        {/* <Link to="/p2p-exchange-software-development">Read More</Link> */}
                    </div>
                </Col>
            </Row> 
            <Row>
                <Col sm="4">                         
                    <div className="OneStopCryptobox">
                        <FontAwesomeIcon icon="cubes" size="3x"/>
                        <h3>Security Token Exchange</h3>
                        <p>Convert financial investments & instruments into tokens uniquely providing a 
                            single trading platform for security tokens & crypto with the affluence of liquidity.</p>
                        {/* <Link title="Customized Exchange Software Solution" to="/security-token-exchange">Read More</Link> */}
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="OneStopCryptobox">
                        <FontAwesomeIcon icon="sitemap" size="3x"/>
                        <h3>Forex Exchange Solutions</h3>
                        <p>Espay is a trusted face globally, whether you’re searching for wholesale/retail 
                            currency exchange platform you are assured a safe and suitable service.</p>
                        {/* <Link title="Customized Exchange Software Solution" to="/talk-to-crypto-exchange-expert">Read More</Link> */}
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="OneStopCryptobox">
                        <FontAwesomeIcon icon="headphones" size="3x"/>
                        <h3>One Stop Crypto Solutions</h3>
                        <p>Whether you are looking for whitelabel or enterprise solutions for centralized, decentralized, P2P, Forex, or Security Token Offering. Espay-X has you covered for all your needs.</p>
                    </div>
                </Col>
            </Row> 
        </div>
      );
    }
}

