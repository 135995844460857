import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";
import Uiux from "../../Images/OTC/UIUX.png";
import Kyc from "../../Images/OTC/KYCAML.png";
import Admincontrol from "../../Images/OTC/AdminControl.png";
import Algorithm from "../../Images/OTC/TradingAlgorithm.png";
import Architecture from "../../Images/OTC/Architecture.png";
import Security from "../../Images/OTC/security.png";


export default class OTCcustom extends Component {
  render() {
    return (
      <div>
        <Row className="mt-30 mb-30">   
            <Col sm="12">
                <div className="stoadviceitle mt-30">
                    <h2>Custom Development </h2>
                    <p>Dedicated approach to building your Cryptocurrency OTC Trading Desk with your Brand Identity and a custom environment</p>
                </div>
            </Col>
        </Row>

        <Row>
            <Col sm={12} md={12} lg={6}>
                <div className="otc-custom-platformbox">
                    <h2><img src={Uiux} alt="Custom UI/UX" title="Custom UI/UX" /> Custom UI/UX</h2>
                    <p> Tailor made UI/UX layer within the system to reflect your brand identity and stand out from your competitors.</p>
                </div>
            </Col>
            
            <Col sm={12} md={12} lg={6}>
                <div className="otc-custom-platformbox">
                    <h2><img src={Kyc} alt="KYC/AML" title="KYC/AML" /> KYC/AML</h2>
                    <p>A tier based KYC module setup with the custom rules to approve the user KYC accordance with governance and AML policy of the regulations.</p>
                </div>
            </Col>
        </Row>
        <Row>
            <Col sm={12} md={12} lg={6}>
                <div className="otc-custom-platformbox">
                    <h2><img src={Admincontrol} alt="Admin Control" title="Admin Control" /> Admin Control</h2>
                    <p>A feature packed admin control panel to manage your trader and all the trading activities with a complete free hand of configuration like new crypto additions, liquidity management on your own.</p>
                </div>
            </Col>
            <Col sm={12} md={12} lg={6}>
                <div className="otc-custom-platformbox">
                    <h2><img src={Algorithm} alt="Trading Algorithm" title="Trading Algorithm" /> Trading Algorithm</h2>
                    <p>Custom Trading Algorithms will be defined for setting the order and matching engine performance as per your desired logics.</p>
                </div>
            </Col>
        </Row>
        <Row>
            <Col sm={12} md={12} lg={6}>
                <div className="otc-custom-platformbox">
                    <h2><img src={Architecture} alt="Architecture" title="Architecture" /> Architecture</h2>
                    <p>System Specific architecture flow of your components and their internal communications will be defined to match the project concept.</p>
                </div>
            </Col>
            
            <Col sm={12} md={12} lg={6}>
                <div className="otc-custom-platformbox">
                    <h2><img src={Security} alt="Security" title="Security" /> Security (Escrow, platform)</h2>
                    <p> Independent Escrow will be deployed to hold the funds via the trade settlement and following the PCI DSS and OWASP guidelines to secure each bit of your platform.</p>
                </div>
            </Col>
        </Row>

      </div>
    )
  }
}
