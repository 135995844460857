import React from 'react';
import { Container,  Col, Row } from 'reactstrap';

import Faqleftpanel from "../Faqleftpanel";

class Cryptocurrencyexchangeswork extends React.Component {
  render() {
    return (
      <div className="main">
        <Container fluid={true}>
            <Row>
                <Col md={4}>
                    <Faqleftpanel {...this.props} />
                </Col>
                <Col md={7} className="mx-auto">
                    <h2 className="topinnertitle">How do cryptocurrency exchanges work?</h2>
                    <p>A third party or an entity runs centralized transactions, i.e. trusting someone else to manage your money. Much when you trust banks to deal with your fiat money, central exchanges consist of the same philosophy. Through the word centralized, we mean a middleman is responsible for whatever assets are traded on the network.</p>
                    <p>Synchronous exchanges are those that a single transaction awards the customer's order. They are small-scale markets, which are thus vulnerable to errors which problems of scaling when large-volume transactions are carried out.</p>
                    <p>Asynchronous exchanges consist of interfaces which allow user requests to be taken and added to a queue. Both are then handled separately using various layers, and the request status is then transmitted to the user. This exchange has no scaling problems, and, unlike synchronous exchanges, can accommodate a large number of transactions.</p>
                    <p>In all fairness, centralized crypto exchanges, being a decentralized virtual currency, do not stick to the entire essence of a crypto-currency.</p>
                    <p>The crypto-industry thus gave rise to decentralized crypto exchange software in cryptocurrencies. Exchanges that are based on the precise idea that the cryptocurrencies stand for-no intermediaries, and bring trust back into the network. Rather than trusting a firm to keep your funds secure, you're doing it for yourself. All your money remains in your pockets, as long as you want it. Trading and other transactions are all done by you and you alone, allowing the most personal interactions of cryptocurrency trading out there, perhaps, decentralized exchanges. A P2P marketplace prepared with the help of a P2P exchange software development provider serves as another option to justify the decentralized nature of the digital assets.</p>
                </Col>
            </Row>
        </Container>
      </div>
    );
  }
}

export default Cryptocurrencyexchangeswork;