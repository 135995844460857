import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

export default class DerivativesCta extends Component {
    render() {
        return (
          <Fragment>
            <Container fluid={true} className="derivativecta-bg">
              <Container>
                <Row>
                  <Col md={9}>
                    <span>
                      Cryptocurrency Derivatives Trading System For Crypto
                      Market Threats
                    </span>
                    <p>
                      The above reasons make crypto space a playground for
                      risk-takers, and a Crypto Derivatives Exchange allows
                      players with risk-management experience to enter the
                      sphere and potentially reap big profits, while
                      simultaneously lowers the risk factor for the market as a
                      whole.
                    </p>
                  </Col>
                  <Col md={3}  className="text-right">
                    <Link to="/contact-us">Talk With Expert</Link>
                  </Col>
                </Row>
              </Container>
            </Container>
          </Fragment>
        );
    }
}
