import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";

import getstarted from './HomeData';

class Section6 extends Component {
    render() {
        const getstarted_h2       = getstarted[6].getstarteddata.fields.getstarted_h2;
        const getstarted_dis      = getstarted[6].getstarteddata.fields.getstarted_dis;
        const getstarted_title1   = getstarted[6].getstarteddata.fields.getstarted_title1;
        const getstarted_dis1     = getstarted[6].getstarteddata.fields.getstarted_dis1;
        const getstarted_title2   = getstarted[6].getstarteddata.fields.getstarted_title2;
        const getstarted_dis2     = getstarted[6].getstarteddata.fields.getstarted_dis2;
        const getstarted_title3   = getstarted[6].getstarteddata.fields.getstarted_title3;
        const getstarted_dis3     = getstarted[6].getstarteddata.fields.getstarted_dis3;
        const getstarted_title4   = getstarted[6].getstarteddata.fields.getstarted_title4;
        const getstarted_dis4     = getstarted[6].getstarteddata.fields.getstarted_dis4;

      return (
        <div> 
            <Row>
                <Col sm="12">
                    <div className="GetStartedTitle">
                        <h2>{getstarted_h2}</h2>
                        <p>{getstarted_dis}</p>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="3">                         
                    <div className="GetStartedBox">
                        <div className="GetStartedNumber">1</div>
                        <h3>{getstarted_title1}</h3>
                        <p>{getstarted_dis1}</p>
                    </div>
                </Col>
                <Col sm="3">                         
                    <div className="GetStartedBox">
                        <div className="GetStartedNumber">2</div>
                        <h3>{getstarted_title2}</h3>
                        <p>{getstarted_dis2}</p>
                    </div>
                </Col>
                <Col sm="3">                         
                    <div className="GetStartedBox">
                        <div className="GetStartedNumber">3</div>
                        <h3>{getstarted_title3}</h3>
                        <p>{getstarted_dis3}</p>
                    </div>
                </Col>
                <Col sm="3">                         
                    <div className="GetStartedBox">
                        <div className="GetStartedNumber">4</div>
                        <h3>{getstarted_title4}</h3>
                        <p>{getstarted_dis4}</p>
                    </div>
                </Col>
            </Row> 
            <Row>
                <Col sm="12">
                    <div className="Contactbtn">
                        <Link to="/contact-us">Start a conversation</Link>
                    </div>                         
                </Col>
            </Row>          
        </div>
      );
    }
}

export default Section6;