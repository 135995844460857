import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import SecurityTokensEuropeimg from '../../../Images/faq/SecurityTokensEurope.jpg';

export default class SecuritytokenEurope extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is the state of security token in Europe?</h2> 
                            <img src={SecurityTokensEuropeimg} alt="security token in Europe" title="security token in Europe" className="img-fluid faqimg"/>
                            <p>Europe continues to see more counties taking a pro-crypto stance. Countries such as the UK, Switzerland, and Malta, have already made huge strides towards furthering their respective positions in the market. While there are many contributing factors behind these moves, the introduction of security tokens has played a huge roll in these countries decision.</p>
                            <p>Security tokens allow for the digitization of traditional investments. This method is called tokenization. Tokenized assets are considered more stable than traditional cryptocurrencies by most countries. Currently, security tokens fall under the EU’s MiFIDII regulations.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
