import React, { Component } from "react";
import { Row, Col } from "reactstrap";

import partinlogo from "../../Images/MarketingResearch/EspayX-logo.png";

export default class PartingWords extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col sm={12}>
            <div className="partingwords-title">
              <span>Parting Words</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 7, offset: 2 }}>
            <div className="partingwords-logo">
              <img
                className="img-fluid"
                src={partinlogo}
                alt="Espay Exchange"
                title="Espay Exchange"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <div className="partingwords-title">
                <p>
                    Security token exchange platforms provide the perfect
                    environment to solve the liquidity problem. Regulated exchanges
                    will protect investors as well as operators by reducing the
                    chances of fraud. That's why STEs show a lot of promise, right
                    now and in the future. They will play a critical role in
                    developing the global security token trading network. Although
                    this will take some time, investing in an STE right now will
                    provide you with the early adopter advantage.
                </p>
                <p>
                    Australia-based ESPAY will provide you with all the technical
                    help needed for security token exchange development. We have
                    experience in serving customized solutions for a diverse range
                    of security token exchange requirements. Connect with us now
                    before taking the STE plunge.
                </p>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
