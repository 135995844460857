import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
//import { Link } from "react-router-dom";

import Boostreturns from "../../Images/Leverage/Boostreturns.png";
import Reshufflecapital  from "../../Images/Leverage/Reshufflecapital.png";
import perrenialearningopportunity  from "../../Images/Leverage/perrenialearningopportunity.png";
import Startlavrage  from "../../Images/Leverage/Startyourleveragandmargintradingexchange.png";

export default class STOWebPage extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm="12">
                        <div className="lavrageadvantage-title text-center">
                            <h2 className="text-center">Advantages of leverage and margin trading exchange</h2>
                            <p>Let us skyrocket your profits in the leverage and margin trading by developing crypto exchange software.</p>
                        </div>
                    </Col>
                </Row>

                <Container>
                    <Row className="text-center lavrageadvantage-section">
                        <div className="col-sm-4">
                            <div className="lavrageadv-stuff">
                                <img src={Boostreturns} alt="Boostreturns" title="Boostreturns" className="img-fluid " />
                                <h3>Boost returns</h3>
                                <p>All the traders need to do is to deposit a minimum amount and maximize their returns to the next level. Exchange owners can earn transaction fees and deleveraging.</p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="lavrageadv-stuff">
                                <img src={Reshufflecapital} alt="Reshufflecapital" title="Reshufflecapital" className="img-fluid " />
                                <h3>Reshuffle capital</h3>
                                <p>Unutilised funds can be redirected for traders who want to trade through leverage and margin trading. Exchange owners can earn steady returns by allowing it. </p>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="lavrageadv-stuff">
                                <img src={perrenialearningopportunity} alt="perrenialearningopportunity" title="perrenialearningopportunity" className="img-fluid " />
                                <h3>Perennial earning opportunity</h3>
                                <p>Whether the market conditions are good or bad, traders who trade through leverage and margin exchange trading are always opportunists to earn huge returns. In that case, exchange owners can have more business for them. </p>
                            </div>
                        </div>
                    </Row>

                    <Row className="start-lavrage-section">
                        <div className="col-sm-6">
                            <h2>Start your leverage and margin trading exchange and earn huge returns</h2>
                            <p>All you need to do is to develop a leverage and margin trading exchange and start allowing traders for this trading to earn your fees. For each transaction, there will be minimal fees of 0.05%, which can reach a huge amount monthly and annually.</p>
                            <p>In addition to that, you are an owner of the exchange and you can tweak the fees at any time and increase your returns. </p>
                            <p>Top-notch exchanges such as Binance and BitMex are already playing in millions by facilitating leverage and margin trading to the traders. </p>
                            <p>Superior and feature-rich exchange for satisfying user experience </p>
                            <p>Let us develop comprehensive and feature-rich bitcoin and crypto margin trading exchanges for your business to lure traders. </p>
                        </div>
                        <div className="col-sm-6">
                        <img src={Startlavrage} alt="Startlavrage" title="Startlavrage" className="img-fluid " />
                        </div>
                    </Row>

                </Container>
            </div>
        )
    }
}
