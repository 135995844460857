import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";

import Mainbanner from './Banner';
//import Particles from 'react-particles-js';
import Mainsection1 from './Home-Section1';
import Mainsection2 from './Home-Section2';
import Mainsection3 from './Home-Section3';
import Mainsection4 from './Home-Section4';
import Mainsection5 from './Home-Section5';
import Mainsection6 from './Home-Section6';
//import Mainsection7 from './Home-Section7';
import Homecertificate from './Home-Certificate';


// import Modalpo from '../PopupModal/index';

// const particleOpt = {
//   "particles":{
//       "number":{
//           "value":90,
//            "density":{
//                "enable":!0,
//                "value_area":300
//                }},
//             "color":{
//                 "value":["#2CC3C5","#000000","#83d238","#33b1f8"]},
//                 "shape":{"type":"circle",
//                 "stroke":{"width":0.5,"color":"#2CC3C5"},
//                 "polygon":{"nb_sides":15}},
//                 "opacity":{"value":0.5,"random":!1,"anim":{"enable":!1,"speed":1.5,"opacity_min":0.15,"sync":!1}},
//                 "size":{"value":2.5,"random":!1,"anim":{"enable":!0,"speed":2,"size_min":0.15,"sync":!1}},
//                 "line_linked":{"enable":!0,"distance":110,"color":"#2CC2C4","opacity":0.25,"width":1},
//                 "move":{"enable":!0,"speed":2.5,"direction":"none","random":!1,"straight":!1,
//                 "out_mode":"out",
//                 "bounce":!1,
//                 "attract":{"enable":!1,"rotateX":600,"rotateY":1200}}},
//                 "interactivity":{"detect_on":"canvas",
//                 "events":{"onhover":{"enable":!1,"mode":"repulse"}, 
//                 "onclick":{"enable":!1,"mode":"push"},"resize":!0},
//                 "modes":{"grab":{"distance":400,"line_linked":{"opacity":1}},
//                 "bubble":{"distance":400,"size":40,"duration":2,"opacity":8,"speed":3},
//                 "repulse":{"distance":200,"duration":0.4},
//                 "push":{"particles_nb":4},
//                 "remove":{"particles_nb":2}
//               }
//             }
//           }

export default class index extends Component {

  componentDidMount() {
    ReactGA.initialize('UA-169625694-1')
    ReactGA.pageview(document.location.pathname)
    ReactGA.pageview(document.location.path)
  }
  
  scrollPositions = {}
  componentWillMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"
    }
    window.scrollTo(0, 0)
  }
  
  render() {
    return (
      <div>
        {this.props.children}
        <Helmet>
          <title>
            Cryptocurrency Exchange Software Development Company
          </title>
          <meta
            name="description"
            content="Espay is the leading cryptocurrency exchange & trading software development company and offering secure, flexible and customizable crypto exchange software for businesses."
          />
          <meta
            property="og:title"
            content="Cryptocurrency Exchange Software Development Company"
          />
          <meta
            property="og:description"
            content="Espay is the leading cryptocurrency exchange & trading software development company and offering secure, flexible and customizable crypto exchange software for businesses."
          />
          <meta
            name="twitter:description"
            content="Espay is the leading cryptocurrency exchange & trading software development company and offering secure, flexible and customizable crypto exchange software for businesses."
          />
          <meta
            name="twitter:title"
            content="Cryptocurrency Exchange Software Development Company"
          />
          <link rel="canonical" href="https://www.espay.exchange" />
        </Helmet>

{/* <Modalpo /> */}

        <Container fluid={true} className="banner_bg">
          <Container>
            <Mainbanner />
          </Container>
          {/* <Particles 
              className="home_maincanvas"
              params={particleOpt}
            />   */}
        </Container>

        <Container>
          <Mainsection1 />
        </Container>

        <Container fluid={true} className="Comprisefeature-bg">
          <Container>
            <Mainsection2 />
          </Container>
        </Container>

        <Container>
          <Mainsection3 />
        </Container>

        <Container fluid={true} className="AdditionalExchangeFeatures">
          <Container>
            <Mainsection4 />
          </Container>
        </Container>

        <Container fluid={true} className="GetStartedToday">
          <Container>
            <Mainsection5 />
          </Container>
        </Container>

        <Container fluid={true} className="certificatebg">
          <Container>
            <Homecertificate />
          </Container>
        </Container>

        <Container fluid={true} className="GetStartedToday">
          <Container>
            <Mainsection6 />
          </Container>
        </Container>

        {/* <Container fluid={true} className="blogbg">
          <Container>
            <Mainsection7 />
          </Container>
        </Container> */}

        <ScrollUpButton className="ScrollUpButton" />
      </div>
    );
  }
}