import React, { Component } from 'react';
import { Row, Col} from 'reactstrap';
import centralized from '../../Images/centralized.png';
import Decentralized from '../../Images/Decentralized.png';

class decentralized_section6 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12">
                    <div className="Decentralizedtitle">
                        <h2>Difference Between Centralized & Decentralized</h2>
                    </div>
                </Col>
            </Row>
            <Row>    
            <Col sm={10} className="mx-auto">
                <Row>
                <Col sm="6">
                    <div className="CentralizedDecentralized">
                        <img className="img-fluid" src={centralized} alt="Centralized" title="Centralized" />
                        <h3>Centralized</h3>
                        <ul>
                            <li>Funds would be controlled by Admin itself</li>
                            <li>Requires KYC</li>
                            <li>Plug-n-play based modular architecture</li>
                            <li>Exchange fiat currency for crypto & vice versa</li>
                       </ul>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="CentralizedDecentralized">
                        <img src={Decentralized} alt="Decentralized" title="Decentralized" />
                        <h3>Decentralized</h3>
                        <ul>
                            <li>Funds would be controlled by users themselves</li>
                            <li>Completely Anonymous</li>
                            <li>It’s very tough to hack</li>
                            <li>No server downtime</li>
                        </ul>
                    </div>
                </Col>  
                </Row>
            </Col>
            </Row>  
        </div>
      );
    }
}

export default decentralized_section6;