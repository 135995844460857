import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import SecurityTokenContent from '../../Images/MarketingResearch/security-token-content-img.png';

export default class STCLDifferentCountries extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm={12}>                    
                        <div className="STLCtitle">
                            <h3>Security Token Legal Compliance in Different Countries</h3>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={5}>                    
                        <img className="img-fluid" src={SecurityTokenContent} alt="Security Token Legal Compliance in Different Countries" title="Security Token Legal Compliance in Different Countries" />
                    </Col>
                    <Col sm={7}>                    
                        <p>Presently, there is no specific data available for STO and STE-friendly countries. This data lists the countries that have welcomed ICOs which also provide the best environment to launch an STE.</p>
                    </Col>
                </Row>
            </div>
        )
    }
}
