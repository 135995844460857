import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import Integration from '../../../../Images/Integration.png';

class Reconciliation_section1 extends Component {
    render() {
      return (
        <div> 
            <Row> 
                <Col sm="6">
                    <div className="Reconciliation">
                        <p>Espay offers trade reconciliation service with its digital asset exchange software. Trade 
                            reconciliation significantly decreases your trading overhead and reduces the functioning risk 
                            related to it. Espay’s <strong>crypto trading software</strong> assures the retention of control
                            of your order flow as well as transaction history; everything during helping you in
                            authenticating information reliability and completeness in your trading detail.</p>
                        <p>With Espay’s crypto exchange you can record as well as manage even the most voluminous data, 
                            filter out any inconsistency in any digital coin exchanges and fund movements. Leverage best 
                            out of reconciliation that comprises matching ledgers against statements to guarantee precise 
                            accounting of all trade booked.</p>
                    </div>
                </Col>
                <Col sm="6" className="mt-30">
                    <img className="img-fluid" src={Integration} alt="Integration" title="Integration" />
                </Col>  
            </Row>          
        </div>
      );
    }
}

export default Reconciliation_section1;