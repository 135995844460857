import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import DerivativesTrading from '../../Images/DerivativesTrading/Derivatives-Trading.png';
export default class DerivativesParameters extends Component {
  render() {
    return (
      <Fragment>
        <Container >
          <Row className="derivative-parameter">
            <Col sm="6">
              <img className="img-fluid" src={DerivativesTrading} alt="Crypto derivavtives exchange software" title="Derivatives Futures" />
            </Col>
            <Col sm="6">
              <p>Our wide range of retail and institutional brokerages solutions are devised on the following parameters: </p>
              <ul>
                <li>Trading desk</li>
                <li>OMS/EMS</li>
                <li>Liquidation Engine</li>
                <li>Indices Master</li>
                <li>Risk management</li>
                <li>Web, mobile, desktop terminals</li>
                <li>Back-office</li>

              </ul>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
