import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import Interestedsec from '../../Images/Interestedsec.png';
import Interestedp2p from '../../Images/Interestedp2p.png';
import Interesteddes from '../../Images/Interesteddes.png';

class product_crypto_section6 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12">
                    <div className="Centralizedtitle">
                        <h2>You May Also Be Interested In</h2>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="4">
                    <div className="centralizedproductbox">
                    <Link to="/decentralized-crypto-exchange-software" title="Decentralized Exchange">
                        <img src={Interesteddes} alt="Decentralized Exchange" title="Decentralized Exchange"/>
                        <h3>Decentralized Exchange</h3>
                        <p>Revolutionize your business with Espay’s decentralized exchange development that makes your whole
                                business completely distributed on a decentralized network. </p>
                    </Link>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="centralizedproductbox">
                        <Link to="/p2p-exchange-software-development" title="P2P Marketplace Software">
                            <img src={Interestedp2p} alt="P2P Marketplace Software" title="P2P Marketplace Software"/>
                            <h3>P2P Marketplace Software</h3>
                            <p>We are offering various white-label platform models to set-up your own peer-to-peer marketplace 
                                with the ability to trade safely on a global level. </p>
                        </Link>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="centralizedproductbox">
                        <Link to="/security-token-exchange" title="Security Token Exchange">
                            <img src={Interestedsec} alt="Security Token Exchange" title="Security Token Exchange" />
                            <h3>Security Token Exchange</h3>
                            <p>Convert financial investments & instruments into tokens uniquely providing a single trading 
                                platform for security tokens & crypto with the affluence of liquidity. </p>
                       </Link>
                    </div>
                </Col>
            </Row>
        </div>
      );
    }
}

export default product_crypto_section6;