import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import aboutus_left from'../../Images/Aboutus/Aboutus.png';

class experts_section2 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="6" className="mt-30">
                    <img className="img-fluid" src={aboutus_left} alt="Crypto Coin Trading Software" title="Crypto Coin Trading Software"/>
                </Col>
                <Col sm="6">
                    <div className="Aboutustitle">
                        <h2>We’re Crypto Coin Trading Software Development Experts.</h2>
                        <p>Espay have expertise in serving on-demand business solutions with a special focus on safety, scale as 
                            well as performance.</p>
                    </div>
                  
                    <div className="AboutusTradingList">
                        <ul>
                            <li>One of the best fintech companies Pool of professionals who will assist you through every step of 
                            exchange development.
                            </li>
                            <li>An efficient strategy to deliver the best APIs and exchange development services is what we 
                                recognized for.</li>
                            <li>Consider our accomplishment based on the success of website, agents and white labelled services we
                                have catered till now.</li>
                            <li>Acknowledge our clients’ queries and develop as well as implement a problem-solving strategy for
                                quick turnaround time.</li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </div>
      );
    }
}

export default experts_section2;