import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";

class features_standard_section2 extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm="12">
                <div className="enterprisetitle">
                    <h2>Cryptocurrency Exchange Software Development Features</h2>
                    <p>These features make you feel secure while utilizing a crypto exchange platform and aid your customers to trade coins as swiftly as possible.</p>
                </div>    
                </Col>
            </Row> 
            <Row>   
                <Col sm="4">
                    <div className="enterprisefeaturesbox">
                        <h3>Advanced Financial Integrations</h3>
                        <p>Easy as well as customized integration offered by Espay’s exchanges with Banking, settlement and 
                            payment rails containing different fiat currencies.</p>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="enterprisefeaturesbox">
                        <h3>Banking Grade Security</h3>
                        <p>We have stimulated our systems & strengthened our platform with bank-grade security that assures each 
                            transaction are routed seamlessly in the highly protected environment.</p>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="enterprisefeaturesbox">
                        <h3>Advanced APIs</h3>
                        <p>As one of the top-notch Cryptocurrency <Link to="/" title="Exchange Software Development Company">Exchange Development Company</Link>, Espay connect to Binary,
                            RPC (Remote Procedure Call), WebSocket, REST, as well as custom APIs.</p>
                    </div>
                </Col>                   
            </Row>
            <Row>   
                <Col sm="4">
                    <div className="enterprisefeaturesbox">
                        <h3>Enterprise Order Matching</h3>
                        <p>Espay’s exchanges comprise an enterprise matching engine to deliver high throughput. It also helps to
                            manage limit & market orders.</p>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="enterprisefeaturesbox">
                        <h3>Cloud Ready Architecture</h3>
                        <p>Our fintech expertise enables us to deliver a cloud-ready architecture that gives you all the influence 
                            of the web without any risking factor of outdated OS.</p>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="enterprisefeaturesbox">
                        <h3>High TPS Trading</h3>
                        <p>We build products that work fast and deliver high performance. It comprises an ability to process
                                plenty of transactions within a second.</p>
                    </div>
                </Col>                   
            </Row>
        </div>
      );
    }
}

export default features_standard_section2;