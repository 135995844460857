import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import Estonia from '../../../Images/faq/Estonia.jpg';

export default class STEstonia extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                            <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is the state of security tokens in Estonia?</h2> 
                            <img src={Estonia} alt="" title="" className="img-fluid faqimg"/>
                            <p>Estonia was among the first EU members to legalize crypto activities. The country already approved over 900 crypto licenses since changing regulations last year. Those licensed include a variety of crypto businesses such as exchanges, startups, and blockchain tech firms. Around 400 of the licenses issued belonged to crypto wallet providers according to recent reports.</p>
                            <p>Estonia managed to go from the poorest country in the EU to a thriving economy through a combination of factors. Tax incentives, a friendly business environment, and easy licensing procedures all contributed to the country’s rise in the blockchain sector. Today, Estonia is one of the most STO friendly countries in the world.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
