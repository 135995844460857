import React, { Component } from 'react';
import ScrollUpButton from "react-scroll-up-button";
import { Container, Row, Form } from 'reactstrap';
import axios from 'axios';

class Newsletter extends Component {

  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
    }

    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);

  };

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });

  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
        let fields = {};
        fields["name"] = "";
        fields["emailid"] = "";

        const contactUsRequest = {
          "Name":this.state.fields.name,
          "EmailID":this.state.fields.emailid,
        }
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.post('https://espaynode.espay.exchange/api/newsLetter',contactUsRequest)
        .then((result) => {
          this.setState({fields:fields});
          alert("Subscribe Successfully");
        });
    }
  }

  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "*Please Enter Your Name.";
    }

    if (typeof fields["name"] !== "undefined") {
      if (!fields["name"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["name"] = "*Please Enter Alphabet Characters only.";
      }
    }

    if (!fields["emailid"]) {
      formIsValid = false;
      errors["emailid"] = "*Please enter your Email-ID.";
    }

    if (typeof fields["emailid"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["emailid"])) {
        formIsValid = false;
        errors["emailid"] = "*Please enter valid Email-ID.";
      }
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

    render() {
      return (
        <div className="Newsletterbtn"> 
          <Container>
                <Row>
                    <Form method="post" name="userRegistrationForm" onSubmit= {this.submituserRegistrationForm} >
                      <input type='hidden' id="zc_gad" name="zc_gad" value=""/>
                        <div className="form-group">
                            <input type="text" name="name" className="form-control" placeholder="Your Name *" value={this.state.fields.name} onChange={this.handleChange} />
                            <div className="errorMsg" style={{ fontSize : '12px' , color : 'red' , fontweight: '600'  }}>{this.state.errors.name}</div>                          
                        </div>
                            
                        <div className="form-group">
                            <input type="text" name="emailid" className="form-control" placeholder="Your Email *" value={this.state.fields.emailid} onChange={this.handleChange} />
                            <div className="errorMsg" style={{ fontSize : '12px' , color : 'red' , fontweight: '600'  }}>{this.state.errors.emailid}</div>
                        </div>
                            
                        <div className="form-group">
                            <input type="submit" className="button form-control" value="Send"/>
                        </div>
                    </Form>
                </Row>
          </Container>

          <ScrollUpButton className="ScrollUpButton"/>
        </div>
      );
    }
}

export default Newsletter;