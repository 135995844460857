import React, { Component,Fragment } from 'react';
import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";
import GuestpostBanner from './GuestpostBanner';
import Contributors from './Contributors';
import WhywriteEspay from './WhywriteEspay';

export default class index extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-125025994-1')
        ReactGA.pageview(document.location.pathname)
        ReactGA.pageview(document.location.path)
      }
    
      scrollPositions = {}
      componentWillMount() {
        if ("scrollRestoration" in window.history) {
          window.history.scrollRestoration = "manual"
        }
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <Fragment>
                {this.props.children}
                <Helmet>
                    <title>Cryptocurrency and Blockchain Write For Us, Submit Guest Post on Espay</title>
                    <meta name="description" content="Write For Us! At Espay.exchange, we're always on the lookout for talented writers who can write informative & interesting content on topic related to Cryptocurrency and blockchain technology"/>
                    <meta property="og:title" content="Cryptocurrency and Blockchain Write For Us, Submit Guest Post on Espay" />
                    <meta property="og:description" content="Write For Us! At Espay.exchange, we're always on the lookout for talented writers who can write informative & interesting content on topic related to Cryptocurrency and blockchain technology" />
                    <meta name="twitter:title" content="Cryptocurrency and Blockchain Write For Us, Submit Guest Post on Espay" />
                    <meta name="twitter:description" content="Write For Us! At Espay.exchange, we're always on the lookout for talented writers who can write informative & interesting content on topic related to Cryptocurrency and blockchain technology" />
                    <link rel="canonical" href="https://www.espay.exchange/write-for-us-guestpost-submission-guideline" />
                </Helmet>
                <GuestpostBanner />
               
                <Contributors />

                <WhywriteEspay />
                
            </Fragment>
        )
    }
}
