import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";

import Mainbanner from './banner';
//import Particles from 'react-particles-js';
import Mainsolutions from './Crypto-Section1';
import Mainfeatures from './Crypto-Section2';
import Mainsoftwaresolution from './Crypto-Section3';
import Mainsolutionfor from './Crypto-Section4';
import Mainechangework from './Crypto-Section5';
import Mainproduct from './Crypto-Section6';
//import Blog from './Crypto-Section7';
import UniqueFeatures from './Crypto-Section8';
import WhyChooseEspay from './Crypto-Section9';
import Awardcertificate from '../AwardCertificate/index';
import Faqmain from '../Faq/faq';

//import TalkCryptoExchange from '../TalkToOurExperts/TalkToOurExpertsForm';
import TalkCryptoExchange from '../Contactus/ContactusdetialsSTO';

// const particleOpt = {
//   "particles":{
//       "number":{
//           "value":90,
//            "density":{
//                "enable":!0,
//                "value_area":300
//                }},
//             "color":{
//                 "value":["#2CC3C5","#000000","#83d238","#33b1f8"]},
//                 "shape":{"type":"circle",
//                 "stroke":{"width":0.5,"color":"#2CC3C5"},
//                 "polygon":{"nb_sides":15}},
//                 "opacity":{"value":0.5,"random":!1,"anim":{"enable":!1,"speed":1.5,"opacity_min":0.15,"sync":!1}},
//                 "size":{"value":2.5,"random":!1,"anim":{"enable":!0,"speed":2,"size_min":0.15,"sync":!1}},
//                 "line_linked":{"enable":!0,"distance":110,"color":"#2CC2C4","opacity":0.25,"width":1},
//                 "move":{"enable":!0,"speed":2.5,"direction":"none","random":!1,"straight":!1,
//                 "out_mode":"out",
//                 "bounce":!1,
//                 "attract":{"enable":!1,"rotateX":600,"rotateY":1200}}},
//                 "interactivity":{"detect_on":"canvas",
//                 "events":{"onhover":{"enable":!1,"mode":"repulse"}, 
//                 "onclick":{"enable":!1,"mode":"push"},"resize":!0},
//                 "modes":{"grab":{"distance":400,"line_linked":{"opacity":1}},
//                 "bubble":{"distance":400,"size":40,"duration":2,"opacity":8,"speed":3},
//                 "repulse":{"distance":200,"duration":0.4},
//                 "push":{"particles_nb":4},
//                 "remove":{"particles_nb":2}
//               }
//             }
//           }

export default class index extends Component {

  componentDidMount() {
    ReactGA.initialize('UA-125025994-1')
    ReactGA.pageview(document.location.pathname)
    ReactGA.pageview(document.location.path)
  }

  scrollPositions = {}
  componentWillMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"
    }
    window.scrollTo(0, 0)
  }
  
  render() {
    return (
      <div>
        {this.props.children}
        <Helmet>
            <title>Crypto Exchange White Label Software Development</title>
            <meta name="description" content="Espay is the leading solution provider of white label and crypto exchange software. We have 100+ dedicated IT who delivered secure, flexible and customizable crypto exchange software solutions."/>
            <meta property="og:title" content="Crypto Exchange White Label Software Development" />
            <meta property="og:description" content="Espay is the leading solution provider of white label and crypto exchange software. We have 100+ dedicated IT who delivered secure, flexible and customizable crypto exchange software solutions." />
            <meta name="twitter:title" content="Crypto Exchange White Label Software Development" />
            <meta name="twitter:description" content="Espay is the leading solution provider of white label and crypto exchange software. We have 100+ dedicated IT who delivered secure, flexible and customizable crypto exchange software solutions." />
            <link rel="canonical" href="https://www.espay.exchange/crypto-exchange-software" />
        </Helmet>

        <Container fluid={true} className="CentralizedExchangeSolution">
        <Container>
          <Mainbanner />
        </Container>
          {/* <Particles 
              className="Centralizedcanvas"
              params={particleOpt}
            />  */}
        </Container>
        
        <Container>
          <Mainsolutions />
        </Container>

        <Container fluid={true} className="FeaturesPlatform">
          <Container>
              <UniqueFeatures />
          </Container>
        </Container>

        <Container fluid={true} className="FeaturesPlatform">
          <Container>
            <Mainfeatures />
          </Container>
        </Container>

        <Container>
            <Mainsoftwaresolution />
        </Container>

        <Container fluid={true} className="IdealCentralizedExchange">
          <Container>
            <Mainsolutionfor />
          </Container>
        </Container>

        <Container>
          <Mainechangework />
        </Container>

        <Container fluid={true} className="OurExchangeProduct">
          <Container>
            <WhyChooseEspay />
          </Container>
          <Awardcertificate />
        </Container>     
        
        

        <Container fluid={true} className="OurExchangeservices">
          <Container>
            <Mainproduct />
          </Container>
        </Container>   

          {/* <Container fluid={true} className="commonblogsection">
            <Container>
              <Blog />
            </Container>
          </Container>    */}


          <Container>
            <h2 className="faqmaintitle">Frequently Asked Questions</h2>
            <Faqmain />
          </Container>
          
          <TalkCryptoExchange />

        <ScrollUpButton className="ScrollUpButton"/>   
      </div>
    )
  }
}

