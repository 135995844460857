import React from 'react';
import { Container,  Col, Row } from 'reactstrap';
import scrollToComponent from 'react-scroll-to-component';
import ScrollUpButton from "react-scroll-up-button";

import MarketBullish from '../../Images/faq/MarketBullish.jpg';
import DetermineToken from '../../Images/faq/DetermineToken.png';
import STOBanner from '../../Images/faq/STOBanner.png';
import STNewBigThing from '../../Images/faq/STNewBigThing.jpg';
import ImgpshFullsizeAnim from '../../Images/faq/ImgpshFullsizeAnim.jpg';
import TrendsOf2019 from '../../Images/faq/TrendsOf2019.jpg';
import how_works from '../../Images/faq/HowTokenizationWorks.jpg';
import EcosystemSecurityToken from '../../Images/faq/EcosystemSecurityToken.jpg';
import Issuance from '../../Images/faq/Issuance.jpg';

import BrokerDealer from '../../Images/faq/Broker-Dealer.jpg';
import CustodyTrust from '../../Images/faq/CustodyTrust.jpg';
import Legal from '../../Images/faq/Legal.jpg';
import Compliance from '../../Images/faq/Compliance.jpg';
import SecurityTokenExchange from '../../Images/faq/SecurityTokenExchange.jpg';
import EquityTokens from  '../../Images/faq/EquityTokens.jpg';
import DebtTokens from  '../../Images/faq/DebtTokens.jpg';
import Derivatives from  '../../Images/faq/Derivatives.jpg';
import SecurityTokensEurope from  '../../Images/faq/SecurityTokensEurope.jpg';
import MIFID from  '../../Images/faq/MIFID.jpg';
import Liechtenstein from  '../../Images/faq/Liechtenstein.jpg';
import SecurityTokensInGermany from  '../../Images/faq/SecurityTokensInGermany.jpg';
import Switzerland from  '../../Images/faq/Switzerland.jpg';
import Malta from  '../../Images/faq/Malta.jpg';
import Estonia from  '../../Images/faq/Estonia.jpg';
import France from  '../../Images/faq/France.jpg';
import UK from  '../../Images/faq/UK.jpg';
import Slovenia from  '../../Images/faq/Slovenia.jpg';
import Ecuador from  '../../Images/faq/Ecuador.jpg';

import BankingSecurity from  '../../Images/faq/BankingSecurity.png';
import STOPaymentSystems from  '../../Images/faq/STOPaymentSystems.jpg';
import HealthcareAssets from  '../../Images/faq/HealthcareAssets.png';
import TheFinancialCenter from  '../../Images/faq/TheFinancialCenter.png';
// import STOAustralia from  '../../Images/faq/STOAustralia.jpg';
import StateSTOGraphAus from  '../../Images/faq/StateSTOGraphAus.png';
import STOExchangesAsian from  '../../Images/faq/Security-Token-ExchangesAsian.jpg';




class STO_Faq extends React.Component {

  constructor(props) {
    super(props);
    this.scrollToTopWithCallback = this.scrollToTopWithCallback.bind(this)
  }

  componentDidMount() {
    scrollToComponent(this.innerfaqcontent, { offset: -60, align: 'top', duration: 500, ease:'inExpo'});
  }

  scrollToTopWithCallback() {
    let scroller = scrollToComponent(this.Violet, { offset: -75, align: 'top', duration: 1500});
    scroller.on('end', () => console.log('Scrolling end!') );
  }

  render() {
    return (
      <div className='main'>
       <h2 className="topinnertitle">Security Token Exchange</h2>
        <div className="leftpanelmain">
            <div className="button_groupmain">
            <button className="button-faq" onClick={this.scrollToTopWithCallback}>
            1)What is Security token </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.lightpink, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            2)What is STO?</button>
            <button className="button-faq" onClick={() => scrollToComponent(this.pink, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            3) What is STO Exchange?</button>
            <button className="button-faq" onClick={() => scrollToComponent(this.Indigo, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            4) Benefits of Having A STO Exchange
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.Blue, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            5) Stats of STO
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.red, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            6) Why the market is bullish about the security tokens?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            7) How to determine if a token falls into the crieteria of Security Token?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray1, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            8) Security Tokens Can Change The Market.
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray2, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            9) How are tokens classified?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray3, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            10) How Security token can liquidize the illiquid assets/securities?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray4, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            11) How does tokenization work?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray5, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            12) Why Security Tokens are the big deal?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray6, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            13) Ecosystem of Security Token
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray7, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            14) What is the significance of issuance in security token exchange?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray8, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            15) What is the broker dealer component of security token exchange?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray9, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            16) What is the custody & trust component of security token exchange?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray10, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            17) What is the legal component of security token exchange?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray11, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            18) What is the compliance component of a security token exchange?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray12, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            19) How Does The Trading Platform Work In Security Token Exchange?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray13, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            20) What are Equity Tokens?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray14, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            21) What Are Debt Tokens?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray15, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            22) What Are Derivatives?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray16, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            23) What is the state of security token in Europe?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray17, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            24) What is Markets In Financial Directive II (MiFID II)?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray18, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            25) What is the state of security token in Liechtenstein?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray19, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            26) The State Of Security Tokens In Germany
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray20, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            27) What is the state of security tokens in Switzerland?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray21, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            28) What is the state of security tokens in Malta?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray22, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            29) What is the state of security tokens in Estonia?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray23, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            30) What is the state of security tokens in France?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray24, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            31) What is the state of security token in UK
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray25, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            32) What is the scenario of security token scenario in Slovenia?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray26, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            33) What is the state of security tokens in Ecuador?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray27, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            34) Security Token Offering And Banking Security
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray28, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            35) How Does The Security Token Work With The Financial Center?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray29, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            36) Tokenization Of Healthcare Assets
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray30, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            37) How Security Tokens Could Be Implemented In Payment Systems?
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray31, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            38) State of Security Token Offerings In Australia
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.gray32, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
            39) State Of Security Token Offering In Asian Countries
            </button>
            {/* <button onClick={() => scrollToComponent(this.Orange, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>demo4</button> */}
            </div>
            </div>
           <Container>
            <section className='innerfaq2content' ref={(section) => { this.Violet = section; }}>
                    <Row>
                        <Col sm={{size:11,offset:1}} className="faqdiffbor">
                            <h4>1) What is Security token: </h4>
                                <ul>
                                    <li>
                                       <p>As we seen previously, A security is a tradable financial asset. It can be equity, debt or asset. The tokenized form of such security makes it security token. Such tokens, represent the security and its holder has the ownership of that security. For which he either gets right to use property, voting right and share in profit in equity or interest on the debts he is owning. In simpler terms, security tokens are cryptographic tokens that pay dividends, share profits, pay interest or invest in other tokens or assets to generate profits for the token holders. This takes care of the liquidity issues.Imagine, your dividends being paid to you on a specific date upon meeting a certain condition via a smart contract!</p>
                                    </li>
                                    <li>
                                        <p>All these programmabilities can bring in a lot of automation and swiftness to the whole process because after all, you are creating programmable security. And this type of security can do all things that a traditional form can and more.</p>
                                    </li>
                                </ul>
                        </Col>
                    </Row>
            </section>
        </Container>
        <Container>
            <section className='innerfaq1content' ref={(section) => { this.lightpink = section; }} style={{paddingTop:'20px'}}>
                <Row>
                    <Col sm={{size:11,offset:1}} className="faqdiffbor">
                        <h4>2) What is STO?</h4>
                        <img className="img-fluid mb-20 mt-20 faqbannerque" src={STOBanner} title="What is STO" alt="What is STO" />
                        <p>The explosion of Initial Coin Offerings (ICOs) two years ago proved just how cryptographic assets can bring massive opportunities for investments – so much that they completely transformed the financial services market. However, ICOs have had their 15 minutes of fame and STOs (Security Token Offerings) are here to take the story further ahead. As we all know Security tokens are tokens which have attributes of a security.The STO is emerging as a powerful and valuable alternative to private equity and venture capital financing for companies globally. It is such a powerful alternative that industry estimates will grow to a $10 trillion opportunity over the next two years.</p>
                        <p>For companies seeking to raise capital, an STO is worth a closer look for a few good reasons. If your specific goal is to raise a large amount of capital and your company matches three or more of the profile points below, It is worth considering an STO:</p>

                        <ul>
                            <li>
                                <p>Generating in excess of $10 million in annual revenue</p>
                            </li>
                            <li>
                                <p>A high growth company</p>
                            </li>
                            <li>
                                <p>Operating a global business</p>
                            </li>
                            <li>
                                <p>Preferring to issue a transferable asset</p>
                            </li>
                            <li>
                                <p>Interested in a funding method that connects with your customer base</p>
                            </li>
                            <li>
                                <p>Desiring greater liquidity for stakeholders</p>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </section>
        </Container>
        <Container>
            <section className='innerfaq1content' ref={(section) => { this.pink = section; }} style={{paddingTop:'20px'}}>
                <Row>
                    <Col sm={{size:11,offset:1}} className="faqdiffbor">
                        <h4>3) What is STO Exchange?</h4>
                        <ul>
                            <li>
                                <p>STO is similar to an ICO in that an offering is made by a business to the crowd, in which consumers purchase crypto tokens built on a blockchain, but the two do not share many similarities beyond that.</p>
                            </li>
                            <li>
                                <p>Security Exchange Platform allows your users to create their own security token exchange and then connect themselves to the main network. This will ensure that the liquidity of the exchanges is maintained. There is a need in the crypto-market for exchange platforms in the market.</p>
                            </li>
                            <li>
                                <p>STO participants are investors, not users, who pay and receive a security (i.e. equity, debt, revenue share, etc) that is represented by a token. In order to sell securities to the crowd, a company has to register the offering with the SEC (difficult and expensive) or use an exemption from said registration.</p>
                            </li>   
                        </ul>

                        <div className="firstinnerfaq">
                           <h5>A security token exchange will have the following features that collectively make up the exchange platform.</h5>
                            <ul>
                                <li>
                                    <h6>Order Matching</h6>
                                    <p>The exchange can contain Associate in Nursing order matching engine which will mechanically try similar orders between consumers and sellers at the most effective market value. it’ll conjointly match the various orders like market and limit.</p>
                                </li>
                                <li>
                                    <h6>Fund Management</h6>
                                    <p>A case are integrated to manage all security token transactions which can be machine-driven. Users may also management fiat-cryptocurrency conversion manually through the in-built process interface with the associated banks.</p>
                                </li>
                                <li>
                                    <h6>Escrow System</h6>
                                    <p>Any group action between the customer and marketer employing a third party or a wise contract is that the written agreement system. Once the accountable parties comply with the payment details, the written agreement releases the plus with none delays.</p>
                                </li>
                                <li>
                                    <h6>Currency Transactions</h6>
                                    <p>You can integrate any currency within the BAF Security Token Exchange code. Any cryptocurrency like Bitcoin, Ethereum or Litecoin or any fiat-currency like North American nation bucks, Chinese RMB may be embedded into the safety token exchange.</p>
                                </li>   
                                <li>
                                    <h6>Payment Gateway Integration</h6>
                                    <p>The exchange platform can integrate a payment entry that permits users to shop for and sell mistreatment order currencies through credit cards, debit cards and different payment systems.</p>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </section>
        </Container>
        <Container>
            <section className='innerfaq2content' ref={(section) => { this.Indigo = section; }}>
                    <Row>
                    <Col sm={{size:11,offset:1}} className="faqdiffbor">
                            <h4>4) Benefits of Having A STO Exchange</h4>
                                <ul>
                                    <li>
                                        <h6>Extremely Secure</h6>
                                        <p>Every security exchange consists of layers of separated layers of servers, encrypted access to users, two-factors authentications, DDoS protection, X-XSS protection, communications protocol public key and Content Security Policy (CSP) protection.</p>
                                    </li>
                                    <li>
                                        <h6>99.9% Uptime</h6>
                                        <p>Security exchanges will keep running despite any failure, because of its inbuilt features: DNS failure, Geo IP Routing and Distributed Server design. this may make sure that the plus exchange platform remains on-line perpetually.</p>
                                    </li>
                                    <li>
                                        <h6>Escrow System</h6>
                                        <p>Any group action between the customer and marketer employing a third party or a wise contract is that the written agreement system. Once the accountable parties comply with the payment details, the written agreement releases the plus with none delays.</p>
                                    </li>
                                    <li>
                                        <h6>Customizable Exchange Platform</h6>
                                        <p>A customizable exchange platform permits the users to form their security token exchange and connect it to the first network. This ensures the liquidity of the exchange is maintained throughout.</p>
                                    </li>
                                </ul>
                        </Col>
                    </Row>
            </section>
        </Container>
        <Container>
            <section className='innerfaq3content' ref={(section) => { this.Blue = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>5) Stats of STO</h4>
                        <ul>
                            <li>
                                <p>In 2017 ST’s dominance was $100 M</p>
                            </li>
                            <li>
                                <p>In 2018 ST’s dominance is $2 Trillion</p>
                            </li>
                            <li>
                                <p>In 2020 ST’s dominance will be $100 Trillion</p>
                            </li>
                        </ul>
                </Col>
            </Row>
            </section>
        </Container>
        <Container>
            <section className='innerfaq3content' ref={(section) => { this.red = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>6) Why the market is bullish about the security tokens?</h4>
                    <img className="img-fluid mb-20 mt-20 faqbannerque" src={MarketBullish} title="Market Bullish" alt="Market Bullish" />
                    <p>A security is found to exist when all four of these elements exist</p>
                        <ul>
                            <li>
                                <p>Investment of money</p>
                            </li>
                            <li>
                                <p>In a common enterprise</p>
                            </li>
                            <li>
                                <p>With an expectation of profits</p>
                            </li>
                            <li>
                                <p>From the efforts of others</p>
                            </li>
                        </ul>

                        <p>If any cryptocurrency has signs of all these elements, then that will be condsidered as security tokens. It brings a lot of advantages over existing way of representing securities. Here is why the market is excited over the security tokens. </p>
                        <ul>
                            <li>
                                <p>It brings in the much-needed regulation in the market required for cryptographic tokens.</p>
                            </li>
                            <li>
                                <p>As a result, such projects will have more support from the investors and will have their trust.</p>
                            </li>
                            <li>
                                <p>This will bring more liquidity to the securities market.</p>
                            </li>
                            <li>
                                <p>Will be more cost-effective, secure, & fast in trading.</p>
                            </li>
                            <li>
                                <p>Will bring more automation to the securities market where an entire back office can be removed or minimized.</p>
                            </li>
                            <li>
                                <p>Lastly, more and more companies want to leverage the power of blockchains, smart contracts in their business which they can surely do through such programmable security tokens</p>
                            </li>
                        </ul>
                        <p>What advantages will it bring to the Securities Market</p>
                        <ul>
                            <li>
                                <p>Bringing Credibility Back</p>
                            </li>
                            <li>
                                <p>Improving Traditional Finance</p>
                            </li>
                            <li>
                                <p>Speeding up Execution</p>
                            </li>
                            <li>
                                <p>Exposure to Bigger Market</p>
                            </li>
                            <li>
                                <p>Huge Number of Investors</p>
                            </li>
                            <li>
                                <p>Lack of Institution Manipulation</p>
                            </li>
                            <li>
                                <p>Easier and quicker Liquidation</p>
                            </li>
                        </ul>
                </Col>
            </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>7) How to determine if a token falls into the crieteria of Security Token?</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={DetermineToken} title="Determine Token" alt="Determine Token" />
                    <p>There are certain qualities for anything to be considerd a security. Here are those crieterias</p>
                        <ul>
                            <li>
                                <p>There is an investment of money</p>
                            </li>
                            <li>
                                <p>There is an expectation of profits</p>
                            </li>
                            <li>
                                <p>The investment of money is in a common enterprise</p>
                            </li>
                            <li>
                                <p>Any profit comes from the efforts of a promoter or third party</p>
                            </li>
                        </ul>
                        <p>For tokens to be considered security tokens, we have similar crieterias. </p>
                        <ul>
                            <li>
                                <p>Is the token being sold as an investment?</p>
                            </li>
                            <li>
                                <p>Is there a person/entity upon whom investors rely?</p>
                            </li>
                            <li>
                                <p>Is there any benefits for holding the token? </p>
                            </li>
                            <li>
                                <p>Is the benefit equally shared between all the holders? </p>
                            </li>
                            <li>
                                <p>If the holders get ownership of any asset/debt?</p>
                            </li>
                            <li>
                                <p>If he gets right for voting and decision making for holding the token? </p>
                            </li>
                        </ul>
                        <p>These are the points which differentiate the security tokens from the other altcoins and tokens. Try to evaluate any cryptocurrencies on this crieterias to findout if it is a potential security token or not.</p>
                </Col>
            </Row>
            </section>
        </Container>
        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray1 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>8) Security Tokens Can Change The Market.</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={STNewBigThing} title="ST-New Big Thing" alt="ST-New Big Thing" />
                    <p>Even in its early days, Security Tokens have already started gaining traction in the market. Blockchain along with the regulation can certainly bring a lot of advantages over the currently available cryptocurrencies. Here is how it can change the global scenario completely</p>
                        <ul>
                            <li>
                                <p>Unlocking Liquidity</p>
                                <p>Traditional securities take a long time to settle. Sure, the settling process has sped up over the years. It’s gone from T+5 (trade date plus 5 business days), to T+3, and eventually what it is now: T+2. For a comparison of just how big this innovation is, consider what email did to snail mail. A few decades ago, you’d have to go through the painstaking process of mailing a letter or a fax in order to send written communications. People would think twice before going through that.</p>
                            </li>
                            <li>
                                <p>Opening Up Capital to Global Markets</p>
                                <p>Depending on the how the token is set up, it may be eligible for trading in global markets. For example, an investor in Germany could easily buy equity in a cafe in Florida with a few clicks of the mouse.</p>
                                <p>Just how much of the global market is possible? A leading platform for compliant token sales, conducted a round for Origin Protocol and attracted over 1800+ investors from over 50 countries worldwide to participate in the investment round.</p>
                                <p>This, again, adds liquidity and provides opportunities for investors to buy shares of assets that were previously much more difficult to attain.</p>
                            </li>
                            <li>
                                <p>24/7 Trading3. </p>
                                <p>Security tokens allow for 24/7 markets, which eliminates the huge inefficiencies of daily market closures.</p>
                            </li>
                            <li>
                                <p>Automated Compliance</p>
                                <p>One of the most difficult aspects of trading securities is adhering to regulations. This is because 1) regulations can vary by asset type, investor type, and buyer/seller/issuer jurisdictions, and 2) you typically need to document regulatory compliance through a series of separate ledgers.</p>
                                <p>But because security tokens are programmable, compliance can be baked right into the token. The hassles of being compliant are greatly reduced, as legally compliant protocols like Polymath and Harbor work together with exchanges like tZERO, 0x, and more. This allows compliance to become exponentially easier, and just about automatic once the systems are in place.</p>
                            </li>
                            <li>
                                <p>Programmable Assets and Securities</p>
                                <p>Dividend Release</p>
                                <p>Voting and Governance Rights</p>
                                <p>Special Rights and Privileges</p>
                                <p>To understand how this will be more effective with security tokens, let's take an example. For example, if Joe owned X amount of Y security token he would be able to promote it to his network and be rewarded with more security tokens. </p>
                            </li>
                            <li>
                                <p>Asset Interoperability</p>
                                <p>In a future where just about anything can be tokenized, this opens the door to asset interoperability – the ability of computer systems or software to exchange and make use of information. </p>
                            </li>
                            <li>
                                <p>Fractional Ownership and Increased Market Depth</p>
                                <p>With security tokens, you get the benefits of fractionalized ownership. Instead of spending millions of dollars for an entire property (or even a piece of artwork), you can spend far less and buy a fraction of that property. Then, if you want to hedge your bet, you can buy a fraction of another property in a different part of town or even in a different part of the world.</p>
                            </li>
                        </ul>
                </Col>
            </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray2 = section; }}>     
            <Row>
                <Col sm={{size:11,offset:1}}>
                    <h4>9) How are tokens classified?</h4>
                    <table className="Faqtable">
                        <tr>
                            <th></th>
                            <th>Currency/Asset</th>
                            <th>Security</th>
                            <th>Equity</th>
                            <th>Utility</th>
                            <th>Debt</th>
                        </tr>
                        <tr>
                            <td>Definition</td>
                            <td>Tokens with the primary function to serve as digital money. Unlike all other tokens, this is property with inherent value. These tokens do not represent third-party value and take their price from market forces like a standard commodity.</td>
                            <td>Tokens which function as a traditional security asset. They represent a stake in the wealth created by a third party and take their value from that party’s success or failure. Distinct from an equity token in that no ownership of the underlying venture is created.</td>
                            <td>Tokens which function as a traditional stock asset. They represent ownership of some third-party asset or venture and take their value from that property’s success or failure.</td>
                            <td>Platform-dedicated tokens which serve as the medium or “coupon” for blockchain platform services. They take their value from the value of their dedicated platform.</td>
                            <td>Tokens which represent outstanding debts and liabilities. They take their value from the debt, its interest and the creditworthiness of the debtor party.</td>
                        </tr>
                        <tr>
                            <td>Real World Analogue</td>
                            <td>Gold, silver, dollars, and euros</td>
                            <td>Some mutual funds and ETFs, nonequity investments made against capital rather than debt. This security type is relatively uncommon outside of blockchain.</td>
                            <td>Stocks, futures, and options contracts</td>
                            <td>Arcade tokens</td>
                            <td>Bonds, mortgages</td>
                        </tr>
                        <tr>
                            <td>Regulatory Approach</td>
                            <td>Hands off by the SEC but increasingly scrutinized by the IRS.</td>
                            <td>Treated as a standard securities transaction by the SEC.	</td>
                            <td>Treated as a standard securities transaction by the SEC.</td>
                            <td>Hands off by the SEC, although the commission is skeptical of this category’s legitimacy.</td>
                            <td>Treated as a standard debt security transaction by the SEC.</td>
                        </tr>
                    </table>
                </Col>
            </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray3 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>10) How Security token can liquidize the illiquid assets/securities?</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={ImgpshFullsizeAnim} title="Imgpsh Fullsize Anim" alt="Imgpsh Fullsize Anim" />
                    <p>You may have a good investment hypothesis that an apartment building down the street is going to appreciate, but you may not have a couple million USD to put up to buy it — and if you do, you better be really confident because it’s going to be hard to sell. Because it’s difficult to sell, that is how the asset or securities are illiquid. Further more, it is difficult for people to invest in the asset or security in other countries. </p>
                        <ul>
                            <li>
                                <p>You can invest in a restaurant, but there’s no way to get that money out if you need it for anything else: you have to wait until the restaurant is sold or pays dividends.</p>
                                <p>By contrast, if you want to buy stock in a company, you can buy a single share for a couple hundred bucks and sell it in minutes if you change your mind.</p>
                            </li>
                            <li>
                                <p>Tokenized securities allow for businesses to lock in funds without locking investors in because the tokens are tradable on a secondary market. The business still gets to use the capital, but investors can exchange shares with each other.</p>
                                <p>With a token, you can code restrictions, enabling buyers and sellers to trade in a secondary market as long as the trade doesn’t violate the coded restrictions, making it easier for the cross border tradinng and even removing the need of central authority.</p>
                            </li>
                        </ul>
                </Col>
            </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray4 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>11) How does tokenization work? </h4>
                    <p>Let’s say an investor wants to tokenize a Diamond; they can create a tradable security token and put the Diamond into a legally binding trust. After the Diamond has gone into liquidity it will have multiple token holders, who now collectively own the asset. The Diamond’s security tokens are then stored on a blockchain smart contract and served as collateral. If multiple investors co-own the Diamond, then the smart contract becomes ‘multi-signature.’ Smart contracts are an algorithmic software license, which verifies the terms and conditions, in a conflict-free manner without third-party interference.</p>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={how_works} title="How does tokenization work?" alt="How does tokenization work?" />
                    <p>The token holders’ agreement will be encrypted and distributed equally, so their legally binding agreement cannot be lost or changed without their permission. Changes can only take place when both or all stakeholders agree on a given matter, which will help cement trust and security amongst investors.</p>
                    <p>Key benefits of tokenization</p>
                        <ul>
                            <li><p>Investors can trade real-world assets that due to their low liquidity would have been difficult to exchange in the past.</p> </li>
                            <li>  <p>Tokens enhance the liquidity of previously hard assets with real estate being the prime example, with its low liquidity and large share of the global asset market.  </p> </li>
                            <li>   <p>Asset tokenization reduces barriers and will attract new investors through fractional ownership, as they can own a percentage of a real-world asset and repackage it with traditional bonds or hedge funds to resell in crypto marketplaces.</p> </li>
                            <li>   <p>Tokenization increases portfolio diversification and spreads risk as investors can now co-own multiple assets at once.</p> </li>
                            <li>   <p>Tokenization is a seamless process and speeds up previously difficult and costly trades by reducing administrative expenses through self-authorizing smart contracts, improving the speed of settlements.</p> </li>

                           
                            <li>
                                <p>Tokenized securities allow for businesses to lock in funds without locking investors in because the tokens are tradable on a secondary market. The business still gets to use the capital, but investors can exchange shares with each other.</p></li>
                                <li>  <p>With a token, you can code restrictions, enabling buyers and sellers to trade in a secondary market as long as the trade doesn’t violate the coded restrictions, making it easier for the cross border tradinng and even removing the need of central authority.</p></li>
                            
                        </ul>
                </Col>
            </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray5 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>12) Why Security Tokens are the big deal? </h4>
                    <p>ICOs got traction in 2017 and raised $5.6 Billion. In the first quarter of 2018 the raised amount was USD 7 Billion and the market went crazy after it. Though, in the next 2 quarters the capitals took huge blow and had been falling since then. But still it managed to raise money, and the total amount concludes to $12.3B. If we calculate the average capital for every token, we’ll get a whooping $29.7M. </p>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={TrendsOf2019} title="Trends Of 2019" alt="Trends Of 2019" />
                    <p>Well, that was just about the ICOs. The coins or tokens with some services or some good idea with them. But, after the fall of Cryptocurrency in 2018, the world has started thinking about the real use case of the blockchain and that is tokenizing the rel world assets/secuities and put them all on blockchain. </p>
                    <p>Here are some stats for the securities being traded. The entire worlds stock exchanges have a capitalization of $80 trillion USD (trending up from $25 trillion in 2009 a 320% increase). The largest uptrend over the last 20 years is the growth of algorithmic trading. Over 1 year 60.49% of fund managers failed to beat the market index. Now consider getting the securities being tokenized and started getting traded globally on 24 hrs open market and without much intereference of the middliers. </p>
                    <p>The tokenization seems to be solving the problems of current system of security trading by making it easier to liquidize the asset and giving it a 24hrs open global market making it easier to buy or sell securities across the globe. Hence, we are forseeing a booming growth in this industry and it is likely that 80% of total securities will be tokenized by 20-20 making it a 100 Trillion dollar industry in coming 10 years. </p>    
                </Col>
            </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray6 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>13) Ecosystem of Security Token </h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={EcosystemSecurityToken} title="Ecosystem of Security Token" alt="Ecosystem of Security Token" />
                    <p>As security tokens and tokenized assets gain steam in the crypto ecosystem, clear infrastructure begins to form. As we previously covered in our social posts series on security tokens, these new instruments aim to tokenize the ownership of all assets, whether they are public and private equities, real estate, precious metals or debt — even things like art. Investors are clearly taking notice as they pump millions of dollars into projects that support the issuance, exchange, and custody of security tokens. </p>
                    <p>We can categorized the security token ecosystem into six sub-categories</p>
                    <ul>
                        <li>Issuance</li>
                        <li>Broker-Dealers</li>
                        <li>Custody & Trust</li>
                        <li>Legal</li>
                        <li>Compliance And </li>
                        <li>Trading</li>
                    </ul>
                    <p>We will see them one by one in our next posts... </p>
                </Col>
            </Row>
            </section>
        </Container>


        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray7 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>14) What is the significance of issuance in security token exchange? </h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={Issuance} title="Issuance" alt="Issuance" />
                    <p>Out of the six components of the Security Token Ecosystem, the first one is Issuance. Issuance platforms enable issuers to tokenize and issue their assets, making them available for sale. These platforms offer tokenization features for a wide range of assets including real estate, debt, equity, and art. Tokenized assets can operate on a number of different blockchains including Ethereum, Bitcoin, and Ravencoin. All tokenized assets are pegged to an underlying asset, whether that is something like the Empire State Building. If the market changes its perception of the price of the asset, the tokens should move in value as well.</p>
                    <p>Here are some platforms for Security Token Issuance: </p>
                    <ul>
                        <li>Polymath</li>
                        <li>Tokensoft</li>
                        <li>Issuance</li>
                        <li>Tokeny</li>
                        <li>Indegogo</li>
                        <li>Securency</li>
                        <li>Securitize</li>
                        <li>SeedInvest</li>
                    </ul>
                </Col>
            </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray8 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>15) What is the broker dealer component of security token exchange?</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={BrokerDealer} title="Broker Dealer" alt="Broker Dealer" />
                    <p>The second important component of the Security Token Ecosystem is the Broker Dealer. Broker-dealers are buyers, sellers, and distributors of security tokens. As dealers, they initiate transactions on behalf of brokerage firms. As brokers, they buy and sell securities on behalf of their clients. Broker-dealers, essentially, help provide liquidity to the market for a security token.</p>
                    <p>Here are some of the broker dealers of the Security Tokens: </p>
                    <ul>
                        <li>Propellr</li>
                        <li>Atomic</li>
                        <li>Uphold</li>
                        <li>EnToro</li>
                        <li>Coin Base</li>
                    </ul>
                </Col>
            </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray9 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>16) What is the custody & trust component of security token exchange?</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={CustodyTrust} title="Custody And Trust" alt="Custody And Trust" />
                    <p>Custody & Trust</p>
                    <p>After the Broker Dealers, Custody and Trust Providers are th essebtial part of this Security Token Ecosystem. Custody & Trust providers help hold tokenized assets — on behalf of clients — to protect them against theft or loss. These providers offer their services to multiple players in the security-token ecosystem, including investors, broker-dealers, and trading platforms.</p>
                    <p>Here are some firms who work as custody and trust provider: </p>
                   <ul>
                        <li>PrimeTrust</li>
                        <li>Six</li>
                        <li>Bankex</li>
                        <li>BitGo</li>
                    </ul>
                </Col>
            </Row>
            </section>
        </Container>
        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray10 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>17) What is the legal component of security token exchange?</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={Legal} title="Legal" alt="Legal" />
                    <p>                   
</p>
                    <p>Here are some firms who can help with the legal framework for the security tokens: </p>
                   <ul>
                        <li>SheppardMullin</li>
                        <li>Cooley</li>
                        <li>FisherBroyles</li>
                        <li>DLA Piper</li>
                        <li>PerkinsCoie</li>
                        <li>Lowenstein Sandler</li>
                    </ul>
                </Col>
            </Row>
            </section>
        </Container>
        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray11 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>18) What is the compliance component of a security token exchange?</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={Compliance} title="Compliance" alt="Compliance" />
                    
                    <p>Here we go with the 5th component of the Security Token Ecosystem, the Compliance. Compliance providers offer services for security token issuers to stay consistent with current regulations. These include investor verification, know-your-customer rules, cap-table management, and fraud management.</p>
                    <p>Here are some firms who can help Issuers with the compliance for their security tokens: </p>
                   <ul>
                        <li>Vertalo</li>
                        <li>Chainpoint</li>
                        <li>EarlyIQ</li>
                        <li>VerifyInvestor.com</li>
                        <li>TransitNet</li>
                        <li>CoreConX</li>
                        <li>CoinFirm</li>
                        <li>Onfido</li>
                    </ul>
                </Col>
            </Row>
            </section>
        </Container>
        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray12 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>19) How Does The Trading Platform Work In Security Token Exchange?</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={SecurityTokenExchange} title="Security Token Exchange" alt="Security Token Exchange" />
                    
                    <p>The last but not the least and very important and interesting component of the Security Token Ecosystem: Trading Platform. Trading platforms enable traders to buy and sell security tokens. These platforms can service both primary and secondary markets. Primary-market trading platforms can also offer issuance services, operating as hybrid issuance and trading platforms.</p>
                    <p>Here are some of the trading platforms for the Security Tokens: </p>
                   <ul>
                        <li>Stellar X</li>
                        <li>TZero</li>
                        <li>Six</li>
                        <li>Fluidity</li>
                        <li>SharesPost</li>
                        <li>Bancor</li>
                    </ul>
                </Col>
            </Row>
            </section>
        </Container>
        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray13 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>20) What are Equity Tokens?</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={EquityTokens} title="Equity Tokens" alt="Equity Tokens" />
                    
                    <p>Equity tokens are the most common form of security tokens and in many cases, investors believe that the terms equity and security token are synonymous. On the contrary – they do not mean the same thing and the terms should not be used interchangeably. Part of what makes equity tokens so attractive to investors outside the crypto space is their similarity to equity shares in a company. These tokens earn issuers the capital they need to develop a network, and in exchange, investors purchasing equity tokens could earn returns such as dividends and in some cases, the right to vote on company proposals.</p>
                </Col>
            </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray14 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>21) What Are Debt Tokens?</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={DebtTokens} title="Debt Tokens" alt="Debt Tokens" />
                    
                    <p>Debt tokens are issued out by lenders and represent debt owned by a company. They can be thought of as loans or IOUs often with an interest rate multiplied or compounded against the principal amount loaned (invested) to a company. They are a type of capital raised through debt that enables the buying and selling of loans within a high-liquidity environment. Depending on the wording of the legal agreements, as well as the structure and functions available in the token, debt tokens may incur unique tax and reporting requirements for anyone issuing, or in some jurisdictions even transacting with, the token.</p>
                </Col>
            </Row>
            </section>
        </Container>
        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray15 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>22) What Are Derivatives?</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={Derivatives} title="Derivatives" alt="Derivatives" />
                    
                    <p>Derivatives form the foundation of financial stability in traditional financial markets. They are used to transfer risk from one person to another and can be thought of as insurance contracts on the variation value expressed on an underlying asset. Prediction Markets are in their infancy and have begun placing option bets on the future of specific stock based on derivative products. Financial derivatives are not as common in the crypto space today but many projects are emerging and with security tokens becoming popularised many believe this is set to change sooner than later.</p>
                </Col>
            </Row>
            </section>
        </Container>
        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray16 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>23) What is the state of security token in Europe?</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={SecurityTokensEurope} title="Security Tokens In Europe" alt="Security Tokens In Europe" />
                    
                    <p>Europe continues to see more counties taking a pro-crypto stance. Countries such as the UK, Switzerland, and Malta, have already made huge strides towards furthering their respective positions in the market. While there are many contributing factors behind these moves, the introduction of security tokens has played a huge roll in these countries decision.</p>
                    <p>Security tokens allow for the digitization of traditional investments. This method is called tokenization. Tokenized assets are considered more stable than traditional cryptocurrencies by most countries. Currently, security tokens fall under the EU’s MiFIDII regulations.</p>
                </Col>
            </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray17 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>24) What is Markets In Financial Directive II (MiFID II)?</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={MIFID} title="Markets In Financial Directive" alt="Markets In Financial Directive" />
                    
                    <p>The EU instituted MiFID II regulations on January 3, 2018. This legislative framework provides investors with added protections. Additionally, MiFID II regulations improve the functionality and efficiency of the market. The legislation is a follow up to the original MiFID (Markets in Financial Directive) regulations which have been in operation since November 2007.</p>
                </Col>
            </Row>
            </section>
        </Container>
        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray18 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>25) What is the state of security token in Liechtenstein?</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={Liechtenstein} title="Liechtenstein" alt="Liechtenstein" />
                    
                    <p>The sixth smallest country in the world, Liechtenstein continues to develop their blockchain sector at an impressive rate. Despite being only 62 square miles in total, this tiny country ranks among the highest in terms of GDP per capita. In October 2018, the country released a public draft titled the “National Blockchain Act.”</p>
                    <p>The document highlighted the counties desire to further their blockchain aspirations. The move was quickly followed by an announcement by the country’s Union Bank AG. Here, the bank described their intention to be the first regulated bank issuing a security token. The bank looks to create a token for interbank activities such as transferring large amounts of funds internationally.</p>
                </Col>
            </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray19 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>26) The State Of Security Tokens In Germany</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={SecurityTokensInGermany} title="Security Tokens In Germany" alt="Security Tokens In Germany" />
                    
                    <p>Germany does not consider cryptocurrencies as financial instruments. In September 2018, the country determined that Bitcoin traders don’t need any licensing and that trading cryptocurrency should remain legal. This was a major ruling in favor of crypto investors. This ruling meant that crypto investors did not need to adhere to Germany’s securities regulations.</p>
                    <p>In December 2018, Germany’s second largest stock exchange announced plans to launch a crypto token exchange. The Boerse Stuttgart Group partnered with the local Fintech firm SolarisBank to make the project a reality. The exchange’s release date is Q3 2019. According to developers, a variety of cryptocurrencies including utility, security, and exchange tokens will be hosted.</p>
                </Col>
            </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray20 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>27) What is the state of security tokens in Switzerland?</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={Switzerland} title="Switzerland" alt="Switzerland" />
                    
                    <p>Switzerland has long been a financial epicenter in Europe. Swiss officials are keen on extending their financial influence into the blockchain sector. The country recently opened a blockchain business sector dubbed the Crypto Valley. Here, blockchain startups receive reduced taxes and other benefits provided by the Swiss government.
Aside from financial benefits, Swiss-based blockchain firms benefit greatly from the country’s clear cut blockchain regulations. The Swiss Financial Market Supervisory Authority (FINMA) breaks tokens into four distinct categories. These categories include asset, payment, utility, and hybrid tokens.</p>
                    <p>Aside from financial benefits, Swiss-based blockchain firms benefit greatly from the country’s clear cut blockchain regulations. The Swiss Financial Market Supervisory Authority (FINMA) breaks tokens into four distinct categories. These categories include asset, payment, utility, and hybrid tokens.</p>
                </Col>
            </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray21 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>28) What is the state of security tokens in Malta?</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={Malta} title="Malta" alt="Malta" />
                    
                    <p>Malta continues to be a driving crypto force in the region. This country took the crypto reigns through a combination of factors. Malta was the first country in the world to provide a solid regulatory framework for crypto investors and ICOs. The government is pro-active in recruiting new startups to their market.</p>
                    <p>Today, Malta is one of the best locations in the world to launch your STO. The country already is home to numerous large crypto platforms. The worlds largest crypto exchange by volume, Binance, partnered with the Maltese Stock Exchange (MSX) in September of last year. The two plan to build a new security token exchange within the country in the coming months.</p>
                </Col>
            </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray22 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>29) What is the state of security tokens in Estonia?</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={Estonia} title="Estonia" alt="Estonia" />
                    
                   <p>Estonia was among the first EU members to legalize crypto activities. The country already approved over 900 crypto licenses since changing regulations last year. Those licensed include a variety of crypto businesses such as exchanges, startups,  and blockchain tech firms. Around 400 of the licenses issued belonged to crypto wallet providers according to recent reports.</p>
                   <p>Estonia managed to go from the poorest country in the EU to a thriving economy through a combination of factors. Tax incentives, a friendly business environment, and easy licensing procedures all contributed to the country’s rise in the blockchain sector. Today, Estonia is one of the most STO friendly countries in the world.</p>
                </Col>
            </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray23 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>30) What is the state of security tokens in France?</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={France} title="France" alt="France" />
                    
                   <p>France took a much different approach regarding cryptocurrency firms. The Authorité des Marchés Financiers (AMF) released ICO guidelines last year. The new regulations require all ICOs to provide full transparency regarding their offerings. Companies must define their tokens use prior to approval. This demand is required so that officials can determine what type of token the company plans to issue.</p>
                   <p>France’s stance on ICOs is mimicked by a plethora of other countries around the globe. By making all ICOs operate in full disclosure, the country managed to simultaneously welcome STOs while reducing the number of ICOs in operation. STOs provide a much safer way for investors to participate in blockchain fundraising raising campaigns.</p>
                </Col>
            </Row>
            </section>
        </Container>
        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray24 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>31) What is the state of security token in UK</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={UK} title="UK" alt="UK" />
                    
                   <p>The UK currently does not have crypto regulations in place. The country continues to research the cryptomarket with regulators claiming that it could be years before proper regulatory guidelines can be developed. In March 2018, the countries Crypto assets Taskforce issued a report in which cryptocurrencies were listed in three distinct classes. These classes include security tokens, utility tokens, and exchange tokens.</p>
                   <p>Currently, the UK crypto debate continues. The British Business Federation Authority (BBFA) released a report in which they detailed how bad regulations would hinder growth in the sector much more than “no regulations at all.” As it stands today, the UK has a strong blockchain community with analysts predicting future growth in this sector.</p>
                </Col>
            </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray25 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>32) What is the scenario of security token scenario in Slovenia?</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={Slovenia} title="Slovenia" alt="Slovenia" />
                    
                   <p>On January 18, 2018, the Bank of Slovenia warned citizens that virtual currencies are not a digital replacement for banknotes and coins, and are not regulated. The Bank explained that entities purchasing, depositing, or trading virtual currencies in Slovenia are not systematically regulated and supervised. It advised citizens to inform themselves about virtual currencies before buying them and to be aware that they could lose their investments in those currencies.[314] Following the Bank’s warning commercial banks reportedly stopped selling cryptocurrencies via ATMs.
</p>
                   <p>Earlier on October 9, 2017, the Financial Stability Board recommended that investors in virtual currencies consider whether the risks are in line with their personal preferences and investment goals,[316] and that investors in ICOs should invest in amounts that would not leave them too exposed</p>
                </Col>
            </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray26 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>33) What is the state of security tokens in Ecuador?</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={Ecuador} title="Ecuador" alt="Ecuador" />
                    
                    <p>The Central Bank of Ecuador has stated that Bitcoin is not an authorized payment method in Ecuador. It further clarified that the bitcoin, as a cryptocurrency, is not backed by any authority, because its value is based merely on speculation.Furthermore, financial transactions with bitcoins are not controlled, supervised, or regulated by any Ecuadoran entity, and therefore they represent a financial risk for those who invest in them.</p>
                    <p>The Central Bank also stated, however, that the purchase and sale of cryptocurrencies such as bitcoin through the internet are not forbidden, but it reiterated that bitcoin is not legal tender and is not an authorized payment method for goods and services according to the Código Orgánico Monetario y Financiero (Organic Monetary and Financial Code).</p>
                </Col>
            </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray27 = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}}>
                    <h4>34) Security Token Offering And Banking Security</h4>
                    <img className="img-fluid  mb-20 mt-20 faqbannerque" src={BankingSecurity} title="Security Token Offering And Banking Security" alt="Security Token Offering And Banking Security" />
                    
                    <p>Contrary to the initial coin offering, ICO, which do not entirely give any kind of
                        rights or obligations- but rather provide access to a platform, network or service,
                        security token offerings, STOs, are real financial securities which have the
                        backing of tangibles like profits, company's revenue and assets. More so, they
                        offer legal rights to users, one of which is voting.</p>
                    <p>In the banking industry basically, security tokens serve the same purpose as
                        conventional security. The only difference is that the ownership has to be
                        confirmed through blockchain transactions, and more importantly, make
                        fractional ownership pretty possible. As much as they are viable and purposeful,
                        they are subject to certain federal laws that bind securities for the benefits of
                        protecting investors on some grounds. Fundamentally, some of its benefits,
                        which transcends traditional security&#39;s are cost-effectiveness, fast/efficient,
                        global trading capability, 24/7 trading, etc.</p>
                    <p>As an example, a Brazilian investment bank in 2015 planned to raise millions of
                        dollars through security tokens investment. Bankco BTG Pactual announced its
                        interest in backing its blockchain-based token with distressed real estate assets
                        in the country to raise about $15 million. Not only that, but it also planned to
                        establish some kind of secondary market with the sale to give the tokens some
                        liquidity.Meanwhile, the question of whether or not STOs would rule over ICOs in 2019 is
                        subject to its attributes which prompt organizations to consider buying into it.
                        For instance, an organization is likely to consider an STO if they desire greater
                        liquidity for their stakeholders, are interested in generating $10 million revenue
                        per annum, are operating a global business, to mention a few. Its glamorous
                        future in fundraising also makes it really considerable, especially as a tool for
                        banks.</p>
                </Col>
            </Row>
            </section>
        </Container>
        
        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray28 = section; }}>     
                <Row>
                    <Col sm={{size:11,offset:1}}>
                        <h4>35) How Does The Security Token Work With The Financial Center?</h4>
                        <img className="img-fluid  mb-20 mt-20 faqbannerque" src={TheFinancialCenter} title="Security Token Work With The Financial Center" alt="Security Token Work With The Financial Center" />
                        <p>
                            The hype behind security tokens these days is immense. Whether or not they are
                            worth your time and the noise (hype) is dependent on how well they serve
                            within the tenets to the value of a token- that is role, features, and purpose. A
                            security token is simply a crypto token which passes the Howey Test, and their
                            value is mostly derived from a tradable and external asset. As long as they can
                            meet every concerned regulation, they use-cases remain incredible.
                        </p>
                        <p>Someone might now want to ask what this has to do with financial centers.
                            Really, their characteristic to represent assets in the real world make them some
                            kind of bridge between the blockchain world and legacy finance. Specifically, the
                            actual changes being brought to the world of finance are: restoration of
                            credibility, fast execution and improved traditional finance. Ideally, traditional
                            financial transactions can be somewhat expensive because of the associated fees.</p>
                        <p>Moreover, exposure to free market and increase in number of investors signals
                            security token use cases in the financial context. That is, tokenizing securities is
                            capable of turning non-securities into securities. With 2019 being a year of
                            anticipated blockchain infusion, security tokens investment are increasing.</p>  
                        <p>Since financial services form about 15% of the world economy, it is expected that
                            significant part of the sector will undergo necessary digitization and integration.
                            And in few months time from now, activities of issuers and investors under some
                            of the known global securities regulation, disclosure practices, and evolving
                            regulation of the blockchain system as well as smart contract-auditing
                            procedures would be enormously stepped up.</p>      
                    </Col>    
                </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray29 = section; }}>     
                <Row>
                    <Col sm={{size:11,offset:1}}>
                        <h4>36) Tokenization Of Healthcare Assets</h4>
                        <img className="img-fluid  mb-20 mt-20 faqbannerque" src={HealthcareAssets} title="Tokenization Of Healthcare Assets" alt="Tokenization Of Healthcare Assets" />
                        <p>As a result of the tremendous potential of data tokenization, many industries are
                            leveraging the benefits and the impact to manage and secure assets. Essentially,
                            the idea of data tokenization is to streamline the process of managing assets, via
                            integration of blockchain technology with real-life assets. To be specific, one of
                            the most prominent interests is in the healthcare industry, and particularly on
                            ways through which data tokenization improves patient security. The application
                            of tokenization solutions to situation under HIPAA is now a common thing in the
                            industry. That is, healthcare enterprises can tap from the benefits of the
                            incredible security provided by the technology.</p>
                        <p>Moreover, it makes the process of complying with certain HIPAA regulations
                            more convenient for administrators, through replacement of the traditional
                            electronic protected health information (ePHI) and non-public personal
                            information (NPPI) with a tokenized value. That way, patients&#39; information can
                            be more easily accessible and protected. While it may just be clear in about a
                            year or two whether security token offerings, STOs have become generally
                            acceptable, they are currently on their way.</p>
                        <p>In another dimension, the U.S. Food and Drug Administration (FDA) are
                            currently encouraging the use of Cooperative Research and Development
                            Agreements (CRADAs) to enhance public-private partnerships, while other
                            initiatives within the context are occurring at the State level. Interestingly, this is
                            not just limited to the United States, as we have similar picture worldwide.</p>    
                        <p>Thus, to incentivize patients to further engage in healthy behavior, for the
                            purpose of simplifying the legal and mechanical approach to security tokens
                            investment, a decentralized healthcare is poised to attract cryptohealth. It can
                            also contribute to necessary factional ownership.</p>    
                    </Col>    
                </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray30 = section; }}>     
                <Row>
                    <Col sm={{size:11,offset:1}}>
                        <h4>37) How Security Tokens Could Be Implemented In Payment Systems?</h4>
                        <img className="img-fluid  mb-20 mt-20 faqbannerque" src={STOPaymentSystems} title="Security Tokens Could Be Implemented In Payment Systems" alt="Security Tokens Could Be Implemented In Payment Systems" />
                        <p>The use of credit cards has been a real applicable method for financial transactions. However, security issues have been the greatest challenge to their use. </p>
                        <p>To solve this problem for once, credit card tokenization could be introduced. The aim is to keep unsecured data and for going through enterprise systems. In essence, the technology uses a randomly generated code (T) instead of a credit card number. This code has no value to hackers. </p>
                        <p>The data cannot go into enterprise systems like ERP, CRM, legacy applications and eCommerce sites. This is the most secure way to keep financial information and other personal data safe.</p>
                        <h4>How does tokenization work?</h4>
                        <p>Your card number is encrypted and stored offsite in a PCI-compliant data vault. This is a Payment secure gateway that allows you to use the token as if it were a real card. You can use it for customer requests and facilitation of reports in a clean manner. No daily operations are interrupted.</p>
                        <h4>Tokenization:-</h4>
                        <ul>
                            <li>Keeps user data in the safest way</li>
                            <li>Keeps source systems and PCI compliance separate</li>
                            <li>The maintenance cost for PCI DSS compliance is reduced </li>
                        </ul>
                        <p>The whole process is achieved through tokenized solutions. Tokenization is utilized as a prevention measure to ensure no raw card numbers enter the merchant’s system.</p>
                        <p>Instead of providing the real card number, the token systems open a different secure field. It returns a token in its stead.</p>
                        <p>Security is paramount in an online transaction. This has been the major fear for many using cryptocurrency. Security tokens are the solution. </p>
                    </Col>    
                </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray31 = section; }}>     
                <Row>
                    <Col sm={{size:11,offset:1}}>
                        <h4>38) State of Security Token Offerings In Australia</h4>
                        {/* <img className="img-fluid  mb-20 mt-20 faqbannerque" src={STOAustralia} title="State of Security Token Offerings In Australia" alt="State of Security Token Offerings In Australia" /> */}
                        <img className="img-fluid  mb-20 mt-20" src={StateSTOGraphAus} title="State of Security Token Offerings In Australia" alt="State of Security Token Offerings In Australia" />
                        
                        <p>Satoshi Nаkаmоtо, the pseudonymous сrеаtоr оf Bitсоin, originally еnviѕiоnеd his elegant solution tо thе dоublе-ѕреnd рrоblеm of digital сurrеnсiеѕ as a method оf dесеntrаlizing mоnеу. The potential use саѕеѕ оf blockchain tесhnоlоgу, hоwеvеr, аrе dеmоnѕtrаblу fаr widеr in ѕсоре. </p>
                        <p>It didn’t take lоng for innоvаtоrѕ аrоund thе wоrld tо rеаlizе thаt virtuаllу аnуthing could bе tоkеnizеd аnd decentralized thrоugh thе аррliсаtiоn оf the distributed ledger technology that drivеѕ Bitcoin — including thе wау in which companies аnd рlаtfоrmѕ аrе created аnd fundеd.</p>
                        <p>Thuѕ, the “initiаl соin оffеring,” or ICO wаѕ born. Inѕtеаd оf рurѕuing vеnturе сарitаl funding оr lаunсhing initiаl рubliс оffеringѕ, blockchain tесhnоlоgу enterprises bеgаn lаunсhing tоkеnizеd, сrоwdfundеd vеnturеѕ thаt hаvе raised billiоnѕ оf dollars thrоugh dесеntrаlizеd capital gеnеrаtiоn events. </p>
                        <p>Sесuritу tоkеn оffеringѕ have еmеrgеd аѕ a result оf thе problems сrеаtеd by the initial соin оffеring induѕtrу. ICOѕ аllоw blосkсhаin tесhnоlоgу рlаtfоrmѕ to connect directly with роtеntiаl users оf futurе products, сарturing startup capital bу оffеring invеѕtоrѕ blосkсhаin tоkеnѕ intеndеd fоr use оn thе platform subsequent tо launch.</p>
                        <p>Eаrlу еxаmрlеѕ оf highlу successful ICOѕ inсludе NEO, Stоrj and Ethеrеum. The rарid expansion of thе ICO induѕtrу, hоwеvеr, mаdе it almost imроѕѕiblе fоr rеgulаtоrѕ to сrеаtе rеgulаtоrу frаmеwоrkѕ in order tо рrоtесt invеѕtоrѕ frоm frаudѕtеrѕ аnd еxit ѕсаmѕ. </p>
                        <h4>How STOs Wоrk</h4>
                        <p>At a bаѕiс lеvеl, a “ѕесuritу” iѕ a finаnсiаl inѕtrumеnt thаt is issued bу a соmраnу in оrdеr to rаiѕе сарitаl. Invеѕtоrѕ that рurсhаѕе securities аrе рrоvidеd with specific rights in rеturn fоr thеir invеѕtmеnt, such as intеrеѕt gеnеrаtеd on a рrinсiраl in the саѕе оf bоndѕ, оr раrtiаl ownership оf a company or dividends in thе case оf ѕtосkѕ. </p>
                        <p>STOѕ оffеr invеѕtоrѕ the орроrtunitу tо рurсhаѕе ѕесuritу tоkеnѕ, which perform the ѕаmе functions аѕ trаditiоnаl ѕесuritiеѕ. Thе most important difference between a security token аnd a traditional security is thе blосkсhаin — ѕесuritу tоkеnѕ are digitized аnd еxiѕt on diѕtributеd lеdgеrѕ, аnd thuѕ оffеr mаnу mоrе features than a ѕtосk сеrtifiсаtе, fоr еxаmрlе. Security tоkеnѕ, likе traditional securities, are ѕubjесt to ѕесuritiеѕ lаw. </p>
                        <p>Thе Auѕtrаliаn Sесuritiеѕ аnd Investments Commission hаѕ рubliѕhеd extensive dосumеntаtiоn аnd guidаnсе оn initiаl coin offerings. The ASIC реrѕресtivе оn tоkеn offering ѕесuritiеѕ classification iѕ clear — initial coin offerings аrе аѕѕеѕѕеd оn a саѕе-bу-саѕе bаѕiѕ and, ѕhоuld thеу mееt ѕресifiс сritеriа, аrе dеfinеd accordingly.</p>
                        <p>Token offerings that meet thе dеfinitiоn оf a managed investment ѕсhеmе undеr Auѕtrаliаn ѕесuritiеѕ law, fоr еxаmрlе, must аbаndоn the ICO mоdеl аnd fulfill product disclosure, liсеnѕing аnd роtеntiаllу mаnаgеd investment ѕсhеmеѕ registration obligations under thе Corporations Act.</p>
                        <p>Tоkеn оffеringѕ thаt provide invеѕtоrѕ with thе орроrtunitу to participate in the соrроrаtе gоvеrnаnсе оf аn еntеrрriѕе аrе likеlу tо be defined as “shares” by thе ASIC, аnd thuѕ must issue a рrоѕресtuѕ in the same manner аѕ an IPO. </p>
                        <p>Thе ASIC has еѕtаbliѕhеd ѕimilаr rеgulаtоrу frameworks for initial coin оffеringѕ thаt rеѕеmblе derivatives, non-cash рауmеnt fасilitiеѕ, finаnсiаl mаrkеtѕ, аnd complex financial рrоduсtѕ. Auѕtrаliаn securities lаw iѕ primarily focused оn сlаѕѕifуing any tоkеn оffеring thаt offers any tоkеn not ѕtriсtlу dеfinеd аѕ a utilitу tоkеn within еxiѕting ѕесuritiеѕ lаw, аnd dоеѕ not yet offer any specific guidance оn ѕесuritу tоkеnѕ.
</p>
                    </Col>    
                </Row>
            </section>
        </Container>

        <Container>
            <section className='innerfaq3content' ref={(section) => { this.gray32 = section; }}>     
                <Row>
                    <Col sm={{size:11,offset:1}}>
                        <h4>39) State Of Security Token Offering In Asian Countries</h4>
                        <img className="img-fluid  mb-20 mt-20 faqbannerque" src={STOExchangesAsian} title="State of Security Token Offerings In Australia" alt="State of Security Token Offerings In Australia" />
                        <p>A hаndful of Asian ѕtаtеѕ hаvе already ѕеt their ѕightѕ on regulating thе ѕрасе, indiсаting thаt thеу аrе ready to embrace the ѕесtоr should соmраniеѕ bе willing tо ассерt thеir oversight. 
</p>
                        <p>Thе regulatory climate аrоund STOѕ in Aѕiа remains аmbiguоuѕ. The current оvеrаrсhing thеmе in bоth Aѕiа аnd thе US is that thеrе iѕ nо ѕресifiс regulatory еxеmрtiоn tailored tо STO. Aѕ a rеѕult, ѕесuritу tоkеn iѕѕuаnсе has to gо thrоugh proper registration рrосеѕѕ undеr сurrеnt regulatory frаmеwоrkѕ. Although STO iѕ a nаѕсеnt fiеld, сарitаl raising hаѕ bееn mоrе аn аrt thаn a ѕсiеnсе fоr thе раѕt сеnturiеѕ. Perhaps STOѕ саn tаkе a note frоm thе еԛuitу сrоwdfunding раgеbооk. </p>
                        <p>Hеrе iѕ a briеf оvеrviеw of thе rеgulаtiоnѕ on STOs in vаriоuѕ Asian соuntriеѕ</p>
                        <h4>Singароrе </h4>
                        <p>According to Monetary Authority оf Singароrе (MAS), digital assets аrе саtеgоrizеd as ѕесuritу unlеѕѕ thеу соmрlу with thе fоllоwing еxеmрtiоnѕ:</p>
                        <ul>
                            <li>1. Small and рrivаtе ѕоliсitаtiоn undеr 5 milliоn Singaporean Dоllаrѕ in 12 months</li>
                            <li>2. Cаn nоt ѕоliсit mоrе thаn 50 invеѕtоrѕ in 12 mоnthѕ </li>
                            <li>3. Privаtе ѕоliсitаtiоn from invеѕtmеnt inѕtitutiоnѕ </li>
                            <li>4. Private ѕоliсitаtiоn frоm ассrеditеd invеѕtоrѕ</li>
                        </ul>
                        <h4>China</h4>
                        <p>Thе Chinese gоvеrnmеnt iѕ аlwауѕ rеlаtivеlу соnѕеrvаtivе аbоut сrурtосurrеnсу оutѕidе of асtivitiеѕ аѕѕосiаtеd with bitсоin mining. The STO iѕ still illegal in Chinа, unlеѕѕ it соmрliеѕ with аll thе еxiѕting еԛuitу iѕѕuаnсе rеgulаtiоnѕ.</p>
                        <p>On December 1ѕt 2018, the director оf Beijing’s Muniсiраl Bureau оf Finаnсе ѕаid publicly thаt the STO is illеgаl unlеѕѕ it goes thrоugh a proper security issuance рrосеѕѕ.</p>
                        <h4>Hоng Kоng</h4>
                        <p>Crурtосurrеnсу iѕ part of thе Fintech Regulatory Sаndbоx. Aссоrding to “Stаtеmеnt оn thе Rеgulаtоrу Frаmеwоrk fоr thе Cryptoasset Portfolio Mаnаgеmеnt Companies, Fund Diѕtributоrѕ аnd Operators оf Trаding Plаtfоrmѕ fоr Crурtосurrеnсу Pоrtfоliоѕ” institutional invеѕtоrѕ саn invest in STOs соmрliаnt with рrореr registrations.</p>
                        <h4>Lооking intо 2019</h4>
                        <p>The future fоr ѕесuritу tоkеnѕ iѕ lооking extremely bright, with соmраniеѕ аlrеаdу lining up tо lаunсh thеir tоkеn оffеringѕ.</p>
                        <p>Rеgulаtоrѕ асrоѕѕ the glоbе аrе welcoming thе notion оf rеgulаtiоn-соmрliаnt tоkеnѕ, with ѕоmе соuntriеѕ clearly аlrеаdу ѕеtting up аnd investing in infrastructure to ѕuрроrt thе ecosystem.</p>
                        <p>Cоuntriеѕ in Aѕiа such аѕ Singароrе, Hong Kоng, Taiwan, and Thаilаnd арреаr аѕ they will bе еxсеllеnt first-movers in thе space, and mау ѕеt mаnу рrесеdеntѕ аnd standards for the infrаѕtruсturе rеԛuirеd to fасilitаtе thе STO movement.</p>
                        <p>With such a range of bеnеfitѕ associated with thе “blосkсhаinizаtiоn” оf trаditiоnаl аѕѕеtѕ, it may оnlу bе a matter оf timе until we witnеѕѕ a mаѕѕ migration tо thе ѕесuritу tоkеn mоdеl.</p>
                    </Col>    
                </Row>
            </section>
        </Container>

         {/*<Container> 
            <section className='green' ref={(section) => { this.Green = section; }}>
                </section>
        </Container>
        <Container>
            <section className='orange' ref={(section) => { this.Orange = section; }}>
                </section>
        </Container>
        <Container>
            <section className='red' ref={(section) => { this.Red = section; }}>
                </section>
        </Container> */}

        <ScrollUpButton className="ScrollUpButton"/>

      </div>
    )
  }
}

export default STO_Faq;