import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Derivative from '../../Images/DerivativesTrading/Trading-Sftware.png';
import Challenges from '../../Images/DerivativesTrading/challenges-address.png';

export default class Customerchallange extends Component {
  render() {
    return (
      <Fragment>
        <Container className="custmorchallanges-section">

          <Row className="customer-row">
            <Col md={5} className="offset-sm-1 customer-box">
              <div className="">
                <h2>Derivative Trading software</h2>
                <ul>
                  <li>Venture Funds</li>
                  <li>Family offices</li>
                  <li>Asset management firms</li>
                  <li>Broker-dealers</li>
                  <li>Proprietary trading firms</li>
                </ul>
              </div>
            </Col>
            <Col md={6} className="customr-img">
              <img className="img-fluid" src={Derivative} alt="Crypto derivavtives exchange software" title="Derivatives Futures" />
            </Col>
          </Row>


          <Row>
            <Col md={6} className="offset-sm-1 customr-img-2">
              <img className="img-fluid" src={Challenges} alt="Crypto derivavtives exchange software" title="Derivatives Futures" />
            </Col>
            <Col md={5} className=" customer-box">
              <div className="derivativedetails">
                <h2>Challenges we <br/>address</h2>
                <ul>
                  <li>Improve onboarding</li>
                  <li>Broker’s house rules implementation on risk management</li>
                  <li>Boost brokerage evaluation</li>
                  <li>Integrate with executing brokers, clearing houses and regulatory reporting systems
                  Implementing validation and routing rules for the support of the different
                          executing brokers</li>
                  <li>Furnish traders with the most advanced functionality</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
