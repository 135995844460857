import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";


import Presale from "../../Images/ICO_Marketing/PresaleandprivatesaleofICO.png";
import ICOlisting from "../../Images/ICO_Marketing/ICOListing.png";
import Appealingwebsite from "../../Images/ICO_Marketing/Apealingwebsite.png";
import CommunityBuilding from "../../Images/ICO_Marketing/CryptoForums.png";
import AirDropcampaign from "../../Images/ICO_Marketing/AirdropCampaign.png";
import Whitepapercreation from "../../Images/ICO_Marketing/WhitepaperCreation.png";
import Socialmedia from "../../Images/ICO_Marketing/Socialmedia.png";
import Influencermarketing from "../../Images/ICO_Marketing/Infulencer.png";
import Pressreleases from "../../Images/ICO_Marketing/PressRelease.png";
import Contentmarketing from "../../Images/ICO_Marketing/Contentmarketing.png";
import Offlinemarketing from "../../Images/ICO_Marketing/offlinemarketing.png";
import Brandingkit from "../../Images/ICO_Marketing/Brandingkit.png";



export default class STOSmartContract extends Component {
  render() {
    return (
      <div>
        <Row>   
            <Col sm="12">
                <div className="stoadviceitle">
                    <h2>ICO marketing services</h2>
                </div>    
            </Col>
        </Row>  

        <Row className="IEO-campaigns">   
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={Presale} alt="Presale" title="Presale" />
                    <h3>Pre-sale and private sale of ICO</h3>
                    <p>Once the Cryptocurrency development is completed, these specifically designed token sale events can help startups to raise the required funds before the final crowd sale. </p>
                </div>
            </Col>
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={ICOlisting} alt="ICOlisting" title="ICOlisting" />
                    <h3>ICO listing </h3>
                    <p>There are various ICO listing websites where you can list your ICO and attract investors. Also, investors too look up to these websites to find prospective ICOs for investment. </p>
                </div>
            </Col>
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={Appealingwebsite} alt="Appealingwebsite" title="Appealingwebsite" />
                    <h3>Appealing website </h3>
                    <p>You need to have a feature-rich and secure website that represents your business and all the details to lure investors to invest in your company. </p>
                </div>
            </Col>
        </Row>      

        <Row className="IEO-campaigns">   
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={CommunityBuilding} alt="CommunityBuilding" title="CommunityBuilding" />
                    <h3>Community Building via crypto forums </h3>
                    <p>Various communities are built to discuss the company's crypto tokens and other features.</p>
                </div>
            </Col>
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={AirDropcampaign} alt="AirDropcampaign" title="AirDropcampaign" />
                    <h3>AirDrop campaign </h3>
                    <p>To create awareness about the company and ICOs, some free coins are issued to the active promoters. </p>
                </div>
            </Col>
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={Whitepapercreation} alt="Whitepapercreation" title="Whitepapercreation" />
                    <h3>Whitepaper creation </h3>
                    <p>Whitepaper drafting is very crucial to provide end-to-end details about the project and ICO to the investors. Some of the information the whitepaper consists are capital requirements, token division and distribution. </p>
                </div>
            </Col>
        </Row>    

        <Row className="IEO-campaigns">   
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={Socialmedia} alt="Socialmedia" title="Socialmedia" />
                    <h3>Social media</h3>
                    <p>The social media is a powerful medium to create hype across the investors irrespective of their geographical parameters. </p>
                </div>
            </Col>
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={Influencermarketing} alt="Influencermarketing" title="Influencermarketing" />
                    <h3>Influencer marketing </h3>
                    <p>You can contact various industry influencers to create awareness about ICOs your company is offering. You can also list your ICOs in various free ICO listing websites. </p>
                </div>
            </Col>
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={Pressreleases} alt="Pressreleases" title="Pressreleases" />
                    <h3>Press releases </h3>
                    <p>Let the media know about your ICOs by publishing press releases to newsletters and websites. </p>
                </div>
            </Col>
        </Row>      

        <Row className="IEO-campaigns">   
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={Contentmarketing} alt="Contentmarketing" title="Contentmarketing" />
                    <h3>Content marketing </h3>
                    <p>Different types of content such as videos, articles and blogs are created by experienced content writers which are distributed among various platforms to create awareness.</p>
                </div>
            </Col>
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={Offlinemarketing} alt="Offlinemarketing" title="Offlinemarketing" />
                    <h3>Offline marketing</h3>
                    <p>You can visit various crypto and Blockchain events and participate in ted talks and forums to create awareness.</p>
                </div>
            </Col>
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={Brandingkit} alt="Brandingkit" title="Brandingkit" />
                    <h3>Branding kit </h3>
                    <p>Both, online and offline marketing channels should be covered consistently to create a brand with much hype. We will go for integrated marketing communications across different online and offline marketing channels. </p>
                </div>
            </Col>
        </Row>      
      </div>
    )
  }
}
