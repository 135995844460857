import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";


export default class StoSection3 extends Component {
  render() {
    return (
        <div> 
        <Row>   
            <Col sm="12">
                <div className="stoadviceitle mt-30">
                    <h2>STO Consulting - Your Benefits</h2>
                </div>
            </Col>

            <Col sm="12">
                <div className="stoadviceabouttitle mt-20">
                    <h3>Free First STO Advice</h3>
                    <p>The first consultation is free and you will learn without obligation whether a security token offering is the best solution for you. We will explain all aspects of STO development in detail - from the STO costs, 
                        legal peculiarities, the technical STO programming up to STO marketing and the subsequent launch.</p>
                </div>
            </Col>
            <Col sm="12">
                <div className="stoadviceabouttitle mt-20">
                    <h3>Extensive STO Advice</h3>
                    <p>The first consultation is free and you will learn without obligation whether a security token offering is 
                        the best solution for you. We will explain all aspects of STO development in detail - from the STO costs, 
                        legal peculiarities, the technical STO programming up to STO marketing and the subsequent launch.</p>
                </div>
            </Col>
            <Col sm="12">
                <div className="stoadviceabouttitle mt-20">
                    <h3>Experienced STO Expert</h3>
                    <p>Our STO experts have extensive experience in the areas of finance, investing, IPOs and venture capital
                         companies. We have been working as an STO agency 
                        for almost 2 years and have been dealing with cryptocurrencies for several years.</p>
                </div>
            </Col>
        </Row>
    </div>
    )
  }
}
