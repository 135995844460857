import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
//import QueueAnim from 'rc-queue-anim';
import OverviewBanner from '../../Images/Best-Exchange-Development.png';

import ourexchange from './HomeData';


class Section1 extends Component {
    render() {
        const ourexchange_h2            = ourexchange[1].ourexchangedata.fields.ourexchange_h2;
        const ourexchange_dis           = ourexchange[1].ourexchangedata.fields.ourexchange_dis;
        const ourexchange_dissub        = ourexchange[1].ourexchangedata.fields.ourexchange_dissub;
        const ourexchange_link          = ourexchange[1].ourexchangedata.fields.ourexchange_link;
        const ourexchange_dissubnext    = ourexchange[1].ourexchangedata.fields.ourexchange_dissubnext;

      return (
        <div  className="OverviewHome"> 
            <Row>
                <Col sm={6}>                         
                    <img className="img-fluid" alt="Exchange Solution Overview" title="Exchange Solution Overview" src={OverviewBanner} />
                </Col>
                <Col sm={6}>   
                    {/* <QueueAnim> */}
                        <h2 
                            key="1" 
                            type="left"
                            // animConfig="null"
                            delay="500"
                            duration="600"
                            interval="250"
                            ease="easeIn"
                            // animatingClassName="queue-anim-entering"
                        >
                           {ourexchange_h2}
                        </h2>
                    {/* </QueueAnim>  */}
                    <p>{ourexchange_dis}</p>
                    <p>{ourexchange_dissub}
                        <Link title="Customized Exchange Software Solution" to="/crypto-exchange-software">
                        <strong>  {ourexchange_link}</strong> 
                        </Link>
                        {ourexchange_dissubnext}
                    </p>
                    {/* <p>Enable new paths to your desires regarding trading platform by getting allied with Espay – Well
                        distinguished Exchange Development Company.</p>
                    <p> We develop trading platforms which are cost-effective
                        with the help of project-focused certified professionals. Espay is standing separately as a
                        <Link title="Customized Exchange Software Solution" to="/crypto-exchange-software">
                        <strong> Customized Exchange Solution</strong>
                        </Link> provider because of its 10+ years of experience in transaction processing. Our
                        groundbreaking methodologies offers industry’s best experience to the clients and add value to every
                        project we commences. We work with our regarded customers to transform their digital needs into 
                        probably the most effective and high performance exchange software.</p> */}
                </Col>
                
            </Row> 
        </div>
      );
    }
}

export default Section1;