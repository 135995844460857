import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import ExchangeDevelopment1 from '../../Images/ExchangeDevelopment1.png';
import { Link } from "react-router-dom";

class Banner extends Component {
    render() {
      return (
        <div> 
            {/* <Row>
                <Col sm={{size:8, offset:2}}>                       
                    <Jumbotron>
                        <div className="bannerdetails">
                            <h1>Know More About Espay Exchange</h1>
                            <p>We are making future-ready crypto exchange software that delivers a
                                digital experience with buying, selling or trading multiple cryptocurrencies 
                                adhered by the Blockchain Technology.</p>
                            <Button>Talk To Our Experts</Button>
                        </div>
                    </Jumbotron>
                </Col>
            </Row>  */}
            <Row>
                <div class="winter-is-coming">
                    <div class="snow snow--near"></div>
                    <div class="snow snow--near snow--alt"></div>
                    
                    <div class="snow snow--mid"></div>
                    <div class="snow snow--mid snow--alt"></div>
                    
                    <div class="snow snow--far"></div>
                    <div class="snow snow--far snow--alt"></div>
                </div>
            </Row>
            {/* <Row className="parag">
                <Col sm={{size:8, offset:2}}>                       
                    <Jumbotron>
                        <div className="bannerdetails">
                            <h1>Espay - X Exchange software purpose-built to help you grow in the digital assets ecosystem</h1>
                            <p>See how our exchange software fits into your business, while providing a seamless experience to your users.</p>
                            <Button>Watch Demo</Button>
                        </div>
                    </Jumbotron>
                </Col>
            </Row> */}
            <Row className="parag">
                <Col sm={{size:6}}>                       
                    
                    <div className="bannerdetails">
                        <h1>Espay - X Exchange software purpose-built to help you grow in the digital assets ecosystem</h1>
                        <p>See how our exchange software fits into your business, while providing a seamless experience to your users.</p>
                        <Link to="/contact-us">Book A Demo</Link>
                    </div>
                </Col>
                <Col sm={{size:6}}>                       
                    <img alt="Exchange Development" title="Exchange Development" src={ExchangeDevelopment1} className="banner_img" />  
                </Col>


                {/* <Col sm={{size:6}}>                       
                    <Jumbotron>
                        <div className="bannerdetails">
                            <h1>Espay - X Exchange software purpose-built to help you grow in the digital assets ecosystem</h1>
                            <p>See how our exchange software fits into your business, while providing a seamless experience to your users.</p>
                            <Button>Watch Demo</Button>
                        </div>
                    </Jumbotron>
                </Col> */}
            </Row>
        </div>
      );
    }
}

export default Banner;