import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";

import Mainbanner from './banner';
import Mainplatform from './Wl-Section1';
import Mainfeatures from './Wl-Section2';
import Mainwhite from './Wl-Section3';
import Mainoffering from './Wl-Section4';
import Mainpltform from './Wl-Section5';
import Mainsoftwar from './Wl-Section6';
import Mainproduct from './Wl-Section7';
import Blog from './Wl-Section8';

import Certificate from '../Certificate/index';
import Faqmain from '../Faq/faq';


export default class index extends Component {

  componentDidMount() {
    ReactGA.initialize('UA-125025994-1')
    ReactGA.pageview(document.location.pathname)
    ReactGA.pageview(document.location.path)
  }
  
  scrollPositions = {}
  componentWillMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"
    }
    window.scrollTo(0, 0)
  }
  
  render() {
    return (
      <div>
        {this.props.children}
        <Helmet>
            <title>White Label Crypto Exchange Software Development</title>
            <meta name="description" content="Espay has expertise in delivering white label crypto exchange software and bitcoin & cryptocurrency trading platform solutions in each corner of the world."/>
            <meta property="og:title" content="White Label Crypto Exchange Software Development" />
            <meta property="og:description" content="Espay has expertise in delivering white label crypto exchange software and bitcoin & cryptocurrency trading platform solutions in each corner of the world." />
            <meta name="twitter:title" content="White Label Crypto Exchange Software Development" />
            <meta name="twitter:description" content="Espay has expertise in delivering white label crypto exchange software and bitcoin & cryptocurrency trading platform solutions in each corner of the world. " />
            <link rel="canonical" href="https://www.espay.exchange/white-label-crypto-exchange-software" />
        </Helmet>

        <Container fluid={true} className="WlCryptoExchange-bg">
          <Container>
            <Mainbanner />
          </Container>
        </Container>

        <Container>
          <Mainplatform />
        </Container>

        <Container fluid={true} className="Wlfeatures-bg">
          <Container>
            <Mainfeatures />
          </Container>
        </Container>

        <Container>
          <Mainpltform />
        </Container>

        <Container fluid={true} className="Wlfeatures-bg">
          <Container>
            <Mainoffering />
          </Container>
        </Container>

        <Container fluid={true} className="Wlbranded-bg">
          <Container>
            <Mainwhite />
          </Container>
        </Container>

        <Container>
          <Mainsoftwar />
        </Container>
        
        <Container fluid={true} className="WlExchangeProduct">
          <Container>
            <Mainproduct />
          </Container>
        </Container>

        <Container fluid={true} className="commonblogsection">
          <Container>
            <Blog />
          </Container>
        </Container>   

        <Container fluid={true} className="commonblogsection">
          <Container>
            <Certificate />
          </Container>
        </Container>  

        <Container fluid={true}>
          <Container>
            <h2 className="faqmaintitle">Frequently Asked Questions</h2>
            <Faqmain />
          </Container>
        </Container>  

        <ScrollUpButton className="ScrollUpButton"/>
      </div>
    )
  }
}

