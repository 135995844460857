import React, { Component, Fragment } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button"; 
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";
import Slidermain from "./nftbanner";
import NftServices from "./NftServices";
import Banefits from "./Banefits";

export default class index extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-125025994-1')
        ReactGA.pageview(document.location.pathname)
        ReactGA.pageview(document.location.path)
      }
    
    scrollPositions = {}
        componentWillMount() {
        if ("scrollRestoration" in window.history) {
            window.history.scrollRestoration = "manual"
        }
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>NFT | Espay Exchange</title>
                    <meta name="description" content="NFT"/>
                    <meta property="og:title" content="NFT" />
                    <meta property="og:description" content="NFT" />
                    <meta name="twitter:title" content="NFT" />
                    <meta name="twitter:description" content="NFT" />
                    <link rel="canonical" href="https://www.espay.exchange/NFT" />
                </Helmet>

                <div className="NftBanner-bg">
                    <Container fluid={true}>
                        <Container>
                            <Slidermain />
                        </Container>
                    </Container>
                </div>     

                
                <NftServices />      

                <Banefits />

                <ScrollUpButton className="ScrollUpButton"/>
            </Fragment>
        )
    }
}
