import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import p2pExchangeSoftware from '../../Images/p2pExchangeSoftware.png';
import HybridCryptoExchange from '../../Images/HybridCryptoExchange.png';
import Interestedsec from '../../Images/Interestedsec.png';

class product_crypto_section6 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12">
                    <div className="Decentralizedtitle">
                        <h2>You May Also Be Interested In</h2>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="4">
                    <div className="Decentralizedproductbox">
                        <Link title="P2P Exchange Software" to="/p2p-exchange-software-development">
                            <img src={p2pExchangeSoftware} alt="P2P Exchange Software" title="P2P Exchange Software" />
                            <h3>P2P Exchange Software</h3>
                            <p>With P2P trading platforms, developers at Espay strive to preserve the real openness that the
                                Blockchain Technology conveys.  </p>
                        </Link>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="Decentralizedproductbox">
                        <Link title="Hybrid Crypto Exchange" to="/hybrid-crypto-exchange-software">
                            <img src={HybridCryptoExchange} alt="Hybrid Crypto Exchange" title="Hybrid Crypto Exchange" />
                            <h3>Hybrid Crypto Exchange</h3>
                            <p>Espay is delivering robust Hybrid crypto Exchange platforms for the users who need automatization, 
                                complex order structures, as well as speed.  </p>
                        </Link>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="Decentralizedproductbox">
                        <Link title="Security Token Exchange" to="/security-token-exchange">
                            <img src={Interestedsec} alt="Security Token Exchange" title="Security Token Exchange" />
                            <h3>Security Token Exchange</h3>
                            <p>Espay offers a fully-compliant-focused security token and digital coin trading platforms that 
                                satisfy your active traders’ every demand.  </p>
                        </Link>
                    </div>
                </Col>
            </Row>
        </div>
      );
    }
}

export default product_crypto_section6;