import React from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

class Faqmakemoney extends React.Component {

  scrollPositions = {}
  componentWillMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"
    }
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="main">
        <Container fluid={true}>
            <Row>
                <Col md={4}>
                <Faqleftpanel {...this.props} />
                </Col>
                <Col md={7} className="mx-auto">
                    <h2 className="topinnertitle">Do crypto exchanges make money?</h2>
                    <p>The top ten cryptocurrency exchanges are taking in income as much as $3 million a day, according to estimates estimated by Bloomberg. Considering that cryptocurrency did not even exist until 2009, income at that level is simply incredible when compared to other industries ' historical growth. Why cryptocurrency exchanges make their money by charging fees for users who sell, purchase, and withdraw cryptocurrencies is easy enough to understand. However, there are a few different important stats and trends that can help us to understand why certain exchanges are currently more profitable than others.</p>
                    <p>However, there are a few factors that determine exactly how much money an exchange would make. These are the fee / exchange rates, in no particular order. Also it depends whether you are going for a centralized crypto exchanges or a decentralized one.</p>
                    <p>Many exchanges charge insane fees that end up padding their numbers on top of what they would have paid anyway. Some charge very small fees, and even give you discounts when you are a high-volume company. However, if you have the resources to drive around 10 K BTC these days, you would have to think about more important things than the exchange fees.</p>
                    <p>If they are made for day traders. Those are the people who would exchange, if not more, hundreds of thousands of USD in cryptos in a single day with just a small one.</p>
                    <p>At the provided exchange exposure to altcoins. Some centralized cryptocurrency exchange solutions or decentralized solutions do cover simple cryptos, such as Bitcoin, Ether(eum), Litecoin, Ripple, etc., but in most others do not dabble. Those which allow access to super-obscure altcoins will cater for a very niche market.</p>
                </Col>
            </Row>
        </Container>
      </div>
    );
  }
}

export default Faqmakemoney;