import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import market from '../../../../Images/market.png';
import spottrading from '../../../../Images/Spot-Trading.png';
import stop_order from '../../../../Images/stop_order.png';
import limit from '../../../../Images/limit.png';
import margin_trading from '../../../../Images/margin_trading.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faBook,faShoppingCart} from '@fortawesome/free-solid-svg-icons';
library.add(faBook,faShoppingCart);

class trading_market_section2 extends Component {
    render() {
      return (
        <div> 
             <Row>   
                <Col sm="12"> 
                    <div className="tradingmarkettitle">
                        <h2>WHAT ESPAY OFFERS</h2>
                    </div>                      
                </Col>
            </Row>  
            <Row>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={market} alt="Market Orders (MKT)" title="Market Orders (MKT)" /></li>
                        <li className="tradingmarketofer">
                            <h4>Market Orders (MKT)</h4>
                            <p>Espay’s crypto exchange software offers market orders that empower your customers’ buy or sell the digital coins right now with the current market price.</p>                
                        </li>
                    </ul> 
                </Col>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={spottrading} alt="Spot Trading" title="Spot Trading" /></li>
                        <li className="tradingmarketofer">
                            <h4>Spot Trading</h4>
                            <p>Espay helps to let your ordinary investors buy and sell Bitcoins from the underlying market where real coins are being traded, not only a paper demonstration of the value. </p>                                    
                        </li>
                    </ul>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={stop_order} alt="Stop Orders (STP)" title="Stop Orders (STP)" /></li>
                        <li className="tradingmarketofer">
                            <h4>Stop Orders (STP)</h4>
                            <p>Enable your traders with a stop orders facility with Espay’s crypto exchanges which are utilized to capture an exact price/higher on a buy order, or to capture an exact price/lower, on a sell order.</p>                                    
                        </li>
                    </ul>
                </Col>
            
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={limit} alt="Limit Orders (LMT)" title="Limit Orders (LMT)" /></li>
                        <li className="tradingmarketofer">
                            <h4>Limit Orders (LMT)</h4>
                            <p>Espay offers limit orders for your crypto trading platform that aid your customers to limit the amount that is paid on a buy order/to sell at an exact price/above on a sell order.</p>                                    
                        </li>
                    </ul> 
                </Col>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={margin_trading} alt="Margin Trading" title="Margin Trading" /></li>
                        <li className="tradingmarketofer">
                            <h4>Margin Trading</h4>
                            <p>Espay’s crypto exchange software let you serve margin trading that allows your traders to trade the digital coins with borrowed money instead of their own.</p>                                    
                        </li>
                    </ul>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><FontAwesomeIcon icon="book" size="2x" className="trading-market-icn"/></li>
                        <li className="tradingmarketofer">
                            <h4>Seller & Buyer Book</h4>
                            <p>To discover the entry and exit points, provide your customers’ different ways to look at the orders books (buy and sell orders). Espay’s exchange development solution aid you with this. </p>                                    
                       </li>
                    </ul>
                </Col>
            
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><FontAwesomeIcon icon="shopping-cart" size="2x" className="trading-market-icn"/></li>
                        <li className="tradingmarketofer">
                            <h4>Active Order</h4>
                            <p>Leverage active orders from Espay’s crypto trading platform and list all pending orders there that will be available for trading. It will aid your traders to be aware of upcoming orders.</p>                                    
                        </li>
                    </ul> 
                </Col>              
            </Row>          
        </div>
      );
    }
}

export default trading_market_section2;