import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import Integration from '../../../../Images/Integration.png';

class integration_section1 extends Component {
    render() {
      return (
        <div> 
            <Row> 
                <Col sm="6">
                    <div className="integration">
                        <p>In order to make the most out of the APIs, you need to implement third-party configuration. Espay 
                            as an exchange development company offers third-party API for seamless functioning of your crypto
                            trading platform. It aids to eliminate duplication and provide multiple features needed for daily
                            operations on the exchange.</p>
                        <p>Grow your business with <Link to="/white-label-crypto-exchange-software" title="Espay Exchange"> <strong>Espay’s ready-made Bitcoin exchange platform </strong></Link> that is completely tested and
                         ready-to-use.</p>
                    </div>
                </Col>
                <Col sm="6" className="mt-30">
                    <img className="img-fluid" src={Integration} alt="Integration" title="Integration" />
                </Col>  
            </Row>          
        </div>
      );
    }
}

export default integration_section1;