import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

import Technicalplan from "../../Images/STO/Technicalplan.png";
import ContractCreation from "../../Images/STO/ContractCreation.png";
import ContractAudit from "../../Images/STO/ContractAudit.png";

export default class STOSmartContract extends Component {
  render() {
    return (
      <div>
        <Row>   
            <Col sm="12">
                <div className="stoadviceitle">
                    <h2>STO Smart Contract</h2>
                    <p>Programming a Smart Contract for your STO is one of the most important tasks of programming. This includes 
                        the development of a feature-rich and technically perfect Smart Contract, which should be absolutely flawless:</p>
                </div>    
            </Col>
        </Row>  

        <Row>   
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={Technicalplan} alt="" title="" />
                    <h3>Technical plan</h3>
                    <p>Before we start with smart contract development, we create a detailed technical plan where we define all the specifics and variables such as sales volume, price, sales phases together with you.</p>
                </div>
            </Col>
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={ContractCreation} alt="" title="" />
                    <h3>Smart Contract Creation</h3>
                    <p>Once we have defined all the details, we start with the technical implementation of the STO Smart Contracts. Since we have already developed several smart contracts, we have a proven approach that saves both time and money for your project.</p>
                </div>
            </Col>
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={ContractAudit} alt="" title="" />
                    <h3>Smart Contract Audit</h3>
                    <p>After programming your STO Smart Contract, we conduct a detailed test phase and conclude a Smart Contract Audit to ensure error-free operation.
</p>
                </div>
            </Col>
        </Row>      
      </div>
    )
  }
}
