import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';

export default class ICOproject extends Component {
    render() {
        return (
            <div className="icoproject-bg">
                <Container>
                    <Row>
                        <Col sm="6" className="icoproject-section">
                            
                            <h2>How to promote your ICO project?</h2>
                            <h3 className="ico-sub-title">Social media profile creation</h3>
                            <p>There are dedicated crypto forums and communities where startups can discuss about their ICO projects and their features. Comprising of community members who are always look for new crypto coins and researching for new investment opportunities, these crypto forums are the best mediums for promotion of your ICO project. </p>
                            <p>Reaching out to these dedicated communities should be one of the most crucial part of your marketing plan. You will be able to understand what community members think of your ICOs and the project by reading out the threads and comments. </p>
                            <p>Most of the community members are potential investors who have invested in different ICOs. Reach out to them as they are your target audience. </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
