import React, { Component, Fragment } from 'react';
import { Row, Col, Container } from 'reactstrap';
//import { Link } from "react-router-dom";

import Cryptocurrencies from "../../Images/Mobile_Trading_Terminal/Cryptocurrencies.png";
import Options from "../../Images/Mobile_Trading_Terminal/Options.png";
import Swaps from "../../Images/Mobile_Trading_Terminal/Swaps.png";
import Futures from "../../Images/Mobile_Trading_Terminal/Futures.png";
import Forex from "../../Images/Mobile_Trading_Terminal/forex.png";
import CFDs from "../../Images/Mobile_Trading_Terminal/CFDs.png";
import Stocks from "../../Images/Mobile_Trading_Terminal/Stocks.png";
import ETFs from "../../Images/Mobile_Trading_Terminal/ETFs.png";
import Spread from "../../Images/Mobile_Trading_Terminal/Spread-Bots.png";


export default class LavrageAdvantage extends Component {
    render() {
        return (
            <Fragment>
                <div className=" crypto-section-feture">
                    <Container>
                        <Row>
                            <h3 className="d-block w-100 feturegetway-text">Easy integration with best performance and UX</h3>
                            <p>Our in-house mobile trading front-ends for both iOS and Android provide the optimum performance and user experience. The platforms characterize easy integration of back office with user management, account registration, and essential business instruments, such as customer portals, KYC/AML providers, market data sources, and payment providers. Our clients avail benefits from all levels of customization, from a fully custom app developed to scratch to tailored widgets implemented into a white-labeled terminal.</p>
                            <p>Our clients are free to choose any of the mentioned asset classes for mobile trading solutions:</p>
                        </Row>
                        <Row className="text-center cryptosection-onediv">
                            <div className="col-sm-2 offset-sm-1">
                                <div className="cryptofuture-box">
                                    <img src={ETFs} alt="ETFs" title="ETFs" className="img-fluid " />
                                    <h3>ETFs</h3>

                                </div>
                            </div>

                            <div className="col-sm-2 ">
                                <div className="cryptofuture-box">
                                    <img src={Options} alt="Options" title="Options" className="img-fluid " />
                                    <h3>Options</h3>

                                </div>
                            </div>

                            <div className="col-sm-2">
                                <div className="cryptofuture-box">
                                    <img src={Swaps} alt="Swaps" title="Swaps" className="img-fluid " />
                                    <h3>Swaps</h3>

                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="cryptofuture-box">
                                    <img src={Futures} alt="Futures" title="Futures" className="img-fluid " />
                                    <h3>Futures</h3>

                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="cryptofuture-box">
                                    <img src={Forex} alt="Forex" title="Forex" className="img-fluid " />
                                    <h3>Forex</h3>

                                </div>
                            </div>

                        </Row>
                        <Row className="text-center">

                            <div className="col-sm-2 offset-sm-2">
                                <div className="cryptofuture-box">
                                    <img src={CFDs} alt="CFDs" title="CFDs" className="img-fluid " />
                                    <h3>CFDs</h3>

                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="cryptofuture-box">
                                    <img src={Stocks} alt="Stocks" title="Stocks" className="img-fluid " />
                                    <h3>Stocks</h3>

                                </div>
                            </div>

                            <div className="col-sm-2 ">
                                <div className="cryptofuture-box">
                                    <img src={Cryptocurrencies} alt="Cryptocurrencies" title="Cryptocurrencies" className="img-fluid " />
                                    <h3>Cryptocurrencies</h3>

                                </div>
                            </div>
                            <div className="col-sm-2">
                                <div className="cryptofuture-box">
                                    <img src={Spread} alt="Spread bets" title="Spread bets" className="img-fluid " />
                                    <h3>Spread bets</h3>

                                </div>
                            </div>
                        </Row>

                    </Container>
                </div>
            </Fragment>
        )
    }
}
