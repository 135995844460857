import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import MarketCapitalizationImg from '../../../Images/faq/MarketCapitalizationImg.png';

export default class MarketingCapitalization extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is marketing capitalization of cryptocurrency exchange?</h2> 
                            <ul>
                                <li>
                                    <p>Market capitalization is a well-known metric for traditional securities, but has unique implications in crypto. Market capitalization is a measure of the value of a security. In crypto, it’s defined as the circulating supply of tokens multiplied by current price. If a coin has 100 tokens outstanding and is trading for $10 a coin, it has a market cap of $1000.</p>
                                </li>
                                
                                <li>
                                    <img src={MarketCapitalizationImg} alt="marketing capitalization" title="marketing capitalization" className="img-fluid faqimg"/>
                                </li>
                                <li>
                                    <p>Market cap can help you in learning a lot about the crypto market. First things first: Bitcoin is still the big dog in town. With roughly 55% of the total crypto market cap, it remains far and away the most valuable coin, roughly four times as valuable as Ethereum. There is a huge gap between Bitcoin and Ethereum, but not a large gap between Ethereum and third-place Ripple. There are still relatively few valuable cryptocurrencies. Only 12 coins have a total market cap of over $1 billion. It’s not uncommon for popular altcoins to have market caps of under $20 million.</p>
                                </li>
                                <li>
                                    <p>So, in cryptocurrencies the number of coins or the price of coins doesnt matter much. The thing which matters and suggest the true value and acceptance of the coin is its market cap, as it is a combination of both - the market price of coin/token and its circulating supply (which shows the public acceptance of the coin). The Ethereum is more in numbers when compared to bitcoin but the price is much lower then that of the Bitcoin. While there is a coin named 42-coin priced more than USD 19000, way above the price of Bitcoin. but the circulating supply is only 42 coins. so in terms of market cap, this coin is no where near Bitcoin. Checking market cap can always halp you evaluating cryptocurrencies for investment and trading.</p>
                                </li>
                            </ul>
                            
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
