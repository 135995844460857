import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container,Row,Col } from "reactstrap";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

import facebook from '../../Images/social-facebook.png';
import twitter from '../../Images/social-twitter.png';
import linkdin from '../../Images/social-linkdin.png';
//import google from '../../Images/social-google.png';
import youtube from '../../Images/social-youtube.png';
import instagram from '../../Images/social-instagram.png';
import medium from '../../Images/social-medium1.png';

export default class index extends Component {
    
  componentDidMount() {
    ReactGA.initialize('UA-125025994-1')
    ReactGA.pageview(document.location.pathname)
    ReactGA.pageview(document.location.path)
  }
  
    scrollPositions = {}
    componentWillMount() {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "manual"
      }
      window.scrollTo(0, 0)
    }
    
  render() {
    return (
      <div className="Thankyoumain">
      {this.props.children}
        <Helmet>
            <title>Your request for a quote has been received.</title>
            <meta property="og:title" content="Your request for a quote has been received." />
            <meta property="og:description" content="/" />
            <meta name="twitter:title" content="Your request for a quote has been received." />
            <meta name="twitter:description" content="" />
            <script async="true" src="gtag('event', 'conversion', {'send_to': 'AW-789359872/JrIJCMmA2IgBEIDasvgC'});"></script>
        </Helmet>
        <Container>
            <Row>
                <Col sm={{size:8,offset:2}}>
                    <h4>Thank You!</h4>
                    <span>Thanks for submitting the form. Our representative will get back to you to fulfil your request.</span>
                    <span>Would you like others to know about Espay Exchange</span>
                        <ul className="thankyousocial">
                            <li>
                                <a  href="https://www.facebook.com/Espayio-Blockchain-Solutions-209436049798474/"><img src={facebook} alt="Facebook" title="Facebook" /></a>
                            </li>
                            <li>
                                <a href="https://twitter.com/espay"><img src={twitter} alt="Twitter" title="Twitter" /></a>
                            </li>
                            <li>
                                <a  href="https://www.linkedin.com/company/espay-blockchain-development"><img src={linkdin} alt="Linkdin" title="Linkdin" /></a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/channel/UC1FLBiI9AGdyfuTgq_Mn3hQ"><img src={youtube} alt="Youtube" title="Youtube" /></a>
                            </li>
                            <li>
                                <a  href="https://www.instagram.com/espayX/"><img src={instagram} alt="instagram" title="instagram" /></a>
                            </li>
                            <li>
                                <a itemProp="sameAs" 
                                href="https://medium.com/espayexchangespace">
                                    <img src={medium} alt="medium" title="medium" /></a>
                            </li>
                        </ul>
                        <Link className="btnlink" to="">Continue to homepage</Link>
                </Col>
            </Row>
        </Container>

        <ScrollUpButton className="ScrollUpButton"/>
      </div>
    )
  }
}
