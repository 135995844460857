import React, { Component } from 'react';
import { Row, Col, Jumbotron} from 'reactstrap';

class Banner extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm={{size:8,offset:2}}>                       
                    <Jumbotron>
                        <div className="bannerdetails">
                            <h1>Vision & Mission</h1>
                            <p>We are constantly serving global clients for their exchange software
                                development needs. The expertise is enough to build the smart platforms 
                                that mean great success for your trading business.</p>
                        </div>
                    </Jumbotron>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default Banner;