import React, { Component } from 'react';
import {Row, Col} from 'reactstrap';
import exchangesolutiondash from '../../Images/exchangesolutiondash.png';
import itemright from '../../Images/itemright.png';
//import { Link } from "react-router-dom";

class software_solution_crypto_section3 extends Component {
    render() {
      return (
        <div className="CompleteExchangeSoftware"> 
            <Row>   
                <Col sm="12">
                    <div className="CryptoSoluctiontitle">
                        <h2>This Exchange Software Is Powerful</h2>
                        <p>A flexible solution that can offer any assets, any features, any settings. The possibilities 
                            are endless.</p>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="6">
                    <Row className="mt-20">
                        <Col sm="1">                         
                            <img src={itemright} alt="Built with blockchain" title="Built with blockchain" />
                        </Col>
                        <Col sm="11">
                            <div className="CompleteExchangeleft">
                                <h3>Built with blockchain</h3>
                                <p>Find the best cryptocurrency exchange software
                                    solutions built with blockchain for buying and 
                                    selling digital coins, complete transparency is available to your clients.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-20">
                        <Col sm="1">                         
                            <img src={itemright} alt="Security" title="Security" />
                        </Col>
                        <Col sm="11">
                            <div className="CompleteExchangeleft">
                                <h3>Security</h3>
                                <p>Trading solutions from Espay are developed to be secured. Skilled developers have experienced
                                        to build secure products based on the cutting-edge standards.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-20">
                        <Col sm="1">                         
                            <img src={itemright} alt="Cloud hosting solution" title="Cloud hosting solution" />
                        </Col>
                        <Col sm="11">
                            <div className="CompleteExchangeleft">
                                <h3>Cloud hosting solution</h3>
                                <p>There is no need for a data center to run your own cloud. Espay offers you every benefit of 
                                    a private cloud without any of the on-site hardware in the FinTech industry.</p>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col sm="6">
                    <img src={exchangesolutiondash} className="img-fluid" 
                    alt="Complete Exchange Software Solution" title="Complete Exchange Software Solution" />
                </Col>
            </Row>
        </div>
      );
    }
}

export default software_solution_crypto_section3;