import React, { Component } from 'react';
import {Row, Col} from 'reactstrap';
import flow2 from '../../Images/flow2.png';

class exchange_work_Crypto_section5 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12">
                    <div className="CryptoSoluctiontitle">
                        <h2>How Cryptocurrency Exchange Work</h2>
                    </div>
                </Col>
                <Col sm={{size:8,offset:2}}>
                    <div className="center">
                        <img src={flow2} className="img-fluid" alt="How Cryptocurrency Exchange Work" 
                        title="How Cryptocurrency Exchange Work" />
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="12">
                    <div className="ExchangeWorkDes">
                        <p>Are you aware of how any bank functions in real life? You know that someone deposited their money in one of the currencies supported by the bank. Then a bank utilizes that money to exchange with other persons who want that currency. When a person who deposited his money in the bank, want it back in the form of another currency, bank delivers someone’s money to him. This is the whole perception of exchange with the centralized authority of bank.</p>
                        <p>Exchange & trading with digital currency is the same where a person buys and sells coins in the form of dollars or crypto coins. The Cryptocurrency exchange software Cryptocurrency Exchange Software create trading pairs and aids you to collect the bitcoins or any other Digital Distributed Currency that you want.</p>
                        <p>Cryptocurrency Exchange Rate changes constantly, so it’s definite that currency trading would face rise and fall. But there is nothing to worry about any security concerns as there is a comprehensive software to save your money before getting stolen. You can easily perform safe trading on cryptocurrency exchanges like you trust your central authorized banks.</p>
                    </div>
                </Col>
            </Row>
        </div>
      );
    }
}

export default exchange_work_Crypto_section5;