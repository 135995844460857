import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CoinListingRequest from '../../../../Images/Currencypairs/CoinTokenservice.png';
import pairconfig from '../../../../Images/Currencypairs/Pairconfiguration.png';
import daemon from '../../../../Images/Currencypairs/daemonconfiguration.png';
import apiConfiguration from '../../../../Images/Currencypairs/APIConfiguration.png';
import liquidity from '../../../../Images/Currencypairs/liquidity.png';
import SpotTrading from '../../../../Images/Currencypairs/Traderouting.png';
import withdraw from '../../../../Images/Currencypairs/WithdrawalRouting.png';
import risksecurity from '../../../../Images/Currencypairs/RiskManagers.png'; 
import ManageMarkets from '../../../../Images/Currencypairs/ManageMarkets.png'; 
    
class Currency_Section2 extends Component {
    render() {
      return (
        <div> 
            <Row> 
                <Col sm={12} md={6} lg={4}>
                    <div className="Currency_Service">
                    <img className="img-fluid" src={ManageMarkets} alt="Manage Markets" title="Manage Markets" /> 
                        <h4>Manage Markets</h4>
                        <p>Espay’s crypto exchange solutions offer a way to track the performance of the crypto market. Let your traders see the type of movement happen on the cryptocurrency marketplace with Espay’s trading platform.</p>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <div className="Currency_Service">
                        <img className="img-fluid" src={CoinListingRequest} alt="CoinListingRequest" title="CoinListingRequest" /> 
                        <h4>Configure Coin / Token [ Service ]</h4>
                        <p>Help your traders to set a configuration for all the coins or set-up coin-specific configuration with Config pools offered by Espay’s digital asset exchange platform.</p>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <div className="Currency_Service">
                        <img className="img-fluid" src={pairconfig} alt="pairconfig" title="pairconfig" /> 
                        <h4>Pair configuration</h4>
                        <p>Espay’s exchange development solutions assist your traders with different crypto pair configurations so that they can leverage best with a trading of one type of crypto coin and another.</p>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <div className="Currency_Service">
                        <img className="img-fluid" src={daemon} alt="daemon" title="daemon" />
                        <h4>Daemon Configuration</h4>
                        <p>Connect different exchange port and let your users send and receive wallet key with Espay’s crypto exchange solutions that serve daemon port configuration for the blockchain based wallet.</p>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <div className="Currency_Service">
                        <img className="img-fluid" src={apiConfiguration} alt="apiConfiguration" title="apiConfiguration" /> 
                        <h4>API Configuration</h4>
                        <p>Allow your traders to interact with the trading system by configuring API on algorithmic trading programs offered by Espay’s crypto exchange platform.</p>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <div className="Currency_Service">
                        <img className="img-fluid" src={liquidity} alt="liquidity" title="liquidity" />
                        <h4>Liquidity Management</h4>
                        <p>Espay is delivering whole order book from third party exchange & display it on your crypto exchange platform. Surge the flow of crypto trading in the website which aid to get the users at first for your business.</p>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <div className="Currency_Service">
                        <img className="img-fluid" src={SpotTrading} alt="SpotTrading" title="SpotTrading" />
                        <h4>Trade Routing</h4>
                        <p>Espay’s crypto exchange software comprises an internal default routing system which may contain preferential order flow agreements with numerous market makers.</p>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <div className="Currency_Service">
                        <img className="img-fluid" src={withdraw} alt="withdraw" title="withdraw" />
                        <h4>Withdrawal Routing</h4>
                        <p>Let your users withdraw the value associated with their assets and route the payment on their preferred platform with Espay’s exchange development solutions.</p>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <div className="Currency_Service">
                        <img className="img-fluid" src={risksecurity} alt="risksecurity" title="risksecurity" />
                        <h4>Risk Managers</h4>
                        <p>Espay offers risk managers that utilize dozens of trading rules on the basis of a symbol, exposure, liquidity/security triggers. It safeguards your traders and blocks wrong trades at the point of order entry.</p>
                    </div>
                </Col>
            </Row>          
        </div>
      );
    }
}

export default Currency_Section2;