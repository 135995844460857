import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import Derivativesimg from '../../../Images/faq/Derivatives.jpg';

export default class Derivatives extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What Are Derivatives?</h2> 
                            <img src={Derivativesimg} alt="Derivatives" title="Derivatives" className="img-fluid faqimg"/>
                            <p>Derivatives form the foundation of financial stability in traditional financial markets. They are used to transfer risk from one person to another and can be thought of as insurance contracts on the variation value expressed on an underlying asset. Prediction Markets are in their infancy and have begun placing option bets on the future of specific stock based on derivative products. Financial derivatives are not as common in the crypto space today but many projects are emerging and with security tokens becoming popularised many believe this is set to change sooner than later.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
