import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";


import Slidermain from "./banner";
import StoSection1 from "./Sto-Section1";
import StoServices from "./Sto-Section2";
import StoBenefits from "./Sto-Section3";
//import TalkToOurExperts from "../TalkToOurExperts/TalkToOurExpertsForm";
import TalkToOurExperts from "../Contactus/ContactusdetialsSTO";
import Certificate from "../Certificate/index";
import Faqmain from '../Faq/faq';

export default class index extends Component {
  componentDidMount() {
    ReactGA.initialize('UA-125025994-1')
    ReactGA.pageview(document.location.pathname)
    ReactGA.pageview(document.location.path)
  }
  
  scrollPositions = {}
  componentWillMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"
    }
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        {this.props.children}
        <Helmet>
            <title>STO Solutions | Security Token Offerings Consulting | Espay.Exchange</title>
            <meta name="description" content="EspayExchange support you from the beginning in the STO development with our consulting services in all areas of the security token offering Launch."/>
            <meta property="og:title" content="STO Solutions | Security Token Offerings Consulting | Espay.Exchange" />
            <meta property="og:description" content="EspayExchange support you from the beginning in the STO development with our consulting services in all areas of the security token offering Launch." />
            <meta name="twitter:title" content="STO Solutions | Security Token Offerings Consulting | Espay.Exchange" />
            <meta name="twitter:description" content="EspayExchange support you from the beginning in the STO development with our consulting services in all areas of the security token offering Launch." />
            <link rel="canonical" href="https://www.espay.exchange/security-token-offering-advice" />
        </Helmet>

        <Container fluid={true} className="AdviceExchange-bg">
          <Container>
            <Slidermain />
          </Container>
        </Container>

        <Container>
            <StoSection1 />
        </Container>


        <Container fluid={true} className="AdviceServices-bg">
          <Container>
            <StoServices />
          </Container>
        </Container>

        <Container>
            <StoBenefits />
        </Container>

        
        <Container>
            <Certificate />
        </Container>
        
        
        <Container>
            <h2 className="faqmaintitle">Frequently Asked Questions</h2>
            <Faqmain />
        </Container>
        
        
        <TalkToOurExperts />
       
        

        <ScrollUpButton className="ScrollUpButton"/>
      </div>
    )
  }
}
