import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import CryexchangeIcon1 from '../../Images/MarketingResearch/CryexchangeIcon1.png';
import CryexchangeIcon2 from '../../Images/MarketingResearch/CryexchangeIcon2.png';
import CryexchangeIcon3 from '../../Images/MarketingResearch/CryexchangeIcon3.png';
import CryexchangeIcon4 from '../../Images/MarketingResearch/CryexchangeIcon4.png';


export default class CryptocurrencyExchange extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm={12}>                    
                        <div className="heading-title">
                            <h2>Difference between Cryptocurrency Exchange and Security Token Exchange</h2>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>  
                        <div className="exchange-box">
                            <div className="ex-box-main">
                                <div className="ex-box">
                                    <h4>Cryptocurrency Exchange</h4>
                                </div>
                                <div className="ex-box">
                                    <h4>Security Token Exchange</h4>
                                </div>
                            </div>
                            <div className="ex-common ex-box-1 ex-blue">
                                <div className="ex-box-contant">
                                    <p>Buy, sell, and trade cryptocurrencies</p>
                                </div>
                                <div className="ex-box-img">
                                    <img src={CryexchangeIcon1} alt="Buy, sell, and trade cryptocurrencies" title="Buy, sell, and trade cryptocurrencies" />
                                </div>
                            </div>
                            <div className="ex-common ex-box-2 ex-blue">
                                <div className="ex-box-contant">
                                    <p>No need for smart contracts</p>
                                </div>
                                <div className="ex-box-img">
                                    <img src={CryexchangeIcon2} alt="smart contracts" title="smart contracts" />
                                </div>
                            </div>
                            <div className="ex-common ex-box-3 ex-green">
                                <div className="ex-box-contant">
                                    <p>Buy, sell, and trade security tokens</p>
                                </div>
                                <div className="ex-box-img">
                                    <img src={CryexchangeIcon3} alt="trade security tokens" title="trade security tokens" />
                                </div>
                            </div>
                            <div className="ex-common ex-box-4 ex-green">
                                <div className="ex-box-contant">
                                    <p>Makes use of smart contracts for trading</p>
                                </div>
                                <div className="ex-box-img">
                                    <img src={CryexchangeIcon4} alt="smart contracts for trading" title="smart contracts for trading" />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
