import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Corporates from '../../Images/Corporates.png';
import liabilities from '../../Images/liabilities.png';
import RealEstate from '../../Images/RealEstate.png';
import Commodities from '../../Images/Commodities.png';

class why_choose_hybrid_section4 extends Component {
    render() {
      return (
        <div> 
            <Row> 
                <Col sm="12">
                    <div className="SecurityTokentitle">
                    <h2>Ideal Security Token Exchange Solution For</h2>
                    <p>What can be converted and listed as Security Tokens</p>
                    </div>
                </Col>
            </Row> 
            <Row>
                <Col sm="3">
                    <div className="IdealSecurityToken">
                        <img className="img-fluid" src={Corporates} alt="Corporates" title="Corporates" />
                        <span>Corporates</span>
                    </div>
                </Col>
                <Col sm="3">
                    <div className="IdealSecurityToken">
                        <img className="img-fluid" src={liabilities} alt="Liabilities" title="Liabilities" />
                        <span>Liabilities</span>
                    </div>
                </Col>
                <Col sm="3">
                    <div className="IdealSecurityToken">
                        <img className="img-fluid" src={RealEstate} alt="Assets like Real Estate" title="Assets like Real Estate" />
                        <span>Assets like Real Estate</span>
                    </div>
                </Col>
                <Col sm="3">
                    <div className="IdealSecurityToken">
                        <img className="img-fluid" src={Commodities} alt="Commodities" title="Commodities" />
                        <span>Commodities</span>
                    </div>
                </Col>
            </Row>         
        </div>
      );
    }
}

export default why_choose_hybrid_section4;