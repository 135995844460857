import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import STOPaymentSystems from '../../../Images/faq/STOPaymentSystems.jpg';

export default class Paymentsystems extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">How Security Tokens Could Be Implemented In Payment Systems?</h2> 
                            <img src={STOPaymentSystems} alt="Payment Systems" title="Payment Systems" className="img-fluid faqimg"/>
                            <p>The use of credit cards has been a real applicable method for financial transactions. However, security issues have been the greatest challenge to their use.</p>
                            <p>To solve this problem for once, credit card tokenization could be introduced. The aim is to keep unsecured data and for going through enterprise systems. In essence, the technology uses a randomly generated code (T) instead of a credit card number. This code has no value to hackers.</p>
                            <p>The data cannot go into enterprise systems like ERP, CRM, legacy applications and eCommerce sites. This is the most secure way to keep financial information and other personal data safe.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
