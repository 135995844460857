import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import Switzerland from '../../../Images/faq/Switzerland.jpg';

export default class STSwitzerland extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is the state of security tokens in Switzerland?</h2> 
                            <img src={Switzerland} alt="Switzerland" title="Switzerland" className="img-fluid faqimg"/>
                            <p>Switzerland has long been a financial epicenter in Europe. Swiss officials are keen on extending their financial influence into the blockchain sector. The country recently opened a blockchain business sector dubbed the Crypto Valley. Here, blockchain startups receive reduced taxes and other benefits provided by the Swiss government. Aside from financial benefits, Swiss-based blockchain firms benefit greatly from the country’s clear cut blockchain regulations. The Swiss Financial Market Supervisory Authority (FINMA) breaks tokens into four distinct categories. These categories include asset, payment, utility, and hybrid tokens.</p>
                            <p>Aside from financial benefits, Swiss-based blockchain firms benefit greatly from the country’s clear cut blockchain regulations. The Swiss Financial Market Supervisory Authority (FINMA) breaks tokens into four distinct categories. These categories include asset, payment, utility, and hybrid tokens.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
