import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import trading_market from '../../../../Images/trading_market.png';
import apple from '../../../../Images/ios_app_logo.png';
import playstore from '../../../../Images/android_app_logo.png';
class trading_market_section1 extends Component {
    render() {
      return (
        <div> 
            <Row> 
                <Col sm="6">
                    <div className="tradingmarkettitle">                       
                        <p>P2P crypto exchanges enable the traders to trade directly without any reliance on the third party.</p>
                        <p>Regular crypto exchange providers serve as mediators among their customers and make a profit by gathering fees. On the other hand, when it comes to Espay’s 
                            <Link to="/p2p-exchange-software-development" title=" P2P exchange software"> <strong> P2P exchange software </strong></Link> the communications are completely directed by pre-defined software, without the need for middlemen. The peer-to-peer crypto exchanges are the best examples of the decentralization.</p>
                        <img className="img-fluid" src={apple} alt="apple logo" title="apple logo " />
                        <img className="img-fluid" src={playstore} alt="playstore logo" title="playstore logo" />
                    </div>
                </Col>
                <Col sm="6" className="mt-30">
                    <img className="img-fluid" src={trading_market} alt="TRADING MARKET SETUP" 
                    title="TRADING MARKET SETUP" />
                </Col>               
            </Row>          
        </div>
      );
    }
}

export default trading_market_section1;