import React, { Component } from 'react'
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import TrendsOf2019 from '../../../Images/faq/TrendsOf2019.jpg';

export default class Securitytokensbigdeal extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">Why Security Tokens are the big deal?</h2>                      
                            <p>ICOs got traction in 2017 and raised $5.6 Billion. In the first quarter of 2018 the raised amount was USD 7 Billion and the market went crazy after it. Though, in the next 2 quarters the capitals took huge blow and had been falling since then. But still it managed to raise money, and the total amount concludes to $12.3B. If we calculate the average capital for every token, we’ll get a whooping $29.7M.</p>
                            <img src={TrendsOf2019} alt="Security Tokens" title="Security Tokens" className="img-fluid faqimg"/>                            
                            <p>Well, that was just about the ICOs. The coins or tokens with some services or some good idea with them. But, after the fall of Cryptocurrency in 2018, the world has started thinking about the real use case of the blockchain and that is tokenizing the rel world assets/secuities and put them all on blockchain.</p>
                            <p>Here are some stats for the securities being traded. The entire worlds stock exchanges have a capitalization of $80 trillion USD (trending up from $25 trillion in 2009 a 320% increase). The largest uptrend over the last 20 years is the growth of algorithmic trading. Over 1 year 60.49% of fund managers failed to beat the market index. Now consider getting the securities being tokenized and started getting traded globally on 24 hrs open market and without much intereference of the middliers.</p>
                            <p>The tokenization seems to be solving the problems of current system of security trading by making it easier to liquidize the asset and giving it a 24hrs open global market making it easier to buy or sell securities across the globe. Hence, we are forseeing a booming growth in this industry and it is likely that 80% of total securities will be tokenized by 20-20 making it a 100 Trillion dollar industry in coming 10 years.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
