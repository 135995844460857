import React, { Component } from 'react'
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import EcosystemSecurityToken from '../../../Images/faq/EcosystemSecurityToken.jpg';

export default class Ecosystemsecuritytoken extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">Ecosystem of Security Token</h2> 
                            <img src={EcosystemSecurityToken} alt="Ecosystem of Security Token" title="Ecosystem of Security Token" className="img-fluid faqimg"/>                                                 
                            <p>As security tokens and tokenized assets gain steam in the crypto ecosystem, clear infrastructure begins to form. As we previously covered in our social posts series on security tokens, these new instruments aim to tokenize the ownership of all assets, whether they are public and private equities, real estate, precious metals or debt — even things like art. Investors are clearly taking notice as they pump millions of dollars into projects that support the issuance, exchange, and custody of security tokens.</p>
                            <p>We can categorized the security token ecosystem into six sub-categories</p>
                            <ul>
                                <li>Issuance</li>
                                <li>Broker-Dealers</li>
                                <li>Custody & Trust</li>
                                <li>Legal</li>
                                <li>Compliance And</li>
                                <li>Trading</li>
                            </ul>
                            <p>We will see them one by one in our next posts...</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
