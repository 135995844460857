import React, { Component } from 'react';
import {Row, Col, Jumbotron} from 'reactstrap';
import { Link } from "react-router-dom";

class Banner extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm={{size:8,offset:2}} xs={{size:11,offset:0}}>                  
                    <Jumbotron>
                        <div className="bannerdetails">
                            <h1>Web and Mobile Trading Experience </h1>
                            <p>USER APP AND ACCESS MEDIA</p>
                            <Link to="/talk-to-crypto-exchange-expert">Talk To Our Experts</Link>
                        </div>
                    </Jumbotron>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default Banner;