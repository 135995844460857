import React, { Component } from 'react';
import {Row, Col} from 'reactstrap';

import SECCertificate from '../../Images/certificate/SEC-logo.png';
import certificatePCI from '../../Images/certificate/PCI-logo.png';
import certificateGDPR from '../../Images/certificate/GDPR-Logo.png';
import certificateknowyourcustomer from '../../Images/certificate/knowyourcustomer_logo_480.png';
import certificateowasp from '../../Images/certificate/owasp-logo-vert.png';
import certificateazure from '../../Images/certificate/azure@2x.png';

export default class index extends Component {
  render() {
    return (
      <div>
          <Row>
            <Col sm="12"  className="certificatetitle">
                <h2>Our Certificates</h2>
             </Col>
          </Row>
        <Row>
            <Col sm="12" md={4} lg={2}>                         
                <div className="certificateshortbox">
                    <img className="img-fluid" src={SECCertificate} alt="Compliance with SEC requirement" title="Compliance with SEC requirement" />
                    <p>Compliance with SEC requirement</p>
                </div>
            </Col>    
            <Col sm="12" md={4} lg={2}>                         
                <div className="certificateshortbox">
                    <img className="img-fluid" src={certificatePCI} alt="Payment Card industry" title="Payment Card industry" />
                    <p>Payment Card industry</p>
                </div>
            </Col>
            <Col sm="12" md={4} lg={2}>                         
                <div className="certificateshortbox">
                    <img className="img-fluid" src={certificateGDPR} alt="General data Protection " title="General data Protection " />
                    <p>General data Protection </p>
                </div>
            </Col>
       
            <Col sm="12" md={4} lg={2}>                         
                <div className="certificateshortbox">
                    <img className="img-fluid" src={certificateknowyourcustomer} alt="KYC/AML Compliance" title="KYC/AML Compliance" />
                    <p>KYC/AML Compliance</p>
                </div>
            </Col>    
            <Col sm="12" md={4} lg={2}>                         
                <div className="certificateshortbox">
                    <img className="img-fluid" src={certificateowasp} alt="Owasp compliance" title="Owasp compliance" />
                    <p>Owasp compliance</p>
                </div>
            </Col>
            <Col sm="12" md={4} lg={2}>                         
                <div className="certificateshortbox">
                    <img className="img-fluid" src={certificateazure} alt="Microsoft Azure " title="Microsoft Azure" />
                    <p>Microsoft Azure</p>
                </div>
            </Col>
        </Row>  
      </div>
    )
  }
}
