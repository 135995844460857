import React, { Component, Fragment } from 'react';
import ReactGA from 'react-ga';
import { Container, Row, Col  } from 'reactstrap';
import { Link } from 'react-router-dom';

import whatsappicon from '../Images/whatsappicon.png';
import phonereceivericon from '../Images/phonereceiver.png';

class CopyrightFooter extends Component {
    componentWillMount(){
        var str = window.location.href
        var res = str.split("/");
        this.setState({
          url:res[3]
        })
    }
    contactClick() {
        ReactGA.event({
            category: 'Click to Call',
            action: 'tel://(+61) 421-514-353',
            label: 'Clicked on Mobile footer call - (+61) 421-514-353',
        });
    }
    whatsappClick() {
        ReactGA.event({
            category: 'Click to Whatsapp',
            action: 'https://api.whatsapp.com/send?phone=61421-514-353',
            label: 'Clicked on Whatsapp - (+61) 421-514-353',
        });
    }

    render() {
      return (
        <Fragment>
         

        <div className={(this.state.url === 'crypto-exchange-software' || this.state.url === 'crypto-exchange-software#TalkToExpert') ? "cryptocopyrightfooter" : "allcopyrightfooter"}>
            <div className="crpcopyrightfooter"> 
                <Container>           
                    <Row>
                        <Col sm="12 center">                         
                            <div className="cescontent">Copyright © 2021 ESPAY PTY LTD</div>
                        </Col>
                    </Row> 
                </Container>   
            </div>

            <div className="copyrightfooter"> 
                <Container>           
                    <Row>
                        <Col sm="4">                         
                        <p>Copyright © 2021 ESPAY PTY LTD</p>
                        </Col>
                        <Col sm="8">                         
                            <ul>
                                <li>
                                    <Link to="/sitemap">SiteMap</Link>
                                </li>
                                <li>
                                    <Link to="/terms-of-use">Terms of Use</Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="/cookie-policy">Cookie Policy</Link>
                                </li>
                            </ul>
                        </Col>
                    </Row> 
                </Container>              
            </div>
        </div>

        <div className="mobilefootercall">
            <Row>
                <Col xs={12} sm={7}>
                    <Link className="bookdemomobile" to="/contact-us">Book FREE Demo</Link>
                </Col>
                <Col xs={12} sm={5}>
                    <ul>
                        <li><a href="https://api.whatsapp.com/send?phone=61421-514-353" onClick={()=>{this.whatsappClick()}}>
                            <img src={whatsappicon} alt="WhatsApp" title="WhatsApp" /></a></li>
                        <li><a href="tel://(+61) 421-514-353" onClick={()=>{this.contactClick()}}>
                            <img src={phonereceivericon} alt="Contact Espay" title="Contact Espay" /></a></li>
                    </ul>
                </Col>
            </Row>
        </div>   
        </Fragment>
      );
    }
}

export default CopyrightFooter;