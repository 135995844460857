import React, { Component, Fragment } from 'react';

import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import {Helmet} from "react-helmet";

import Whitepaperbanner from './WhitepaperBanner';
import Whitepaperdetails from './WhitepaperDetails';

export default class index extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-125025994-1')
        ReactGA.pageview(document.location.pathname)
        ReactGA.pageview(document.location.path)
      }
      
      scrollPositions = {}
      componentWillMount() {
        if ("scrollRestoration" in window.history) {
          window.history.scrollRestoration = "manual"
        }
        window.scrollTo(0, 0)
      }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Whitepaper: Espay-X Whitelabel Cryptocurrency Exchange Software</title>
                    <meta name="description" content="The most comprehensive description of the Espay-X whitelabel cryptocurrency exchange software. From product concept, to all features, to pricing - get all the information here."/>
                    <meta property="og:title" content="Whitepaper: Espay-X Whitelabel Cryptocurrency Exchange Software" />
                    <meta property="og:description" content="The most comprehensive description of the Espay-X whitelabel cryptocurrency exchange software. From product concept, to all features, to pricing - get all the information here." />
                    <meta name="twitter:title" content="Whitepaper: Espay-X Whitelabel Cryptocurrency Exchange Software" />
                    <meta name="twitter:description" content="The most comprehensive description of the Espay-X whitelabel cryptocurrency exchange software. From product concept, to all features, to pricing - get all the information here." />
                    <link rel="canonical" href="https://www.espay.exchange/whitelabel-cryptocurrency-exchange-software-whitepaper" />
                </Helmet>

                <Whitepaperbanner />
                <Whitepaperdetails />
                
                <ScrollUpButton className="ScrollUpButton"/>
            </Fragment>
        )
    }
}
