import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import cmsimg from '../../../../Images/CMS_based_Website_Development.png';

class marketing_features_section1 extends Component {
    render() {
      return (
        <div> 
            <Row> 
                <Col sm="6">
                    <div className="marketingfeaturestitle">                       
                        <p>Leverage best out of Espay’s professional <Link to="/crypto-exchange-software" title="Espay Exchange"> <strong>crypto exchange software </strong></Link> solutions that deliver major marketing features for your digital coins trading platform. Marketing is an essential channel for any business and crypto exchange business is no exception from this. Espay’s experts understand the significance of today’s crypto economy and develop crypto exchange software accordingly.</p>
                        <p>Ensure the growth of your crypto trading platform with Espay’s solutions and create value for your targeted customers in each corner of the world.</p>                         
                    </div>
                </Col>
                <Col sm="6" className="mt-30">
                    <img className="img-fluid" src={cmsimg} alt="MARKETING FEATURES" 
                    title="MARKETING FEATURES" />
                </Col>                 
            </Row>          
        </div>
      );
    }
}

export default marketing_features_section1;