import React, { Component } from 'react'
import { BrowserRouter as Router, Route , Switch } from "react-router-dom";

import Faqs from '../Components/Faqs/index';
import Faqmakemoney from '../Components/Faqs/CryptoExchanges/faqmakemoney';
import Faqcryptoexchanges from '../Components/Faqs/CryptoExchanges/Faqcryptoexchanges';
import CryptotradingPairswork from '../Components/Faqs/CryptoExchanges/CryptotradingPairswork';
import Cryptocurrencyexchangeswork from '../Components/Faqs/CryptoExchanges/Cryptocurrencyexchangeswork';
import Startcryptoexchangebusiness from '../Components/Faqs/CryptoExchanges/Startcryptoexchangebusiness';
import Securitytokenofferingwork from '../Components/Faqs/CryptoExchanges/Securitytokenofferingwork';
import whatcentralisedexchange from '../Components/Faqs/CryptoExchanges/WhatCentralisedexchange';
import Whatdecentralizedcryptoexchange from '../Components/Faqs/CryptoExchanges/Whatdecentralizedcryptoexchange';
import WhatDigitalassetexchange from '../Components/Faqs/CryptoExchanges/WhatDigitalassetexchange';
import Whatp2pexchange from '../Components/Faqs/CryptoExchanges/Whatp2pexchange';
import WhatWhitelabelcryptoexchange from '../Components/Faqs/CryptoExchanges/WhatWhitelabelcryptoexchange';


import WhatSecuritytoken from '../Components/Faqs/SecurityTokenExchange/WhatSecuritytoken';
import Whatissto from '../Components/Faqs/SecurityTokenExchange/WhatisSTO';
import Whatisstoexchange from '../Components/Faqs/SecurityTokenExchange/WhatSTOExchange';
import HavingaSTOExchange from '../Components/Faqs/SecurityTokenExchange/HavingaSTOExchange';
import Marketbullishabout from '../Components/Faqs/SecurityTokenExchange/Marketbullishabout';
import Determinetokenfalls from '../Components/Faqs/SecurityTokenExchange/Determinetokenfalls';
import Securitytokenscanchange from '../Components/Faqs/SecurityTokenExchange/Securitytokenscanchange';
import Tokensclassified from '../Components/Faqs/SecurityTokenExchange/Tokensclassified';
import Securitytokenliquidize from '../Components/Faqs/SecurityTokenExchange/Securitytokenliquidize';
import Doestokenizationwork from '../Components/Faqs/SecurityTokenExchange/Doestokenizationwork';
import Securitytokensbigdeal from '../Components/Faqs/SecurityTokenExchange/Securitytokensbigdeal';
import Ecosystemsecuritytoken from '../Components/Faqs/SecurityTokenExchange/Ecosystemsecuritytoken';
import Significanceissuance from '../Components/Faqs/SecurityTokenExchange/Significanceissuance';
import Brokerdealercomponent from '../Components/Faqs/SecurityTokenExchange/Brokerdealercomponent';
import Custodytrustcomponent from '../Components/Faqs/SecurityTokenExchange/Custodytrustcomponent';
import Legalcomponent from '../Components/Faqs/SecurityTokenExchange/Legalcomponent';
import Compliancecomponent from '../Components/Faqs/SecurityTokenExchange/Compliancecomponent';
import Tradingplatformwork from '../Components/Faqs/SecurityTokenExchange/Tradingplatformwork';
import Equitytokens from '../Components/Faqs/SecurityTokenExchange/Equitytokens';


import DebtTokens from '../Components/Faqs/SecurityTokenExchange/DebtTokens';
import Derivatives from '../Components/Faqs/SecurityTokenExchange/Derivatives';
import SecuritytokenEurope from '../Components/Faqs/SecurityTokenExchange/SecuritytokenEurope';
import FinancialDirective from '../Components/Faqs/SecurityTokenExchange/FinancialDirective';
import STLiechtenstein from '../Components/Faqs/SecurityTokenExchange/STLiechtenstein';
import STGermany from '../Components/Faqs/SecurityTokenExchange/STGermany';
import STSwitzerland from '../Components/Faqs/SecurityTokenExchange/STSwitzerland';
import STMalta from '../Components/Faqs/SecurityTokenExchange/STMalta';
import STEstonia from '../Components/Faqs/SecurityTokenExchange/STEstonia';
import STFrance from '../Components/Faqs/SecurityTokenExchange/STFrance';
import STuk from '../Components/Faqs/SecurityTokenExchange/STuk';
import STSlovenia from '../Components/Faqs/SecurityTokenExchange/STSlovenia';
import STEcuador from '../Components/Faqs/SecurityTokenExchange/STEcuador';
import STBankingsecurity from '../Components/Faqs/SecurityTokenExchange/STBankingsecurity';
import STFinancialcenter from '../Components/Faqs/SecurityTokenExchange/STFinancialcenter';
import HealthcareAssets from '../Components/Faqs/SecurityTokenExchange/HealthcareAssets';
import Paymentsystems from '../Components/Faqs/SecurityTokenExchange/Paymentsystems';
import STAustralia from '../Components/Faqs/SecurityTokenExchange/STAustralia';
import STAsiancountries from '../Components/Faqs/SecurityTokenExchange/STAsiancountries';


import decentralizedexchange from '../Components/Faqs/DecentralizeExchange/decentralizedexchange';
import BenefitsDecentralized from '../Components/Faqs/DecentralizeExchange/BenefitsDecentralized';
import TechnicalSpecifications from '../Components/Faqs/DecentralizeExchange/TechnicalSpecifications';
import BusinessesDecentralized from '../Components/Faqs/DecentralizeExchange/BusinessesDecentralized';
import GrowthPotential from '../Components/Faqs/DecentralizeExchange/GrowthPotential';
import Atomicswapdevelopment from '../Components/Faqs/DecentralizeExchange/Atomicswapdevelopment';


import BenefitsP2Pexchange from '../Components/Faqs/P2PExchange/BenefitsP2Pexchange';
import Marketprediction from '../Components/Faqs/P2PExchange/Marketprediction';
import TechnologyP2Pexchange from '../Components/Faqs/P2PExchange/TechnologyP2Pexchange';


import MarketingCapitalization from '../Components/Faqs/MarketCapitalization/MarketingCapitalization';
import AlternativeTradingsystems from '../Components/Faqs/AlternativeTradingSystems/AlternativeTradingsystems';


export default class faqurl extends Component {
    render() {
        return (
            <Router>
            <div>
            <Switch>
{/* Crypto Exchange     ID= faqpanel1 */}
                <Route exact path="/Faqs" component={Faqs} />
                <Route exact path="/do-crypto-exchanges-make-money" component={Faqmakemoney} />
                <Route exact path="/how-do-centralized-crypto-exchanges-work" component={Faqcryptoexchanges} />
                <Route exact path="/how-do-crypto-trading-pairs-work" component={CryptotradingPairswork} />
                <Route exact path="/how-do-cryptocurrency-exchanges-work" component={Cryptocurrencyexchangeswork} />
                <Route exact path="/how-do-i-start-a-crypto-exchange-business" component={Startcryptoexchangebusiness} />
                <Route exact path="/how-do-security-token-offering-work" component={Securitytokenofferingwork} />
                <Route exact path="/what-is-a-centralised-exchange" component={whatcentralisedexchange} />
                <Route exact path="/what-is-a-decentralized-crypto-exchange" component={Whatdecentralizedcryptoexchange} />
                <Route exact path="/what-is-a-digital-asset-exchange" component={WhatDigitalassetexchange} />
                <Route exact path="/what-is-p2p-exchange" component={Whatp2pexchange} />
                <Route exact path="/what-is-white-label-crypto-exchange" component={WhatWhitelabelcryptoexchange} />
                
{/* Security Token Exchange     ID= faqpanel2 */}
                <Route exact path="/what-is-security-token" component={WhatSecuritytoken} />
                <Route exact path="/what-is-sto" component={Whatissto} />
                <Route exact path="/what-is-sto-exchange" component={Whatisstoexchange} />
                <Route exact path="/benefits-of-having-a-sto-exchange" component={HavingaSTOExchange} />
                <Route exact path="/why-the-market-is-bullish-about-the-security-tokens" component={Marketbullishabout} />
                <Route exact path="/how-to-determine-if-a-token-falls-into-the-crieteria-of-security-token" component={Determinetokenfalls} />
                <Route exact path="/security-tokens-can-change-the-market" component={Securitytokenscanchange} />
                <Route exact path="/how-are-tokens-classified" component={Tokensclassified} />
                <Route exact path="/how-security-token-can-liquidize-the-illiquid-assets-securities" component={Securitytokenliquidize} />
                <Route exact path="/how-does-tokenization-work" component={Doestokenizationwork} />
                <Route exact path="/why-security-tokens-are-the-big-deal" component={Securitytokensbigdeal} />
                <Route exact path="/ecosystem-of-security-token" component={Ecosystemsecuritytoken} />
                <Route exact path="/what-is-the-significance-of-issuance-in-security-token-exchange" component={Significanceissuance} />
                <Route exact path="/what-is-the-broker-dealer-component-of-security-token-exchange" component={Brokerdealercomponent} />
                <Route exact path="/what-is-the-custody-trust-component-of-security-token-exchange" component={Custodytrustcomponent} />
                <Route exact path="/what-is-the-legal-component-of-security-token-exchange" component={Legalcomponent} />
                <Route exact path="/what-is-the-compliance-component-of-a-security-token-exchange" component={Compliancecomponent} />
                <Route exact path="/how-does-the-trading-platform-work-in-security-token-exchange" component={Tradingplatformwork} />
                <Route exact path="/what-are-equity-tokens" component={Equitytokens} />
                <Route exact path="/what-are-debt-tokens" component={DebtTokens} />                
                <Route exact path="/what-are-derivatives" component={Derivatives} />                
                <Route exact path="/what-is-the-state-of-security-token-in-europe" component={SecuritytokenEurope} />                
                <Route exact path="/what-is-markets-in-financial-directive-ii" component={FinancialDirective} />                
                <Route exact path="/what-is-the-state-of-security-token-in-liechtenstein" component={STLiechtenstein} />                
                <Route exact path="/the-state-of-security-tokens-in-germany" component={STGermany} />                
                <Route exact path="/what-is-the-state-of-security-tokens-in-switzerland" component={STSwitzerland} />                
                <Route exact path="/what-is-the-state-of-security-tokens-in-malta" component={STMalta} />                
                <Route exact path="/what-is-the-state-of-security-tokens-in-estonia" component={STEstonia} />
                <Route exact path="/what-is-the-state-of-security-tokens-in-france" component={STFrance} />
                <Route exact path="/what-is-the-state-of-security-token-in-uk" component={STuk} />
                <Route exact path="/what-is-the-scenario-of-security-token-scenario-in-slovenia" component={STSlovenia} />
                <Route exact path="/what-is-the-state-of-security-tokens-in-ecuador" component={STEcuador} />
                <Route exact path="/security-token-offering-and-banking-security" component={STBankingsecurity} />
                <Route exact path="/how-does-the-security-token-work-with-the-financial-center" component={STFinancialcenter} />
                <Route exact path="/tokenization-of-healthcare-assets" component={HealthcareAssets} />
                <Route exact path="/how-security-tokens-could-be-implemented-in-payment-systems" component={Paymentsystems} />
                <Route exact path="/state-of-security-token-offerings-in-australia" component={STAustralia} />
                <Route exact path="/state-of-security-token-offering-in-asian-countries" component={STAsiancountries} />
                
{/* Decentralize Exchange     ID= faqpanel3 */}
                <Route exact path="/what-is-decentralized-exchange" component={decentralizedexchange} />
                <Route exact path="/what-are-the-benefits-of-decentralized-exchange" component={BenefitsDecentralized} />
                <Route exact path="/what-are-the-key-technical-specifications-of-the-decentralized-exchange" component={TechnicalSpecifications} />
                <Route exact path="/how-businesses-could-deploy-a-decentralized-exchange" component={BusinessesDecentralized} />
                <Route exact path="/the-growth-and-potential-of-decentralized-exchanges" component={GrowthPotential} />
                <Route exact path="/what-is-atomic-swap-and-how-it-is-used-in-decentralized-exchange-development" component={Atomicswapdevelopment} />
           
{/* P2p Exchanges  ID= faqpanel4 */}
                <Route exact path="/benefits-of-P2P-exchange" component={BenefitsP2Pexchange} />
                <Route exact path="/market-prediction-for-cryptocurrency-exchange" component={Marketprediction} />
                <Route exact path="/technology-of-P2P-exchange-and-earning-opportunity" component={TechnologyP2Pexchange} />

{/* Market Capitalization  ID= faqpanel5 */}
                <Route exact path="/what-is-marketing-capitalization-of-cryptocurrency-exchange" component={MarketingCapitalization} />
                
{/* Alternative Trading Systems  ID= faqpanel6 */}
                <Route exact path="/what-is-an-alternative-trading-system" component={AlternativeTradingsystems} />
                
             </Switch>
            </div>

            </Router>
        )
    }
}
