import React, { Component } from 'react';
import { Row, Col} from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {faCreditCard, faServer, faDesktop  } from '@fortawesome/free-solid-svg-icons';
import AtomicSwapPower from '../../Images/decentralized/AtomicSwapPower.png';
import Reducedcostrisks from '../../Images/decentralized/Reducedcostrisks.png';
import DEXSmartContract from '../../Images/decentralized/DEXSmartContract.png';
import Fullcustomisation from '../../Images/decentralized/Fullcustomisation.png';

class development_decentralized_section1 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12">
                    <div className="Decentralizedtitle">
                        <h2>Smart Contract Based Exchange Development</h2>
                        <p>Espay is delivering peer-to-peer atomic swap technology for the fastest, more secure and user-friendly DEX globally.</p>
                    </div>
                </Col>
            </Row>  
            <Row>
                <Col sm="6">
                    <div className="DecentralizedServiceBox">
                        {/* <FontAwesomeIcon icon={faDesktop}  size="3x" /> */}
                        <img src={AtomicSwapPower} alt="Atomic swap exchange development" title="Atomic swap exchange development" />
                        <h3>Atomic swap exchange development</h3>
                        <p>Blockchain based Atomic Swaps enables your traders to trade digital currency directly and without third
                            parties within the private wallet.</p>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="DecentralizedServiceBox">
                        {/* <FontAwesomeIcon icon={faCreditCard} size="3x"/> */}
                        <img src={Fullcustomisation} alt="Full Customization" title="Full Customization" />
                        <h3>Full Customization</h3>
                        <p>Espay provides a safe, flexible and fully customizable white label decentralized exchange for businesses
                            who want to create their own trading platforms that best suit their needs.</p>
                    </div>
                </Col>
            </Row>        
            <Row>
                <Col sm="6">
                    <div className="DecentralizedServiceBox">
                        {/* <FontAwesomeIcon icon={faCreditCard} size="3x"/> */}
                        <img src={Reducedcostrisks} alt="Reduced Cost & Risks" title="Reduced Cost & Risks" />
                        <h3>Reduced Cost & Risks</h3>
                        <p>Task automation with a code-based digital contract with minimum or no human intervention reduces the costs of processing and conventional contracting.</p>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="DecentralizedServiceBox">
                        {/* <FontAwesomeIcon icon={faServer} size="3x"/> */}
                        <img src={DEXSmartContract} alt="DEX Smart Contract" title="DEX Smart Contract" />
                        <h3>DEX Smart Contract</h3>
                        <p>Our decentralized smart contract exchange covers end user information protection, private key architecture, business logic, software maintenance to ensure security on decentralized systems in exchange market.</p>
                    </div>
                </Col>
            </Row>
            
        </div>
      );
    }
}

export default development_decentralized_section1;