import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import Integration from '../../../../Images/Currencypairs/Integration.png';

class Currency_Section1 extends Component {
    render() {
      return (
        <div> 
            <Row> 
                <Col sm="6">
                    <div className="Currency">
                        <p>Espay offers the best way to manage and track preferences for available crypto pairs, market 
                            cap, volume and much more. Let your users get access to accurate statistics and profit/loss 
                            information about their crypto investments.</p>
                        <p>Your traders can leverage the latest quotes as well as price charts for crypto pairs with 
                            Espay’s <Link to="/" title="Espay Exchange"> <strong> digital asset exchange software.</strong>
                            </Link> It will aid them to view on value, market cap and 
                            supply by exchange for every crypto pair in the market.</p>
                    </div>
                </Col>
                <Col sm="6" className="mt-30">
                    <img className="img-fluid" src={Integration} alt="Integration" title="Integration" />
                </Col>  
            </Row>          
        </div>
      );
    }
}

export default Currency_Section1;