import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from "react-router-dom";

export default class FutureLaveraged extends Component {
  render() {
    return (
      <div>
        <div className="Leveragework-bg text-white">
          <Container>
            <Row>
              <Col sm="4" className="">
              <h3 className="futurelavrage-title">Features of <br/>Leveraged Crypto <br/>Exchange</h3>
              <Link className="extra-btn" to="/talk-to-crypto-exchange-expert">Talk To Our Experts</Link>
              </Col>
              <Col sm="4" className="lavrage-works-content lavrage-works-first">
                <div className="future-box">
                  <p className="future-box-heading">Advanced Leverage Configuration</p>
                  <p className="future-box-desc">High value leverage flexibility to support your traders strategy</p>
                </div>
                <div className="future-box">
                  <p className="future-box-heading">VaR Engine</p>
                  <p className="future-box-desc">Value at Risk based risk computation engine for risk management.</p>
                </div>
                <div className="future-box">
                  <p className="future-box-heading">Layered Security</p>
                  <p className="future-box-desc">Multiple level security within Multi-Sig wallets and User Accounts.</p>
                </div>
                <div className="future-box">
                  <p className="future-box-heading">Multiple Order Types</p>
                  <p className="future-box-desc">Highest range of order types with advanced support Iceberg, FOK, GTC. </p>
                </div>
              </Col>

              

              <Col sm="4" className="lavrage-works-content">
                <div className="future-box">
                  <p className="future-box-heading">High Frequency Match Engine</p>
                  <p className="future-box-desc">Best in Class Match Engine to process 1Mn TPS at microseconds of delay.</p>
                </div>
                <div className="future-box">
                  <p className="future-box-heading">Feature Rich Admin Panel </p>
                  <p className="future-box-desc">Control the Users with Leverage and Risk Management at the tip of your hand.</p>
                </div>
                <div className="future-box">
                  <p className="future-box-heading">100% Cloud Based</p>
                  <p className="future-box-desc">Serverless Architecture with utmost scalability and 24*7 Uptime.</p>
                </div>
                <div className="future-box">
                  <p className="future-box-heading">Smart Modular Architecture</p>
                  <p className="future-box-desc">Modular Based Infrastructure to easily customize the modules and provide best security.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>


      </div>
    )
  }
}
