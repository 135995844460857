import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

export default class STOServices extends Component {
  render() {
    return (
      <div>
        <Row>   
            <Col sm="12">
                <div className="stoadviceitle">
                    <h2>Our Service</h2>
                </div>    
            </Col>
        </Row> 
        <Row>   
            <Col sm="12">
                <div className="stoservicebox">
                    <h3>STO Website Development</h3>
                    <p>We create a modern website for your security token offering, which not only ensures the successful market 
                        presence of your business, but is also integrated with your Smart Contract and STO Dashboard. 
                        We pay attention to its excellent performance, so your STO website can handle a lot of traffic.</p>
                </div>    
            </Col>
            <Col sm="12">
                <div className="stoservicebox">
                    <h3>STO Token Creation</h3>
                    <p>Our STO agency in Australia your security token according to the highest security standards. After token programming,
                         we conduct extensive testing in the live environment so we can confirm its technical maturity.</p>
                </div>    
            </Col>
            <Col sm="12">
                <div className="stoservicebox">
                    <h3>STO Investor Dashboard</h3>
                    <p>An STO Investor Dashboard automates the sale of your security token and offers a multitude of additional 
                        functions for your STO investors. Our services include conception, design and technical implementation 
                        of your investor dashboard.</p>
                </div>    
            </Col>
        </Row>
      </div>
    )
  }
}
