import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";
import Analytics from "../../Images/Leverage/RiskLavrage/riskanalytics.png";
import Deleveraging from "../../Images/Leverage/RiskLavrage/Deleveraging.png";
import Calculator from "../../Images/Leverage/RiskLavrage/Automatic.png";
import Insurance from "../../Images/Leverage/RiskLavrage/Insurance.png";
import Analyzer from "../../Images/Leverage/RiskLavrage/Analyzer.png";
import Decomposition from "../../Images/Leverage/RiskLavrage/Decomposition.png";




export default class RiskLavraged extends Component {
    render() {
        return (
            <div>
                <Row className="mt-30 mb-30">
                    <Col sm="12">
                        <div className="stoadviceitle mt-30">
                            <h2 className="text-center">Risk Management of Leveraged Crypto Exchange</h2>
                        </div>
                    </Col>
                </Row>


                <Row className="Cutting-edge-section">
                    <Col sm="4">
                        <div className="row cuttingbox">
                            <div className="col-sm-3"><img src={Analytics} alt="On the Fly Risk Analytics" title="On the Fly Risk Analytics" className="img-fluid " /></div>
                            <div className="col-sm-9 overview-cont pr-0">
                                <h4>On the Fly Risk Analytics</h4>
                                <p>Real Time Calculation for all the Pre-Trade and Post-Trade positions to maintain the risk at the lowest possible margin.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm="4">
                        <div className="row cuttingbox">
                            <div className="col-sm-3"><img src={Deleveraging} alt="Smart Deleveraging" title="Smart Deleveraging" className="img-fluid " /></div>
                            <div className="col-sm-9 overview-cont pr-0">
                                <h4>Smart Deleveraging</h4>
                                <p>Automated Deleveraging mechanics based on the margin calculation to avoid all the liquidation and helping by offsetting the risk to high liquid position on mark price.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm="4">
                        <div className="row cuttingbox">
                            <div className="col-sm-3"><img src={Calculator} alt="Automatic PnL Calculator" title="Automatic PnL Calculator" className="img-fluid " /></div>
                            <div className="col-sm-9 overview-cont pr-0">
                                <h4>Automatic PnL Calculator</h4>
                                <p>Real Time Profit and Loss calculator at every order settlements and liquidations.</p>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className="Cutting-edge-section">
                    <Col sm="4">
                        <div className="row cuttingbox">
                            <div className="col-sm-3"><img src={Insurance} alt="nsurance Pool" title="nsurance Pool" className="img-fluid " /></div>
                            <div className="col-sm-9 overview-cont pr-0">
                                <h4>Insurance Pool</h4>
                                <p>Helping out the Traders to give back a boost for position even when they fall below the required Margin to all the open positions.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm="4">
                        <div className="row cuttingbox">
                            <div className="col-sm-3"><img src={Analyzer} alt="What-if Analyzer" title="What-if Analyzer" className="img-fluid " /></div>
                            <div className="col-sm-9 overview-cont pr-0">
                                <h4>What-if Analyzer</h4>
                                <p>The user can enter a hypothetical portfolio, and analyze the contribution to the risk of different risk factors.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm="4">
                        <div className="row cuttingbox">
                            <div className="col-sm-3"><img src={Decomposition} alt="Risk Decomposition Mechanics" title="Risk Decomposition Mechanics" className="img-fluid " /></div>
                            <div className="col-sm-9 overview-cont pr-0">
                                <h4>Risk Decomposition Mechanics</h4>
                                <p>Users can generate a variety of risk decomposition reports from EspayRisk Engine, online as well as the end of the day.</p>
                            </div>
                        </div>
                    </Col>
                </Row>



            </div>
        )
    }
}
