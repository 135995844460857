import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import uidesigning  from '../../../../Images/ul_desing.png';
import mobiletrading  from '../../../../Images/mobile-trading-Copy.png';
import tradingmangmnet  from '../../../../Images/mobile-trading.png';

class dynamic_cms_mngt_section3 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12"> 
                    <div className="dynamiccmsmanagmenttitle">
                        <h2>You May Also Be Interested In</h2>
                    </div>                      
                </Col>
            </Row>  
            <Row>
                <Col sm="4">                         
                    <div className="dynamic-cms-box">
                        <Link to="/exchange-ui-design" title="Ul Designing">
                            <img className="img-fluid" src={uidesigning} alt="Mobile Trading Experience" title="Ul Designing" />
                            <h3 className="interested-header">Ul Designing</h3>
                            <p className="interested-caption">Creation of User Interface based on Customer Preference and Template Selection </p>
                        </Link>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="dynamic-cms-box">
                    <Link to="/mobile-trading-platform" title="Mobile Trading Experience">
                        <img className="img-fluid" src={mobiletrading} alt="mobiletrading" title="Mobile Trading Experience" />
                        <h3 className="interested-header">Mobile Trading Experience</h3>
                        <p  className="interested-caption">Native Espay’s applications for trading on iOS as well as Android devices. It contains rich charting features, cutting-edge order types and a wealth of significant account detail. </p>
                    </Link> 
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="dynamic-cms-box">
                    <Link to="/security-modules-and-features" title="Trading Report Management">
                        <img className="img-fluid" src={tradingmangmnet} alt="Trading Report Management" title="Trading Report Management" />
                        <h3 className="interested-header">Trading Report Management</h3>
                        <p  className="interested-caption">Our reporting software enables traders and managers to easily track each single activity in a trading account.</p>
                    </Link>
                    </div>
                </Col>
            </Row>           
        </div>
      );
    }
}

export default dynamic_cms_mngt_section3;