import React, { Component } from 'react';
import { Row, Col, Jumbotron} from 'reactstrap';

class Banner extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm={{size:8,offset:2}} className="faqbannerdetails">                       
                    <Jumbotron>
                        <h1>Espay-X Knowledge Base</h1>
                        <p>Everything that you need to know to become a cryptocurrency exchange pro.</p>
                    </Jumbotron>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default Banner;