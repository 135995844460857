import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import yearsexperience from '../../Images/centralized/10yearsexperience.png';
import Dedicated from '../../Images/centralized/100Dedicated.png';
import Bankinggradesecurity from '../../Images/centralized/Bankinggradesecurity.png';
import ConvergedExchange from '../../Images/centralized/ConvergedExchange.png';
class exchange_software_standard_section6 extends Component {
    render() {
      return (
        <div className="mt-30 mb-30 WhyChooseExchange"> 
            <Row>
                <Col sm={12}>
                    <div className="enterprisetitle">
                        <h2>Why Choose Espay For Enterprise Exchange Development?</h2>
                    </div>
                </Col>
            </Row>         
            <Row>
                <Col sm={3}>                         
                    <div className="WhyChoosebox">
                        <img src={Dedicated} alt="100+ Dedicated IT Team" title="100+ Dedicated IT Team" />
                        <p>100+ Dedicated IT Team</p>
                    </div>
                </Col>                   
                <Col sm={3}>                         
                    <div className="WhyChoosebox">
                        <img src={Bankinggradesecurity} alt="Banking Grade Security" title="Banking Grade Security" />
                        <p>Banking Grade Security</p>
                    </div>
                </Col>
                <Col sm={3}>                         
                    <div className="WhyChoosebox">
                        <img src={yearsexperience} alt="10+ Years of Experience" title="10+ Years of Experience" />
                        <p>10+ Years of Experience</p>
                    </div>
                </Col>
                <Col sm={3}>                         
                    <div className="WhyChoosebox">
                        <img src={ConvergedExchange} alt="Converged Exchange Solution" title="Converged Exchange Solution" />
                        <p>Converged Exchange Solution</p>
                    </div>
                </Col>
            </Row>
        </div>
      );
    }
}

export default exchange_software_standard_section6;