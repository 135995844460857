import React, { Component } from 'react';
import { Row, Col} from 'reactstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AtomicSwapPower from '../../Images/decentralized/AtomicSwapPowerleft.png';

class work_decrntralized_section2 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12">
                    <div className="Decentralizedtitle">
                        <h2>How Atomic Swap Power Exchange Work?</h2>
                    </div>
                </Col>
                <Col sm="6">
                        <p>When it comes to digital currency, <strong>atomic swaps</strong> are an anticipated feature that could enable straight
                            transformation among two digital coins without any need for a third-party intermediary or middle-man. By 
                            engaging hash time-locked <strong>smart contracts, atomic swaps</strong> assures that involved parties will provide the 
                            currency desired for the trade, or else the transaction is cancelled automatically. These trades consume 
                            atomicity as they either happen or are terminated instantly.</p>
                        <p>Utilizing the standard of atomic swaps, direct Atomic Stock Exchanges empower retail investors to avoid 
                            mandatory conversion into cash. Atomic exchanges could viably work with large-cap stocks that have
                            profound liquidity, and the liquidity required to make the trades would be delivered by high-frequency 
                            traders (HFT) who could form the gap that exists.</p>
                        <p>With atomic swaps, two users can exchange crypto coins from different blockchain with high security. Every 
                            user will agree with different terms before initiating a transaction. Then both make use of private keys 
                            to sign a copy of that particular transaction. The exchange is performed immediately, without any 
                            transaction fees and eliminates the need for a centralized authority to complete the transfer. Atomic Swap 
                            Power Exchange Work</p>
                </Col>
                <Col sm="6">
                    <img className="img-fluid" src={AtomicSwapPower} alt="How Atomic Swap Power Exchange Work"
                     title="How Atomic Swap Power Exchange Work" />
                </Col>
            </Row>          
        </div>
      );
    }
}

export default work_decrntralized_section2;