import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import UK from '../../../Images/faq/UK.jpg';

export default class STuk extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is the state of security token in UK</h2> 
                            <img src={UK} alt="security token in UK" title="security token in UK" className="img-fluid faqimg"/>
                            <p>The UK currently does not have crypto regulations in place. The country continues to research the cryptomarket with regulators claiming that it could be years before proper regulatory guidelines can be developed. In March 2018, the countries Crypto assets Taskforce issued a report in which cryptocurrencies were listed in three distinct classes. These classes include security tokens, utility tokens, and exchange tokens.</p>
                            <p>Currently, the UK crypto debate continues. The British Business Federation Authority (BBFA) released a report in which they detailed how bad regulations would hinder growth in the sector much more than “no regulations at all.” As it stands today, the UK has a strong blockchain community with analysts predicting future growth in this sector.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
