import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";

import Certificate from "../Certificate/index";
//import TalkToOurExperts from "../TalkToOurExperts/TalkToOurExpertsForm";
import TalkToOurExperts from "../Contactus/ContactusdetialsSTO";

import Slidermain from "./banner";
import LeverageMarginexchange from "./LeverageMarginexchange";
import LavrageAdvantage from "./LavrageAdvantage";
import WorldclassExperience from "./WorldclassExperience";
import Offertraders from "./Offertraders";
import Faqmain from '../Faq/faq';

export default class index extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-125025994-1')
        ReactGA.pageview(document.location.pathname)
        ReactGA.pageview(document.location.path)
      }
      
      scrollPositions = {}
      componentWillMount() {
        if ("scrollRestoration" in window.history) {
          window.history.scrollRestoration = "manual"
        }
        window.scrollTo(0, 0)
      }
    
  render() {
    return (
      <div>
        {this.props.children}
        <Helmet>
            <title>Cryptocurrency Margin Trading Platforms | White label Leverage Crypto Exchange Software | Leverage and Margin Trading Exchange Software | Espay Exchange</title>
            <meta name="description" content="Espay Exchange offers white label leverage crypto exchange softwares that comes with exclusive features and enhanced security. Our margin trading platforms allows trading with a minimal value of just 1 BTC and helps attain maximize profits."/>
            <meta property="og:title" content="Cryptocurrency Margin Trading Platforms | White label Leverage Crypto Exchange Software | Leverage and Margin Trading Exchange Software | Espay Exchange" />
            <meta property="og:description" content="Espay Exchange offers white label leverage crypto exchange softwares that comes with exclusive features and enhanced security. Our margin trading platforms allows trading with a minimal value of just 1 BTC and helps attain maximize profits." />
            <meta name="twitter:title" content="Cryptocurrency Margin Trading Platforms | White label Leverage Crypto Exchange Software | Leverage and Margin Trading Exchange Software | Espay Exchange" />
            <meta name="twitter:description" content="Espay Exchange offers white label leverage crypto exchange softwares that comes with exclusive features and enhanced security. Our margin trading platforms allows trading with a minimal value of just 1 BTC and helps attain maximize profits." />
            <link rel="canonical" href="https://www.espay.exchange/ieo-consulting-services" />
        </Helmet>

        <div className="Ieobackground-bg">
        <Container fluid={true} className="programming-bg">
          <Container>
            <Slidermain />
          </Container>
        </Container>
        </div>

        <Container>
            <LeverageMarginexchange />
        </Container>

        <Container>
            <LavrageAdvantage />
        </Container>

        <Container fluid={true} className="SmartContact-bg ">
          <Container>
              <WorldclassExperience />
          </Container>
        </Container>

        
        <Container>
            <Certificate />
        </Container>

        <Container>
            <h2 className="faqmaintitle">Frequently Asked Questions</h2>
            <Faqmain />
          </Container>
        
        <TalkToOurExperts />


        <ScrollUpButton className="ScrollUpButton"/>
      </div>
    )
  }
}
