import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import cmsimg from '../../../../Images/WhiteLabelExchangeDesh.jpg';

class mobile_Trading_Platform_section1 extends Component {
    render() {
      return (
        <div> 
            <Row> 
                <Col sm="6">
                    <div className="mobiletradingtitle">                       
                        <p>Espay as a leading exchange development company offers user-friendly apps for your users to trade digital coins with mobile view. These apps provide real-time monitoring and trading along with advanced security options.</p>
                        <p>Let your traders monitor the performance of individual crypto coins. It also offers in-app price alerts that your users can decide to enable. Your traders can also trade directly from the chart and also enable the push notification on their mobile devices.</p>                         
                    </div>
                </Col>
                <Col sm="6" className="mt-30">
                    <img className="img-fluid" src={cmsimg} alt="Web and Mobile Trading Experience" 
                    title="Web and Mobile Trading Experience" />
                </Col>                  
            </Row>          
        </div>
      );
    }
}

export default mobile_Trading_Platform_section1;