import React, { Component } from 'react';
import {Row,Col} from 'reactstrap';

import flagchina from '../../Images/MarketingResearch/flag-china.jpg';
import flagbanglasesh from '../../Images/MarketingResearch/flag-banglasesh.jpg';
import flagsouthkoria from '../../Images/MarketingResearch/flag-southkoria.jpg';
import flagsyris from '../../Images/MarketingResearch/flag-syris.jpg';
import flagoman from '../../Images/MarketingResearch/flag-oman.jpg';


export default class SecurityTokenExchangeEcosystem extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm={12}>
                        <div className="stecosystem-title">
                            <h3>Security Token Exchange Ecosystem</h3>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={4}>
                        <div className="ecosystembox">
                            <div className="country-box">
                                <span>China</span>
                                <img src={flagchina} alt="China" title="China" />
                            </div>
                            <h3>Banned</h3>
                            <p>The People's Bank of China (PBoC) has taken a firm stand to ban everything associated with cryptocurrencies, which is unlikely to change in the foreseeable future.</p>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="ecosystembox">
                            <div className="country-box countrycolor">
                                <span>Bangladesh</span>
                                <img src={flagbanglasesh} alt="China" title="China" />
                            </div>
                            <h3>Banned</h3>
                            <p>Currently, cryptocurrencies are illegal in Bangladesh. As a result, security tokens also remain illegal in the country.</p>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="ecosystembox">
                            <div className="country-box countrycolor1">
                                <span>South Korea</span>
                                <img src={flagsouthkoria} alt="China" title="China" />
                            </div>
                            <h3>Under Heavy Scrutiny</h3>
                            <p>Although not illegal, cryptocurrencies and security tokens remain under heavy scrutiny in South Korea. South Korean authorities continue to view all things associated with blockchain skeptically.</p>
                        </div>
                    </Col>
                </Row>

                <Row className="ecosystemtow-box">
                    <Col sm={4} className="ecosystemtowsp">
                        <div className="ecosystembox">
                            <h3>Banned</h3>
                            <p>The war-torn country is unlikely to venture into the security token realm anytime soon.</p>
                            <div className="country-box">
                                <span>Syria</span>
                                <img src={flagsyris} alt="China" title="China" />
                            </div>
                        </div>
                    </Col>
                    <Col sm={4} className="ecosystemtowsp1">
                        <div className="ecosystembox">
                            <h3>Banned</h3>
                            <p>There seems to be no light at the end of the tunnel as Omanian authorities show no intention to issue clear policies or guidelines to regulate digital currencies or assets.</p>
                            <div className="country-box countrycolor2">
                                <span>Oman</span>
                                <img src={flagoman} alt="Oman" title="Oman" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
