import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';


class Exchange_Section2 extends Component {
    render() {
      return (
        <div>
            <div className="Exchange_UI_Design"> 
            <Row>
                <Col sm="1"></Col>
                <Col sm="10">
                    <h2>What Espay offers</h2>
                </Col>
            </Row>
                <Row> 
                    <Col sm="1"></Col>
                    <Col sm="10">
                        <div className="UI_Design_Service">
                            <p>❏ Landing Page Designing</p>
                            <p>A powerful, responsive and elegant crypto exchange landing page designs from Espay that lets you reach and provide value to a wide range of customers.</p>
                        </div>
                    </Col> 
                </Row>
                <Row> 
                    <Col sm="1"></Col>
                    <Col sm="10">
                        <div className="UI_Design_Service">
                            <p>❏ Front Page Designing</p>
                            <p>Get an appealing front-end design and engage users on the global platform. Espay is here to develop and provide the best trading experience to your customers with amazing designs.</p>
                        </div>
                    </Col> 
                </Row>
                <Row> 
                    <Col sm="1"></Col>
                    <Col sm="10">
                        <div className="UI_Design_Service">
                            <p>❏ Content Mapping on Site</p>
                            <p>Espay delivers content that will aid you to align your digital marketing with customers’ on your website. Be your visitors’ best friend and let them understand how they make trading decisions by providing valuable resources.</p>
                        </div>
                    </Col> 
                </Row>
                <Row> 
                    <Col sm="1"></Col>
                    <Col sm="10">
                        <div className="UI_Design_Service">
                            <p>❏ Responsive Layout Implementation</p>
                            <p>Based on the themes you have selected, skilled exchange UI designers from Espay makes it responsive. It will be easy for your users to buy and sell crypto coins from any of the devices they are utilizing.</p>
                        </div>
                    </Col> 
                </Row>
                <Row> 
                    <Col sm="1"></Col>
                    <Col sm="10">
                        <div className="UI_Design_Service">
                            <p>❏ CRM API Integration</p>
                            <p>Espay integrates CRM API that automatically updates the details of any visitors’ activity whether they are filling the forms or perform trading on the platforms and much more.</p>
                        </div>
                    </Col> 
                </Row>
                <Row> 
                    <Col sm="1"></Col>
                    <Col sm="10">
                        <div className="UI_Design_Service">
                            <p>❏ Push Notification API Integration</p>
                            <p>Espay offers a robust API integration that is devoted to sending push notifications. You can get real-time visualization and in-app notification with the cutting-edge trading platform from Espay.</p>
                        </div>
                    </Col> 
                </Row>
                <Row> 
                    <Col sm="1"></Col>
                    <Col sm="10">
                        <div className="UI_Design_Service">
                            <p>❏ Live Chat API Integration</p>
                            <p>Easily integrate live chat API with the tools you are using already. Espay is delivering crypto exchanges that improve user engagement and aid to widen the community by providing a chat interface.</p>
                        </div>
                    </Col> 
                </Row>
                <Row> 
                    <Col sm="1"></Col>
                    <Col sm="10">
                        <div className="UI_Design_Service">
                            <p>❏ Analytics and Tracking Template Integration</p>
                            <p>Espay aid to integrate analytics and tracking template with your exchange that brings you to check out critical metrics such as a list of visitors, rates of conversion and much more.</p>
                        </div>
                    </Col> 
                </Row>
            </div>          
        </div>
      );
    }
}

export default Exchange_Section2;