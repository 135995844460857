import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

import AttractiveBusiness from "../../Images/Mobile_Trading_Terminal/Attractive_Tools.png";

export default class LeverageMarginexchange extends Component {
  render() {
    return (
      <div>
        <Row className="AttractiveBusiness-section">
          <Col sm="6">
            <div className="AttractiveBusiness-content">
              <h2>The most attractive business tools</h2>
              <p>Mobile trading terminals grow beyond the edges as the most innovative and engaging business tools. Espay Exchange provides complete mobile technology, allowing on-the-go trading with real-time market data streaming, portfolio monitoring, risk management, and news.</p>
            </div>
          </Col>
          <Col sm="5" className="offset-sm-1">
            <img src={AttractiveBusiness} alt="AttractiveBusiness" title="AttractiveBusiness" className="img-fluid" />
          </Col>
        </Row>
      </div>
    )
  }
}
