import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

export default class WhatCentralisedexchange extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is a centralised exchange?</h2>
                            <p>Within the expression "centralized exchange of crypto-currency," the concept of centralization refers to the use of a middle man or a third party to assist within transactions. Both buyers and sellers trust the middle man to handle their properties. This is that in a bank system, where a client trusts the bank to keep its money.</p>
                            <p>The explanation for this setup is that banks provide protection and monitoring that an person can't do on their own. The same idea applies in the case of a centralized crypto-currency exchange. Transactors not only believe that the exchange can complete their transactions for them in a secure manner, but also use the network of users in the exchange to find trading partners.</p>
                            <p>In the case of cryptocurrencies, mostly held in digital wallets, a person may lose hundreds or thousands of dollars in digital currency holdings simply by forgetting the key to a wallet. A centralized cryptocurrency exchange solution does not allow that to happen, because it protects the funds instead of the individual investor.</p>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

