import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import EnterpriseExchange from '../../Images/enterprise/EnterpriseExchange.png';

class offering_standard_section4 extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm="12">
                    <div className="enterprisetitle">
                        <h2>Our Enterprise Exchange Software Offering</h2>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="7">
                    <img alt="Enterprise Exchange Solution" title="Enterprise Exchange Solution" src={EnterpriseExchange} className="enterprice-feature-img img-fluid" />
                </Col>
                <Col sm="5">
                <div className="enterpriseexchangesolution">
                    <h3>Enterprise Exchange Solution</h3>
                    <hr/>
                    <ul>
                        <li>Hybrid Version</li>
                        <li>Android & IOS Apps</li>
                        <li>Hot & Cold Wallet</li>
                        <li>Admin Control</li>
                        <li>AI Based Tools</li>
                        <li>Source Code Access</li>                                   
                    </ul>
                    <Link to="/contact-us" title="Exchange Software Development Company">Ask For Quote</Link>
                </div>
                </Col>     
            </Row> 
        </div>
      );
    }
}

export default offering_standard_section4;