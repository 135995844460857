import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import STExchange from '../../Images/MarketingResearch/token-exchanges-img.png';

export default class SecurityTokenExchanges extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm={12}>
                        <div className="estetitle">
                            <h3>Enter Security Token Exchanges</h3>
                            <p>Many crypto enthusiasts view security token exchange platforms as the best solution to overcome the liquidity problem. Let's dig a little deeper into how a security token exchange works.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <div className="estetitle">
                            <h4>How It Works</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={7}>
                        <div className="estediscription">
                            <p>A security token exchange is analogous to a traditional stock exchange. Instead of shares and stocks, you can buy, sell, and trade security tokens.</p>
                            <p>Each STE will have a list of pre-requisites to start trading on their platform. Depending on the laws of the country where the STE is located, you will need to fulfill KYC and AML requirements to become a full-fledged trader. In the US, you will need to comply with one of the three regulations D, S or A+.</p>
                            <p>The total number of regulated STEs around the world remains unknown as the market is still young. However, several stock exchanges around the globe such as Gibraltar Stock Exchange, Malta Stock Exchange, and the Swiss Exchange are trying their best to get a license to list and trade security tokens.</p>
                        </div>
                    </Col>
                    <Col sm={5}>
                        <img className="img-fluid" src={STExchange} alt="Security Token Exchange" title="Security Token Exchange"/>
                    </Col>
                </Row>
            </div>
        )
    }
}
