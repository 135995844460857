import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.scss';
// import registerServiceWorker from './registerServiceWorker';
import Index from './router/index';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';


ReactDOM.render(<Index />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// import React from 'react';
// import { BrowserRouter } from 'react-router-dom';
// import ReactDOM from 'react-dom';
// import './index.css';
// import './App.scss';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import registerServiceWorker from './registerServiceWorker';
// import Index from './router/index';

// ReactDOM.render(<BrowserRouter>
//                     <Index />
//                 </BrowserRouter>
// , document.getElementById('root'));
// registerServiceWorker();


