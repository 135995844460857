import React from 'react';
import { Container,  Col, Row } from 'reactstrap';
import scrollToComponent from 'react-scroll-to-component';
import ScrollUpButton from "react-scroll-up-button";

class P2P_Faq extends React.Component {

  constructor(props) {
    super(props);
    this.scrollToTopWithCallback = this.scrollToTopWithCallback.bind(this)
  }

  componentDidMount() {
    scrollToComponent(this.innerfaqcontent, { offset: -60, align: 'top', duration: 500, ease:'inExpo'});
  }

  scrollToTopWithCallback() {
    let scroller = scrollToComponent(this.Violet, { offset: -75, align: 'top', duration: 1500});
    scroller.on('end', () => console.log('Scrolling end!') );
  }

  render() {
    return (
      <div className='main'>
       <h2 className="topinnertitle">P2P Exchange</h2>
            <div className="button_groupmain">
            <button className="button-faq" onClick={this.scrollToTopWithCallback}>
                1) What is P2P Exchange ?</button>
            <button className="button-faq" onClick={() => scrollToComponent(this.Indigo, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
                2) Benefits of P2P Exchange
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.Blue, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
                3) Crypto Exchange: Market Analysis
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.Orange, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
                4) P2P : Technology & Earning Potential
            </button>
            </div>
        <Container>
            <section className='innerfaq1content' ref={(section) => { this.Violet = section; }}>
                <Row>
                    <Col sm={{size:11,offset:1}} className="faqdiffbor">
                        <h4>1) What is P2P Exchange ? </h4>
                            <p>Centralized exchanges provide access to the cryptocurrency markets in a secure and trust-based platform. But there is a large community who prefer to do trading in trust-less environment and with different payment method as well. A peer-to-peer (P2P) service is a decentralized platform whereby two individuals interact directly with each other, without inter-mediation by a third-party. Instead, the buyer and the seller transact directly with each other. Where regular crypto exchanges offer to trade cryptos against cryptos or against a limited set of fiat currencies, these P2P Exchanges offer wide range of trading and payment options for buying and selling Cryptos. Added to this P2P Exchanges require less resources compared to the regular exchange platforms. Hence, they are able to attract a large number of traders and huge amount of trading volume by charging them very less amount of fees on larger trades. Well, lower transaction charge is not the only advantage P2P exchanges offer to the traders. P2P Exchange is basically like an e-commerce type trading of coin/token and which has security over wallets which handles transaction on trading exchange. Buyer or seller can post an ad with the amount of cryptocurrencies they want to buy or sell along with expected price, currency they want to trade against, method of payment and many other options platforms allow them to choose from. Peer to Peer exchange allows buyers and sellers to negotiate on price before a coin/token is transferred. The transaction here are faster than the regular crypto exchanges and security is also a prime feature of P2P Exchanges. </p>    
                    </Col>
                </Row>
            </section>
        </Container>
        <Container>
            <section className='innerfaq2content' ref={(section) => { this.Indigo = section; }}>
                    <Row>
                    <Col sm={{size:11,offset:1}} className="faqdiffbor">
                            <h4>2) Benefits of P2P Exchange :</h4>
                            <ul>
                                <li>
                                    <h6>Transaction Censorship :</h6>
                                    <p>The decentralized system allows everyone to access the same copy of ledgers. Regular exchanges are run by humans. They are susceptible to getting hacked or manipulated. Authority control can intervene and tamper the ledger. Moreover, they can freeze your funds. P2P system doesn’t have the central authority to control and there are almost no chances of tampering.  Even if the system is hacked, the person must either hack 51% of the nodes to change the ledger of each account or take that 51% of his confidence. Now, that sounds impossible.</p>
                                </li>
                                <li>
                                    <h6>Cheaper Cost of Operation :</h6>
                                    <p>Again, the influence of people in regular exchange would mean extra spending. On the other hand, P2P exchanges are run by the software. The software doesn’t demand the transaction or brokerage fees. The transactional fee is optional in trading cryptocurrencies. It is optional when a user wants its transaction to be validated by the miner urgently.</p>
                                </li>
                                <li>
                                    <h6>Privacy Sustenance :</h6>
                                    <p>The central exchange system asks every minute details about the enroller. The government has been enforcing KYC regulations which mean that they have all the details. In the case of P2P, the government can’t apply brute force to KYC process. So, all the transactions can be conducted anonymously.</p>
                                </li>
                                <li>
                                    <h6>Secure Channel :</h6>
                                    <p>The P2P exchange is secure because it doesn’t store the cryptocurrencies for its users. It just makes the sellers and buyers meet. This minimizes the counterparty risk.</p>
                                </li>
                        </ul>
                        </Col>
                    </Row>
            </section>
        </Container>
        <Container>
            <section className='innerfaq3content' ref={(section) => { this.Blue = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}} className="faqdiffbor">
                    <h4>3) Market prediction for Cryptocurrency Exchange :</h4>
                        <p>The Bitcoin market capitalization increased from approximately 0.04 billion U.S. dollars in the first quarter of 2012 to approximately 114.05 billion U.S. dollars in the third quarter of 2018. In 2016 there were 75 exchanges listed on Coin Market Cap and the total trading volume generated by them was USD 21.4 Million. The number of trading platforms increased from 75 to 225 in year 2018, and the trading volume increased from USD 21.4 Million to USD 5400 Million. Meanwhile the number of cryptocurrencies available for trading increased from 710 in 2016 to 2073 in 2018. Leaving a huge opportunity for trading platforms to cover and earn good in coming years with total trading volume expected reaching more than 4 trillion USD.</p>
                </Col>
            </Row>
            </section>
        </Container>
         <Container>
            <section className='innerfaq4content' ref={(section) => { this.Orange = section; }}>
                <Row>
                <Col sm={{size:11,offset:1}} className="faqdiffbor">
                        <h4>4) Technology of  P2P Exchange and Earning Opportunity :</h4>
                           <p>Peer to Peer exchange is very much lightweight platform in comparison to regular centralized exchange platform. It doesn’t require to have a trade match engine or liquidity or high mechanism to manage a Peer to Peer Crypto Exchange. Peer to Peer platform is actually a marketplace where users comes and put currencies for sell and buyers buy it and does the payment. In between the platform earns for providing them with the platform for trading. So, there is an admin panel to manage ads, moderate them, manage escrow, and trade fees, and withdrawals. Now here, the trading platform charges less percentage of amount for trading large amount of crypto currency, so the trade volume here will be hug and that raises the amount of transaction fees. Apart from the transaction fees, platform charges for withdrawal of the currencies and posting premium ad – or showing ad on top of other ads.</p>
                    </Col>
                </Row>
            </section>
        </Container>

        <ScrollUpButton className="ScrollUpButton"/>

      </div>
    )
  }
}

export default P2P_Faq;