import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import factorasseicon1 from '../../Images/MarketingResearch/factor-asse-icon-1.png';
import factorasseicon2 from '../../Images/MarketingResearch/factor-asse-icon-2.png';
import factorasseicon3 from '../../Images/MarketingResearch/factor-asse-icon-3.png';
import factorasseicon4 from '../../Images/MarketingResearch/factor-asse-icon-4.png';
import factorasseicon5 from '../../Images/MarketingResearch/factor-asse-icon-5.png';

export default class AssetbasedFactors extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm={12}>
                        <div className="assettitle">
                            <h3>Asset-based Factors</h3>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <div className="assfactor-bg">
                            <ul className="asset-factor-box">
                                <li>
                                    <div className="factorboximg">
                                        <img className="img-fluid" src={factorasseicon1} alt="" title=""/>
                                        <div className="factorboxdetails">
                                            <span>Price of the Underlying Asset</span>  
                                            <p>The underlying asset's value and token's values are proportional, one increases with the other.</p>  
                                        </div>  
                                    </div>  
                                </li>    
                                <li>
                                    <div className="factorboximg">
                                        <img className="img-fluid" src={factorasseicon2} alt="" title=""/>
                                     
                                    <div className="factorboxdetails">
                                        <span>Cash Flows or Dividends</span>  
                                        <p>Sometimes, the underlying asset offers dividends or cash income. In this case, the fluctuations in this cash income or dividend will affect the token’s value.</p>  
                                    </div> 
                                    </div>     
                                </li>    
                                <li>
                                    <div className="factorboximg">
                                        <img className="img-fluid" src={factorasseicon3} alt="" title=""/>
                                       
                                    <div className="factorboxdetails">
                                        <span>Collateral</span>  
                                        <p>If the underlying asset is using collateral, the value of the collateral will affect its value, which in turn, will affect the token's value.</p>  
                                    </div> 
                                    </div>     
                                </li>    
                                <li>
                                    <div className="factorboximg">
                                            <img className="img-fluid" src={factorasseicon4} alt="" title=""/>
                                            
                                        <div className="factorboxdetails">
                                            <span>Risk</span>  
                                            <p>The risks associated with the underlying asset will affect the token's value.</p>  
                                        </div> 
                                    </div>        
                                </li>    
                                <li>
                                    <div className="factorboximg">
                                        <img className="img-fluid" src={factorasseicon5} alt="" title=""/>
                                        
                                    <div className="factorboxdetails">
                                        <span>Liquidity</span>  
                                        <p>Similarly, an asset's liquidity will also affect the token's value. For example, more liquid assets such as stocks will gain value quickly, while tokens based on less liquid assets, such as real estate, will appreciate slowly.</p>  
                                    </div> 
                                    </div>    
                                </li>    
                            </ul>
                        </div>
                    </Col>
                </Row>    
                
            </div>
        )
    }
}
