import React, { Component, Fragment } from 'react';
import { Container,Row,Col} from 'reactstrap';

export default class DerivativesTradingsystems extends Component {
    render() {
        return (
          <Fragment>
            <Container className="derivativesmain">
              <Row>
                <Col md={12}>
                  <div className="derivativetitle">
                    <h2>Key Features of an exchange</h2>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="derivativedetails">
                    <span>Match Engine</span>
                    <p>Espay  software solutions for exchanges utilising our price-time priority matching algorithm that has microsecond latency and can handle a throughput of up to 1000,000 orders per second. We support both direct and implied matching.</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="derivativedetails">
                    <span>Market Data Delivery</span>
                    <p>Espay technology solution provides data service delivering real-time and delayed market data, running on a proven and robust cloud infrastructure that is backed by Microsoft Azure. Deploy in minutes. Gain insights into your resources. Pull data from other IT ops tools.</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="derivativedetails">
                    <span>Easy and Secure</span>
                    <p>Easy and secure payment technology by ESPAY provides unpatch security system along with taking care of easy ness in multilateral analytical approach for complex pattern recognition.</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="derivativedetails">
                    <span>Index Management and Data visualisation</span>
                    <p>Espay software solutions integrated platform that provides lifecycle management for indices. Our solution supports index design, backtesting, basket analysis, re-weighting and re-balancing. trading application providing customers with historical, delayed or live analytical information visualisation</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </Fragment>
        );
    }
}
