import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import SecRegulations from '../../Images/SecRegulations.png';
import TokenExchange1 from '../../Images/TokenExchange1.png';
import ListingTokens from '../../Images/ListingTokens.png';
import AInvestors from '../../Images/AInvestors.png';
import TradingUtilityTokens  from '../../Images/TradingUtilityTokens.png';
import Transparency  from '../../Images/Transparency.png';

class benefits_service_section4 extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm="12">     
                    <div className="SecurityTokentitle">                   
                        <h2>Security Token Exchange Benefits</h2>
                    </div>
                </Col>
            </Row> 
            <Row>
                <Col sm="12">
                    <Row>
                        <Col sm="1">
                            <img className="img-fluid" src={SecRegulations} alt="Complaint With Sec Regulations" title="Complaint With Sec Regulations" />
                        </Col>
                        <Col sm="3">
                            <div className="SecurityBenefitsbox">
                                <h3>Complaint With Sec Regulations</h3>
                                <p>Possessing such a license enables security token issuers (security token ICOs) to act under relevant 
                                    regulations. We offer a federally-regulated platform for <strong>trading security tokens.</strong></p>
                            </div>    
                        </Col>
                        <Col sm="1">
                            <img className="img-fluid" src={TokenExchange1} alt="Asset-Backed Token Exchange" title="Asset-Backed Token Exchange"  />
                        </Col>
                        <Col sm="3">
                            <div className="SecurityBenefitsbox">
                                <h3>Asset-Backed Token Exchange</h3>
                                <p>Espay is developing a robust asset-backed token exchange with a real-life value that offers actively
                                    managed crypto funds and real-world assets.</p>
                            </div>
                        </Col>
                        <Col sm="1">
                            <img className="img-fluid" src={ListingTokens} alt="Listing Of Tokens" title="Listing Of Tokens"  />
                        </Col>
                        <Col sm="3">
                            <div className="SecurityBenefitsbox">
                                <h3>Listing Of Tokens</h3>
                                <p>Espay offers a very easy procedure for token listing, compliant with different standards such as DS 
                                    Protocol, R-Token, ST-20, S3.</p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
            <Col sm="12">
                    <Row>
                        <Col sm="1">
                            <img className="img-fluid" src={AInvestors} alt="Accredited Investors" title="Accredited Investors"  />
                        </Col>
                        <Col sm="3">
                            <div className="SecurityBenefitsbox">
                                <h3>Accredited Investors</h3>
                                <p>Espay’s security token exchange enables only accredited Investors as they have a superior 
                                    prominence under financial regulation laws.</p>
                            </div>
                        </Col>
                        <Col sm="1">
                            <img className="img-fluid" src={TradingUtilityTokens} alt="Legally Compliant Tokens" title="Legally Compliant Tokens"  />
                        </Col>
                        <Col sm="3">
                            <div className="SecurityBenefitsbox">
                                <h3>Legally Compliant Tokens</h3>
                                <p>The security token has the regulations of the registered jurisdiction coded. These include legal frameworks of fundraising, investor qualification rules & transaction limits on transfer.</p>
                            </div>
                        </Col>
                        <Col sm="1">
                            <img className="img-fluid" src={Transparency} alt="Portfolio Management" title="Portfolio Management"  />
                        </Col>
                        <Col sm="3">
                            <div className="SecurityBenefitsbox">
                                <h3>Portfolio Management</h3>
                                <p>Management of funds and through dedicated workflow tools which includes fund raised with accredited investor, non-accredited investors and other investors.</p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
      );
    }
}

export default benefits_service_section4;