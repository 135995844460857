import React, { Component,Fragment } from 'react';
import { Container, Row, Col} from 'reactstrap';
//import { Link } from "react-router-dom";
import Optionsimg from '../../Images/Derivatives/OptionsTrading.png';
import Swapsimg from '../../Images/Derivatives/SwapsTrading.png';
import Futuresimg from '../../Images/Derivatives/FuturesTrading.png';


export default class FocussedExpertise extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col md={12}>  
                            <div className="cryderivativestitle">
                                <h2>Focussed Expertise On Cryptocurrency Derivatives Exchange Development</h2>
                                <p>The team at Espay-X logically applies the derivative function of contract, taking into consideration the benchmarked indices for the underlying asset. Our cryptocurrency derivatives trading system hosts all major derivative functions, namely swaps, futures, and options.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className="focussedbox">
                            <div>
                                <img className="img-fluid" src={Swapsimg} alt="Crypto derivatives exchange development" title="Derivatives Swaps" />
                                <h3>Swaps</h3>
                                <p>We are the experts in crypto swap derivative contract development, which involves the exchange of a future stream of fixed interest rate payments for a stream of floating rate payments.</p>
                                {/* <Link to="">Explore Swaps</Link> */}
                            </div>
                        </Col>
                        <Col md={4} className="focussedbox">
                            <div>
                                <img className="img-fluid" src={Futuresimg} alt="Crypto derivavtives exchange software" title="Derivatives Futures" />
                                <h3>Futures</h3>
                                <p>We have included future contract In crypto derivatives where a buyer has an obligation for a buyer to purchase a crypto or a seller to sell a crypto at a fixed price and a predetermined future price.</p>
                                {/* <Link to="">Explore Futures</Link> */}
                            </div>
                        </Col> 
                        <Col md={4} className="focussedbox">
                            <div>
                                <img className="img-fluid" src={Optionsimg} alt="Crypto derivavtives exchange platform" title="Derivatives Options" />
                                <h3>Options</h3>
                                <p>We develop an options contract In crypto derivatives where a buyer has the right (not an obligation) to purchase a crypto or a seller to sell a crypto at a predetermined price by a specific timeline.</p>
                                {/* <Link to="">Explore Options</Link> */}
                            </div>
                        </Col>   
                    </Row>
                </Container>  
            </Fragment>
        )
    }
}
