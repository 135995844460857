import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";

class Security_Modules_Section1 extends Component {
    render() {
      return (
        <div>
            <div className="Securty_Modules_And_Features"> 
                <Row> 
                    <Col sm="1"></Col>
                    <Col sm="10">
                        <div className="Security_Modules_Service">
                            <p>❏ Device White listing</p>
                            <p>Espay crypto exchange software endows permissions for company-approved devices while efficiently and easily blocking all others. It provides the best security to your crypto trading platform.</p>
                        </div>
                    </Col> 
                </Row>
                <Row> 
                    <Col sm="1"></Col>
                    <Col sm="10">
                        <div className="Security_Modules_Service">
                            <p>❏ IP Profiling</p>
                            <p>Ensure your crypto exchange does not surplus your broadband service by transferring more data that can really manage. Espay aid you with this and let your trading platform running smoothly.</p>
                        </div>
                    </Col> 
                </Row>
                <Row> 
                    <Col sm="1"></Col>
                    <Col sm="10">
                        <div className="Security_Modules_Service">
                            <p>❏ Activity Analyzer</p>
                            <p>Observe your trader’s every day’s login/time/location pattern with Espay’s<Link to="/crypto-exchange-software"
                            title="Espay Exchange"> <strong> exchange software solutions.</strong> </Link> It will also report if login comes from any uncertain pattern.</p>
                        </div>
                    </Col> 
                </Row>
                <Row> 
                    <Col sm="1"></Col>
                    <Col sm="10">
                        <div className="Security_Modules_Service">
                            <p>❏ Login Confirmation Alerts</p>
                            <p>Notify your traders that their login has been confirmed on the crypto trading platform by providing login confirmation alerts with Espay’s crypto exchange solutions.</p>
                        </div>
                    </Col> 
                </Row>
                <Row> 
                    <Col sm="1"></Col>
                    <Col sm="10">
                        <div className="Security_Modules_Service">
                            <p>❏ Request Analyzer</p>
                            <p>Leverage AI based tracking with Espay’s crypto exchanges that work on the server level and it will report you if some doubtful request comes on your crypto trading platform.</p>
                        </div>
                    </Col> 
                </Row>
                <Row> 
                    <Col sm="1"></Col>
                    <Col sm="10">
                        <div className="Security_Modules_Service">
                            <p>❏ Request Level Encryption</p>
                            <p>Espay’s digital asset exchange platform lets you save your user’s request in an encrypted format. So no one can read your trader’s detail on transparent crypto exchange platform.</p>
                        </div>
                    </Col> 
                </Row>
                <Row> 
                    <Col sm="1"></Col>
                    <Col sm="10">
                        <div className="Security_Modules_Service">
                            <p>❏ Modular Architecture</p>
                            <p>Espay’s crypto trading software comprises a modular architecture through which you can add/replace any of the modules of your trading platform without upsetting the rest of the ongoing system.</p>
                        </div>
                    </Col> 
                </Row>
            </div>          
        </div>
      );
    }
}

export default Security_Modules_Section1;