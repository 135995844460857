import React, { Component } from 'react';
import { Row, Col} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import homesection2 from './HomeData';

import { library } from '@fortawesome/fontawesome-svg-core';
import {faCreditCard, faServer,faExchangeAlt} from '@fortawesome/free-solid-svg-icons';
library.add(faCreditCard,faExchangeAlt,faServer);



class Section2 extends Component {
    render() {
        const ourexchangesoftware_h2    = homesection2[2].ourexchangesoftwaredata.fields.ourexchangesoftware_h2;
        const ourexchangesoftware_dis   = homesection2[2].ourexchangesoftwaredata.fields.ourexchangesoftware_dis;
        const ourexchange_title1        = homesection2[2].ourexchangesoftwaredata.fields.ourexchange_title1;
        const ourexchange_dis1          = homesection2[2].ourexchangesoftwaredata.fields.ourexchange_dis1;
        const ourexchange_title2        = homesection2[2].ourexchangesoftwaredata.fields.ourexchange_title2;
        const ourexchange_dis2          = homesection2[2].ourexchangesoftwaredata.fields.ourexchange_dis2;
        const ourexchange_title3        = homesection2[2].ourexchangesoftwaredata.fields.ourexchange_title3;
        const ourexchange_dis3          = homesection2[2].ourexchangesoftwaredata.fields.ourexchange_dis3;
      return (
        <div>
            <Row>
                <Col sm="12">
                    <div className="Compriseof">
                        <h2>{ourexchangesoftware_h2}</h2>
                        <p>{ourexchangesoftware_dis}</p>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="4">                         
                    <div className="Comprisebox">
                        <FontAwesomeIcon icon="credit-card" size="3x" />
                        <h3>{ourexchange_title1}</h3>
                        <p>{ourexchange_dis1}</p>
                        <Link title="Customized Exchange Software Solution" to="/crypto-exchange-software">Read More</Link>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="Comprisebox">
                        <FontAwesomeIcon icon="server" size="3x"/>
                        <h3>{ourexchange_title2}</h3>
                        <p>{ourexchange_dis2}</p>
                        <Link title="Customized Exchange Software Solution" to="/security-token-exchange">Read More</Link>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="Comprisebox">
                        <FontAwesomeIcon icon="exchange-alt" size="3x"/>
                        <h3>{ourexchange_title3}</h3>
                        <p>{ourexchange_dis3}</p>
                        <Link title="Customized Exchange Software Solution" to="/talk-to-crypto-exchange-expert">Read More</Link>
                    </div>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default Section2;