import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';

import Faqleftpanel from "../Faqleftpanel";

export default class Securitytokenofferingwork extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">How does security token offering work?</h2>
                            <p>Security tokens offer some unique benefits— especially in improving secondary liquidity on the market, reducing enforcement costs, automating trade controls, providing fractional ownership, and enabling interoperability of properties.</p>
                            <p>STOs also opened up opportunities for businesses to raise funds by issuing regulatory-compliant digital security tokens to investors. Both the investor and the issuer have the benefits, while still having far greater protection against fraud compared to an ICO. Issuers may come from a number of sectors, including commercial real estate, venture capital firms and SMEs.</p>
                            <p>When an SME (i.e., Company A) wants to issue equity security tokens in their business, they can do so with the aid of multiple market participants including</p>
                            <ul>
                                <li>Issuance Platforms</li>
                                <li>Exchanges</li>
                                <li>Custodians</li>
                                <li>Broker-Dealers</li>
                                <li>Legal/Compliance</li>
                            </ul>
                            <p>Company A can formally issue its security token to investors via a platform for issuance. Well-known issuance networks include Polymath and Harbor, which are integrated with service providers such as custodians, broker dealers, and legal / compliance bodies to promote a process that is safe and consistent with regulation. Security Token Marketing Solutions enable businesses to make their token viral.</p>
                            <p>Security token exchange platform development companies for issuance platforms often work on structured token interfaces (i.e.,ST-20 for Polymath and R-Token for Harbor) that render hard-coded regulatory criteria into token contracts such as explicit trade restrictions. Standardized token interfaces for security tokens also allow for asset interoperability, which has positive downstream effects in secondary market liquidity and reduced friction in token trading.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

