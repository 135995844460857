import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import wallet from '../../../../Images/wallet-1.png';
import { Link } from "react-router-dom";

class wallet_management_section1 extends Component {
    render() {
      return (
        <div> 
            <Row> 
                <Col sm="6">
                    <div className="walletmanagementtitle">    
                        <p>Espay is a leading B2B solution provider when it comes to exchange development solutions globally. It contains a team of professionals having advanced skills and deep experience in a broad spectrum of Blockchain Wallet development.</p>
                        <p>Enable your users to manage their crypto coins and digital assets within their crypto wallet. Espay is offering <Link to="/crypto-exchange-software" title="Espay Exchange"> <strong>digital asset exchange solutions </strong></Link>  that secure and manage crypto wallet. Developers of Espay Strictly adheres to all the security standards for the development of secure cryptocurrency wallets.</p>     
                    </div>
                </Col>
                <Col sm="6" className="mt-30">
                    <img className="img-fluid" src={wallet} alt="WALLET MANAGEMENT" title="WALLET MANAGEMENT" />
                </Col>  
            </Row>          
        </div>
      );
    }
}

export default wallet_management_section1;