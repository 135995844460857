import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Link } from "react-router-dom";

import fe_manhosting from '../../Images/fe_manhosting.png';
import fe_management from '../../Images/fe_management.png';
import fe_trading from '../../Images/fe_trading.png';
import fe_designing from '../../Images/fe_designing.png';
import fe_order from '../../Images/fe_order.png';
import fe_trreport from '../../Images/fe_trreport.png';
import fe_security from '../../Images/fe_security.png';
import fe_mobileapp from '../../Images/fe_mobileapp.png';
import fe_hosting from '../../Images/fe_hosting.png';


export default class CryptoSection8 extends Component {
  render() {
    return (
      <div>
        <Row>
                <Col sm="12">
                    <div className="UniqueFeaturesInfo">
                        <h2>What Are The Unique Features Of Espay Exchange Software?</h2>
                        <p>Being one of the most prominent<strong> exchange development company,</strong> Espay
                            uses this collection of features to attain best possible outcomes.</p>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="4">                         
                    <div className="UniqueFeaturesBox">
                        <img src={fe_manhosting} alt="Self-Managed Hosting Solutions" title="Self-Managed Hosting Solutions" />
                        <span>Self-Managed Hosting Solutions</span>
                        <p>Software as a service provider, Espay is offering self-managed and hosted
                            infrastructure that empower you to avoid procurement costs.
                        </p>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="UniqueFeaturesBox">
                        <img src={fe_management} alt="Currency Pair Management" title="Currency Pair Management" />
                        <span>Currency Pair Management</span>
                        <p>When your customers trading currency pairs, trading platforms offered by Espay let you manage
                            preference for different Pairs as well as Markets.
                        </p>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="UniqueFeaturesBox">
                        <img src={fe_trading} alt="Trading Market Setup" title="Trading Market Setup" />
                        <span>Trading Market Setup</span>
                        <p>Espay aids to create peer-to-peer customer market through which it is possible to stop and limit order
                            as well as to spot trading and much more.
                        </p>
                    </div>
                </Col>
            </Row>         
            <Row>
                <Col sm="4">                         
                    <div className="UniqueFeaturesBox">
                        <img src={fe_designing} alt="Exchange UI Designing" title="Exchange UI Designing" />
                        <span>Exchange UI Designing</span>
                        <p>Espay creates a user interface on the basis of customer preferences as well as template selection
                                whether it’s landing page designing or front pages designing.
                        </p>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="UniqueFeaturesBox">
                        <img src={fe_order} alt="Full liquidity order book" title="Full liquidity order book" />
                        <span>Full liquidity order book</span>
                        <p>Our offering comprises more than one liquidity options to assure full order book from the launching
                            of your trading platform.
                        </p>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="UniqueFeaturesBox">
                        <img src={fe_trreport} alt="Trading Report Management" title="Trading Report Management" />
                        <span>Trading Report Management</span>
                        <p>Our reporting software enables traders and managers to easily track each single activity in a 
                            trading account.
                        </p>
                    </div>
                </Col>
            </Row> 
            <Row>
                <Col sm="4">                         
                    <div className="UniqueFeaturesBox">
                        <img src={fe_security} alt="Security (KYC and AML and 2FA)" title="Security (KYC and AML and 2FA)" />
                        <span>Security (KYC and AML and 2FA)</span>
                        <p>exchange software development from Espay comprises user account and a security module which ensure
                                safety with two-factor authentication.
                        </p>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="UniqueFeaturesBox">
                        <img src={fe_mobileapp} alt="Mobile App" title="Mobile App" />
                        <span>Mobile App</span>
                        <p>Native Espay’s applications for trading on iOS as well as Android devices. It contains rich charting
                                features, cutting-edge order types and a wealth of significant account detail.
                        </p>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="UniqueFeaturesBox">
                        <img src={fe_hosting} alt="Managed Hosting Solutions" title="Managed Hosting Solutions" />
                        <span>Managed Hosting Solutions</span>
                        <p>Espay is providing complete exchange solutions with option of dedicated server hosting so no need to
                                spend money on own hardware.
                        </p>
                    </div>
                </Col>
            </Row> 
           
      </div>
    )
  }
}
