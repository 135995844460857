import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import Slovenia from '../../../Images/faq/Slovenia.jpg';

export default class STSlovenia extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is the scenario of security token scenario in Slovenia?</h2> 
                            <img src={Slovenia} alt="Slovenia" title="Slovenia" className="img-fluid faqimg"/>
                            <p>On January 18, 2018, the Bank of Slovenia warned citizens that virtual currencies are not a digital replacement for banknotes and coins, and are not regulated. The Bank explained that entities purchasing, depositing, or trading virtual currencies in Slovenia are not systematically regulated and supervised. It advised citizens to inform themselves about virtual currencies before buying them and to be aware that they could lose their investments in those currencies.[314] Following the Bank’s warning commercial banks reportedly stopped selling cryptocurrencies via ATMs.</p>
                            <p>Earlier on October 9, 2017, the Financial Stability Board recommended that investors in virtual currencies consider whether the risks are in line with their personal preferences and investment goals,[316] and that investors in ICOs should invest in amounts that would not leave them too exposed</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
