import React, { Component } from 'react';
import { Row, Col, Jumbotron} from 'reactstrap';
import { Link } from "react-router-dom";

class Banner extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm={{size:8,offset:2}}>                       
                    <Jumbotron>
                        <div className="bannerdetails">
                            <h1>Enterprise Crypto Exchange Development Company</h1>
                            <p>Espay is delivering the most robust and scalable enterprise cryptocurrency  
                               exchange software solutions to support the customers in technological aspects of 
                                trading software challenges globally.</p>
                            <Link to="/talk-to-crypto-exchange-expert">Talk To Our Experts</Link>
                        </div>
                    </Jumbotron>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default Banner;