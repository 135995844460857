import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import SecureChannel from '../../Images/P2PSecureChannel.png';
import HighLevelSecurity from '../../Images/P2PHighLevelSecurity.png';
import LargeVolumeTrading from '../../Images/P2PLargeVolumeTrading.png';
import CustomizableUI from '../../Images/P2PCustomizableUI.png';

import TradingFees from '../../Images/P2PTradingFees.png';
import P2PTrading from '../../Images/P2PTrading.png';
import Reliability from '../../Images/P2PReliability.png';
import Scalability from '../../Images/P2PScalability.png';

export default class P2PExchange4 extends Component {
  render() {
    return (
      <div>
            <Row>
                <Col sm="12">
                    <div className="P2Ptitle">
                        <h2>What You Get With Espay’s P2P Marketplace Model</h2>
                        <p>Peer-to-peer marketplace support quick transfers and offer considerable savings over banks.
                            P2P marketplace development is on the rise at a fast pace by delivering 
                            a lower-cost alternative to individuals as well as small businesses.</p>
                    </div>   
                </Col>
            </Row>

            <Row>
                <Col sm="12" md="6" lg="3">
                    <div className="ExchangeModelbox">
                        <img src={SecureChannel} alt="Secure Channel" title="Secure Channel" />
                        <h4>Secure Channel</h4>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="3">
                    <div className="ExchangeModelbox">
                        <img src={TradingFees} alt="Higher Revenue from Trading Fees" title="Higher Revenue from Trading Fees" />
                        <h4>Higher Revenue from Trading Fees</h4>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="3">
                    <div className="ExchangeModelbox">
                        <img src={LargeVolumeTrading} alt="Large volume trading" title="Large volume trading" />
                        <h4>Large volume trading</h4>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="3">
                    <div className="ExchangeModelbox">
                        <img src={CustomizableUI} alt="Customizable UI" title="Customizable UI" />
                        <h4>Customizable UI</h4>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="3">
                    <div className="ExchangeModelbox">
                        <img src={HighLevelSecurity} alt="High Level Security" title="High Level Security" />
                        <h4>High Level Security</h4>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="3">
                    <div className="ExchangeModelbox">
                        <img src={P2PTrading} alt="Peer to Peer Trading" title="Peer to Peer Trading" />
                        <h4>Peer to Peer Trading</h4>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="3">
                    <div className="ExchangeModelbox">
                        <img src={Reliability} alt="Reliability" title="Reliability" />
                        <h4>Reliability</h4>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="3">
                    <div className="ExchangeModelbox">
                        <img src={Scalability} alt="Scalability" title="Scalability" />
                        <h4>Scalability</h4>
                    </div>
                </Col>
            </Row>  
      </div>
    )
  }
}
