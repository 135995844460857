import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";


export default class StoSection1 extends Component {
  render() {
    return (
        <div> 
        <Row className="mt-30 mb-30">   
            <Col sm="12">
                <div className="stoadviceabouttitle">
                <p>Security token offerings (STO) have gained in importance recently and have become even more
                    popular than ICOs. The reason for this is the stricter legal regulation of STOs compared to 
                    ICOs and the hedging of assets that make up the value of the token and keep investors' money.
                    Our STO agency in Australia is your reliable partner in creating your security token offering.
                    We support you from the beginning in the STO development with our consulting services in all areas 
                    of the STO Launch. From the legal aspects to consider with a security token offering, to financing 
                    and STO marketing - all from our experienced team of STO experts.
                </p> 

                </div>
                <div className="stoadviceitle mt-30">
                    <h2>What is an STO (Security Token Offering)?</h2>
                    <p>Security Token Offering (STO) has become a valuable alternative to IPOs, venture capital, 
                        and even ICOs. A security token offering mediates to the holders of tokens that are classified as securities and that 
                        confer different rights compared to utility tokens in the case of a classic ICO. An STO typically secures its security 
                        tokens with assets that have a relatively fixed price in the market, 
                        leaving the token stable and free from price fluctuations similar to traditional ICOs.</p>
                    <p>
                    Security Tokens replace the traditional paper certificates with digital tokens on Blockchain. Security tokens enable liquidity, large-scale trading, 
                    and new financial methods that are beneficial to both the issuer and the buyer.
                    </p>    
                </div>
            </Col>
        </Row>
    </div>
    )
  }
}
