import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import Integration from '../../../../Images/Integration.png';

class Trading_Section1 extends Component {
    render() {
      return (
        <div> 
            <Row> 
                <Col sm="6">
                    <div className="Trading">
                        <p>Get the insights of transactions taking place on your crypto trading platform with the help of 
                            exchange development solutions offered by Espay.</p>
                        <p>Reporting module developed by Espay’s expert developers aid you to view orders and activities
                            made by users, number of registered users, accounting activity, transactional activity and 
                            much more. Espay offers highly optimized and <Link to="/"
                            title="Espay Exchange"> <strong> secure digital asset exchange software</strong> </Link> for your
                            business along with the best reporting module. Create and archive reports directly in your
                            trading platform.</p>
                    </div>
                </Col>
                <Col sm="6" className="mt-30">
                    <img className="img-fluid" src={Integration} alt="Integration" title="Integration" />
                </Col>  
            </Row>          
        </div>
      );
    }
}

export default Trading_Section1;