import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import ReferandEarn from '../../../../Images/Refer-and-Earn.png';
import Affiliate  from '../../../../Images/affiliate-marketing.png';
import Leaderboard from '../../../../Images/Leaderboard.png';
import Voting from '../../../../Images/vote-icon.png';
import CoinListing from '../../../../Images/Pairwise-Statements-and-Ledger.png';
import Feedback  from '../../../../Images/feedback.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faUser,faUsers} from '@fortawesome/free-solid-svg-icons';
library.add(faUser,faUsers);

class marketing_features_section2 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12"> 
                    <div className="marketingfeaturestitle">
                        <h2>Espay's Inbuilt Exchange Marketing Features</h2>
                    </div>                      
                </Col>
            </Row> 
            <Row>
                <Col sm="6">
                    <ul>
                        <li><img className="img-fluid" src={ReferandEarn } alt="Refer and Earn" title="Refer and Earn" /></li>
                        <li className="marketing-features-cont">
                            <h4>Refer and Earn</h4>
                            <p>Espay’s crypto exchange software brings an opportunity for your traders to earn more fund by referring fellow traders. </p>                                   
                        </li>
                    </ul>
                </Col>
                <Col sm="6">
                    <ul>                     
                        <li><FontAwesomeIcon icon="users" size="2x" className="dynamic-cms-icn"/></li>                       
                        <li className="marketing-features-cont">
                            <h4>Tell A Friend</h4>
                            <p>This is a service trusted by many globally. Espay’s crypto trading platform lets customers tell their friends and bring them in to trade digital coins on your platform. </p>                               
                        </li>
                    </ul> 
                </Col>            
            </Row> 
            <Row>   
                <Col sm="6">
                    <ul>
                        <li><img className="img-fluid" src={Affiliate } alt="Affiliate Setup" title="Affiliate Setup" /></li>
                        <li className="marketing-features-cont">
                            <h4>Affiliate Setup</h4>
                            <p>Refer a traffic to your crypto trading platform and let the referrer earn commission by implementing affiliate setup provided by Espay’s exchange solutions. </p>                                  
                        </li>
                    </ul>
                </Col>
                  <Col sm="6">
                    <ul>
                        <li><FontAwesomeIcon icon="user" size="2x" className="dynamic-cms-icn"/></li>
                        <li className="marketing-features-cont">
                            <h4>Invite Friend</h4>
                            <p>Espay offers professional cryptocurrency exchange software through which users can send invitation link/code to friends and invite them to register to trade digital coins on your platform. </p>                                  
                        </li>
                    </ul>
                </Col>              
            </Row>
            <Row>             
                <Col sm="6">
                    <ul>
                        <li><img className="img-fluid" src={Leaderboard } alt="Leaderboard " title="Leaderboard " /></li>
                        <li className="marketing-features-cont">
                            <h4>Leaderboard  </h4>
                            <p>Include leaderboard in your trading platform from Espay to generate competition and boost engagement among the users of crypto exchange. </p>                                   
                        </li>
                    </ul>
                </Col>
                  <Col sm="6">
                    <ul>
                        <li><img className="img-fluid" src={Voting } alt="Feature Voting" title="Feature Voting" /></li>
                        <li className="marketing-features-cont">
                            <h4>Feature Voting</h4>
                            <p>Serve your customers with Espay’s crypto exchange that comprises feature voting because it is public and your customers have a clear view of what is happening within the trading platform. </p>                                   
                        </li>
                    </ul>
                </Col>              
            </Row>
            <Row>           
                <Col sm="6">
                    <ul>
                        <li><img className="img-fluid" src={CoinListing } alt="Coin Listing Voting " title="Coin Listing Voting " /></li>
                        <li className="marketing-features-cont">
                            <h4>Coin Listing Voting </h4>
                            <p>Espay as an exchange development company offers digital asset exchange software that let the traders vote for their favorite coins to place it on the list of crypto exchange software. </p>                                   
                        </li>
                    </ul>
                </Col>
                  <Col sm="6">
                    <ul>
                        <li><img className="img-fluid" src={Feedback } alt="Feedback Survey" title="Feedback Survey" /></li>
                        <li className="marketing-features-cont">
                            <h4>Feedback Survey</h4>
                            <p>Collect qualitative data from your visitors or traders which is directly delivered to users on the trading platforms. It will help you to improve your trading platform the way your traders want. </p>                                  
                        </li>
                    </ul>
                </Col>             
            </Row>        
        </div>
      );
    }
}

export default marketing_features_section2;