import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Container, Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import Newsletter from "../Components/Newsletter";

import facebook from '../Images/social-facebook.png';
import twitter from '../Images/social-twitter.png';
import linkdin from '../Images/social-linkdin.png';
//import google from '../Images/social-google.png';
import youtube from '../Images/social-youtube.png';
import instagram from '../Images/social-instagram.png';
import medium from '../Images/social-medium1.png';

import AddressBook from '../Images/icon-AddressBook.png';
import Building from '../Images/icon-building.png';
import map from '../Images/icon-map.png';
import Phone from '../Images/icon-phone.png';
import Envelope from '../Images/icon-envelope.png';


class Footer extends Component {
    componentWillMount() {
        var str = window.location.href
        var res = str.split("/");
        this.setState({
            url: res[3]
        })
    }
    handleClick() {
        ReactGA.event({
            category: 'Click to Email',
            action: 'mailto:hello@espay.exchange',
            label: 'Clicked on footer email - hello@espay.exchange',
        });
    }
    contactClick() {
        ReactGA.event({
            category: 'Click to Call',
            action: 'tel://+61 (04) 8884-3353',
            label: 'Clicked on footer call - +61 (04) 8884-3353',
        });
    }

    render() {
        return (
            <div>
                {/* <div className={(this.state.url === 'crypto-exchange-software' 
        || this.state.url === 'crypto-exchange-software#TalkToExpert') 
        ? "cryptofooter" : "allfooter"}> */}

                {/* <Container className="crynewfooter">  
                <Row>
                    <Col sm="4"> 
                        <div className="crpcontent">Espay offers a secure as well as fully customized , liquidity ready exchange software 
                            for Startup and enterprise who want to start their own exchange platform in the market.</div>
                        <div className="crpcontent">Our white label exchange platform is ready to use exchange platform with attractive user 
                            interface, developed by dedicated in-house exchange software developers.</div>    
                    </Col>
                    
                    <Col sm={{size:4, offset:1}}> 
                        <h6>Business Detail</h6>
                        <ul className="businessdetails">
                            <li><img src={Building} alt="ESPAY PTY LTD" title="ESPAY PTY LTD" />
                                    <p>ESPAY PTY LTD</p> 
                                </li>
                            <li><img className="mt-10" src={map} alt="Address" title="Address" />
                                    <span itemProp="streetAddress">22 Lambert Cres, Baulkham Hills,</span> 
                                    <span itemProp="addressLocality">Sydney,</span>, 
                                    <span itemProp="addressRegion">NSW, Australia, </span> 
                                    <span itemProp="postalCode">2153</span>
                                </li>
                        </ul>
                    </Col>
                    <Col sm="3"> 
                        <ul className="businessdetails">
                            <li className="mt-10"><img src={Phone} alt="Contact" title="Contact" />
                                     <a href="tel://(+61) 421-514-353"> <Button className="footerbtn" 
                                      onClick="gtag('event','Click',{'event_category':'Click to Call','event_label':'Clicked on footer call -(+61) 421-514-353'});">
                                        (+61) 421-514-353
                                    </Button>
                                    </a>
                                    <a href="tel://(+61) 421-514-353" className="footerbtn" onClick={()=>{this.contactClick()}} >
                                        (+61) 421-514-353
                                    </a>
                                </li>
                            <li><img src={Envelope} alt="Email" title="Email" />
                                    <a href="mailto:hello@espay.exchange">
                                        <Button className="footerbtn" onClick="gtag('event','Click',{'event_category':'Click to Email','event_label':
                                        'Clicked on footer email - hello@espay.exchange'});">
                                            hello@espay.exchange
                                        </Button>
                                    </a>
                                    <a href="mailto:hello@espay.exchange" className="footerbtn" onClick={()=>{this.handleClick()}} >
                                        hello@espay.exchange
                                    </a>
                                </li>
                            <li><img src={AddressBook} alt="" title="" />
                                    <p>ABN: 46 626 112 589</p>
                                </li>
                        </ul>
                    </Col>
                </Row>
            </Container> */}

                <div className="FooterMain">
                    <Container>
                        <Row>
                            <Col sm="3">
                                <h6>Product</h6>
                                <ul className="footermenu">
                                    <li>
                                        <Link to="/crypto-exchange-software">Crypto Exchange</Link>
                                    </li>
                                    <ul>
                                        <li>
                                            <Link to="/centralized-exchange-solutions">Centralized</Link>
                                        </li>
                                        <ul>
                                            <li>
                                                <Link to="/white-label-crypto-exchange-software">WhiteLabel</Link>
                                            </li>
                                            <li>
                                                <Link to="/centralized-business-exchange-software">Standard</Link>
                                            </li>
                                            <li>
                                                <Link to="/centralized-enterprise-exchange-software">Enterprise</Link>
                                            </li>
                                        </ul>
                                        <li>
                                            <Link to="/decentralized-crypto-exchange-software">Decentralized</Link>
                                        </li>

                                        <li>
                                            <Link to="/hybrid-crypto-exchange-software">Hybrid</Link>
                                        </li>
                                        <li>
                                            <Link to="/p2p-exchange-software-development">P2P Exchange</Link>
                                        </li>

                                    </ul>
                                    <li>
                                        <Link to="/security-token-exchange">Security Token Exchange</Link>
                                    </li>
                                    
                                    <li>
                                        <Link to="/cryptocurrency-derivatives-exchange-development">Derivative Exchange</Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col sm="3">
                                <h6>Company</h6>
                                <ul className="footercommenu">
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/about-us">About Us</Link>
                                    </li>
                                    <li>
                                        <a href="https://espay.exchange/blog/">Blog</a>
                                    </li>
                                    <li>
                                        <Link to="/contact-us">Contact Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/vision-mission">Vision &amp; Mission</Link>
                                    </li>
                                    <li>
                                        <Link to="/do-crypto-exchanges-make-money">FAQ</Link>
                                    </li>
                                    <li>
                                        <Link to="/write-for-us-guestpost-submission-guideline">Write For Us</Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col sm="3">
                                <div itemScope itemType="http://schema.org/PostalAddress">
                                    <h6>Business Detail</h6>
                                    <ul className="businessdetails">
                                        <li><img src={Building} alt="ESPAY PTY LTD" title="ESPAY PTY LTD" />
                                            <p>ESPAY PTY LTD</p>
                                        </li>
                                        <li><img className="mt-10" src={map} alt="Address" title="Address" />
                                            <span itemProp="streetAddress">22 Lambert Cres, Baulkham Hills, </span>
                                            <span itemProp="addressLocality">Sydney,</span>,
                                    <span itemProp="addressRegion">NSW, Australia, </span>
                                            <span itemProp="postalCode">2153</span>
                                        </li>
                                        <li className="mt-10"><img src={Phone} alt="Contact" title="Contact" />
                                            {/* <a href="tel://(+61) 421-514-353" 
                                        onClick="gtag('event','Click',{'event_category':'Click to Call','event_label':
                                        'Clicked on footer call -(+61) 421-514-353'});"> (+61) 421-514-353</a> */}
                                            {/* <a href="tel://(+61) 421-514-353" onClick="gtag('event','Click',{'event_category':'Click to Call','event_label':'Clicked on call Contact us -(+61) 421-514-353'});"> (+61) 421-514-353</a> */}
                                            {/* <a href="tel://(+61) 421-514-353"> <Button className="footerbtn" 
                                      onClick="gtag('event','Click',{'event_category':'Click to Call','event_label':'Clicked on footer call -(+61) 421-514-353'});">
                                        (+61) 421-514-353
                                    </Button>
                                    </a>    */}
                                            <a href="tel://+61 (04) 8884-3353" className="footerbtn" onClick={() => { this.contactClick() }} >
                                            +61 (04) 8884-3353
                                    </a>
                                        </li>
                                        <li><img src={Envelope} alt="Email" title="Email" />
                                            {/* <a href="mailto:hello@espay.exchange"
                                     onClick="gtag('event','Click',{'event_category':'Click to Email','event_label':'Clicked on footer email - hello@espay.exchange'});">
                                     hello@espay.exchange</a> */}
                                            {/* <a href="mailto:hello@espay.exchange" onClick="gtag('event','Click',{'event_category':'Click to Email','event_label':'Clicked on footer email - hello@espay.exchange'});">hello@espay.exchange</a> */}
                                            {/* <a href="mailto:hello@espay.exchange">
                                        <Button className="footerbtn" onClick="gtag('event','Click',{'event_category':'Click to Email','event_label':
                                        'Clicked on footer email - hello@espay.exchange'});">
                                            hello@espay.exchange
                                        </Button>
                                    </a> */}
                                            <a href="mailto:hello@espay.exchange" className="footerbtn" onClick={() => { this.handleClick() }} >
                                                hello@espay.exchange
                                    </a>
                                        </li>
                                        <li><img src={AddressBook} alt="" title="" />
                                            <p>ABN: 46 626 112 589</p>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col sm="3">
                                <h6>Join Our Subscriber Club !</h6>
                                <Newsletter />

                                <h6>Connect with Us</h6>
                                <div itemScope itemType="http://schema.org/Organization">
                                    <ul className="footersocial">
                                        <li>
                                            <a itemProp="sameAs" href="https://www.facebook.com/Espayexchange/"><img src={facebook} alt="Facebook" title="Facebook" /></a>
                                        </li>
                                        <li>
                                            <a itemProp="sameAs" href="https://twitter.com/espay"><img src={twitter} alt="Twitter" title="Twitter" /></a>
                                        </li>
                                        <li>
                                            <a itemProp="sameAs" href="https://www.linkedin.com/company/espay-blockchain/"><img src={linkdin} alt="Linkdin" title="Linkdin" /></a>
                                        </li>
                                        {/* <li>
                                        <a itemProp="sameAs" href="https://plus.google.com/101061883799844924895"><img src={google} alt="Google" title="Google" /></a>
                                    </li> */}
                                        <li>
                                            <a itemProp="sameAs" href="https://www.youtube.com/channel/UC1FLBiI9AGdyfuTgq_Mn3hQ"><img src={youtube} alt="Youtube" title="Youtube" /></a>
                                        </li>
                                        <li>
                                            <a itemProp="sameAs" href="https://www.instagram.com/espayx/"><img src={instagram} alt="instagram" title="instagram" /></a>
                                        </li>
                                        <li>
                                            <a itemProp="sameAs"
                                                href="https://medium.com/espayexchange">
                                                <img src={medium} alt="medium" title="medium" /></a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default Footer;