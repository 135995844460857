import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import Malta from '../../../Images/faq/Malta.jpg';


export default class STMalta extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is the state of security tokens in Malta?</h2> 
                            <img src={Malta} alt="Malta" title="Malta" className="img-fluid faqimg"/>
                            <p>Malta continues to be a driving crypto force in the region. This country took the crypto reigns through a combination of factors. Malta was the first country in the world to provide a solid regulatory framework for crypto investors and ICOs. The government is pro-active in recruiting new startups to their market.</p>
                            <p>Today, Malta is one of the best locations in the world to launch your STO. The country already is home to numerous large crypto platforms. The worlds largest crypto exchange by volume, Binance, partnered with the Maltese Stock Exchange (MSX) in September of last year. The two plan to build a new security token exchange within the country in the coming months.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
