import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";

class solutions_centralized_section1 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12"> 
                    <div className="Centralizedtitle">
                        <h2>Centralized Exchange Solutions</h2>
                    </div>                      
                </Col>
            </Row>          
            <Row>
                <Col sm="12">
                    <div>
                        <p>Leverage your own trading platform by opting for a white label powered by Espay – one of the top-notch centralized exchange solution providers. You can make your exchange platform work the way you need with customized features; no matter how compound and challenging your needs would be.</p>
                        <p>Our enterprise and                        
                        <strong> <Link title="Standard Exchange Platforms" to="/centralized-business-exchange-software">
                        standard exchange platforms</Link> </strong> 
                         enable real-time centralized trading of crypto assets with blockchain integration. It delivers 
                        an unmatched trading experience combined with a variety of back-end solutions. Espay’s fully customizable 
                        crypto exchange allows you to define any rules for trade as well as let you add any payment gateway for
                         fiat/cryptocurrency.</p>
                    </div>
                </Col>
            </Row>
        </div>
      );
    }
}

export default solutions_centralized_section1;