import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";

import Certificate from "../Certificate/index";
//import TalkToOurExperts from "../TalkToOurExperts/TalkToOurExpertsForm";
import TalkToOurExperts from "../Contactus/ContactusdetialsSTO";

import Slidermain from "./banner";
import StoPlatform from "./STOPlatform";
import StoSmartContact from "./STOSmartContract";
import STOWebPage from "./STOWebPage";
import STOServices from "./STOServices";
import STOAdvantages from "./STOAdvantages";

import Faqmain from '../Faq/faq';

export default class index extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-125025994-1')
        ReactGA.pageview(document.location.pathname)
        ReactGA.pageview(document.location.path)
      }
      
      scrollPositions = {}
      componentWillMount() {
        if ("scrollRestoration" in window.history) {
          window.history.scrollRestoration = "manual"
        }
        window.scrollTo(0, 0)
      }
    
  render() {
    return (
      <div>
        {this.props.children}
        <Helmet>
            <title>Security Token Offering Development Company | Top STO Services Agency</title>
            <meta name="description" content="As a STO Company we take care of the technical development Of your STO. You can take over all the services related to your security token offering development: Smart Contract Development, Investor Dashboard Creation, STO Security and many more."/>
            <meta property="og:title" content="Security Token Offering Development Company | Top STO Services Agency" />
            <meta property="og:description" content="As a STO Company we take care of the technical development Of your STO. You can take over all the services related to your security token offering development: Smart Contract Development, Investor Dashboard Creation, STO Security and many more." />
            <meta name="twitter:title" content="Security Token Offering Development Company | Top STO Services Agency" />
            <meta name="twitter:description" content="As a STO Company we take care of the technical development Of your STO. You can take over all the services related to your security token offering development: Smart Contract Development, Investor Dashboard Creation, STO Security and many more." />
            <link rel="canonical" href="https://www.espay.exchange/security-token-offering-development" />
        </Helmet>

        <Container fluid={true} className="programming-bg">
          <Container>
            <Slidermain />
          </Container>
        </Container>

        <Container>
            <StoPlatform />
        </Container>

        <Container fluid={true} className="SmartContact-bg">
          <Container>
            <StoSmartContact />
          </Container>
        </Container>

        <Container>
            <STOWebPage />
        </Container>

        <Container fluid={true} className="SmartContact-bg">
          <Container>
            <STOServices />
          </Container>
        </Container>

        <Container>
            <STOAdvantages />
        </Container>
        
        <Container>
            <Certificate />
        </Container>

        <Container>
            <h2 className="faqmaintitle">Frequently Asked Questions</h2>
            <Faqmain />
          </Container>
        
        <TalkToOurExperts />


        <ScrollUpButton className="ScrollUpButton"/>
      </div>
    )
  }
}
