import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

export default class STOPlatform extends Component {
  render() {
    return (
      <div>
        <Row className="mt-30 mb-30">   
            <Col sm="12">
                <div className="stoadviceabouttitle">
                <p>As a technically sound STO agency in Australia, Espay Exchange accompanies STO in their complete technical development.
                     Our blockchain developers are experts in developing the most important programming languages ​​and frameworks from the blockchain scene. You can take over all the necessary technical services related to your STO development: 
                    Smart Contract Development, Investor Dashboard Creation, STO Security and many more.
                </p> 

                </div>
                <div className="stoadviceitle mt-30">
                    <h2>STO Dashboard & Platform</h2>
                   <p>Our STO agency is responsible for the programming of your STO Investor Dashboard, which enables the sale or purchase of your
                        security tokens. We have been involved in the development of both ICO and STO dashboards and platforms for multiple projects 
                        and have the expertise required to professionally implement these applications. Our ICO programming services also include:</p>
                </div>
            </Col>
        </Row>

        <Row>
            <Col sm={12} md={12} lg={6}>
                <div className="Platformbox">
                    <h2>KYC and AML support</h2>
                    <p>STO projects must comply with several legal requirements. "Know Your Customer" (KYC) as well as 
                        "Anti Money Laundering" (AML) verification processes, which are partly required for ICO service, are also required by STO 
                        service. We integrate the KYC and AML functionalities into your STO Investor Dashboard, so that your project is legally
                         compliant.</p>
                </div>
            </Col>
            
            <Col sm={12} md={12} lg={6}>
                <div className="Platformbox">
                    <h2>Built-in calculator</h2>
                    <p>With a calculator, your investors will always be able to review 
                        potential transactions from multiple crypto currencies and Fiat currencies before they are executed.</p>
                </div>
            </Col>
        </Row>
        <Row>
            <Col sm={12} md={12} lg={6}>
                <div className="Platformbox">
                    <h2>Extensive analytics</h2>
                    <p>The Analytics section helps you to analyse the behaviour of your investors and to better anticipate their activities.
</p>
                </div>
            </Col>
            <Col sm={12} md={12} lg={6}>
                <div className="Platformbox">
                    <h2>Extensive functionality</h2>
                    <p>Choose from a high level of functionality and determine only the functionalities that you really need.</p>
                </div>
            </Col>
        </Row>
        <Row>
            <Col sm={12} md={12} lg={6}>
                <div className="Platformbox">
                    <h2>Own user and admin account</h2>
                    <p>Your STO Dashboard will provide personalized accounts to your investors, 
                        so they will have an overview of past payments, submitted documents and much more information.</p>
                </div>
            </Col>
            
            <Col sm={12} md={12} lg={6}>
                <div className="Platformbox">
                    <h2>Integration with third party providers</h2>
                    <p>We will integrate your STO platform as well as your STO dashboard into all necessary APIs and interfaces.</p>
                </div>
            </Col>
        </Row>

      </div>
    )
  }
}
