import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import SafeSecure from '../../Images/SafeSecure.png';
import TokenExchange from '../../Images/TokenExchange.png';
import Reconciliation from '../../Images/Reconciliation.png';
import Traders from '../../Images/Traders.png';

class features_service_secion2 extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm="12">  
                    <div className="SecurityTokentitle">                      
                        <h2>Security Token Exchange Development Features</h2>
                    </div>
                </Col>
            </Row> 
            <Row>
                <Col sm="6">
                    <div className="SecurityTokenFeaturesbox">
                        <img src={SafeSecure} alt="Safe And Secure" title="Safe And Secure" />
                        <h3>Safe And Secure</h3>
                        <p>We employ renowned encryption methods within our security token exchange platform that will keep user
                            accounts encrypted as the safety is our higher priority.</p>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="SecurityTokenFeaturesbox">
                        <img src={TokenExchange} alt="Asset-backed Token Exchange" title="Asset-backed Token Exchange" />
                        <h3>Asset-backed Token Exchange</h3>
                        <p>Espay is building a scalable asset-backed token exchange with a real-life value that offers actively
                            managed fund of crypto and real-world assets.</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="6">
                    <div className="SecurityTokenFeaturesbox">
                        <img src={Reconciliation} alt="Faster Clearing & Reconciliation" title="Faster Clearing & Reconciliation" />
                        <h3>Faster Clearing & Reconciliation</h3>
                        <p>When it comes to post-trade servicing, Espay’s exchange helps to streamline and improve the
                            reconciliation and clear transactions with just a single click.</p>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="SecurityTokenFeaturesbox">
                        <img src={Traders} alt="Whitelisted Traders" title="Whitelisted Traders" />
                        <h3>Whitelisted Traders</h3>
                        <p>Our exchange will allow cryptocurrency trading to only whitelisted traders. The transfer will only
                            complete successfully if the recipient is registered. It reduces the chances of frauds.</p>
                    </div>
                </Col>
            </Row>
        </div>
      );
    }
}

export default features_service_secion2;