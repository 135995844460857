import React, { Component } from 'react';
import {Row, Col} from 'reactstrap';

export default class PotentialAvailableMarket extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm={12}>
                        <div className="potential-title">
                            <span>Potential Available Market for Security Token Exchanges</span>
                            <p>The decentralized nature of security tokens makes them globally accessible. As a result, a security token exchange located in the US can allow investors from South Africa to trade tokens on their platform. Still, owing to the lack of awareness, the available target audience remains limited.</p>
                            <p>The potential available market includes -</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <div className="potential-box">
                            <span>Accredited Investors</span>
                        </div>
                        <div className="potential-detial-box">
                            <p>Accredited investors are individuals with high net worth. In the US, an accredited investor is:</p>
                            <ul>
                                <li>An individual with $1,000,000 in net worth other than a Primary Residence.</li>
                                <li>A member of the household earning $200,000 for the last two consecutive years and expecting it to continue in the current year.</li>
                                <li>An individual with a spouse, earning $300,000 for the last two consecutive years and expecting it to continue in the current year.</li>
                            </ul>
                            <p>According to this definition, 8.25% of all American households or around 10,108,811 households in the US were Accredited Investors in 2018. They hold about 70.28% of all private wealth in America, which comes to a staggering $45.5 trillion.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} md={12} lg={6}>
                        <div className="potential-box">
                            <span>Non-Accredited Investors</span>
                        </div>
                        <div className="potential-detial-box">
                            <p>Non-accredited investors perhaps form the largest group of small investors, shunned by the present complex regulations and geographic restrictions. However, STEs listing security tokens approved under Reg A+ in the US and its equivalent in other countries can tap into these investors from across the globe.</p>
                        </div>
                    </Col>
                    <Col sm={6} md={12} lg={6}>
                        <div className="potential-box">
                            <span>Millennials</span>
                        </div>
                        <div className="potential-detial-box">
                            <p>Being the largest generation (35%) in the U.S. labor force and perhaps in the world, Millennials are the driving force behind many national economies. However, as they are paying more for rent, food, and other comforts, Millennials often find it difficult to access traditional investment assets such as real estate, stocks, and shares.</p>
                            <p>But, being tech-savvy, they are well aware of security tokens. This openness to technology and fractional ownership of a tokenized asset make security tokens a promising investment alternative to them. You can target Millennials to invest in STEs relatively easily.</p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={6} md={12} lg={6}>
                        <div className="potential-box">
                            <span>Startups</span>
                        </div>
                        <div className="potential-detial-box">
                            <p>Another market segment that you can tap into includes startups. Security token offerings offer a reliable alternative to the traditional crowdfunding options for raising capital investments. The same security tokens can be traded on your exchange platform.</p>
                        </div>
                    </Col>
                    <Col sm={6} md={12} lg={6}>
                        <div className="potential-box">
                            <span>Consultants and Service Providers</span>
                        </div>
                        <div className="potential-detial-box">
                            <p>Consultants and service providers are the individuals or companies offering various technical, legal, and financial services related to security tokens. As these individuals already know how security tokens function, converting them into potential investors is relatively easy. If not, they can, at least, function as the advocates of your exchange platform bringing in more investors.</p>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
