import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import CustomizedSolution1 from '../../Images/CustomizedSolution1.png';
import Architectureicon from '../../Images/Architectureicon.png';
import SecurityCore from '../../Images/SecurityCore.png';
import Technology from '../../Images/Technology.png';
import management from '../../Images/management.png';
import Developedhouse from '../../Images/Developedhouse.png';

class platform_standard_section3 extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm="12">
                    <div className="enterprisetitle mt-30">
                        <h2>Benefits For Enterprise Exchange Software Solution</h2>
                        <p>Espay’s trading platforms are one of the only high-volume exchanges on the web which adhere latest
                                advancements and <br/> supports smooth trading process.</p>
                    </div>
                </Col>
            </Row> 
            <Row> 
                <Col sm="1">
                    <img className="img-fluid enterprise-feature-icon" src={CustomizedSolution1} alt="Fully Customized Solution" title="Fully Customized Solution" />
                </Col>
                <Col sm="3">
                    <div className="enterprisebenefitsbox">
                        <h3>Fully Customized Solution</h3>
                        <p>Exchange platform is highly customization, extensible as well as versatile, designed and developed to help 
                        to save your time.</p>
                    </div>
                </Col>   
                <Col sm="1">
                    <img className="img-fluid enterprise-feature-icon" src={Architectureicon} alt="Scalable Architecture" title="Scalable Architecture" />
                </Col>
                <Col sm="3">
                    <div className="enterprisebenefitsbox">
                    <h3>Scalable Architecture</h3>
                    <p>Espay’s trading software comprises a scalable architecture that can scale up to come across increased
                            transaction loads.</p>
                    </div>
                </Col>
                <Col sm="1">
                    <img className="img-fluid enterprise-feature-icon" src={SecurityCore} alt="Security at its Core" title="Security at its Core" />
                </Col>
                <Col sm="3">
                    <div className="enterprisebenefitsbox">
                    <h3>Security at its Core</h3>
                    <p>Espay’s trading platforms offer multiple layers of security that lessen hacking attempts and assure that
                                your funds will be secure always.</p>
                    </div>
                </Col>               
            </Row>
            <Row>   
                <Col sm="1">
                    <img className="img-fluid enterprise-feature-icon" src={Technology} alt="Cutting-Edge Technology" title="Cutting-Edge Technology" />
                </Col>
                <Col sm="3">
                    <div className="enterprisebenefitsbox">
                    <h3>Cutting-Edge Technology</h3>
                    <p>Developers at Espay keep eyes on the continuous updates in technology to deliver cutting-edge exchange 
                        solutions in each corner of the world.</p>
                    </div>
                </Col>
                <Col sm="1">
                    <img className="img-fluid enterprise-feature-icon" src={management} alt="Ease of operations management" title="Ease of operations management" />
                </Col>
                <Col sm="3">
                    <div className="enterprisebenefitsbox">
                    <h3>Ease of operations management</h3>
                    <p>Bitcoin exchange solutions from Espay comprises an ability to manage multiple operational aspects with
                            great easiness in developing, planning, etc.</p>
                    </div>
                </Col>
                <Col sm="1">
                    <img className="img-fluid enterprise-feature-icon" src={Developedhouse} alt="Developed in house" title="Developed in house" />
                </Col>
                <Col sm="3">
                    <div className="enterprisebenefitsbox">
                    <h3>Developed in house</h3>
                    <p>Peer-to-peer crypto exchanges which are manufactured in-house aids you keep track of modifications and 
                        scale production & enables to establish fixes in a timely fashion.</p>
                    </div>
                </Col>                
            </Row>
        </div>
      );
    }
}

export default platform_standard_section3;