import React, { Component } from 'react';
import {Row, Col} from 'reactstrap';
import keytimg1 from '../../Images/MarketingResearch/key-tk-1.png';
import keytimg2 from '../../Images/MarketingResearch/key-tk-2.png';
import keytimg3 from '../../Images/MarketingResearch/key-tk-3.png';
import keytimg4 from '../../Images/MarketingResearch/key-tk-4.png';
import keytimg5 from '../../Images/MarketingResearch/key-tk-5.png';

export default class KeyPlayersSTEM extends Component {
    render() {
        return (
          <div>
            <Row>
              <Col sm={12}>
                <div className="keyplayers-title">
                  <span>
                    Key Players in the Security Token Exchange Market
                  </span>
                </div>
              </Col>
            </Row>

            <Row className="keyplayers-box">
              <Col sm={12} lg={7} className="order-2 order-lg-1">
                    <h3>OpenFinance Network (OFN)</h3>
                    <p>Market Cap - $6 billion</p>
                    <p>Investors - Sharpe Ventures and Huobi</p>
                    <p>Features</p>
                    <ul>
                        <li>
                            It is open for accredited and non-accredited
                            investors in the US and other countries.
                        </li>
                        <li>
                            It provides a one-time investor passport
                            verification process for easy registration.
                        </li>
                        <li>
                            It lists qualified broker-dealers, transfer agents,
                            custodians, escrow agents, fund administrators, and
                            registered funding portals.
                        </li>
                        <li>
                            It is supported by blockchain bigwigs such as Atomic
                            Capital, Polymath, Harbor, Securitize, and Huobi,
                            among others.
                        </li>
                        <li>
                            You can trade US-compliant security tokens eligible
                            under a Regulation S, D, A+ or CF.{" "}
                        </li>
                    </ul>
              </Col>
              <Col sm={12} lg={5} className="text-center pr-0 order-1">
                <img
                  className="img-fluid"
                  src={keytimg1}
                  alt="OpenFinance Network"
                  title="OpenFinance Network"
                />
              </Col>
            </Row>

            <Row className="keyplayers-box">
                <Col sm={12} lg={5} className="text-center">
                    <img
                    className="img-fluid"
                    src={keytimg2}
                    alt="OpenFinance Network"
                    title="OpenFinance Network"
                    />
                </Col>
                <Col sm={12} lg={7} className="text-lg-right text-sm-left">
                    <h3>Overstock/Tzero</h3>
                    <p>Market Cap - $134 million</p>
                    <p>Investors - TEAM IN RESIDENCE and Medici Ventures</p>
                    <p>Features -</p>
                    <ul>
                        <li>ou can trade security tokens available through ICOs that are compliant with FINRA and SEC regulations.</li>
                        <li>It uses two-factor identification, multi-sig addresses, and other preventative measures to protect investors.</li>
                        <li>It provides stock inventory management systems, brokerage services, and smart ordering routing services.</li>
                    </ul>
                </Col>
            </Row>

            <Row className="keyplayers-box">
              <Col sm={12} lg={7} className="order-2 order-lg-1">
                <h3>Bancor</h3>
                <p>Market Cap - $35 million</p>
                <p>Investors - NA</p>
                <p>Features -</p>
                <ul>
                    <li>Bancor charges no listing fees.</li>
                    <li>You can list any ERC20 token including security tokens  on the Bancor Network.</li>
                </ul>
              </Col>
              <Col sm={12} lg={5} className="text-center order-1">
                <img
                  className="img-fluid"
                  src={keytimg3}
                  alt="OpenFinance Network"
                  title="OpenFinance Network"
                />
              </Col>
            </Row>

            <Row className="keyplayers-box">
                <Col sm={12} lg={5} className="text-center">
                    <img
                    className="img-fluid"
                    src={keytimg4}
                    alt="OpenFinance Network"
                    title="OpenFinance Network"
                    />
                </Col>
                <Col sm={12} lg={7} className="text-lg-right text-sm-left">
                    <h3>CEZEX</h3>
                    <p>Market Cap - $20 million</p>
                    <p>Investors - Pinnacle Digital, IPE Global Pte Ltd, Phnom Penh</p>
                    <p>Derivatives Exchange, and SB Kaikaku Fund</p>
                    <p>Features -</p>
                    <ul>
                        <li>You can tokenize company shares, bonds, commodities, and real estate assets.</li>
                        <li>It will be open to both, retail and institutional investors.</li>
                    </ul>
                </Col>
            </Row>

            <Row className="keyplayers-box">
              <Col sm={12} lg={7} className="order-2 order-lg-1">
                <h3>Templum</h3>
                <p>Market Cap - $10 million</p>
                <p>Investors - SBI Holdings Inc. Japan, and others</p>
                <p>Features -</p>
                <ul>
                    <li>You can launch tokenized securities in the US market.</li>
                    <li>It is an SEC-approved Alternative Trading System (ATS) a broker-dealer firm for private and unregistered digital assets.</li>
                    <li>It is also compliant with the Financial Industry Regulatory Authority (FINRA).</li>
                </ul>
              </Col>
              <Col sm={12} lg={5} className="text-center order-1">
                <img
                  className="img-fluid"
                  src={keytimg5}
                  alt="OpenFinance Network"
                  title="OpenFinance Network"
                />
              </Col>
            </Row>
          </div>
        );
    }
}
