import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';


export default class STOSmartContract extends Component {
  render() {
    return (
      <div>
        <div className="Leveragework-bg text-white">  
            <Container>
            <Col sm="5" className="lavrage-works-content">
                <div className="">
                    <h3>How does it work?</h3>
                    <p>The leverage margin trading is all about borrowing funds from the exchanges for multiple reasons such as to increase the order volume or to get maximum profits. Various cryptocurrency exchanges offer such lending funds to the traders once they deposit a margin amount to the exchange. </p>
                    <p>Let's understand it by an example. There is a trader who wants to purchase 150 bitcoins from the exchange. The price of each bitcoin is $10,000. To buy that much stock, a trader has to pay $1.5 million in normal trading. However, in the leverage and margin exchange trading, he can just deposit a margin of 1 bitcoin and acquire a position of 150 bitcoins. Now, if the price of the bitcoin reaches to $11,000, the trader will make a profit of $150,000. In a normal scenario, he would have earned just $1,000.</p>
                </div>
            </Col>
            
            </Container>
        </div>      

             
      </div>
    )
  }
}
