import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Container } from 'reactstrap';
import { Helmet } from "react-helmet";
import ScrollUpButton from "react-scroll-up-button";

// import Mainbanner from './banner';
// import Mainvision from './TP-Section1';
// import Mainmision from './TP-Section2';
// import Challenges from './TP-Section3';
// import Needtime from './TP-Section4';
// import Launching from './TP-Section6';
// import HowWork from './TP-Section7';
// import Cryptoattract from './TP-Section8';
// import Flow from './TP-Section9';
import Peoplecoin from './TP-Section10';
import Sec11 from './TP-Section11';
import Sec12 from './TP-Section12';
import Sec13 from './TP-Section13';
import Sec14 from './TP-Section14';
// import Sec15 from './TP-Section15';
// import Sec16 from './TP-Section16';
// import Sec17 from './TP-Section17';
// import Sec18 from './TP-Section18';
// import Sec19 from './TP-Section19';
// import Sec20 from './TP-Section20';
// import Sec21 from './TP-Section21';
// import Sec22 from './TP-Section22';
// import Sec23 from './TP-Section23';
// import Sec24 from './TP-Section24';
// import Sec25 from './TP-Section25';
import Sec26 from './TP-Section26';

export default class index extends Component {

  componentDidMount() {
    ReactGA.initialize('UA-125025994-1')
    ReactGA.pageview(document.location.pathname)
    ReactGA.pageview(document.location.path)
  }
  
  scrollPositions = {}
    componentWillMount() {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "manual"
      }
      window.scrollTo(0, 0)
  }

  render() {
    return (

      <div>
        {this.props.children}
        <Helmet>
            <title>Reasons Why You Should Invest In Whitelabel Cryptocurrency Exchange Development</title>
            <meta name="description" content="Cryptocurrency is a booming market now which has boosted cryptocurrency exchange development for businesses. Learn the top reasons and how you could build your custom exchange."/>
            <meta property="og:title" content="Reasons Why You Should Invest In Whitelabel Cryptocurrency Exchange Development" />
            <meta property="og:description" content="Cryptocurrency is a booming market now which has boosted cryptocurrency exchange development for businesses. Learn the top reasons and how you could build your custom exchange." />
            <meta name="twitter:title" content="Reasons Why You Should Invest In Whitelabel Cryptocurrency Exchange Development" />
            <meta name="twitter:description" content="Cryptocurrency is a booming market now which has boosted cryptocurrency exchange development for businesses. Learn the top reasons and how you could build your custom exchange." />
            <link rel="canonical" href="https://www.espay.exchange/invest-in-whitelabel-cryptocurrency-exchange" />
        </Helmet>

        <Container fluid={true} className="banner_bg">
          <Sec26 />
        </Container>
        
        <Container fluid={true} className="banner_bg">
           <Sec13 />
        </Container>

        <Container fluid={true} className="Sec12">
          <Container>
            <Sec12 />
          </Container>
        </Container>

          <Container fluid={true} className="Sec14">
          <Container>
            <Sec14 />
          </Container>
        </Container>

         <Container fluid={true} className="Sec11">
          <Container>
            <Sec11 />
          </Container>
        </Container>

        <Container fluid={true} className="Peoplecoin ">
          <Container>
            <Peoplecoin />
          </Container>
        </Container>

         {/* <Container fluid={true} className="Template-banner">
          <Container>
            <Mainbanner />
          </Container>
        </Container>

        <Container>
          <Mainmision /> 
        </Container>   

        <Container fluid={true} className="template-graph">
          <Container>
            <Mainvision />
          </Container>
        </Container>

        <Container fluid={true} className="Challenges">
          <Container>
            <Challenges />
          </Container>
        </Container>

        <Container fluid={true} className="Needtime">
          <Container>
            <Needtime />
          </Container>
        </Container>

        <Container fluid={true} className="Launching">
          <Container>
            <Launching />
          </Container>
        </Container>

        <Container fluid={true} className="Howwork">
          <Container>
            <HowWork />
          </Container>
        </Container>

        <Container fluid={true} className="Cryptoattract ">
          <Container>
            <Cryptoattract />
          </Container>
        </Container>

        <Container fluid={true} className="Flow ">
          <Container>
            <Flow />
          </Container>
        </Container>


        <Container fluid={true} className="landingsection3">
          <Container>
            <Sec15 />
          </Container>
        </Container>

        <Container fluid={true} className="landingsection4">
          <Container>
            <Sec16 />
          </Container>
        </Container>

        <Container fluid={true}>
          <Container>
            <Sec17 />
          </Container>
        </Container>

        <Container fluid={true} className="landingsection656">
          <Container>
            <Sec18 />
          </Container>
        </Container>

        <Container fluid={true} className="landingsection7">
          <Container>
            <Sec19 />
          </Container>
        </Container>

        <Container fluid={true}>
          <Container>
            <Sec20 />
          </Container>
        </Container>

        <Container fluid={true} className="landingsection9">
          <Container>
            <Sec21 />
          </Container>
        </Container>

        <Container fluid={true}>
          <Container>
            <Sec22 />
          </Container>
        </Container>

        <Container fluid={true} className="landingsection11">
          <Container>
            <Sec23/>
          </Container>
        </Container>

        <Container fluid={true} className="landingsection12">
          <Container>
            <Sec24/>
          </Container>
        </Container>

        <Container fluid={true} className="landingsection13">
          <Container>
            <Sec25/>
          </Container>
        </Container>*/}

      <ScrollUpButton className="ScrollUpButton"/>

      </div>
    )
  }
}

