import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";

export default class P2PExchange3 extends Component {
  render() {
    return (
      <div>
        <Row> 
            <Col sm="9">
                <span>Looking for Most secure P2P Crypto Exchange ?</span>
            </Col>
            <Col sm="3">
                <Link to="/contact-us">Book A Demo</Link>
            </Col>
        </Row>  
      </div>
    )
  }
}
