import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
//import { Link } from "react-router-dom";
import Righttick from "../../Images/righttick.png";
import AirDropcampaign from "../../Images/ICO_Marketing/AirdropCampaignimage.png";

export default class ICOproject extends Component {
    render() {
        return (
            <div className="">
                <Container className="AirDrop">
                    <Row>
                        <Col sm="6" className="icoproject-section">
                            <div className="stoadviceitle">
                                <h2>What is AirDrop campaign?</h2>
                            </div>
                            <p>AirDrop campaign is a famous and highly effective marketing strategy in which some free tokens are distributed to the active promoters or the registered users of a specific blockchain.</p>
                            <p>The main objective of distribution of the free coins is to aware people about the ICO project and let them participate in the discussions on various forums and communities. </p>
                            <p>Project listing on ICO listing websites and calendars </p>
                            <p>Crypto investors research much about new and highly potential crypto tokens and they regularly visit various ICO listing websites to find information about new ICOs. </p>
                            <p>Some of the ICO listing websites and calendars are</p>
                        </Col>
                        <Col sm="6">
                            <img src={AirDropcampaign} alt="AirDropcampaign" title="AirDropcampaign" className="airdropimg"/>
                        </Col>
                    </Row>
                    <Row>
                        <div className="col-sm-3">
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">ICO Bench</span>
                            </p>
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">Coin Rating</span>
                            </p>
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">Smith & Crown</span>
                            </p>
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">ICO Drops</span>
                            </p>
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">Token Data</span>
                            </p>
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">ICO Data</span>
                            </p>
                        </div>

                        <div className="col-sm-3">

                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">ICO Bazaar</span>
                            </p>
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">ICO Alert</span>
                            </p>
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">ICO Watch List</span>
                            </p>
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">ICO Hot List</span>
                            </p>
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">List ICO</span>
                            </p>
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">ICO Map</span>
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">ICO Champs</span>
                            </p>
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">ICO Tracker</span>
                            </p>
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">CoinGecko</span>
                            </p>
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">Token Schedule</span>
                            </p>
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">ICO Rating</span>
                            </p>
                        </div>

                        <div className="col-sm-3">

                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">Token Market</span>
                            </p>
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">Coin Telegraph</span>
                            </p>
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">ICO Stream</span>
                            </p>
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">ICO Signal</span>
                            </p>
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">And more..</span>
                            </p>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-sm-12">
                            <p>ICO calenders offer a complete list of all upcoming ICOs and in this manner, you can notify the potential investors and Crypto community about your ICO project. </p>
                            <p>What are the things to be included in the ICO calenders </p>
                        </div>
                        <div className="col-sm-3">
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">The project name</span>
                            </p>
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">Token details</span>
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">Project details</span>
                            </p>
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">The website URL</span>
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <p>
                                <span class="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                <span class="col-sm-9">Contact information</span>
                            </p>
                        </div>
                    </Row>

                </Container>
            </div >
        )
    }
}
