import React, { Component, Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';


import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const faqpanel1 = [
    "/Faqs",
    "/do-crypto-exchanges-make-money",
    "/how-do-centralized-crypto-exchanges-work",
    "/how-do-crypto-trading-pairs-work",
    "/how-do-cryptocurrency-exchanges-work",
    "/how-do-i-start-a-crypto-exchange-business",
    "/how-do-security-token-offering-work",
    "/what-is-a-centralised-exchange",
    "/what-is-a-decentralized-crypto-exchange",
    "/what-is-a-digital-asset-exchange",
    "/what-is-p2p-exchange",
    "/what-is-white-label-crypto-exchange",
]

const faqpanel2 = [
    "/what-is-security-token",
    "/what-is-sto",
    "/what-is-sto-exchange",
    "/benefits-of-having-a-sto-exchange",
    "/why-the-market-is-bullish-about-the-security-tokens",
    "/how-to-determine-if-a-token-falls-into-the-crieteria-of-security-token",
    "/security-tokens-can-change-the-market",
    "/how-are-tokens-classified",
    "/how-security-token-can-liquidize-the-illiquid-assets-securities",
    "/how-does-tokenization-work",
    "/why-security-tokens-are-the-big-deal",
    "/ecosystem-of-security-token",
    "/what-is-the-significance-of-issuance-in-security-token-exchange",
    "/what-is-the-broker-dealer-component-of-security-token-exchange",
    "/what-is-the-custody-trust-component-of-security-token-exchange",
    "/what-is-the-legal-component-of-security-token-exchange",
    "/what-is-the-compliance-component-of-a-security-token-exchange",
    "/how-does-the-trading-platform-work-in-security-token-exchange",
    "/what-are-equity-tokens",
    "/what-are-debt-tokens",
    "/what-are-derivatives",
    "/what-is-the-state-of-security-token-in-europe",
    "/what-is-markets-in-financial-directive-ii",
    "/what-is-the-state-of-security-token-in-liechtenstein",
    "/the-state-of-security-tokens-in-germany",
    "/what-is-the-state-of-security-tokens-in-switzerland",
    "/what-is-the-state-of-security-tokens-in-malta",
    "/what-is-the-state-of-security-tokens-in-estonia",
    "/what-is-the-state-of-security-tokens-in-france",
    "/what-is-the-state-of-security-token-in-uk",
    "/what-is-the-scenario-of-security-token-scenario-in-slovenia",
    "/what-is-the-state-of-security-tokens-in-ecuador",
    "/security-token-offering-and-banking-security",
    "/how-does-the-security-token-work-with-the-financial-center",
    "/tokenization-of-healthcare-assets",
    "/how-security-tokens-could-be-implemented-in-payment-systems",
    "/state-of-security-token-offerings-in-australia",
    "/state-of-security-token-offering-in-asian-countries",
]
const faqpanel3 = [
    "/what-is-decentralized-exchange",
    "/what-are-the-benefits-of-decentralized-exchange",
    "/what-are-the-key-technical-specifications-of-the-decentralized-exchange",
    "/how-businesses-could-deploy-a-decentralized-exchange",
    "/the-growth-and-potential-of-decentralized-exchanges",
    "/what-is-atomic-swap-and-how-it-is-used-in-decentralized-exchange-development",
]
const faqpanel4 = [
    "/benefits-of-P2P-exchange",
    "/market-prediction-for-cryptocurrency-exchange",
    "/technology-of-P2P-exchange-and-earning-opportunity",
]
const faqpanel5 = [
    "/what-is-marketing-capitalization-of-cryptocurrency-exchange"
]
const faqpanel6 = [
    "/what-is-an-alternative-trading-system"
]
export default class Faqleftpanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: "faqpanel1",
            modal2: false,
            activeLink : ''
        }
    }
    linkActive(activeLink) {
        this.setState({activeLink : activeLink});
    }
    showModal(modal) {
        this.setState({
            [modal]: true,
        });

    }
    closeModal(tabId) {
        this.setState({
            [tabId]: false
        });
    }

    //Expand Panel Change....
    expandPanelChange = (panel, value) => (event, expanded) => {
        console.log("expanded",expanded)
        this.setState({ expanded: expanded ? panel : '' });
    };
componentDidMount(){
    const { location } = this.props;
    // console.log("expanded",this.state.expanded)
    // console.log("componentDidMount location.pathname",location.pathname)
    // console.log("faqpanel1.indexOf(location.pathname)", faqpanel1.indexOf(location.pathname))
    // console.log("faqpanel2.indexOf(location.pathname)", faqpanel2.indexOf(location.pathname))
    // console.log("faqpanel3.indexOf(location.pathname)", faqpanel3.indexOf(location.pathname))
    // console.log("faqpanel4.indexOf(location.pathname)", faqpanel4.indexOf(location.pathname))
    // console.log("faqpanel5.indexOf(location.pathname)", faqpanel5.indexOf(location.pathname))
    // console.log("faqpanel6.indexOf(location.pathname)", faqpanel6.indexOf(location.pathname))
    
    if (location !== undefined && location.pathname !== undefined && faqpanel1.indexOf(location.pathname)!=-1) {
        this.setState({ expanded: "faqpanel1", activeLink:location.pathname });
    } else if (location !== undefined && location.pathname !== undefined && faqpanel2.indexOf(location.pathname)!=-1) {
        this.setState({ expanded: "faqpanel2", activeLink:location.pathname });
    } else if (location !== undefined && location.pathname !== undefined && faqpanel3.indexOf(location.pathname)!=-1) {
        this.setState({ expanded: "faqpanel3", activeLink:location.pathname });
    } else if (location !== undefined && location.pathname !== undefined && faqpanel4.indexOf(location.pathname)!=-1) {
        this.setState({ expanded: "faqpanel4", activeLink:location.pathname });
    } else if (location !== undefined && location.pathname !== undefined && faqpanel5.indexOf(location.pathname)!=-1) {
        this.setState({ expanded: "faqpanel5", activeLink:location.pathname });
    } else if (location !== undefined && location.pathname !== undefined && faqpanel6.indexOf(location.pathname)!=-1) {
        this.setState({ expanded: "faqpanel6", activeLink:location.pathname });
    } else {
        this.setState({ expanded: "faqpanel1", activeLink:location.pathname });
    }
}
   
    render() {
        return (
            <Fragment>
                <Container fluid={true}>
                    <Row>
                        <div className="col-md-12 mx-auto">
                            <div className="facility-addons">
                                <ExpansionPanel
                                    expanded={this.state.expanded === 'faqpanel1'}
                                    onChange={this.expandPanelChange('faqpanel1')}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Crypto Exchanges</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="faqleftmain">
                                        <Typography className="Faqlinks">
                                            <Link className={this.state.activeLink === "/do-crypto-exchanges-make-money" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/do-crypto-exchanges-make-money")} 
                                            to="/do-crypto-exchanges-make-money">Do crypto exchanges make money?</Link>

<Link className={this.state.activeLink === "/how-do-centralized-crypto-exchanges-work" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/how-do-centralized-crypto-exchanges-work")} 
                                            to="/how-do-centralized-crypto-exchanges-work">How do centralized crypto exchanges work?</Link>

<Link className={this.state.activeLink === "/how-do-crypto-trading-pairs-work" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/how-do-crypto-trading-pairs-work")} 
                                            to="/how-do-crypto-trading-pairs-work">How do crypto trading pairs work?</Link>

<Link className={this.state.activeLink === "/how-do-cryptocurrency-exchanges-work" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/how-do-cryptocurrency-exchanges-work")} 
                                            to="/how-do-cryptocurrency-exchanges-work">How do cryptocurrency exchanges work?</Link>

<Link className={this.state.activeLink === "/how-do-i-start-a-crypto-exchange-business" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/how-do-i-start-a-crypto-exchange-business")} 
                                            to="/how-do-i-start-a-crypto-exchange-business">How do I start a crypto exchange business?</Link>
					    
                        <Link className={this.state.activeLink === "/how-do-security-token-offering-work" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/how-do-security-token-offering-work")} 
                                            to="/how-do-security-token-offering-work">How does security token offering work?</Link>

<Link className={this.state.activeLink === "/what-is-a-centralised-exchange" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-a-centralised-exchange")} 
                                            to="/what-is-a-centralised-exchange">What is a Centralised exchange?</Link>

<Link className={this.state.activeLink === "/what-is-a-decentralized-crypto-exchange" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-a-decentralized-crypto-exchange")} 
                                            to="/what-is-a-decentralized-crypto-exchange">What is a decentralized crypto exchange?</Link>

<Link className={this.state.activeLink === "/what-is-a-digital-asset-exchange" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-a-digital-asset-exchange")} 
                                            to="/what-is-a-digital-asset-exchange">What is a digital asset exchange?</Link>

<Link className={this.state.activeLink === "/what-is-p2p-exchange" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-p2p-exchange")} 
                                            to="/what-is-p2p-exchange">What is p2p exchange?</Link>

<Link className={this.state.activeLink === "/what-is-white-label-crypto-exchange" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-white-label-crypto-exchange")} 
                                            to="/what-is-white-label-crypto-exchange">What is white label crypto exchange?</Link>
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>

                                <ExpansionPanel
                                    expanded={this.state.expanded === 'faqpanel2'}
                                    onChange={this.expandPanelChange('faqpanel2')}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Security Token Exchange</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="faqleftmain">
                                        <Typography className="Faqlinks">
                                            <Link className={this.state.activeLink === "/what-is-security-token" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-security-token")} 
                                            to="/what-is-security-token">What is Security token</Link>

                                            <Link className={this.state.activeLink === "/what-is-sto" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-sto")} 
                                            to="/what-is-sto">what-is-sto</Link>
                                            
                                            <Link className={this.state.activeLink === "/what-is-sto-exchange" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-sto-exchange")} 
                                            to="/what-is-sto-exchange">What is STO Exchange?</Link>

                                            <Link className={this.state.activeLink === "/benefits-of-having-a-sto-exchange" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/benefits-of-having-a-sto-exchange")} 
                                            to="/benefits-of-having-a-sto-exchange">Benefits of Having A STO Exchange</Link>

                                            <Link className={this.state.activeLink === "/why-the-market-is-bullish-about-the-security-tokens" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/why-the-market-is-bullish-about-the-security-tokens")} 
                                            to="/why-the-market-is-bullish-about-the-security-tokens">Why the market is bullish about the security tokens?</Link>
					                        
                                            
                                            <Link className={this.state.activeLink === "/how-to-determine-if-a-token-falls-into-the-crieteria-of-security-token" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/how-to-determine-if-a-token-falls-into-the-crieteria-of-security-token")} 
                                            to="/how-to-determine-if-a-token-falls-into-the-crieteria-of-security-token">How to determine if a token falls into the crieteria of Security Token?</Link>
                                            <Link className={this.state.activeLink === "/security-tokens-can-change-the-market" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/security-tokens-can-change-the-market")} 
                                            to="/security-tokens-can-change-the-market">Security Tokens Can Change The Market.</Link>

                                            <Link className={this.state.activeLink === "/how-are-tokens-classified" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/how-are-tokens-classified")} 
                                            to="/how-are-tokens-classified">How are tokens classified?</Link>

                                            <Link className={this.state.activeLink === "/how-security-token-can-liquidize-the-illiquid-assets-securities" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/how-security-token-can-liquidize-the-illiquid-assets-securities")} 
                                            to="/how-security-token-can-liquidize-the-illiquid-assets-securities">How Security token can liquidize the illiquid assets/securities?</Link>
                                            
                                            <Link className={this.state.activeLink === "/how-does-tokenization-work" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/how-does-tokenization-work")} 
                                            to="/how-does-tokenization-work">How does tokenization work?</Link>

                                            <Link className={this.state.activeLink === "/why-security-tokens-are-the-big-deal" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/why-security-tokens-are-the-big-deal")} 
                                            to="/why-security-tokens-are-the-big-deal">Why Security Tokens are the big deal?</Link>
                                            
                                            <Link className={this.state.activeLink === "/ecosystem-of-security-token" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/ecosystem-of-security-token")} 
                                            to="/ecosystem-of-security-token">Ecosystem of Security Token</Link>
                                        
                                            <Link className={this.state.activeLink === "/what-is-the-significance-of-issuance-in-security-token-exchange" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-the-significance-of-issuance-in-security-token-exchange")} 
                                            to="/what-is-the-significance-of-issuance-in-security-token-exchange">What is the significance of issuance in security token exchange?</Link>

                                            <Link className={this.state.activeLink === "/what-is-the-broker-dealer-component-of-security-token-exchange" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-the-broker-dealer-component-of-security-token-exchange")} 
                                            to="/what-is-the-broker-dealer-component-of-security-token-exchange">What is the broker dealer component of security token exchange?</Link>

                                            <Link className={this.state.activeLink === "/what-is-the-custody-trust-component-of-security-token-exchange" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-the-custody-trust-component-of-security-token-exchange")} 
                                            to="/what-is-the-custody-trust-component-of-security-token-exchange">What is the custody & trust component of security token exchange?</Link>

                                            <Link className={this.state.activeLink === "/what-is-the-legal-component-of-security-token-exchange" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-the-legal-component-of-security-token-exchange")} 
                                            to="/what-is-the-legal-component-of-security-token-exchange">What is the legal component of security token exchange?</Link>


                                            <Link className={this.state.activeLink === "/what-is-the-compliance-component-of-a-security-token-exchange" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-the-compliance-component-of-a-security-token-exchange")} 
                                            to="/what-is-the-compliance-component-of-a-security-token-exchange">What is the compliance component of a security token exchange?</Link>

                                            <Link className={this.state.activeLink === "/how-does-the-trading-platform-work-in-security-token-exchange" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/how-does-the-trading-platform-work-in-security-token-exchange")} 
                                            to="/how-does-the-trading-platform-work-in-security-token-exchange">How Does The Trading Platform Work In Security Token Exchange?</Link>
                                            
                                            <Link className={this.state.activeLink === "/what-are-equity-tokens" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-are-equity-tokens")} 
                                            to="/what-are-equity-tokens">What are Equity Tokens?</Link>
                                            
                                            <Link className={this.state.activeLink === "/what-are-debt-tokens" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-are-debt-tokens")} 
                                            to="/what-are-debt-tokens">What Are Debt Tokens?</Link>

                                            <Link className={this.state.activeLink === "/what-are-derivatives" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-are-derivatives")} 
                                            to="/what-are-derivatives">What Are Derivatives?</Link>

                                            <Link className={this.state.activeLink === "/what-is-the-state-of-security-token-in-europe" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-the-state-of-security-token-in-europe")} 
                                            to="/what-is-the-state-of-security-token-in-europe">What is the state of security token in Europe?</Link>

                                            <Link className={this.state.activeLink === "/what-is-markets-in-financial-directive-ii" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-markets-in-financial-directive-ii")} 
                                            to="/what-is-markets-in-financial-directive-ii">What is Markets In Financial Directive II (MiFID II)?</Link>

                                            <Link className={this.state.activeLink === "/what-is-the-state-of-security-token-in-liechtenstein" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-the-state-of-security-token-in-liechtenstein")} 
                                            to="/what-is-the-state-of-security-token-in-liechtenstein">What is the state of security token in Liechtenstein?</Link>


                                            <Link className={this.state.activeLink === "/the-state-of-security-tokens-in-germany" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/the-state-of-security-tokens-in-germany")} 
                                            to="/the-state-of-security-tokens-in-germany">The State Of Security Tokens In Germany</Link>
                                            
                                            <Link className={this.state.activeLink === "/what-is-the-state-of-security-tokens-in-switzerland" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-the-state-of-security-tokens-in-switzerland")} 
                                            to="/what-is-the-state-of-security-tokens-in-switzerland">What is the state of security tokens in Switzerland?</Link>
                                            
                                            <Link className={this.state.activeLink === "/what-is-the-state-of-security-tokens-in-malta" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-the-state-of-security-tokens-in-malta")} 
                                            to="/what-is-the-state-of-security-tokens-in-malta">What is the state of security tokens in Malta?</Link>
                                            
                                            <Link className={this.state.activeLink === "/what-is-the-state-of-security-tokens-in-estonia" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-the-state-of-security-tokens-in-estonia")} 
                                            to="/what-is-the-state-of-security-tokens-in-estonia">What is the state of security tokens in Estonia?</Link>

                                            <Link className={this.state.activeLink === "/what-is-the-state-of-security-tokens-in-france" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-the-state-of-security-tokens-in-france")} 
                                            to="/what-is-the-state-of-security-tokens-in-france">What is the state of security tokens in France?</Link>
                                            
                                            <Link className={this.state.activeLink === "/what-is-the-state-of-security-token-in-uk" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-the-state-of-security-token-in-uk")} 
                                            to="/what-is-the-state-of-security-token-in-uk">What is the state of security token in UK</Link>

                                            <Link className={this.state.activeLink === "/what-is-the-scenario-of-security-token-scenario-in-slovenia" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-the-scenario-of-security-token-scenario-in-slovenia")} 
                                            to="/what-is-the-scenario-of-security-token-scenario-in-slovenia">What is the scenario of security token scenario in Slovenia?</Link>

                                            <Link className={this.state.activeLink === "/what-is-the-state-of-security-tokens-in-ecuador" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-the-state-of-security-tokens-in-ecuador")} 
                                            to="/what-is-the-state-of-security-tokens-in-ecuador">What is the state of security tokens in Ecuador?</Link>

                                            <Link className={this.state.activeLink === "/security-token-offering-and-banking-security" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/security-token-offering-and-banking-security")} 
                                            to="/security-token-offering-and-banking-security">Security Token Offering And Banking Security</Link>
                                            

                                            <Link className={this.state.activeLink === "/how-does-the-security-token-work-with-the-financial-center" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/how-does-the-security-token-work-with-the-financial-center")} 
                                            to="/how-does-the-security-token-work-with-the-financial-center">How Does The Security Token Work With The Financial Center?</Link>
                                            
                                            <Link className={this.state.activeLink === "/tokenization-of-healthcare-assets" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/tokenization-of-healthcare-assets")} 
                                            to="/tokenization-of-healthcare-assets">Tokenization Of Healthcare Assets</Link>
                                            
                                            <Link className={this.state.activeLink === "/how-security-tokens-could-be-implemented-in-payment-systems" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/how-security-tokens-could-be-implemented-in-payment-systems")} 
                                            to="/how-security-tokens-could-be-implemented-in-payment-systems">How Security Tokens Could Be Implemented In Payment Systems?</Link>
                                            
                                            <Link className={this.state.activeLink === "/state-of-security-token-offerings-in-australia" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/state-of-security-token-offerings-in-australia")} 
                                            to="/state-of-security-token-offerings-in-australia">State of Security Token Offerings In Australia</Link>
                                            
                                            <Link className={this.state.activeLink === "/state-of-security-token-offering-in-asian-countries" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/state-of-security-token-offering-in-asian-countries")} 
                                            to="/state-of-security-token-offering-in-asian-countries">State Of Security Token Offering In Asian Countries</Link>
                                            
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>

                                <ExpansionPanel
                                    expanded={this.state.expanded === 'faqpanel3'}
                                    onChange={this.expandPanelChange('faqpanel3')}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Decentralize Exchange</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="faqleftmain">
                                        <Typography className="Faqlinks">

                                            <Link className={this.state.activeLink === "/what-is-decentralized-exchange" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-decentralized-exchange")} 
                                            to="/what-is-decentralized-exchange">What is decentralized exchange ?</Link>
                                            
                                            <Link className={this.state.activeLink === "/what-are-the-benefits-of-decentralized-exchange" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-are-the-benefits-of-decentralized-exchange")} 
                                            to="/what-are-the-benefits-of-decentralized-exchange">What are the benefits of decentralized exchange ?</Link>
                                            
                                            <Link className={this.state.activeLink === "/what-are-the-key-technical-specifications-of-the-decentralized-exchange" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-are-the-key-technical-specifications-of-the-decentralized-exchange")} 
                                            to="/what-are-the-key-technical-specifications-of-the-decentralized-exchange">What are the key technical specifications of the decentralized exchange ?</Link>
                                            
                                            <Link className={this.state.activeLink === "/how-businesses-could-deploy-a-decentralized-exchange" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/how-businesses-could-deploy-a-decentralized-exchange")} 
                                            to="/how-businesses-could-deploy-a-decentralized-exchange">How businesses could deploy a decentralized exchange ?</Link>
                                            
                                            <Link className={this.state.activeLink === "/the-growth-and-potential-of-decentralized-exchanges" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/the-growth-and-potential-of-decentralized-exchanges")} 
                                            to="/the-growth-and-potential-of-decentralized-exchanges">The growth and potential of decentralized exchanges.</Link>
					    
                                            <Link className={this.state.activeLink === "/what-is-atomic-swap-and-how-it-is-used-in-decentralized-exchange-development" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-atomic-swap-and-how-it-is-used-in-decentralized-exchange-development")} 
                                            to="/what-is-atomic-swap-and-how-it-is-used-in-decentralized-exchange-development">What is atomic swap and how it is used in decentralized exchange development ?</Link>
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel
                                    expanded={this.state.expanded === 'faqpanel4'}
                                    onChange={this.expandPanelChange('faqpanel4')}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>P2P Exchange</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="faqleftmain">
                                        <Typography className="Faqlinks">
                                            <Link className={this.state.activeLink === "/benefits-of-P2P-exchange" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/benefits-of-P2P-exchange")} 
                                            to="/benefits-of-P2P-exchange">Benefits of P2P Exchange</Link>
                                            
                                            <Link className={this.state.activeLink === "/market-prediction-for-cryptocurrency-exchange" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/market-prediction-for-cryptocurrency-exchange")} 
                                            to="/market-prediction-for-cryptocurrency-exchange">Market prediction for Cryptocurrency Exchange</Link>
                                            
                                            <Link className={this.state.activeLink === "/technology-of-P2P-exchange-and-earning-opportunity" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/technology-of-P2P-exchange-and-earning-opportunity")} 
                                            to="/technology-of-P2P-exchange-and-earning-opportunity">Technology of P2P Exchange and Earning Opportunity</Link>
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>

                                <ExpansionPanel
                                    expanded={this.state.expanded === 'faqpanel5'}
                                    onChange={this.expandPanelChange('faqpanel5')}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Market Capitalization</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="faqleftmain">
                                        <Typography className="Faqlinks">
                                            <Link className={this.state.activeLink === "/what-is-marketing-capitalization-of-cryptocurrency-exchange" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-marketing-capitalization-of-cryptocurrency-exchange")} 
                                            to="/what-is-marketing-capitalization-of-cryptocurrency-exchange">What is marketing capitalization of cryptocurrency exchange?</Link>
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>

                                <ExpansionPanel
                                    expanded={this.state.expanded === 'faqpanel6'}
                                    onChange={this.expandPanelChange('faqpanel6')}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Alternative Trading Systems</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="faqleftmain">
                                        <Typography className="Faqlinks">
                                            <Link className={this.state.activeLink === "/what-is-an-alternative-trading-system" ? "faqactive" : ""} 
                                            onClick={() => this.linkActive("/what-is-an-alternative-trading-system")} 
                                            to="/what-is-an-alternative-trading-system">What Is An Alternative Trading System?</Link>
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                        </div>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
