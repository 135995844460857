import React, { Component } from 'react';
import {Row, Col} from 'reactstrap';
import Dedicated from '../../Images/Dedicated.png';
import BankingGradeSecurity from '../../Images/BankingGradeSecurity.png';
import YearsExperience from '../../Images/YearsExperience.png';
import ConvergedExchangeSolution from '../../Images/ConvergedExchangeSolution.png';

import Certificate from "../Certificate/index";

export default class CryptoSection9 extends Component {
  render() {
    return (
        <div className="WhyChooseExchange">
        <Row>
            <Col sm={12}>
                <div className="WhyChooseTitle">
                    <h2>Why Choose Espay For Exchange Development?</h2>
                    <p>Espay is the fintech software home. We focus completely on financial technology solutions. We 
                        develop exchange software that aids financial companies to run their trading business.</p>
                </div>
            </Col>
        </Row>          
        <Row>
            <Col sm={3}>                         
                <div className="WhyChoosebox">
                    <img src={Dedicated} alt="100+ Dedicated IT Team" title="100+ Dedicated IT Team" />
                    <p>100+ Dedicated IT Team</p>
                </div>
            </Col>                   
            <Col sm={3}>                         
                <div className="WhyChoosebox">
                    <img src={BankingGradeSecurity} alt="Banking Grade Security" title="Banking Grade Security" />
                    <p>Banking Grade Security</p>
                </div>
            </Col>
            <Col sm={3}>                         
                <div className="WhyChoosebox">
                    <img src={YearsExperience} alt="10+ Years of Experience" title="10+ Years of Experience" />
                    <p>10+ Years of Experience</p>
                </div>
            </Col>
            <Col sm={3}>                         
                <div className="WhyChoosebox">
                    <img src={ConvergedExchangeSolution} alt="Converged Exchange Solution" title="Converged Exchange Solution" />
                    <p>Converged Exchange Solution</p>
                </div>
            </Col>
        </Row>   
        <div className="mt-50">
            <Certificate />
        </div>
    </div>
    )
  }
}
