import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import RoundFinancialInstitutional from '../../Images/centralized/RoundFinancialInstitutional.png';
import RoundCorporateInvestors from '../../Images/centralized/RoundCorporateInvestors.png';
import Roundcoinowners from '../../Images/centralized/Roundcoinowners.png';
import RoundSolutions from '../../Images/centralized/RoundSolutions.png';
import Roundcrypto from '../../Images/centralized/Roundcrypto.png';
import RoundForexInvestor from '../../Images/centralized/RoundForexInvestor.png';

class solution_for_centralized_section3 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12">
                    <div className="Centralizedtitle">
                        <h2>Ideal Centralized Exchange Solution For</h2>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="2" className="CentralizedIdealService">
                    <img src={RoundFinancialInstitutional} alt="" title=""/>
                    <p>Financial Institutional</p>
                </Col>
                <Col sm="2" className="CentralizedIdealService">
                        <img src={RoundCorporateInvestors} alt="Corporate Investors" title="Corporate Investors" />
                        <p>Corporate Investors</p>
                </Col>
                <Col sm="2" className="CentralizedIdealService">
                        <img src={Roundcoinowners} alt="New Coin Owners" title="New Coin Owners" />
                        <p>New Coin Owners</p>
                </Col>
                <Col sm="2" className="CentralizedIdealService">
                        <img src={RoundSolutions} alt="Solutions for Startups" title="Solutions for Startups" />
                        <p>Solutions for Startups</p>
                </Col>
                <Col sm="2" className="CentralizedIdealService">
                        <img src={Roundcrypto} alt="Niche Crypto exchangers" title="Niche Crypto exchangers" />
                        <p>Niche Crypto exchangers</p>
                </Col>
                <Col sm="2" className="CentralizedIdealService">
                        <img src={RoundForexInvestor} alt="Forex Investor" title="Forex Investor" />
                        <p>Forex Investor</p>
                </Col>
            </Row>
        </div>
      );
    }
}

export default solution_for_centralized_section3;