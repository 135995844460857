import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import scrollToComponent from 'react-scroll-to-component';
import ScrollUpButton from "react-scroll-up-button";

import MarketCapitalization from '../../Images/faq/AlternateTradingSystem.png';

export default class AlternateTradingSystem extends Component {
  
  constructor(props) {
    super(props);
    this.scrollToTopWithCallback = this.scrollToTopWithCallback.bind(this)
  }

  componentDidMount() {
    scrollToComponent(this.innerfaqcontent, { offset: -60, align: 'top', duration: 500, ease:'inExpo'});
  }

  scrollToTopWithCallback() {
    let scroller = scrollToComponent(this.Violet, { offset: -75, align: 'top', duration: 1500});
    scroller.on('end', () => console.log('Scrolling end!') );
  }

  render() {
    return (
      <div className='main'>
       <h2 className="topinnertitle">Alternative Trading Systems</h2>
            <div className="button_groupmain">
            <button className="button-faq" onClick={this.scrollToTopWithCallback}>
            1) What Is An Alternative Trading System?</button>
            
            </div>
        <Container>
            <section className='innerfaq1content' ref={(section) => { this.Violet = section; }}>
                <Row>
                    <Col sm={{size:11,offset:1}} className="faqdiffbor">
                        <h4>1) What is marketing capitalization of cryptocurrency exchange?</h4>
                        <ul>
                            <li>
                                <p>ATS is actually a platform for matching buy and sell orders of its users. An ATS usually registers as a broker-dealer. It serves a very important role in providing investors alternative means to access liquidity. In early stage startups, equity positions have little or no liquidity, preventing investors from realizing a return on their investment for several years. Bringing security tokens and ATS together may help solve this issue. Investors will be able to buy or sell security tokens that have been created by issuers who used an existing exemption, Once registered ATS platforms are available. Investors may still be subject to holding periods as per the Rule 144 of SEC Guidelines, but a one year mandatory holding period is far better than a 3-5 year period of lack of liquidity.</p>
                            </li>
                           
                            <img className="img-fluid" src={MarketCapitalization} title="Market Capitalization" alt="Market Capitalization" />
                           
                            <li className="mt-30">
                                <p>For cryptocurrency exchanges that would like to list tokens that are classified as securities, registering as an ATS will help keep them on the path of compliance. This includes registering with the SEC as a broker-dealer and becoming a member of a self-regulatory organization (SRO), such as FINRA. They will also be required to file applicable paperwork, such as Form ATS. </p>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </section>
        </Container>
       
        
        <ScrollUpButton className="ScrollUpButton"/>

      </div>
    )
  }
}
