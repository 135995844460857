import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import flagargentina from '../../Images/MarketingResearch/flag-argentina.jpg';
import flagaustralia from '../../Images/MarketingResearch/flag-australia.jpg';
import flagbrazil from '../../Images/MarketingResearch/flag-brazil.jpg';
import flagcanada from '../../Images/MarketingResearch/flag-canada.jpg';
import flagcaymanIslands from '../../Images/MarketingResearch/flag-caymanIslands.jpg';
import flagcyprus from '../../Images/MarketingResearch/flag-cyprus.jpg';
import flagestonia from '../../Images/MarketingResearch/flag-estonia.jpg';
import flagfrance from '../../Images/MarketingResearch/flag-france.jpg';
import flaggermany from '../../Images/MarketingResearch/flag-germany.jpg';
import flaggibraltar from '../../Images/MarketingResearch/flag-gibraltar.jpg';
import flaghongkong from '../../Images/MarketingResearch/flag-hongkong.jpg';
import flagindia from '../../Images/MarketingResearch/flag-india.jpg';
import flagindonesia from '../../Images/MarketingResearch/flag-indonesia.jpg';
import flagisrael from '../../Images/MarketingResearch/flag-israel.jpg';
import flagjamaica from '../../Images/MarketingResearch/flag-jamaica.jpg';
import flagjapan from '../../Images/MarketingResearch/flag-japan.jpg';
import flaglithuania from '../../Images/MarketingResearch/flag-lithuania.jpg';
import flagmalaysia from '../../Images/MarketingResearch/flag-malaysia.jpg';
import flagmalta from '../../Images/MarketingResearch/flag-malta.jpg';
import flagmexico from '../../Images/MarketingResearch/flag-mexico.jpg';
import flagphilippines from '../../Images/MarketingResearch/flag-philippines.jpg';
import flagrussia from '../../Images/MarketingResearch/flag-russia.jpg';
import flagsingapore from '../../Images/MarketingResearch/flag-singapore.jpg';
import flagsouthafrica from '../../Images/MarketingResearch/flag-southafrica.jpg';
import flagswitzerland from '../../Images/MarketingResearch/flag-switzerland.jpg';
import flagtaiwan from '../../Images/MarketingResearch/flag-taiwan.jpg';
import flagthailand from '../../Images/MarketingResearch/flag-thailand.jpg';
import flaguae from '../../Images/MarketingResearch/flag-uae.jpg';
import flagunitedkingdom from '../../Images/MarketingResearch/flag-unitedkingdom.jpg';
import flagunitedstates from '../../Images/MarketingResearch/flag-unitedstates.jpg';


export default class ListCountries extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm={{size:8,offset:2}}>
                        <div className="liscountrietitle">
                            <h3>List of Countries Welcoming Security Token Exchanges and Their Regulations</h3>
                        </div>
                    </Col>
                </Row>
                <Row className="column-box">
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagargentina} alt="Argentina" title="Argentina" />
                            </div>
                            <div className="countries-content">
                                <span>Argentina</span>
                                <p>No specific regulations.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagaustralia} alt="Australia" title="Australia" />
                            </div>
                            <div className="countries-content">
                                <span>Australia</span>
                                <p>Established a regulatory sandbox for Fintech startups.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagbrazil} alt="Brazil" title="Brazil" />
                            </div>
                            <div className="countries-content">
                                <span>Brazil</span>
                                <p>Regulated under article 2 of Law n. 6.385/76.</p>
                            </div>
                        </div>
                    </Col>
                </Row> 
                <Row className="column-box">
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagcanada} alt="Canada" title="Canada" />
                            </div>
                            <div className="countries-content">
                                <span>Canada</span>
                                <p>Subject to Canadian securities laws on case-by-case basis.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagcaymanIslands} alt="Cayman Islands" title="Cayman Islands" />
                            </div>
                            <div className="countries-content">
                                <span>Cayman Islands</span>
                                <p>Regulated by Cayman Islands Monetary Authority (CIMA).</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagcyprus} alt="Cyprus" title="Cyprus" />
                            </div>
                            <div className="countries-content">
                                <span>Cyprus</span>
                                <p>Regulated by Cyprus Securities and Exchange Commission.</p>
                            </div>
                        </div>
                    </Col>
                </Row> 

                <Row className="column-box">
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagestonia} alt="Estonia" title="Estonia" />
                            </div>
                            <div className="countries-content">
                                <span>Estonia</span>
                                <p>Regulated by Estonian Financial Supervisory Authority (EFSA). All listing prospectuses must adhere to the Prospectus Regulation 809/2004 and EU prospectus directive 2003/71.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagfrance} alt="France" title="France" />
                            </div>
                            <div className="countries-content">
                                <span>France</span>
                                <p>The Authorité des Marchés Financiers (AMF) introduced new ICO regulations in 2019.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flaggermany} alt="Germany" title="Germany" />
                            </div>
                            <div className="countries-content">
                                <span>Germany</span>
                                <p>No specific regulations, but companies must adhere to existing laws such as Banking Act, Investment Act, Securities Trading Act, Payment Services Supervision Act, and Prospectus Acts.</p>
                            </div>
                        </div>
                    </Col>
                </Row>   

                <Row className="column-box">
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flaggibraltar} alt="Gibraltar" title="Gibraltar" />
                            </div>
                            <div className="countries-content">
                                <span>Gibraltar</span>
                                <p>Subject to specific proposals for the regulation of token sales by the Government.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flaghongkong} alt="Hong Kong" title="Hong Kong" />
                            </div>
                            <div className="countries-content">
                                <span>Hong Kong</span>
                                <p>Securities and Futures Commission (SFC) is working on regulatory guidelines.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagindia} alt="India" title="India" />
                            </div>
                            <div className="countries-content">
                                <span>India</span>
                                <p>No specific laws yet. Securities and Exchange Board of India (SEBI) is in the process of setting up regulatory framework.</p>
                            </div>
                        </div>
                    </Col>
                </Row> 

                <Row className="column-box">
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagindonesia} alt="Indonesia" title="Indonesia" />
                            </div>
                            <div className="countries-content">
                                <span>Indonesia</span>
                                <p>Futures Exchange Supervisory Board (Bappebti) is working on regulations for cryptocurrency exchanges and related taxation.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagisrael} alt="Israel" title="Israel" />
                            </div>
                            <div className="countries-content">
                                <span>Israel</span>
                                <p>Israel Securities Authority offers a clear definition of what separates a utility token from a security.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagjamaica} alt="Jamaica" title="Jamaica" />
                            </div>
                            <div className="countries-content">
                                <span>Jamaica</span>
                                <p>No specific regulations.</p>
                            </div>
                        </div>
                    </Col>
                </Row> 

                <Row className="column-box">
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagjapan} alt="Japan" title="Japan" />
                            </div>
                            <div className="countries-content">
                                <span>Japan</span>
                                <p>Japan’s Financial Services Agency (FSA) will issue new regulations soon.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flaglithuania} alt="Lithuania" title="Lithuania" />
                            </div>
                            <div className="countries-content">
                                <span>Lithuania</span>
                                <p>The Lithuanian Ministry of Finance has already issued crystal clear regulations covering crucial aspects of STO and ICO such as taxation, accounting, issuance, and compliance.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagmalaysia} alt="Malaysia" title="Malaysia" />
                            </div>
                            <div className="countries-content">
                                <span>Malaysia</span>
                                <p>Rigorous regulations introduced recently.</p>
                            </div>
                        </div>
                    </Col>
                </Row> 

                <Row className="column-box">
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagmalta} alt="Malta" title="Malta" />
                            </div>
                            <div className="countries-content">
                                <span>Malta</span>
                                <p>Offers the most clear regulatory guidance. Three main laws include Malta Digital Innovation Authority Act (MDIA), Innovative Technological Arrangement and Services Act (ITAS Act), and Virtual Financial Asset Act (VFA Act).</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagmexico} alt="Mexico" title="Mexico" />
                            </div>
                            <div className="countries-content">
                                <span>Mexico</span>
                                <p>A new Fintech bill was signed into law in 2018.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagphilippines} alt="Philippines" title="Philippines" />
                            </div>
                            <div className="countries-content">
                                <span>Philippines</span>
                                <p>The Philippines Securities and Exchange Commission (PSEC) is close to issuing new initial coin offering (ICO) regulations.</p>
                            </div>
                        </div>
                    </Col>
                </Row> 

                <Row className="column-box">
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagrussia} alt="Russia" title="Russia" />
                            </div>
                            <div className="countries-content">
                                <span>Russia</span>
                                <p>Offers the most clear regulatory guidance. Three main laws include Malta Digital Innovation Authority Act (MDIA), Innovative Technological Arrangement and Services Act (ITAS Act), and Virtual Financial Asset Act (VFA Act).</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagsingapore} alt="Singapore" title="Singapore" />
                            </div>
                            <div className="countries-content">
                                <span>Singapore</span>
                                <p>A new Fintech bill was signed into law in 2018.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagsouthafrica} alt="South Africa" title="South Africa" />
                            </div>
                            <div className="countries-content">
                                <span>South Africa</span>
                                <p>The Philippines Securities and Exchange Commission (PSEC) is close to issuing new initial coin offering (ICO) regulations.</p>
                            </div>
                        </div>
                    </Col>
                </Row> 

                <Row className="column-box">
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagswitzerland} alt="Switzerland" title="Switzerland" />
                            </div>
                            <div className="countries-content">
                                <span>Switzerland</span>
                                <p>Regulated by the Swiss Financial Market Supervisory Authority (FINMA).</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagtaiwan} alt="Taiwan" title="Taiwan" />
                            </div>
                            <div className="countries-content">
                                <span>Taiwan</span>
                                <p>In the process of defining national regulatory standards.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagthailand} alt="Thailand" title="Thailand" />
                            </div>
                            <div className="countries-content">
                                <span>Thailand</span>
                                <p>No clear regulatory guidelines. However, Thailand’s Securities and Exchange Commission (SEC) has reportedly clarified how existing laws can apply to crypto securities.</p>
                            </div>
                        </div>
                    </Col>
                </Row> 

                <Row className="column-box">
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flaguae} alt="United Arab Emirates (UAE)" title="United Arab Emirates (UAE)" />
                            </div>
                            <div className="countries-content">
                                <span>United Arab Emirates (UAE)</span>
                                <p>UAE's Securities and Commodities Authority (SCA) will introduce Initial Coin Offering (ICO) regulations in 2019.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagunitedkingdom} alt="United Kingdom" title="United Kingdom" />
                            </div>
                            <div className="countries-content">
                                <span>United Kingdom</span>
                                <p>Financial Conduct Authority (FCA) is yet to issue firm regulations.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="countries-box">
                            <div className="countries-flag">
                                <img src={flagunitedstates} alt="United States" title="United States" />
                            </div>
                            <div className="countries-content">
                                <span>United States</span>
                                <p>Currently, security tokens are regulated under SEC's Regulations D, S, and A+.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
