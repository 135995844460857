import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";

class site extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm="12">                         
                    <div className="sitemapbanner">
                        <h1>Sitemap</h1>
                    </div>
                    <Row>
                    <Col sm="6">
                        <ul className="exchangestitle">
                            <li><Link to="/crypto-exchange-software">Crypto Exchange</Link></li>
                            <ul className="submenulist">
                                <li><Link to="/centralized-exchange-solutions">Centralized</Link></li>
                                <ul>
                                    <li><Link to="/white-label-crypto-exchange-software">Whitelabel</Link></li>
                                    <li><Link to="/centralized-business-exchange-software">Standard</Link></li>
                                    <li><Link to="/centralized-enterprise-exchange-software">Enterprise</Link></li>
                                </ul>

                                <li><Link to="/decentralized-crypto-exchange-software">Decentralized</Link></li>
                                <ul>
                                    <li><Link to="/hybrid-crypto-exchange-software">Hybrid</Link></li>
                                </ul>
                                <li><Link to="/p2p-exchange-software-development">P2P Exchange</Link></li>
                            </ul> 
                        </ul>
                    </Col>
                    <Col sm="6">
                         <ul className="exchangestitle">
                            <li><Link to="/security-token-exchange">Security Token Exchange</Link></li>
                        </ul>
                    </Col>
                    </Row>
                    <Row>
                    <Col sm="12">
                        <ul className="exchangestitle">
                            <li><Link to="/">Features</Link></li>
                        </ul>
                    </Col>
                    <Col sm="6">
                        <ul className="exchangestitle">
                            <ul className="submenulist">
                                <li><Link to="/">Front End Features</Link></li>
                                <ul>
                                    <li><Link to="/security-modules-and-features">Security Modules And Features</Link></li>
                                    <li><Link to="/exchange-ui-design">Exchange UI Design</Link></li>
                                    <li><Link to="/Trading-dashboard">Trading Dashboard</Link></li>
                                    <li><Link to="/dyanmic-cms-for-managing">Dynamic CMS Management</Link></li>
                                    <li><Link to="/mobile-trading-platform">Mobile Trading Platform</Link></li>
                                    <li><Link to="/mass-communication-features">Mass Communication</Link></li>
                                    <li><Link to="/trading-chart-for-exchange">Trading Chart</Link></li>
                                    <li><Link to="/wallet-management">Wallet Management</Link></li>
                                    <li><Link to="/marketing-features">Marketing Features</Link></li>
                                    <li><Link to="/trading-market-setup">Trading Market Setup</Link></li>
                                </ul>
                            </ul> 
                        </ul>
                    </Col>
                    <Col sm="6">
                         <ul className="exchangestitle">
                            <ul className="submenulist">
                                <li><Link to="/p2p-exchange-software-development">Back End Features</Link></li>
                                <ul>
                                    <li><Link to="/integrations">API Integrations</Link></li>
                                    <li><Link to="/trading-report-management">Trading Report Management</Link></li>
                                    <li><Link to="/currency-and-pairs">Currency Pair Managment</Link></li>
                                    <li><Link to="/trading-and-reconciliation">Trading And Reconciliation</Link></li>
                                </ul>
                            </ul> 
                        </ul>
                    </Col>
                    </Row>

                    <Row>
                        <Col sm="4">
                            <ul className="exchangestitle">
                                <li><Link to="/">Company</Link></li>
                            </ul>
                            <ul className="exchangestitle">
                                <ul className="submenulist">
                                    <li><Link to="/vision-mission">Vision & Mission</Link></li>
                                    <li><Link to="/about-us">About Us</Link></li>
                                </ul>
                            </ul>
                        </Col>
                        <Col sm="4">
                            <ul className="exchangestitle">
                                <li><a href="https://espay.exchange/blog/">Blog Info</a></li>
                            </ul>
                            <ul className="exchangestitle">
                                <ul className="submenulist">
                                    <li><a href="https://espay.exchange/blog/">Blog</a></li>
                                </ul>
                            </ul>
                        </Col>
                        <Col sm="4">
                            <ul className="exchangestitle">
                                <li><Link to="/contact-us">Contact Info</Link></li>
                            </ul>
                            <ul className="exchangestitle">
                                <ul className="submenulist">
                                    <li><Link to="/contact-us">Get in Touch</Link></li>
                                </ul>
                            </ul>
                        </Col>
                    </Row>
                    

                    
                   
                   
                </Col>
            </Row>   
        </div>
      );
    }
}

export default site;