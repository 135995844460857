import React, { Component } from 'react'
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";

import Mainbanner from './banner';


export default class index extends Component {
  render() {
    return (
      <div>
        <Helmet>
            <title>Book Your Exchange Software Demo</title>
            <meta name="description" content=" Book your cryptocurrency exchange software demo with Espay-X to see how it functions and how it could build a profitable business."/>
        </Helmet>

        <Container fluid={true} className="blur">
            <Container>
                <Mainbanner />
            </Container>
        </Container>

     
      </div>
    )
  }
}

