import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";

class features_standard_section2 extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm="12">
                    <div className="standardtitle">
                        <h2>Bitcoin & Cryptocurrency Exchange Features</h2>
                        <p>Espay offers the best as well as most valuable services to our customers with exceptional technical expertise.</p>
                    </div>
                </Col>
            </Row> 
            <Row>   
                <Col sm="4">
                    <div className="Standardfeaturesbox">
                        <h3>Set up on your own server</h3>
                        <p>We are delivering exchange software that can be customized to suit your business model and will set up on
                            your own server with your brand name.</p>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="Standardfeaturesbox">
                        <h3>Third Party APIs Configuration</h3>
                        <p>Our ready-made Bitcoin exchange platform is completely tested and ready-to-use. Software integration with 
                            third-party businesses through the API.</p>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="Standardfeaturesbox">
                        <h3>Customized UI & UX</h3>
                        <p>Every business contains a unique need, and we tend to offer the most flexible and customized UI/UX with our
                        <Link title="Crypto Exchange Development Solutions" to="/Page_not_found">
                        Crypto exchange development solutions</Link> for each customer.</p>
                    </div>
                </Col>                   
            </Row>
            <Row>   
                <Col sm="4">
                    <div className="Standardfeaturesbox">
                        <h3>Buy and Sell order Book</h3>
                        <p>Our exchange software offer order book that provides information on buy and sell orders for the 
                            supported digital currencies on the platform.</p>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="Standardfeaturesbox">
                        <h3>Trade Matching Engine</h3>
                        <p>Our trade matching engine matches up bids and offers complete trades with the use of one or more algo 
                            which assign trades between competing bids and offers at the same price.</p>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="Standardfeaturesbox">
                        <h3>Integrated Web Crypto Wallet</h3>
                        <p>The exchange software comprises a multi-currency wallet for every currency implemented within your 
                            trading platform.</p>
                    </div>
                </Col>                   
            </Row>
        </div>
      );
    }
}

export default features_standard_section2;