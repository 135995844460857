import React, { Component,Fragment } from 'react';
import { Container, Row, Col} from 'reactstrap';
//import { Link } from "react-router-dom";
import creditrisk from '../../Images/Derivatives/creditrisk.png';
import Liquidityrisk from '../../Images/Derivatives/Liquidityrisk.png';
import Marketrisk from '../../Images/Derivatives/Marketrisk.png';


export default class Buildtraders extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col md={10} className="mx-auto">  
                            <div className="cryderivativestitle">
                                <h2>Why Cryptocurrency Derivatives Exchange Development? You See, Crypto Trading Is Hit With Major Challenges</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <div className="buildtradingbox">
                                <img src={creditrisk} alt="Credit risk" title="Credit risk" />
                                <h3>Credit risk</h3>
                                <p>Credit risk occurs when any of the parties fail to fulfill the contractual obligation.</p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="buildtradingbox">
                                <img src={Liquidityrisk} alt="Liquidity risk" title="Liquidity risk" />
                                <h3>Liquidity risk</h3>
                                <p>Liquidity risk is financial risk resulting from uncertain cash crunching.</p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="buildtradingbox">
                                <img src={Marketrisk} alt="Market risk" title="Market risk" />
                                <h3>Market risk</h3>
                                <p>Underlying asset price fluctuations lead to market risk.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
