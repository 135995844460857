import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import API_Integrations from'../../Images/Landing/API_Integrations.png';
import Trading_Report_Management from '../../Images/Landing/Trading_Report_Management.png';
import Currency_Pair_Managment from '../../Images/Landing/Currency_Pair_Managment.png';
import Trading_And_Reconciliation from '../../Images/Landing/Trading_And_Reconciliation.png';

class cryptocurrency_section2 extends Component {
    render() {
      return (
        <div className="Artificial_Intelligence_front"> 
            <Row>
                <Col sm="12">
                    <div className="Artificial_Intelligence_Info">
                        <h4>Artificial Intelligence Based Back End That Ensures Smooth Business Execution</h4>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="3">                         
                    <div className="Artificial_Intelligence_Box">
                    <Link to="integrations"><img src={API_Integrations} alt="API Integrations" title="API Integrations" /></Link>
                        <span><Link to="integrations">API Integrations</Link></span>                      
                    </div>
                </Col>
                <Col sm="3">                         
                    <div className="Artificial_Intelligence_Box">
                    <Link to="trading-report-management"><img src={Trading_Report_Management} alt="Trading Report Management" title="Trading Report Management" /></Link>
                        <span><Link to="trading-report-management">Trading Report Management</Link></span>
                        
                    </div>
                </Col>
                <Col sm="3">                         
                    <div className="Artificial_Intelligence_Box">
                    <Link to="currency-and-pairs"><img src={Currency_Pair_Managment} alt="Currency Pair Managment" title="Currency Pair Managment" /></Link>
                        <span><Link to="currency-and-pairs">Currency Pair Managment</Link></span>
                        
                    </div>
                </Col>
                <Col sm="3">                         
                    <div className="Artificial_Intelligence_Box">
                    <Link to="trading-and-reconciliation"><img src={Trading_And_Reconciliation} alt="Trading And Reconciliation" title="Trading And Reconciliation" /></Link>
                        <span><Link to="trading-and-reconciliation">Trading And Reconciliation</Link></span>
                        
                    </div>
                </Col>
            </Row>
        </div>
      );
    }
}

export default cryptocurrency_section2;