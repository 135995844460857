import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Dedicated from '../../Images/Dedicated.png';
import BankingGradeSecurity from '../../Images/BankingGradeSecurity.png';
import YearsExperience from '../../Images/YearsExperience.png';
import ConvergedExchangeSolution from '../../Images/ConvergedExchangeSolution.png';

import whychoose from './HomeData';

class Section5 extends Component {
    render() {
        const whychoose_h2       = whychoose[5].whychoosedata.fields.whychoose_h2;
        const whychoose_dis      = whychoose[5].whychoosedata.fields.whychoose_dis;
        const whychoose_box1     = whychoose[5].whychoosedata.fields.whychoose_box1;
        const whychoose_box2     = whychoose[5].whychoosedata.fields.whychoose_box2;
        const whychoose_box3     = whychoose[5].whychoosedata.fields.whychoose_box3;
        const whychoose_box4     = whychoose[5].whychoosedata.fields.whychoose_box4;
      return (
        <div className="WhyChooseExchange">
            <Row>
                <Col sm="12">
                    <div className="WhyChooseTitle">
                        <h2>{whychoose_h2}</h2>
                        <p>{whychoose_dis}</p>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="3">                         
                    <div className="WhyChoosebox">
                        <img src={Dedicated} alt="100+ Dedicated IT Team" title="100+ Dedicated IT Team" />
                        <p>{whychoose_box1}</p>
                    </div>
                </Col>                   
                <Col sm="3">                         
                    <div className="WhyChoosebox">
                        <img src={BankingGradeSecurity} alt="Banking Grade Security" title="Banking Grade Security" />
                        <p>{whychoose_box2}</p>
                    </div>
                </Col>
                <Col sm="3">                         
                    <div className="WhyChoosebox">
                        <img src={YearsExperience} alt="10+ Years of Experience" title="10+ Years of Experience" />
                        <p>{whychoose_box3}</p>
                    </div>
                </Col>
                <Col sm="3">                         
                    <div className="WhyChoosebox">
                        <img src={ConvergedExchangeSolution} alt="Converged Exchange Solution" title="Converged Exchange Solution" />
                        <p>{whychoose_box4}</p>
                    </div>
                </Col>
            </Row>          
        </div>
      );
    }
}

export default Section5;