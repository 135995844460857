import React, { Component } from 'react';
import {Row, Col} from 'reactstrap';
import { Link } from "react-router-dom";
import StandardExchangeSolution from '../../Images/StandardExchangeSolution.png';

class packages_centralized_section6_1 extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm="7">
                    <img src={StandardExchangeSolution} className="centrlized-feature-Standard-img" alt="Standard Exchange Solution" title="Standard Exchange Solution" />
                </Col>
                <Col sm="5" className="mt-30">
                    <div className="CentralizedWhiteLabel">
                        <h2>Standard Exchange Solution</h2>
                        <hr/>
                        <ul>
                            <li>Set on your own Server</li>
                            <li>Plug & Play</li>
                            <li>Third Party APIs Configuration</li>
                            <li>Base Currency Setup</li>
                            <li>Customised UI & UX</li>
                            <li>12 Months Technical Support</li>
                        </ul>
                        <Link to="/contact-us">Scale UP</Link>
                    </div>
                </Col>
            </Row>
        </div>
      );
    }
}

export default packages_centralized_section6_1;