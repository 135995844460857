import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import HybridCryptoExchange from '../../Images/HybridCryptoExchange.png';
import Interestedenterpriseexchange1 from '../../Images/Interestedenterpriseexchange1.png';
import p2pExchangeSoftware from '../../Images/p2pExchangeSoftware.png';

class product_crypto_section6 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12">
                    <div className="SecurityTokentitle">
                        <h2>Our Other Security Token Exchange Offering</h2>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="4">
                    <div className="securityproductbox">
                        <Link title="Hybrid crypto Exchange" to="/hybrid-crypto-exchange-software">
                            <img className="img-fluid" src={HybridCryptoExchange} alt="Hybrid crypto Exchange" title="Hybrid crypto Exchange" />
                            <h3>Hybrid crypto Exchange</h3>
                            <p> Espay is delivering robust Hybrid crypto Exchange platforms for the users who need automatization,
                                    complex order structures, as well as speed.  </p>
                        </Link>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="securityproductbox">
                        <Link title="Enterprise Crypto Exchange" to="/centralized-enterprise-exchange-software">
                            <img className="img-fluid" src={Interestedenterpriseexchange1} alt="Enterprise Crypto Exchange" title="Enterprise Crypto Exchange" />
                            <h3>Enterprise Crypto Exchange</h3>
                            <p>We are a renowned Exchange software development company and specialize in offering secure & reliable
                                    solutions to setup your exchange successfully. </p>
                        </Link>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="securityproductbox">
                        <Link title="Peer to Peer Exchange" to="/p2p-exchange-software-development">
                            <img className="img-fluid" src={p2pExchangeSoftware} alt="Peer to Peer Exchange" title="Peer to Peer Exchange" />
                            <h3>Peer to Peer Exchange</h3>
                            <p>Espay is the best decentralized P2P cryptocurrency exchange solution provider having expert
                                    blockchain developers on board that delivers robust software for your customers where Individuals (buyers & sellers) are the only participants. </p>
                        </Link>
                    </div>
                </Col>
            </Row>
        </div>
      );
    }
}

export default product_crypto_section6;