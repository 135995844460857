import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';

import Advance from "../../Images/Leverage/Advance_margin.png";
import Initial from "../../Images/Leverage/initial_margin.png";
import Maintenance from "../../Images/Leverage/maintenance_margin.png";

export default class MarginLavraged extends Component {
  render() {
    return (
      <div>
        <div className="offtertrader-bg lavrage-CryptoExchange">
          <Row>
            <Col sm="12">
              <h2 className="">Margin Management of Leveraged Crypto Exchange</h2>
            </Col>
          </Row>
          <Row>
            <div className="col-sm-4 ">
              <div className="offerborder">
                <img src={Advance} alt="Advance Margin Support" title="Advance Margin Support" className="img-fluid " />
                <p><b>❏ Advance Margin Support </b></p>
                <p>Multiple Margin Calculation support with different strategies to be customized from like Portfolio Margining, Cross Margining and Isolated Margining</p>
              </div>
            </div>
          
            <div className="col-sm-4 ">
              <div className="offerborder">
                <img src={Initial} alt="Initial Margin Calculation" title="Initial Margin Calculation" className="img-fluid " />
                <p><b>❏ Initial Margin Calculation</b></p>
                <p>Automated Initial Margin Calculator to give the users a perfect idea on the allocation of funds with every order placement.</p>
              </div>
            </div>
          
            <div className="col-sm-4 ">
              <div className="offerborder">
                <img src={Maintenance} alt="Real Time Maintenance Margin" title="Real Time Maintenance Margin" className="img-fluid " />
                <p><b>❏ Real Time Maintenance Margin</b></p>
                <p>Active Dual Margin checks to avoid the liquidation and allocation of the margins to most liquid positions and help the traders to earn most out of the positions.</p>
              </div>
            </div>
          </Row>
        </div>

      </div>
    )
  }
}
