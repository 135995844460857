import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import cmsimg from '../../../../Images/CMS_based_Website_Development.png';

class dynamic_cms_mngt_section1 extends Component {
    render() {
      return (
        <div> 
            <Row> 
                <Col sm="6">
                    <div className="dynamiccmsmanagmenttitle">                   
                        <p>Dynamic CMS is developed to serve powerful customer experience for all the devices and through every channel. Dynamic CMS is developed to serve powerful customer experience for all the devices and through every channel.  With the help of CMS, a business can manage digital information on its website via making and maintaining content. Management of web content efficiently can offer useful business software and deliver the best outcomes as well as value.</p>
                        <p>Espay design and develop a dynamic content management system to manage the content of digital coin exchange. You can take a full control of the trading platform with the service provided by Espay. It is user-friendly and completely easy to get involved with. Expand your digital presence and reach new traders by implementing dynamic content for crypto trading globally.  </p>                         
                    </div>
                </Col>
                <Col sm="6" className="mt-30">
                    <img className="img-fluid" src={cmsimg} alt="Dynamic CMS" 
                    title="Dynamic CMS" />
                </Col>                  
            </Row>          
        </div>
      );
    }
}

export default dynamic_cms_mngt_section1;