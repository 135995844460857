import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

import IEOservice from "../../Images/IEO/whatsincludedinourIEOconsultingService.png";

export default class STOWebPage extends Component {
  render() {
    return (
      <div>
        <Row>   
            <Col sm="12">
                <div className="stoadviceitle">
                    <h2>What’s included in our IEO consulting service?</h2>
                    <p>Our IEO platform consulting solution includes all the steps from ideation to launch and post-IEO activities to ensure maximum investor interest.</p>
                </div>    
            </Col>
        </Row>  
       

        <Row>   
            <Col sm="7">
                <div className="stowebpagebox">
                    <h3>●	Pre-IEO validation</h3>
                </div>
                <div className="stowebpagebox">
                    <h3>●	Legal audit</h3>
                </div>
                <div className="stowebpagebox">
                    <h3>●	IEO marketing</h3>
                </div>
                <div className="stowebpagebox">
                    <h3>●	Technical development</h3>
                </div>
                <div className="stowebpagebox">
                    <h3>●	Financial management</h3>
                </div>
                <div className="stowebpagebox">
                    <h3>●	Platform listing</h3>
                </div>
                <div className="stowebpagebox">
                    <h3>●	IEO audit</h3>
                </div>
                <div className="stowebpagebox">
                    <h3>●	Post-IEO roadmap</h3>
                </div>
                

            </Col>
            <Col sm="5">
                <img src={IEOservice} alt="IEOservice" title="IEOservice" className="img-fluid" />
            </Col>
        </Row>    
      </div>
    )
  }
}
