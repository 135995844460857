import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";

import Mainbanner from './banner';
import Mainsoftwarestandard from './Std-Section1';
import Mainfeaturesstandard from'./Std-Section2';
import Mainplatformstandard from './Std-Section3';
import Mainofferingstandard from './Std-Section4';
import Mainplatform from './Std-Section5';
import Mainexhcangesoftware from './Std-Section6';
import Mainproduct from './Std-Section7';
import Blog from './Std-Section8';

import Certificate from '../Certificate/index';
import Faqmain from '../Faq/faq';

export default class index extends Component {

  componentDidMount() {
    ReactGA.initialize('UA-125025994-1')
    ReactGA.pageview(document.location.pathname)
    ReactGA.pageview(document.location.path)
  }
  
  scrollPositions = {}
  componentWillMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"
    }
    window.scrollTo(0, 0)
  }
  
  render() {
    return (

      <div>
        {this.props.children}
        <Helmet>
            <title>Bitcoin And Cryptocurrency Exchange Software - Espay Exchange</title>
            <meta name="description" content="Espay Exchange provides a secure, flexible and fully customizable crypto exchange software for businesses that want to create their own exchange for bitcoin or any digital cryptocurrency."/>
            <meta property="og:title" content="Bitcoin And Cryptocurrency Exchange Software - Espay Exchange" />
            <meta property="og:description" content="Espay Exchange provides a secure, flexible and fully customizable crypto exchange software for businesses that want to create their own exchange for bitcoin or any digital cryptocurrency." />
            <meta name="twitter:title" content="Bitcoin And Cryptocurrency Exchange Software - Espay Exchange" />
            <meta name="twitter:description" content="Espay Exchange provides a secure, flexible and fully customizable crypto exchange software for businesses that want to create their own exchange for bitcoin or any digital cryptocurrency." />
            <link rel="canonical" href="https://www.espay.exchange/centralized-business-exchange-software" />
        </Helmet>

        <Container fluid={true} className="standardExchange-bg">
          <Container>
            <Mainbanner />
          </Container>
        </Container>
        
        <Container> 
          <Mainsoftwarestandard />
        </Container>

        <Container fluid={true} className="standardfeatures-bg">
          <Container>
            <Mainfeaturesstandard />
          </Container>
        </Container>

        <Container>
          <Mainplatformstandard />
        </Container>

        <Container fluid={true} className="standardfeatures-bg">
          <Container>
            <Mainofferingstandard />
          </Container>
        </Container>

        <Container fluid={true} className="standardbranded-bg">
          <Container>
            <Mainplatform />
          </Container>
        </Container>

        <Container>
          <Mainexhcangesoftware />
        </Container>

        <Container fluid={true} className="standardexchangeproduct">
          <Container>
            <Mainproduct />
          </Container>
        </Container>

        <Container fluid={true} className="commonblogsection">
          <Container>
            <Blog />
          </Container>
        </Container>   

        <Container fluid={true}>
          <Container>
            <Certificate />
          </Container>
        </Container>  

        <Container fluid={true}>
          <Container>
            <h2 className="faqmaintitle">Frequently Asked Questions</h2>
            <Faqmain />
          </Container>
        </Container> 

        <ScrollUpButton className="ScrollUpButton"/>
      </div>
    )
  }
}

