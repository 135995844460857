import React, { Component, Fragment} from 'react';
import {Container,Row,Col} from 'reactstrap';
import { Link } from "react-router-dom";

import Graph from "../../Images/whitepaper/graph.jpg";
import Datadetails from "../../Images/whitepaper/datadetails.jpg";

export default class WhitepaperDetails extends Component {
    render() {
        return (
          <Fragment>
            <Container>
                <Row>
                    <Col md={12}>
                    <div className="whitepaperdetailsbox">
                        <h2>Disclaimer</h2>
                        <p>The given White Paper document has been formulated to
                        present the Espay Exchange white label cryptocurrency
                        exchange software potential participants of the platform and
                        those interested in contributing to its development. The
                        information set out below may not be exhaustive and does not
                        imply any contractual relationship. Its sole purpose is to
                        provide information to potential investors, so they may
                        determine whether they are willing to start up their own
                        platform with our <Link to="/">white label cryptocurrency exchange software.</Link></p>
                        
                        <p>No part of the given White Paper shall be deemed to constitute a prospectus or a solicitation for contributions, nor does it in any way pertain to an offering or a solicitation of an offer to buy any securities in any jurisdiction.</p>
                        <p>The given document is not composed in accordance with, nor subject to, any laws or regulations of any jurisdiction aimed at protecting contributors.</p>
                        <p>Certain statements, estimates, and financial information contained in the given White Paper constitute forward-looking statements. Such forward-looking statements or information deemed risks and uncertainties, which may cause actual events or results to differ materially from the estimates or results implied or expressed in such forward-looking statements.</p>
                        <p>The given English language white paper is the primary and sole official source of information about the Espay-X white label crypto exchange software. The information contained herein may be translated into other languages or used for establishing written or verbal communication channels with potential partners or investors.</p>
                        <p>Espay-X reserves the right to introduce changes to the given White Paper. In the case of a difference or differences between the versions of the document, the latest version of the White Paper published on our website shall prevail, and all previously published versions are considered to be invalid in all their iterations and representations.</p>
                        <p>Espay-X prohibits investors in the platform to avoid capital controls of any kind and does not allow them to conduct contributions in foreign assets of any manner.</p>
                        <p>The information contained in this White paper and on https://www.espay.exchange is of descriptive nature only. In particular, you should be advised that the 
    It is the sole responsibility of the reader and potential participant or contributor to ensure that participation in the in the setup of a white label cryptocurrency exchange is not prohibited under the applicable laws of said participant’s or contributor’s country of residence or domicile.</p>
                        <p>Espay-X will take all legal steps to maintain our white label software’s legitimacy in the quickly changing environment of regulatory policies. Espay exchange software will stay compliant to all regulatory laws applicable even in the future. Therefore, we have carefully created our product by close observation of the legal and jurisdictional compliances of various regions.</p>
                    </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <div className="whitepaperdetailsbox">
                            <h2>The Vision Behind Our Whitelabel Crypto Exchange Software</h2>
                            <p>In the past few years, the cryptocurrency market has been constantly growing. It has become a multibillion-dollar industry, appealing for investors who see it as a platform for making quick money. The sudden popularity of investing in new coins and tokens was undoubtedly inspired by stories of young millionaires who invested in Bitcoin back in the time when only few people believed this technology would be successful.</p>
                            <p>The vision of effortless income has attracted some expert investors, but also large numbers of inexperienced enthusiasts. A common weakness of these novice investors is that they are not familiar with how cryptocurrencies work since they are usually not interested in the underlying technology of blockchain. Moreover, as opposed to seasoned professional brokers, these new investors typically lack the knowledge of basic stock exchange principles.</p>
                            <p>However, these newcomers are the driving force behind the quickly growing market, fueling the largest share of the industry. Their number is steadily growing as the public awareness of cryptocurrencies and their benefits spreads across the globe.</p>
                            <strong>Our experience with different exchanges</strong>
                            <p>We tested different existing exchanges, because there was not a single one that would satisfy the needs of traders. We soon found out that as technology enthusiasts, we were deeply dissatisfied with the state of the cryptocurrency market. That is why we decided to do something about it.</p>
                            <p>We approached other users to ask them about their experience. Many of them shared our views on the unsatisfactory situation of white label crypto exchange platform. We have heard most of those complaints over and over. Let us summarize the most frequent deficiencies and concerns</p>
                            <ul>
                                <li>There is a huge need for a stable and secure cryptocurrency exchange system. Even the biggest players have stability issues, therefore their applications are not completely reliable. This is not acceptable in the trading world, where every millisecond can be worth a large sum of money.</li>
                                <li>To the big surprise of users, most cryptocurrency exchanges lack many functionalities of established exchange systems (Forex, commodities, etc.) that have been used for many years.</li>
                                <li>In the biggest exchanges offer only few of the most common coins and tokens.</li>
                                <li>Most exchanges impose restrictive daily and monthly transaction limits. The registration process is too lengthy. The whole registration process can take up to three months, during this time, investors can miss many opportunities.</li>
                                <li>Security is one of the biggest concerns across all the white label crypto exchange platform as there have been several instances of hackers’ attacks, thus compromising the security of users’ data, exchange’s key business information, and the hard-earned money of crypto traders.</li>
                            </ul>
                            <p>These issues make the users search for more than one exchange, force them to work with more products at once and cause unnecessary inconvenience. It seems that also expert users are missing one exchange that would contain all the basic features and “do them right”.</p>
                            <p>We are aware that it is challenging to succeed as a new project in a saturated market. However, the fact that Espay-X is not new in white label cryptocurrency exchange software on the market is actually one of its advantages. This allows us to evaluate several established projects and the usability of their features. Unlike the leading exchange platforms providers, Espay-X does not have to alter an already operational product and force users to acquire new usage patterns. Our product is a result of years of study of users and the business behaviour. This study has enabled us to power-pack our white label crypto exchange with the most advanced features.</p>
                            <p>Right from the start we are building an exchange with the expectation of large trading volumes, so the whole solution is based on a scalable microservices architecture.</p>
                            <p>The motto of our company is to maintain continuous technological and functional development of the product. Our goal is to create a balanced, easy-to-use product that would be suitable for traders of all levels and help you build your business network seamlessly, while also providing all the advanced features required by experienced investors.</p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <div className="whitepaperdetailsbox">
                            <h2>The Market For Whitelabel Cryptocurrency Exchange Software</h2>
                            <p>The Cryptocurrency market today comprises a multi-billion-dollar industry. Though, it is yet to be accepted by the masses as a tool for everyday exchanges, comparable to your standard fiat currency such as the dollar or euro. While currently holding a market share of more or less than 1.2% in current exchange currencies, it is expected to see rapid growth during upcoming years.</p>
                            <p>By our opinion the cryptocurrency market now is in the stage of "volatile growth", which began in late 2016 and most likely will continue in medium perspective. Sharp price fluctuations and corrections are the natural state for this stage of development.</p>
                            <p>We believe that cryptocurrency market is not an accidental phenomenon and it is based on fundamental factors, which is confirmed by the eight-year history of development which began in 2009 when Bitcoin - the cryptocurrency which change the usual means of payment and digital transactions – has appeared.</p>
                            <p>Over the last 8 years Bitcoin and other cryptocurrencies only by the fact of their existence and growing distribution refuted many negative forecasts, opinions and assessments. They debunked some of the myths about their insolvency, the lack of prospects and the impossibility of changing global financial relations.</p>
                            <p>Over the mentioned period of time many alternative cryptocurrencies appeared. Many of them are embodying completely different ideas and fundamentally new concepts of economic ties. There is a gradual official legitimization of the legal status for cryptocurrencies and the integration of digital transaction elements into the traditional economic systems.</p>
                            <p>Until November 2019, the total capitalization of all cryptocurrencies (according to coinmarketcap.com) increased by more than was close to $250 billion.</p>
                            <img className="img-fluid" src={Graph} alt="White Label Crypto exchange software" title="White Label Crypto exchange software" />
                            <div className="graphdetails">(Source: Coin Market Cap. Period: 1st Jan 2019 – 4th Nov 2019)</div>
                            <p>According to Coin Market Cap, here is the data of 24h trading volume for the top 10 cryptocurrency exchange.</p>
                            <img className="img-fluid" src={Datadetails} alt="White Label Cryptocurrency exchange software" title="White Label Cryptocurrency exchange software" />
                            <p></p><p>Based on these and many other factors and assessments, it is possible to come to a very concrete conclusion - staying away from this market means to standing still without seeing the prospect and to lose the profit which now is on its maximum level by the point of view of the market in the initial stage of growth.</p>
                            <p>However, we see the most profitable strategy in organizing and creating a white label crypto exchange platform, which will unite the functionalities of existing cryptocurrency exchanges, fill up the market gaps and will become the biggest cryptocurrency exchange software network with sharing orders between platforms. It is suitable for a variety of tasks and will be able to solve many tasks regardless of the specific user's activities and needs.</p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <div className="whitepaperdetailsbox">
                            <h2>Target group for white label crypto exchange</h2>
                            <p>As we already pointed out in the Introduction of this white paper, Espay-X is aiming at the large and steadily growing group of start-up investors as the main target group. To specify the target group, let’s sum up some of the main traits of a potential user</p>
                            <ul>
                                <li>A start up investor, who is searching for the best business opportunity and needs the help of a community of professionals.</li>
                                <li>An experienced user searching for a better trading platform to replace his current exchange.</li>
                                <li>The owner of a larger amount of cryptocurrencies, searching for a safe way to multiply his holdings via high interest P2P loans.</li>
                                <li>A holder, who wants to gain passive income from the transaction fees Sonata will divide between investors.</li>
                                <li>An exchange specialist, who knows how to maximize his earnings using a few different exchange platforms.</li>
                                <li>Strategy providers and asset managers, who manage asset portfolios of their clients.</li>
                            </ul>
                            <p>These user personas can and in most cases will mingle into more complex groups. That allows us to reach our audience with a wider variety of marketing tools, as their online presence in different channels overlaps.</p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <div className="whitepaperdetailsbox">
                            <h2>The Product: Espay-X Cryptocurrency Exchange Software</h2>
                            <strong>Espay-X White Label Solution</strong>
                            <p>Considering only the total volume of top 10 cryptocurrencies of we can easily estimate the total income of the exchanges. Considering the average fee of 0.05% the income of the exchanges was over 180$ millions / month only from transactions fee. </p>
                            <p>Espay-X White Label Solution will come up with a white label crypto exchange software, ready to go live from day one for anyone who will pay a small monthly fee. All the exchanges running on Espay-X network will have orders openly shared. This means if a customer acquires one exchange platform, this will have plenty of orders from day 1.</p>
                            <strong>Espay-X Mission</strong>
                            <p>The mission of Espay-X is to provide both website owners and users of white label crypto exchange platform, the biggest database of orders / users and achieve the biggest trading volume from cryptocurrency area. For the website owners: providing a fully functional platform, ready to use from day one, full of orders and transactions; and for the users: possibility to find the best deal from a huge amount of orders, low commissions, high trading experience, bounty and rewards programs.</p>
                            <b>What We Offer?</b>
                            <ul>
                                <li>Custom domain name</li>
                                <li>Custom logo and personalization</li>
                                <li>Possibility to set custom fee per transaction</li>
                                <li>Branded platform</li>
                                <li>Trading strategies that matches the current user behaviour in the market</li>
                            </ul>
                            <p>Whether you’re a professional trader or just starting out with cryptocurrencies, Espay-X will offer a wide variety of tools for engaging with digital asset markets. It will be straightforward to buy and sell cryptocurrencies through the Espay-X exchange, while additional features will make the platform capable of handling complex trading operations, as well.</p>
                            <strong>Working demo exchange platform can be booked with our product expert.</strong>
                            <b>Over 100 cryptocurrencies available in 2019</b>
                            <ul>
                                <li>Desktop and mobile friendly</li>
                                <li>Custom transaction fee (up to the customer if will apply low or high fees)</li>
                                <li>Capable of processing high volume of transactions per second low fees on deposits and withdrawals</li>
                                <li>Bounty and reward programs for the community</li>
                            </ul>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <div className="whitepaperdetailsbox">
                           <h2>The Espay-X White Label Crypto Exchange Platform Business Model</h2>
                           <p><b>Step 1.</b> Domain owner buys one of Espay-X’s packages with for a fully functional platform.</p>
                           <p><b>Step 2.</b> Website owner set his own fees and start personalizing the platform.</p>
                           <p><b>Step 3.</b> Website owner brings new users to his platform.</p>
                           <p><b>Step 4.</b> Those users start trading within Espay-X platform.</p>
                           <p><b>Step 5.</b> Espay-X platform calculates all the fees in the backend</p>
                           <p><b>Step 6.</b> Considering the package acquired the total fee accumulated will be splitted between website owner and Espay-X Company.</p>
                           <p>An important element of the whole system is the number of users who comes to trade on Espay-X platform. Considering that, we as a platform provider we will offer a big variety of cryptocurrencies, user friendly platform and a huge amount of orders and transactions in Espay-X network. On the other side the website owner (which can be a owner of a community, someone capable to bring thousands of users, ICO & IEO owners or a cryptocurrency owner) must promote their platform in order to increase their income. A simplified economic model is that website owner receive a part (depend of the package acquired) from transactions fee made by its users and Espay-X company gets the rest.</p>
                           <strong>The basis of the economic model of Espay-X is the following</strong>
                           <p><b>Transaction Volume</b> is the volume in USD transactions during one year</p>
                           <p><b>Number of website exchanges</b> is the number of websites which are running on our white label platform. Each website will have separate domain name and custom personalization.</p>
                           <p><b>Cryptocurrencies</b> average of cryptocurrencies available for trading in each month.</p>
                           <p><b>New cryptocurrency price</b> an average cost for adding a new cryptocurrency to the network (one time fee).</p>
                           <p><b>Customer percentage</b> average website owner income percent from transactions fee.</p>
                           <p><b>User transaction fee</b> average of transaction fee percentage applied by website owner to each transaction.</p>
                           <p><b>Espay-X percentage</b> is the average percentage from transactions fee from website owners.</p>
                           <p><b>Exchange platform price</b> is the average cost of the exchange platform for one year.</p>
                           <p><b>Hardware units</b> number of servers needed for running the Espay-X network.</p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <div className="whitepaperdetailsbox">
                            <h2>Technological Features In The Cryptocurrency Exchange Software</h2>
                            <p>To optimize the work of the Espay-X project, the system has a number of technological features. Some of them are listed below</p>
                            <p><b>Easy integration :</b>Espay-X platform can be easily personalized with few clicks into any company’s cryptocurrency exchange business.</p>
                            <p><b>Built-in tracking fees :</b>Espay-X will track all the orders in real time mode, will calculate fees differentially between user from platform A (which has a fee for example of 0.05%) and another user from platform B (which has a fee for example of 1%).</p>
                            <p><b>Real time updates :</b>users using the trading platform will experience the real time update of the active, history orders and current price.</p>
                            <p><b>Protection from fraud :</b>to avoid abuse, the system has a mechanism to protect against bots, unauthorized withdrawals, 2 factor authentication, ip detection and email confirmations.</p>
                            <p><b>Referral program mechanism :</b>each website owner can set the percentage of referral program if they want to activate it. The tracking of the referrals will be made individually per exchange owners.</p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <div className="whitepaperdetailsbox">
                            <h2>Security With The Bitcoin Exchange Software</h2>
                            <p>The main concern of any rationally thinking investor must be the safety of a given service. Questions like “Is it safe to entrust my money to the platform owners?” or “Can I trust the expertise of the creators?” are perfectly understandable. That is why we have built our whitelabel cryptocurrency exchange software with the mindset, "security first". We provide multiple security layers, including unmatched server-side security, hardware wallet storage and premium protection against phishing and DDoS attacks. We have learned from the best and will secure your investment no matter what.</p>
                            <p>We have already created a basic set of rules for the white label crypto exchange software.</p>
                            <span>The set includes, but is not limited to, the following</span>
                            <ul>
                                <li>Strict internal security processes, risk and vulnerability management</li>
                                <li>Certified data center that meets the highest security standards – ISO 27001, SOC 1 and SOC 2/SSAE 16/ISAE 3402, PCI Level 1, Sarbanes-Oxley (SOX), FISMA Moderate</li>
                                <li>Port scanning</li>
                                <li>An isolated database environment</li>
                                <li>Individual micro services, communication via an encrypted protocol</li>
                                <li>90%+ test coverage</li>
                                <li>Regular test-first updates of all used dependencies</li>
                                <li>Secure communication over IPsec (Internet Protocol security)</li>
                                <li>SQL injection protection</li>
                                <li>EV SSL certificate</li>
                                <li>SSL/HTTPS + HSTS</li>
                                <li>Cross-site scripting protection (XSS)</li>
                                <li>Cross-site request forgery protection (CSRF)</li>
                                <li>Spoofing and sniffing protection</li>
                                <li>IP address blacklist</li>
                                <li>Strong password policy with two-factor authentication (SMS verification, Google Authenticator)</li>
                                <li>All passwords and keys will be stored using modern hashes (Argon2i, bcrypt, scrypt or PBKDF2)</li>
                                <li>Cryptography – we do not use MD5, SHA-2 or similar hash functions that could mean potential security vulnerability of the system. All sensitive data will be encrypted using AES-256 encryption.</li>
                                <li>Private keys stored on multiple cryptocurrency wallets. The wallets will be geographically separate, so that compromise of one wallet will not endanger all keys.</li>
                            </ul>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <div className="whitepaperdetailsbox">
                            <h2>The liquidity challenge of white label crypto exchange platform</h2>
                            <p>Liquidity creation is an important issue for all current and planned platforms for cryptocurrency exchange and trading. If a new platform can’t create a reasonable liquidity immediately after its launch, it can quickly lose interest of early traders and this can inevitably lead to a premature end of the whole system. We are fully aware of this issue and that is why we have developed our strategic market creation plan. The plan is constantly evolving to reflect current cryptocurrency market development and allows Espay-X to stay up-to-date with all legislative and economical changes.</p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <div className="whitepaperdetailsbox">
                            <h2>Development scenarios for cryptocurrency exchange software</h2>
                            <p>Following are models of Espay-X development that you could select from based on your business strategy</p>
                            <div className="pricetable-bg">
                            <table className="pricetable">
                                <tr>
                                    <th>Timeline</th>
                                    <th>Standard</th>
                                    <th>Professional</th>
                                    <th>Enterprise</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td>
                                        <table>
                                            <tr>
                                                <th>0.25%</th>
                                                <th>0.15%</th>
                                            </tr>
                                        </table>
                                    </td>
                                    <td>
                                        <table>
                                            <tr>
                                                <th>0.10%</th>
                                                <th>0.05%</th>
                                            </tr>
                                        </table>
                                    </td>
                                    <td>
                                        <table>
                                            <tr>
                                                <th>Free</th>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Quarterly</th>
                                    <td>
                                        <table>
                                            <tr>
                                                <td>$1500 x 3 Months= $4500</td>
                                                <td>$3000 x 3 Months= $9000</td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td>
                                        <table>
                                            <tr>
                                                <td>$4000 x 3 Months= $12000</td>
                                                <td>$6000 x 3 Months= $18000</td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td>
                                        <table>
                                            <tr>
                                                <td>$10000 x 3 Months= $30000</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Half-yearly</th>
                                    <td>
                                        <table>
                                            <tr>
                                                <td>$1500 x 6 Months= $9000</td>
                                                <td>$3000 x 6 Months= $18000</td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td>
                                        <table>
                                            <tr>
                                                <td>$4000 x 6 Months= $24000</td>
                                                <td>$6000 x 6 Months= $36000</td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td>
                                        <table>
                                            <tr>
                                                <td>$10000 x 6 Months= $60000</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Yearly</th>
                                    <td>
                                        <table>
                                            <tr>
                                                <td>$1500 x 12 Months= $18000</td>
                                                <td>$3000 x 12 Months= $36000</td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td>
                                        <table>
                                            <tr>
                                                <td>$4000 x 12 Months= $48000</td>
                                                <td>$6000 x 12 Months= $72000</td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td>
                                        <table>
                                            <tr>
                                                <td>$10000 x 12 Months= $120000</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <div className="whitepaperdetailsbox">
                            <h2>User interface in white label crypto exchange software</h2>
                            <p>We conducted in-depth interviews with exchange experts and the results have shown that every user has different needs and likes different things when it comes to the user interface. Therefore, we decided to make our default interface based on the most common usage patterns. Our designers have spent hours analyzing the user experience of professional brokers to build a truly distraction free interface in the white label cryptocurrency exchange software . We aim to fulfill the needs of the majority of users. However, we plan an addition of customizable user interface to suit the needs of all of our clients.</p>
                            <strong>Margin trading</strong>
                            <p>Margin (also leverage) trading is an expert tool used to level up the order income. On the other hand, this highly requested functionality can represent a high-risk scenario for the lender and the exchange if not “done right”. It is quite difficult to master the right algorithm for pairing the lender with the buyer and that is one of the reasons, why the feature is not as common as it might be.</p>
                            <p>We are aware that margin trading is regulated or even restricted in certain countries. The platform will allow restriction of certain functions for specific countries, thus effectively providing the needed flexibility.</p>

                            <strong>All coins and tokens in one place</strong>
                            <p>Some exchanges do not offer small tokens because these tokens have low liquidity and not everyone is interested in buying them. However, there is a high demand for exchanges with a wide portfolio of tokens of different values. That is why Espay-X will offer the majority of important tradeable tokens.</p>

                            <strong>Bot-friendly API</strong>
                            <p>Espay-X will support all kinds of traders. Traders who prefer carefully planned long-term strategies can take advantage of our bot-friendly, well documented API. You will be able to customize your own notifications, trading orders, limits and much more.</p>

                            <strong>Social elements</strong>
                            <p>Interest in cryptocurrencies has connected highly specialized communities of professional and semi- professional exchange brokers. We plan to foster and cultivate this community even further. Espay-X will feature group chats, categorized by topics, currencies etc. Real-time chat will allow users to share their knowledge and experience, communicate with others, ask for help or share a solution.</p>
                            <p>Yet another social mechanism is trader ranks. The profile of every trader will be distinctly highlighted by a color and symbols based on their trade volume and success. This way the community can grow, strive and become stronger in a truly win-win fashion.</p>

                            <strong>Mobile apps</strong>
                            <p>Espay-X is not a single application, but a whole ecosystem. Although we consider the web-based application as its core, the mobile application is not of any less importance. The mobile experience is a carefully planned part of the overall user experience and unlike what you might have seen in other cases, it is not just a smaller version of the core application. Our mobile apps will provide all the basic features of the web exchange, portfolio overview, current income from trading and also advanced notifications settings. Espay-X will be available as a native app, both for Android and iOS.</p>

                            <strong>Underlying technology</strong>
                            <p>Carefully planned design and flawless technical execution are key factors for API smooth user experience. Our developers have long-term experience creating global IoT projects, streaming services and other volume-heavy platforms that provide services to hundreds of thousands of users at the same time. The team has put together a stack of technologies we have already tested and implemented elsewhere.</p>
                            <p>Our application will be distributed between carefully planned microservices. This will allow us to maximize effectively of every part of the platform and will provide almost unlimited scalability for the future.</p>

                            <strong>Marketing Strategy</strong>
                            <p>Our marketing strategy focuses on online promotion of the application. In our advertisement, we will clearly and transparently emphasize the advantages it has for the users. The campaign will present the project, its mission and its global impact to a pre-defined group of users.</p>

                            <strong>Mission of the project</strong>
                            <p>When it comes to cryptocurrency trading and exchange, we strive for simplicity and efficiency. We want to take part in education and in the development of blockchain technologies on a global level. Our mission is to introduce cryptocurrencies to the general public, novice traders and ecommerce companies that can also largely contribute to the development of cryptocurrencies.</p>

                            <strong>Strategic goal of the project</strong>
                            <p>The main goal of this project is the development of a community formed around our exchange application. A community of users who would trade together, communicate, share their expertise and help each other. When we designed our platform, we focused on the effectiveness of the trading process and on maximizing income with respect to the current situation on the market.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="whitepaperdetailsbox">
                            <h2>Marketing channels</h2>
                            <p>The project will be promoted on efficient communication channels that can be used to target a specific audience. Below, you can find a list of the channels we will be using. The list is neither exhaustive nor definitive.</p>
                        
                            <strong>1. Press release</strong>
                            <p>A press release is a great way to inform the general public about the project and its characteristics. Not only does it raise awareness of the project and the brand, but it also allows us to measure the conversion rate of visitors from information websites.</p>
                            <p><b>Reach :</b> up to 15 million users / month</p>

                            <strong>2. Guest articles and PR articles</strong>
                            <p>Publishing guest articles on thematic websites is usually for free. This applies to smaller websites with lower reach. High-traffic websites and media servers publish PR articles for a fixed fee.</p>
                            <p><b></b>up to 25 million users / month</p>

                            <strong>PPC</strong>
                            <p>PPC is one of the most complicated marketing methods when it comes to advertising crypto products. This is even more true in comparison with the year 2017. Advertising ICO and other crypto projects is currently banned on browsers and Google, Facebook, LinkedIn and Twitter content networks. This has unfortunately reduced the efficiency of performance marketing. However, there is still a large number of specialized websites, blogs and media that offer PPC advertising or other forms of publicity with great potential.</p>
                            <p><b>Reach :</b> depending on the budget, we estimate it could be up to 5 million users / month</p>

                            <strong>Social networks and internet forums</strong>
                            <p>This is one of the best ways to achieve significant reach within your target group at a relatively low cost. There are hundreds of communities on social networks and these communities have hundreds of thousands of members. The advantage is that they are usually active members with a potential of long-term communication. Some of these channels offer paid advertisement that is easily quantifiable.</p>
                            <p><b>Reach :</b> 35 million users / month</p>

                            <strong>Referral program</strong>
                            <p>These commission programs are more and more popular, both with users who are rewarded if their friends join the program, but also with bloggers, youtubers and other content creators.</p>
                            <p><b>Reach :</b> 3 million users / month</p>

                            <strong>A loyal community</strong>
                            <p>From the very beginning of the Espay-X, we know that we cannot survive without healthy and loyal community. Even before our designers started with first iterations of the platform itself, we have started to pitch ideas for a community-friendly environment.</p>
                            <p>Our goal was not just to create environment for trading, but also for seamless communication of our users, simple sharing of trading experience and support of specific roles (asset managers, margin lenders, investors etc.) </p>
                        </div>
                    </Col>
                </Row>
            </Container>
          </Fragment>
        );
    }
}
