import React, { Component } from 'react';
import { Row, Col} from 'reactstrap';
import { Link } from "react-router-dom";
import NFTRightImg from '../../Images/NFT/NFTRight.png';

class nftbanner extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm={5}>                       
                    <div className="bannerdetails">
                        <h1>NFT</h1>
                        <span>Development Service</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                    </div>
                </Col>
                <Col sm={7}>
                    <img className="img-fluid" src={NFTRightImg} alt="NFT Development Service" title="NFT Development Service" />
                </Col>
            </Row> 
        </div>
      );
    }
}

export default nftbanner;