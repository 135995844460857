import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
//import { Link } from "react-router-dom";

import Boostreturns from "../../Images/Leverage/Boostreturns.png";
import Reshufflecapital  from "../../Images/Leverage/Reshufflecapital.png";
import perrenialearningopportunity  from "../../Images/Leverage/perrenialearningopportunity.png";
import Startlavrage  from "../../Images/Leverage/Startyourleveragandmargintradingexchange.png";

export default class LavrageAdvantage extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm="12">
                        <div className="lavrageadvantage-title text-center">
                            <h2 className="text-center">Key Product Advantages of Leveraged Crypto Exchange</h2>
                            <p>Let us skyrocket your profits in the leverage and margin trading by developing leveraged crypto exchange software.</p>
                        </div>
                    </Col>
                </Row>

                <Container>
                    <Row className="text-center lavrageadvantage-section">
                        <div className="col-sm-4">
                            <div className="lavrageadv-stuff">
                                <img src={Boostreturns} alt="Boostreturns" title="Boostreturns" className="img-fluid " />
                                <h3>Boost returns</h3>
                                <p>All the traders need to do is to deposit a minimum amount and maximize their returns to the next level. Exchange owners can earn transaction fees and deleveraging. </p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="lavrageadv-stuff">
                                <img src={Reshufflecapital} alt="Reshufflecapital" title="Reshufflecapital" className="img-fluid " />
                                <h3>Reshuffle capital</h3>
                                <p>Unutilised funds can be redirected for traders who want to trade through leverage and margin trading. Exchange owners can earn steady returns by allowing it. </p>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="lavrageadv-stuff">
                                <img src={perrenialearningopportunity} alt="perrenialearningopportunity" title="perrenialearningopportunity" className="img-fluid " />
                                <h3>Perennial earning opportunity</h3>
                                <p>Whether the market conditions are good or bad, traders who trade through leverage and margin exchange trading are always opportunists to earn huge returns. In that case, exchange owners can have more business for them. </p>
                            </div>
                        </div>
                    </Row>

                </Container>
            </div>
        )
    }
}
