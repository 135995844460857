import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

//import Pools from "../../Images/StandardExchangeSolution.png";
import Implementation from "../../Images/STO/Implementation-Observation.png";
import Marketing from "../../Images/STO/Marketing-Plan.png";
import STOPools from "../../Images/STO/STOPools.png";



export default class MarketingServices extends Component {
  render() {
    return (
      <div>
        <Row className="mt-30 mb-30">   
            <Col sm="12">
                <div className="stoadviceabouttitle">
                    <p>Successful marketing is the starting point of every security token offering project. We are experts in digital marketing and have been dealing with online marketing for projects of various types and sizes for several years. For more than 3 years we have been working with ICO and STO projects, which we support from A to Z in the planning, design and implementation of your marketing strategies.</p> 
                    <p>As one of the leading full-service ICO marketing agencies, we serve customers from USA, Australia, UK, Germany, Austria and India. Our services include all marketing activities that are necessary for the success of a Security Token Offering project: STO Email Marketing, STO PR, STO Social Media Marketing, Creation of your STO White Paper, Online Display Advertising on Social Media, Google Ad networks and more. In addition, through our broad network of partners, we can assist in the planning and organization of events, roadshows, investor dinners and many other events of interest to STO projects</p>
                    <p>We have already looked after some successful projects in the crypto area regarding marketing. With our STO marketing campaign you will achieve the necessary financing for the realization of your project.</p>
                </div>
            </Col>
        </Row>

        <Row>
            <Col sm="4">
                <div className="marketingservicebox">
                    <img src={STOPools} alt="STO Pools" title="STO Pools" />
                    <h3>STO Pools</h3>
                    <p>STO pools are an important part of our STO marketing campaign. STO pools are communities of individual investors who invest in ICO or STO projects together. Through our broad partner network of STO Pools, your security token offering can reach between $50,000 and $250,000 per pool. We only offer this service in a limited way in order to maintain our image in the networks.</p>
                </div>
            </Col>
            <Col sm="4">
                <div className="marketingservicebox">
                    <img src={Implementation} alt="Implementation and Observation" title="Implementation and Observation" />
                    <h3>Implementation and Observation</h3>
                    <p>After we have sketched and planned all marketing activities, we start with the implementation. We take care of the continuous optimization of the elaborated strategies in all marketing areas. At the same time, we constantly analyse new potential opportunities that arise in the course of implementation.</p>
                </div>
            </Col>
            <Col sm="4">
                <div className="marketingservicebox">
                    <img src={Marketing} alt="STO Marketing Plan" title="STO Marketing Plan" />
                    <h3>STO Marketing Plan</h3>
                    <p>STO Marketing requires a differentiated approach across multiple marketing channels: Paid Advertising, Content Marketing, Influencer Marketing, Community Marketing, Event Marketing and a few more activities that are particularly important for STO projects. After creating your STO Marketing Plan, we ensure its full implementation.</p>
                </div>
            </Col>
        </Row>
      </div>
    )
  }
}
