import React, { Component } from 'react';
import {Row,Col} from 'reactstrap';

import wasticon from '../../Images/MarketingResearch/re-sector-1.png';
import wasticon2 from '../../Images/MarketingResearch/re-sector-2.png';
import wasticon3 from '../../Images/MarketingResearch/re-sector-3.png';
import wasticon4 from '../../Images/MarketingResearch/re-sector-4.png';

import helthimg from '../../Images/MarketingResearch/helthcare-icon1.png';
import helthimg2 from '../../Images/MarketingResearch/helthcare-icon2.png';
import helthimg3 from '../../Images/MarketingResearch/helthcare-icon3.png';
import technology from '../../Images/MarketingResearch/technology-bg.png';

import techimgicon1 from '../../Images/MarketingResearch/tech-icon-1.png';
import techimgicon2 from '../../Images/MarketingResearch/tech-icon-2.png';
import techimgicon3 from '../../Images/MarketingResearch/tech-icon-3.png';
import techimgicon4 from '../../Images/MarketingResearch/tech-icon-4.png';

import neufundicon from '../../Images/MarketingResearch/Neufund-bg.png';
import neufundicon1 from '../../Images/MarketingResearch/Neufund-icon.png';

export default class WorldwideAcceptance extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm={12}>
                        <div className="worldwide-title">
                            <span>Worldwide Acceptance of Security Tokens</span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <div className="wast-cerbox">
                            <div className="wb-box-icon">
                                <img src={wasticon} alt="Real Estate Sector" title="Real Estate Sector" />
                            </div>
                            <h3>Real Estate Sector</h3>
                        </div>
                    </Col>
                </Row>

                <Row className="worldwide-security-box">
                    <Col sm={4}>
                        <div className="worldwide-box">
                            <div className="worldwide-box-icon">
                                <img src={wasticon2} alt="Block-Estate" title="Block-Estate" />
                            </div>
                            <div className="worldwide-des">
                                <span>Block-Estate</span>
                                <p>The US-based tokenized real estate fund recently partnered with Polymath and CoinList’s ComplyAPI to raise US$50 Million.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="worldwide-box worldwidecolor">
                            <div className="worldwide-box-icon">
                                <img src={wasticon3} alt="St. Regis Aspen Resort" title="St. Regis Aspen Resort" />
                            </div>
                            <div className="worldwide-des">
                                <span>St. Regis Aspen Resort</span>
                                <p>St. Regis Aspen Resort in Colorado successfully raised $18 million by selling Aspen Coins through crowdfunding platform Indiegogo under Reg D 506c.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="worldwide-box worldwidecolor1">
                            <div className="worldwide-box-icon">
                                <img src={wasticon4} alt="QuantmRE" title="QuantmRE" />
                            </div>
                            <div className="worldwide-des">
                                <span>QuantmRE</span>
                                <p>Under Regulation A, QuantmRE is also offering a security token tied to owner-occupied, residential real estate assets in the US. It has successfully secured $1M in 2018. It plans to list the EQRE token on a regulated security token exchange platform.</p>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className="healthcare-industry">
                    <Col sm={12}>
                        <div className="helth-box">
                            <div className="helth-img">
                                <img src={helthimg} alt="Real Estate Sector" title="Real Estate Sector" />
                            </div>
                            <h3>Real Estate Sector</h3>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={6}>
                        <div className="helthleft-box">
                            <Row>
                                <Col sm={5}>
                                    <div className="helthleft">
                                        <img src={helthimg2} alt="MintHealth" title="MintHealth" />
                                        <span>MintHealth</span>
                                    </div>
                                </Col>
                                <Col sm={7}>
                                    <p>MintHealth, a decentralized health Platform-as-a-Service (PaaS) company, has created MintHealth Security Token (MHST) on the Polymath platform.</p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="helthleft-box">
                            <Row>
                                <Col sm={5}>
                                    <div className="helthleft">
                                        <img src={helthimg3} alt="Agenus" title="Agenus" />
                                        <span>Agenus</span>
                                    </div>
                                </Col>
                                <Col sm={7}>
                                    <p>Agenus, an immuno-oncology company, has launched Biotech Electronic Security Token (BEST) endeavoring to secure up to $100 million.</p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            
                <Row>
                    <Col sm={12}>
                        <div className="techimg">
                            <img className="img-fluid" src={technology} alt="Technology" title="Technology" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <div className="techno-box-main">
                            <div className="tech-bg"></div>
                            <div className="technology-box">
                                <div className="tech-icon">
                                    <img src={techimgicon1} alt="Elio Motors" title="Elio Motors" />
                                </div>
                                <div className="tech-details">
                                    <span>Elio Motors</span>
                                    <p>Elio Motors intends to raise up to $25 million by launching ElioCoin under Regulation D. The token will be available for secondary trading on tZERO's security token platform.</p>
                                </div>
                            </div>
                            <div className="technology-box">
                                <div className="tech-icon">
                                    <img src={techimgicon2} alt="Gab" title="Gab" />
                                </div>
                                <div className="tech-details">
                                    <span>Gab</span>
                                    <p>Gab, a social network platform that supports free speech, used ERC-20 security tokens to raise capital. Currently in the Test the Waters Phase for Title IV investments on StartEngine, Gab has raised more than $5 million already.</p>
                                </div>
                            </div>
                            <div className="technology-box">
                                <div className="tech-icon">
                                    <img src={techimgicon3} alt="Knowbella Tech" title="Knowbella Tech" />
                                </div>
                                <div className="tech-details">
                                    <span>Knowbella Tech</span>
                                    <p>Knowbella Tech intends to boost scientific collaborations through its open science collaboration platform. The company will allow institutional as well as individual investors to buy its Helix tokens.</p>
                                </div>
                            </div>
                            <div className="technology-box">
                                <div className="tech-icon">
                                    <img src={techimgicon4} alt="RideCoin" title="RideCoin" />
                                </div>
                                <div className="tech-details">
                                    <span>RideCoin</span>
                                    <p>RideCoin, a peer-to-peer ridesharing alternative to Uber and Ola is in the Test the Waters Phase for Title IV investments on StartEngine. It has raised more than $3 million.</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <div className="neufund-box-main">
                            <div className="neufund-box">
                                <img className="img-fluid" src={neufundicon} alt="Finance and Banking" title="Finance and Banking" />
                                <div className="neu-icon-txt">
                                    <span>Finance and Banking</span>
                                </div>
                                <div className="neu-content">
                                    <img className="img-fluid" src={neufundicon1} alt="Neufund" title="Neufund" />
                                    <span>Neufund</span>
                                    <p>Neufund, a Berlin-based company offering peer-to-peer solution for asset tokenization raked in 2.6M Euro recently. It offered FTH, an Ethereum-based token.</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
