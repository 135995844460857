import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";

import Certificate from "../Certificate/index";
//import TalkToOurExperts from "../TalkToOurExperts/TalkToOurExpertsForm";
import TalkToOurExperts from "../Contactus/ContactusdetialsSTO";

import Marketingslider from "./banner";
import Marketingservices from "./MarketingServices";
import MarketingAgency from "./MarketingAgency";
import MediaMarketing from "./MediaMarketing";
import CommunityMarketing from "./CommunityMarketing";
import STOListing from "./STOListing";


import Faqmain from '../Faq/faq';

export default class index extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-125025994-1')
        ReactGA.pageview(document.location.pathname)
        ReactGA.pageview(document.location.path)
      }
      
      scrollPositions = {}
      componentWillMount() {
        if ("scrollRestoration" in window.history) {
          window.history.scrollRestoration = "manual"
        }
        window.scrollTo(0, 0)
      }
  render() {
    return (
      <div>
        {this.props.children}
        <Helmet>
            <title>Security Token Marketing Solutions | Top STO Marketing Company | STO Launch Services</title>
            <meta name="description" content="As one of the leading full-service ICO marketing agencies, we serve customers from USA, Australia, UK, Germany, Austria and India. Our services include all marketing activities that are necessary for the success of a STO - Security Token Offering project."/>
            <meta property="og:title" content="Security Token Marketing Solutions | Top STO Marketing Company | STO Launch Services" />
            <meta property="og:description" content="As one of the leading full-service ICO marketing agencies, we serve customers from USA, Australia, UK, Germany, Austria and India. Our services include all marketing activities that are necessary for the success of a STO - Security Token Offering project." />
            <meta name="twitter:title" content="Security Token Marketing Solutions | Top STO Marketing Company | STO Launch Services" />
            <meta name="twitter:description" content="As one of the leading full-service ICO marketing agencies, we serve customers from USA, Australia, UK, Germany, Austria and India. Our services include all marketing activities that are necessary for the success of a STO - Security Token Offering project." />
            <link rel="canonical" href="https://www.espay.exchange/security-token-offering-marketing" />
        </Helmet>

        <Container fluid={true} className="AdviceExchange-bg">
          <Container>
            <Marketingslider />
          </Container>
        </Container>

        <Container>
          <Marketingservices />
        </Container>

        <Container>
          <MarketingAgency />
        </Container>
        
        <Container fluid={true} className="MediaMarketing-bg">
          <Container>
            <MediaMarketing />
          </Container>
        </Container>

        <Container>
          <CommunityMarketing />
        </Container>

        <Container fluid={true} className="PublicRelation-bg">
          <Container>
            <STOListing />
          </Container>
        </Container>

        <Container className="mt-50">
            <Certificate />
        </Container>

        <Container className="mt-50">
            <h2 className="faqmaintitle">Frequently Asked Questions</h2>
            <Faqmain />
        </Container>
        
        <TalkToOurExperts />

        <ScrollUpButton className="ScrollUpButton"/>
      </div>
    )
  }
}
