import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

import CustomDevelopment from "../../Images/OTC/CustomDevelopment.png";
import Turnkeysolutions from "../../Images/OTC/Turnkeysolutions.png";

export default class Turnkey extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col sm="1"></Col>
          <Col sm="5">
            <div className="Turnkeytitle">
              <img src={Turnkeysolutions} alt="Turnkey Solution" title="Turnkey Solution" />
              <h2 className="">Turnkey Solution</h2>
              <p>Highly scaled Whitelabel OTC Desk to empower your institutional traders to execute large block trades with a controlled Slippage mechanism</p>
            </div>
          </Col>

          <Col sm="5">
            <div className="Turnkeytitle">
              <img src={CustomDevelopment} alt="Custom Development" title="Custom Development" />
              <h2>Custom Development</h2>
              <p>Institutional-Grade Custom crafted OTC Trading Desk with the modular approach to confirm your Trade Certainty and feature-packed Back-Office</p>
            </div>
          </Col>
        </Row>



      </div>
    )
  }
}
