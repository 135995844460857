import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

import leveragemarginexchange  from "../../Images/Leverage/leveragemarginexchange.png";

export default class LaunchLaveraged extends Component {
  render() {
    return (
      <div>
        <Row className="lavragemarginex-section">    
          <Col sm="7">
                <div className="lavragemarginex-content">
                    <h2>Launch Your Own Leveraged Crypto Exchange</h2>
                    <p>Leverage and margin trading has become popular in the cryptosphere due to an obvious reason that it allows traders to start with a minimal amount and then borrow additional funds from the exchange to trade and grow their profits. </p>
                    <p>Espay Exchange has a dedicated team of experienced professionals to create feature-rich and secure crypto margin platforms for smooth and highly secure crypto margins trading. </p>
                </div>    
            </Col>
            <Col sm="5">
                <img src={leveragemarginexchange} alt="leveragemarginexchange" title="leveragemarginexchange" className="img-fluid" />
            </Col>
        </Row>  
       

       
      </div>
    )
  }
}
