import React, { Component } from 'react';
import { Row, Col} from 'reactstrap';
import { Link } from "react-router-dom";
import investors from '../../Images/investors.png';
import networks from '../../Images/networks.png';
import payments  from '../../Images/payments.png';
import developers  from '../../Images/developers.png';

import exchangefeatures from './HomeData';

class Section4 extends Component {
    render() {
        const features_h2       = exchangefeatures[4].exchangefeaturesdata.fields.exchangefeatures_h2;
        const features_dis      = exchangefeatures[4].exchangefeaturesdata.fields.exchangefeatures_dis;
        const features_title1   = exchangefeatures[4].exchangefeaturesdata.fields.exchangefeatures_title1;
        const features_subdis1  = exchangefeatures[4].exchangefeaturesdata.fields.exchangefeatures_dis1;
        const features_title2   = exchangefeatures[4].exchangefeaturesdata.fields.exchangefeatures_title2;
        const features_subdis2  = exchangefeatures[4].exchangefeaturesdata.fields.exchangefeatures_dis2;
        const features_title3   = exchangefeatures[4].exchangefeaturesdata.fields.exchangefeatures_title3;
        const features_subdis3  = exchangefeatures[4].exchangefeaturesdata.fields.exchangefeatures_dis3;
        const features_title4   = exchangefeatures[4].exchangefeaturesdata.fields.exchangefeatures_title4;
        const features_subdis4  = exchangefeatures[4].exchangefeaturesdata.fields.exchangefeatures_dis4;

      return (
        <div> 
            <Row>
                <Col sm="12">
                    <div className="ExchangeFeaturestitle">
                        <h2>{features_h2}</h2>
                        <p>{features_dis}</p>
                    </div>
                </Col>
            </Row>
            <div className="ExchangeFeaturesBg">          
                <Row>
                    <Col sm="1" md="1">
                        <img src={investors} alt="High Performance Computing" title="High Performance Computing" />
                    </Col>
                    <Col sm="5" md="5">                         
                        <div className="AdditionalBox">
                            <h3>{features_title1}</h3>
                            <p>{features_subdis1}</p>
                        </div>
                    </Col>
                    <Col sm="1" md="1">
                        <img src={networks} alt="Banking Grade Security" title="Banking Grade Security" />
                    </Col>
                    <Col sm="5" md="5">                         
                        <div className="AdditionalBox">
                            <h3>{features_title2}</h3>
                            <p>{features_subdis2}</p>
                        </div>
                    </Col>
                </Row>    
                <Row>
                    <Col sm="1" md="1">
                        <img src={payments} alt="Built for the Enterprise segment" title="Built for the Enterprise segment" />
                    </Col>
                    <Col sm="5" md="5">                         
                        <div className="AdditionalBox">
                            <h3>{features_title3}</h3>
                            <p>{features_subdis3}</p>
                        </div>
                    </Col>
                    <Col sm="1" md="1">
                        <img src={developers} alt="Transcend using A.I." title="Transcend using A.I." />
                    </Col>
                    <Col sm="5" md="5">                         
                        <div className="AdditionalBox">
                            <h3>{features_title4}</h3>
                            <p>{features_subdis4}</p>
                        </div>
                    </Col>
                    
                    <Link title="Get A Quote" to="/talk-to-crypto-exchange-expert">Get A Quote</Link>
                </Row>
            </div>     
        </div>
      );
    }
}

export default Section4;