import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


export default class Faq extends Component {
    constructor(props) {
		super(props)
		this.state = {
			expanded: undefined
		}
	}
	//Expand Panel Change....
	expandPanelChange = (panel, value) => (event, expanded) => {
		this.setState({ expanded: (expanded ? (this.state.expanded === panel ? undefined : panel) : undefined) });
	};
  render() {
    return (
      <div>
        <Container>
            <Row>
                <Col md={8} className="mx-auto">  
                    <div className="cryderivativestitle">
                        <h2>Build A Happy Traders’ Community With Espay Derivatives Exchange Development</h2>
                        <p>Derivatives are financial instruments that are highly complex and used by professional or institutional investors. Here are the reasons why investors will love your solution</p>
                    </div>
                </Col>
            </Row>
          <Row>
            <Col md={7}>
              <ExpansionPanel
                expanded={this.state.expanded === "faqpanel1"}
                onChange={this.expandPanelChange("faqpanel1")}
                className="faqpanel"
              >
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Protection From Volatility</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography>
                    The fundamental concept of our cryptocurrency derivatives
                    exchange development is for individuals and corporations to
                    reduce their risk exposure and to shield themselves against
                    any volatility in the price of the underlying asset.
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel
                expanded={this.state.expanded === "faqpanel2"}
                onChange={this.expandPanelChange("faqpanel2")}
                className="faqpanel"
              >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                  <Typography>Hedging</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography>
                    Investors could leverage ' hedging, ' which involves taking
                    steps to offset potential losses. Our cryptocurrency
                    derivatives trading platform acts as an integral technique
                    of risk management for institutions and investors.
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel
                expanded={this.state.expanded === "faqpanel3"}
                onChange={this.expandPanelChange("faqpanel3")}
                className="faqpanel"
              >
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Speculation</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography>
                    EspayDerivatives enables traders to bet on cryptocurrencies
                    rates, with the main goal of profiting from the underlying
                    cryptocurrency's price changes.
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Col>
            <Col md={{size:4, offset:1}}>
                <div className="faqdetials">
                    <p>A demo session with our product expert will give you a better idea about the market challenges, needs and how you could disrupt with an innovative solution from concept to launch and post-launch.</p>
                    <Link to="/contact-us">Book A Demo</Link>                    
                </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
