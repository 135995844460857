import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

import webpageimg from "../../Images/STO/web-creation.png";

export default class STOWebPage extends Component {
  render() {
    return (
      <div>
        <Row>   
            <Col sm="12">
                <div className="stoadviceitle">
                    <h2>STO web pages creation</h2>
                    <p>We create your STO website based on the best programming languages ​​from the point of view of performance, 
                        so that we can ensure its performance with an unlimited number of visitors. In addition, we integrate your STO website 
                        into your STO Investor Dashboard, so that your investors have the simplest possible access.</p>
                </div>    
            </Col>
        </Row>  
       

        <Row>   
            <Col sm="7">
                <div className="stowebpagebox">
                    <h3>Converting STO website</h3>
                    <p>In addition to the technical features, we also apply the best practices from the conversion optimization, so your STO website as many visitors to invest invites.</p>
                </div>
                <div className="stowebpagebox">
                    <h3>Integration</h3>
                    <p>We integrate your STO website into your STO dashboard as well as any external systems that are required.</p>
                </div>
                <div className="stowebpagebox">
                    <h3>Detailed tests</h3>
                    <p>The websites we create go through an elaborate trial to make sure that there are no surprises when you launch your future website.</p>
                </div>
            </Col>
            <Col sm="5">
                <img src={webpageimg} alt="" title="" className="img-fluid" />
            </Col>
        </Row>    
      </div>
    )
  }
}
