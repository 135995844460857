import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button"; 
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";

import Certificate from "../Certificate/index";
//import TalkToOurExperts from "../TalkToOurExperts/TalkToOurExpertsForm";
import TalkToOurExperts from "../Contactus/ContactusdetialsSTO";

import Slidermain from "./banner";
import Turnkey from "./Turnkey";
import TurnkeySolution from "./TurnkeySolution";
import OTCCustom  from "./OTCcustom";
import OtcEspay from "./OtcEspay";
import OtcStep from "./OtcStep";
import Faqmain from '../Faq/faq';

export default class index extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-125025994-1')
        ReactGA.pageview(document.location.pathname)
        ReactGA.pageview(document.location.path)
      }
      
      scrollPositions = {}
      componentWillMount() {
        if ("scrollRestoration" in window.history) {
          window.history.scrollRestoration = "manual"
        }
        window.scrollTo(0, 0)
      }
    
  render() {
    return (
      <div>
        {this.props.children}
        <Helmet>
            <title>OTC Trading Desk For Startups, Enterprises | Espay Exchange</title>
            <meta name="description" content="Cryptocurrency OTC Trading Desk Development that include implementation of trading algorithms, Smart Order Routing and exchanges connectivity. Hire an award-winning team of Blockchain developers to build your personalized OTC exchange."/>
            <meta property="og:title" content="OTC Trading Desk For Startups, Enterprises | Espay Exchange" />
            <meta property="og:description" content="Cryptocurrency OTC Trading Desk Development that include implementation of trading algorithms, Smart Order Routing and exchanges connectivity. Hire an award-winning team of Blockchain developers to build your personalized OTC exchange." />
            <meta name="twitter:title" content="OTC Trading Desk For Startups, Enterprises | Espay Exchange" />
            <meta name="twitter:description" content="Cryptocurrency OTC Trading Desk Development that include implementation of trading algorithms, Smart Order Routing and exchanges connectivity. Hire an award-winning team of Blockchain developers to build your personalized OTC exchange." />
            <link rel="canonical" href="https://www.espay.exchange/" />
        </Helmet>

        <div className="Ieobackground-bg">
        <Container fluid={true} className="programming-bg">
          <Container>
            <Slidermain />
          </Container>
        </Container>
        </div>

        <Container>
            <Turnkey/>
        </Container>

        <Container fluid={true} className="SmartContact-bg">
          <Container>
            <TurnkeySolution />
          </Container>
        </Container>
        
        <Container>
          <OTCCustom/>
        </Container>

        <Container fluid="true "className="SmartContact-bg ">
            <Container>
            <OtcEspay />
            </Container>
        </Container>

        <Container >
          <Container>
              <OtcStep />
          </Container>
        </Container>

        <Container>
            <Certificate />
        </Container>

        <Container>
            <h2 className="faqmaintitle">Frequently Asked Questions</h2>
            <Faqmain />
          </Container>
        
        <TalkToOurExperts />


        <ScrollUpButton className="ScrollUpButton"/>
      </div>
    )
  }
}
