import React, { Component } from 'react';
//import graph from 'https://youtu.be/qqmciSCKHgI';

import ImgpshFullsizeAnim from '../../Images/Landing/Imagelending2.gif';

class Tp_section13 extends Component {
  render() {
    return (
      <div className="banner">
      <img className="img-fluid" src={ImgpshFullsizeAnim}
      alt="Cryptocurrency Market CAGR Until 2025"
      title="Cryptocurrency Market CAGR Until 2025"
      />
        {/* <video id="player" class="video-js" autoPlay>
           <source src={graph} type="video/mp4"></source>
         </video> */}
      </div>
    );
  }
}

export default Tp_section13;