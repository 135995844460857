import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import Auctioneer from '../../Images/NFT/auctioneer.png';
import Marketplace from '../../Images/NFT/marketplace.png';
import WebDevelo from '../../Images/NFT/web-development.png';
import NFTCta from '../../Images/NFT/NFTCta.png';

import FeaturesTokens from './FeaturesTokens';

export default class NftServices extends Component {
    render() {
        return (
            <Fragment>
                <div className="Nftservices-main">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <h2>NFT Development Services</h2>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Col md={4}>
                                <div className="nftservicesbox">
                                    <img src={WebDevelo} alt="NFT Development (ERC 721)" title="NFT Development (ERC 721)" />
                                    <span>NFT Development <br></br>(ERC 721)</span>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="nftservicesbox">
                                    <img src={Auctioneer} alt="NFT Auction Platform" title="NFT Auction Platform" />
                                    <span>NFT Auction Platform</span>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="nftservicesbox">
                                    <img src={Marketplace} alt="NFT Marketplace" title="NFT Marketplace" />
                                    <span>NFT <br></br> Marketplace</span>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="Nftservices-cta">
                    <Container>
                        <Row>
                            <Col md={5}>
                                <h3>NFT</h3>
                                <h3>Development</h3>
                                <h3>Services</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                <a href="">Talk to our experts</a>
                            </Col>
                            <Col md={7}>
                                <img src={NFTCta} className="img-fluid" alt="" title="" />
                            </Col>
                        </Row>
                    </Container>
                </div>

                <FeaturesTokens />
            </Fragment>
        )
    }
}
