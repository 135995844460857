import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

export default class MediaMarketing extends Component {
  render() {
    return (
      <div>
        <Row className="mt-30 mb-30">   
            <Col sm="12">
                <div className="marketingmaintitle">
                    <h3>STO Social Media Marketing</h3>
                    <p>STO social media marketing is a fundamental part of any crypto marketing strategy. This includes the creation, implementation and monitoring of social media marketing strategies for Facebook, Twitter, Medium, LinkedIn, etc. to name a few.</p>
                </div>
            </Col>
        </Row>
        <Row className="mt-30 mb-30">   
            <Col sm="6">
                <div className="marketingbox">
                    <h3>Telegram</h3>
                    <p>Telegram is one of the most important channels for the entire crypto and blockchain community. The platform has even introduced its own ICO in 2018 and has accumulated substantial funding. The community marketing strategy of an STO usually begins with the establishment of the Telegram Group. We ensure the continuous development and community management on telegram by means of community activities such as competitions, bonus tokens and much more.</p>
                </div>
            </Col>
            <Col sm="6">
                <div className="marketingbox">
                    <h3>Bitcointalk</h3>
                    <p>Bitcointalk is the most famous crypto and blockchain forum on the market. We create an ANN(Announcements) thread on Bitcointalk from your STO and ensure its permanent popularization through community management, contests and other marketing strategies.</p>
                </div>
            </Col>
        </Row>
      </div>
    )
  }
}
