import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import Deposition from '../../../../Images/Deposition.png';
import withdraw from '../../../../Images/withdraw.png';
import convert from '../../../../Images/convert.png';
import Transaction_History from '../../../../Images/Transaction-History.png';
import Limits_Control from '../../../../Images/Limits-Control.png';
import Send_Fund from '../../../../Images/Send-Fund.png';
import Transfer_In_Manager from '../../../../Images/Transfer-In-Manager.png';
import socialicon from '../../../../Images/socialicon.png';
import tokenstacking from '../../../../Images/Coin-Listing-Request.png';
import address_withdrawal from '../../../../Images/aAddress-Whitelisting-For-Withdrawal.png';
import decentralized from '../../../../Images/decentralized_address.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faAddressBook} from '@fortawesome/free-solid-svg-icons';
library.add(faAddressBook);

class wallet_management_section2 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12"> 
                    <div className="walletmanagementtitle">
                        <h2>Customized CMS Includes</h2>
                    </div>                      
                </Col>
            </Row>  
            <Row>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={Deposition} alt="Deposition" title="Deposition" /></li>
                        <li className="wallet-management-cont">
                            <h4>Deposition</h4>
                            <p>Let the procedure of fund deposition will be simple, convenient and secure for your traders with Espay’s exchange development solution. </p>                                   
                        </li>
                    </ul> 
                </Col>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={withdraw } alt="withdraw" title="withdraw" /></li>
                        <li className="wallet-management-cont">
                            <h4>Withdrawal </h4>
                            <p>Ensure secure fund withdrawal to your merchants from a trading account with the crypto exchange software of Espay. </p>                                   
                        </li>
                    </ul>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <ul>                      
                        <li><FontAwesomeIcon icon="address-book" size="2x" className="wallet-management-icn"/></li>                     
                        <li className="wallet-management-cont">
                            <h4>Address Generation </h4>
                            <p>Espay’s trading solutions generate random crypto coin addresses and give assurance to your clients that fund transferred to the exact addresses. </p>                                    
                        </li>
                    </ul> 
                </Col>              
            
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={convert} alt="Convert Funds to Site Token" title="Convert Funds to Site Token" /></li>
                        <li className="wallet-management-cont">
                            <h4>Convert Funds to Site Token</h4>
                            <p>Espay crypto trading platform provides seamless conversion of their funds to site tokens within their own wallets, and without having to deal with common issues that plague exchanges.</p>                                    
                        </li>
                    </ul> 
                </Col>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={Transaction_History} alt="Transaction History" title="Transaction History" /></li>
                        <li className="wallet-management-cont">
                            <h4>Transaction History  </h4>
                        <   p>Espay let your users verify and understand the account transactions they have made on your crypto trading platform by providing transaction history. </p>                                  
                        </li>
                    </ul>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={Limits_Control} alt="Limits Control" title="Limits Control" /></li>
                        <li className="wallet-management-cont">
                            <h4>Limits Control</h4>
                            <p>Prevent your traders from extreme volatility or price manipulation in the crypto market by enabling control over daily trading limits with crypto exchange solutions from Espay. </p>                                   
                        </li>
                    </ul>
                </Col>                
            
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={Send_Fund} alt="Send Fund" title="SendFund" /></li>
                        <li className="wallet-management-cont">
                            <h4>Send Fund</h4>
                            <p>Transfer and send Bitcoin or any other crypto coins on the distributed network by utilizing Espay’s digital asset exchange platform.</p>                                   
                        </li>
                    </ul> 
                </Col>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={Transfer_In_Manager} alt="Transfer In Manage" title="Transfer In Manage" /></li>
                        <li className="wallet-management-cont">
                            <h4>Transfer In Manager</h4>
                            <p>Manage the fund efficiently while transferring it into the crypto wallet of your traders on your crypto trading platform. </p>                                   
                        </li>
                    </ul>
                </Col>              
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={socialicon} alt="Transfer Out Manager " title="Transfer Out Manager " /></li>
                        <li className="wallet-management-cont">
                            <h4>Transfer Out Manager </h4>
                            <p>Manage the fund efficiently while transferring out from the crypto wallet of your traders on your crypto trading platform. </p>                                   
                        </li>
                    </ul>
                </Col>
            
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li>
                            <img className="img-fluid" src={tokenstacking} alt="Token Stacking" title="Token Stacking" />
                        </li>
                        <li className="wallet-management-cont">
                            <h4>Token Stacking</h4>
                            <p>Let your traders and merchants take the rewards of token holders by offering token stacking on your crypto trading platform. </p>                                   
                        </li>
                    </ul> 
                </Col>
                <Col sm="12" md="6" lg="4">
                    <ul>                       
                        <li><img className="img-fluid" src={address_withdrawal} alt="Address Whitelisting For Withdrawal " title="Address Whitelisting For Withdrawal " /></li>                       
                        <li className="wallet-management-cont">
                            <h4>Address Whitelisting For Withdrawal </h4>
                            <p>Provide security to your traders by setting a withdrawal address on digital coins and authorize withdrawals to a particular address.</p>                                   
                        </li>
                    </ul> 
                </Col>                
                <Col sm="12" md="6" lg="4">
                    <ul>                        
                        <li><img className="img-fluid" src={decentralized} alt="Decentralized Address Generation with External Private key" title="Decentralized Address Generation with External Private key" /></li>
                        <li className="wallet-management-cont">
                            <h4>Decentralized Address Generation with External Private key</h4>
                            <p>simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>                                   
                        </li>
                    </ul>
                </Col>              
            </Row>         
        </div>
      );
    }
}

export default wallet_management_section2;