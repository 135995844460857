import React, { Component } from 'react';
import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";
import ScrollUpButton from "react-scroll-up-button";
import { Container} from 'reactstrap';


import Bannerdetails from '../DerivativeExchangesoftwaresolution/DerivativeexchangesolutionBanner';

//Trading dashboard and derivative exchange software solution
//Comopanents Same used

import DerivativesTradingsystems from '../DerivativeExchangesoftwaresolution/DerivativesSolutiontradingsystems'
import Ourinsight from '../Features/Front_And_Features/Trading_dashboard/Trading_section5';
import MayInterested from '../Features/Front_And_Features/Trading_dashboard/Trading_section3';
import Customizedtrading from '../DerivativeExchangesoftwaresolution/DerivativeCustomizedTrading';


import Certificate from '../Certificate/index';
import Faqmain from '../Faq/faq';

export default class index extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-125025994-1')
        ReactGA.pageview(document.location.pathname)
        ReactGA.pageview(document.location.path)
      }
      
      scrollPositions = {}
      componentWillMount() {
        if ("scrollRestoration" in window.history) {
          window.history.scrollRestoration = "manual"
        }
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div>
                {this.props.children}
                <Helmet>
                    <title>Software Solutions for Equities and Derivatives Exchange</title>
                    <meta name="description" content="Espay provide software solutions for equities, derivatives and cryptocurrency  exchange ranging from white label to enterprise platform with Derivatives - Toolkits, Derivatives - Trading Systems. Derivatives Software solutions. Trade Futures & Options Systems"/>
                    <meta property="og:title" content="Software Solutions for Equities and Derivatives Exchange" />
                    <meta property="og:description" content="Espay provide software solutions for equities, derivatives and cryptocurrency  exchange ranging from white label to enterprise platform with Derivatives - Toolkits, Derivatives - Trading Systems. Derivatives Software solutions. Trade Futures & Options Systems" />
                    <meta name="twitter:title" content="Software Solutions for Equities and Derivatives Exchange" />
                    <meta name="twitter:description" content="Espay provide software solutions for equities, derivatives and cryptocurrency  exchange ranging from white label to enterprise platform with Derivatives - Toolkits, Derivatives - Trading Systems. Derivatives Software solutions. Trade Futures & Options Systems" />
                    <link rel="canonical" href="https://www.espay.exchange/derivative-exchange-software-solution" />
                </Helmet>
                
                <Container fluid={true} className="derivative_bg">
                    <Bannerdetails />
                </Container>

                <DerivativesTradingsystems />

                <Container fluid={true} className="Trading_dashboard_Overview">
                    <Container>
                        <Customizedtrading />
                    </Container>
                </Container>
                
                <Container fluid={true} className="Trading_dashboard_interested-bg">
                    <Container>
                        <MayInterested />
                    </Container>
                </Container>

                <Container className="commonblogsection">
                    <Ourinsight />
                </Container>
           
                <Container>
                    <Certificate />
                </Container>  

                <Container fluid={true}>
                    <Container>
                        <h2 className="faqmaintitle">Frequently Asked Questions</h2>
                        <Faqmain />
                    </Container>
                </Container>   
                
                <ScrollUpButton className="ScrollUpButton"/>
            </div>
        )
    }
}
