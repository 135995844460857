import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import Compliance from '../../../Images/faq/Compliance.jpg';

export default class Compliancecomponent extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is the compliance component of a security token exchange?</h2> 
                            <img src={Compliance} alt="compliance component" title="compliance component" className="img-fluid faqimg"/>
                            <p>Here we go with the 5th component of the Security Token Ecosystem, the Compliance. Compliance providers offer services for security token issuers to stay consistent with current regulations. These include investor verification, know-your-customer rules, cap-table management, and fraud management.</p>
                            <p>Here are some firms who can help Issuers with the compliance for their security tokens:</p>
                            <ul>
                                <li>Vertalo</li>
                                <li>Chainpoint</li>
                                <li>EarlyIQ</li>
                                <li>VerifyInvestor.com</li>
                                <li>TransitNet</li>
                                <li>CoreConX</li>
                                <li>CoinFirm</li>
                                <li>Onfido</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
