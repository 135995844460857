import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

export default class WhatSTOExchange extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is STO Exchange?</h2>
                            <ul>
                                <li>STO is similar to an ICO in that an offering is made by a business to the crowd, in which consumers purchase crypto tokens built on a blockchain, but the two do not share many similarities beyond that.</li>
                                <li>Security Exchange Platform allows your users to create their own security token exchange and then connect themselves to the main network. This will ensure that the liquidity of the exchanges is maintained. There is a need in the crypto-market for exchange platforms in the market.</li>
                                <li>STO participants are investors, not users, who pay and receive a security (i.e. equity, debt, revenue share, etc) that is represented by a token. In order to sell securities to the crowd, a company has to register the offering with the SEC (difficult and expensive) or use an exemption from said registration.</li>
                            </ul>
                            <strong className="faqstrongtitle">A security token exchange will have the following features that collectively make up the exchange platform.</strong>
                            <ul>
                                <li>
                                    <b>Order Matching</b>
                                    <p>The exchange can contain Associate in Nursing order matching engine which will mechanically try similar orders between consumers and sellers at the most effective market value. it’ll conjointly match the various orders like market and limit.</p>
                                </li>
                                <li>
                                    <b>Fund Management</b>
                                    <p>A case are integrated to manage all security token transactions which can be machine-driven. Users may also management fiat-cryptocurrency conversion manually through the in-built process interface with the associated banks.</p>
                                </li>
                                <li>
                                    <b>Escrow System</b>
                                    <p>Any group action between the customer and marketer employing a third party or a wise contract is that the written agreement system. Once the accountable parties comply with the payment details, the written agreement releases the plus with none delays.</p>
                                </li>
                                <li>
                                    <b>Currency Transactions</b>
                                    <p>You can integrate any currency within the BAF Security Token Exchange code. Any cryptocurrency like Bitcoin, Ethereum or Litecoin or any fiat-currency like North American nation bucks, Chinese RMB may be embedded into the safety token exchange.</p>
                                </li>
                                <li>
                                    <b>Payment Gateway Integration</b>
                                    <p>The exchange platform can integrate a payment entry that permits users to shop for and sell mistreatment order currencies through credit cards, debit cards and different payment systems.</p>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
