import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import Push from '../../../../Images/Push-Notification-Queue.png';
import newsfeed from '../../../../Images/NEWS-FEEDS.png';
import newssection from '../../../../Images/NEWS-SECTION.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faEnvelopeOpen,faEnvelopeSquare,faBullhorn} from '@fortawesome/free-solid-svg-icons';
library.add(faEnvelopeOpen,faEnvelopeSquare,faBullhorn);

class mass_communication_section2 extends Component {
    render() {
      return (
        <div> 
             <Row>   
                <Col sm="12"> 
                    <div className="masscommunicationtitle">
                        <h2>WHAT ESPAY OFFERS</h2>
                    </div>                      
                </Col>
            </Row>  
            <Row>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><FontAwesomeIcon icon="envelope-open" size="2x" className="mass-communication-icn"/></li>
                        <li className="masscommunicationofer">
                            <h4>EMAIL ALERTS</h4>
                            <p>Alert crypto traders with email notification that will aid you to focus on customer retention. With so many digital coins on the market, keep traders engaged by using Espay’s crypto exchange software.</p>                                  
                        </li>
                    </ul> 
                </Col>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><FontAwesomeIcon icon="envelope-square" size="2x" className="mass-communication-icn"/></li>
                        <li className="masscommunicationofer">
                            <h4>SMS ALERTS</h4>
                            <p>The mobile channel is a significant element for your crypto trading business communication strategies. Espay help you to alert your customers with SMS as it is an effective tool because of its simplicity, ease of use and reach.</p>                                   
                        </li>
                    </ul>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={Push} alt="PUSH NOTIFICATION" title="PUSH NOTIFICATION" /></li>
                        <li className="masscommunicationofer">
                            <h4>PUSH NOTIFICATION</h4>
                            <p>Keep your traders engaged with push notification feature offered by Espay even when the web pages of your crypto trading platform is not open in their browser. You can notify your customers without knowing their contact details.</p>                                   
                        </li>
                    </ul>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={newsfeed} alt="NEWS FEEDS" title="NEWS FEEDS" /></li>
                        <li className="masscommunicationofer">
                            <h4>NEWS FEEDS</h4>
                            <p>It will be easier for your customers to get updates about existence of new information regarding crypto coins trading with Espay’s digital asset exchange platform. Attract more customers by building strong social relationships. </p>                                  
                        </li>
                    </ul> 
                </Col>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><FontAwesomeIcon icon="bullhorn" size="2x" className="mass-communication-icn"/></li>
                        <li className="masscommunicationofer">
                            <h4>ANNOUNCEMENT SECTION</h4>
                            <p>Serve your customers with the latest crypto coin trading market updates and exchange related announcements with the help of announcement section offered by Espay’s crypto exchange software.</p>                                   
                        </li>
                    </ul>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={newssection} alt="NEWS SECTION" title="NEWS SECTION" /></li>
                        <li className="masscommunicationofer">
                            <h4>NEWS SECTION</h4>
                            <p>Keep your customers updated by offering the international crypto trading news with Espay’s crypto exchange software solution. Latest updates will attract large visitors and help you grow your customer base with regular engagement.</p>                                   
                        </li>
                    </ul>
                </Col>
            </Row>        
        </div>
      );
    }
}

export default mass_communication_section2;