import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import Bannerdetails from './Banner';
import DerivativesParameters from './DerivativesParameters'
import Brokerage from './Brokerage';
import InstitutionalTraders from './Institutionaltraders';
import DerivativeFeature from './DerivativeFeature';
import Customerchallange from './Customerchallange';


import Certificate from '../Certificate/index';
import Faqmain from '../Faq/faq';

export default class index extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-125025994-1')
        ReactGA.pageview(document.location.pathname)
        ReactGA.pageview(document.location.path)
    }

    scrollPositions = {}
    componentWillMount() {
        if ("scrollRestoration" in window.history) {
            window.history.scrollRestoration = "manual"
        }
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                {this.props.children}
                <Helmet>
                    <title></title>
                    <meta name="description" content="" />
                    <meta property="og:title" content="" />
                    <meta property="og:description" content="" />
                    <meta name="twitter:title" content="" />
                    <meta name="twitter:description" content="" />
                    <link rel="canonical" href="https://www.espay.exchange/" />
                </Helmet>

                <Container fluid={true} className="derivative_bg">
                    <Bannerdetails />
                </Container>

                <DerivativesParameters />

                <Container fluid={true} className="Brokerage-section">
                    <Brokerage />
                </Container>

                <Container fluid={true} className="">
                    <Container>
                        <InstitutionalTraders />
                    </Container>
                </Container>

                <Container fluid={true} className="DerivativeFeature-section">
                    <Container>
                        <DerivativeFeature />
                    </Container>
                </Container>

                <Container>
                    <Customerchallange />
                </Container>

                

                <Container>
                    <Certificate />
                </Container>

                <Container fluid={true}>
                    <Container>
                        <h2 className="faqmaintitle">Frequently Asked Questions</h2>
                        <Faqmain />
                    </Container>
                </Container>

                <ScrollUpButton className="ScrollUpButton" />
            </div>
        )
    }
}
