import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import IEOconsultation from "../../Images/IEO/Initialexchangeofferingconsultationfromespayx.png";

export default class Offertraders extends Component {
  render() {
    return (
      <div>
        <div className="offtertrader-bg">
          <Row>
            <Col sm="12">
              <h2 className="">High performance and stability</h2>
              <p>Espay Exchange mobile trading terminals optimizes its market data processing to the maximum to provide high performance and advance stability during low bandwidth mobile/WiFi data exchange. The technology can switch between mobile networks without any delay in market data delivery for watchlists and charts. Our proprietary binary protocol for market data delivery includes data-wise processing and value compression that escalates the data handling compared to the contemporary methods of quotes delivery, frequent UI updates and providing fast load.
</p>
            </Col>
          </Row>
        </div>
      </div >
    )
  }
}
