import React, { Component } from 'react';
import { Row, Col,Container } from 'reactstrap';
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

export default class BookingExchangeThankyou extends Component {
  render() {
    return (
      <div>
        <Helmet>
            <title>Book An Exchange Software Demo Now - CEX | DEX| P2P | Security Tokens | Forex</title>
            <meta name="description" content="Book your cryptocurrency exchange software demo with Espay-X to see how it functions and how it could build a profitable business."/>
            <link rel="canonical" href="https://www.espay.exchange/thank-you-for-booking-exchange-demo" />
        </Helmet>

         <Container fluid={true} className="thankubg">
            <Container>
            <Row>
                <Col sm="12">
                  <div className="thankyou-content">
                      <h1>Thank you for showing your interest.</h1>
                      <p>One of our exchange experts will contact you shortly to book your demo.</p>
                      <Link to="https://www.espay.exchange/">Explore Espay-X</Link>
                  </div>
                </Col>
                            
            </Row>
            </Container>
            </Container>
      </div>
    )
  }
}

