import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

import Programmers from "../../Images/STO/experienced_programmers.png";
import Programming from "../../Images/STO/extensive_sto_programming.png";
import Infrastructure from "../../Images/STO/modern_technical_infrastructure.png";
import Contracts from "../../Images/STO/smart_contracts_programming.png";
import AuditsTesti from "../../Images/STO/STO_audits_testi.png";
import Dashboard from "../../Images/STO/STO_dashboard_programming.png";

export default class STOAdvantages extends Component {
  render() {
    return (
      <div>
        <Row>   
            <Col sm="12">
                <div className="stoadviceitle">
                    <h2>STO Technology - Your Advantages</h2>
                </div>    
            </Col>
        </Row> 
        <Row>   
            <Col sm="6">
                <div className="stoAdvantagesbox">
                    <img src={Programmers} alt="Experienced Programmers" title="Experienced Programmers" />
                    <h3>Experienced Programmers</h3>
                    <p>An experienced development team is crucial for the success of our STO agency. After years of cooperation 
                        and many successful projects behind us, we have established a solid team of blockchain and STO 
                        specialists in the field of technical development. Our programmers guide you from A to Z in programming 
                        your security token offering - from blockchain logs, security audits, investor dashboards, smart contracts,
                         and more.</p>
                </div>    
            </Col>
           
            <Col sm="6">
                <div className="stoAdvantagesbox">
                    <img src={AuditsTesti} alt="STO Audits And Tests" title="STO Audits And Tests" />
                    <h3>STO Audits And Tests</h3>
                    <p>After we have implemented all the technical aspects of your STO project, we ensure the comprehensive security audit of your Smart Contracts, Tokens, STO Investor Dashboard and all related technical products. We test the correct functioning of all software and systems as well as the smooth integration to external systems.</p>
                </div>    
            </Col>
        </Row>   
        <Row> 
            <Col sm="6">
                <div className="stoAdvantagesbox">
                    <img src={Programming} alt="Extensive STO Programming" title="Extensive STO Programming" />
                    <h3>Extensive STO Programming</h3>
                    <p>As a full-service agency for creating security token offerings, we accompany our customers from the beginning to the successful launch of their STO project. You will have a single partner who will take care of all aspects of STO programming.</p>
                </div>    
            </Col>
            <Col sm="6">
                <div className="stoAdvantagesbox">
                    <img src={Dashboard} alt="" title="STO Dashboard Programming" />
                    <h3>STO Dashboard Programming</h3>
                    <p>We create your own STO Investor Dashboard, which is individually tailored to your requirements. Our STO programmers are experts at programming blockchain nodes, wallets, smart contracts and security audits.</p>
                </div>    
            </Col>
        </Row>  

        <Row>
            <Col sm="6">
                <div className="stoAdvantagesbox">
                    <img src={Contracts} alt="Smart Contracts Programming" title="Smart Contracts Programming" />
                    <h3>Smart Contracts Programming</h3>
                    <p>Our STO agency develops customized Smart Contracts for your STO, which we additionally integrate into your Security Token and STO Dashboard. When programming your security token, we take into account all the technical requirements that have established themselves as the market standard.</p>
                </div>    
            </Col>
            <Col sm="6">
                <div className="stoAdvantagesbox">
                    <img src={Infrastructure} alt="Modern Technical Infrastructure" title="Modern Technical Infrastructure" />
                    <h3>Modern Technical Infrastructure</h3>
                    <p>Our STO specialists work with the market leading programming languages ​​on the blockchain market. We create blockchain nodes using Python, C ++ and Java. Smart contracts are realized on the basis of solidity and serpent.</p>
                </div>    
            </Col>
        </Row>  
      </div>
    )
  }
}
