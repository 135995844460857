import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";

import Certificate from "../Certificate/index";
//import TalkToOurExperts from "../TalkToOurExperts/TalkToOurExpertsForm";
import TalkToOurExperts from "../Contactus/ContactusdetialsSTO";

import LegalSlidermain from "./banner";
import LegalServices from "./LegalServices";
import Faqmain from '../Faq/faq';

export default class index extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-125025994-1')
        ReactGA.pageview(document.location.pathname)
        ReactGA.pageview(document.location.path)
      }
      
      scrollPositions = {}
      componentWillMount() {
        if ("scrollRestoration" in window.history) {
          window.history.scrollRestoration = "manual"
        }
        window.scrollTo(0, 0)
      }
  render() {
    return (
      <div>
        {this.props.children}
        <Helmet>
            <title>Security Token Offering Legal Advising | STO Development and Marketing Solutions</title>
            <meta name="description" content="Our full-service STO agency in Australia offers future STO projects comprehensive legal advice and preparation of legal security token offering documentation through our partner network of law firms and legal STO agencies that have been dealing with financial markets, IPOs and STOs for years."/>
            <meta property="og:title" content="Security Token Offering Legal Advising | STO Development and Marketing Solutions" />
            <meta property="og:description" content="Our full-service STO agency in Australia offers future STO projects comprehensive legal advice and preparation of legal security token offering documentation through our partner network of law firms and legal STO agencies that have been dealing with financial markets, IPOs and STOs for years." />
            <meta name="twitter:title" content="Security Token Offering Legal Advising | STO Development and Marketing Solutions" />
            <meta name="twitter:description" content="Our full-service STO agency in Australia offers future STO projects comprehensive legal advice and preparation of legal security token offering documentation through our partner network of law firms and legal STO agencies that have been dealing with financial markets, IPOs and STOs for years." />
            <link rel="canonical" href="https://www.espay.exchange/security-token-offering-legal" />
        </Helmet>


        <Container fluid={true} className="legalheader-bg">
          <Container>
            <LegalSlidermain />
          </Container>
        </Container>

        <Container>
            <LegalServices />
        </Container>

        <Container className="mt-50">
            <Certificate />
        </Container>

      <Container>
          <h2 className="faqmaintitle">Frequently Asked Questions</h2>
          <Faqmain />
      </Container>
        
        <TalkToOurExperts />

        <ScrollUpButton className="ScrollUpButton"/>
      </div>
    )
  }
}
