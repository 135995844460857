import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import Security_Modules_And_Features from '../../Images/Landing/Security_Modules_And_Features.png';
import Exchange_UI_Design from '../../Images/Landing/Exchange_UI_Design.png';
import Trading_Dashboard from '../../Images/Landing/Trading_Dashboard.png';
import Dynamic_CMS_Management from '../../Images/Landing/Dynamic_CMS_Management.png';
import Mobile_Trading_Platform from '../../Images/Landing/Mobile_Trading_Platform.png';
import Trading_Chart from '../../Images/Landing/Trading_Chart.png';
import Wallet_Management from '../../Images/Landing/Wallet_Management.png';
import Marketing_Features from '../../Images/Landing/Marketing_Features.png';
import Trading_Market_Setup from '../../Images/Landing/Trading_Market_Setup.png';
import Mass_Communication from '../../Images/Landing/Mass_Communication.png';
//import ScrollReveal from 'scrollreveal';

class cryptocurrency_section1 extends Component {
    render() {
      return (
        <div className="Powerfulfront"> 
            <Row>
                <Col sm="12">
                    <div className="PowerfulfrontInfo">
                        <h4>Powerful Front End Specifications For Seamless Admin & User Experience</h4>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="4">                         
                    <div className="PowerfulfrontBox">
                     <Link to="security-modules-and-features"><img src={Security_Modules_And_Features} alt="Security Modules And Features" title="Security Modules And Features" /></Link>
                        <span><Link to="security-modules-and-features">Security Modules And Features</Link></span>
                       
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="PowerfulfrontBox">
                    <Link to="exchange-ui-design"><img src={Exchange_UI_Design} alt="Exchange UI Design" title="Exchange UI Design" /></Link>
                        <span><Link to="exchange-ui-design">Exchange UI Design</Link></span>
                        
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="PowerfulfrontBox">
                    <Link to="Trading-dashboard"><img src={Trading_Dashboard} alt="Trading Dashboard" title="Trading Dashboard" /></Link>
                        <span><Link to="Trading-dashboard">Trading Dashboard</Link></span>
                        
                    </div>
                </Col>
            </Row>         
            <Row>
                <Col sm="4">                         
                    <div className="PowerfulfrontBox">
                    <Link to="dyanmic-cms-for-managing"><img src={Dynamic_CMS_Management} alt="Dynamic CMS Management" title="Dynamic CMS Management" /></Link>
                        <span><Link to="dyanmic-cms-for-managing">Dynamic CMS Management</Link></span>
                       
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="PowerfulfrontBox">
                    <Link to="mobile-trading-platform"><img src={Mobile_Trading_Platform} alt="Mobile Trading Platform" title="Mobile Trading Platform" /></Link>
                        <span><Link to="mobile-trading-platform">Mobile Trading Platform</Link></span>
                       
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="PowerfulfrontBox">
                    <Link to="mass-communication-features"><img src={Mass_Communication} alt="Mass Communication" title="Mass Communication" /></Link>
                        <span><Link to="mass-communication-features">Mass Communication</Link></span>
                       
                    </div>
                </Col>
            </Row> 
            <Row>
                <Col sm="4">                         
                    <div className="PowerfulfrontBox">
                    <Link to="trading-chart-for-exchange"><img src={Trading_Chart} alt="Trading Chart" title="Trading Chart" /></Link>
                        <span><Link to="trading-chart-for-exchange">Trading Chart</Link></span>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="PowerfulfrontBox">
                    <Link to="wallet-management"><img src={Wallet_Management} alt="Wallet Management" title="Wallet Management" /></Link>
                        <span><Link to="wallet-management">Wallet Management</Link></span>
                        
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="PowerfulfrontBox">
                    <Link to="marketing-features"><img src={Marketing_Features} alt="Marketing Features" title="Marketing Features" /></Link>
                        <span><Link to="marketing-features">Marketing Features</Link></span>
                       
                    </div>
                </Col>
            </Row> 
            <Row>
                <Col sm="4">                         
                    <div className="PowerfulfrontBox">
                    <Link to="trading-market-setup"><img src={Trading_Market_Setup} alt="Trading Market Setup" title="Trading Market Setup" /></Link>
                        <span><Link to="trading-market-setup">Trading Market Setup</Link></span>
                        
                    </div>
                </Col>
            </Row>
        </div>
      );
    }
}

export default cryptocurrency_section1;