import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import ChartTemplates from '../../../../Images/Chart-Templates.png';
import ChartViews from '../../../../Images/Chart-Views.png';
import TechnicalAnalysis from '../../../../Images/Technical-Analysis.png';
import CustomIndicators from '../../../../Images/Custom-Indicators.png';
import ChartViewing from '../../../../Images/Chart-Viewing-ModeChart-Viewing-Mode.png';
import Chart_Three from '../../../../Images/Chart_Three.png';
import PortfolioTracking from '../../../../Images/Portfolio-Tracking-Chart.png';

class trading_chart_section2 extends Component {
    render() {
      return (
        <div> 
             <Row>   
                <Col sm="12"> 
                    <div className="tradingtitle">
                        <h2>WHAT ESPAY OFFERS</h2>
                    </div>                      
                </Col>
            </Row>  
            <Row>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={ChartTemplates} alt="Chart Trading" title="Chart Trading" /></li>
                        <li className="tradingchartofer">
                            <h4>Chart Trading</h4>
                            <p>Charting and trading offered by Espay’s crypto trading platform are completely responsive and effective. It will aid your customers to create, limit and stop orders and let them make a profit.</p>                                  
                        </li>
                    </ul> 
                </Col>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={ChartViews} alt="Chart Views" title="Chart Views" /></li>
                        <li className="tradingchartofer">
                            <h4>Chart Views</h4>
                            <p>Espay provides a wide-ranging variety when it comes to crypto charts that best suit different strategies. Your users can get different views about coin price action and much more. </p>                                    
                        </li>
                    </ul>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={TechnicalAnalysis} alt="Technical Analysis" title="Technical Analysis" /></li>
                        <li className="tradingchartofer">
                            <h4>Technical Analysis</h4>
                            <p>Each single tool that is necessary for efficient technical analysis are all easy-to-get directly from the chart to your customers with Espay’s crypto exchange development solutions.</p>                                   
                        </li>
                    </ul>
                </Col>
            
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={CustomIndicators} alt="Custom Indicators" title="Custom Indicators" /></li>
                        <li className="tradingchartofer">
                            <h4>Custom Indicators</h4>
                            <p>Espay’s exchange development services support custom indicators which is a fragment of the mature as well as the engaged community of plenty of developers.</p>                                   
                        </li>
                    </ul> 
                </Col>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={ChartViewing} alt="Chart Viewing Mode" title="Chart Viewing Mode" /></li>
                        <li className="tradingchartofer">
                            <h4>Chart Viewing Mode</h4>
                            <p>With Espay’s trading software your traders can detach the charts and act as a tradable, stand-alone desktop app that is to be utilized on different screens. </p>                                   
                        </li>
                    </ul>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={Chart_Three} alt="Chart Templates" title="Chart Templates" /></li>
                        <li className="tradingchartofer">
                            <h4>Chart Templates</h4>
                            <p>It will be easier than ever before to generate and save chart templates for the future use with Espay’s digital asset exchange software. </p>                                   
                        </li>
                    </ul>
                </Col>
           
                <Col sm="12" md="6" lg="4">
                    <ul>
                        <li><img className="img-fluid" src={PortfolioTracking} alt="Portfolio Tracking Chart" title="Portfolio Tracking Chart" /></li>
                        <li className="tradingchartofer">
                            <h4>Portfolio Tracking Chart</h4>
                            <p>Portfolio performance charts aid you with the tracking of portfolio value as well as day-to-day performance. Let your users select among various time frames and get portfolio info while hovering over the charts.</p>                                   
                        </li>
                    </ul> 
                </Col>
            </Row>          
        </div>
      );
    }
}

export default trading_chart_section2;