import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";


import Usability from "../../Images/OTC/Usability.png";
import Liquidity from "../../Images/OTC/Liquidity.png";
import Trading from "../../Images/OTC/Trading.png";
import Security from "../../Images/OTC/security.png";
import Maintenance from "../../Images/OTC/Maintenance.png";


export default class TurnkeySolution extends Component {
  render() {
    return (
      <div>
        <Row>   
            <Col sm="12">
                <div className="stoadviceitle">
                    <h2>Turnkey Solution</h2>
                    <p>Complete End to End implemented Cryptocurrency OTC Trading Desk to ease your business.</p>
                </div>    
            </Col>
        </Row>  

        <Row className="IEO-campaigns">   
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={Usability} alt="Usability" title="Usability" />
                    <h3>Usability</h3>
                    <p>A easy and simple to use interface with attractive UI/UX allowing every class of traders to execute trades effortlessly </p>
                </div>
            </Col>
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={Liquidity} alt="Coins and Liquidity" title="Coins and Liquidity" />
                    <h3>Coins and Liquidity</h3>
                    <p>180+ coins and tokens supported out of the box with deep liquidity to allow large size block trading executions within microseconds.</p>
                </div>
            </Col>
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={Trading} alt="Trading" title="Trading" />
                    <h3>Trading</h3>
                    <p>The platform for the exchange conducts strict KYC / AML procedures and collects every detail about the participants.</p>
                </div>
            </Col>
        </Row>      

        <Row className="IEO-campaigns">   
            <Col sm="4" className="offset-sm-2">
                <div className="smartcontractbox">
                    <img src={Security} alt="User Account and Security" title="User Account and Security" />
                    <h3>User Account and Security</h3>
                    <p> A regulatory compliant platform with and support for basic KYC, AML, and 2FA for managing the user accounts with a geo-fencing and IP whitelisting functions</p>
                </div>
            </Col>
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={Maintenance} alt="Maintenance & Support" title="Maintenance & Support" />
                    <h3>Maintenance & Support</h3>
                    <p>A complete hassle-free experience for your business where experts manage your platform hosting and 24*7 customer support.</p>
                </div>
            </Col>
        </Row>      
      </div>
    )
  }
}
