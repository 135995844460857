import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import SecurityTokenExchange from '../../../Images/faq/SecurityTokenExchange.jpg';

export default class Tradingplatformwork extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">How Does The Trading Platform Work In Security Token Exchange?</h2> 
                            <img src={SecurityTokenExchange} alt="Trading Platform Work" title="Trading Platform Work" className="img-fluid faqimg"/>
                            <p>The last but not the least and very important and interesting component of the Security Token Ecosystem: Trading Platform. Trading platforms enable traders to buy and sell security tokens. These platforms can service both primary and secondary markets. Primary-market trading platforms can also offer issuance services, operating as hybrid issuance and trading platforms.</p>
                            <p>Here are some of the trading platforms for the Security Tokens:</p>
                            <ul>
                                <li>Stellar X</li>
                                <li>TZero</li>
                                <li>Six</li>
                                <li>Fluidity</li>
                                <li>SharesPost</li>
                                <li>Bancor</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
