import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import feescharge from '../../../../Images/dyanmiccms/feescharge.png';
import ListCoin from '../../../../Images/dyanmiccms/ListCoin.png';
import Coincreation from '../../../../Images/dyanmiccms/Coincreation.png';
import Landingpage from '../../../../Images/dyanmiccms/Landingpage.png';
import Aboutus from '../../../../Images/dyanmiccms/Aboutus.png';
import policypage from '../../../../Images/dyanmiccms/policypage.png';
import Logo from '../../../../Images/dyanmiccms/Logo.png';
import support from '../../../../Images/dyanmiccms/Support.png';
import Sociallinks from '../../../../Images/dyanmiccms/Sociallinks.png';
import Smstempelates from '../../../../Images/dyanmiccms/Smstempelates.png';
import Contactus from '../../../../Images/dyanmiccms/Contactus.png';
import EmailTemplate from '../../../../Images/dyanmiccms/EmailTemplate.png';


class dynamic_cms_mngt_section2 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12"> 
                    <div className="dynamiccmsmanagmenttitle">
                        <h2>Customized CMS Includes</h2>
                    </div>                      
                </Col>
            </Row>  
            <Row>
                <Col sm={12} md={6} lg={4}>
                    <div className="dynamic_cmsincludes">
                        <img className="img-fluid" src={feescharge} alt="FEES AND CHARGES PAGES" title="FEES AND CHARGES PAGES" />
                        <h3>FEES AND CHARGES PAGES</h3>
                        <p>Espay helps your customers know about the fees charged on a per-trade basis on your digital asset trading platform. It'll aid them to take trading decision on-the-go.</p>                                   
                    </div>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <div className="dynamic_cmsincludes">
                        <img className="img-fluid" src={ListCoin} alt="LIST COIN" title="LIST COIN" />
                        <h3>LIST COIN</h3>
                        <p>Get your tokens or crypto coins listed from Espay’s digital asset crypto exchange software. It will notify your visitors about different coins listed on your platform.</p>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <div className="dynamic_cmsincludes">
                        <img className="img-fluid" src={Sociallinks} alt="SOCIAL LINKS" title="SOCIAL LINKS" />
                        <h3>SOCIAL LINKS</h3>
                        <p>Provide a chance for your content to be shareable by including social links on your trading platform. With Espay’s Crypto exchange software keep your customers entertained and up-to-date.</p>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <div className="dynamic_cmsincludes">
                        <img className="img-fluid" src={Landingpage} alt="LANDING PAGE" title="LANDING PAGE" />
                        <h3>LANDING PAGE</h3>
                        <p>Espay offers a compelling crypto exchange landing page that lets you find out an extensive range of users who are using multiple devices to buy/sell and trade crypto coins.</p>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <div className="dynamic_cmsincludes">
                        <img className="img-fluid" src={Aboutus} alt="ABOUT US" title="ABOUT US" />
                        <h3>ABOUT US</h3>
                        <p>With the about us page delivered by Espay, you can tell your visitors what they want to know about your crypto exchange platform. It’s easy for you to express your values to them.</p>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <div className="dynamic_cmsincludes">
                        <img className="img-fluid" src={Smstempelates} alt="SMS TEMPLATES" title="SMS TEMPLATES" />
                        <h3>SMS TEMPLATES</h3>
                        <p>You can utilize SMS templates offered by Espay and drive traffic to your crypto exchange platform. Encourage your customers to trade digital coin with text messaging.</p>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <div className="dynamic_cmsincludes">
                        <img className="img-fluid" src={policypage} alt="POLICY PAGES" title="POLICY PAGES" />
                        <h3>POLICY PAGES</h3>
                        <p>As online professionals and entrepreneurs’ privacy policy are one of the most significant pages you need to add to your crypto exchange platform. Espay aid you with this and let you gain the trust of your audience.</p>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <div className="dynamic_cmsincludes">
                        <img className="img-fluid" src={Logo} alt="LOGO" title="LOGO" />
                        <h3>LOGO</h3>
                        <p>Espay’s crypto exchange software let you define your brand identity with a logo so that your customers can easily create a visual symbol that represents your crypto trading business on the cyberspace.</p>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={4}>
                    <div className="dynamic_cmsincludes">
                        <img className="img-fluid" src={Contactus} alt="CONTACT US" title="CONTACT US" />
                        <h3>CONTACT US</h3>
                        <p>Espay streamlines the communication process with your customers globally with the help of contact us page. It will encourage your visitors to say hello and help them with their queries to boost engagement, trading, and retention.</p>
                    </div>
                </Col>  
                <Col sm={12} md={6} lg={4}>
                    <div className="dynamic_cmsincludes">
                        <img className="img-fluid" src={EmailTemplate} alt="EMAIL TEMPLATES" title="EMAIL TEMPLATES" />
                        <h3>EMAIL TEMPLATES</h3>
                        <p>Leverage responsive email templates that perfectly matches your crypto trading platform from Espay. Boost visitors to your crypto exchange with emails.</p>
                    </div>
                </Col> 
                <Col sm={12} md={6} lg={4}>
                    <div className="dynamic_cmsincludes">
                        <img className="img-fluid" src={support} alt="SUPPORT" title="SUPPORT" />
                        <h3>SUPPORT</h3>
                        <p>Great support begins with better software. Espay provides a support page that will aid your customers to contact you easily if they need any crypto trading support on your website.</p>                                   
                    </div>    
                </Col>  
                <Col sm={12} md={6} lg={4}>
                    <div className="dynamic_cmsincludes">
                        <img className="img-fluid" src={Coincreation} alt="COIN PAGES CREATION" title="COIN PAGES CREATION" />
                        <h3>COIN PAGES CREATION</h3>
                        <p>Espay helps you to create different different coin pages on your trading platform that let your customers know about different coins traded on your platform.</p>                                   
                    </div>
                </Col> 
            </Row>            
        </div>
      );
    }
}

export default dynamic_cms_mngt_section2;