import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

import OTCEspay from "../../Images/OTC/Otcespay.png";

export default class IEOconsultingservice extends Component {
  render() {
    return (
      <div>
        <Row>   
            <Col sm="12">
                <div className="otctrading-title">
                    <h2>Why do cryptocurrency OTC trading desks software development with Espay? </h2>
                </div>    
            </Col>
        </Row>  
       

        <Row>   
            <Col sm="7">
                <div className="otctrading">
                    <p>●	High Frequency Support to ensure the quick settlements of Large size blocks for all the large-scale and institutional traders.</p>
                </div>
                <div className="otctrading">
                    <p>●	Deep Liquidity Pool with the proprietary market making and multiple exchanges liquidity feeds.</p>
                </div>
                <div className="otctrading">
                    <p>●	End to End encrypted trading and escrow service with the KYC/AML regulations hard coded for the custom security setup.</p>
                </div>
                <div className="otctrading">
                    <p>●	Executions of trades at the best price are guaranteed to avoid the system slippage.</p>
                </div>
            </Col>
            <Col sm="5">
                <img src={OTCEspay} alt="OTCEspay" title="OTCEspay" className="img-fluid" />
            </Col>
        </Row>    
      </div>
    )
  }
}
