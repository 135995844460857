import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class features_crypto_section2 extends Component {
    render() {
      return (
        <div> 
             <Row>   
                <Col sm="12">
                    <div className="CryptoSoluctiontitle">
                        <h2>Integrated Cryptocurrency Exchange Platform Features</h2>
                        <p>Espay offers exchange solutions for established firms to boost and optimize their
                            workflows as well as aid new brokers to develop their businesses.</p>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="4">                         
                    <div className="PlatformFeaturesBox">
                        <FontAwesomeIcon icon="stroopwafel"  />
                        <h3 className="features_title">Containerization</h3>
                        <p>Our exchange apps are bundled together with all of their related configuration files, libraries and dependencies in Docker and Kubernetes for efficient and bug-free operation.</p>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="PlatformFeaturesBox">
                        <FontAwesomeIcon icon="stroopwafel" />
                        <h3>Exchange API Integration</h3>
                        <p>The all-in-one cryptocurrency Exchange API. Leverage fast, reliable and easy-to-integrate
                                Exchange API Integration solution from Espay.
                        </p>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="PlatformFeaturesBox">
                        <FontAwesomeIcon icon="stroopwafel" />
                        <h3>Trading Report Management</h3>
                        <p>Our reporting software enables traders as well as managers to track each single activity 
                            in a trading account easily.
                        </p>
                    </div>
                </Col>
            </Row> 
            <Row>
                <Col sm="4">                         
                    <div className="PlatformFeaturesBox">
                        <FontAwesomeIcon icon="stroopwafel"  />
                        <h3>Mass Communication Features</h3>
                        <p>Exchange software solutions from Espay lets your customers receive communication from the system 
                            such as email-SMS alerts, push notifications and much more.
                        </p>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="PlatformFeaturesBox">
                        <FontAwesomeIcon icon="stroopwafel" />
                        <h3>Exchange Referral Program Management</h3>
                        <p>Espay have inbuilt Referral program announcement features for your cryptocurrency exchange software 
                            solutions.
                        </p>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="PlatformFeaturesBox">
                        <FontAwesomeIcon icon="stroopwafel" />
                        <h3>High frequency trading</h3>
                        <p>Espay’s trading platforms let you transact a large number of orders at fractions of a second.
                                It analyzes multiple markets and executes orders based on market conditions.
                        </p>
                    </div>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default features_crypto_section2;