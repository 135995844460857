import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";
import Whitepaper from "../../Images/IEO/Ideationwhitepaperreation.png";
import TokenDevelopment from "../../Images/IEO/TokenDevelopment.png";
import PreIEOmarketing from "../../Images/IEO/PREIEOMarketingServices.png";
import Listingservices from "../../Images/IEO/listingservices.png";
import Buzzcreation from "../../Images/IEO/BuzzcreationusingPR.png";


export default class STOPlatform extends Component {
  render() {
    return (
      <div>
        <Row className="mt-30 mb-30">   
            <Col sm="12">
                <div className="stoadviceitle mt-30">
                    <h2>Our IEO consulting service covers every step of development</h2>
                </div>
            </Col>
        </Row>


        <Row className="ieoconsultingservice-box">
            <Col sm="4">
                <div className="marketingservicebox">
                    <img src={Whitepaper} alt="STO Pools" title="STO Pools" />
                    <h3>Ideation/ White paper creation</h3>
                    <p>We have our team brainstorming sessions to finalize the best material to offer in the whitepaper.</p>
                </div>
            </Col>
            <Col sm="4">
                <div className="marketingservicebox">
                    <img src={TokenDevelopment} alt="Implementation and Observation" title="Implementation and Observation" />
                    <h3>Token Development</h3>
                    <p>Using our token development services expertise we help you develop tokens for your project in various blockchains.</p>
                </div>
            </Col>
            <Col sm="4">
                <div className="marketingservicebox">
                    <img src={PreIEOmarketing} alt="STO Marketing Plan" title="STO Marketing Plan" />
                    <h3>Pre-IEO marketing Services</h3>
                    <p>Create a hype for the project to have traction during initial exchange offerings before the tokens are listed.</p>
                </div>
            </Col>
        </Row>

        <Row className="ieoconsultingservice-box">
            <Col sm="4">
                <div className="marketingservicebox">
                    <img src={Listingservices} alt="STO Pools" title="STO Pools" />
                    <h3>Listing services</h3>
                    <p>We have an experienced engineering team that works with top Cryptocurrency Exchanges technical staff to list tokens.</p>
                </div>
            </Col>
            <Col sm="4">
                <div className="marketingservicebox">
                    <img src={Buzzcreation} alt="Implementation and Observation" title="Implementation and Observation" />
                    <h3>Buzz creation using PR to generate demand on exchanges</h3>
                    <p>Create hype among investors before and after IEO through regular press releases, project updates, thereby increasing demand for tokens.</p>
                </div>
            </Col>
        </Row>

        
        
      </div>
    )
  }
}
