import React, { Component, Fragment } from 'react';
import { Container, Row, Col} from 'reactstrap';
import { Link } from "react-router-dom";

export default class DerivativeexchangesolutionBanner extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col xs={12} md={9} className="mx-auto">                             
                            <div className="bannerdetails">
                                <h1>Derivatives Trading Platform</h1>
                                <p></p>
                                <Link to="/talk-to-crypto-exchange-expert">Talk To Our Experts</Link>
                            </div>
                        </Col>
                    </Row> 
                </Container>
            </Fragment>
        )
    }
}
