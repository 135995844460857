import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

import GlobalUsage  from "../../Images/Crypto_payment_Gateway/GlobalUsage.png";
import Minimalfees  from "../../Images/Crypto_payment_Gateway/minimalfees.png";
import FirstMoverAdvantage  from "../../Images/Crypto_payment_Gateway/FirstMoverAdvantage.png";
import Hourwithdrawal  from "../../Images/Crypto_payment_Gateway/24hourwithdrawal.png";
import SecureTransactions  from "../../Images/Crypto_payment_Gateway/SecureTransactions.png";
import Fastestuptime  from "../../Images/Crypto_payment_Gateway/Fastestuptime.png";
import MultiplePOS  from "../../Images/Crypto_payment_Gateway/MultiplePOS.png";
import Acquirenewcusomters  from "../../Images/Crypto_payment_Gateway/Acquirenewcusomters.png";




export default class WorldclassExperience extends Component {
    render() {
        return (
            <div>
                <Row className="mt-30 mb-30">
                    <Col sm="12">
                        <div className="stoadviceitle mt-30">
                            <h2 className="">Advantage Of Crypto Payment Gateway</h2>
                        </div>
                    </Col>
                </Row>


                <Row className="Cutting-edge-section">
                    <Col sm="4">
                        <div className="row cuttingbox">
                            <div className="col-sm-3"><img src={GlobalUsage} alt="GlobalUsage" title="GlobalUsage" className="img-fluid " /></div>
                            <div className="col-sm-9 overview-cont pr-0">
                                <h4>Global Usage</h4>
                                <p>The payment processing gateway is open for anyone to use across the world, for paying the merchants with bitcoin or any other cryptocurrency of their choice.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm="4">
                        <div className="row cuttingbox">
                            <div className="col-sm-3"><img src={Minimalfees} alt="minimalfees" title="minimalfees" className="img-fluid " /></div>
                            <div className="col-sm-9 overview-cont pr-0">
                                <h4>Minimal fees</h4>
                                <p>It comes with a minimal transaction fee, which can down to even zero. This applies for transactions from one corner of the earth to another.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm="4">
                        <div className="row cuttingbox">
                            <div className="col-sm-3"><img src={FirstMoverAdvantage} alt="FirstMoverAdvantage" title="FirstMoverAdvantage" className="img-fluid " /></div>
                            <div className="col-sm-9 overview-cont pr-0">
                                <h4>First Mover Advantage</h4>
                                <p>Take advantage of being the first-mover, as there are not many merchants who accept bitcoin and other cryptocurrencies for the delivery of goods and services.</p>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className="Cutting-edge-section">
                    <Col sm="4">
                        <div className="row cuttingbox">
                            <div className="col-sm-3"><img src={Hourwithdrawal} alt="Hourwithdrawal" title="Hourwithdrawal" className="img-fluid " /></div>
                            <div className="col-sm-9 overview-cont pr-0">
                                <h4>24 Hour Withdrawal</h4>
                                <p>The gateway offers the fastest withdrawal option compared to any other payment methods, thereby empowering merchants to withdraw their earnings and enhancing their liquidity to run their business.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm="4">
                        <div className="row cuttingbox">
                            <div className="col-sm-3"><img src={SecureTransactions} alt="SecureTransactions" title="SecureTransactions" className="img-fluid " /></div>
                            <div className="col-sm-9 overview-cont pr-0">
                                <h4>Secure Transactions</h4>
                                <p>Transactions are done wallet to wallet with no third party involvement and access to your data. This makes the whole transaction secure and anonymous.  </p>
                            </div>
                        </div>
                    </Col>
                    <Col sm="4">
                        <div className="row cuttingbox">
                            <div className="col-sm-3"><img src={Fastestuptime} alt="Fastestuptime" title="Fastestuptime" className="img-fluid " /></div>
                            <div className="col-sm-9 overview-cont pr-0">
                                <h4>Fastest Uptime</h4>
                                <p>Your crypto-payment gateway can be up and running in the fastest time, ensuring no loss of business.</p>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className="Cutting-edge-section">
                    <Col sm="4">
                        <div className="row cuttingbox">
                            <div className="col-sm-3"><img src={MultiplePOS} alt="MultiplePOS" title="MultiplePOS" className="img-fluid " /></div>
                            <div className="col-sm-9 overview-cont pr-0">
                                <h4>Multiple POS</h4>
                                <p>Merchants andconsumers can use cryptos in the store with a simple iPad as it comes with multiple POS Apps.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm="4">
                        <div className="row cuttingbox">
                            <div className="col-sm-3"><img src={Acquirenewcusomters} alt="Acquirenewcusomters" title="Acquirenewcusomters" className="img-fluid " /></div>
                            <div className="col-sm-9 overview-cont pr-0">
                                <h4>Acquire New Customers</h4>
                                <p>The gateway offers business opportunities by opening your business to an entirely new customer base who prefer paying in cryptocurrencies.</p>
                            </div>
                        </div>
                    </Col>
                </Row>


            </div>
        )
    }
}
