import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";
import Righttick from "../../Images/righttick.png";
import Presale from "../../Images/ICO_Marketing/PresalesandprivatesalesofICO.png";
import Whitepapercreation from "../../Images/ICO_Marketing/WhitpaperCreation.png";
import Socialmediaprofile from "../../Images/ICO_Marketing/Socialmediaprofilecreations.png";


export default class Presaleico extends Component {
    render() {
        return (
            <div className="presalesection">
                <Row className="mt-30 mb-30">
                    <Col sm="12">
                        <div className="stoadviceitle mt-30">
                            <h2>Pre-sale and private sale of ICO</h2>
                        </div>
                    </Col>
                </Row>


                <Row className="">
                    <div className="col-sm-6">
                        <h3 className="ico-sub-title">Pre-sale of ICO</h3>
                        <p>Before the final crowd sale, some promotional token sale events are held to raise the required funds. These are called pre-sale of ICO and private sale of ICO. </p>
                        <p className="ico-quotetitle">The companies have to spend on </p>
                        <div className="row">
                            <div className="col-sm-5">
                                <p>
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Paid advertising </span>
                                </p>
                                <p>
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Staff recruitment </span>
                                </p>
                            </div>
                            <div className="col-sm-7">
                                <p>
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Event hosting</span>
                                </p>
                                <p>
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Smart Contract development </span>
                                </p>
                            </div>
                        </div>

                        <div className="privatesale-div">
                            <h3 className="ico-sub-title">Private sale of ICO </h3>
                            <p>Here, an event is held for the company founders and other stakeholders who will discuss the project with the advisor committee. Here, some angel investors, institutional investors and the VCs will attend the event and the company will try to impress them to invest in their ICO. </p>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <img src={Presale} alt="Presale" title="Presale" className="img-fluid" />
                    </div>
                </Row>

                <Row>
                    <div className="col-sm-12">
                        <div className="double-border"></div>
                    </div>
                </Row>

                <Row className="">
                    <div className="col-sm-6">
                        <img src={Whitepapercreation} alt="Whitepapercreation" title="Whitepapercreation" className="img-fluid ieoconsultation-image" />
                    </div>
                    <div className="col-sm-6">
                        <h3 className="ico-sub-title">Whitepaper creation</h3>
                        <p>First and foremost, you need to develop a whitepaper which specifically discuss all the aspects of the project including any minor details. The main aim is to offer up-to-date information to the investors. It comprises of following content: </p>
                        <div className="row">
                            <div className="col-sm-12">
                                <p>
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Information on the idea or project</span>
                                </p>
                                <p>
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Projects start & finish date </span>
                                </p>
                                <p>
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Project or the ideas key milestone</span>
                                </p>
                                <p>
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">The competitive edge from the present competitors</span>
                                </p>
                                <p>
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">The required capital needed to commence/complete the project</span>
                                </p>
                                <p>
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Token division & distribution</span>
                                </p>
                                <p>
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Society benefits (if any)</span>
                                </p>
                                <p>
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Team details</span>
                                </p>

                            </div>
                            <p>Experts should be hired to design a professional whitepaper document that can help to attract more investors to invest in the project. </p>
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="col-sm-12">
                        <div className="double-border"></div>
                    </div>
                </Row>

                <Row className="">
                    <div className="col-sm-12">
                        <h3 className="ico-sub-title">Appealing website</h3>
                        <p>Having a website dedicated for the project is must which will be your 24×7 online shop displaying information for the investors. It must be developed and launched before the ICO launch event. </p>
                        <p className="ico-quotetitle">Some of the features of the website are</p>
                        <div className="row">
                            <div className="col-sm-6">
                                <p className="row">
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">An “About Us” page describing the company</span>
                                </p>
                                <p className="row">
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">The project idea </span>
                                </p>

                                <p className="row">
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Detail information about the token</span>
                                </p>
                                <p className="row">
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">ICO details</span>
                                </p>

                                <p className="row">
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">A separate page for the Whitepaper</span>
                                </p>

                                <p className="row">
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Social media links: Facebook, Twitter, Reddit, Youtube, Linkedin, and more</span>
                                </p>

                                <p className="row">
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Crypto community links like Bitcointalk, Cryptocurrencytalk, Bitcoingarden and more</span>
                                </p>
                            </div>
                            <div className="col-sm-6">

                                <p className="row">
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">The terms and conditions</span>
                                </p>
                                <p className="row">
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Roadmap details</span>
                                </p>
                                <p className="row">
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Team details including team members photos and a short description</span>
                                </p>
                                <p className="row">
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Page comprising all the legal details and information</span>
                                </p>
                                <p className="row">
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">A “Contact Us” page with details like address, email id, phone number etc.</span>
                                </p>
                            </div>
                            <div className="col-sm-12">
                                <p>Go for the attractively designed and developed website with smooth navigation and fresh and original content to lure the investors. Also, you can add the blog section where you can post related press releases, blogs and other information. </p>
                            </div>
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="col-sm-12">
                        <div className="double-border"></div>
                    </div>
                </Row>

                <Row className="">
                    <div className="col-sm-7">
                        <h3 className="ico-sub-title">Social media profile creation </h3>
                        <p>You cannot deny the supreme power of the social media platforms in today's digitalization era. You need to notify your target audience by reaching out to them through compelling social media content and other strategies. </p>
                        <p className="ico-quotetitle">Some popular social media platforms:</p>
                        <div className="row">
                            <div className="col-sm-5">
                                <p>
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Facebook</span>
                                </p>
                                <p>
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Twitter </span>
                                </p>
                            </div>
                            <div className="col-sm-">
                                <p>
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Linkedin</span>
                                </p>
                                <p>
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Reddit and more </span>
                                </p>
                            </div>
                        </div>
                        <p className="ico-quotetitle">Some popular social media platforms:</p>

                        <div className="row">
                            <div className="col-sm-5">
                                <p>
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Bitcointalk</span>
                                </p>
                                <p>
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Cryptocurrency talk </span>
                                </p>
                            </div>
                            <div className="col-sm-7">
                                <p>
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Bitcoingarden</span>
                                </p>
                                <p>
                                    <span className="col-sm-1"><img src={Righttick} alt="Righttick" title="Righttick" /></span>
                                    <span className="col-sm-9">Cryptojunction and more</span>
                                </p>
                            </div>
                        </div>

                        
                    </div>
                    <div className="col-sm-5">
                        <img src={Socialmediaprofile} alt="Socialmediaprofile" title="Socialmediaprofile" className="img-fluid " />
                    </div>
                </Row>








            </div>
        )
    }
}
