import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';

import Faqleftpanel from "../Faqleftpanel";

export default class Startcryptoexchangebusiness extends Component {
  
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">How do I start a crypto exchange business?</h2>
                            <div className="topsubtitlefaq">Step 1: Identify the type of exchange you want to launch</div>
                              <p>It's important to decide what sort of crypto exchange you want to start. First of all, it is important to understand the various types of white label crypto exchange software for making this decision.</p>
                              <p>Centralized exchange: A centralized cryptocurrency exchange solutions includes crypto transactions involving a middleman or a third party. That is why it is viewed by traders as a reliable market.</p>
                              <p>P2P exchange: A P2P (Peer-to-Peer) exchange conducts transactions without any intervention of third parties, while directly linking buyers and sellers.</p>
                              <p>Decentralized exchange: A decentralized crypto exchange software makes transactions simpler without intermediaries. This sort of forum for trading is not very prevalent so liquidity is lacking.</p>
                              <p>P2P exchange: A P2P (Peer--) exchange conducts transactions without any intervention of third parties, while directly linking buyers and sellers. However, for conflict resolution the third party comes into action.</p>
                            <div className="topsubtitlefaq">Step 2: Conduct thorough market research</div>
                              <p>Any project should be backed by extensive market research as it helps solidify your decisions. Do rigorous research to identify current crypto market trends and the anticipated price of cryptocurrencies in the year 2020. This will help you decide which cryptocurrencies should be listed on your exchange.</p>
                            <div className="topsubtitlefaq">Step 3: Identify the location where you plan to launch your exchange</div>
                              <p>Take time to choose where to focus your business on crypto. Studying the regulatory structure and government policy makes sense, as well as figuring out if the country you are considering is sensitive to cryptocurrencies and has a vibrant community of crypto-traders to help your business.</p>
                            <div className="topsubtitlefaq">Step 4: Check out the jurisdictions of the country where you intend to launch your exchange</div>
                              <p>As policymakers across the globe begin to learn more about cryptocurrencies, legislation is being developed to monitor these digital currencies. While some governments are sensitive to cryptocurrencies, other governments are hostile. Understanding the country laws where you plan to launch your crypto exchange–whether it's a custom exchange or crypto exchange program with a white label–is essential.</p>
                            <div className="topsubtitlefaq">Step 5: Hire a team of legal counselors</div>
                              <p>It is likely to miss considering some crucial regulatory aspects relating to cryptocurrencies and setting up an exchange in a given country, particularly if you are not from a legal context. Hiring a legal team is also advisable to find out the regulations and advise you on how to operate a crypto exchange while adhering to all such regulations.</p>
                            <div className="topsubtitlefaq">Step 6: Identify the cost of building a crypto exchange </div>
                              <p>To create and launch your crypto exchange, you need to employ a crypto-currency exchange creation business. For the creation of an exchange each company charges a different amount.</p>
                            <div className="topsubtitlefaq">Step 7: Partner with a cryptocurrency exchange development company</div>
                              <p>Your project's progress depends largely on the development team, so rely on a trustworthy development company to exchange crypts. Find out if the organization has real-world experience working on projects such as yours, because it helps ensure the project is completed efficiently and on time. Therefore, search the business team you are considering recruiting for. </p>
                              <p>Your chosen company will have the following experts</p>
                            <ul>
                              <li>C-suite executives</li>
                              <li>Subject matter experts</li>
                              <li>Blockchain developers and designers</li>
                              <li>Blockchain analysts</li>
                              <li>Blockchain financial advisors and crypto marketing experts</li>
                            </ul>
                            <div className="topsubtitlefaq">Step 8: Decide on the features that you want to integrate into your exchange</div>
                              <p>The functionality you incorporate into your exchange of crypts can make or break your exchange. Strengthen your exchange with features that users are looking for in an exchange platform–these include features that allow stable, simple, and quick trading.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
