import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Brokerag from '../../Images/DerivativesTrading/brokerage.png';


export default class Brokerage extends Component {
  render() {
    return (
      <Fragment>
        <div className="">
        <Container>
          <Row>
            <div className="col">
              <h3>Reasons to choose the EspayX Trading Suite for your brokerage</h3>
              <p>Trading Infrastructure perfectly crafted as per your business needs </p>
            </div>
          </Row>
          <Row>
            <Col sm="6" className="">
            <p>Espay Exchange is the #1 choice of brokerage firms when they require a tailored fit trading platform for a range of asset classes such as stocks, ETFs, swaps, futures, options and commodities operating under different regulations.</p>
              <p>What goes into the making of a perfect product? The amalgamation of a custom-made and branded front-end (web, mobile, or desktop terminal), interconnected with leverage, risk, match and settlement engines, generates the end result. We are experienced enough to deal with various integration projects, coordinating our modules with the broker’s ecosystems.</p>
              <div className="brokeage-facility-box">
                <p><b>This Facilitates:</b></p>
                <ul>
                  <li>Best in class Pre Trade and Post Trade Risk Management</li>
                  <li>Auto Deleveraging customized engines</li>
                  <li>End to End settlements</li>
                </ul>
              </div>
              <p>Not only this but we also make sure that our web solutions are 100% modular</p>
            </Col>
            <Col sm="6">
              <img className="img-fluid" src={Brokerag} alt="Brokerage" title="Brokerage" />
            </Col>
          </Row>
        </Container>
        </div>
      </Fragment>
    );
  }
}
