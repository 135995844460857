import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

import AMLIntegration from "../../Images/STO/AML_Integration.png";
import KYC_implementaion from "../../Images/STO/KYC_implementaion.png";
import STODocumentation from "../../Images/STO/Legal_STO_Documentation.png";
import Monitoring from "../../Images/STO/Legal_STO_Monitoring.png";
import Preparation from "../../Images/STO/Preparation_Of_The_STO_Token_Sales_Agreement.png";
import Consulting from "../../Images/STO/STO_Consulting.png";
import extensive from "../../Images/STO/STO_extensive_partner_network.png";
import Foundation from "../../Images/STO/STO_Foundation.png";
import Whitepaper from "../../Images/STO/STO_Whitepaper.png";

export default class LegalServices extends Component {
  render() {
    return (
      <div>
        <Row className="mt-30 mb-30">   
            <Col sm="12">
                <div className="stoadviceabouttitle">
                <p>Blockchain projects that want to collect money through an ICO or STO are getting more and more regulated than 
                    they were a year ago. Furthermore, from a legal point of view, security token offerings are very similar to a 
                    classic IPO and, compared to ICOs, are much more demanding and complex. Our full-service STO agency in 
                    Australia offers future STO projects comprehensive legal advice and preparation of legal STO documentation 
                    through our partner network of law firms and legal STO agencies that have been dealing with financial markets, 
                    IPOs and STOs for years.
                </p> 
                </div>
            </Col>
        </Row>

        <Row>   
            <Col sm="12">
                <div className="legalheadertitle">
                    <h2>Our Services</h2>
                </div>
            </Col>
        </Row>  
        <Row>   
            <Col sm="6">
                <div className="legalservicebox">
                    <img src={extensive} alt="Extensive Partner Network" title="Extensive Partner Network" />
                    <h3>Extensive Partner Network</h3>
                    <p>Our partner network of legal advisers, legal agencies, banks and lawyers with experience in the fields of blockchain, financial markets, initial public offerings as well as ICOs and STOs have the necessary expertise to successfully support your STO project in any legal area. Due to the high number of our partners, we also have specialists in niches and various industries that specialize exclusively in providing legal advice in these areas.</p>
                </div>
            </Col>
            <Col sm="6">
                <div className="legalservicebox">
                    <img src={Foundation} alt="STO Foundation" title="STO Foundation" />
                    <h3>STO Foundation</h3>
                    <p>Our affiliate network will help you start your business by issuing the Security Token Offering. Our legal STO partners will look after the provision of all relevant legal documents, the registration of the company, the opening of a bank account for the STO and much more. Furthermore, we can assist you in selecting an appropriate jurisdiction for your STO project so that your STO can benefit from favourable tax and legal conditions.</p>
                </div>
            </Col>
            <Col sm="6">
                <div className="legalservicebox">
                    <img src={Monitoring} alt="Legal STO Monitoring" title="Legal STO Monitoring" />
                    <h3>Legal STO Monitoring </h3>
                    <p>The development of a security token offering takes longer than the development of an ICO project and is therefore much more demanding. Given the momentum in the blockchain market and the frequent changes in its regulation, it is extremely important that you always stay informed about legal status. Our STO agency for legal advice in Australia ensures that you always stay up to date on all relevant changes. </p>
                </div>
            </Col>
            <Col sm="6">
                <div className="legalservicebox">
                    <img src={STODocumentation} alt="Legal STO Documentation" title="Legal STO Documentation" />
                    <h3>Legal STO Documentation</h3>
                    <p>Each STO should provide prospective investors with legal terms of use, the terms of token sale, the purchase and sales contract terms, the privacy policy, and the warranty and disclaimer. Our partner network ensures the preparation of this documentation.</p>
                </div>
            </Col>
            <Col sm="6">
                <div className="legalservicebox">
                    <img src={AMLIntegration} alt="AML Integration" title="AML Integration" />
                    <h3>AML Integration</h3>
                    <p>AML stands for Anti Money Laundering. These legal regulations serve to avert money laundering in the first place. STO projects must follow these rules and comply with all AML requirements. Our STO agency in Australia ensures the integration of all AML measures into your STO dashboard / platform. </p>
                </div>
            </Col>
            <Col sm="6">
                <div className="legalservicebox">
                    <img src={KYC_implementaion} alt="KYC Implementation" title="KYC Implementation" />
                    <h3>KYC Implementation</h3>
                    <p>A KYC (Know Your Customer) process is indispensable for security token offering projects. The procedure is used to authenticate each buyer of their security tokens. Our STO agency ensures both the legal conception of this procedure and its technical implementation on your STO platform.</p>
                </div>
            </Col>
            <Col sm="6">
                <div className="legalservicebox">
                    <img src={Consulting} alt="STO Consulting" title="STO Consulting" />
                    <h3>STO Consulting </h3>
                    <p>Through our partners, our STO agency in Australia can support your STO from A to Z. This includes, among other things, the selection of suitable locations, the preparation of legal documentation, the integration of KYC / AML procedures on your STO platform for the sale of your security tokens and many more.</p>
                </div>
            </Col>
            <Col sm="6">
                <div className="legalservicebox">
                    <img src={Preparation} alt="Preparation Of The STO Token Sales Agreement" title="Preparation Of The STO Token Sales Agreement" />
                    <h3>Preparation Of The STO Token Sales Agreement</h3>
                    <p>The token sale agreement includes the Taxation, Pricing, Time frame for the STO sale, soft and hard caps, dividend pay-out, security token approval rights, escrow and more.
</p>
                </div>
            </Col>
            
            <Col sm="6">
                <div className="legalservicebox">
                    <img src={Whitepaper} alt="STO Whitepaper" title="STO Whitepaper" />
                    <h3>STO Whitepaper</h3>
                    <p>STO whitepaper should meet specific legal requirements and also receive a legal audit. Our legal partners will take care of making your STO whitepaper fully compliant.</p>
                </div>
            </Col>
        </Row>   
        
      </div>
    )
  }
}
