import React, { Component,Fragment } from 'react'
import {Container, Row, Col} from 'reactstrap';
import GuestpostSubmissionRight from '../../Images/GuestpostSubmission/HeaderBenner.png';

import ReactHtmlParser from "react-html-parser";

import GuestpostSubmissionData from './GuestpostSubmissionData';

export default class GuestpostBanner extends Component {
    render() {
        const {Bannerdata}=GuestpostSubmissionData[0];
        const {Guestpotabout}=GuestpostSubmissionData[1];
        var fields={}
        var fieldsguest={}
        if(Bannerdata!=undefined){
                fields=Bannerdata.fields;
        }
        if(Guestpotabout!=undefined){
            fieldsguest=Guestpotabout.fielsds;
        }
        const h1tag   = fields!=undefined?fields.Banner_h1:"";
        const Imgtag  = fields!=undefined?fields.Banner_tag:"";

        const Abouttitle  = fieldsguest!=undefined?fieldsguest.title:"";
        const AboutDescription  = fieldsguest!=undefined?fieldsguest.Description:"";
        return (
            <Fragment>
                <div className="Guestpostbanner_bg">
                    <Container>
                        <Row>
                            <Col md={6}>                       
                                {ReactHtmlParser(h1tag)}
                            </Col>
                            <Col md={6}> 
                                <img alt={Imgtag} title={Imgtag} src={GuestpostSubmissionRight} className="img-fluid" />  
                            </Col>
                        </Row> 
                    </Container>
                </div>

                <div className="Guestpostabout">
                    <Container>
                        <Row>
                            <Col md={12}>                       
                                {ReactHtmlParser(Abouttitle)}
                                {ReactHtmlParser(AboutDescription)}
                            </Col>
                        </Row> 
                    </Container>
                </div>
            </Fragment>
        )
    }
}
