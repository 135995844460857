import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

//import bannertoken from '../../Images/MarketingResearch/bannertoken.png';

export default class ICOSTO extends Component {
    render() {
        return (
            <div className="icosto-bg">
                <Row>
                    <Col sm={12}>                    
                        <div className="heading-title">
                            <h2>Difference between ICO and STO</h2>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} xs={12}>                    
                        <div className="icobg">
                            <div className="ico-contant">
                                <p>Not backed by a tangible asset</p>
                                <p>Not regulated</p>
                                <p>No entry barrier for investors</p>
                                <p>High risk of scams as they are not regulated and not backed by real assets.</p>
                                <p>No protection for investors</p>
                                <p>Not an investment</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} xs={12}>                    
                        <div className="stobg">
                            <div className="sto-contant">
                                <p>Backed by a tangible asset</p>
                                <p>Regulated by SEC</p>
                                <p>Currently restricted to accredited investors</p>
                                <p>No risk of scams as they are regulated and backed by real assets.</p>
                                <p>Legal protection for investors</p>
                                <p>Offers a digital form of investment</p>
                            </div>    
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
