import React, { Component } from 'react';
import {Container,Row, Col} from 'reactstrap';
//import { Link } from 'react-router-dom';
// import Modaltest from '../Model/test'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMapMarkerAlt, faMobileAlt, faEnvelope, faAddressBook } from '@fortawesome/free-solid-svg-icons';

export default class ContactusBanner extends Component {
    render() {
        return (
            <div className="bannerdetails">
                <Container>
                    <Row>
                        <Col md={10} sm={12} className="mx-auto">
                            <h1>Contact Us</h1>
                            <span>Envision how our blockchain solutions could fit into your business model. Request a consultation and discover how you could take your business to the next level.</span>
                            {/* <Modaltest/> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
