import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import BankingSecurity from '../../../Images/faq/BankingSecurity.png';

export default class STBankingsecurity extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">Security Token Offering And Banking Security</h2> 
                            <img src={BankingSecurity} alt="Banking Security" title="Banking Security" className="img-fluid faqimg"/>
                            <p>Contrary to the initial coin offering, ICO, which do not entirely give any kind of rights or obligations- but rather provide access to a platform, network or service, security token offerings, STOs, are real financial securities which have the backing of tangibles like profits, company's revenue and assets. More so, they offer legal rights to users, one of which is voting.</p>
                            <p>In the banking industry basically, security tokens serve the same purpose as conventional security. The only difference is that the ownership has to be confirmed through blockchain transactions, and more importantly, make fractional ownership pretty possible. As much as they are viable and purposeful, they are subject to certain federal laws that bind securities for the benefits of protecting investors on some grounds. Fundamentally, some of its benefits, which transcends traditional security's are cost-effectiveness, fast/efficient, global trading capability, 24/7 trading, etc.</p>
                            <p>As an example, a Brazilian investment bank in 2015 planned to raise millions of dollars through security tokens investment. Bankco BTG Pactual announced its interest in backing its blockchain-based token with distressed real estate assets in the country to raise about $15 million. Not only that, but it also planned to establish some kind of secondary market with the sale to give the tokens some liquidity.Meanwhile, the question of whether or not STOs would rule over ICOs in 2019 is subject to its attributes which prompt organizations to consider buying into it. For instance, an organization is likely to consider an STO if they desire greater liquidity for their stakeholders, are interested in generating $10 million revenue per annum, are operating a global business, to mention a few. Its glamorous future in fundraising also makes it really considerable, especially as a tool for banks.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
