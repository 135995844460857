import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

export default class Marketprediction extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">Market prediction for Cryptocurrency Exchange</h2> 
                            <p>The Bitcoin market capitalization increased from approximately 0.04 billion U.S. dollars in the first quarter of 2012 to approximately 114.05 billion U.S. dollars in the third quarter of 2018. In 2016 there were 75 exchanges listed on Coin Market Cap and the total trading volume generated by them was USD 21.4 Million. The number of trading platforms increased from 75 to 225 in year 2018, and the trading volume increased from USD 21.4 Million to USD 5400 Million. Meanwhile the number of cryptocurrencies available for trading increased from 710 in 2016 to 2073 in 2018. Leaving a huge opportunity for trading platforms to cover and earn good in coming years with total trading volume expected reaching more than 4 trillion USD.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
