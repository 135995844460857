import React, { Component } from 'react';
import { Row, Col, Jumbotron} from 'reactstrap';
import { Link } from "react-router-dom";

class Banner extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm={{size:8,offset:2}}>                       
                    <Jumbotron>
                        <div className="bannerdetails">
                            <h1>White Label Crypto Exchange Software Development</h1>
                            <p>Espay makes it easy for you to launch your own white label bitcoin exchange  
                                software that is completely cheaper, faster & safer and let your customers trade 
                                digital coins with high security.</p>
                                <Link to="/talk-to-crypto-exchange-expert">Talk To Our Experts</Link>
                        </div>
                    </Jumbotron>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default Banner;