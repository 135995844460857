import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import P2PCryptoDask from '../../Images/P2PDasktop.png';

export default class P2PExchange1 extends Component {
  render() {
    return (
      <div>
            <Row>
                <Col sm="6">
                    <div className="P2Ptitle">
                        <h2>WHY DEVELOP P2P MARKET PLACE SOFTWARE</h2>
                        <p>The term Peer-to-peer (often P2P), It is an exchange of assets at a crypto marketplace between 
                            participants with no central body involvement. However, in the trading context, going peer-to-peer 
                            means it allows buying and selling to negotiate on asked price before token/coin is transferred. By nature,
                             P2P takes a decentralized approach which supports direct interactions between individuals & groups. Furthermore, 
                             this approach simply describes computer networking architecture (p2p file sharing) where every peer can 
                            act as a server for others as well as with digital currency trading (virtual currency).</p>
                    </div>
                </Col>
                <Col sm="6" className="mt-30">
                    <img src={P2PCryptoDask} alt="P2PDasktop" title="P2PDasktop" className="img-fluid" />
                </Col>
            </Row>
      </div>
    )
  }
}
