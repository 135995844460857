import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";

import Certificate from "../Certificate/index";
//import TalkToOurExperts from "../TalkToOurExperts/TalkToOurExpertsForm";
import TalkToOurExperts from "../Contactus/ContactusdetialsSTO";

import Slidermain from "./banner";
import WhatareICOs from "./WhatareICOs";
import ICOmarketingservices from "./ICOmarketingservices";
import LaunchofICO from "./LaunchofICO";
import Presaleico from "./Presaleico";
import ICOproject from "./ICOproject";
import IcoAirDrop from "./IcoAirDrop";
import ICOadvertising from "./ICOadvertising";
import Faqmain from '../Faq/faq';

export default class index extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-125025994-1')
        ReactGA.pageview(document.location.pathname)
        ReactGA.pageview(document.location.path)
      }
      
      scrollPositions = {}
      componentWillMount() {
        if ("scrollRestoration" in window.history) {
          window.history.scrollRestoration = "manual"
        }
        window.scrollTo(0, 0)
      }
    
  render() {
    return (
      <div>
        {this.props.children}
        <Helmet>
            <title>ICO Marketing Services | ICO Promotion Services | ICO Marketing Agency - Espay Exchange</title>
            <meta name="description" content="Espay Exchange is an advanced ICO Marketing Agency with extensive experience in marketing numerous ICOs successfully. Our cutting-edge ICO marketing services include PPC, PR, SEO, ORM, Social Media, Content Management etc. Our adept ICO marketers offer the best solution for ICO promotion"/>
            <meta property="og:title" content="ICO Marketing Services | ICO Promotion Services | ICO Marketing Agency - Espay Exchange" />
            <meta property="og:description" content="Espay Exchange is an advanced ICO Marketing Agency with extensive experience in marketing numerous ICOs successfully. Our cutting-edge ICO marketing services include PPC, PR, SEO, ORM, Social Media, Content Management etc. Our adept ICO marketers offer the best solution for ICO promotion" />
            <meta name="twitter:title" content="ICO Marketing Services | ICO Promotion Services | ICO Marketing Agency - Espay Exchange" />
            <meta name="twitter:description" content="Espay Exchange is an advanced ICO Marketing Agency with extensive experience in marketing numerous ICOs successfully. Our cutting-edge ICO marketing services include PPC, PR, SEO, ORM, Social Media, Content Management etc. Our adept ICO marketers offer the best solution for ICO promotion" />
            <link rel="canonical" href="https://www.espay.exchange/ico-marketing" />
        </Helmet>

        <div className="Ieobackground-bg">
        <Container fluid={true} className="programming-bg">
          <Container>
            <Slidermain />
          </Container>
        </Container>
        </div>

        <Container>
            <WhatareICOs />
        </Container>

        <Container fluid={true} className="SmartContact-bg">
          <Container>
            <ICOmarketingservices />
          </Container>
        </Container>


        <Container>
            <LaunchofICO />
        </Container>

        <Container fluid={true} className="SmartContact-bg ">
          <Container>
              <Presaleico />
          </Container>
        </Container>

        <ICOproject/>
        <IcoAirDrop/>
        <ICOadvertising/>

        <Container>
            <Certificate />
        </Container>

        <Container>
            <h2 className="faqmaintitle">Frequently Asked Questions</h2>
            <Faqmain />
          </Container>
        
        <TalkToOurExperts />


        <ScrollUpButton className="ScrollUpButton"/>
      </div>
    )
  }
}
