import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import Legal from '../../../Images/faq/Legal.jpg';

export default class Legalcomponent extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is the legal component of security token exchange?</h2> 
                            <img src={Legal} alt="legal component" title="legal component" className="img-fluid faqimg"/>
                            <p>Here are some firms who can help with the legal framework for the security tokens:</p>
                            <ul>
                                <li>SheppardMullin</li>
                                <li>Cooley</li>
                                <li>FisherBroyles</li>
                                <li>DLA Piper</li>
                                <li>PerkinsCoie</li>
                                <li>Lowenstein Sandler</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
