import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";

import Mainbanner from './banner';
import Maincookie from './Cookie-Section1';

export default class index extends Component {

  componentDidMount() {
    ReactGA.initialize('UA-125025994-1')
    ReactGA.pageview(document.location.pathname)
    ReactGA.pageview(document.location.path)
  }

  scrollPositions = {}
  componentWillMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"
    }
    window.scrollTo(0, 0)
  }
  
  render() {
    return (
      <div>
        {this.props.children}
        <Helmet>
            <title>Cookie Policy - Espay Exchange</title>
            <meta name="description" content="Check here to check us our latest cookies &amp; policy."/>
            <link rel="canonical" href="https://www.espay.exchange/cookie-policy" />
            <meta property="og:title" content="Cookie Policy - Espay Exchange" />
            <meta property="og:description" content="Check here to check us our latest cookies &amp; policy." />
            <meta name="twitter:title" content="Cookie Policy - Espay Exchange" />
            <meta name="twitter:description" content="Check here to check us our latest cookies &amp; policy." />
        </Helmet>

        <Container fluid={true} className="CookiePolicyBanner">
          <Container>
            <Mainbanner />
          </Container>
        </Container>
 
        <Container>
          <Maincookie /> 
        </Container>    
        <ScrollUpButton className="ScrollUpButton"/>  
      </div>
    )
  }
}

