import React, { Component } from 'react';
import {Row, Col} from 'reactstrap';
import { Link } from "react-router-dom";

class features_lable_Section2 extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm="12">
                    <div className="Whitelabeltitle">
                        <h2>Exchange White Label Platform Features</h2>
                        <p>An <Link title="Exchange Software Solution" to="/crypto-exchange-software">exchange software solution</Link> for entrepreneurs viewing to own and run a virtual currency trading 
                            platform in their market.</p>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="4"> 
                    <div className="WlplatformFeaturesBox">                        
                        <h3>Exchange as a Service</h3> 
                        <p>White Label solutions offer our business partners an easy approach to setup your own customized trading on
                            our platform, with their brand name.</p>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="WlplatformFeaturesBox">                          
                        <h3>Liquidity & Merchant API</h3> 
                        <p>Espay is building a liquidity aggregator through which your users will have accessibility to price at any 
                            major exchange by just having only one account.</p>
                    </div>
                </Col>
                <Col sm="4"> 
                    <div className="WlplatformFeaturesBox">                         
                        <h3>Multiple Digital Assets</h3> 
                        <p>Our trading software offers a multi-asset cryptocurrency wallet that simplifies your work on the go and 
                            makes it much easier to control multiple assets.</p>
                    </div>
                </Col>
            </Row> 
            <Row>
                <Col sm="4"> 
                    <div className="WlplatformFeaturesBox">                        
                        <h3>Security and real-time monitoring</h3> 
                        <p>Actively monitors the network to recognize bad performers and to identify suspicious behaviors. It 
                            raises real-time alerts.</p>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="WlplatformFeaturesBox">                          
                        <h3>Two-factor authentication</h3> 
                        <p>2FA adds next level of security and assure that cybercriminals can’t log in to account even if they
                                have accessibility to the account’s credentials.</p>
                    </div>
                </Col>
                <Col sm="4"> 
                    <div className="WlplatformFeaturesBox">                         
                        <h3>Trade Matching Engine</h3> 
                        <p>A matching engine from Espay is one of the quicker and the most robust matching engine technology for 
                            the retailers and crypto market.</p>
                    </div>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default features_lable_Section2;