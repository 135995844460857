import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

export default class BusinessesDecentralized extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">How businesses could deploy a decentralized exchange ?</h2> 
                            <p>There is reason to be optimistic about the future of decentralized exchanges, however, as advances like the 0x protocol could enable DEXs to offer solutions comparable to centralized exchanges</p>
                            <p>With 0x, market making, and buyer & seller interactions occur off-chain, before transaction settlement occurs on-chain. Exchanges operating using 0x (called 'relayers'), can thus offer more complex revenue models through schemes like fee splitting with other exchanges using 0x, reserve manager models where exchanges contribute to each other's liquidity (earning a share of trading fees as affiliates), and similar solutions that improve user experience off chain, while retaining the integrity of a blockchain settlement layer. In doing so, exchanges operating with the 0x protocol should be able to address issues such as poor liquidity and slippage.</p>
                            <p>One example of a novel solution to the user experience challenges faced by decentralized exchanges, facilitated using 0x, is ERC dEX's Aqueduct tool. The primary focus of Aqueduct is creating an operable open-order book solution where users of the ERC dEX relayer have access to buyers and sellers across other 0x operators. This means a wider pool of options to match orders. This system creates both a wider circle, and also creates incentives for users to add to this circle.</p>
                            <p>Another major aspect of the Aqueduct solution will be the addition of API layers that will help users access detailed, granular information on trade history, order data, and other potentially useful information.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
