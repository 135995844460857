import React, { Component } from 'react';
import {Row, Col } from 'reactstrap';

class special_section3 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12">
                    <div className="Aboutustitle">
                        <h2>What Makes Espay Exchange Special?</h2>
                        <p>The USP of Espay lies in its excellent model of project management that aid to offer the most advanced
                            <strong> crypto exchange software development.</strong></p>
                    </div>
                </Col>
                <Row>
                    <Col sm="6">
                        <ul className="AboutMakeSpeciallist">
                            <li>Our professionals have managed customers from different industries and geographical locations 
                                and have effectively recognized the core issues they have faced in the development of Exchange
                                platform.</li>
                            <li>Our product functionalities comprise wide-ranging use of cryptographic identification as well 
                                as cryptographic hashing that will help to make your trading software an ideal and secure 
                                solution.</li>
                            <li>We offer software as a service with the possibilities to advance your business above the
                                ever-changing competition in the market and safeguard your data with the highest form of 
                                Security hash functions.</li>
                        </ul>
                    </Col>
                    <Col sm="6">
                        <ul className="AboutMakeSpeciallist">
                            <li>We execute the crypto exchange script on a digital coins trading platform so that your users 
                                can easily trade multiple cryptocurrencies with high security and safe channel.</li>
                            <li>Experience is a significant to get things done without any mistake or as soon as possible. 
                                Espay have the far-reaching expertise to develop the 
                                successful <strong>exchange software solutions.</strong></li>
                            <li>Satisfaction of client is our major concern and to achieve this we use cutting-edge
                                technology. You will get <strong>24/7 customer support</strong> from us to deliver the best service 
                                immediately.</li>
                        </ul>
                    </Col>
                </Row>
            </Row>
        </div>
      );
    }
}

export default special_section3;