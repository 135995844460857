import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import BrokerDealer from '../../../Images/faq/Broker-Dealer.jpg';

export default class Brokerdealercomponent extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                            <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is the broker dealer component of security token exchange?</h2> 
                            <img src={BrokerDealer} alt="broker dealer" title="broker dealer" className="img-fluid faqimg"/>
                            <p>The second important component of the Security Token Ecosystem is the Broker Dealer. Broker-dealers are buyers, sellers, and distributors of security tokens. As dealers, they initiate transactions on behalf of brokerage firms. As brokers, they buy and sell securities on behalf of their clients. Broker-dealers, essentially, help provide liquidity to the market for a security token.</p>
                            <p>Here are some of the broker dealers of the Security Tokens:</p>
                            <ul>
                                <li>Propellr</li>
                                <li>Atomic</li>
                                <li>Uphold</li>
                                <li>EnToro</li>
                                <li>Coin Base</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
