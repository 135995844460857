import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";
import { Card, CardImg,CardBody } from 'reactstrap';
import blog1  from '../../Images/blog/IMG_2.png';
import blog2  from '../../Images/blog/BlockchainTechnology.jpg';

class blog extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12"> 
                    <div className="blogcommontitle">
                        <h2>Our Insight</h2>
                    </div>                      
                </Col>
            </Row>  
            <Row className="commonblog">
                <Col sm="4">                         
                    <Card className="carddiv">
                        <CardImg top width="100%" src={blog1} alt="Top 4 Blockchain Based Use Cases For The Crypto Exchange/Crypto Trading"  title="Top 4 Blockchain Based Use Cases For The Crypto Exchange/Crypto Trading" />
                        <CardBody>
                            <a target="_blank" rel="noopener noreferrer" href="https://espay.exchange/blog/top-4-blockchain-based-use-cases-for-the-crypto-exchange-trading/" className="card-title">Top 4 Blockchain Based Use Cases For The Crypto Exchange/Crypto Trading</a>                        
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="4">                         
                    <Card className="carddiv">
                        <CardImg top width="100%" src={blog2} alt="Blockchain Technology – Delivering The Best Exchange Platform" title="Blockchain Technology – Delivering The Best Exchange Platform" />
                        <CardBody>
                            <a target="_blank" rel="noopener noreferrer" href="https://espay.exchange/blog/blockchain-technology-delivering-best-exchange-platform/" className="card-title">Blockchain Technology – Delivering The Best Exchange Platform</a>
                        </CardBody>
                    </Card>
                </Col>
            </Row>           
        </div>
      );
    }
}

export default blog;