import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";


class Tp_section12 extends Component {
    render() {
      return (
        <div className="mt-30 mb-30"> 
            <Row>
                <Col sm={{size:12}}>
                    <div className="Additionalfeature-content">
                        <h1>But The Trading In This Booming Industry Is Hit By Some Challenges</h1>
                        <p>The exchanges that are currently available for traders suffer major scaling-issues both from the technical and operational side. Let’s see the biggest issues that are currently affecting most of the crypto exchanges.</p>
                        
                    </div>
                </Col>
                <Col sm={{size:3}}>
                    <div className="flip-container">
                        <div className="flipper">
                            <div className="front1">
                                <h4>Market Dominance</h4>
                            </div>
                            <div className="back">
                                <p>A handful of exchanges makes it easy for big sharks to charge a huge price for their cryptocurrency. Market needs exchanges that are accountable and transparent to empower the traders.</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={{size:3}}>
                <div className="flip-container">
                        <div className="flipper">
                            <div className="front2">
                                <h4>Lack of Liquidity</h4>
                            </div>
                            <div className="back">
                                <p>Few counterparties to bid and ask for coins will increase the bid-ask spread as selling prices will go high and asking price too low. This is making the coin illiquid. Every new exchange should address this issue and ensure liquidity for all listed coins.</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={{size:3}}>
                <div className="flip-container">
                        <div className="flipper">
                            <div className="front3">
                                <h4>Lack of Coin Diversity</h4>
                            </div>
                            <div className="back">
                                <p>Many exchanges only offer bitcoin to starters and later let them invest in other coins. Rather, exchanges should allow users with any other coin and not limit it to Bitcoin.</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={{size:3}}>
                <div className="flip-container">
                        <div className="flipper">
                            <div className="front4">
                                <h4>Regional Restrictions</h4>
                            </div>
                            <div className="back">
                                <p> There isn’t a cryptocurrency exchange that provides trading facility to every country. A decentralized currency needs an exchange solution that is beyond regional borders.</p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row> 

                <div className="Trading-btn">             
                    <Link to="/contact-us">Book An Appointment</Link>
                </div>
            
        </div>
      );
    }
}

export default Tp_section12;