import React, { Component,Fragment } from 'react';
import {Container, Row, Col} from 'reactstrap';
import ReactHtmlParser from "react-html-parser";
import GuestpostSubmissionData from './GuestpostSubmissionData';

export default class Contributors extends Component {
    render() {
        const {Contributors}=GuestpostSubmissionData[2];
        var fielsds={}
        if(Contributors!=undefined){
                fielsds=Contributors.fielsds;
        }
        
        const h2tag   = fielsds!=undefined?fielsds.Contitle:"";
        const Description   = fielsds!=undefined?fielsds.ConDescription:"";
        const Rulestitle   = fielsds!=undefined?fielsds.Rulestitle:"";
        const Ruleslist   = fielsds!=undefined?fielsds.Ruleslist:"";
        const Formattingtitle   = fielsds!=undefined?fielsds.Formattingtitle:"";
        const Formattinglist   = fielsds!=undefined?fielsds.Formattinglist:"";
        const ConDescriptionnote   = fielsds!=undefined?fielsds.ConDescriptionnote:"";

        return (
            <Fragment>
                <div className="Contributorsbg">
                <Container>
                    <Row>
                        <Col md={12}>
                            {ReactHtmlParser(h2tag)}
                            {ReactHtmlParser(Description)}
                        </Col>
                    </Row>

                    <Row>
                        <Col md={5}>
                            {ReactHtmlParser(Rulestitle)}
                            {ReactHtmlParser(Ruleslist)}
                        </Col>
                        <Col md={2} className="middlepart">
                            <i className=""></i>
                        </Col>
                        <Col md={5}>
                            {ReactHtmlParser(Formattingtitle)}
                            {ReactHtmlParser(Formattinglist)}
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>{ReactHtmlParser(ConDescriptionnote)}</Col>
                    </Row>
                </Container>
                </div>
            </Fragment>
        )
    }
}
