import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Software from '../../Images/Landing/Software.png';
import Country_regulations from '../../Images/Landing/Country_regulations.png';
import Business_model from '../../Images/Landing/Business_model.png';
import Revenue_streams from '../../Images/Landing/Revenue_streams.png';
import Launch from '../../Images/Landing/Launch.png';
import { Link } from "react-router-dom";

class cryptocurrency_section3 extends Component {
    render() {
      return (
        <div className="mt-30 mb-30"> 
            <Row>
                <Col sm={{size:12}}>
                    <div className="Additionalfeature-content">
                        <h1>Espay-X Is Powering more than 15 exchanges worldwide</h1>
                        <p>Find out how Espay-X can help you build your exchange business</p>
                    </div>
                </Col>
                <Col sm={{size:4}}>
                    <div className="flip-container">
                        <div className="flipper">
                            <div className="front">
                                <img className="img-fluid"  src={Software} alt="Software" title="Software" />
                                <h4>Software</h4>          
                            </div>
                            <div className="back">
                                <p>Use Espay-X’s industry leading exchange software to start your cryptocurrency exchange. Our software powers 15+ worldwide exchanges and trusted my renowned markets.</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={{size:4}}>
                <div className="flip-container">
                        <div className="flipper">
                            <div className="front">
                                <img className="img-fluid"  src={Country_regulations} alt="Country_regulations" title="Country_regulations" />
                                <h4>Country regulations</h4>
                                
                            </div>
                            <div className="back">
                                <p>Stay on the right side of the law as our exchange experts will guide you through the various legal complications of various countries related to crypto exchanges.</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={{size:4}}>
                <div className="flip-container">
                        <div className="flipper">
                            <div class="front">
                                <img className="img-fluid"  src={Business_model} alt="Business_model" title="Business_model" />
                                <h4>Business model</h4>
                                
                            </div>
                            <div className="back">
                                <p>We help you build the most profitable business model that integrates with your business and team.</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={{size:4, offset:2}}>
                    <div className="flip-container">
                        <div className="flipper">
                            <div className="front">
                                <img className="img-fluid"  src={Revenue_streams} alt="Revenue_streams" title="Revenue_streams" />
                                <h4>Revenue streams</h4>
                                
                            </div>
                            <div className="back">
                                <p>Talk with our experts and you will come to know there are various streams to generate revenue from exchanges other than trading commission.</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={{size:4}}>
                    <div className="flip-container">
                        <div className="flipper">
                            <div className="front">
                                <img className="img-fluid"  src={Launch} alt="Launch" title="Launch" />
                                <h4>Launch</h4>
                                
                            </div>
                            <div className="back">
                                <p> From business concept to launch, Espay-X has you covered for all the steps involved in building and successfully disrupting the market with your unique offering.</p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row> 
            <Row>
                <Col sm={{size:12}}>
                <div className="worldwide-btn">
                    <Link to="/talk-to-crypto-exchange-expert">Talk to our expert</Link>
                </div>
                </Col>
            </Row>
            
            
        </div>
      );
    }
}

export default cryptocurrency_section3;