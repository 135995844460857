import React, { Component } from 'react';
import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";
import ScrollUpButton from "react-scroll-up-button";
import { Container} from 'reactstrap';
import Bannerdetails from '../DerivativeExchangesoftware/DerivativeexchangeBanner';

//whitelabel crypto exchange software and derivative exchange software development
//Comopanents Same used
import DerivativesTradingsystems from '../DerivativeExchangesoftware/DerivativesTradingsystems'
import Benefits  from '../White_lable/Wl-Section5';
import ExchangeWhitelabel  from '../White_lable/Wl-Section4';
import Lookingfullybranded  from '../White_lable/Wl-Section3';
import Whychooseespay  from '../White_lable/Wl-Section6';
import Ourotherexchange  from '../White_lable/Wl-Section7';
import Ourinsight  from '../White_lable/Wl-Section8';
import Certificate from '../Certificate/index';
import Faqmain from '../Faq/faq';

export default class index extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-125025994-1')
        ReactGA.pageview(document.location.pathname)
        ReactGA.pageview(document.location.path)
      }
      
      scrollPositions = {}
      componentWillMount() {
        if ("scrollRestoration" in window.history) {
          window.history.scrollRestoration = "manual"
        }
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <div>
                {this.props.children}
                <Helmet>
                    <title>Derivative exchange software development | trading software company</title>
                    <meta name="description" content="Espay provide derivative exchange software development  our trading software for equities, derivatives and cryptocurrency exchanges, starting from white label to enterprise setup, Derivatives - Toolkits, Derivatives - Trading Systems. Futures & Options Systems"/>
                    <meta property="og:title" content="Derivative exchange software development | trading software company" />
                    <meta property="og:description" content="Espay provide derivative exchange software development  our trading software for equities, derivatives and cryptocurrency exchanges, starting from white label to enterprise setup, Derivatives - Toolkits, Derivatives - Trading Systems. Futures & Options Systems" />
                    <meta name="twitter:title" content="Derivative exchange software development | trading software company" />
                    <meta name="twitter:description" content="Espay provide derivative exchange software development  our trading software for equities, derivatives and cryptocurrency exchanges, starting from white label to enterprise setup, Derivatives - Toolkits, Derivatives - Trading Systems. Futures & Options Systems" />
                    <link rel="canonical" href="https://www.espay.exchange/derivative-exchange-software-development-company" />
                </Helmet>
                
                <Container fluid={true} className="derivative_bg">
                    <Bannerdetails />
                </Container>

                <DerivativesTradingsystems />

                <Container>
                    <Benefits />
                </Container>
                <Container fluid={true} className="Wlfeatures-bg">
                    <Container>
                        <ExchangeWhitelabel />
                    </Container>    
                </Container>

                <Container fluid={true} className="Wlbranded-bg">
                    <Container>
                        <Lookingfullybranded />
                    </Container>    
                </Container>

                <Container>
                    <Whychooseespay />
                </Container>    

                <Container fluid={true} className="WlExchangeProduct">
                    <Container>
                        <Ourotherexchange />
                    </Container>    
                </Container>

                <Container className="commonblogsection">
                    <Ourinsight />
                </Container>    

                <Container>
                    <Certificate />
                </Container>  

                <Container fluid={true}>
                    <Container>
                        <h2 className="faqmaintitle">Frequently Asked Questions</h2>
                        <Faqmain />
                    </Container>
                </Container>   
                
                <ScrollUpButton className="ScrollUpButton"/>
            </div>
        )
    }
}
