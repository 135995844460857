import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import android from '../../../../Images/android.png';
import ios from '../../../../Images/ios.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faHome,faDesktop} from '@fortawesome/free-solid-svg-icons';
library.add(faHome,faDesktop);

class Mobile_Trading_Platform_section2 extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm="6">
                    <ul>
                        <li><img className="img-fluid" src={android } alt="LIST COIN" title="LIST COIN" /></li>
                        <li className="mobile-trading-cont">
                            <h4>Android Application </h4>
                            <p>Espay’s crypto exchange solutions offer the Android application for your android smartphone users that let them perform a different trading task with the latest Android app. </p>                                   
                        </li>
                    </ul>
                </Col>
                <Col sm="6">
                    <ul>                      
                        <li><img className="img-fluid" src={ios} alt="IOS Application" title="IOS Application" /></li>                       
                        <li className="mobile-trading-cont">
                            <h4>IOS Application </h4>
                            <p>Espay’s crypto trading software is also available for iOS devices. Your traders who are using iPhone can leverage best out of crypto exchange offered by Espay. </p>                                    
                        </li>
                    </ul> 
                </Col>              
            </Row> 
            <Row>               
                <Col sm="6">
                    <ul>
                        <li><FontAwesomeIcon icon="home" size="2x" className="mobile-trading-icn"/></li>
                        <li className="mobile-trading-cont">
                            <h4>Web Interface </h4>
                            <p>Espay as a crypto exchange development company know the nature of traders and develop web interface with responsiveness. Serve the best web interface to your traders with Espay’s trading solutions.</p>                                   
                        </li>
                    </ul>
                </Col>
                <Col sm="6">
                    <ul>
                        <li><FontAwesomeIcon icon="desktop" size="2x" className="mobile-trading-icn"/></li>
                        <li className="mobile-trading-cont">
                            <h4>Hybrid Interface For Desktop</h4>
                            <p>Crypto exchange software from the experts of Espay consider the user experience properties of both native & web to deliver a variety of benefits with the hybrid interface.</p>                                   
                        </li>
                    </ul>
                </Col>               
            </Row>                     
        </div>
      );
    }
}

export default Mobile_Trading_Platform_section2;