import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";

import Interestedforexexchange from "../../Images/Interestedforexexchange.png";
import Interestedp2p from "../../Images/Interestedp2p.png";
import Interesteddes from "../../Images/Interesteddes.png";

export default class P2PExchange5 extends Component {
  render() {
    return (
      <div>
            <Row>
                <Col sm="12">
                    <div className="P2Ptitle">
                        <h2>Our Other Exchange Product</h2>
                    </div>
                </Col>
            </Row>  
            <Row>
                <Col sm="4">
                    <div className="p2pproductbox">
                        <Link to="/security-token-exchange" title="Forex Exchange Solutions">
                            <img className="img-fluid" src={Interestedforexexchange} alt="Security Token Exchange" title="Security Token Exchange"  />
                            <h3>Forex Exchange Solutions</h3>
                            <p>Espay is a trusted face globally, whether you’re searching 
                                for wholesale/retail currency exchange platform you are assured a 
                                safe and suitable service.</p>
                        </Link>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="p2pproductbox">
                        <Link to="/p2p-exchange-software-development" title="P2P Market Place">
                            <img className="img-fluid" src={Interestedp2p} alt="P2P Marketplace Software" title="P2P Marketplace Software" />
                            <h3>P2P Market Place</h3>
                            <p>With P2P trading platforms, developers at Espay strive to
                                 preserve the real openness that the Blockchain Technology conveys.</p>
                        </Link>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="p2pproductbox">
                        <Link to="/decentralized-crypto-exchange-software" title="Decentralized Exchange">
                            <img className="img-fluid" src={Interesteddes} alt="Enterprise Exchange" title="Enterprise Exchange" />
                            <h3>Decentralized Exchange</h3>
                            <p>Highly secure & anonymous. Everything gets stored on the users’ 
                                device without worrying about server down time and fraudulent activities. </p>
                        </Link>
                    </div>
                </Col>
            </Row>  
      </div>
    )
  }
}
