import React from 'react';
import {Container} from 'reactstrap';
import { Link } from "react-router-dom";
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';

const index = () => (
  <div>
 
    <Container className="mt-30 mb-30">
        <Accordion atomic={true}>

        <AccordionItem title="Security Token Exchange">
            <DummyContent />
        </AccordionItem>

        <AccordionItem title="Decentralize Exchange">
            <DummyContent1 />
        </AccordionItem>

        <AccordionItem title="P2P Exchange">
            <DummyContent2 />
        </AccordionItem>

        <AccordionItem title="Market Capitalization">
            <DummyContent3/>
        </AccordionItem>

        <AccordionItem title="Alternative Trading Systems">
            <DummyContent4/>
        </AccordionItem>


        </Accordion>
    </Container>

  </div>
);

const DummyContent = () => (
  <div className="faqmainbox">
    <p><Link to="/STO-FAQ"> 1) What is Security Token</Link></p>
    <p><Link to="/STO-FAQ"> 2) What is STO?</Link></p>
    <p><Link to="/STO-FAQ"> 3) What is STO Exchange ?</Link></p>
    <p><Link to="/STO-FAQ"> 4) Benefits of Having A STO Exchange</Link></p>
    <p><Link to="/STO-FAQ"> 5) Stats of STO</Link></p>
    <p><Link to="/STO-FAQ"> 6) Why the market is bullish about the security tokens?</Link></p>
    <p><Link to="/STO-FAQ"> 7) How to determine if a token falls into the crieteria of Security Token?</Link></p>
    <p><Link to="/STO-FAQ"> 8) Security Tokens Can Change The Market.</Link></p>
    <p><Link to="/STO-FAQ"> 9) How are tokens classified?</Link></p>
    <p><Link to="/STO-FAQ"> 10) How Security token can liquidize the illiquid assets/securities?</Link></p>
    <p><Link to="/STO-FAQ"> 11) How does tokenization work ?</Link></p>
    <p><Link to="/STO-FAQ"> 12) Why Security Tokens are the big deal?</Link></p>
    <p><Link to="/STO-FAQ"> 13) Ecosystem of Security Token</Link></p>
    <p><Link to="/STO-FAQ"> 14) What is the significance of issuance in security token exchange?</Link></p>
    <p><Link to="/STO-FAQ"> 15) What is the broker dealer component of security token exchange?</Link></p>
    <p><Link to="/STO-FAQ"> 16) What is the custody & trust component of security token exchange?</Link></p>
    <p><Link to="/STO-FAQ"> 17) What is the legal component of security token exchange?</Link></p>
    <p><Link to="/STO-FAQ"> 18) What is the compliance component of a security token exchange?</Link></p>
    <p><Link to="/STO-FAQ"> 19) How Does The Trading Platform Work In Security Token Exchange?</Link></p>
    <p><Link to="/STO-FAQ"> 20) What are Equity Tokens?</Link></p>
    <p><Link to="/STO-FAQ"> 21) What Are Debt Tokens?</Link></p>
    <p><Link to="/STO-FAQ"> 22) What Are Derivatives?</Link></p>
    <p><Link to="/STO-FAQ"> 23) What is the state of security token in Europe?</Link></p>
    <p><Link to="/STO-FAQ"> 24) What is Markets In Financial Directive II (MiFID II)?</Link></p>
    <p><Link to="/STO-FAQ"> 25) What is the state of security token in Liechtenstein?</Link></p>
    <p><Link to="/STO-FAQ"> 26) The State Of Security Tokens In Germany</Link></p>
    <p><Link to="/STO-FAQ"> 27) What is the state of security tokens in Switzerland?</Link></p>
    <p><Link to="/STO-FAQ"> 28) What is the state of security tokens in Malta?</Link></p>
    <p><Link to="/STO-FAQ"> 29) What is the state of security tokens in Estonia?</Link></p>
    <p><Link to="/STO-FAQ"> 30) What is the state of security tokens in France?</Link></p>
    <p><Link to="/STO-FAQ"> 31) What is the state of security token in UK</Link></p>
    <p><Link to="/STO-FAQ"> 32) What is the scenario of security token scenario in Slovenia?</Link></p>
    <p><Link to="/STO-FAQ"> 33) What is the state of security tokens in Ecuador?</Link></p>
    <p><Link to="/STO-FAQ"> 34) Security Token Offering And Banking Security</Link></p>
    <p><Link to="/STO-FAQ"> 35) How Does The Security Token Work With The Financial Center?</Link></p>
    <p><Link to="/STO-FAQ"> 36) Tokenization Of Healthcare Assets</Link></p>
    <p><Link to="/STO-FAQ"> 37) How Security Tokens Could Be Implemented In Payment Systems?</Link></p>
    <p><Link to="/STO-FAQ"> 38) State of Security Token Offerings In Australia</Link></p>
    <p><Link to="/STO-FAQ"> 39) State Of Security Token Offering In Asian Countries</Link></p>
  </div>
);

const DummyContent1 = () => (
  <div>
    <p><Link to="/Decentralize-Faq"> 1) What is decentralized exchange ?</Link></p>
    <p><Link to="/Decentralize-Faq"> 2) What are the benefits of decentralized exchange ?</Link></p>
    <p><Link to="/Decentralize-Faq"> 3) Are there any drawbacks of decentralized exchange ?</Link></p>
    <p><Link to="/Decentralize-Faq"> 4) What are the key technical specifications of the decentralized exchange ?</Link></p>
    <p><Link to="/Decentralize-Faq"> 5) How businesses could deploy a decentralized exchange ?</Link></p>
    <p><Link to="/Decentralize-Faq"> 6) The growth and potential of decentralized exchanges.</Link></p>
    <p><Link to="/Decentralize-Faq"> 7) What is atomic swap and how it is used in decentralized exchange development ?</Link></p>
  </div>
);

const DummyContent2 = () => (
  <div>
    <p><Link to="/P2P-Faq"> 1) What is P2P Exchange ?</Link></p>
    <p><Link to="/P2P-Faq"> 2) Benefits of P2P Exchange:</Link></p>
    <p><Link to="/P2P-Faq"> 3) Market prediction for Cryptocurrency Exchange:</Link></p>
    <p><Link to="/P2P-Faq"> 4) Technology of  P2P Exchange and Earning Opportunity:</Link></p>
  </div>
);

const DummyContent3 = () => (
  <div>
    <p><Link to="/cryptocurrency-exchange-market-capitalization"> 1) What is marketing capitalization of cryptocurrency exchange?</Link></p>
  </div>
);

const DummyContent4 = () => (
  <div>
    <p><Link to="/alternate-trading-system"> 1) What Is An Alternative Trading System?</Link></p>
  </div>
);



export default index;