import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

import CryptopaymentGateway  from "../../Images/Crypto_payment_Gateway/CryptopaymentGateway.png";

export default class STOWebPage extends Component {
  render() {
    return (
      <div>
        <Row className="lavragemarginex-section">    
          <Col sm="7">
                <div className="lavragemarginex-content">
                    {/* <h2>What are leverage and margin exchange?</h2> */}
                    <p>At Blockchain App Factory, we help you develop a secure cryptocurrency wallet and payment solutions of your own.We bring expertise in deliveringa customized, multi-currency and secure web and mobile wallet with attractive UI/UX design, which can be used daily. </p>
                    <p>It empowers you to offer payment options through  QR Code, NFC or URL to your users. We ensure the utmost security, safety, and reliability in our gateway, as it is well equipped with liquidity from the trusted exchange and third-party liquidity providers. You can also integrate your hot and cold wallets into the payment gateway. </p>
                </div>    
            </Col>
            <Col sm="5">
                <img src={CryptopaymentGateway} alt="CryptopaymentGateway" title="CryptopaymentGateway" className="img-fluid" />
            </Col>
        </Row>  
       

       
      </div>
    )
  }
}
