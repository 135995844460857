import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";

//import Mainbanner from './banner';
import Mainabout from './About-Section1';
import Mainexperts from './About-Section2';
import Mainspecial from './About-Section3';
import Maiechangework from './About-Section4';

export default class index extends Component {

  componentDidMount() {
    ReactGA.initialize('UA-125025994-1')
    ReactGA.pageview(document.location.pathname)
    ReactGA.pageview(document.location.path)
  }

  scrollPositions = {}
  componentWillMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"
    }
    window.scrollTo(0, 0)
  }

  render() { 
    return (
      <div>
        {this.props.children}
        <Helmet>
            <title>Best Exchange Software Solutions Company - Espay Exchange</title>
            <meta name="description" content="Espay Exchange is the leading global exchange solution provider of enterprise digital asset, 
            forex exchange and security token exchange software development. Contact us today."/>
            <meta property="og:title" content="Best Exchange Software Solutions Company - Espay Exchange" />
            <meta property="og:description" content="Espay Exchange is the leading global exchange solution provider of enterprise digital asset, 
            forex exchange and security token exchange software development. Contact us today." />
            <meta name="twitter:title" content="Best Exchange Software Solutions Company - Espay Exchange" />
            <meta name="twitter:description" content="Espay Exchange is the leading global exchange solution provider of enterprise digital asset, 
            forex exchange and security token exchange software development. Contact us today." />
            
            <link rel="canonical" href="https://www.espay.exchange/about-us" />
        </Helmet>

        <Container fluid={true} className="AboutusBanner-bg">
          {/* <Container>
            <Mainbanner />
          </Container> */}
        </Container>

        <Container>
          <Mainabout />
        </Container>
       
       <Container fluid={true} className="AboutusTrading-bg">
          <Container>
            <Mainexperts />
          </Container>
        </Container>

        <Container>
          <Mainspecial />
        </Container>

        <Container fluid={true} className="AboutusTrading-bg">
          <Container>
            <Maiechangework />
          </Container>
        </Container>

        <ScrollUpButton className="ScrollUpButton"/>
        
      </div>
    )
  }
}

