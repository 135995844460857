import React, { Component } from 'react'
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

export default class Tokensclassified extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">How are tokens classified?</h2>
                            <table class="Faqtable"><tr><th></th><th>Currency/Asset</th><th>Security</th><th>Equity</th><th>Utility</th><th>Debt</th></tr><tr><td>Definition</td><td>Tokens with the primary function to serve as digital money. Unlike all other tokens, this is property with inherent value. These tokens do not represent third-party value and take their price from market forces like a standard commodity.</td><td>Tokens which function as a traditional security asset. They represent a stake in the wealth created by a third party and take their value from that party’s success or failure. Distinct from an equity token in that no ownership of the underlying venture is created.</td><td>Tokens which function as a traditional stock asset. They represent ownership of some third-party asset or venture and take their value from that property’s success or failure.</td><td>Platform-dedicated tokens which serve as the medium or “coupon” for blockchain platform services. They take their value from the value of their dedicated platform.</td><td>Tokens which represent outstanding debts and liabilities. They take their value from the debt, its interest and the creditworthiness of the debtor party.</td></tr><tr><td>Real World Analogue</td><td>Gold, silver, dollars, and euros</td><td>Some mutual funds and ETFs, nonequity investments made against capital rather than debt. This security type is relatively uncommon outside of blockchain.</td><td>Stocks, futures, and options contracts</td><td>Arcade tokens</td><td>Bonds, mortgages</td></tr><tr><td>Regulatory Approach</td><td>Hands off by the SEC but increasingly scrutinized by the IRS.</td><td>Treated as a standard securities transaction by the SEC. </td><td>Treated as a standard securities transaction by the SEC.</td><td>Hands off by the SEC, although the commission is skeptical of this category’s legitimacy.</td><td>Treated as a standard debt security transaction by the SEC.</td></tr></table>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
