import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';

import Faqleftpanel from "../Faqleftpanel";

export default class Whatp2pexchange extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is p2p exchange?</h2>
                            <p>P2P Buyers suit Bitcoin Sellers exchanges. There are people who trad with other people back and forth. Essentially, the sellers set their own rates and what kinds of payments they accept. Most accept PayPal, bank transfers, credit cards and hard cash, also cold. The P2P exchange software development operates the trading platform more like a dating app, matching those involved in cryptocurrency trading. They take a small fee for the matchmaking operation, of course.</p>
                            <p>Centralized exchanges provide access to the cryptocurrency markets in a secure and trust-based platform. But there is a large community who prefer to do trading in trust-less environment and with different payment method as well. A peer-to-peer (P2P) service is a decentralized platform whereby two individuals interact directly with each other, without inter-mediation by a third-party. Instead, the buyer and the seller transact directly with each other. Where regular crypto exchanges offer to trade cryptos against cryptos or against a limited set of fiat currencies, these P2P Exchanges offer wide range of trading and payment options for buying and selling Cryptos. Added to this P2P Exchanges require less resources compared to the regular exchange platforms. Hence, they are able to attract a large number of traders and huge amount of trading volume by charging them very less amount of fees on larger trades. Well, lower transaction charge is not the only advantage P2P exchanges offer to the traders. P2P Exchange is basically like an e-commerce type trading of coin/token and which has security over wallets which handles transaction on trading exchange. Buyer or seller can post an ad with the amount of cryptocurrencies they want to buy or sell along with expected price, currency they want to trade against, method of payment and many other options platforms allow them to choose from. Peer to Peer exchange allows buyers and sellers to negotiate on price before a coin/token is transferred. The transaction here are faster than the regular crypto exchanges and security is also a prime feature of P2P Exchanges.</p>
                            <h2 className="topinnertitle">What are the main players in P2P Exchange?</h2>
                            <ul>
                                <li>LocalBitcoins</li>
                                <li>Paxful</li>
                                <li>Remitano</li>
                                <li>WazirX</li>
                                <li>Coinbase</li>
                                <li>XCoins</li>
                            </ul>
                            <p>Probably there are others, but those are the two biggest and most respected, with LocalBitcoins as the chief. Every has its pros and cons.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
