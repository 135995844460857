import React, { Component } from 'react';
//import graph1 from 'https://youtu.be/xRTjX9qWwLY';
import ImgpshFullsizeAnim1 from '../../Images/Landing/Imagelending1.gif';

class Tp_section26 extends Component {
  render() {
    return (
      <div className="banner">
      <img className="img-fluid" src={ImgpshFullsizeAnim1}
      alt="Global cryptocurrency market capitalization"
      title="Global cryptocurrency market capitalization"
      />
        {/* <video id="player" class="video-js" autoPlay>
           <source src={graph1} type="video/mp4"></source>
         </video> */}
      </div>
    );
  }
}

export default Tp_section26;