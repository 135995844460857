import React, { Component } from 'react';
import { Row, Col,TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import CryptoPoolTrading1 from '../../Images/hybrid/CryptopoolTrading.png';
import CommunityTrading from '../../Images/hybrid/CommunityTrading.png';
import Enterprisesegment from '../../Images/hybrid/EnterpriseSegment.png';
import Reliability from '../../Images/hybrid/Reliabilityhybrid.png';
import ProfessionalTeam from '../../Images/hybrid/ProfessionalTeam.png';
import classnames from 'classnames';

class exchange_hybrid_section3 extends Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          activeTab: '1'
        };
      }
    
      toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
      }
    render() {
      return (
        <div> 
          <Row> 
              <Col sm={12}>
                <div className="hybridtitle">
                  <h2>Benefits For Hybrid Digital Asset Exchange</h2>
                  <p>Espay’s unique hybrid model comprises multiple benefits of centralized as well as decentralized
                      platforms.</p>
                </div>
              </Col>
          </Row> 
          <Row>
            <Nav tabs>
              <NavItem className="HybridTabmenu">
                <NavLink
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => { this.toggle('1'); }}
                >
                  Crypto Pool Trading
                </NavLink>
              </NavItem>
              <NavItem  className="HybridTabmenu">
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
                >
                  Community Trading
                </NavLink>
              </NavItem>
              <NavItem  className="HybridTabmenu">
                <NavLink
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => { this.toggle('3'); }}
                >
                  Enterprise segment
                </NavLink>
              </NavItem>
              <NavItem  className="HybridTabmenu">
                <NavLink
                  className={classnames({ active: this.state.activeTab === '4' })}
                  onClick={() => { this.toggle('4'); }}
                >
                  Reliability
                </NavLink>
              </NavItem>
              <NavItem  className="HybridTabmenu">
                <NavLink
                  className={classnames({ active: this.state.activeTab === '5' })}
                  onClick={() => { this.toggle('5'); }}
                >
                  Professional Team
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                    <Row className="HybridTabmenuDis">
                        <Col sm={3} className="text-center">
                            <img className="img-fluid" src={CryptoPoolTrading1} alt="Crypto Pool Trading" title="Crypto Pool Trading" />
                        </Col>
                        <Col sm={9}>
                            <p>Our platform is proficient to obtain high trading volumes and makes considerable profits. Pool trading aids 
                                in diffusion your risk while investing in top 30 digital coins on the market.</p>
                        </Col>
                    </Row>        
              </TabPane>
              <TabPane tabId="2">
                    <Row className="HybridTabmenuDis">
                        <Col sm={3} className="text-center">
                            <img className="img-fluid" src={CommunityTrading} alt="Community Trading" title="Community Trading" />
                        </Col>
                        <Col sm={9}>
                            <p>Espay’s decentralized hybrid solutions let users upload their own smart contracts to take part in an active 
                                smart contract community to trade and boost exposure.</p>
                        </Col>
                    </Row>      
              </TabPane>
              <TabPane tabId="3">
                    <Row className="HybridTabmenuDis">
                        <Col sm={3} className="text-center">
                            <img className="img-fluid" src={Enterprisesegment} alt="Enterprise Segment" title="Enterprise Segment" />
                        </Col>
                        <Col sm={9}>
                            <p>Exchange platforms offered by Espay contains High-performance technology. Developed for multi-asset-class 
                                trading & execution solutions for enterprise platform.</p>
                        </Col>
                    </Row>      
              </TabPane>
              <TabPane tabId="4">
                    <Row className="HybridTabmenuDis">
                        <Col sm={3} className="text-center">
                            <img className="img-fluid" src={Reliability} alt="Reliability" title="Reliability" />
                        </Col>
                        <Col sm={9}>
                            <p>Working with sensitive detail trained us how to develop stable & reliable software for trading, banking 
                                and investment zones in the FinTech industry.</p>
                        </Col>
                    </Row>      
              </TabPane>
              <TabPane tabId="5">
                    <Row className="HybridTabmenuDis">
                        <Col sm={3} className="text-center">
                            <img className="img-fluid" src={ProfessionalTeam} alt="Professional Team" title="Professional Team" />
                        </Col>
                        <Col sm={9}>
                            <p>A team of cryptocurrency experts is functioning every day to advance the platform and to satisfy customers’
                                ever-changing needs with the unique solutions.</p>
                        </Col>
                    </Row>      
              </TabPane>
            </TabContent>
          </Row>
                 
        </div>
      );
    }
}

export default exchange_hybrid_section3;