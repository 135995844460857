import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import OrderMatching from '../../Images/MarketingResearch/key-components-icon-1.png';
import FundManagement from '../../Images/MarketingResearch/key-components-icon-2.png';
import SmartContracts from '../../Images/MarketingResearch/key-components-icon-3.png';
import EscrowSystem from '../../Images/MarketingResearch/key-components-icon-4.png';
import CurrencyTransactions from '../../Images/MarketingResearch/key-components-icon-5.png';
import PaymentGateway from '../../Images/MarketingResearch/key-components-icon-6.png';


export default class KeyComponentsSTE extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm={12}>
                        <div className="keysyetitle">
                            <h3>Key Components of a Security Token Exchange</h3>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <div className="key-box-main">
                            <div className="key-box-left">
                                <div className="key-blue">
                                    <div className="key-box-icon">
                                        <img src={OrderMatching} alt="Order Matching" title="Order Matching" />
                                    </div>
                                    <div className="key-box-details">
                                        <span>Order Matching</span>
                                        <p>The exchange comprises an order matching engine that automatically pairs similar orders between buyers and sellers at the best market price. It also matches the different orders depending on the market and trading limits.</p>
                                    </div>
                                </div>
                                <div className="key-blue">
                                    <div className="key-box-icon">
                                        <img src={SmartContracts} alt="Smart Contracts" title="Smart Contracts" />
                                    </div>
                                    <div className="key-box-details">
                                        <span>Smart Contracts</span>
                                        <p>A smart contract is a computer-generated protocol that automatically triggers a verified transaction and completes it without any human interference. It makes each transaction trackable, secure, and irreversible.</p>
                                    </div>
                                </div>
                                <div className="key-blue">
                                    <div className="key-box-icon">
                                        <img src={CurrencyTransactions} alt="Currency Transactions" title="Currency Transactions" />
                                    </div>
                                    <div className="key-box-details">
                                        <span>Currency Transactions</span>
                                        <p>Any cryptocurrency like Bitcoin, Ethereum or Litecoin or any fiat-currency like the US Dollar and the Chinese RMB can be embedded into the exchange.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="key-box-left">
                                <div className="key-green">
                                    <div className="key-box-icon">
                                        <img src={FundManagement} alt="Fund Management" title="Fund Management" />
                                    </div>
                                    <div className="key-box-details">
                                        <span>Fund Management</span>
                                        <p>A wallet is integrated to manage all security token transactions, which are automated. Users can also control fiat-cryptocurrency conversion manually through the in-built processing interface with the associated banks.</p>
                                    </div>
                                </div>
                                <div className="key-green">
                                    <div className="key-box-icon">
                                        <img src={EscrowSystem} alt="Escrow System" title="Escrow System" />
                                    </div>
                                    <div className="key-box-details">
                                        <span>Escrow System</span>
                                        <p>Any transaction between the buyer and seller using a smart contract goes to the escrow system. Once the responsible parties agree to the payment details, the escrow releases the asset without any delays.</p>
                                    </div>
                                </div>
                                <div className="key-green">
                                    <div className="key-box-icon">
                                        <img src={PaymentGateway} alt="Payment Gateway" title="Payment Gateway" />
                                    </div>
                                    <div className="key-box-details">
                                        <span>Payment Gateway Integration</span>
                                        <p>The exchange platform integrates a payment gateway that allows users to buy and sell tokens using fiat currencies through credit cards, debit cards and other payment methods.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
