import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import BenefitsSecure from '../../Images/BenefitsSecure.png';
import BenefitsSecure3 from '../../Images/BenefitsSecure3.png';
import BenefitsSecure1 from '../../Images/BenefitsSecure1.png';
import BenefitsSecure4 from '../../Images/BenefitsSecure4.png';
import BenefitsSecure6 from '../../Images/BenefitsSecure6.png';
import BenefitsSecure5 from '../../Images/BenefitsSecure5.png';

class white_Label_Section3 extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm="12">
                    <div className="Whitelabeltitle">
                        <h2>Benefits For Centralized Exchange White Label</h2>
                        <p className="white_title">Our <strong>white label exchange software</strong> helps you to reduce overall expenses, increase 
                    clienteles, drive<br/> more volume and much more.</p>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="4"> 
                    <div className="benefitswhitelabel">
                        <img className="img-fluid" src={BenefitsSecure} alt="Cutting-Edge Technology" title="Cutting-Edge Technology" />                         
                        <h3>Cutting-Edge Technology</h3> 
                        <p>Developers at Espay keep eyes on the continuous updates in technology to deliver cutting-edge exchange 
                            solutions in each corner of the world.</p>
                    </div>
                </Col>
                <Col sm="4"> 
                    <div className="benefitswhitelabel"> 
                        <img className="img-fluid" src={BenefitsSecure3} alt="Fast Time to Market" title="Fast Time to Market" />                       
                        <h3>Fast Time to Market</h3> 
                        <p>Trading platforms from Espay are developed and optimized in parallel with the preparation of production
                                which reduces time to market.</p>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="benefitswhitelabel">  
                        <img className="img-fluid" src={BenefitsSecure1} alt="Ease of operations management" title="Ease of operations management" />                        
                        <h3>Ease of operations management</h3> 
                        <p>A secure and cutting-edge administrative panel offered by Espay’s crypto exchanges aid you with robust 
                            analytics, advanced alert settings, risk monitoring and much more.</p>
                    </div>
                </Col>
            </Row> 
            <Row>
                <Col sm="4"> 
                    <div className="benefitswhitelabel"> 
                        <img className="img-fluid" src={BenefitsSecure4} alt="Developed in-house" title="Developed in-house" />                        
                        <h3>Developed in-house</h3> 
                        <p>Crypto exchanges which are manufactured in-house aids you keep track of modifications and scale 
                            production & enables to establish fixes in a timely fashion.</p>
                    </div>
                </Col>
                <Col sm="4"> 
                    <div className="benefitswhitelabel">  
                        <img className="img-fluid" src={BenefitsSecure6} alt="Fast customer on-boarding" title="Fast customer on-boarding" />                      
                        <h3>Fast customer on-boarding</h3> 
                        <p>Espay’s easy onboarding, simple e-KYC and fast deposits features make it easy to buy and sell the 
                            world’s top tokens.</p>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="benefitswhitelabel"> 
                        <img className="img-fluid" src={BenefitsSecure5} alt="Go to the market in weeks" title="Go to the market in weeks" />                         
                        <h3>Go to the market in weeks</h3> 
                        <p>Trading platforms from Espay are developed and optimized in parallel with the preparation of production
                                which reduces time to market.</p>
                    </div>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default white_Label_Section3;