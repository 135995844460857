import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import HealthcareAssetsimg from '../../../Images/faq/HealthcareAssets.png';

export default class HealthcareAssets extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">Tokenization Of Healthcare Assets</h2> 
                            <img src={HealthcareAssetsimg} alt="Health care Assets" title="Health care Assets" className="img-fluid faqimg"/>
                            <p>As a result of the tremendous potential of data tokenization, many industries are leveraging the benefits and the impact to manage and secure assets. Essentially, the idea of data tokenization is to streamline the process of managing assets, via integration of blockchain technology with real-life assets. To be specific, one of the most prominent interests is in the healthcare industry, and particularly on ways through which data tokenization improves patient security. The application of tokenization solutions to situation under HIPAA is now a common thing in the industry. That is, healthcare enterprises can tap from the benefits of the incredible security provided by the technology.</p>
                            <p>Moreover, it makes the process of complying with certain HIPAA regulations more convenient for administrators, through replacement of the traditional electronic protected health information (ePHI) and non-public personal information (NPPI) with a tokenized value. That way, patients' information can be more easily accessible and protected. While it may just be clear in about a year or two whether security token offerings, STOs have become generally acceptable, they are currently on their way.</p>
                            <p>In another dimension, the U.S. Food and Drug Administration (FDA) are currently encouraging the use of Cooperative Research and Development Agreements (CRADAs) to enhance public-private partnerships, while other initiatives within the context are occurring at the State level. Interestingly, this is not just limited to the United States, as we have similar picture worldwide.</p>
                            <p>Thus, to incentivize patients to further engage in healthy behavior, for the purpose of simplifying the legal and mechanical approach to security tokens investment, a decentralized healthcare is poised to attract cryptohealth. It can also contribute to necessary factional ownership.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
