import React from 'react';
import { Container,  Col, Row } from 'reactstrap';
import scrollToComponent from 'react-scroll-to-component';
import faq1 from '../../Images/decfaq1.png';
import faq2 from '../../Images/decfaq2.png';
import ScrollUpButton from "react-scroll-up-button";

class Decentralize_Faq extends React.Component {

  constructor(props) {
    super(props);
    this.scrollToTopWithCallback = this.scrollToTopWithCallback.bind(this)
  }

  componentDidMount() {
    scrollToComponent(this.innerfaqcontent, { offset: -60, align: 'top', duration: 500, ease:'inExpo'});
  }

  scrollToTopWithCallback() {
    let scroller = scrollToComponent(this.Violet, { offset: -75, align: 'top', duration: 1500});
    scroller.on('end', () => console.log('Scrolling end!') );
  }

  render() {
    return (
      <div className='main'>
       <h2 className="topinnertitle">Decentralize Exchange</h2>
            <div className="button_groupmain">
            <button className="button-faq" onClick={this.scrollToTopWithCallback}>
                1) What is decentralized exchange (DEX)</button>
            <button className="button-faq" onClick={() => scrollToComponent(this.Indigo, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
                2) Benefits of DEX
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.Blue, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
                3) Drawbacks of DEX
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.Orange, { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
                4) Technical specifications of DEX
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.Grey , { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
                5) DEX for businesses
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.Pink , { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
                6) Growth & future of DEX
            </button>
            <button className="button-faq" onClick={() => scrollToComponent(this.Purple , { offset: -60, align: 'top', duration: 700, ease:'inExpo'})}>
                7) Atomic Swap & DEX
            </button>
            </div>
        <Container>
            <section className='innerfaq1content' ref={(section) => { this.Violet = section; }}>
                <Row>
                    <Col sm={{size:11,offset:1}} className="faqdiffbor">
                        <h4>1) What is decentralized exchange ? </h4>
                            <p>A decentralized exchange (DEX) is a cryptocurrency exchange which operates in a decentralized way, i.e., without a central authority, without relying on a third-party service to hold the customer's funds. Instead, trades occur directly between users (peer to peer) through an automated process. This system can be achieved by creating proxy tokens (crypto assets that represent a certain fiat or crypto currency) or assets (that can represent shares in a company for example) or through a decentralized multi-signature escrow system, among other solutions that are currently being developed.</p>
                            <p>Because users do not need to transfer their assets to the exchange, decentralized exchanges reduce the risk of theft from hacking of exchanges. Decentralized exchanges can also prevent price manipulation or faked trading volume through wash trading and are more anonymous than exchanges which implement know your customer requirements.</p>
                            <p>A decentralized exchange can still have centralized components, whereby some control of the exchange is still in the hands of a central authority. A notable example being IDEX blocking New York State users from placing orders on the platform.</p>
                    </Col>
                </Row>
            </section>
        </Container>
        <Container>
            <section className='innerfaq2content' ref={(section) => { this.Indigo = section; }}>
                    <Row>
                    <Col sm={{size:11,offset:1}} className="faqdiffbor">
                            <h4>2) What are the benefits of decentralized exchange ?</h4>
                            <ul>
                                <li>
                                    <p>Trustless nature</p>
                                </li>
                                <li>
                                    <p>Funds are held by you in your personal wallet and not by a third party</p>
                                </li>
                                <li>
                                    <p>Users are not required to disclose their personal details (except if the exchange method involves bank transfers, in this case only the buyer or seller from which you will buy\sell will only know your details)</p>
                                </li>
                                <li>
                                    <p>No Hacker activity</p>
                                </li>
                                <li>
                                    <p>No Server Downtime</p>
                                </li>
                        </ul>
                        </Col>
                    </Row>
            </section>
        </Container>
        <Container>
            <section className='innerfaq3content' ref={(section) => { this.Blue = section; }}>     
            <Row>
            <Col sm={{size:11,offset:1}} className="faqdiffbor">
                    <h4>3) Are there any drawbacks of decentralized exchange ?</h4>
                        <ul>
                            <li>
                                <p>Due to a lack of KYC process and no way to revert a transaction, users are at a loss if they are ever hacked for their passwords or private keys.</p>
                            </li>
                            <li>
                                <p>Low Liquidity</p>
                            </li>
                            <li>
                                <p>Only Basic features for Trade available</p>
                            </li>
                        </ul>
                </Col>
            </Row>
            </section>
        </Container>
         <Container>
            <section className='innerfaq4content' ref={(section) => { this.Orange = section; }}>
                <Row>
                <Col sm={{size:11,offset:1}} className="faqdiffbor">
                        <h4>4) What are the key technical specifications of the decentralized exchange ?</h4>
                           <p>These exchanges use the Ethereum platform as their underlying technology and run as a series of smart contracts. In other words, there is no central point where the technology is stored, run or managed. Radar Relay has a team of open source contributors rather than a traditional incorporation structure.</p>
                           <p>It is important to know that these technologies are still early, low volume and very slow compared to all other types of centralised service. A small enthusiast audience do use truly decentralised exchanges, but their volume is tiny when compared to the rest of the crypto markets.</p>
                           <p>It is too early to say what the standard AML / KYC (or CDD) rules will look like in this arena, but there is no reason why a DEX could not implement rules or adhere to a code of conduct. Even though their “management team” does not have a clear nexus, they could still comply with global standards and local market regulations.</p>
                           <p>As an aside, this points to the potential for “decentralised market structure”. Which is an area of potential significant innovation from a resilience perspective. In addition, decentralised market structures have the potential to limit the systemic risk of FMI’s to be too big to fail (given they can be owned and operated by many parties at once).</p>
                    </Col>
                </Row>
            </section>
        </Container>
        <Container>
            <section className='innerfaq5content' ref={(section) => { this.Grey = section; }}>
                <Row>
                    <Col sm={{size:11,offset:1}} className="faqdiffbor">
                            <h4>5) How businesses could deploy a decentralized exchange ?</h4>
                            <p>There is reason to be optimistic about the future of decentralized exchanges, however, as advances like the 0x protocol could enable DEXs to offer solutions comparable to centralized exchanges</p>
                            <p>With 0x, market making, and buyer & seller interactions occur off-chain, before transaction settlement occurs on-chain. Exchanges operating using 0x (called 'relayers'), can thus offer more complex revenue models through schemes like fee splitting with other exchanges using 0x, reserve manager models where exchanges contribute to each other's liquidity (earning a share of trading fees as affiliates), and similar solutions that improve user experience off chain, while retaining the integrity of a blockchain settlement layer. In doing so, exchanges operating with the 0x protocol should be able to address issues such as poor liquidity and slippage.</p>
                            <p>One example of a novel solution to the user experience challenges faced by decentralized exchanges, facilitated using 0x, is ERC dEX's Aqueduct tool. The primary focus of Aqueduct is creating an operable open-order book solution where users of the ERC dEX relayer have access to buyers and sellers across other 0x operators. This means a wider pool of options to match orders. This system creates both a wider circle, and also creates incentives for users to add to this circle.</p>
                            <p>Another major aspect of the Aqueduct solution will be the addition of API layers that will help users access detailed, granular information on trade history, order data, and other potentially useful information.</p>
                        </Col>
                    </Row>
            </section>
        </Container>
        <Container>
            <section className='innerfaq6content' ref={(section) => { this.Pink = section; }}>
            <Row>
                <Col sm={{size:11,offset:1}} className="faqdiffbor">
                        <h4>6) The growth and potential of decentralized exchanges.</h4>
                            <img src={faq1} title="The growth and potential of decentralized exchanges." alt="The growth and potential of decentralized exchanges." />
                            <p>24hr volume indicates the USD value for number of tokens traded on an exchange in a 24 hour period (UTC times used). Numbers are extremely large thus the 'E+..' notation is used in most cases. I.e. 7.91+E14 equals ~$791000000000000.</p>
                            <img src={faq2} title="The growth and potential of decentralized exchanges." alt="The growth and potential of decentralized exchanges." />
                    </Col>
                </Row>
                </section>
        </Container>
        <Container>
            <section className='innerfaq7content' ref={(section) => { this.Purple = section; }}>
            <Row>
                <Col sm={{size:11,offset:1}} className="faqdiffbor">
                        <h4>7) What is atomic swap and how it is used in decentralized exchange development ?</h4>
                            <p>Atomic swap is a smart contract technology that enables exchange of one cryptocurrency for another without using centralized intermediaries, such as exchanges.</p>
                            <p>Atomic swaps can take place directly between blockchains of different cryptocurrencies or they can be conducted off-chain, away from the main blockchain.</p>
                            <p>Atomic swaps solve the problem of time, complexity and interoperability between all currency blockchains through the use of Hash Timelock Contracts (HTLC). As its name denotes, HTLC is a time-bound smart contract between parties that involves the generation of a cryptographic hash function, which can be verified between them. </p>
                            <p>Simply, atomic swaps require both parties to acknowledge receipt of funds within a specified timeframe using a cryptographic hash function. If one of the involved parties fails to confirm the transaction within the timeframe, then the entire transaction is voided, and funds are not exchanged. The latter action helps remove counterparty risk.</p>                       
                    </Col>
                </Row>
                </section>
        </Container>

        <ScrollUpButton className="ScrollUpButton"/>

      </div>
    )
  }
}

export default Decentralize_Faq;