import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';

import Fractionalicon from '../../Images/NFT/Factorial-Ownership.png';
import Globalmarketicon from '../../Images/NFT/Global-market-access.png';
import Uniqueicon from '../../Images/NFT/Unique-immutable-tokens.png';
import DigitalOwnershipicon from '../../Images/NFT/Digital-Ownership.png';
import EasilyRecoverableicon from '../../Images/NFT/Easily-Recoverable.png';
import Noncounteicon from '../../Images/NFT/Non-counterfeit.png';
import noninteroperableicon from '../../Images/NFT/Non-interoperable.png';
import Transparencyicon from '../../Images/NFT/Transparency.png';
import EasilyTransferableicon from '../../Images/NFT/EasilyTransferable.png';
import Secureicon from '../../Images/NFT/Secure-on-chain-aseet.png';


export default class FeaturesTokens extends Component {
    render() {
        return (
            <Fragment>
                <div className="featurestokens">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <h3>Features of Non-Fungible Tokens</h3>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col md={12}> 
                                <ul>
                                    <li>
                                        <div className="fettokensbox">
                                            <img src={Fractionalicon} alt="Fractional Ownership" title="Fractional Ownership" />
                                            <span>Fractional Ownership</span>
                                        </div>
                                    </li>
                                    
                                    <li>
                                        <div className="fettokensbox">
                                            <img src={DigitalOwnershipicon} alt="Digital Ownership License" title="Digital Ownership License" />
                                            <span>Digital Ownership License</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="fettokensbox">
                                            <img src={EasilyRecoverableicon} alt="Easily Recoverable" title="Easily Recoverable" />
                                            <span>Easily Recoverable</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="fettokensbox">
                                            <img src={Transparencyicon} alt="Transparency" title="Transparency" />
                                            <span>Transparency</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="fettokensbox">
                                            <img src={Noncounteicon} alt="Non-Counterfeit" title="Non-Counterfeit" />
                                            <span>Non-Counterfeit</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="fettokensbox">
                                            <img src={noninteroperableicon} alt="Non-Interoperable" title="Non-Interoperable" />
                                            <span>Non-Interoperable</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="fettokensbox">
                                            <img src={EasilyTransferableicon} alt="Easily Transferable" title="Easily Transferable" />
                                            <span>Easily Transferable</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="fettokensbox">
                                            <img src={Secureicon} alt="Secure On-chain Asset" title="Secure On-chain Asset" />
                                            <span>Secure On-chain Asset</span>
                                        </div>
                                    </li>
                                    
                                    <li>
                                        <div className="fettokensbox">
                                            <img src={Uniqueicon} alt="Unique Immutable Tokens" title="Unique Immutable Tokens" />
                                            <span>Unique Immutable Tokens</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="fettokensbox">
                                            <img src={Globalmarketicon} alt="Global Market Access" title="Global Market Access" />
                                            <span>Global Market Access without Intermediaries</span>
                                        </div>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>            
                </div>
            </Fragment>
        )
    }
}
