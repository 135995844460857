import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

import WhatareICO  from "../../Images/ICO_Marketing/WhatareICOs.png";

export default class WhatareICOs extends Component {
  render() {
    return (
      <div>
        <Row className="whatisico-section">   
            <Col sm="7">
                <div className="stoadviceitle">
                    <h2>What are ICOs? </h2>
                    <p>When a startup wants to raise their funds, initial Coin Offering can be of great help which allows startups by offering investors crypto tokens or cryptocurrencies for fiat and other cryptocurrencies. Startups don't have to rely on any traditional mean of fundraising and institutions.</p>
                </div>    
            </Col>
            <Col sm="5">
                <img src={WhatareICO} alt="WhatareICOs" title="WhatareICOs" className="img-fluid ieoconsultation-image" />
            </Col>
        </Row>  
       

       
      </div>
    )
  }
}
