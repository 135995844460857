import React, { Component } from 'react';
import {Row,Col} from 'reactstrap';

import Investorimg from '../../Images/MarketingResearch/marketing-pf-img1.png';
import Offeringimg from '../../Images/MarketingResearch/marketing-pf-img2.png';
import Barriersimg from '../../Images/MarketingResearch/marketing-pf-img3.png';
import Securityimg from '../../Images/MarketingResearch/marketing-pf-img4.png';

export default class CurrentMarketPerformance extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col md={12}>
                        <div className="currentmarket-title">
                            <h3>Current Market Performance for Security Token Exchange Development</h3>
                            <p>Owing to the complex legal framework, you can extend your services to the accredited investors, startups, and (to some extent) even non-accredited investors.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={9} className="order-2 order-md-1">
                        <div className="currentmarket-details">
                            <span>Investor Sentiment</span>
                            <p>While everyone is looking at security token exchanges as the best solution to liquidity problem, investors largely remain skeptical. The ICO downfall in 2017 is partly responsible for this. More than 80% of the ICOs conducted in 2017 have been identified as scams till date.</p>
                            <p>However, leading stock exchanges in Malta, Gibraltar, London, Canada, and Australia have already started developing STE solutions of their own. This will help generate a more positive investor sentiment in 2019 and beyond.</p>
                        </div>
                    </Col>
                    <Col md={3} className="order-1">
                        <img className="img-fluid" src={Investorimg} alt="Current Market Performance" title="Current Market Performance"/>
                    </Col>
                </Row>
                <Row className="mt-50">
                    <Col md={3}>
                        <img className="img-fluid" src={Offeringimg} alt="Offering and Missing" title="Offering and Missing"/>
                    </Col>
                    <Col md={9} className="text-right">
                        <div className="currentmarket-details">
                            <span>What Key Players Are Offering and Missing</span>
                            <p>Existing security token exchanges are offering STEs that are fully compliant with the respective regulatory frameworks. These exchanges come with strict KYC/AML verification, easy-to-customize architecture, and in some cases, an over-the-counter (OTC) exchange option.</p>
                            <p>However, the primary concern is providing connectivity across different exchanges. It will generate a swift flow of security tokens across exchanges, resulting in increased liquidity and globalized trading access.</p>
                        </div>
                    </Col>
                </Row>

                <Row className="mt-50">
                    <Col md={9} className="order-2 order-md-1">
                        <div className="currentmarket-details">
                            <span>Entry Barriers</span>
                            <p>The lack of a global legal framework and infrastructure has resulted in the lack of advanced financial instruments. In the present legal framework, establishing a legal connection between controlling a security token and having a valid claim against the underlying asset is often tricky. As a result, you mostly see equity-based security tokens being traded. Hopefully, regulators will be able to eliminate such entry barriers with time.</p>
                        </div>
                    </Col>
                    <Col md={3} className="order-1">
                        <img className="img-fluid" src={Barriersimg} alt="Entry Barriers" title="Entry Barriers"/>
                    </Col>
                </Row>

                <Row className="mt-50">
                    <Col md={3}>
                        <img className="img-fluid" src={Securityimg} alt="Security Issues and Hacks" title="Security Issues and Hacks"/>
                    </Col>
                    <Col md={9} className="text-right">
                        <div className="currentmarket-details">
                            <span>Security Issues and Hacks</span>
                            <p>Security is also a significant obstacle. Bancor, which is launching a security token exchange soon, was recently hacked. Cybercriminals hacked into its cryptocurrency exchange and stole $23.5 million of cryptocurrency tokens belonging to its users.</p>
                            <p>Another cyber attack on Newdex, a decentralized token exchange, resulted in the theft of $58,000 in cryptocurrency directly from users. Hackers flooded the exchange with one billion fake EOS to illegally purchase BLACK, IQ, and ADD tokens from the exchange.</p>
                        </div>
                    </Col>
                </Row>
                
            </div>
        )
    }
}
