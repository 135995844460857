import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import Securitytoken from '../../../../Images/securitytoken.png';

class Security_Modules_Section1 extends Component {
    render() {
      return (
        <div> 
            <Row> 
                <Col sm="6">
                    <div className="Security_Modules">
                        <p>Security is an important aspect for any of the software. Espay’s professionals understand the significance when it comes to strong integration of software security and develops crypto exchange software consequently.</p>
                        <p>Leverage reliable<Link to="/"
                            title="Espay Exchange"> <strong> digital asset exchange platform</strong> </Link> from Espay that comprises internal system level security for your crypto trading business. Serve your traders with more secure and more efficient crypto trading platform and give importance to their privacy.</p>
                    </div>
                </Col>
                <Col sm="6" className="mt-30">
                    <img className="img-fluid" src={Securitytoken} alt="SecurityToken" title="SecurityToken" />
                </Col>  
            </Row>          
        </div>
      );
    }
}

export default Security_Modules_Section1;