import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

import STOCommunityMarketing from "../../Images/STO/STO-CommunityMarketing.png";
import EmailMarketing from "../../Images/STO/STO-email-marketing.png";
import RoadshowsEvents from "../../Images/STO/RoadshowsEvents.png";


export default class CommunityMarketing extends Component {
  render() {
    return (
      <div className="commmarketing">
          <Row>   
            <Col sm="6">
                <div className="marketingbox MarketingComman">
                    <h3>STO Community Marketing</h3>
                    <p>Community marketing is the cornerstone of any STO marketing strategy. Community management requires thorough planning and a lot of time to be really efficient. As a full-service STO marketing agency we provide for the development of your user community and the continuous and constant STO Community Management on Facebook, Twitter, Telegram, Bitcointalk, Steemit, Slack, Reddit and Quora. In implementing the STO marketing campaign, we prioritize the development of a community and start with this task in the first phases.</p>
                </div>
            </Col>
            <Col sm="6">
                <img src={STOCommunityMarketing} alt="STO Community Marketing" title="STO Community Marketing" className="img-fluid" />
            </Col>
          </Row>
          <Row>
            <Col sm="6">
                <img src={EmailMarketing} alt="STO E-Mail Marketing" title="STO E-Mail Marketing" className="img-fluid" />
            </Col>
            <Col sm="6">
                <div className="marketingbox MarketingComman">
                    <h3>STO E-Mail Marketing</h3>
                    <p>Our STO marketing agency uses the email addresses of your investors through different methods such as: For example, using newsletters on your STO website and through various marketing channels such as social media or telegram. Later, we will create a tailor-made e-mail marketing strategy of at least 5 steps, with the aim of positively influencing the investment decision of your potential investors.</p>
                </div>
            </Col>
          </Row>
          <Row>  
            <Col sm="6">
                <div className="marketingbox MarketingComman">
                    <h3>STO Roadshows and Events</h3>
                    <p>STOs are more complex in their development than ICOs. The marketing strategy is no exception - while an ICO can theoretically only be marketed online, an STO project also requires offline marketing in order to fully exploit its potential for success. Through our wide network of partners, the STO marketing agency Espay Exchange can assist you in the planning of your own roadshows and events. Furthermore, our partners can represent their STO project at STO and Blockchain events and introduce them to the audience with a sales talk.</p>
                </div>
            </Col>
            <Col sm="6">
                <img src={RoadshowsEvents} alt="STO Roadshows and Events" title="STO Roadshows and Events" className="img-fluid" />
            </Col>
        </Row>
      </div>
    )
  }
}
