import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import DebtTokensimg from '../../../Images/faq/DebtTokens.jpg';

export default class DebtTokens extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What Are Debt Tokens?</h2> 
                            <img src={DebtTokensimg} alt="Debt Tokens" title="Debt Tokens" className="img-fluid faqimg"/>
                            <p>Debt tokens are issued out by lenders and represent debt owned by a company. They can be thought of as loans or IOUs often with an interest rate multiplied or compounded against the principal amount loaned (invested) to a company. They are a type of capital raised through debt that enables the buying and selling of loans within a high-liquidity environment. Depending on the wording of the legal agreements, as well as the structure and functions available in the token, debt tokens may incur unique tax and reporting requirements for anyone issuing, or in some jurisdictions even transacting with, the token.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
