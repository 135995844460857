import React, { Component } from 'react';
import { Row, Col} from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {faCreditCard} from '@fortawesome/free-solid-svg-icons';
import Highperformance from '../../Images/decentralized/Highperformance.png';
import Securitybest from '../../Images/decentralized/Securitybest.png';
import speedscalability from '../../Images/decentralized/speedscalability.png';
import Reliabilitydecentralized from '../../Images/decentralized/Reliabilitydecentralized.png';

class business_decentralized_section3 extends Component {
    render() {
      return (
        <div > 
            <Row>   
                <Col sm="12">
                    <div className="Decentralizedtitle">
                        <h2>How Decentralized Exchange Helps Your Business?</h2>
                        <p>Transform your business with Espay’s Smart contracts decentralized exchange development that makes your
                            business wholly distributed and secure.</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="6">
                    <div className="DecentralizedHelpbox">
                        <Row sm="6">
                            <Col sm="2">                                   
                                {/* <FontAwesomeIcon icon={faCreditCard} size="3x"  />                                 */}
                                <img className="img-fluid" src={Securitybest} alt="Security at its best" title="Security at its best" />
                            </Col>
                            <Col sm="10">
                                <h3>Security at its best</h3>
                                <p>Trading solutions from Espay are developed to be secured. Skilled developers have experienced to build 
                                    secure products based on the cutting-edge standards.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="DecentralizedHelpbox">
                        <Row sm="6">
                            <Col sm="2">                                  
                                {/* <FontAwesomeIcon icon={faCreditCard} size="3x"  /> */}
                                <img className="img-fluid" src={Reliabilitydecentralized} alt="Reliability" title="Reliability" />
                            </Col>
                            <Col sm="10">
                                <h3>Reliability</h3>
                                <p>Working with sensitive detail trained us how to develop stable & reliable software for trading, 
                                    banking and investment zones in the FinTech industry.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>  
            <Row>
                <Col sm="6">
                    <div className="DecentralizedHelpbox">
                        <Row sm="6">
                            <Col sm="2">                                   
                                {/* <FontAwesomeIcon icon={faCreditCard} size="3x"  />*/}
                                <img className="img-fluid" src={Highperformance} alt="High performance" title="High performance" />
                            </Col>
                            <Col sm="10">
                                <h3>High performance</h3>
                                <p>A liquidity-ready trading platform with multiple coins, fiat gateways, and supreme 
                                    safety – without spending heavily in development as well as infrastructure.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="6">
                    <div className="DecentralizedHelpbox">
                        <Row sm="6">
                            <Col sm="2">                                  
                                {/* <FontAwesomeIcon icon={faCreditCard} size="3x"  />*/}
                                <img className="img-fluid" src={speedscalability} alt="Speed & Scalability" title="Speed & Scalability" />
                            </Col>
                            <Col sm="10">
                                <h3>Speed & Scalability</h3>
                                <p>Our decentralized crypto exchanges are completely scalable as well as work rapidly. This is a 
                                    significant ability for any trading and investment platforms.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>             
        </div>
      );
    }
}

export default business_decentralized_section3;