import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import IssuancePlatforms from '../../Images/MarketingResearch/security-token-img-1.png';
import BrokersDealers from '../../Images/MarketingResearch/security-token-img-2.png';
import Investors from '../../Images/MarketingResearch/security-token-img-3.png';
import CustodiansTrustees from '../../Images/MarketingResearch/security-token-img-4.png';
import LegalAdvisors from '../../Images/MarketingResearch/security-token-img-5.png';
import TradingPlatforms from '../../Images/MarketingResearch/security-token-img-6.png';


export default class STEEcosystem extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm={12}>                    
                        <div className="ecoheading-title">
                            <h3>Security Token Exchange Ecosystem</h3>
                        </div>
                    </Col>
                </Row> 

                <Row>
                    <Col sm={12}>   
                        <div className="security-token-box">
                            <Row>
                                <Col md={3} lg={2}>
                                    <img src={IssuancePlatforms} alt="Issuance Platforms" title="Issuance Platforms" />
                                </Col>
                                <Col  md={9} lg={10}>
                                    <span>Issuance Platforms</span>
                                    <p>Issuance platforms allow startups and business owners to tokenize and sell their real-world assets to global investors. Most platforms offer the technology to tokenize different assets, ranging from real estate to fine art.</p>
                                    <p>For example, Ethereum (a brand from Polymath) is one of the leading security token issuance platforms with their own ST-20 token standard for token development. It also has tie-ups with exchange platforms such as tZero to allow immediate trading.</p>
                                </Col>
                            </Row>    
                        </div>
                    </Col>

                    <Col sm={12}>   
                        <div className="security-token-box">
                            <Row>
                                <Col md={3} lg={2}>
                                    <img src={BrokersDealers} alt="Brokers and Dealers" title="Brokers and Dealers" />
                                </Col>
                                <Col md={9} lg={10}>
                                    <span>Brokers and Dealers</span>
                                    <p>Brokers and dealers can buy, sell, distribute, and trade security tokens on behalf of their clients, providing the much-needed liquidity. However, you need to hire a licensed broker/dealer.</p>
                                </Col>
                            </Row>    
                        </div>
                    </Col>

                    <Col sm={12}>   
                        <div className="security-token-box">
                            <Row>
                                <Col md={3} lg={2}>
                                    <img src={Investors} alt="Investors" title="Investors" />
                                </Col>
                                <Col md={9} lg={10}>
                                    <span>Investors</span>
                                    <p>Investors are the clients that buy and sell security tokens with the aim to make a profit. There are two types of investors, accredited and non-accredited investors. Usually, accredited investors include high net worth individuals (HNWI) or financial institutions such as banks, insurance companies, and trusts. They can buy unregistered security tokens. On the other hand, non-accredited investors are the small guys that don't meet the net worth requirements by SEC or its equivalent.</p>
                                </Col>
                            </Row>    
                        </div>
                    </Col>

                    <Col sm={12}>   
                        <div className="security-token-box">
                            <Row>
                                <Col md={3} lg={2}>
                                    <img src={CustodiansTrustees} alt="Custodians and Trustees" title="Custodians and Trustees" />
                                </Col>
                                <Col md={9} lg={10}>
                                    <span>Custodians and Trustees</span>
                                    <p>Custodians and trustees are individuals or organizations that will hold the underlying asset in the name of the investors. Their job is to protect the asset from damage or theft.</p>
                                </Col>
                            </Row>    
                        </div>
                    </Col>

                    <Col sm={12}>   
                        <div className="security-token-box">
                            <Row>
                                <Col md={3} lg={2}>
                                    <img src={LegalAdvisors} alt="Legal Advisors" title="Legal Advisors" />
                                </Col>
                                <Col md={9} lg={10}>
                                    <span>Legal Advisors</span>
                                    <p>Legal advisors comprise lawyers and law firms that provide investors, issuers, and other individuals in the security token exchange ecosystem with the required legal advice.</p>
                                </Col>
                            </Row>    
                        </div>
                    </Col>

                    <Col sm={12}>   
                        <div className="security-token-box">
                            <Row>
                                <Col md={3} lg={2}>
                                    <img src={TradingPlatforms} alt="Trading Platforms" title="Trading Platforms" />
                                </Col>
                                <Col md={9} lg={10}>
                                    <span>Trading Platforms</span>
                                    <p>The actual trading platform is where all the action takes place using smart contracts. You can find primary and secondary trading platforms. Primary platforms offer both, issuance and trading services for security tokens, while secondary platforms offer only trading facilities.</p>
                                </Col>
                            </Row>    
                        </div>
                    </Col>

                </Row>  
            </div>
        )
    }
}
