import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import whitelabelexchange from '../../Images/whitelabelexchange.png';

class platform_lable_Section1 extends Component {
    render() {
      return (
        <div className="mt-30 mb-30"> 
            <Row>
                <Col sm="6">
                    <img src={whitelabelexchange} 
                    className="img-fluid"
                    alt="White Label Bitcoin And Cryptocurrency Exchange Platform"
                    title="White Label Bitcoin And Cryptocurrency Exchange Platform"
                    />
                </Col>
                <Col sm="6">
                    <div className="Whitelabeltitle">
                        <h2>White Label Bitcoin And Cryptocurrency Exchange Platform</h2>
                        <p>Setup your real-time white-label exchange software on the go with Espay’s robust and secure trading 
                            platform. We have expertise in delivering <strong>white-label bitcoin exchange software solutions</strong> in each corner
                            of the world. There are a plethora of established exchanges which are trusted us and our trading 
                            platforms to establish their business. To develop and design different white-label platform development 
                            service Espay contains the best group of professionals on board.</p>
                        <p>Our exchange software provides an all-inclusive solution and is the only thing you need to setup a
                            successful cryptocurrency exchange. Espay offers the world’s most popular exchanges used by the largest
                            enterprises, corporations and Fintech companies. Our exchange software supports multiple major digital
                            coins such as Bitcoin, Litecoin, Bitcoin Cash, Ethereum, XRP, etc. Brokers can anticipate every feature 
                            needed as administrator and control their customer’s trading accounts in real-time.</p>
                    </div>
                </Col>
            </Row>
        </div>
      );
    }
}

export default platform_lable_Section1;