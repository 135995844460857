import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import fe_manhosting from '../../Images/fe_manhosting.png';
import fe_management from '../../Images/fe_management.png';
import fe_trading from '../../Images/fe_trading.png';
import fe_designing from '../../Images/fe_designing.png';
import fe_order from '../../Images/fe_order.png';
import fe_trreport from '../../Images/fe_trreport.png';
import fe_security from '../../Images/fe_security.png';
import fe_mobileapp from '../../Images/fe_mobileapp.png';
import fe_hosting from '../../Images/fe_hosting.png';

import features from './HomeData';


class Section3 extends Component {
    render() {
        const features_h2       = features[3].uniquefeaturesdata.fields.features_h2;
        const features_dis      = features[3].uniquefeaturesdata.fields.features_dis;
        const features_title1   = features[3].uniquefeaturesdata.fields.features_title1;
        const features_subdis1  = features[3].uniquefeaturesdata.fields.features_subdis1;
        const features_title2   = features[3].uniquefeaturesdata.fields.features_title2;
        const features_subdis2  = features[3].uniquefeaturesdata.fields.features_subdis2;
        const features_title3   = features[3].uniquefeaturesdata.fields.features_title3;
        const features_subdis3  = features[3].uniquefeaturesdata.fields.features_subdis3;
        const features_title4   = features[3].uniquefeaturesdata.fields.features_title4;
        const features_subdis4  = features[3].uniquefeaturesdata.fields.features_subdis4;
        const features_title5   = features[3].uniquefeaturesdata.fields.features_title5;
        const features_subdis5  = features[3].uniquefeaturesdata.fields.features_subdis5;
        const features_title6   = features[3].uniquefeaturesdata.fields.features_title6;
        const features_subdis6  = features[3].uniquefeaturesdata.fields.features_subdis6;
        const features_title7   = features[3].uniquefeaturesdata.fields.features_title7;
        const features_subdis7  = features[3].uniquefeaturesdata.fields.features_subdis7;
        const features_title8   = features[3].uniquefeaturesdata.fields.features_title8;
        const features_subdis8  = features[3].uniquefeaturesdata.fields.features_subdis8;
        const features_title9   = features[3].uniquefeaturesdata.fields.features_title9;
        const features_subdis9  = features[3].uniquefeaturesdata.fields.features_subdis9;

      return (
        <div className="UniqueFeatures"> 
            <Row>
                <Col sm="12">
                    <div className="UniqueFeaturesInfo">
                        <h2>{features_h2}</h2>
                        <p>{features_dis}</p>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="4">                         
                    <div className="UniqueFeaturesBox">
                        <img src={fe_manhosting} alt="Self-Managed Hosting Solutions" title="Self-Managed Hosting Solutions" />
                        <h3>{features_title1}</h3>
                        <p>{features_subdis1}</p>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="UniqueFeaturesBox">
                        <img src={fe_management} alt="Currency Pair Management" title="Currency Pair Management" />
                        <h3>{features_title2}</h3>
                        <p>{features_subdis2}</p>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="UniqueFeaturesBox">
                        <img src={fe_trading} alt="Trading Market Setup" title="Trading Market Setup" />
                        <h3>{features_title3}</h3>
                        <p>{features_subdis3}</p>
                    </div>
                </Col>
            </Row>         
            <Row>
                <Col sm="4">                         
                    <div className="UniqueFeaturesBox">
                        <img src={fe_designing} alt="Exchange UI Designing" title="Exchange UI Designing" />
                        <h3>{features_title4}</h3>
                        <p>{features_subdis4}</p>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="UniqueFeaturesBox">
                        <img src={fe_order} alt="Full liquidity order book" title="Full liquidity order book" />
                        <h3>{features_title5}</h3>
                        <p>{features_subdis5}</p>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="UniqueFeaturesBox">
                        <img src={fe_trreport} alt="Trading Report Management" title="Trading Report Management" />
                        <h3>{features_title6}</h3>
                        <p>{features_subdis6}</p>
                    </div>
                </Col>
            </Row> 
            <Row>
                <Col sm="4">                         
                    <div className="UniqueFeaturesBox">
                        <img src={fe_security} alt="Security (KYC and AML and 2FA)" title="Security (KYC and AML and 2FA)" />
                        <h3>{features_title7}</h3>
                        <p>{features_subdis7}</p>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="UniqueFeaturesBox">
                        <img src={fe_mobileapp} alt="Mobile App" title="Mobile App" />
                        <h3>{features_title8}</h3>
                        <p>{features_subdis8}</p>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="UniqueFeaturesBox">
                        <img src={fe_hosting} alt="Managed Hosting Solutions" title="Managed Hosting Solutions" />
                        <h3>{features_title9}</h3>
                        <p>{features_subdis9}</p>
                    </div>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default Section3;