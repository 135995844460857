import React, { Component } from 'react';
import { Row, Col, Jumbotron} from 'reactstrap';
import { Link } from "react-router-dom";

class Banner extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm={{size:8,offset:2}}>                       
                    <Jumbotron>
                        <div className="bannerdetails">
                            <h1>Bitcoin & Cryptocurrency Exchange Software</h1>
                            <p>Best centralized crypto exchange for the businesses that are interested in creating their own trading platform. 
                                Our high-quality solutions are all you need to set up a successful exchange.</p>
                             <Link to="/talk-to-crypto-exchange-expert">Talk To Our Experts</Link>
                        </div>
                    </Jumbotron>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default Banner;