import React, { Component } from 'react';
import { Row, Col} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core';
import {faCreditCard, faServer,faExchangeAlt} from '@fortawesome/free-solid-svg-icons';
library.add(faCreditCard,faExchangeAlt,faServer);

class cryptocurrency_section4 extends Component {
    render() {
      return (
        <div>
            <Row>
                <Col sm="12">
                    <div className="Software_Development_heading">
                        <h2>Our Exchange Software Development Comprise Of</h2>
                        <p>Espay develops extensively compatible and performance-centric<strong> trending exchanges</strong> to
                            leverage best out of<strong> peer-to-peer blockchain technology.</strong></p>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="4">                         
                    <div className="Software_Development_box">
                        <FontAwesomeIcon icon="credit-card" size="3x" />
                        <h3>Digital Asset Exchange</h3>
                        <p>Offer a secure platform that can be trusted without any second thought.
                            We offer a wide array of security features containing standard areas of best practice.</p>
                        <Link title="Customized Exchange Software Solution" to="/crypto-exchange-software">Read More</Link>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="Software_Development_box">
                        <FontAwesomeIcon icon="server" size="3x"/>
                        <h3>Security Token Exchange</h3>
                        <p>Convert financial investments & instruments into tokens uniquely providing a 
                            single trading platform for security tokens & crypto with the affluence of liquidity.</p>
                        <Link title="Customized Exchange Software Solution" to="/crypto-exchange-software">Read More</Link>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="Software_Development_box">
                        <FontAwesomeIcon icon="exchange-alt" size="3x"/>
                        <h3>Forex Exchange Solutions</h3>
                        <p>Espay is a trusted face globally, whether you’re searching for wholesale/retail 
                            currency exchange platform you are assured a safe and suitable service.</p>
                        <Link title="Customized Exchange Software Solution" to="/crypto-exchange-software">Read More</Link>
                    </div>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default cryptocurrency_section4;