import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import EquityTokens from '../../../Images/faq/EquityTokens.jpg';

export default class Equitytokens extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What are Equity Tokens?</h2> 
                            <img src={EquityTokens} alt="Equity Tokens" title="Equity Tokens" className="img-fluid faqimg"/>
                            <p>Equity tokens are the most common form of security tokens and in many cases, investors believe that the terms equity and security token are synonymous. On the contrary – they do not mean the same thing and the terms should not be used interchangeably. Part of what makes equity tokens so attractive to investors outside the crypto space is their similarity to equity shares in a company. These tokens earn issuers the capital they need to develop a network, and in exchange, investors purchasing equity tokens could earn returns such as dividends and in some cases, the right to vote on company proposals.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
