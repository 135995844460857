import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import WhiteLabelExchangeDesh from '../../Images/WhiteLabelExchangeDesh.jpg';

class software_lable_Section6 extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm="12">
                <div className="Whitelabeltitle">
                    <h2>Why Choose Espay For White Label Exchange Software?</h2>
                    <p className="title_p">Espay offers a secure as well as fully customized, liquidity ready exchange software for Startup.</p>
                </div>
                </Col>
                
            </Row> 
            <Row>
                <Col sm="6" className="mb-30">
                    <img className="img-fluid" src={WhiteLabelExchangeDesh} alt="White Label Exchange Software?"
                     title="White Label Exchange Software?" /> 
                </Col>
                <Col sm="6" className="mt-30">
                    <p>Espay’s platform is a turn-key solution, to begin with a <strong>white-label crypto exchange</strong> or brokerage. It 
                        delivers an unmatched crypto exchange experience combined with different back-end solutions. Our
                        white-label platform empowers real-time trading of multiple crypto assets with that to simplify deposits
                        and withdrawals.</p>
                    <p>At Espay, we consider leading-edge technology to enable financial markets ahead. On the field of trading, 
                        the important factor apart from the strategy is buying and selling software that you utilize. And we are 
                        delivering the most proficient and wide-ranging trading software which offers you control over the trade.</p>
                </Col>
            </Row>                 
        </div>
      );
    }
}

export default software_lable_Section6;