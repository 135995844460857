import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route , Switch, Redirect } from "react-router-dom";

//components
import Home from '../Components/Home';
import Crypto from '../Components/Crypto_Exchange';
import Centralized from '../Components/Centralized';
import Whitelable from '../Components/White_lable';
import Standard from '../Components/Standard';
import Enterprise from '../Components/Enterprise';
import Decentralized from '../Components/Decentralized';
import Hybrid from '../Components/Hybrid';
import Security_Token_Exchange from '../Components/Security-Token-Exchange';
import P2PExchange from '../Components/P2PExchange';
import vision_mision from '../Components/Vision_Mision';
import About from '../Components/About';
//import Contact from '../Components/Contact_Us';
import Contact from '../Components/Contactus';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Copyright from '../Components/CopyrightFooter';
import Terms from '../Components/TermsOfUse';
import Privacy from '../Components/PrivacyPolicy';
import Cookie from '../Components/CookiePolicy';
//import Page_not_found from '../Components/Page_not_found';
import Integrations from '../Components/Features/Back_And_Features/Integrations';
import Currency from '../Components/Features/Back_And_Features/Currency_And_Pairs';
import Trading from '../Components/Features/Back_And_Features/Trading-Report-Management';
import Reconciliation from '../Components/Features/Back_And_Features/Trading_And_Reconciliation';
import uldesign from '../Components/Features/Front_And_Features/exchange_ui_design';
import Security from '../Components/Features/Front_And_Features/Security_Modules_And_Features';
import Trading_Dashboard from '../Components/Features/Front_And_Features/Trading_dashboard';
import Mobile_Trading_Platform from '../Components/Features/Front_And_Features/Mobile_Trading_Platform';
import Trading_Chart from '../Components/Features/Front_And_Features/Trading_Chart';
import Mass_Communication from '../Components/Features/Front_And_Features/Mass_Communication';
import Wallet_Management from '../Components/Features/Front_And_Features/Wallet_Management';
import Marketing_Features from '../Components/Features/Front_And_Features/Marketing_Features';
import Trading_Market_Setup from '../Components/Features/Front_And_Features/Trading_Market_Setup';
import Dynamic_CMS_Management from '../Components/Features/Front_And_Features/Dynamic_CMS_Management';

import STOAdvice from '../Components/STOOffering-Advice';
import STOProgramming from '../Components/STOProgramming';
import STOlegal from '../Components/STOlegal';
import STOmarketing from '../Components/STOOffering-Marketing'

import Talk_Expart_Call from '../Components/TalkToOurExperts/index';
import Thank_You from '../Components/Thank_You/index';
import QuoteRequest from '../Components/QuoteRequest/index';

import Faq from '../Components/Faq/index';
import stofaq from '../Components/Faq/STO_Faq';
import decfaq from '../Components/Faq/Decentralize_Faq';
import p2pfaq from '../Components/Faq/P2P_Faq';

import MarketingCapitalization from '../Components/Faq/marketing_capitalization';
import AlternateTradingSystem from '../Components/Faq/AlternateTradingSystem';
import Landing from '../Components/Landing/index';
import cryptocurrency_exchange_features from  '../Components/cryptocurrency-exchange-features/index';
import MarketingResearch from  '../Components/MarketingResearch/index';
import Whitepaper from '../Components/Whitepaper/index';
import sitemap from '../Components/SiteMap/index';
import Bookdemo from '../Components/Bookdemo';
import BookingExchangeThankyou from '../Components/Thank_You/BookingExchangeThankyou';
import Derivativeexchange from '../Components/DerivativeExchangesoftware/index';
import Derivativeexchangesoluction from '../Components/DerivativeExchangesoftwaresolution/index';
import Cryptocurrencyderivatives from '../Components/Cryptocurrencyderivatives/index';

import IEOValidation from '../Components/IEO/IEOValidation/index';
import IeoConsulting from '../Components/IEOConsulting/index';

import Derivative from '../Components/Derivative';
import Cryptopaymentgatwat from '../Components/CryptoPaymentGateway';
import ICOMarketing from '../Components/IEOMarketing';

import Faqurl from './faqurl';

import LeverageMargin from '../Components/LeverageMargin';
import OtcDesk from '../Components/OtcDesk';
import Mobile_Trading_Terminal  from '../Components/MobileTradingTerminal';
import DerivativesTradingPlatform from '../Components/DerivativeTrading';
import GuestpostSubmissionGuideline from '../Components/GuestpostSubmission';
import NFTdata from '../Components/NFT/index';

function fireTracking() {
  ReactGA.pageview(window.location.hash);
}

export default class Index extends Component {
  render() {
    return (
      <Router>
        <div>
          <Header />
          <Switch>
              <Route onUpdate={fireTracking} exact path="/" component={Home} />
           
              <Route onUpdate={fireTracking} exact path="/crypto-exchange-software" component={Crypto} />
              <Redirect from='/crypto-exchange-solution' to='/crypto-exchange-software'/>            
              <Route onUpdate={fireTracking} exact path="/centralized-exchange-solutions" component={Centralized} />
              <Route onUpdate={fireTracking} exact path="/white-label-crypto-exchange-software" component={Whitelable} />
              <Redirect from='/centralized-white-label-exchange-software' to='/white-label-crypto-exchange-software'/>

              <Route onUpdate={fireTracking} exact path="/centralized-business-exchange-software" component={Standard} />
              <Route onUpdate={fireTracking} exact path="/centralized-enterprise-exchange-software" component={Enterprise} />
              <Route onUpdate={fireTracking} exact path="/decentralized-crypto-exchange-software" component={Decentralized} />
              <Redirect from='/decentralized-exchange-solutions' to='/decentralized-crypto-exchange-software'/>

              <Route onUpdate={fireTracking} exact path="/hybrid-crypto-exchange-software" component={Hybrid} />
              <Route onUpdate={fireTracking} exact path="/security-token-exchange" component={Security_Token_Exchange} />
              <Route onUpdate={fireTracking} exact path="/p2p-exchange-software-development" component={P2PExchange} />
              <Redirect from='/p2p-exchange-software' to='/p2p-exchange-software-development'/>

              <Route onUpdate={fireTracking} exact path="/vision-mission" component={vision_mision} />
              <Route onUpdate={fireTracking} exact path="/about-us" component={About} />
              <Route onUpdate={fireTracking} exact path="/contact-us" component={Contact} />
              <Route onUpdate={fireTracking} exact path="/terms-of-use" component={Terms} />
              <Route onUpdate={fireTracking} exact path="/privacy-policy" component={Privacy} />
              <Route onUpdate={fireTracking} exact path="/cookie-policy" component={Cookie} />
              <Route onUpdate={fireTracking} exact path="/Integrations" component={Integrations} />
              <Route onUpdate={fireTracking} exact path="/currency-and-pairs" component={Currency} />
              <Route onUpdate={fireTracking} exact path="/trading-report-management" component={Trading} />
              <Route onUpdate={fireTracking} exact path="/Trading-And-Reconciliation" component={Reconciliation} />
              <Route onUpdate={fireTracking} exact path="/security-modules-and-features" component={Security} />
              <Route onUpdate={fireTracking} exact path="/exchange-ui-design" component={uldesign} />
              <Route onUpdate={fireTracking} exact path="/Trading-dashboard" component={Trading_Dashboard} />
              <Route onUpdate={fireTracking} exact path="/mobile-trading-platform" component={Mobile_Trading_Platform} />
              <Route onUpdate={fireTracking} exact path="/trading-chart-for-exchange" component={Trading_Chart} />
              <Route onUpdate={fireTracking} exact path="/mass-communication-features" component={ Mass_Communication} />
              <Route onUpdate={fireTracking} exact path="/wallet-management" component={Wallet_Management} />
              <Route onUpdate={fireTracking} exact path="/marketing-features" component={Marketing_Features} />
              <Route onUpdate={fireTracking} exact path="/trading-market-setup" component={Trading_Market_Setup} />
              <Route onUpdate={fireTracking} exact path="/dyanmic-cms-for-managing" component={Dynamic_CMS_Management} />
              <Route onUpdate={fireTracking} exact path="/talk-to-crypto-exchange-expert" component={Talk_Expart_Call} />
              <Route onUpdate={fireTracking} exact path="/thank-you" component={Thank_You} />
              <Route onUpdate={fireTracking} exact path="/quote-request" component={QuoteRequest} />

              <Route onUpdate={fireTracking} exact path="/Faq" component={Faq} />
            
              <Route onUpdate={fireTracking} exact path="/STO-FAQ" component={stofaq} />
              <Route onUpdate={fireTracking} exact path="/Decentralize-Faq" component={decfaq} />
              <Route onUpdate={fireTracking} exact path="/P2P-Faq" component={p2pfaq} />        
         
              <Route onUpdate={fireTracking} exact path="/cryptocurrency-exchange-market-capitalization" component={MarketingCapitalization} />
              <Route onUpdate={fireTracking} exact path="/alternate-trading-system" component={AlternateTradingSystem} />
              <Route onUpdate={fireTracking} exact path="/invest-in-whitelabel-cryptocurrency-exchange" component={Landing} />
              <Route onUpdate={fireTracking} exact path="/cryptocurrency-exchange-features" component={cryptocurrency_exchange_features} />
              <Route onUpdate={fireTracking} exact path="/sitemap" component={sitemap} />
			        <Route onUpdate={fireTracking} exact path="/book-cryptocurrency-exchange-software-demo" component={Bookdemo} />
              <Route onUpdate={fireTracking} exact path="/thank-you-for-booking-exchange-demo" component={BookingExchangeThankyou} />

              <Route onUpdate={fireTracking} exact path="/security-token-offering-advice" component={STOAdvice} />
              <Route onUpdate={fireTracking} exact path="/security-token-offering-development" component={STOProgramming} />
              <Route onUpdate={fireTracking} exact path="/security-token-offering-legal" component={STOlegal} />
              <Route onUpdate={fireTracking} exact path="/security-token-offering-marketing" component={STOmarketing} />
              <Route onUpdate={fireTracking} exact path="/security-token-offering-exchange-marketing-research" component={MarketingResearch} />
              <Route onUpdate={fireTracking} exact path="/whitelabel-cryptocurrency-exchange-software-whitepaper" component={Whitepaper} />
              <Route onUpdate={fireTracking} exact path="/derivative-exchange-software-development-company" component={Derivativeexchange} />
              <Route onUpdate={fireTracking} exact path="/derivative-exchange-software-solution" component={Derivativeexchangesoluction} />
              <Route onUpdate={fireTracking} exact path="/cryptocurrency-derivatives-exchange-development" component={Cryptocurrencyderivatives} />
              
              <Route onUpdate={fireTracking} exact path="/ieo-consulting-services" component={IeoConsulting} />
              <Route onUpdate={fireTracking} exact path="/ieovalidation" component={IEOValidation} />
              
              <Route onUpdate={fireTracking} exact path="/leverage-margin-trading-exchange" component={Derivative} />
              <Route onUpdate={fireTracking} exact path="/crypto-payment-gateway" component={Cryptopaymentgatwat} />
              <Route onUpdate={fireTracking} exact path="/ico-marketing" component={ICOMarketing} />
              <Route onUpdate={fireTracking} exact path="/leverage-margin-trading-exchange" component={LeverageMargin} />
              <Route onUpdate={fireTracking} exact path="/otc-trading-desk-development" component={OtcDesk} />
              <Route onUpdate={fireTracking} exact path="/mobile-trading-terminal" component={Mobile_Trading_Terminal} />
              <Route onUpdate={fireTracking} exact path="/derivatives-trading-platform" component={DerivativesTradingPlatform} />
              <Route onUpdate={fireTracking} exact path="/write-for-us-guestpost-submission-guideline" component={GuestpostSubmissionGuideline} />
              <Route onUpdate={fireTracking} exact path="/nft" component={NFTdata} />
              
              {/* <Route onUpdate={fireTracking} exact path="/testform" component={Testform} /> */}
              <Faqurl />
             
              
                            
              {/* <Route path="/sitemap.xml" component={sitemapxml} /> */}
              {/* <Route component={Page_not_found} /> */}
              
              <Redirect from='/Blog' to='/'/>
              {/* <Route path='*'  from='*' exact={true} component={Home} /> */}
            </Switch>     
          <Footer />
          <Copyright />
        </div>
      </Router>
    );
  }
}