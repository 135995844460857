import React, { Component } from 'react'
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import HowTokenizationWorks from '../../../Images/faq/HowTokenizationWorks.jpg';

export default class Doestokenizationwork extends Component {
    render() {
        return (
            <div className="main">
            <Container fluid={true}>
                <Row>
                    <Col md={4}>
                    <Faqleftpanel {...this.props} />
                    </Col>
                    <Col md={7} className="mx-auto">
                        <h2 className="topinnertitle">How does tokenization work?</h2>
                        <p>Let’s say an investor wants to tokenize a Diamond; they can create a tradable security token and put the Diamond into a legally binding trust. After the Diamond has gone into liquidity it will have multiple token holders, who now collectively own the asset. The Diamond’s security tokens are then stored on a blockchain smart contract and served as collateral. If multiple investors co-own the Diamond, then the smart contract becomes ‘multi-signature.’ Smart contracts are an algorithmic software license, which verifies the terms and conditions, in a conflict-free manner without third-party interference.</p>
                        <img className="img-fluid faqimg" src={HowTokenizationWorks} alt="tokenization work" title="tokenization work" />
                        <p>The token holders’ agreement will be encrypted and distributed equally, so their legally binding agreement cannot be lost or changed without their permission. Changes can only take place when both or all stakeholders agree on a given matter, which will help cement trust and security amongst investors.</p>
                        <p>Key benefits of tokenization</p>
                        <ul>
                            <li>Investors can trade real-world assets that due to their low liquidity would have been difficult to exchange in the past.</li>
                            <li>Tokens enhance the liquidity of previously hard assets with real estate being the prime example, with its low liquidity and large share of the global asset market.</li>
                            <li>Asset tokenization reduces barriers and will attract new investors through fractional ownership, as they can own a percentage of a real-world asset and repackage it with traditional bonds or hedge funds to resell in crypto marketplaces.</li>
                            <li>Tokenization increases portfolio diversification and spreads risk as investors can now co-own multiple assets at once.</li>
                            <li>Tokenization is a seamless process and speeds up previously difficult and costly trades by reducing administrative expenses through self-authorizing smart contracts, improving the speed of settlements.</li>
                            <li>Tokenized securities allow for businesses to lock in funds without locking investors in because the tokens are tradable on a secondary market. The business still gets to use the capital, but investors can exchange shares with each other.</li>
                            <li>With a token, you can code restrictions, enabling buyers and sellers to trade in a secondary market as long as the trade doesn’t violate the coded restrictions, making it easier for the cross border tradinng and even removing the need of central authority.</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
        )
    }
}
