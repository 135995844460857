import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";

import Mainbanner from './banner';
import Mainsoftwarestandard from './Enter-Secion1';
import Mainfeaturesstandard from'./Enter-Secion2';
import Mainplatformstandard from './Enter-Secion3';
import Mainofferingstandard from './Enter-Secion4';
import Mainplatform from './Enter-Secion5';
import Mainexhcangesoftware from './Enter-Secion6';
import Mainproduct from './Enter-Secion7';
import Blog from './Enter-Secion8';

import Certificate from '../Certificate/index';
import Faqmain from '../Faq/faq';

export default class index extends Component {

  componentDidMount() {
    ReactGA.initialize('UA-125025994-1')
    ReactGA.pageview(document.location.pathname)
    ReactGA.pageview(document.location.path)
  }
  
  scrollPositions = {}
  componentWillMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"
    }
    window.scrollTo(0, 0)
  }
  
  render() {
    return (

      <div>
        {this.props.children}
        <Helmet>
            <title>Cryptocurrency Exchange Development Company - Exchange Software Solution</title>
            <meta name="description" content="Espay specializes in best bitcoin &amp; cryptocurrency exchange platform development solutions. Our cryptocurrency developers are well aware of the security threats and they can develop secured codes."/>
            <meta property="og:title" content="Cryptocurrency Exchange Development Company - Exchange Software Solution" />
            <meta property="og:description" content="Cryptocurrency Exchange Development Company - Exchange Software Solution" />
            <meta name="twitter:title" content="Cryptocurrency Exchange Development Company - Exchange Software Solution" />
            <meta name="twitter:description" content="Cryptocurrency Exchange Development Company - Exchange Software Solution" />
            <link rel="canonical" href="https://www.espay.exchange/centralized-enterprise-exchange-software" />
        </Helmet>
        <Container fluid={true} className="enterpriseexchange-bg">
          <Mainbanner />
        </Container>
        
        <Container>
          <Mainsoftwarestandard />
        </Container>

        <Container fluid={true} className="enterprisefeatures-bg">
          <Container>
            <Mainfeaturesstandard />
          </Container>
        </Container>

        <Container fluid={true} className="enterprisebranded-bg">
          <Container>
            <Mainplatformstandard />
          </Container>
        </Container>

        <Container>
          <Mainplatform />
        </Container>

        <Container fluid={true} className="enterprisefeatures-bg">
          <Container>
            <Mainofferingstandard />
          </Container>
        </Container>

        <Container>
          <Mainexhcangesoftware />
        </Container>

        <Container fluid={true} className="enterpriseexchangeproduct">
          <Container>
            <Mainproduct /> 
          </Container>    
        </Container> 

        <Container fluid={true} className="commonblogsection">
          <Container>
            <Blog />
          </Container>
        </Container>   

        <Container fluid={true}>
          <Container>
            <Certificate />
          </Container>
        </Container>  

        <Container>
            <h2 className="faqmaintitle">Frequently Asked Questions</h2>
            <Faqmain />
          </Container>
        
        <ScrollUpButton className="ScrollUpButton"/>
      </div>
    )
  }
}

