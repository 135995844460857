import React, { Component, Fragment } from 'react';
import { Container, Row, Col} from 'reactstrap';
import { Link } from "react-router-dom";

export default class DerivativeexchangeBanner extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col xs={12} md={8} className="mx-auto">                             
                            <div className="bannerdetails">
                                <h1>Exchange Software Solutions <span>for Equities and Derivatives</span></h1>
                                <p>Full exchange infrastructure for stocks, options, futures and </p>
                                <p>cryptocurrency by Espay derivative software solutions</p>
                                <Link to="/talk-to-crypto-exchange-expert">Talk To Our Experts</Link>
                            </div>
                        </Col>
                    </Row> 
                </Container>
            </Fragment>
        )
    }
}
