import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
//import { Link } from "react-router-dom";

export default class Roadmap extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="cryderivativestitle">
                                <h2>Espay Crypto Derivatives Exchange Development Is Your Road Map To Success</h2>
                                <p>Years of experience in FinTech software development for various markets resulted in perfecting our exchange-traded cryptocurrency derivatives development</p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={4}>
                            <div className="derivetivehelpbox">
                                <i>1</i>
                                <span>Industry expertise</span>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="derivetivehelpbox">
                                <i>2</i>
                                <span>Web & Mobile Development</span>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="derivetivehelpbox">
                                <i>3</i>
                                <span>Integrated Connectivity</span>
                            </div>
                        </Col>
                       
                        <Col md={4}>
                            <div className="derivetivehelpbox">
                                <i>4</i>
                                <span>Testing & Automation</span>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="derivetivehelpbox">
                                <i>5</i>
                                <span>Optimization</span>
                            </div>
                        </Col>
                    </Row>
                </Container> 
            </Fragment>
        )
    }
}
