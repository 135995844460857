import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import CustodyTrust from '../../../Images/faq/CustodyTrust.jpg';

export default class Custodytrustcomponent extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is the custody & trust component of security token exchange?</h2> 
                            <img src={CustodyTrust} alt="custody & trust component" title="custody & trust component" className="img-fluid faqimg"/>
                            <p>After the Broker Dealers, Custody and Trust Providers are th essebtial part of this Security Token Ecosystem. Custody & Trust providers help hold tokenized assets — on behalf of clients — to protect them against theft or loss. These providers offer their services to multiple players in the security-token ecosystem, including investors, broker-dealers, and trading platforms.</p>
                            <p>Here are some firms who work as custody and trust provider:</p>
                            <ul>
                                <li>PrimeTrust</li>
                                <li>Six</li>
                                <li>Bankex</li>
                                <li>BitGo</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
