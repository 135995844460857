import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';

import Faqleftpanel from "../Faqleftpanel";

export default class WhatDigitalassetexchange extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is a digital asset exchange?</h2>
                            <p>Exchanges play a crucial role in the economy of the crypto-currency. They have market liquidity, and the ability of customers to purchase digital assets with relative ease and low cost. Ideally they will have tranquility of mind, additional resources to help buy and sell, and more advantages for traders.</p>
                            <p>You may also be familiar with 'centralized exchange solutions' as they represent both a plurality of exchanges, as well as a significant part of the overall amount of trade across all exchanges. However, decentralized exchanges are a recent trend that peaked user-and public interest in early 2018. Nevertheless, they have failed since then to top centralized exchanges in terms of the overall amount of trade. You can opt for white label crypto exchange software to start your own digital asset exchange.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
