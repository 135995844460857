import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Hybridmodel from '../../Images/hybrid/Hybridmodel.png';
import LiquidityManagement from '../../Images/hybrid/LiquidityManagement.png';
import EscrowManagement from '../../Images/hybrid/EscrowManagement.png';
import BankingGrade from '../../Images/hybrid/BankingGrade.png';
import Highperformance from '../../Images/hybrid/Highperformance.png';
import OrderBook from '../../Images/hybrid/OrderBook.png';

class features_hybrid_section2 extends Component {
    render() {
      return (
        <div> 
            <Row>  
                <Col sm={12}>
                    <div className="hybridtitle">
                        <h2>Hybrid Exchange Platform Features</h2>
                        <p>Espay is offering an all-in-one hybrid system for your advanced traders that will let them trade 
                            cryptocurrencies without worrying about the security of fund and sensitive information.</p>
                    </div>
                </Col>
            </Row> 
            <Row>
                <Col sm={4}>
                    <div className="HybridFeaturesServices">
                        <img className="img-fluid" src={Hybridmodel} alt="Hybrid Model" title="Hybrid Model" />
                        <h3>Hybrid Model</h3>
                        <p>Espay’s hybrid exchange platform combines features of both CEX and DEX models to leverage the best out of 
                            both: Support fiat currency, robust authentication, and transparency.</p>
                    </div>
                </Col>
                <Col sm={4}>
                    <div className="HybridFeaturesServices">
                        <img className="img-fluid" src={LiquidityManagement} alt="Liquidity Management" title="Liquidity Management" />
                        <h3>Liquidity Management</h3>
                        <p>We can get whole order book from third party exchange & display it on your crypto Exchange platform. It
                            surges the flow of crypto trading in the website which aid to get the users at first for your
                            business.</p>
                    </div>
                </Col>
                <Col sm={4}>
                    <div className="HybridFeaturesServices">
                        <img className="img-fluid" src={EscrowManagement} alt="Escrow Management" title="Escrow Management" />
                        <h3>Escrow Management</h3>
                        <p>Espay offers escrow management to serve the need of your digital coins buyers and sellers that safely 
                            keep crypto funds with commercial-grade safety as well as industry best practices.</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <div className="HybridFeaturesServices">
                        <img className="img-fluid" src={BankingGrade} alt="Banking Grade Security" title="Banking Grade Security" />
                        <h3>Banking Grade Security</h3>
                        <p>We have stimulated our systems & strengthened our platform with bank-grade security that assures each 
                            transaction are routed seamlessly in the highly protected environment.</p>
                    </div>
                </Col>
                <Col sm={4}>
                    <div className="HybridFeaturesServices">
                        <img className="img-fluid" src={OrderBook} alt="Atomic Swap Exchange Development" title="Atomic Swap Exchange Development" />
                        <h3>Full Liquidity Order Book</h3>
                        <p>Our offering includes more than one liquidity options from the launch of your trading platform to ensure full order book from.</p>
                    </div>
                </Col>
                <Col sm={4}>
                    <div className="HybridFeaturesServices">
                        <img className="img-fluid" src={Highperformance} alt="High Performance" title="High Performance" />
                        <h3>High Performance</h3>
                        <p>A liquidity-ready trading platform with multiple coins, fiat gateways, and supreme safety – without 
                            spending heavily in development as well as infrastructure.</p>
                    </div>
                </Col>
            </Row>                  
        </div>
      );
    }
}

export default features_hybrid_section2;