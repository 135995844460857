import React, { Component, Fragment } from 'react';
import { Row, Col, Container } from 'reactstrap';
//import { Link } from "react-router-dom";

import IEOservice from "../../Images/IEO/whatsincludedinourIEOconsultingService.png";

export default class ICOproject extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <div className="stoadviceitle">
                        <h2>Platforms for ICO advertising</h2>
                    </div>
                </Container>
                <div className="icoadvertise">

                    <Row className="icoadverstise-bg">
                        <Col sm="6"></Col>
                        <Col sm="4" className="icoproject-section">
                            <div className="stoadviceitle-text">
                                <h2>How to promote your ICO project?</h2>
                            </div>
                            <div className="presalesection">
                                <h3 className="ico-sub-title">Social media profile creation</h3>
                            </div>
                            <p>You cannot imagine a business advertising without social media platforms nowadays. You can even reach out to investors from different geographical locations through social media marketing. You can also use social media paid campaigns to reach out to your target audience. </p>
                            <p>You need to hire best copywriters who can create best content for the social media platforms. It would help you to get more traffic on your ICO website. </p>
                            <p>Some of the leading social media platforms are </p>
                        </Col>
                    </Row>

                    <Row className="icoadverstiseone-bg">
                        <Col sm="6"></Col>
                        <Col sm="4" className="icoproject-section">
                            <div className="presalesection">
                                <h3 className="ico-sub-title icoadverstise-text">Email marketing</h3>
                            </div>
                            <p>Though email marketing is considered as a traditional marketing strategy, it is yet Powerful and excellent strategy. First of all, you need to create a list of emails of potential investors and then reach out to them with a carefully crafted email which contains all the information. You can also put the hyperlinks of the ICO website and other social media platform links. </p>
                        </Col>
                    </Row>


                    <Row className="icoadverstisethree-bg">
                        <Col sm="6"></Col>
                        <Col sm="4" className="icoproject-section">
                            <div className="presalesection">
                                <h3 className="ico-sub-title icoadverstise-text">Right influencer marketing</h3>
                            </div>
                            <p>There are many ICO influencers and advisories who can be approached to promote your ICO project. They might charge some fees for their outreach help. Their recommendations can make a huge impact in the crypto investor world. </p>
                        </Col>
                    </Row>

                    <Row className="icoadverstisefour-bg">
                        <Col sm="6"></Col>
                        <Col sm="4" className="icoproject-section">
                            <div className="presalesection">
                                <h3 className="ico-sub-title icoadverstise-text">ICO bounty campaign</h3>
                            </div>
                            <p>Companies offer some rewards to individuals for participating in the ICO marketing campaigns and to create awareness among the crypto communities. These rewards are called ICO bounties. These bounties are given for writing blogs, identifying the bugs in the website or for other notable contribution. </p>
                        </Col>
                    </Row>


                    <Row className="icoadverstisefive-bg">
                        <Col sm="6"></Col>
                        <Col sm="4" className="icoproject-section">
                            <div className="presalesection">
                                <h3 className="ico-sub-title icoadverstise-text ">Press release</h3>
                            </div>
                            <p>Create feature-rich and detailed press releases and distribute to some online press release publishing websites to attest more investors for your ICO project. When your press release is posted on reputed websites, your project's stature and value increase. </p>
                            <p>Some of the reputed press release publishing websites are </p>
                            <p><span>Reuters</span> <span>Yahoo Finance</span> <span>CCN</span> <span>Forbes</span> <span>Entrepreneur.com</span></p>
                        </Col>
                    </Row>

                    <Row className="icoadverstisesix-bg">
                        <Col sm="6"></Col>
                        <Col sm="4" className="icoproject-section">
                            <h3 className="ico-sub-title icoadverstise-text"> Content marketing</h3>
                            <p>Highly effective and carefully crafted content marketing strategy can work supremely for your ICO project. Create content that is carefully crafted by the experienced content writers and can offer value, information and relevance to the readers and then distribute the content on various channels to attest more visibility and traffic. </p>
                            <p>Some of the content creating strategies are </p>
                            <p><span>Blog creation</span> <span>Video creation</span> <span>PR distribution </span></p>
                        </Col>
                    </Row>


                    <Row className="icoadverstiseseven-bg">
                        <Col sm="6"></Col>
                        <Col sm="4" className="icoproject-section">
                            <div className="presalesection">
                                <h3 className="ico-sub-title icoadverstise-text">Branding kit for offline events</h3>
                            </div>
                            <p>We want to ensure a consistent message is communicated in the online as well as offline events. Presence in offline event enhances reputation and trust in the ICO with the crypto-enthusiasts and investors ready for on-the-spot deals. We ensure that our clients attend the best of the events across the globe. A Branding Kit would ensure the message is reached to the target audience with our resources at your disposal to help you out in the events.</p>
                        </Col>
                    </Row>

                    <Row className="icoadverstiseeight-bg">
                        <Col sm="6"></Col>
                        <Col sm="4" className="icoproject-section">
                            <div className="presalesection">
                                <h3 className="ico-sub-title icoadverstise-text">Why the need for an online and offline presence?</h3>
                            </div>
                            <p>You need to have an online and offline presence in various crypto communities and investors to gain their attention and we can help in designing and implementing a fullproof and sensitive marketing plan for your ICO project. We have all resources at our disposal to make a difference. We have leveraged our expertise for various companies and have helped them to raise combined portfolio exceeding 700 million. </p>
                        </Col>
                    </Row>

                </div>
            </Fragment>
        )
    }
}
