import React, { Component } from 'react'
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import STNewBigThing from '../../../Images/faq/STNewBigThing.jpg';

export default class Securitytokenscanchange extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">Security Tokens Can Change The Market.</h2>
                            <img className="img-fluid faqimg" src={STNewBigThing} alt="Security Tokens Can Change The Market." title="Security Tokens Can Change The Market." />                            
                            <p>Even in its early days, Security Tokens have already started gaining traction in the market. Blockchain along with the regulation can certainly bring a lot of advantages over the currently available cryptocurrencies. Here is how it can change the global scenario completely</p>
                            <ul>
                                <li>
                                    Unlocking Liquidity
                                    <p>Traditional securities take a long time to settle. Sure, the settling process has sped up over the years. It’s gone from T+5 (trade date plus 5 business days), to T+3, and eventually what it is now: T+2. For a comparison of just how big this innovation is, consider what email did to snail mail. A few decades ago, you’d have to go through the painstaking process of mailing a letter or a fax in order to send written communications. People would think twice before going through that.</p>
                                </li>
                                <li>Opening Up Capital to Global Markets
                                    <p>Depending on the how the token is set up, it may be eligible for trading in global markets. For example, an investor in Germany could easily buy equity in a cafe in Florida with a few clicks of the mouse.</p>
                                    <p>Just how much of the global market is possible? A leading platform for compliant token sales, conducted a round for Origin Protocol and attracted over 1800+ investors from over 50 countries worldwide to participate in the investment round.</p>
                                    <p>This, again, adds liquidity and provides opportunities for investors to buy shares of assets that were previously much more difficult to attain.</p>
                                </li>
                                <li>24/7 Trading3.
                                    <p>Security tokens allow for 24/7 markets, which eliminates the huge inefficiencies of daily market closures.</p>
                                </li>
                                <li>Automated Compliance
                                    <p>One of the most difficult aspects of trading securities is adhering to regulations. This is because 1) regulations can vary by asset type, investor type, and buyer/seller/issuer jurisdictions, and 2) you typically need to document regulatory compliance through a series of separate ledgers.</p>
                                    <p>But because security tokens are programmable, compliance can be baked right into the token. The hassles of being compliant are greatly reduced, as legally compliant protocols like Polymath and Harbor work together with exchanges like tZERO, 0x, and more. This allows compliance to become exponentially easier, and just about automatic once the systems are in place.</p>
                                </li>
                                <li>Programmable Assets and Securities
                                    <p>Dividend Release</p>
                                    <p>Voting and Governance Rights</p>
                                    <p>Special Rights and Privileges</p>
                                    <p>To understand how this will be more effective with security tokens, let's take an example. For example, if Joe owned X amount of Y security token he would be able to promote it to his network and be rewarded with more security tokens.</p>
                                </li>
                                <li>Asset Interoperability
                                    <p>In a future where just about anything can be tokenized, this opens the door to asset interoperability – the ability of computer systems or software to exchange and make use of information.</p>
                                </li>
                                <li>Fractional Ownership and Increased Market Depth
                                    <p>With security tokens, you get the benefits of fractionalized ownership. Instead of spending millions of dollars for an entire property (or even a piece of artwork), you can spend far less and buy a fraction of that property. Then, if you want to hedge your bet, you can buy a fraction of another property in a different part of town or even in a different part of the world.</p>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
