import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import tokenleft from '../../Images/MarketingResearch/token-left.png';
import tokenright from '../../Images/MarketingResearch/token-right.png';

export default class Tokens extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm={12}>                    
                        <div className="heading-title">
                            <h2>What Are Tokens?</h2>
                            <p>Tokens are the building blocks of STEs. Although people may use the terms "cryptocurrency" and "token" interchangeably, they are different. 
Unlike a cryptocurrency, security tokens bring enhanced protection against fraud and miss-selling.</p>
                        </div>
                    </Col>
                </Row> 
                <Row>
                    <Col sm={12}>                    
                        <div className="tokens-box">
                            <h3>There are two types of tokens</h3>
                        </div>
                    </Col>
                </Row> 

                <Row>
                    <Col sm={6}>                    
                        <div className="tokensleftright-box">
                            <h3>Utility Tokens</h3>
                            <img className="img-fluid" src={tokenleft} alt="Token Left" title="Token Left" />
                            <p>Utility tokens usually provide access to an exclusive utility or service. Tech startups often use them as crowdfunding means to raise capital through ICOs. Essentially, investors pay now for access to a company’s product or service in the future.</p>
                        </div>
                    </Col>
                    <Col sm={6}>                    
                        <div className="tokensleftright-box">
                            <h3>Security Tokens</h3>
                            <img className="img-fluid" src={tokenright} alt="Token Right" title="Token Right" />
                            <p>Security Tokens usually represent tangible assets such as shares, dividends or real estate. Comparable to traditional securities, they often fall under security regulations of a country.</p>
                        </div>
                    </Col>
                </Row> 
            </div>
        )
    }
}
