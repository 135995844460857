import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Container } from 'reactstrap';
import ScrollUpButton from "react-scroll-up-button";
import ContactusBanner from './ContactusBanner';
import ContactusDetails from './ContactusdetialsPanaCrm';
// import Modaltest from '../Model/test'

export default class Contactus extends Component {
    scrollPositions = {}
    componentWillMount() {
        if ("scrollRestoration" in window.history) {
            window.history.scrollRestoration = "manual"
        }
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <Helmet>
                    <title>Blockchain Consulting & Development Company | Get in touch</title>
                    <meta name="description" content="Envision how our blockchain solutions could fit into your business model. Request a consultation and discover how you could take your business to the next level." />
                    <meta property="og:title" content="Blockchain Consulting & Development Company | Get in touch" />
                    <meta property="og:description" content="Envision how our blockchain solutions could fit into your business model. Request a consultation and discover how you could take your business to the next level." />
                    <meta name="twitter:description" content="Envision how our blockchain solutions could fit into your business model. Request a consultation and discover how you could take your business to the next level." />
                    <meta name="twitter:title" content="Blockchain Consulting & Development Company | Get in touch" />
                    <link rel="canonical" href="https://www.espay.exchange/contact-us" />
                </Helmet>
                <Container fluid={true} className="contactusbanner-bg">
                    <ContactusBanner />
                </Container>
                <ContactusDetails />
                {/* <Modaltest /> */}
                <ScrollUpButton className="ScrollUpButton" />
            </div>
        )
    }
}
