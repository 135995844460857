import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";
import Mainbanner from './banner';
import Mainabout from './cryptocurrency_section1';
import Demo  from './cryptocurrency_section2';
import Exchangesworldwide from './cryptocurrency_section3';
import SoftwareDevelopment from './cryptocurrency_section4';

export default class index extends Component {

  componentDidMount() {
    ReactGA.initialize('UA-125025994-1')
    ReactGA.pageview(document.location.pathname)
    ReactGA.pageview(document.location.path)
  }

  scrollPositions = {}
  componentWillMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"
    }
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        {this.props.children}
        <Helmet>
            <title>Features For Cryptocurrency Exchange Software Development</title>
            <meta name="description" content="Espay-X shares a list of all the key features that are inevitable part of cryptocurrency exchange software development."/>
            <meta property="og:title" content="Features For Cryptocurrency Exchange Software Development" />
            <meta property="og:description" content="Espay-X shares a list of all the key features that are inevitable part of cryptocurrency exchange software development." />
            <meta name="twitter:title" content="Features For Cryptocurrency Exchange Software Development" />
            <meta name="twitter:description" content="Espay-X shares a list of all the key features that are inevitable part of cryptocurrency exchange software development." />
        </Helmet>

        <Container fluid={true} className="apps-craft-welcome-section apps-craft-welcome-section-v19">
          <Container className="banner-detail-section">
            <Mainbanner />
          </Container>
        </Container>

        <Container>
          <Mainabout />
        </Container>
       
        <Container fluid={true} className="Artificial_Intelligence">
          <Container>
            <Demo />
          </Container>
        </Container>

      {/* <Container fluid={true} className="mousce">
        <Mainspecial />
      </Container> */}

        <Container fluid={true} className="Exchanges_worldwide">
          <Container>
            <Exchangesworldwide/>
          </Container>
        </Container>

        <Container fluid={true} className="Software_Development">
          <Container>
            <SoftwareDevelopment />
          </Container>
        </Container>
      </div>
    )
  }
}