import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import p2pExchange from '../../Images/p2pExchange.png';
import Interesteddes1 from '../../Images/Interesteddes1.png';
import CentralizedExchange from '../../Images/CentralizedExchange.png';

class product_crypto_section6 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12">
                    <div className="standardtitle">
                        <h2>Our Other Exchange Product</h2>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="4">
                    <div className="standardproductbox">
                        <Link title="P2P Exchange Development" to="/p2p-exchange-software-development">
                            <img src={p2pExchange} alt="P2P Exchange Development" title="P2P Exchange Development" />
                            <h3>P2P Exchange Development</h3>
                            <p>Espay is the best decentralized P2P cryptocurrency exchange solution provider having expert 
                                blockchain developers on board that delivers robust software for your customers where Individuals (buyers & sellers) are the only participants.</p>
                        </Link>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="standardproductbox">
                        <Link title="Decentralized Exchange" to="/decentralized-crypto-exchange-software">
                            <img src={Interesteddes1}  alt="Decentralized Exchange" title="Decentralized Exchange"/>
                            <h3>Decentralized Exchange</h3>
                            <p>Just provide trading services without acting as third party. Revolutionize your business with
                                Espay’s decentralized exchange development that make your whole business completely distributed on a decentralized network. </p>
                        </Link>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="standardproductbox">
                        <Link title="Centralized Exchange" to="/centralized-exchange-solutions">
                            <img src={CentralizedExchange} alt="Centralized Exchange" title="Centralized Exchange" />
                            <h3>Centralized Exchange</h3>
                            <p>Become a middle-man to offer trading software. Leverage real-time centralized exchanges of digital 
                                asset that delivers unmatched trading experience combined with a variety of back-end solutions.  </p>
                        </Link>
                    </div>
                </Col>
            </Row>
        </div>
      );
    }
}

export default product_crypto_section6;