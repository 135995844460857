import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import Issuance from '../../../Images/faq/Issuance.jpg';

export default class Significanceissuance extends Component {
    render() {
        return (
            <div className="main">
            <Container fluid={true}>
                <Row>
                    <Col md={4}>
                    <Faqleftpanel {...this.props} />
                    </Col>
                    <Col md={7} className="mx-auto">
                        <h2 className="topinnertitle">What is the significance of issuance in security token exchange?</h2> 
                        <img src={Issuance} alt="Significance" title="Significance" className="img-fluid faqimg"/>                                                 
                        <p>Out of the six components of the Security Token Ecosystem, the first one is Issuance. Issuance platforms enable issuers to tokenize and issue their assets, making them available for sale. These platforms offer tokenization features for a wide range of assets including real estate, debt, equity, and art. Tokenized assets can operate on a number of different blockchains including Ethereum, Bitcoin, and Ravencoin. All tokenized assets are pegged to an underlying asset, whether that is something like the Empire State Building. If the market changes its perception of the price of the asset, the tokens should move in value as well.</p>
                        <p>Here are some platforms for Security Token Issuance:</p>
                        <ul>
                            <li>Polymath</li>
                            <li>Tokensoft</li>
                            <li>Issuance</li>
                            <li>Tokeny</li>
                            <li>Indegogo</li>
                            <li>Securency</li>
                            <li>Securitize</li>
                            <li>SeedInvest</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
        )
    }
}
