import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import withdraw from '../../../../Images/withdraw.png';
import KYCC from '../../../../Images/KYCC.png';
import CoinListingRequest from '../../../../Images/Coin-Listing-Request.png';
import Deposition from '../../../../Images/Deposition.png';
import SpotTrading from '../../../../Images/Spot-Trading.png';
import Pairwise from '../../../../Images/Pairwise.png';
import tradingconvert from '../../../../Images/trading_convert.png';
import TransactionESCROW from '../../../../Images/Transaction-ESCROW.png';
import treadtrasfer from '../../../../Images/tread-trasfer.png';
import oprncart from '../../../../Images/oprncart.png';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faBug} from '@fortawesome/free-solid-svg-icons';
library.add(faBug);
    
class Reconciliation_Section2 extends Component {
    render() {
      return (
        <div> 
            <Row> 
            <Col sm="12" md="6">
                    <div className="reconciliation_service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={withdraw} alt="withdraw" title="withdraw" />
                            </Col>
                            <Col sm="10">
                                <h4>Withdrawal Approvals</h4>
                                <p>Most automatically processed coin and token withdrawals from Espay will be processed within a few minutes. Wallet funds are kept to a minimum level and refilled periodically withdrawal approvals to ensure security risks are minimized.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6">
                    <div className="reconciliation_service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={KYCC} alt="KYCC" title="KYCC" />
                            </Col>
                            <Col sm="10">
                                <h4>KYC Approvals</h4>
                                <p>Espay provides real-time reconciliations of currency securities and approved KYC information on blockchain can save a bank’s resources spent on manual processes and trade errors.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6">
                    <div className="reconciliation_service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={oprncart} alt="oprncart" title="oprncart" />
                            </Col>
                            <Col sm="10">
                                <h4>Open Orders</h4>
                                <p>Leverage best out of Espay’s crypto exchange software and let your traders make open orders to buy/sell a security that remains in effect until get cancelled/executed/expired.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6">
                    <div className="reconciliation_service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={CoinListingRequest} alt="CoinListingRequest" title="CoinListingRequest" /> 
                            </Col>
                            <Col sm="10">
                                <h4>Coin Listing Request</h4>
                                <p>Espay carefully analyzes every coin listing request and token before implementing it on the platform by considering market capitalization, clear white paper, technical parameters of the token and etc.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6">
                    <div className="reconciliation_service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={Pairwise} alt="Pairwise" title="Pairwise" /> 
                            </Col>
                            <Col sm="10">
                                <h4>Pairwise Statements and Ledger</h4>
                                <p>Espay’s crypto exchanges aid you to display and validate pairwise transaction statements and ledger which is very significant as a payment option.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6">
                    <div className="reconciliation_service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={tradingconvert} alt="tradingconvert" title="tradingconvert" /> 
                            </Col>
                            <Col sm="10">
                                <h4>Transfer IN</h4>
                                <p>Espay’s digital asset exchange platform let your traders Transfer their fund in the crypto wallet without compromising on high security.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6">
                    <div className="reconciliation_service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={Deposition} alt="Deposition" title="Deposition" />
                            </Col>
                            <Col sm="10">
                                <h4>Deposition Recon</h4>
                                <p>Espay helps you to create a “credit discrepancy” when the amount that a bank credits to a trader’s account differ from the total of the items deposited.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6">
                    <div className="reconciliation_service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={SpotTrading} alt="SpotTrading" title="SpotTrading" />
                            </Col>
                            <Col sm="10">
                                <h4>Trade Recon</h4>
                                <p>Provide assurance to your traders that the fund leaving an A/C matches the amount that’s been spent and assure the two are balanced with Espay’s Trade recon prominent feature.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6">
                    <div className="reconciliation_service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={treadtrasfer} alt="SpotTtreadtrasferrading" title="treadtrasfer" />
                            </Col>
                            <Col sm="10">
                                <h4>Transfer OUT</h4>
                                <p>Allow your traders to convert their digital coin into cash and then transfer it to bank account out of the crypto wallet by using Espay’s crypto exchange.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6">
                    <div className="reconciliation_service">
                        <Row>
                            <Col sm="2"> 
                             <FontAwesomeIcon icon="bug" size="2x" />
                            </Col>
                            <Col sm="10">
                                <h4>Bug Report</h4>
                                <p>View a list of all the bugs and manage issues regarding crypto trading from bug reports offered by Espay’s Crypto exchange software.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col sm="12" md="6">
                    <div className="reconciliation_service">
                        <Row>
                            <Col sm="2"> 
                                <img className="img-fluid" src={TransactionESCROW} alt="TransactionESCROW" title="TransactionESCROW" />
                            </Col>
                            <Col sm="10">
                                <h4>Transaction ESCROW</h4>
                                <p>Overcome the issue of volatility in the cryptocurrency market and assure that your trades are performed in a safe, timely manner with Espay’s encrypted escrow services.</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>          
        </div>
      );
    }
}

export default Reconciliation_Section2;