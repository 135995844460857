import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import lsecuritytokens from '../../Images/MarketingResearch/l-security-tokens.png';

export default class LimitationsSecurityTokens extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm={12}>
                        <div className="lsttitle">
                            <h3>Limitations of Security Tokens</h3>
                            <img className="img-fluid" src={lsecuritytokens} alt="Limitations of Security Tokens" title="Limitations of Security Tokens" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <div className="listtokenbox">
                            <span>Complex Legal Compliance</span>
                            <p>Only handful countries have specific legal regulations for security tokens. In most countries, the present (and often outdated) security-related laws govern STO and STE transactions. Ensuring compliance with complex securities regulations results in delays and risks.</p>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="listtokenbox lstbox2">
                            <span>Lack of Infrastructure</span>
                            <p>At present, you can buy, sell, and trade security tokens via licensed exchanges, which are very few. Plus, the time lock period allows you to trade security tokens between qualified investors for a limited time period only.</p>
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div className="listtokenbox lstbox3">
                            <span>Nascent Market</span>
                            <p>The concept of security tokens and their application is still in its infancy and so is the market. As a result, trading volume and consequent liquidity are limited. Hopefully, with better legal compliance and awareness, the market will overcome these challenges.</p>
                        </div>
                    </Col>
                </Row>    
            </div>
        )
    }
}
