import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Researchicon from '../../Images/Researchicon.png';
import DataCollectionicon from '../../Images/DataCollectionicon.png';
import Targetingicon from '../../Images/Targetingicon.png';
import Resulticon from '../../Images/Resulticon.png';

class exchange_work_section4 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12">
                    <div className="Aboutustitle">
                        <h2>How Espay Exchange Work ?</h2>
                        <p>To deliver value and high performance to customers with exchange software, Espay works enthusiastically.</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="3" className="AboutusWork">
                    <img className="img-fluid" src={Researchicon} alt="Research" title="Research" />
                    <h3>Research</h3>
                </Col>
                <Col sm="3" className="AboutusWork">
                    <img className="img-fluid" src={DataCollectionicon} alt="Data Collection" title="Data Collection" />
                    <h3>Data Collection</h3>
                </Col>
                <Col sm="3" className="AboutusWork">
                    <img className="img-fluid" src={Targetingicon} alt="Targeting" title="Targeting" />
                    <h3>Targeting</h3>
                </Col>
                <Col sm="3" className="AboutusWork">
                    <img className="img-fluid" src={Resulticon} alt="Result" title="Result" />
                    <h3>Result</h3>
                </Col>
            </Row>
        </div>
      );
    }
}

export default exchange_work_section4;