import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Creator from '../../Images/NFT/Creator.png';

export default class Banefits extends Component {
    render() {
        return (
            <Fragment>
                <div className="benefit-nft">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <h3>Benefit of NFT</h3>
                            </Col>
                        </Row>
                    </Container>

                    <Container>
                        <Row>
                            <Col md={4}>
                                <div className="benefitbox">
                                    <img className="img-fluid" src={Creator} alt="Creator" title="Creator" />
                                    <span>Creator</span>
                                    <ul>
                                        <li>Access to Global Audience</li>
                                        <li>Control of Scarcity and Supply</li>
                                        <li>Fair chance of earnings</li>
                                        <li>Direct contact with Buyer</li>
                                        <li>Lifetime Royalty</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md={4}>

                            </Col>
                            <Col md={4}>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </Fragment>
        )
    }
}
