import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
//import { Link } from "react-router-dom";

import FastestPayment  from "../../Images/Crypto_payment_Gateway/FastestPayment.png";
import Seamlessconversion  from "../../Images/Crypto_payment_Gateway/seamlessconversion.png";
import multiplepayments  from "../../Images/Crypto_payment_Gateway/multiplepayments.png";
import Multipleblockchaincurrency  from "../../Images/Crypto_payment_Gateway/Multipleblockchaincurrency.png";
import UserControlfunds  from "../../Images/Crypto_payment_Gateway/UserControlfunds.png";
import DataSecurity  from "../../Images/Crypto_payment_Gateway/DataSecurity.png";
import Multiplewallet  from "../../Images/Crypto_payment_Gateway/Multiplewallet.png";
import MultiLanguagesupport  from "../../Images/Crypto_payment_Gateway/MultiLanguagesupport.png";
import Transactionhistory  from "../../Images/Crypto_payment_Gateway/Transactionhistory.png";
import TwoFactorAuthentication  from "../../Images/Crypto_payment_Gateway/TwoFactorAuthentication.png";


export default class STOWebPage extends Component {
    render() {
        return (
            <div>
                
                <Container>
                    <Row className="text-center crypto-section">
                        <div className="col-sm-4">
                            <div className="lavrageadv-stuff">
                                <img src={FastestPayment} alt="FastestPayment" title="FastestPayment" className="img-fluid " />
                                <h3>Fastest Transaction Time</h3>
                                <p>Achieve the fastest transaction time with user-centric gateways.</p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="lavrageadv-stuff">
                                <img src={Seamlessconversion} alt="Seamlessconversion" title="Seamlessconversion" className="img-fluid " />
                                <h3>Seamless Conversion</h3>
                                <p>A glitch-free conversion of fiat into cryptos for payments.</p>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="lavrageadv-stuff">
                                <img src={multiplepayments} alt="multiplepayments" title="multiplepayments" className="img-fluid " />
                                <h3>Multiple Payment Options</h3>
                                <p>Convert your fiat to crypto via credit/debit card, wire transfer or through other cryptos.</p>
                            </div>
                        </div>
                    </Row>


                    <Row className="text-center crypto-section">
                        <div className="col-sm-4">
                            <div className="lavrageadv-stuff">
                                <img src={Multipleblockchaincurrency} alt="Multipleblockchaincurrency" title="Multipleblockchaincurrency" className="img-fluid " />
                                <h3>Multiple Blockchain Currency</h3>
                                <p>Enable integration of multiple blockchains such as Bitcoin, Ethereum, EOS and so on, for payment options.</p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="lavrageadv-stuff">
                                <img src={UserControlfunds} alt="UserControlfunds" title="UserControlfunds" className="img-fluid " />
                                <h3>User-Controlled Funds</h3>
                                <p>A secure and transparent platform for users with complete control of their funds and private keys. </p>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="lavrageadv-stuff">
                                <img src={DataSecurity} alt="DataSecurity" title="DataSecurity" className="img-fluid " />
                                <h3>Data Security</h3>
                                <p>Encryption of data through state-of-the-art cryptographic techniques.</p>
                            </div>
                        </div>
                    </Row>

                    <Row className="text-center crypto-section">
                        <div className="col-sm-4">
                            <div className="lavrageadv-stuff">
                                <img src={Multiplewallet} alt="Multiplewallet" title="Multiplewallet" className="img-fluid " />
                                <h3>Multiple Wallets</h3>
                                <p>Allow users to seamlessly transfer cryptos by integrating multiple wallets to a single application.</p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="lavrageadv-stuff">
                                <img src={MultiLanguagesupport} alt="MultiLanguagesupport" title="MultiLanguagesupport" className="img-fluid " />
                                <h3>Multi-language support</h3>
                                <p>Users have the option to select languages of their choice including French, German, Mandarin etc.</p>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="lavrageadv-stuff">
                                <img src={Transactionhistory} alt="Transactionhistory" title="Transactionhistory" className="img-fluid " />
                                <h3>Transaction History</h3>
                                <p>All the transactions of the cryptocurrencies will be easily available for Admins and Users to check. </p>
                            </div>
                        </div>
                    </Row>

                    <Row className="text-center crypto-section">
                        <div className="col-sm-4">
                            <div className="lavrageadv-stuff">
                                <img src={TwoFactorAuthentication} alt="TwoFactorAuthentication" title="TwoFactorAuthentication" className="img-fluid " />
                                <h3>Two Factor Authentication</h3>
                                <p>Verification of user identity using the QR Code and OTP or Pin Code protection, thereby enhancing security for users.</p>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        )
    }
}
