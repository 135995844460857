import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

export default class TechnologyP2Pexchange extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">Technology of P2P Exchange and Earning Opportunity</h2> 
                            <p>Peer to Peer exchange is very much lightweight platform in comparison to regular centralized exchange platform. It doesn’t require to have a trade match engine or liquidity or high mechanism to manage a Peer to Peer Crypto Exchange. Peer to Peer platform is actually a marketplace where users comes and put currencies for sell and buyers buy it and does the payment. In between the platform earns for providing them with the platform for trading. So, there is an admin panel to manage ads, moderate them, manage escrow, and trade fees, and withdrawals. Now here, the trading platform charges less percentage of amount for trading large amount of crypto currency, so the trade volume here will be hug and that raises the amount of transaction fees. Apart from the transaction fees, platform charges for withdrawal of the currencies and posting premium ad – or showing ad on top of other ads.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
