import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

import IEOlunch from "../../Images/ICO_Marketing/TheSuccessfulllaunchofICO.png";

export default class STOWebPage extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm="12">
                        <div className="stoadviceitle">
                            <h2>The successful launch of ICO</h2>
                        </div>
                    </Col>
                </Row>


                <Row>
                    <Col sm="7">
                        <div className="presalesection">
                        <h3 className="ico-sub-title">Cryptocurrency Development</h3>
                        </div>
                        <p>The most common type of crypto tokens are Ethereum based ERC 20 which are widely used for Cryptocurrency Development. We possess sheer expertise in creating cryptocurrencies in different blockchains such as </p>
                        <div className="stowebpagebox">
                            <h3>●	Ethereum </h3>
                        </div>
                        <div className="stowebpagebox">
                            <h3>●	EOS</h3>
                        </div>
                        <div className="stowebpagebox">
                            <h3>●	Hyperledger</h3>
                        </div>
                        <div className="stowebpagebox">
                            <h3>●	Customized blockchain for your application </h3>
                        </div>
                    </Col>
                    <Col sm="5">
                        <img src={IEOlunch} alt="IEOlunch" title="IEOlunch" className="img-fluid" />
                    </Col>
                </Row>
            </div>
        )
    }
}
