import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import vision from '../../Images/vision.png';

class vision_section1 extends Component {
    render() {
      return (
        <div className="mt-30 mb-30"> 
            <Row>
                <Col sm="4">                       
                    <img className="img-fluid" src={vision} alt="Our Vision" title="Our Vision" />
                </Col>
                <Col sm={{size:6,offset:1}}>
                    <div className="VisionMissiontitle">
                        <h2>Our Vision</h2>
                        <p>To be a global innovative technology company on the sphere of digital coin 
                        <Link title="Exchange Software Solutions" to="/crypto-exchange-software">
                            <strong> exchange software solutions </strong></Link> with attention to financial profits and safety of each single 
                            stakeholder.</p>
                        <p>Our viewpoint is to serve real-world solutions to real-world issues that uplift customer experiences and 
                            describe the future today. We have a clear vision to solve current problems faced by customers in a manner 
                            that make their business easier.</p>
                    </div>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default vision_section1;