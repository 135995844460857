import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import MarketBullish from '../../../Images/faq/MarketBullish.jpg';

export default class Marketbullishabout extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">Why the market is bullish about the security tokens?</h2>
                            <img className="img-fluid faqimg" src={MarketBullish} alt="Why the market is bullish about the security tokens" title="Why the market is bullish about the security tokens" />                            
                            <p>The explosion of Initial Coin Offerings (ICOs) two years ago proved just how cryptographic assets can bring massive opportunities for investments – so much that they completely transformed the financial services market. However, ICOs have had their 15 minutes of fame and STOs (Security Token Offerings) are here to take the story further ahead. As we all know Security tokens are tokens which have attributes of a security.The STO is emerging as a powerful and valuable alternative to private equity and venture capital financing for companies globally. It is such a powerful alternative that industry estimates will grow to a $10 trillion opportunity over the next two years.</p>
                            <p>For companies seeking to raise capital, an STO is worth a closer look for a few good reasons. If your specific goal is to raise a large amount of capital and your company matches three or more of the profile points below, It is worth considering an STO:</p>
                            <ul>
                                <li>Generating in excess of $10 million in annual revenue</li>
                                <li>A high growth company</li>
                                <li>Operating a global business</li>
                                <li>Preferring to issue a transferable asset</li>
                                <li>Interested in a funding method that connects with your customer base</li>
                                <li>Desiring greater liquidity for stakeholders</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
