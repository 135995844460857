import React, { Component,Fragment } from 'react';
import { Row, Col} from 'reactstrap';
import { Link } from "react-router-dom";

//import bannerimg from '../../Images/Derivatives/ExchangeDerivativesbanner.jpg'

export default class Derivativesbanner extends Component {
    render() {
        return (
            <Fragment>
                {/* <img className="img-fluid" src={bannerimg} alt="" title="" /> */}
                <Row>
                    <Col sm={9} className="mx-auto">                             
                        <div className="bannerdetails">
                            <h1>Developing Competitive Platforms For Exchange Traded Derivatives For Cryptocurrency</h1>
                            <p>An Investors' Opportunity To Leverage From The Growing Crypto Derivatives Market</p>
                            <Link to="/talk-to-crypto-exchange-expert">Book Appointment Now!</Link>
                        </div>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
