import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";


import Screening from "../../Images/IEO/Screening.png";
import Trust from "../../Images/IEO/Trust.png";
import KYCAML from "../../Images/IEO/KYCAML.png";
import SmartContractRegulation from "../../Images/IEO/Smartcontract.png";
import Marketing from "../../Images/IEO/Marketing.png";
import Security from "../../Images/IEO/Security.png";

export default class STOSmartContract extends Component {
  render() {
    return (
      <div>
        <Row>   
            <Col sm="12">
                <div className="stoadviceitle">
                    <h2>We help you build profitable IEO campaigns</h2>
                    <p>By consulting Espay-X for the launch of your IEO, you are assured that your IEO has various advantages to increase investor confidence.</p>
                </div>    
            </Col>
        </Row>  

        <Row className="IEO-campaigns">   
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={Screening} alt="Screening" title="Screening" />
                    <h3>Screening</h3>
                    <p>The exchange platform that is conducting the IEO screens the token developers' projects brought in.</p>
                </div>
            </Col>
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={Trust} alt="Trust" title="Trust" />
                    <h3>Trust</h3>
                    <p>When the project goes through screening and is approved, the investors start trusting the tokens of the IEO project.</p>
                </div>
            </Col>
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={KYCAML} alt="KYCAML" title="KYCAML" />
                    <h3>KYC/AML</h3>
                    <p>The platform for the exchange conducts strict KYC / AML procedures and collects every detail about the participants.</p>
                </div>
            </Col>
        </Row>      

        <Row className="IEO-campaigns">   
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={SmartContractRegulation} alt="Smart Contract Regulation" title="Smart Contract Regulation" />
                    <h3>Smart Contract Regulation</h3>
                    <p>The trading network takes full responsibility for smart contract laws when it comes to IEOs.</p>
                </div>
            </Col>
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={Marketing} alt="Marketing" title="Marketing" />
                    <h3>Marketing</h3>
                    <p>You get to save a huge amount on marketing while doing an IEO as the exchange platform takes initiative.While doing an IEO, you get to save a huge amount on marketing as the platform takes initiative.</p>
                </div>
            </Col>
            <Col sm="4">
                <div className="smartcontractbox">
                    <img src={Security} alt="Security" title="Security" />
                    <h3>Security</h3>
                    <p>As the exchange is responsible for all the KYC / AML procedures, investors need not worry about protection.</p>
                </div>
            </Col>
        </Row>      
      </div>
    )
  }
}
