import React, { Component } from 'react';
import { Row, Col, Jumbotron } from 'reactstrap';

class Banner extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm={{size:8, offset:2}}>                       
                    <Jumbotron>
                        <div className="PrivacyPolicydetails">
                            <h1>Our Privacy Policy</h1>
                            <p>When you use our services, you’re trusting us with your information. We  
                                understand this is a big responsibility and work hard to protect your 
                                information.</p>
                        </div>
                    </Jumbotron>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default Banner;