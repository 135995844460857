import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import CryptoArbitrageTrading from '../../Images/centralized/CryptoArbitrageTrading.png';
import Bankgradesecurity from '../../Images/centralized/Bankgradesecurity.png';
import OrderMatchingEngine from '../../Images/centralized/OrderMatchingEngine.png';
import AdministrativeControl from '../../Images/centralized/AdministrativeControl.png';
import ModularArchitecture from '../../Images/centralized/ModularArchitecture.png';
import IntegratedCryptocurrencywallet from '../../Images/centralized/IntegratedCryptocurrencywallet.png';
import Liquiditymanagement from '../../Images/centralized/Liquiditymanagement.png';
import EscrowManagemen from '../../Images/centralized/EscrowManagemen.png';
import MultisigWallet from '../../Images/centralized/MultisigWallet.png';

class features_centralized_section2 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12">  
                    <div className="Centralizedtitle">
                        <h2>Espay Centralized Exchange Features</h2>
                        <p>Our< strong>centralized exchange solutions</strong>  are currently being used by millions of traders and over 80 financial institutions</p>
                    </div>                     
                </Col>
            </Row>          
            <Row>
                <Col sm="4">
                    <div className="CentralizedFeatureBox">
                        <img src={CryptoArbitrageTrading} alt="Crypto Arbitrage Trading" title="Crypto Arbitrage Trading"/>
                        <h3>Crypto Arbitrage Trading</h3>
                        <p>Simultaneous buying & selling of a crypto asset on various markets in an attempt to profit from the price temporary price difference between the markets.</p>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="CentralizedFeatureBox">
                        <img src={Bankgradesecurity} alt="Bank Grade Security" title="Bank Grade Security"/>
                        <h3>Bank Grade Security</h3>
                        <p> PCI DSS compliance demonstrates a data-security platform ready to address risk, taking into consideration any aspect of payment-data protection, monitoring, and management.</p>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="CentralizedFeatureBox">
                        <img src={MultisigWallet} alt="Multi-Sig Wallet" title="Multi-Sig Wallet"/>
                        <h3>Multi-Sig Wallet</h3>
                        <p>By creating a multisig wallet that requires two keys, traders create a two-factor authentication mechanism to access funds.</p>
                    </div>
                </Col>                    
            </Row>
            <Row>
                <Col sm="4">
                    <div className="CentralizedFeatureBox">
                        <img src={OrderMatchingEngine} alt="Order Matching Engine" title="Order Matching Engine" />
                        <h3>Order Matching Engine</h3>
                        <p>Our enterprise matching engine offers high throughput by supporting plenty of different type of orders 
                            per second.</p>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="CentralizedFeatureBox">
                        <img src={AdministrativeControl} alt="Administrative Control" title="Administrative Control"/>
                        <h3>Administrative Control</h3>
                        <p>A secure and cutting-edge administrative panel offered by Espay’s centralized crypto exchanges aid you 
                            with robust analytics, advanced alert settings, risk monitoring and much more.</p>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="CentralizedFeatureBox">
                        <img src={ModularArchitecture} alt="Modular Architecture" title="Modular Architecture"/>
                        <h3>Modular Architecture</h3>
                        <p>Plugin based and independent architecture of centralized cryptocurrency trading platform empowers easy 
                            linking to KYC/AML, verification, reporting as well as other services.</p>
                    </div>
                </Col>                  
            </Row>
            <Row>
                <Col sm="4">
                    <div className="CentralizedFeatureBox">
                        <img src={IntegratedCryptocurrencywallet} alt="Integrated Cryptocurrency wallet" title="Integrated Cryptocurrency wallet"/>
                        <h3>Integrated Cryptocurrency wallet</h3>
                        <p>The exchange software comprises a multicurrency wallet for every currency implemented within your trading platform.
                            It will support multi and fiat currency with multiple payment and withdrawal methods.</p>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="CentralizedFeatureBox">
                        <img src={Liquiditymanagement} alt="Liquidity management" title="Liquidity management"/>
                        <h3>Liquidity management</h3>
                        <p>We can get whole order book from third party exchange & display it on your crypto Exchange platform to 
                            surge the flow of crypto trading in the website which aid to get the users at first for your business.</p>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="CentralizedFeatureBox">
                        <img src={EscrowManagemen} alt="Escrow Management" title="Escrow Management" />
                        <h3>Escrow Management</h3>
                        <p>Espay offers centralized crypto exchanges that decrease website formation time, keep an eye on 
                            blockchain security protocols and upsurge quality of information with escrow and accuracy.</p>
                    </div>
                </Col>             
            </Row>
        </div>
      );
    }
}

export default features_centralized_section2;