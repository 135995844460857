import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import cmsimg from '../../../../Images/CMS_based_Website_Development.png';

class mass_communication_section1 extends Component {
    render() {
      return (
        <div> 
            <Row> 
                <Col sm="6">
                    <div className="mobiletradingtitle">                      
                        <p>Espay’s crypto exchange solutions offers the power of different functions of mass communication. You can transmit the messages to a large number of audience on your trading platform. It’s a quick way to disseminate information, news as well as advertising to a wide range of people who are staying far away.</p>                        
                    </div>
                </Col>
                <Col sm="6" className="mt-30">
                    <img className="img-fluid" src={cmsimg} alt="MASS COMMUNICATION FEATURES" 
                    title="MASS COMMUNICATION FEATURES" />
                </Col>         
            </Row>          
        </div>
      );
    }
}

export default mass_communication_section1;