import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import ExchangeSoftwarepc from '../../Images/enterprise/ExchangeSoftwarepc.png';

class software_standard_section1 extends Component {
    render() {
      return (
        <div className="mt-30 mb-30"> 
            <Row>   
                <Col sm={4}>
                    <img className="img-fluid" src={ExchangeSoftwarepc} alt="ExchangeSoftwarepc" title="ExchangeSoftwarepc" />
                </Col>
                <Col sm={{size:7,offset:1}}>
                <div className="enterpriseabouttitle">
                    <h2>Know More About Exchange Software Development</h2>
                    <p>Espay is a renowned name in developing game-changing and user-oriented crypto exchanges. We offer intuitive
                            Bitcoin exchange development as well as help our admired clienteles in obtaining new and retaining 
                            existing investors to maximize the usage. With the leading-edge technology, we upgrade platforms making 
                            them more cost-effective for your end-users. If you are in need to develop crypto exchange software, we 
                            are ready to satisfy your certain prerequisites.</p>
                    <p>Our team has always made it a priority to build world’s best <strong> crypto exchange development</strong>. We can say that 
                        we have best experts on board who give us a competitive advantage in this ever-changing market of digital 
                        coins. They understand it from scratch, what complete customization, scalability and advanced security are 
                        all about.</p>
                </div>
                </Col>
            </Row>
        </div>
      );
    }
}

export default software_standard_section1;