import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';


import MarketingResearchBanner from '../../Images/MarketingResearch/MarketingResearchBanner.jpg';

class MarketingBanner extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm={12}>                    
                    <div className="bannerdetails">
                        <h1>The Future of Security Token Exchange <span>and the Investment Opportunities Arising from It</span> </h1>
                    </div>
                </Col>
            </Row> 
            <Row>
                <Col sm={12}>                    
                    <div className="bannerdetails">
                        <img className="img-fluid" src={MarketingResearchBanner} alt="The Future of Security Token Exchange" title="The Future of Security Token Exchange" />
                    </div>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default MarketingBanner;