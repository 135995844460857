import React, { Component,Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import Coin_Listing_Request from '../../Images/Coin-Listing-Request.png';
//import Chart_Three from '../../Images/Chart_Three.png';
import Top_Gainer_Losers from '../../Images/Top-Gainer-Losers.png';
import Complain_Status from '../../Images/Complain-Status.png';
import margin_trading from '../../Images/margin_trading.png';
import active_order from '../../Images/active-order.png';
import by_sell from '../../Images/by_sell.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faShoppingCart,faSearch,faBook} from '@fortawesome/free-solid-svg-icons';
library.add(faShoppingCart,faSearch,faBook);

export default class DerivativeCustomizedTrading extends Component {
    render() {
        return (
            <Fragment>
                <Row>   
                    <Col sm="12"> 
                        <div className="tradingtitle">
                            <h2>Customized Trading Dashboard Overview</h2>
                        </div>                      
                    </Col>
                </Row>  
                <Row>
                    <Col sm="12" md="6" lg="4">
                        <ul>
                            <li><img className="img-fluid" src={Coin_Listing_Request} alt="Favorite Pair / Currency" title="Favorite Pair / Currency" /></li>
                            <li className="overview-cont">
                                <h4>Favorite Pair / Currency</h4>
                                <p>Based on the latest data, your customers will find the best forex pair to trade having high liquidity. Espay helps you to offer best customer experience with its exchange development solutions.</p>                                   
                            </li>
                        </ul> 
                    </Col>
                    
                    <Col sm="12" md="6" lg="4">
                        <ul>
                            <li>
                                <FontAwesomeIcon icon="search" size="2x" className="overview-icn"/>                           
                            </li>
                            <li className="overview-cont">
                                <h4>Pair Search</h4>
                                <p>Let the traders of your crypto exchange platform match two trading stocks which are highly associated and whose values have moved together for a specific period of time.</p>                                   
                            </li>
                        </ul>
                    </Col>
                
                    <Col sm="12" md="6" lg="4">
                        <ul>
                            <li><img className="img-fluid" src={Top_Gainer_Losers} alt="Top Gainer & Losers" title="Top Gainer & Losers" /></li>
                            <li className="overview-cont">
                                <h4>Top Gainer & Losers</h4>
                                <p>You can facilitate your traders to look out for top gainers as well as losers on a dashboard that will aid them to find profitable trades with Espay’s crypto trading software.</p>                                    
                            </li>
                        </ul> 
                    </Col>
                    <Col sm="12" md="6" lg="4">
                        <ul>
                            <li><img className="img-fluid" src={Complain_Status} alt="Complain Status" title="Complain Status" /></li>
                            <li className="overview-cont">
                                <h4>Complain Status</h4>
                                <p>Espay aid you with its crypto exchange software solutions and serve your traders to view the current status of their complaints on the trader’s dashboard. </p>                                    
                            </li>
                        </ul>
                    </Col>
                    <Col sm="12" md="6" lg="4">
                        <ul>
                            <li><img className="img-fluid" src={margin_trading} alt="Trade Summary" title="Trade Summary" /></li>
                            <li className="overview-cont">
                                <h4>Trade Summary</h4>
                                <p>Espay’s crypto exchange software offers a dashboard to your customers designed to give an overview of trade data and shows a subset of the million records.</p>                                    
                            </li>
                        </ul>
                    </Col>
                
                    <Col sm="12" md="6" lg="4">
                        <ul>
                            <li>
                                <FontAwesomeIcon icon="shopping-cart" size="2x" className="overview-icn"/> 
                            </li>
                            <li className="overview-cont">
                                <h4>Recent Orders</h4>
                                <p>Let your traders know about the recent orders placed on the trading platform with dashboard offered by Espay’s digital asset exchange software.</p>                                 
                            </li>
                        </ul> 
                        
                    </Col>
                    <Col sm="12" md="6" lg="4">
                        <ul>
                            <li><img className="img-fluid" src={by_sell} alt="Buy / Sell Section" title="Buy / Sell Section" /></li>
                            <li className="overview-cont">
                                <h4>Buy / Sell Section</h4>
                                <p>With the help of buy and sell section on your dashboard, your traders can buy and sell digital coins at a competitive price. Espay’s crypto exchange software is all you need for this.</p>                                  
                            </li>
                        </ul>
                    </Col>
                    <Col sm="12" md="6" lg="4">
                        <ul>
                            <li>
                                <FontAwesomeIcon icon="book" size="2x" className="overview-icn"/>
                            </li>
                            <li className="overview-cont">
                                <h4>Order Books</h4>
                                <p>Espay’s offerings for crypto exchanges comprises full order book from the launch of the trading platform. Your traders can find the list of buy and sell orders organized by price level.</p>                                   
                            </li>
                        </ul>
                    </Col>
            
                    <Col sm="12" md="6" lg="4">
                        <ul>
                            <li><img className="img-fluid" src={active_order} alt="Active Order" title="Active Order" /></li>
                            <li className="overview-cont">
                                <h4>Active Order</h4>
                                <p>Leverage active orders from Espay’s crypto trading platform and list all pending orders there that will be available for trading. It will aid your traders to be aware of upcoming orders. </p>                                  
                            </li>
                        </ul> 
                    </Col>
                </Row> 
            </Fragment>
        )
    }
}
