import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";

import Mainbanner from './banner';
// import Mainwelcome from './Terms-Section1';
import Mainwelcome from './terms-of-use';

export default class index extends Component {

  componentDidMount() {
    ReactGA.initialize('UA-125025994-1')
    ReactGA.pageview(document.location.pathname)
    ReactGA.pageview(document.location.path)
  }
  
  scrollPositions = {}
  componentWillMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"
    }
    window.scrollTo(0, 0)
  }
  
  render() {
    return (
      <div>
        {this.props.children}
        <Helmet>
            <title>Terms And Condition - Espay Exchange</title>
            <meta name="description" content="This agreement describes the terms of conditions of using Espay Exchange website, 
            eligibility, security, intellectual property, rights, limitations and obligations."/>
            <meta property="og:title" content="Terms And Condition - Espay Exchange" />
            <meta property="og:description" content="This agreement describes the terms of conditions of using Espay Exchange website, 
            eligibility, security, intellectual property, rights, limitations and obligations." />
            <meta name="twitter:title" content="Terms And Condition - Espay Exchange" />
            <meta name="twitter:description" content="This agreement describes the terms of conditions of using Espay Exchange website, 
            eligibility, security, intellectual property, rights, limitations and obligations." />
            <link rel="canonical" href="https://www.espay.exchange/terms-of-use" />
        </Helmet>

        <Container fluid={true} className="TermsBanner">
          <Mainbanner />
        </Container>

        <Container>
          <Mainwelcome />  
        </Container>    

        <ScrollUpButton className="ScrollUpButton"/>  
      </div>
    )
  }
}

