import React, { Component, Fragment } from 'react';
import { Container,Row,Col} from 'reactstrap';

export default class DerivativesTradingsystems extends Component {
    render() {
        return (
          <Fragment>
            <Container className="derivativesmain">
              <Row>
                <Col md={12}>
                  <div className="derivativetitle">
                    <h2>Derivatives Trading Systems</h2>
                    <p>
                      Bitcoin future trading software development.
                      Trade Bitcoin futures, a Bitcoin perpetual swap and many
                      more cryptocurrency derivatives software platform. Trade
                      physically delivered cryptocurrency futures. trade
                      physically settled daily and monthly Bitcoin futures
                      options
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="derivativedetails">
                    <span>Derivative Trading software</span>
                    <p>
                      Espay Derivative Trading software helps our clients evolve
                      and succeed in this changing landscape. It provides
                      market-leading functionality for each of your business
                      lines, for both sales and trading, with comprehensive
                      coverage from risk-based pricing and structuring to
                      real-time portfolio management and P&L analysis.
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="derivativedetails">
                    <span>Derivatives software</span>
                    <p>
                      Espay Derivatives software is a cost effective, real time
                      data analysis application that meets the compliance needs
                      of the most demanding financial institutions. Designed as
                      a powerful tool to help eliminate risk and enhance
                      security.
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="derivativedetails">
                    <span>Future and option systems</span>
                    <p>
                      Espay provider of Future and option systems to the
                      derivatives community. Using the updated technology to
                      automate - AI base trade flow across front, middle and
                      back offices, our solutions encompass electronic order
                      routing, clearing, risk & back office as part of a modular
                      architecture.
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="derivativedetails">
                    <span>Derivative White label software</span>
                    <p>
                      Espay makes it easy for you to launch your own white label
                      derivative exchange software that is completely cheaper,
                      faster & safer and let your customers trade digital coins
                      with high security.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </Fragment>
        );
    }
}
