import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";

import Certificate from "../Certificate/index";
//import TalkToOurExperts from "../TalkToOurExperts/TalkToOurExpertsForm";
import TalkToOurExperts from "../Contactus/ContactusdetialsSTO";

import Slidermain from "./banner";
import AttractiveBusiness from "./AttractiveBusiness";
import EasyIntegration from  "./EasyIntegration";

import MobileAdvantage from "./MobileAdvantage";
import WorldclassExperience from "./WorldclassExperience";
import Offertraders from "./Offertraders";
import Faqmain from '../Faq/faq';

export default class index extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-125025994-1')
        ReactGA.pageview(document.location.pathname)
        ReactGA.pageview(document.location.path)
      }
      
      scrollPositions = {}
      componentWillMount() {
        if ("scrollRestoration" in window.history) {
          window.history.scrollRestoration = "manual"
        }
        window.scrollTo(0, 0)
      }
    
  render() {
    return (
      <div>
        {this.props.children}
        <Helmet>
            <title></title>
            <meta name="description" content=""/>
            <meta property="og:title" content="" />
            <meta property="og:description" content="" />
            <meta name="twitter:title" content="" />
            <meta name="twitter:description" content="" />
            <link rel="canonical" href="https://www.espay.exchange/mobile-trading-terminal" />
        </Helmet>

        <div className="Ieobackground-bg">
        <Container fluid={true} className="programming-bg">
          <Container>
            <Slidermain />
          </Container>
        </Container>
        </div>
        
        <Container>
            <AttractiveBusiness />
        </Container>

        <Container>
          <EasyIntegration/>
        </Container>

        <Container fluid="true" className="SmartContact-bg"> 
            <MobileAdvantage />
        </Container>

        <Container>
          <Offertraders/>
        </Container>
        
        <Container>
            <Certificate />
        </Container>

        <Container>
            <h2 className="faqmaintitle">Frequently Asked Questions</h2>
            <Faqmain />
          </Container>
        
        <TalkToOurExperts />


        <ScrollUpButton className="ScrollUpButton"/>
      </div>
    )
  }
}
