import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";

class platform_service_section3 extends Component {
    render() {
      return (
        <div> 
            <Row> 
                <Col sm="9">
                    <span>Looking to Start Exchange Trading Business ?</span>
                </Col>
                <Col sm="3">
                    <Link title="Ask For Quote" to="/contact-us">BOOK A DEMO</Link>
                </Col>
            </Row>         
        </div>
      );
    }
}

export default platform_service_section3;