import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import IEOconsultation from "../../Images/IEO/Initialexchangeofferingconsultationfromespayx.png";

export default class STOSmartContract extends Component {
  render() {
    return (
      <div>
        <div className="offtertrader-bg">
          <Row>
            <Col sm="12">
              <h2 className="">Offer traders insurance against auto deleveraging</h2>
              <p className="txt-center">Nobody likes to lose and being an exchange owner, you can encourage readers to trade even when they are not going good by offering insurance against auto deleveraging. If the trader's position falls below the maintenance margin amount, the insurance fund will take care of the initial loss and encourage the trader to bid for higher orders.</p>
            </Col>
          </Row>
          <Row>
            <div className="col-sm-10">
              <div className="offerborder">
                <p><b>❏ Leverage and margin trading exchange development with interoperability functions </b></p>
                <p>Traders would appreciate uninterrupted and smooth time while trading on the exchange and being a world-class leverage and margin trading exchange Development Company, we offer robust and scalable exchange software that supports API key integration to offer the best experience to the traders.  </p>
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-sm-10">
            <div className="offerborder">
                <p><b>❏ Trading Bots </b></p>
                <p>Let the traders use the bots in a highly secure environment to take the advantages of the dynamic cryptosphere.</p>
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-sm-10">
              <div className="offerborder">
                <p><b>❏ Third-party app development </b></p>
                <p>Encourage the community to build more scalable and secure external applications that explore new use cases and improve productivity.</p>
              </div>
            </div>
          </Row>
        </div>







      </div >
    )
  }
}
