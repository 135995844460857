export default [
{
    "homebannerdata": {
        "fields": {
            "homebanner_h1": "<h1>Redefining Trading Technologies</h1>",
            "homebanner_dis": "One-stop solution for your exchange software development needs",
        }
    }
},

{
    "ourexchangedata": {
        "fields": {
            "ourexchange_h2": " Digital Asset Trading Suite",
            "ourexchange_dis": "Enable new paths to your desires regarding trading platform by getting allied with Espay – Well distinguished Digital Asset Exchange Development Company.",
            "ourexchange_dissub": "We develop cryptocurrency trading software which are efficient, scalable & cost-effective with the help of project-focused certified professionals. Espay is standing separately as a",
            "ourexchange_link": "most advanced exchange solution provider ",
            "ourexchange_dissubnext": "because of its 10+ years of experience in transaction processing systems. Our groundbreaking methodologies offers industry’s best experience to the clients and add value to every project we commences. We work with our regarded customers to transform their digital needs into the most secure, effective and high performance exchange software.",
        }
    }
},
{
    "ourexchangesoftwaredata": {
        "fields": {
            "ourexchangesoftware_h2": "Our Exchange Software Development Comprise Of",
            "ourexchangesoftware_dis": "Espay develops extensively compatible and performance-centric trending exchanges to leverage best out of peer-to-peer blockchain technology.",
            "ourexchange_title1": "Crypto Asset Exchange",
            "ourexchange_dis1": "Offer a secure platform that can be trusted without any second thought. We offer a wide array of security features containing standard areas of best practice.",
            "ourexchange_title2": "Security Token Exchange",
            "ourexchange_dis2": "Convert financial investments & instruments into tokens uniquely providing a single trading platform software for security tokens & crypto with the affluence of liquidity.",
            "ourexchange_title3": "Derivatives Exchange Solution",
            "ourexchange_dis3": "Our cryptocurrency derivatives trading system hosts all major derivative functions, namely swaps, futures, and options.",
        }
    }
},
{
    "uniquefeaturesdata": {
        "fields": {
            "features_h2": "What Are The Unique Features Of Espay Exchange Software?",
            "features_dis": "Being one of the most prominent exchange development company, Espay uses this collection of features to attain best possible outcomes.",
            "features_title1": "Self-Managed Hosting Solutions",
            "features_subdis1": "Software as a service provider, Espay is offering self-managed and hosted infrastructure that empower you to avoid procurement costs.",
            "features_title2":"Currency Pair Management",
            "features_subdis2":"When your customers trading currency pairs, trading platforms offered by Espay let you manage preference for different Pairs as well as Markets.",
            "features_title3":"Trading Market Setup",
            "features_subdis3":"Espay aids to create peer-to-peer customer market through which it is possible to stop and limit order as well as to spot trading and much more.",
            "features_title4":"Exchange UI Designing",
            "features_subdis4":"Espay creates a user interface on the basis of customer preferences as well as template selection whether it’s landing page designing or front pages designing.",
            "features_title5":"Full liquidity order book",
            "features_subdis5":"Our offering comprises more than one liquidity options to assure full order book from the launching of your cryptocurrency trading software.",
            "features_title6":"Trading Report Management",
            "features_subdis6":"Our reporting software enables traders and managers to easily track each single activity in a trading account.",
            "features_title7":"Security (KYC and AML and 2FA)",
            "features_subdis7":"exchange software development from Espay comprises user account and a security module which ensure safety with two-factor authentication.",
            "features_title8":"Mobile App",
            "features_subdis8":"Native Espay’s applications for trading on iOS as well as Android devices. It contains rich charting features, cutting-edge order types and a wealth of significant account detail.",
            "features_title9":"Managed Hosting Solutions",
            "features_subdis9":"Espay is providing complete exchange solutions with option of dedicated server hosting so no need to spend money on own hardware.",
        }
    }
},
{
    "exchangefeaturesdata": {
        "fields": {
            "exchangefeatures_h2": "Additional Exchange Features",
            "exchangefeatures_dis": "Implement Espay’s blockchain based exchange software enriched with leading-edge technology.",
            "exchangefeatures_title1": "High Performance Computing",
            "exchangefeatures_dis1": "Exchanges from Espay have ability to process plenty of orders per second. It comprises built-in components of scalability to accommodate workload growth.",
            "exchangefeatures_title2": "Banking Grade Security",
            "exchangefeatures_dis2": "Our exchange solutions have been built and developed by the Espay’s security experts to keep sensitive info and funds safe.",
            "exchangefeatures_title3": "Built for the Enterprise segment",
            "exchangefeatures_dis3": "Exchange platforms offered by Espay contains High-performance technology. Developed for multi-asset-class trading & execution solutions for enterprise platform.",
            "exchangefeatures_title4": "Transcend using A.I.",
            "exchangefeatures_dis4": "Our skilled engineers predict markets with the use of inter-market analysis and open source sentiment, solve hard system optimization issues, handle risk and much more.",
            
        }
    }
},
{
    "whychoosedata": {
        "fields": {
            "whychoose_h2": "Why Choose Espay For Exchange Development?",
            "whychoose_dis": "Espay is the fintech software home. We focus completely on financial technology solutions. We develop exchange software that aids financial companies to run their trading business.",
            "whychoose_box1":"100+ Dedicated IT Team",
            "whychoose_box2":"Banking Grade Security",
            "whychoose_box3":"10+ Years of Experience",
            "whychoose_box4":"Converged Exchange Solution",
        }
    }
},
{
    "getstarteddata": {
        "fields": {
            "getstarted_h2": "Get Started Today",
            "getstarted_dis": "Leverage best out of our next-generation exchange software solution that satisfy your trading platform regarding needs.",
            "getstarted_title1": "Contact Us",
            "getstarted_dis1": "Feel free to contact us for your any sort of complex queries.",
            "getstarted_title2": "Meet With Technical Team",
            "getstarted_dis2": "Experts in our technical team are always ready to meet you.",
            "getstarted_title3": "Select your Package",
            "getstarted_dis3": "Choose our exchange solutions that best suit your budget.",
            "getstarted_title4": "Project kick off",
            "getstarted_dis4": "Kick start your trading platform software with the help of our experts.",
        }
    }
},
]