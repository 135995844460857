import React, { Component,Fragment } from 'react';
import { Container, Row, Col} from 'reactstrap';
//import { Link } from "react-router-dom";
import Bitcoinicon from '../../Images/Derivatives/Bitcoinfutures.png';
import Cryptoicon from '../../Images/Derivatives/Cryptofuture.png';
import Dailyicon from '../../Images/Derivatives/Dailyfuture.png';
export default class Investors extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <Row>
                        <Col md={10} className="mx-auto">  
                            <div className="cryderivativestitle">
                                <h2>What’s In It For Investors?</h2>
                                <p>Investors could capitalize from high volatility by providing a risk management platform for participants regardless of whether the underlying price goes up or down.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <div className="investorsbox">
                                <img className="img-fluid" src={Bitcoinicon} alt="derivatives exchange software providers" title="" />
                                <span>270% vs. 150%</span>
                                <p>The edge that bitcoin futures got over cryptocurrency in 2Q 2019</p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="investorsbox">
                                <img className="img-fluid" src={Cryptoicon} alt="cryptocurrency derivatives exchange" title="" />
                                <span>13600</span>
                                <p>The number of crypto future contracts that CME recorded in May 2019</p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="investorsbox">
                                <img className="img-fluid" src={Dailyicon} alt="crypto derivatives trading platform" title="" />
                                <span>$370 Mn</span>
                                <p>The average amount of daily future contracts as of August 2019</p>
                            </div>
                        </Col>
                    </Row>    
                </Container>  
            </Fragment>
        )
    }
}
