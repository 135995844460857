import React, { Component } from 'react';
import {Row,Col} from 'reactstrap';
import exinterested1 from '../../Images/MarketingResearch/ex-interested-b1.png';
import exinterested2 from '../../Images/MarketingResearch/ex-interested-b2.png';
import exinterested3 from '../../Images/MarketingResearch/ex-interested-b3.png';
import exinterested4 from '../../Images/MarketingResearch/ex-interested-b4.png';
import exinterested5 from '../../Images/MarketingResearch/ex-interested-b5.png';

export default class LaunchingSTE extends Component {
    render() {
        return (
          <div>
            <Row>
              <Col sm={12}>
                <div className="launchingste-title">
                  <span>
                    Stock Exchanges Interested in Launching Security
                    Token Exchanges
                  </span>
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <div className="launchingste-box">
                    <img className="img-fluid" src={exinterested1} alt="Gibraltar Stock Exchange" title="Gibraltar Stock Exchange" />
                    <span>Gibraltar Stock Exchange</span>
                    <p>
                        The Gibraltar Stock Exchange (GSX) established a
                        subsidiary called the Gibraltar Blockchain Exchange
                        (GBX), which is a security token exchange platform.
                        It secured a license from the Gibraltar Financial
                        Services Commission (GFSC) on 22nd Nov, 2018,
                        becoming the first to own and operate a regulated
                        blockchain exchange. Currently, it supports Bitcoin
                        (BTC), Ethereum (ETH), and the exchange’s native
                        Rock (RKT) token.
                    </p>
                </div>
              </Col>
              <Col sm={6}>
                <div className="launchingste-box">
                    <img className="img-fluid" src={exinterested2} alt="Australian Securities Exchange" title="Australian Securities Exchange" />
                    <span>Australian Securities Exchange</span>
                    <p>The Australian Securities Exchange (ASE) is planning to use blockchain technology to enable post-trade settlements. Investors will be able to trade underlying assets as security tokens on the new trading platform. It is essentially developing and testing its own blockchain system. Recently, however, ASE moved the launch date for the new exchange platform from the fourth quarter of 2020 to March-April, 2021.
                    </p>
                </div>
              </Col>
            </Row>

            <Row>
                <Col sm={6}>
                    <div className="launchingste-box">
                        <img className="img-fluid" src={exinterested3} alt="Malta Stock Exchange" title="Malta Stock Exchange" />
                        <span>Malta Stock Exchange</span>
                        <p>The Malta Stock Exchange has created a fintech and digital asset subsidiary called MSX PLC. The MSX has signed an MOU with crypto exchange Binance to set up a security token exchange. Currently, Malta offers one of the most well-regulated and pragmatic legal frameworks for all things blockchain.</p>
                    </div>
                </Col>
                <Col sm={6}>
                    <div className="launchingste-box">
                        <img className="img-fluid" src={exinterested4} alt="SIX Swiss Exchange" title="SIX Swiss Exchange" />
                        <span>SIX Swiss Exchange</span>
                        <p>The SIX Swiss Exchange is a primary stock exchange in Switzerland. It is also developing a blockchain-based fully-integrated security token exchange platform. The regulated trading platform will be fully compliant with the regulations of the Swiss financial regulator, FINMA. It will provide trading, settlement, and custody services, and will be rolled out in mid-2019.</p>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col sm={6}>
                    <div className="launchingste-box">
                        <img className="img-fluid" src={exinterested5} alt="London Stock Exchange" title="London Stock Exchange" />
                        <span>London Stock Exchange</span>
                        <p>The London Stock Exchange Group (LSEG) and UK's financial regulatory authority, the Financial Conduct Authority (FCA) have joined hands with Nivaura and 20|30 to issue blockchain-based equity in a UK company. The security tokens will be launched in a fully compliant manner. This move is considered as the first step towards developing a fully-compliant custody, clearing, and settlement system.</p>
                    </div>
                </Col>
            </Row>
        </div>
        );
    }
}
