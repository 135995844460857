import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import graph from '../../Images/Landing/ES-version-2.png';


class Tp_section14 extends Component {
    render() {
      return (
        <div  className="ladingsection2"> 
            <Row>
                <Col sm="5">                         
                    {/* <video id="player" class="esversion" controls loop autoPlay >
                        <source src={graph} type="video/mp4"></source>
                    </video> */}
                    <img className="img-fluid" src={graph} alt="Espay Helps You Disrupt The Market With Whitelabel Crypto Exchange Development" title="Espay Helps You Disrupt The Market With Whitelabel Crypto Exchange Development" ></img>
                </Col>
                <Col sm="7">                         
                    <h2>Espay Helps You Disrupt The Market With Whitelabel Crypto Exchange Development</h2>
                    <p>Espay provides a secure, flexible and fully customized whitelabel cryptocurrency exchange software for investors, crypto millenials, and the trading platform ecosystem. We help you enter the market with your own exchange that has an answer to all the current challenges and helps you sustain in the market with our skilled market leadership and expertise.</p>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default Tp_section14;