import React, { Component } from 'react';
import {Row,Col} from 'reactstrap';

export default class StartSecurityTokenExchange extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm={12}>
                        <div className="startste-title">
                            <h3>The Best Places to Start Security Token Exchange</h3>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={6} lg={4}>
                        <div className="startste-box">
                            <span>Lithuania</span>
                            <p>The country has already issued clear regulations covering all crucial aspects of security tokens, including trading and compliance. The country's crypto-friendly regulations have attracted global security token players such as DESICO. The company holds a brokerage license for the secondary trading of tokens.</p>
                        </div>
                    </Col>
                    <Col sm={6} lg={4}>
                        <div className="startste-box">
                            <span>Gibraltar (UK)</span>
                            <p>Gibraltar is renowned for its crypto friendly regulations. Recently, Gibraltar Blockchain Exchange (GBX), a subsidiary of Gibraltar Stock Exchange (GSX) was set up to trade security tokens on its servers. Gibraltar is the first country to have a regulated security token exchange.</p>
                        </div>
                    </Col>
                    <Col sm={6} lg={4}>
                        <div className="startste-box">
                            <span>Singapore</span>
                            <p>The global financial hub is making great strides in the regulated security token market. The Monetary Authority of Singapore (MAS) recently gave the green signal to CapBridge Pte Ltd to open a private security token exchange called 1X. It is one of the first few private exchanges that will be launched soon.</p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <div className="startste-box">
                            <span>Malta</span>
                            <p>Malta is perhaps the most security-token-exchange-friendly country. The three laws governing security tokens in the country are:</p>
                            <ul>
                                <li>
                                    <div>A. Malta Digital Innovation Authority Act (MDIA)</div>
                                    <p>The MDIA act provides a legal framework for defining technical and economic aspects of Distributed Ledger Technology (DLT) platforms.</p>
                                </li>
                                <li>
                                    <div>B. Innovative Technological Arrangement and Services Act (ITAS Act)</div>
                                    <p>It provides guidelines for creating, auditing, and certifying all software technologies concerning blockchain, smart contracts, and security token exchanges.</p>
                                </li>
                                <li>
                                    <div>C. Virtual Financial Asset Act (VFA Act)</div>
                                    <p>It provides a legal framework for regulating any activity related to virtual financial assets such as ICOs, crypto wallet providers, investment advisors, and security token exchanges, among others.</p>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={6}>
                        <div className="startste-box">
                            <span>Switzerland</span>
                            <p>The Swiss Financial Market Supervisory Authority (FINMA) regulates all the security and utility token-related companies and transactions in Switzerland. It offers guidelines on the classification of tokens into payment, utility, and asset tokens. In January 2017, Switzerland established a global hub for virtual currencies called Crypto Valley near the town of Zug.</p>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="startste-box">
                            <span>Canada</span>
                            <p>In 2018, the Canadian Securities Administrators (CSA) published a further Staff Notice casting light on Securities Law Implications for security and utility token offerings. Taking its pro-crypto approach further on Feb 13, 2018, the Canadian Securities Exchange, an alternative stock market founded in 2003, signed an MOU with Kabuni Technologies Inc.</p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <div className="startste-box">
                            <span>USA</span>
                            <p>The Security and Exchange Commission (SEC) uses the Howey Test to define if an investment contract is a security or not. It has three</p>
                            <ul>
                                <li>
                                    <div className="stetitle">A. Regulation D</div>
                                    <ul>
                                        <li>No registration with the SEC.</li>
                                        <li>You can solicit only accredited and verified investors.</li>
                                        <li>You have to fill Form D after selling the security tokens.</li>
                                    </ul>
                                </li>
                                <li>
                                    <div className="stetitle">B. Regulation A+</div>
                                    <ul>
                                        <li>You can sell SEC-approved security tokens to non-accredited investors as well.</li>
                                        <li>You can raise up to $20 million over 12-months in Tier 1 or up to $50 million in Tier 2 over 12 months.</li>
                                        <li>It requires intensive SEC reporting.</li>
                                    </ul>
                                </li>
                                <li>
                                    <div className="stetitle">C. Regulation S</div>
                                    <ul>
                                        <li>Exemption from the registration under Section 5 of the Securities Act of 1933.</li>
                                        <li>You can solicit only investors outside of the US and its territories.</li>
                                        <li>You must comply with the securities regulations of the country of the issuance.</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
