import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import Interested_enterpriseexchange1 from '../../../../Images/Interestedsec.png';
import CentralizedExchange from '../../../../Images/CentralizedExchange.png';
import Interestedsec from '../../../../Images/Interestedsec.png';

class Currency_Section3 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12">
                    <div className="Currencytitle">
                        <h2>Our Other Currency Exchange Offering</h2>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="4">
                    <div className="Currencyproductbox">
                        <Link to="/decentralized-crypto-exchange-software" title="P2P Exchange Software">
                            <img src={Interestedsec} alt="Decentralized Exchange Software" title="Decentralized Exchange Software"  />
                            <span>Decentralized Exchange Software</span>
                            <p>Revolutionize your business with Espay’s decentralized exchange development that make your whole
                               business completely distributed on a decentralized network. </p>
                        </Link>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="Currencyproductbox">
                        <Link to="/centralized-exchange-solutions" title="Centralized Exchange Software">
                            <img src={CentralizedExchange}  alt="Centralized Exchange software" title="Centralized Exchange software" />
                            <span>Centralized Exchange software </span>
                            <p> Offer a trading software by being a middle-man. Leverage a robust centralized exchange that
                                provides unique trading experience along with varied back-end solutions. </p>
                        </Link>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="Currencyproductbox">
                        <Link to="/security-token-exchange" title="Enterprise Crypto Exchange">
                            <img src={Interested_enterpriseexchange1} alt="Enterprise Crypto Exchange" title="Enterprise Crypto Exchange" />
                            <span>Enterprise Crypto Exchange</span>
                            <p>We are a renowned Exchange software development company and specialize in offering secure & reliable
                                solutions to setup your exchange successfully.  </p>
                        </Link>
                    </div>
                </Col>
            </Row>
        </div>
      );
    }
}

export default Currency_Section3;