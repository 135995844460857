import React from 'react';
import { Container,  Col, Row } from 'reactstrap';
import scrollToComponent from 'react-scroll-to-component';
import ScrollUpButton from "react-scroll-up-button";

import MarketCapitalization from '../../Images/faq/MarketCapitalizationImg.png';

class marketing_capitalization extends React.Component {

  constructor(props) {
    super(props);
    this.scrollToTopWithCallback = this.scrollToTopWithCallback.bind(this)
  }

  componentDidMount() {
    scrollToComponent(this.innerfaqcontent, { offset: -60, align: 'top', duration: 500, ease:'inExpo'});
  }

  scrollToTopWithCallback() {
    let scroller = scrollToComponent(this.Violet, { offset: -75, align: 'top', duration: 1500});
    scroller.on('end', () => console.log('Scrolling end!') );
  }

  render() {
    return (
      <div className='main'>
       <h2 className="topinnertitle">Market Capitalization</h2>
            <div className="button_groupmain">
            <button className="button-faq" onClick={this.scrollToTopWithCallback}>
            1) Market Capitalization</button>
            
            </div>
        <Container>
            <section className='innerfaq1content' ref={(section) => { this.Violet = section; }}>
                <Row>
                    <Col sm={{size:11,offset:1}} className="faqdiffbor">
                        <h4>1) What is marketing capitalization of cryptocurrency exchange?</h4>
                        <ul>
                            <li>
                                <p>Market capitalization is a well-known metric for traditional securities, but has unique implications in crypto. Market capitalization is a measure of the value of a security. In crypto, it’s defined as the circulating supply of tokens multiplied by current price. If a coin has 100 tokens outstanding and is trading for $10 a coin, it has a market cap of $1000.</p>
                            </li>
                           
                            <img className="img-fluid" src={MarketCapitalization} title="Market Capitalization" alt="Market Capitalization" />
                           
                            <li className="mt-30">
                                <p>Market cap can help you in learning a lot about the crypto market. First things first: Bitcoin is still the big dog in town. With roughly 55% of the total crypto market cap, it remains far and away the most valuable coin, roughly four times as valuable as Ethereum. There is a huge gap between Bitcoin and Ethereum, but not a large gap between Ethereum and third-place Ripple. There are still relatively few valuable cryptocurrencies. Only 12 coins have a total market cap of over $1 billion. It’s not uncommon for popular altcoins to have market caps of under $20 million. </p>
                            </li>
                            <li>
                                <p>So, in cryptocurrencies the number of coins or the price of coins doesnt matter much. The thing which matters and suggest the true value and acceptance of the coin is its market cap, as it is a combination of both - the market price of coin/token and its circulating supply (which shows the public acceptance of the coin). The Ethereum is more in numbers when compared to bitcoin but the price is much lower then that of the Bitcoin. While there is a coin named 42-coin priced more than USD 19000, way above the price of Bitcoin. but the circulating supply is only 42 coins. so in terms of market cap, this coin is no where near Bitcoin. Checking market cap can always halp you evaluating cryptocurrencies for investment and trading. </p>
                            </li>   
                        </ul>
                    </Col>
                </Row>
            </section>
        </Container>
       
        
        <ScrollUpButton className="ScrollUpButton"/>

      </div>
    )
  }
}

export default marketing_capitalization;
