import React, { Component } from 'react';
import {Row,Col} from 'reactstrap';
import startupsimg from '../../Images/MarketingResearch/smbox-icon1.png';
import Accreditedimg from '../../Images/MarketingResearch/smbox-icon2.png';
import Nonimg from '../../Images/MarketingResearch/smbox-icon3.png';

export default class TotalServiceableMarket extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm={12}>
                        <div className="totalservicestitle">
                            <span>Total Serviceable Market for Security Token Exchanges</span>
                            <p>Although 2018 has witnessed a tremendous momentum for the security token development, the STE development sector is yet to pick up pace.</p>
                        </div>
                    </Col>
                </Row>
                <Row className="tsmsecurity-box">
                    <Col md={6} lg={4} className="tsm-details">
                        <div className="tsm-detailsbox">
                            <span>Startups</span>
                            <img src={startupsimg} alt="Startups" title="Startups" />
                            <p>Security tokens often provide early-stage startups with access to global capital. As a result, they are more likely to take the STO route. At the moment, tapping into this customer base is the best way to increase your revenue.</p>
                        </div>
                    </Col>
                    <Col md={6} lg={4} className="tsm-details">
                        <div className="tsm-detailsbox">
                            <span>Accredited Investors</span>
                            <img src={Accreditedimg} alt="Accredited Investors" title="Accredited Investors" />
                            <p>Security tokens often provide early-stage startups with access to global capital. As a result, they are more likely to take the STO route. At the moment, tapping into this customer base is the best way to increase your revenue.</p>
                        </div>    
                    </Col>
                    <Col md={6} lg={4} className="tsm-details">
                        <div className="tsm-detailsbox">
                            <span>Non-Accredited Investors</span>
                            <img src={Nonimg} alt="Non-Accredited Investors" title="Non-Accredited Investors" />
                            <p>Security tokens often provide early-stage startups with access to global capital. As a result, they are more likely to take the STO route. At the moment, tapping into this customer base is the best way to increase your revenue.</p>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
