import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import tradingimg from '../../../../Images/tradingimg.png';

class Trading_section1 extends Component {
    render() {
      return (
        <div> 
            <Row> 
                <Col sm="6">
                    <div className="tradingtitle">     
                        <p>Serve your traders with Espay’s trading dashboard. It will be the excellent supplement to the currency charts on the trading platform offered by Espay’s cryptocurrency exchange software.</p>
                        <p>It helps your customers to get a quick look on what is happening in the currency market globally. Your traders will get to know which currencies are actually moving, clients’ positions, volume and trading details as well as volatility & trades
                         per time on main coin pairs.</p>
                         <p>Leverage best out of Espay’s <Link to="/" title="Espay Exchange"> <strong>digital asset exchange </strong></Link>digital asset exchange solutions and provide your traders with a wider
                              view of the crypto economy.</p>
                    </div>
                </Col>
                <Col sm="6" className="mt-30">
                    <img className="img-fluid" src={tradingimg} alt="tradingimg dashboard" 
                    title="tradingimg dashboard"/>
                </Col>           
            </Row>          
        </div>
      );
    }
}

export default Trading_section1;