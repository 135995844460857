import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';

import Faqleftpanel from "../Faqleftpanel";

export default class Whatdecentralizedcryptoexchange extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is a decentralized crypto exchange?</h2>
                            <p>A decentralized exchange is an exchange system not dependent on a third-party provider to collect the funds of the customers. Alternatively, via an automated mechanism, trades occur directly between users (peer-to-peer) Such a system can be created by creating proxy tokens (cryptoassets that represent a certain fiat or cryptocurrency) or assets (that can, for example, represent shares in a company) or, among other solutions, a decentralized multi-signature escrow system.</p>
                            <p>This method compares with the centralized model where users deposit their money, and the exchange issues an' IOU' that can be exchanged openly on the website. When a customer demands that his funds be removed, these are converted back into the cryptocurrency they represent and sent to their owner.</p>
                            <p>In order to build a decentralized exchange, you will need the help of technology experts for decentralized crypto exchange software.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
