import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
//import { Link } from "react-router-dom";
import SECLogo from '../../Images/certificate/SEC-logo.png';
import PCILogo from '../../Images/certificate/PCI-logo.png';
import GDPRLogo from '../../Images/certificate/GDPR-Logo.png';
import KYCLogo from '../../Images/certificate/knowyourcustomer_logo_480.png';
import owaspLogo from '../../Images/certificate/owasp-logo-vert.png';
import azureLogo from '../../Images/certificate/azure@2x.png';

export default class HomeCertificate extends Component {
  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col sm={12}>
              <div className="certificatetitle">
                <h2>Is Your Blockchain Project Compliant?</h2>
                <p>There’s no doubt that adopting blockchain technology into your business will improve the security of data since
                   the technology eliminates centralized systems that cybercriminals usually target. however compliance with AML,
                   PCI,KYC, and GDPR needs also are important within the development of your blockchain solution. If you’re
                    planning a token launch, compliance with applicable securities law is additionally a fundamental requirement. 
                    Espay Exchange will help accomplish effective regulatory compliance altogether of those areas.</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
                <div className="certificatebox">
                    <img src={SECLogo} alt="" title="" />
                    <h3>Compliance with SEC requirement</h3>
                    <p>If you are Considering an Initial Coin offering (ico) or Security Token offering (STO) to boost funding for your blockchain project, 
                      we are able to facilitate ensure that your offer is compliant with SEC laws.</p>
                </div>
            </Col>
            <Col sm={4}>
                <div className="certificatebox">
                    <img src={PCILogo} alt="" title="" />
                    <h3>Payment Card industry (PCI)</h3>
                    <p>We Provide recommendation and Support with adherence to the Payment Card industry data Security standard (PCI DSS) 
                      to assist make sure that all companies store credit card data securely.</p>
                </div>
            </Col>
            <Col sm={4}>
                <div className="certificatebox">
                    <img src={GDPRLogo} alt="" title="" />
                    <h3>General data Protection (GDPR)</h3>
                    <p>GDPR needs that personally identifiable information (PII) be keep and processed according to a particular set of rules.
                       we are able to help ensure your company is compliant.</p>
                </div>
            </Col>
          </Row>

          <Row>
            <Col sm={4}>
                <div className="certificatebox">
                    <img src={KYCLogo} alt="" title="" />
                    <h3>KYC/AML Compliance</h3>
                    <p>Know your customer (KYC) and anti money laundering (AML) needs could apply to your token launch or your blockchain project.
                       Let Espay Exchange help you ensure your Project is totally compliant.</p>
                </div>
            </Col>
            <Col sm={4}>
                <div className="certificatebox">
                    <img src={owaspLogo} alt="" title="" />
                    <h3>Owasp compliance</h3>
                    <p>The Open web Application Security Project (OWASP) This technology agnostic document defines a group of
                       general software security coding practices, integrated into the software development lifecycle. 
                    </p>
                </div>
            </Col>
            <Col sm={4}>
                <div className="certificatebox">
                    <img src={azureLogo} alt="" title="" />
                    <h3>Microsoft Azure</h3>
                    <p>Microsoft Azure is designed based on Security Development Life-cycle which is an industry leading assurance process.
                       Use unique threat intelligence, built-in security controls and multi-layered from Azure to help
                       identify and protect against rapidly evolving threats.</p>
                </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
