import React, { Component } from 'react'
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import DetermineToken from '../../../Images/faq/DetermineToken.png';

export default class Determinetokenfalls extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">How to determine if a token falls into the crieteria of Security Token?</h2>
                            <img className="img-fluid faqimg" src={DetermineToken} alt="crieteria of Security Token" title="crieteria of Security Token" />                            
                            <p>There are certain qualities for anything to be considerd a security. Here are those crieterias</p>
                            <ul>
                                <li>There is an investment of money</li>
                                <li>There is an expectation of profits</li>
                                <li>The investment of money is in a common enterprise</li>
                                <li>Any profit comes from the efforts of a promoter or third party</li>
                            </ul>
                            <p>For tokens to be considered security tokens, we have similar crieterias.</p>
                            <ul>
                                <li>Is the token being sold as an investment?</li>
                                <li>Is there a person/entity upon whom investors rely?</li>
                                <li>Is there any benefits for holding the token?</li>
                                <li>Is the benefit equally shared between all the holders?</li>
                                <li>If the holders get ownership of any asset/debt?</li>
                                <li>If he gets right for voting and decision making for holding the token?</li>
                            </ul>
                            <p>These are the points which differentiate the security tokens from the other altcoins and tokens. Try to evaluate any cryptocurrencies on this crieterias to findout if it is a potential security token or not.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
