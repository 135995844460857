import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import tokenboximg from '../../Images/MarketingResearch/benefit-token-box-img.png';
import tokenboxleftimg from '../../Images/MarketingResearch/benefit-token-box-img-2.png';
import boxicon1 from '../../Images/MarketingResearch/bs-box-icon-1.png';
import boxicon2 from '../../Images/MarketingResearch/bs-box-icon-2.png';
import boxicon3 from '../../Images/MarketingResearch/bs-box-icon-3.png';
import boxicon4 from '../../Images/MarketingResearch/bs-box-icon-4.png';
import boxicon5 from '../../Images/MarketingResearch/bs-box-icon-5.png';
import boxicon6 from '../../Images/MarketingResearch/bs-box-icon-6.png';
import boxicon7 from '../../Images/MarketingResearch/bs-box-icon-7.png';

export default class BenefitsSecurityTokens extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col md={12}>
                        <div className="token-title">
                            <h3>Benefits of Security Tokens</h3>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="Benefits-Security-main">
                            <div className="bs-box-1 bs-cm">
                                <div className="bs-box-img">
                                    <img src={tokenboximg} alt="" />
                                    <div className="bs-box-icon">
                                        <img src={boxicon1} alt="" />
                                    </div>  
                                    <div className="bs-box-text">
                                        <span>Global Capital Access</span>
                                        <p>The traditional capital market is extremely restrictive. For example, a South African investor finds it challenging to invest in a small Canadian company and vice versa. With security tokens, however, cross country transactions become a lot easier. </p>
                                    </div>  
                                </div>
                            </div>
                            <div className="bs-box-2 bs-cm bs-cm-left">
                                <div className="bs-box-img">
                                    <img src={tokenboxleftimg} alt="" />
                                    <div className="bs-box-icon">
                                        <img src={boxicon2} alt="" />
                                    </div>  
                                    <div className="bs-box-text">
                                        <span>SEC Compliance</span>
                                        <p>Security tokens fall under various SEC regulations D, S, and A+. Under these regulations, you get access to accredited and non-accredited investors from the US and abroad. You also have to comply with KYC and AML norms, lending legitimacy to your security tokens.</p>
                                    </div>  
                                </div>
                            </div>

                            <div className="bs-box-3 bs-cm">
                                <div className="bs-box-img">
                                    <img src={tokenboximg} alt="" />
                                    <div className="bs-box-icon">
                                        <img src={boxicon3} alt="" />
                                    </div>  
                                    <div className="bs-box-text">
                                        <span>Higher Liquidity</span>
                                        <p>Free access to global investors results in more liquidity. Investors across countries can easily buy, sell, and trade security tokens through licensed security token exchange platforms.</p>
                                    </div>  
                                </div>
                            </div>

                            <div className="bs-box-4 bs-cm bs-cm-left">
                                <div className="bs-box-img">
                                    <img src={tokenboxleftimg} alt="" />
                                    <div className="bs-box-icon">
                                        <img src={boxicon4} alt="" />
                                    </div>  
                                    <div className="bs-box-text">
                                        <span>Quicker Execution</span>
                                        <p>Blockchain-based security tokens operate in a peer-to-peer fashion, leaving no need for a middleman or a centralized exchange. You won't need a third party to verify investors or transactions. The use of smart contracts results in faster, secure and more economical transactions.</p>
                                    </div>  
                                </div>
                            </div>

                            <div className="bs-box-5 bs-cm">
                                <div className="bs-box-img">
                                    <img src={tokenboximg} alt="" />
                                    <div className="bs-box-icon">
                                        <img src={boxicon5} alt="" />
                                    </div>  
                                    <div className="bs-box-text">
                                        <span>Improved Regulation and Credibility</span>
                                        <p>Unlike utility tokens, security tokens fall under federal regulations. The accountability factor lends more credibility to security tokens, especially from the investor's point of view.</p>
                                    </div>  
                                </div>
                            </div>

                            <div className="bs-box-6 bs-cm bs-cm-left">
                                <div className="bs-box-img">
                                    <img src={tokenboxleftimg} alt="" />
                                    <div className="bs-box-icon">
                                        <img src={boxicon6} alt="" />
                                    </div>  
                                    <div className="bs-box-text">
                                        <span>Fractional Ownership</span>
                                        <p>Security tokens allow every investor to have fractional ownership of an underlying asset. You can divide assets into smaller units available for sale. Thus, investing in security tokens becomes a lot easier for small investors.</p>
                                    </div>  
                                </div>
                            </div>

                            <div className="bs-box-7 bs-cm">
                                <div className="bs-box-img">
                                    <img src={tokenboximg} alt="" />
                                    <div className="bs-box-icon">
                                        <img src={boxicon7} alt="" />
                                    </div>  
                                    <div className="bs-box-text">
                                        <span>Transparency</span>
                                        <p>Each blockchain transaction is secure, verifiable, and reliable, making it more transparent compared to traditional transactions. This can reduce the chances of fraud, manipulation, and money laundering.</p>
                                    </div>  
                                </div>
                            </div>

                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
