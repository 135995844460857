import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";
//import Particles from 'react-particles-js';
import Mainbanner from '../../Front_And_Features/Marketing_Features/banner';
import Mainsection from '../../Front_And_Features/Marketing_Features/marketing_features_section1';
import MarketingFeaturesExfeature from '../../Front_And_Features/Marketing_Features/marketing_features_section2';
import MarketingFeaturesInterested from '../../Front_And_Features/Marketing_Features/marketing_features_section3';
import Blog from '../../Front_And_Features/Marketing_Features/marketing_features_section4';

import Certificate from '../../../Certificate/index';
import Faqmain from '../../../Faq/faq';

// const particleOpt = {
//   "particles":{
//       "number":{
//           "value":90,
//            "density":{
//                "enable":!0,
//                "value_area":300
//                }},
//             "color":{
//                 "value":["#2CC3C5","#000000","#83d238","#33b1f8"]},
//                 "shape":{"type":"circle",
//                 "stroke":{"width":0.5,"color":"#2CC3C5"},
//                 "polygon":{"nb_sides":15}},
//                 "opacity":{"value":0.5,"random":!1,"anim":{"enable":!1,"speed":1.5,"opacity_min":0.15,"sync":!1}},
//                 "size":{"value":2.5,"random":!1,"anim":{"enable":!0,"speed":2,"size_min":0.15,"sync":!1}},
//                 "line_linked":{"enable":!0,"distance":110,"color":"#2CC2C4","opacity":0.25,"width":1},
//                 "move":{"enable":!0,"speed":2.5,"direction":"none","random":!1,"straight":!1,
//                 "out_mode":"out",
//                 "bounce":!1,
//                 "attract":{"enable":!1,"rotateX":600,"rotateY":1200}}},
//                 "interactivity":{"detect_on":"canvas",
//                 "events":{"onhover":{"enable":!1,"mode":"repulse"}, 
//                 "onclick":{"enable":!1,"mode":"push"},"resize":!0},
//                 "modes":{"grab":{"distance":400,"line_linked":{"opacity":1}},
//                 "bubble":{"distance":400,"size":40,"duration":2,"opacity":8,"speed":3},
//                 "repulse":{"distance":200,"duration":0.4},
//                 "push":{"particles_nb":4},
//                 "remove":{"particles_nb":2}
//               }
//             }
//           }

export default class index extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-125025994-1')
        ReactGA.pageview(document.location.pathname)
        ReactGA.pageview(document.location.path)
      }
      scrollPositions = {}
  componentWillMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"
    }
    window.scrollTo(0, 0)
  }
  
  render() {
    return (
        <div>
            {this.props.children}
            <Helmet>
                <title>Web Trading Platform Features - Espay Exchange</title>
                <meta name="description" content="Espay Exchange Software Marketing Features include Tools to Spread Amoung Users Inclusion, Refer and Earn, Tell A Friend, Affiliate Setup, Invite Friend, Leader Board, Feature Voting, Coin Listing Voting, Feedback Survey."/>
                <meta property="og:title" content="Web Trading Platform Features - Espay Exchange" />
                <meta property="og:description" content="Espay Exchange Software Marketing Features include Tools to Spread Amoung Users Inclusion, Refer and Earn, Tell A Friend, Affiliate Setup, Invite Friend, Leader Board, Feature Voting, Coin Listing Voting, Feedback Survey." />
                <meta name="twitter:title" content="Web Trading Platform Features - Espay Exchange" />
                <meta name="twitter:description" content="Espay Exchange Software Marketing Features include Tools to Spread Amoung Users Inclusion, Refer and Earn, Tell A Friend, Affiliate Setup, Invite Friend, Leader Board, Feature Voting, Coin Listing Voting, Feedback Survey." />
                <link rel="canonical" href="https://www.espay.exchange/marketing-features" />
            </Helmet>

            <Container fluid={true} className="marketingfeature">
            <Container>
                <Mainbanner />
            </Container>
            {/* <Particles 
                className="marketing_featurecanvas"
                params={particleOpt}
                /> */}
            </Container>
            <Container className="mainsection">
                <Mainsection />
            </Container>
             <Container fluid={true} className="Marketing_Features_exfeature">
                <Container>
                    <MarketingFeaturesExfeature/>
                </Container>
            </Container>
            <Container fluid={true} className="Marketing_Features_Interested">
                <Container>
                    <MarketingFeaturesInterested/>
                </Container>
            </Container>
            <Container fluid={true} className="commonblogsection">
            <Container>
              <Blog />
            </Container>
          </Container>   

          <Container fluid={true}>
            <Container>
              <Certificate />
            </Container>
          </Container>  

          <Container>
            <h2 className="faqmaintitle">Frequently Asked Questions</h2>
            <Faqmain />
          </Container>

            <ScrollUpButton className="ScrollUpButton"/> 
        </div>
    )
  }
}

