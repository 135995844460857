import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import AttentionSecurity from '../../Images/AttentionSecurity.png';
import Developedinhouse from '../../Images/Developedinhouse.png';
import CustomizedSolution from '../../Images/CustomizedSolution.png';
import OneStopSolution from '../../Images/OneStopSolution.png';
import ScalableArchitecture from '../../Images/ScalableArchitecture.png';
import Server from '../../Images/Server.png';

class platform_standard_section3 extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm="12">
                    <div className="standardtitle">
                        <h2>Benefits For Bitcoin Exchange Platform</h2>
                        <p>The world’s fastest, highly secured, as well as most effective exchange platforms are delivered by <br/>Espay without any risk.</p>
                    </div>
                </Col>
            </Row> 
            <Row> 
                <Col sm="1">
                    <img className="img-fluid" src={AttentionSecurity} alt="Attention to Security" title="Attention to Security" />
                </Col>
                <Col sm="3">
                    <div className="standardBenefitsbox">
                        <h3>Attention to Security</h3>
                        <p>Espay’s trading platforms offers multiple layers of security that lessen hacking attempts and assure that 
                        your funds will be secure always.</p>
                    </div>
                </Col>   
                <Col sm="1">
                    <img className="img-fluid" src={Developedinhouse} alt="Developed in-house" title="Developed in-house" />
                </Col>
                <Col sm="3">
                    <div className="standardBenefitsbox">
                        <h3>Developed in-house</h3>
                        <p><Link title="Peer-To-Peer Crypto Exchanges" to="/p2p-exchange-software-development">
                        Peer-to-peer crypto exchanges </Link> which are manufactured in-house aids you keep track of modifications and 
                        scale production & enables to establish fixes in a timely fashion.</p>
                    </div>
                </Col>
                <Col sm="1">
                    <img className="img-fluid" src={CustomizedSolution} alt="Customized Solution" title="Customized Solution" />
                </Col>
                <Col sm="3">
                    <div className="standardBenefitsbox">
                        <h3>Customized Solution</h3>
                        <p>Exchange platform is highly customization, extensible as well as versatile, designed and developed to help
                                to save your time.</p>
                    </div>
                </Col>               
            </Row>
            <Row className="mt-20">   
                <Col sm="1">
                    <img className="img-fluid" src={OneStopSolution} alt="One Stop Solution" title="One Stop Solution" />
                </Col>
                <Col sm="3">
                    <div className="standardBenefitsbox">
                        <h3>One Stop Solution</h3>
                        <p>Developers at Espay keep eyes on the continuous updates in technology to deliver one-stop exchange solutions
                        in each corner of the world.</p>
                    </div>
                </Col>
                <Col sm="1">
                    <img className="img-fluid" src={ScalableArchitecture} alt="Scalable Architecture" title="Scalable Architecture" />
                </Col>
                <Col sm="3">
                    <div className="standardBenefitsbox">
                        <h3>Scalable Architecture</h3>
                        <p>Espay’s trading software comprises a scalable architecture that can scale up to come across increased
                            transaction loads.</p>
                    </div>
                </Col>
                <Col sm="1">
                    <img className="img-fluid" src={Server} alt="Free Setup on Your Server" title="Free Setup on Your Server" />
                </Col>
                <Col sm="3">
                    <div className="standardBenefitsbox">
                        <h3>Free Setup on Your Server</h3>
                        <p>Reliable and free setup on your server from one of the best exchange software solution providers Espay,
                        keeps your website up and running hassle-free.</p>
                    </div>
                </Col>                
            </Row>
        </div>
      );
    }
}

export default platform_standard_section3;