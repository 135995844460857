import React, { Component } from 'react';
import { Row, Col, Jumbotron} from 'reactstrap';
import { Link } from "react-router-dom";

export default class banner extends Component {
  render() {
    return (
      <div>
        <Row>
            <Col sm={{size:8,offset:2}}>                       
                <Jumbotron>
                    <div className="bannerdetails">
                        <h1>Legal Advice For Your Security Token Offering</h1>
                          <Link to="/talk-to-crypto-exchange-expert">Talk To Our Experts</Link>
                    </div>
                </Jumbotron>
            </Col>
        </Row> 
      </div>
    )
  }
}
