import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
//import { Link } from "react-router-dom";

import Seamless from "../../Images/Mobile_Trading_Terminal/seamless_Integrations.png";
import Onboarding from "../../Images/Mobile_Trading_Terminal/Easy_Onboarding.png";
import Flexible from "../../Images/Mobile_Trading_Terminal/Flexible_Delivery.png";
import Attractive_Interface from "../../Images/Mobile_Trading_Terminal/Attractive_interface.png";



export default class MobileAdvantage extends Component {
    render() {
        return (
            <div className="mobile-advantage">
                <Container>
                    <h2 className="">Key advantages of mobile trading terminals</h2>

                    <Row className="mobile-advantage-box">
                        <div className="col-sm-6 mobile-adv-line-right">
                            <h3>Seamless integration</h3>
                            <Row>
                                <Col sm="12">
                                    <p>Solutions are based on standard ready-to-use react native based Mobile platform, offering each and every level of customization, fast implementation, and a limited time to market.</p>
                                    <p>Amalgamation with third-party venues via APIs and special bridges</p>
                                </Col>
                            </Row>
                        </div>
                        <div className="col-sm-6 text-center  mobile-adv-line-left ">
                            <div className="lavrageadv-stuff text-center">
                                <img src={Seamless} alt="Seamless" title="Seamless" className="img-fluid mobile-adv-image-1" />
                            </div>
                        </div>
                    </Row>

                    <Row className="mobile-advantage-box">
                        <div className="col-sm-6 mobile-adv-line-right">
                            <div className="lavrageadv-stuff text-center">
                                <img src={Onboarding} alt="Onboarding" title="Onboarding" className="img-fluid mobile-adv-image-2" />
                            </div>
                        </div>
                        <div className="col-sm-6  mobile-adv-line-left ">
                            <h3 className="text-right">Easy Onboarding</h3>
                            <Row className="text-right">
                                <Col sm="12">
                                    <p>Conventional user-interfaces and user-friendly experience</p>
                                    <p>Easy to access and understand introduction for inexperienced traders</p>
                                    <p>Adjustable color spaces and dynamic themes</p>
                                    <p>Client assistance with special regional requirements</p>
                                </Col>
                            </Row>
                        </div>
                    </Row>

                    <Row className="mobile-advantage-box">
                        <div className="col-sm-6 mobile-adv-line-right">
                            <h3>Flexible delivery</h3>
                            <Row>
                                <Col sm="12">
                                    <p>Affordable white-labeling</p>
                                    <p>UIs from the top</p>
                                    <p>Adapting to regional requirements and localization of solutions</p>
                                    <p>Flexibility with backend options: build on EspayX OMS, third-party backend, or tailored custom systems with a range of asset classes</p>
                                </Col>
                            </Row>
                        </div>
                        <div className="col-sm-6 text-center  mobile-adv-line-left ">
                            <div className="lavrageadv-stuff text-center">
                                <img src={Flexible} alt="Flexible" title="Flexible" className="img-fluid mobile-adv-image-3" />
                            </div>
                        </div>
                    </Row>
                    

                    <Row className="mobile-advantage-box">
                        <div className="col-sm-6 mobile-adv-line-right">
                            <div className="lavrageadv-stuff text-center">
                                <img src={Attractive_Interface} alt="Attractive" title="Attractive" className="img-fluid mobile-adv-image-4" />
                            </div>
                        </div>
                        <div className="col-sm-6  mobile-adv-line-left ">
                            <h3 className="text-right">Attractive Interface</h3>
                            <Row className="text-right">
                                <Col sm="12">
                                    <p>Interactive charts that come with real-time data</p>
                                    <p>A wide range of analysis possibilities including drawing tools and customizable studies</p>
                                    <p>Portfolio editing from the chart</p>
                                    <p>Push notifications and sophisticated price alerts with fine adjustment options</p>
                                </Col>
                            </Row>
                        </div>
                    </Row>



                </Container>
            </div>
        )
    }
}
