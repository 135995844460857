import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";

import IEOValidationBanner from "./IEOBanner";


export default class index extends Component {
  componentDidMount() {
    ReactGA.initialize('UA-125025994-1')
    ReactGA.pageview(document.location.pathname)
    ReactGA.pageview(document.location.path)
  }
  
  scrollPositions = {}
  componentWillMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"
    }
    window.scrollTo(0, 0)
  }
  
  render() {
    return (

      <div>
        {this.props.children}
        <Helmet>
            <title>Pre IEO Validation</title>
            <meta name="description" content=""/>
            <meta property="og:title" content="" />
            <meta property="og:description" content="" />
            <meta name="twitter:title" content="" />
            <meta name="twitter:description" content="" />
            <link rel="canonical" href="https://www.espay.exchange/" />
        </Helmet>

        <Container fluid={true} className="ieovalbanner">
          <Container>
            <IEOValidationBanner />
          </Container>
        </Container>

        <Container>
          
        </Container>   


        <ScrollUpButton className="ScrollUpButton"/>
      </div>
    )
  }
}