import React, { Component,Fragment } from 'react';
import { Form, FormGroup, Input, Container, Row, Col } from 'reactstrap';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMapMarkerAlt, faMobileAlt, faEnvelope, faAddressBook } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import skypeicon from '../../Images/Socialmedia/skypeicon.png';
import telegramicon from '../../Images/Socialmedia/telegramicon.png';
import whatsappicon from '../../Images/Socialmedia/whatsappicon.png';

import JbsPropogateLoader from "../PageLoader/PropagateLoader";

//import Mapmain from "../Contactus/map";

export default class Contactusdetials extends Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
      Phone: ""
    }

    this.submituserContactusForm = this.submituserContactusForm.bind(this);

  };
  handleOnChange = (value) => {
    console.log("38 contactvalue", value);
    this.setState({ Phone: value }, () => {
      console.log(this.state.Phone);
    });
  };


  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;

    this.setState({
      fields
    });

  }
  handlemobileChange = (value, data) => {
    let fields = this.state.fields;
    fields['Phone'] = value;
    this.setState({
      fields
    });
  }

  //Espay.com.au Main-Form

  submituserContactusForm(e) {
    e.preventDefault();
    if (this.validateForm()) {
      let fields = {};
      fields["name"] = "";
      fields["emailid"] = "";
      fields["Country"] = "";
      fields["mobileno"] = "";
      fields["message"] = "";
      

      const contactUsRequest = {
        "Name": this.state.fields.Name,
        "EmailID": this.state.fields.EmailID,
        "Country": this.state.fields.Country,
        "Phone": this.state.fields.Phone,
        "Description": this.state.fields.Description
      }
      this.setState({ pageLoader: true })
      axios.defaults.headers.post['Content-Type'] = 'application/json';
      axios.post('https://espaynode.espay.exchange/api/espayAuForm', contactUsRequest)
        .then((result) => {
          this.setState({ fields: fields });
          window.location.href = "https://www.espay.exchange/thank-you/";
        })
        .catch((error) => {
          this.setState({ pageLoader: false })
        })
    }
  }

  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["Name"]) {
      formIsValid = false;
      errors["name"] = "*Please Enter Your Name.";
    }

    if (typeof fields["Name"] !== "undefined") {
      if (!fields["Name"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["name"] = "*Please Enter Alphabet Characters only.";
      }
    }
    if (!fields["Country"]) {
      formIsValid = false;
      errors["country"] = "*Please Enter Your Country.";
    }
    if (!fields["EmailID"]) {
      formIsValid = false;
      errors["emailid"] = "*Please Enter Your Email-ID.";
    }

    if (typeof fields["EmailID"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["EmailID"])) {
        formIsValid = false;
        errors["emailid"] = "*Please Enter valid Email-ID.";
      }
    }

    if (!fields["Phone"]) {
      formIsValid = false;
      errors["mobileno"] = "*Please Enter Your Phone Number.";
    }
    if (!fields["Description"]) {
      formIsValid = false;
      errors["messagedes"] = "*Please Enter Your Message.";
    }
    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  render() {
    return (
      <Fragment>
        {this.state.pageLoader && <JbsPropogateLoader />}
        <Container className="Contactusmain">
          <Row>
            <Col sm={12}>
              <h2 className="text-center">Are You Open For A Conversation With Our Exchange Expert?</h2>
              <p className="text-center">We can rephrase line with We will get back to you within 24 hrs.</p>
            </Col>
          </Row>
          <Row className="mt-50">
            <Col sm={5}>
              <div className="contacttitle">
                <h2>Reach Out Us</h2>
              </div>
              
              <Form method="post" name="userContactusForm" onSubmit={this.submituserContactusForm} >
                <FormGroup>
                  <input type="text" name="Name" className="form-control" placeholder="Your Name *" value={this.state.fields.Name} onChange={this.handleChange} />
                  <div className="errorMsg">{this.state.errors.name}</div>
                </FormGroup>
                <FormGroup>
                  <input type="text" name="EmailID" className="form-control" placeholder="Your Email *" value={this.state.fields.EmailID} onChange={this.handleChange} />
                  <div className="errorMsg">{this.state.errors.emailid}</div>
                </FormGroup>
                <FormGroup>
                  <input type="text" name="Country" className="form-control" placeholder="Your Country *" value={this.state.fields.Country} onChange={this.handleChange} />
                  <div className="errorMsg">{this.state.errors.country}</div>
                </FormGroup>
                <FormGroup>
                  {/* <input type="text" name="Phone" className="form-control" placeholder="Your Phone Number with country code*" value={this.state.fields.Phone} onChange={this.handleChange} /> */}
                  {/* <PhoneInput
                    inputExtraProps={{
                      name: "Phone",
                      required: true,
                      autoFocus: false
                    }}
                    defaultCountry={'us'}
                    value={this.state.fields.Phone}
                    onChange={this.handlemobileChange}
                    className="form-control"
                  />
                  <div className="errorMsg">{this.state.errors.mobileno}</div> */}
                  <PhoneInput
                    inputextraprops={{
                      name: "Phone",
                      required: true,
                      autoFocus: true
                    }}
                    value={this.state.fields.Phone}
                    onChange={this.handlemobileChange}
                    className="form-control"
                    error={this.state.fields.Phone ? (isValidPhoneNumber(this.state.fields.Phone) 
                      ? undefined : 'Invalid phone number') : this.state.errors.mobileno}
                  />
                </FormGroup>
                <FormGroup className="mt-30">
                  <textarea name="Description" className="form-control" placeholder="Your Message" style={{ height: '93px' }} value={this.state.fields.Description} onChange={this.handleChange} ></textarea>
                  <div className="errorMsg">{this.state.errors.messagedes}</div>
                </FormGroup>
                <FormGroup className="contactbtn">
                  <Input className="contactsendbutton" type='submit' value='Send' />
                </FormGroup>
                <FormGroup>
                    <div className="contactlinkbox">
                        <a href="https://join.skype.com/invite/hzYIupE2Wna8" target="_blank" rel="noopener noreferrer">
                        <img src={skypeicon} alt="Skype" title="Skype" />Skype
                      </a>
                      <a href="https://api.whatsapp.com/send?phone=61421-514-353" target="_blank" rel="noopener noreferrer">
                        <img src={whatsappicon} alt="Whatapps" title="Whatapps" /> Whatsapp
                      </a>
                      <a href="https://t.me/Mrudul_Espay" target="_blank" rel="noopener noreferrer">
                        <img src={telegramicon} alt="Telegram" title="Telegram" /> Telegram
                      </a>
                    </div>
                </FormGroup>
              </Form>
            </Col>
            <Col sm={1}></Col>
            <Col sm={6}>
              <div className="contacttitle">
                <h2>Schedule A Meeting</h2>
              </div>  
              <iframe title="Mrudul" frameBorder="0" 
              src="https://calendly.com/mrudul" 
              height="100%" width="100%"
              style={{minHeight:'300px' }}>

              </iframe>
            </Col>
          </Row>
        </Container>
      </Fragment>
    )
  }
}
// export default GoogleApiWrapper({
//   apiKey: apiMaps
// })(Contactusdetials);