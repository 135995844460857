import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import mission from '../../Images/mission.png';

class mision_section2 extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm="6">
                <div className="Missiondes">
                    <h2>Our Mission</h2>
                    <p>Espay has set its mission since inception to provide value and high performance at each stage of exchange 
                        software development. To reach our objectives, Espay has its goals clear- nurturing a culture of 
                        revolution as well as passion within the organization. We make it possible with endless investment in R&D 
                        to stay ahead of the tech curve.</p>
                    <ul>
                        <li> Run a business that is sustainable</li>
                        <li>Software excellence and revolutionize the industry</li>
                        <li>Support keenly for social as well as economic impartiality</li>
                    </ul>
                </div>
                </Col>
                <Col sm={{size:4,offset:1}}>                       
                    <img className="img-fluid" src={mission} alt="Our Mission" title="Our Mission" />
                </Col>
            </Row> 
        </div>
      );
    }
}

export default mision_section2;