import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import centralizedEnterpriseExchangeSolution from '../../Images/centralizedEnterpriseExchangeSolution.png';

class packages_centralized_section6_2 extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm="5">
                    <div className="CentralizedWhiteLabel">
                        <h2>Enterprise  Exchange Solution</h2>
                        <hr/>
                        <ul>
                            <li>Hybrid Version</li>
                            <li>Android & IOS Apps</li>
                            <li>Hot & Cold Wallet</li>
                            <li>Admin Control</li>
                            <li>AI Based Tools</li>
                            <li>Source Code Access</li>
                        </ul>
                    <Link to="/contact-us">Dominate</Link>
                    </div>
                </Col>
                <Col sm="7">
                    <img src={centralizedEnterpriseExchangeSolution} className="centrlized-feature-Enterprise-img" alt="Standard Exchange Solution" title="Standard Exchange Solution" />
                </Col>
            </Row>
        </div>
      );
    }
}

export default packages_centralized_section6_2;