import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

import Stepone from "../../Images/OTC/Stepone.png";
import Steptwo from "../../Images/OTC/Steptwo.png";
import Stepthree from "../../Images/OTC/Stepthree.png";
import Stepfour from "../../Images/OTC/Stepfour.png";
import Stepfive from "../../Images/OTC/Stepfive.png";
import Stepsix from "../../Images/OTC/Stepsix.png";


export default class OtcStep extends Component {
    render() {
        return (
            <div>
                <Row className="mt-30 mb-30">
                    <Col sm="12">
                        <div className="stoadviceitle mt-30">
                            <h2>How we develop custom software for crypto OTC Desk (Process Flow)</h2>
                        </div>
                    </Col>
                </Row>


                <Row className="OTCDesk-process">
                    <Col sm="4" className="Otcstep step-one">
                        <div className="OTCDesk-process-box">
                            <img src={Stepone} alt="Stepicon" title="Stepicon" />
                            <h3>Step 1</h3>
                            <p>Project Feasibility study, requirement gathering and Business Model drafting to define the project scope and implementation strategy.</p>
                        </div>
                    </Col>
                    <Col sm="4" className="Otcstep step-two">
                        <div className="OTCDesk-process-box">
                            <img src={Steptwo} alt="Stepicon" title="Stepicon" />
                            <h3>Step 2</h3>
                            <p>Legal compliance set-up guidance with the tokenomics implementation and platform architecture modeling as per the geo compliance.</p>
                        </div>
                    </Col>
                    <Col sm="4" className="Otcstep step-three">
                        <div className="OTCDesk-process-box">
                            <img src={Stepthree} alt="Stepicon" title="Stepicon" />
                            <h3>Step 3</h3>
                            <p>Custom UI/UX development of modules with the smart contracts <br />and Escrow Setup.</p>
                        </div>
                    </Col>
                </Row>

                <Row className=" OTCDesk-process OTCDesk-process-second otc-desktop-step  ">

                    <Col sm="4" className="Otcstep step-four">
                        <div className="OTCDesk-process-box">
                            <img src={Stepsix} alt="Stepicon" title="Stepicon" />
                            <h3>Step 6</h3>
                            <p>Private Environment setup over the dedicated server for the platform launch and go live in the market</p>
                        </div>
                    </Col>
                    <Col sm="4" className="Otcstep step-five">
                        <div className="OTCDesk-process-box">
                            <img src={Stepfive} alt="Stepicon" title="Stepicon" />
                            <h3>Step 5</h3>
                            <p>Liquidity pool setup with the final beta testing and security audits and complete platform training by the project management team.</p>
                        </div>
                    </Col>
                    <Col sm="4" className="Otcstep step-six">
                        <div className="OTCDesk-process-box">
                            <img src={Stepsix} alt="Stepicon" title="Stepicon" />
                            <h3>Step 4</h3>
                            <p>Setting up the exchange with desired coins/tokens and engines with the Multi-sig wallet in the local infrastructure for the internal testing.</p>
                        </div>
                    </Col>
                </Row>

                <Row className=" OTCDesk-process OTCDesk-process-second  otc-mobile-step ">
                    <Col sm="4" className="Otcstep step-six">
                        <div className="OTCDesk-process-box">
                            <img src={Stepfour} alt="Stepicon" title="Stepicon" />
                            <h3>Step 4</h3>
                            <p>Setting up the exchange with desired coins/tokens and engines with the Multi-sig wallet in the local infrastructure for the internal testing.</p>
                        </div>
                    </Col>

                    <Col sm="4" className="Otcstep step-five">
                        <div className="OTCDesk-process-box">
                            <img src={Stepfive} alt="Stepicon" title="Stepicon" />
                            <h3>Step 5</h3>
                            <p>Liquidity pool setup with the final beta testing and security audits and complete platform training by the project management team.</p>
                        </div>
                    </Col>
                    <Col sm="4" className="Otcstep step-four">
                        <div className="OTCDesk-process-box">
                            <img src={Stepsix} alt="Stepicon" title="Stepicon" />
                            <h3>Step 6</h3>
                            <p>Private Environment setup over the dedicated server for the platform launch and go live in the market</p>
                        </div>
                    </Col>

                </Row>

            </div>
        )
    }
}
