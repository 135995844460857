import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

import ContentMarketing from "../../Images/STO/STO-Content-Marketing.png";
import OnlineAdvertising from "../../Images/STO/STO-Online-Advertising.png";


export default class MarketingAgency extends Component {
  render() {
    return (
      <div>
        <Row className="mt-30 mb-30">   
            <Col sm="12">
                <div className="marketingmaintitle">
                    <h2>STO Marketing Agency</h2>
                </div>
            </Col>
        </Row>
        <Row className="mt-30 mb-30">   
            <Col sm="12">
                <div className="marketingmaintitle">
                    <h3>STO Marketing Consulting</h3>
                    <p>Our STO marketing consultants have been supporting successful blockchain projects for several years. In addition, we advise you on all aspects of STO marketing and are always ready to answer your questions.</p>
                </div>
            </Col>
        </Row>
        <Row className="mt-30 mb-30">   
            <Col sm="6">
                <div className="marketingbox">
                    <h3>STO marketing planning and strategy</h3>
                    <p>At the beginning, we start working on an STO marketing strategy, which we create individually for your project. Then we plan all marketing activities that are promising for your STO. We tailor these activities according to the industry in which your project is based.</p>
                </div>
            </Col>
            <Col sm="6">
                <div className="marketingbox">
                    <h3>STO analysis</h3>
                    <p>Our marketing consultants analyse the current status of your STO project and optimize all ongoing marketing activities.</p>
                </div>
            </Col>
        </Row>
        <Row className="mt-30 mb-30">   
            <Col sm="12">
                <div className="marketingmaintitle">
                    <h3>STO Whitepaper</h3>
                </div>
            </Col>
        </Row>
        <Row className="mt-30 mb-30">   
            <Col sm="6">
                <div className="marketingbox">
                    <h3>STO whitepaper creation</h3>
                    <p>If required, our STO agency can take over the creation of your STO whitepaper. We know the variables that influence the investment decision of your investors and the legal requirements of STO whitepapers.</p>
                </div>
            </Col>
            <Col sm="6">
                <div className="marketingbox">
                    <h3>STO whitepaper optimization</h3>
                    <p>If you have already created an STO whitepaper, we can optimize it and improve it if necessary.</p>
                </div>
            </Col>
        </Row>
        <Row className="mt-30 mb-30">   
            <Col sm="12">
                <div className="marketingmaintitle">
                    <h3>STO Content Marketing</h3>
                    <p>Content Marketing involves creating blog, articles and other content that inform about your project.</p>
                </div>
            </Col>
        </Row>
        <Row className="mt-30 mb-30">   
            <Col sm="6">
                <div className="marketingbox">
                    <h3>STO Content Marketing Plan</h3>
                    <p>Our STO marketing agency starts with the creation of an STO Content Marketing Plan. The creation includes the definition of the content (articles, videos, Infographics, etc.) as well as the selection of the appropriate topics.</p>
                </div>
                <div className="marketingbox">
                    <h3>Content creation</h3>
                    <p>After we have created the STO Content Marketing Plan, we continue to create the content. Our authors have been involved in Blockchain industry for years and are experts in creating quality articles.</p>
                </div>
                <div className="marketingbox">
                    <h3>Promotion</h3>
                    <p>Afterwards we arrange for the promotion of the content on crypto news sites, social media etc.</p>
                </div>
            </Col>
            <Col sm="6">
                <img className="img-fluid" src={ContentMarketing} alt="STO Content Marketing" title="STO Content Marketing" />
            </Col>
        </Row>
        <Row className="mt-30 mb-30">   
            <Col sm="12">
                <div className="marketingmaintitle">
                    <h3>STO Online Advertising</h3>
                    <p>STO Advertising deals with online ads on Google, Facebook and crypto networks. Our STO marketing agency in Australia is knowledgeable in creating online ads and their permanent optimization. We only have positive investment returns with all the ICO and STO projects we have worked with in this area. Especially with crypto networks we have managed to reduce the CPC (costs per click) to a minimum.</p>
                </div>
            </Col>
        </Row>

        <Row className="mt-30 mb-30"> 
            <Col sm="6">
                <img className="img-fluid" src={OnlineAdvertising} alt="STO Online Advertising" title="STO Online Advertising" />
            </Col>  
            <Col sm="6">
                <div className="marketingbox">
                    <h3>Google Adwords</h3>
                    <p>Google Adwords is the top advertising space in the online space. Our STO marketing services include the conception, creation and continuous optimization of the advertising texts and the banner for the campaigns on Google Ads.</p>
                </div>
                <div className="marketingbox">
                    <h3>Facebook Ads</h3>
                    <p>Facebook is almost unimaginable today when it comes to online advertising. Our security token offering agency will develop a Facebook Ad strategy and deliver high quality and converting ads to Facebook, as well as continue to refine them.</p>
                </div>
                <div className="marketingbox">
                    <h3>Native Ads</h3>
                    <p>Native Ads have an excellent cost-to-value ratio because they are 1. very cheap and 2. are targeted to potential STO investors. Crypto networks are the fundamental part of our STO advertising strategy.</p>
                </div>
                <div className="marketingbox">
                    <h3>Retargeting</h3>
                    <p>Retargeting allows us to re-address potential prospects who have shown interest in your STO, but who are not yet invested in your STO. We provide retargeting setup and retargeting campaigns, which have both a higher conversion rate than traditional ads and lower costs.</p>
                </div>
            </Col>
        </Row>    
      </div>
    )
  }
}
