import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import PriceFormation from '../../Images/PriceFormation.png';
import analytics from '../../Images/analytics.png';
import transaction from '../../Images/transaction.png';
import execution from '../../Images/execution.png';

class why_choose_hybrid_section4 extends Component {
    render() {
      return (
        <div className="mb-30"> 
            <Row> 
                <Col sm="12">
                    <div className="hybridtitle">
                        <h2>Why Choose Espay For Hybrid Exchange Development ?</h2>
                        <p className="why_title">Powered by round-the-clock support providing hybrid cryptocurrency exchange platforms to users of all 
                            levels from startups to industry’s professionals.</p>
                    </div>
                </Col>
            </Row> 
            <Row className="HybridWhyChoose">
                <Col sm="3">
                    <img className="img-fluid" src={PriceFormation} alt="Transparent Price Formation" title="Transparent Price Formation" />
                    <h3>Transparent Price Formation</h3>
                </Col>
                <Col sm="3">
                    <img className="img-fluid" src={analytics} alt="Advanced Analytics" title="Advanced Analytics" />
                    <h3>Advanced Analytics</h3>
                </Col>
                <Col sm="3">
                    <img className="img-fluid" src={transaction} alt="High Transaction Throughput" title="High Transaction Throughput" />
                    <h3>High Transaction Throughput</h3>
                </Col>
                <Col sm="3">
                    <img className="img-fluid" src={execution} alt="Latency And Fair Execution" title="Latency And Fair Execution" />
                    <h3>Latency And Fair Execution</h3>
                </Col>
            </Row>         
        </div>
      );
    }
}

export default why_choose_hybrid_section4;