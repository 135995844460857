import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

export default class WhatSecuritytoken extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is Security token</h2>

                            <p>As we seen previously, A security is a tradable financial asset. It can be equity, debt or asset. The tokenized form of such security makes it security token. Such tokens, represent the security and its holder has the ownership of that security. For which he either gets right to use property, voting right and share in profit in equity or interest on the debts he is owning. In simpler terms, security tokens are cryptographic tokens that pay dividends, share profits, pay interest or invest in other tokens or assets to generate profits for the token holders. This takes care of the liquidity issues.Imagine, your dividends being paid to you on a specific date upon meeting a certain condition via a smart contract!</p>
                            <p>All these programmabilities can bring in a lot of automation and swiftness to the whole process because after all, you are creating programmable security. And this type of security can do all things that a traditional form can and more.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
