import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";


export default class TechnicalSpecifications extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What are the key technical specifications of the decentralized exchange ?</h2> 
                            <p>These exchanges use the Ethereum platform as their underlying technology and run as a series of smart contracts. In other words, there is no central point where the technology is stored, run or managed. Radar Relay has a team of open source contributors rather than a traditional incorporation structure.</p>
                            <p>It is important to know that these technologies are still early, low volume and very slow compared to all other types of centralised service. A small enthusiast audience do use truly decentralised exchanges, but their volume is tiny when compared to the rest of the crypto markets.</p>
                            <p>It is too early to say what the standard AML / KYC (or CDD) rules will look like in this arena, but there is no reason why a DEX could not implement rules or adhere to a code of conduct. Even though their “management team” does not have a clear nexus, they could still comply with global standards and local market regulations.</p>
                            <p>As an aside, this points to the potential for “decentralised market structure”. Which is an area of potential significant innovation from a resilience perspective. In addition, decentralised market structures have the potential to limit the systemic risk of FMI’s to be too big to fail (given they can be owned and operated by many parties at once).</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
