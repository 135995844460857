import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import MIFID from '../../../Images/faq/MIFID.jpg';

export default class FinancialDirective extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">What is Markets In Financial Directive II (MiFID II)?</h2> 
                            <img src={MIFID} alt="Financial Directive" title="Financial Directive" className="img-fluid faqimg"/>
                            <p>The EU instituted MiFID II regulations on January 3, 2018. This legislative framework provides investors with added protections. Additionally, MiFID II regulations improve the functionality and efficiency of the market. The legislation is a follow up to the original MiFID (Markets in Financial Directive) regulations which have been in operation since November 2007.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
