import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import Interestedforexexchange from '../../Images/Interestedforexexchange.png';
import Interestedp2p from '../../Images/Interestedp2p.png';
import Interesteddes from '../../Images/Interesteddes.png';

class product_crypto_section6 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12">
                    <div className="CryptoSoluctiontitle">
                        <h2>Our Other Exchange Product</h2>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="4">
                    <div className="cryptoproductbox">
                        <Link to="#" title="Forex Exchange Solutions">
                            <img className="img-fluid" src={Interestedforexexchange} alt="Forex Exchange Solutions" title="Forex Exchange Solutions"/>
                            <h3>Forex Exchange Solutions</h3>
                            <p>Espay is a trusted face globally, whether you’re searching for wholesale/retail currency exchange
                                platform you are assured a safe and suitable service.</p>
                        </Link>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="cryptoproductbox">
                        <Link to="#" title="P2P Exchange Software">
                            <img className="img-fluid" src={Interestedp2p} alt="P2P Exchange Software" title="P2P Exchange Software" />
                            <h3>P2P Exchange Software</h3>
                            <p>With P2P trading platforms, developers at Espay strive to preserve the real openness that the 
                                Blockchain Technology conveys.</p>
                        </Link>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="cryptoproductbox">
                        <Link to="#" title="Decentralized Exchange">
                            <img className="img-fluid" src={Interesteddes} alt="Decentralized Exchange" title="Decentralized Exchange" />
                            <h3>Decentralized Exchange</h3>
                            <p>Highly secure & anonymous. Everything gets stored on the users’ device without worrying about server
                            down time and fraudulent activities. </p>
                        </Link>
                    </div>
                </Col>
            </Row>
        </div>
      );
    }
}

export default product_crypto_section6;