import React, { Component } from 'react';
import { Container,  Col, Row } from 'reactstrap';
import Faqleftpanel from "../Faqleftpanel";

import decfaq1 from '../../../Images/decfaq1.png';
import decfaq2 from '../../../Images/decfaq2.png';

export default class GrowthPotential extends Component {
    render() {
        return (
            <div className="main">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                        <Faqleftpanel {...this.props} />
                        </Col>
                        <Col md={7} className="mx-auto">
                            <h2 className="topinnertitle">The growth and potential of decentralized exchanges.</h2> 
                            <img src={decfaq1} alt="growth and potential" title="growth and potential" className="img-fluid faqimg"/>
                            <p>24hr volume indicates the USD value for number of tokens traded on an exchange in a 24 hour period (UTC times used). Numbers are extremely large thus the 'E+..' notation is used in most cases. I.e. 7.91+E14 equals ~$791000000000000.</p>
                            <img src={decfaq2} alt="Centralized vs Decentralized" title="Centralized vs Decentralized" className="img-fluid faqimg"/>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
