import React, { Component } from 'react';
import {Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import ScalableArchitecture1 from '../../Images/ScalableArchitecture1.png';
import BankingGradeSecurity5 from'../../Images/BankingGradeSecurity5.png';
import EdgeTechnology from  '../../Images/EdgeTechnology.png';
import fMarket from '../../Images/fMarket.png';

class exchange_solution_centralized_Section4 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12">
                    <div className="Centralizedtitle">
                        <h2>Benefits For Centralized Exchange Solution</h2>
                        <p>As a leading <Link to="/" title="Exchange Software Development Company">exchange software development company,</Link>
                         Our Espay Exchange software has unique benefits that aid your business to stay on top level.</p>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="1">
                    <img className="img-fluid centralized-fea-icon" src={ScalableArchitecture1} alt="Scalable Architecture" title="Scalable Architecture" />
                </Col>
                <Col sm="5">
                    <div className="CentralizedBenefitsbox">
                        <h3>Scalable Architecture</h3>
                        <p className="service">Espay’s trading software comprises a scalable architecture that can scale up to come across increased
                            transaction loads.</p>
                    </div>
                </Col> 
                <Col sm="1">
                    <img className="img-fluid centralized-fea-icon" src={BankingGradeSecurity5} alt="Security at its Core" title="Security at its Core" />
                </Col>
                <Col sm="5">
                    <div className="CentralizedBenefitsbox">
                        <h3>Security at its Core</h3>
                        <p className="service">Our exchange platforms are developed to be secure. Our team is expert in building secure
                        software based on the latest standards in the FinTech industry.</p>
                    </div>
                </Col> 
            </Row>
            <Row>
                <Col sm="1">
                    <img className="img-fluid centralized-fea-icon" src={EdgeTechnology} alt="Cutting-Edge Technology" title="Cutting-Edge Technology" />
                </Col>
                <Col sm="5">
                    <div className="CentralizedBenefitsbox">
                        <h3>Cutting-Edge Technology</h3>
                        <p className="service">Developers at Espay keep eyes on the continuous updates in technology to deliver 
                        cutting-edge <Link to="/crypto-exchange-software">exchange solutions</Link> in each corner of the world.</p>
                    </div>
                </Col> 
                <Col sm="1">
                    <img className="img-fluid centralized-fea-icon" src={fMarket} alt="Fast Time to Market" title="Fast Time to Market" />
                </Col>
                <Col sm="5">
                    <div className="CentralizedBenefitsbox">
                        <h3>Fast Time to Market</h3>
                        <p className="service">Trading platforms from Espay are developed and optimized in parallel with the 
                        preparation of production which reduces time to market.</p>
                    </div>
                </Col> 
            </Row>
        </div>
      );
    }
}

export default exchange_solution_centralized_Section4;