import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import Interestedsec from '../../Images/Interestedsec.png';
import HybridCryptoExchange from '../../Images/HybridCryptoExchange.png';
import Interesteddes1 from '../../Images/Interesteddes1.png';

class product_crypto_section6 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12">
                    <div className="enterprisetitle">
                        <h2>Our Other Exchange Product</h2>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="4">
                    <div className="enterpriseproductbox">
                        <Link to="/security-token-exchange" title="Security Token Exchange">
                            <img src={Interestedsec} alt="Security Token Exchange" title="Security Token Exchange" />
                            <h3>Security Token Exchange</h3>
                            <p>Espay offers a fully-compliant-focused security token and digital coin trading platforms that 
                                satisfy your active traders’ every demand. </p>
                        </Link>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="enterpriseproductbox">
                        <Link to="/hybrid-crypto-exchange-software" title="Hybrid Crypto Exchange">
                            <img src={HybridCryptoExchange} alt="Hybrid Crypto Exchange" title="Hybrid Crypto Exchange"/>
                            <h3>Hybrid Crypto Exchange</h3>
                            <p>Espay is delivering robust Hybrid crypto Exchange platforms for the users who need automatization, 
                                complex order structures, as well as speed.  </p>
                        </Link>
                    </div>
                </Col>
                <Col sm="4">
                    <div className="enterpriseproductbox">
                        <Link to="/decentralized-crypto-exchange-software" title="Decentralized Exchange Software">
                            <img src={Interesteddes1} alt="Decentralized Exchange Software" title="Decentralized Exchange Software" />
                            <h3>Decentralized Exchange Software</h3>
                            <p> Revolutionize your business with Espay’s decentralized exchange development that make your whole 
                                business completely distributed on a decentralized network.   </p>
                        </Link>
                    </div>
                </Col>
            </Row>
        </div>
      );
    }
}

export default product_crypto_section6;