import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import ExchangeSoftwarepc from '../../Images/centralized/ExchangeSoftwarepc.png';

class software_standard_section1 extends Component {
    render() {
      return (
        <div> 
            <Row className="mt-30 mb-30">   
                <Col sm={4}>
                    <img className="img-fluid" src={ExchangeSoftwarepc} title="Crypto Exchange Development Company" 
                    alt="Crypto Exchange Development Company" />
                </Col>
                <Col sm={{size:7, offset:1}}>
                    <div className="standardabouttitle">
                        <h2>Know More About Crypto Exchange Software</h2>
                        <p>At Espay – best centralized <Link title="Crypto Exchange Development Company" to="/"><strong>Crypto Exchange Development Company</strong></Link> , we have solutions
                            for Bitcoin and Altcoins such as Litecoin, Ethereum, Ripple, Bitcoin cash and much more existing presently. 
                            Digital coins are virtual currencies that are not managed by a specific banking system or a country. 
                            They empower a perfect P2P mode of fund transfer among countries without getting into the mandatory chains of 
                            fees/trading rates.</p>
                        <p>The skilled developers at Espay perform to make crypto exchange simple, fun as well as intuitive. The 
                            latest technology empowers us to provide a solution for the development of crypto marketplace as well. If 
                            you want to be a part of the digital coin exchange world and want to integrate an exchange platform for 
                            your business, Espay has a solution.</p>
                        <p>We have a perfect team enriched with highly professionals who are aware of the relevant technologies needed
                                to develop a secure and scalable cryptocurrency exchange software platform.</p>
                    </div>
                </Col>
            </Row>
        </div>
      );
    }
}

export default software_standard_section1;