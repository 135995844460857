import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import ExchangeSoftwareOffering from '../../Images/ExchangeSoftwareOffering.png';

class offering_standard_section4 extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm="12">
                    <div className="standardtitle">
                        <h2>Our Exchange Software Offering</h2>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="5">
                    <img className="img-fluid" src={ExchangeSoftwareOffering} alt="Standard Exchange Solution" title="Standard Exchange Solution" />
                </Col>
                <Col sm={{size:5, offset:2}}>
                    <div className="standardexchangesolution">
                        <h3>Standard Exchange Solution</h3>
                        <hr/>
                        <ul>
                            <li> Set on your own Server</li>
                            <li> Plug & Play</li>
                            <li>Third Party APIs Configuration</li>
                            <li>Base Currency Setup</li>
                            <li>Customised UI & UX</li>
                            <li>12 Months Technical Support</li>                                   
                        </ul>
                        <Link title="Ask For Quote" to="/contact-us">Ask For Quote</Link>
                    </div>
                </Col>     
            </Row> 
        </div>
      );
    }
}

export default offering_standard_section4;