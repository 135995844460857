import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";

import Mainbanner from './banner';
import Mainsection1 from './Privacy-Section1';

export default class index extends Component {

  componentDidMount() {
    ReactGA.initialize('UA-125025994-1')
    ReactGA.pageview(document.location.pathname)
    ReactGA.pageview(document.location.path)
  }
  
  scrollPositions = {}
  componentWillMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"
    }
    window.scrollTo(0, 0)
  }
  
  render() {
    return (
      <div>
        {this.props.children}
        <Helmet>
            <title>Privacy Policy - Espay Exchange</title>
            <meta name="description" content="This policy describes how Espay Exchange handles information and protects your privacy."/>
            <meta property="og:title" content="Privacy Policy - Espay Exchange" />
            <meta property="og:description" content="This policy describes how Espay Exchange handles information and protects your privacy." />
            <meta name="twitter:title" content="Privacy Policy - Espay Exchange" />
            <meta name="twitter:description" content="This policy describes how Espay Exchange handles information and protects your privacy." />
            <link rel="canonical" href="https://www.espay.exchange/privacy-policy" />
        </Helmet>

        <Container fluid={true} className="PrivacyPolicyBanner">
          <Container>
            <Mainbanner />
          </Container>
        </Container>
 
        <Container>
          <Mainsection1 /> 
        </Container>

        <ScrollUpButton className="ScrollUpButton"/>
      </div>
    )
  }
}

