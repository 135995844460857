import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';


export default class UtilityTokensSecurityTokens extends Component {
    render() {
        return (
            <div className="utilitysecuritytokens-bg">
                <Row>
                    <Col sm={12}>                    
                        <div className="heading-title">
                            <h2>Difference between Utility Tokens and Security Tokens</h2>
                        </div>
                    </Col>
                </Row> 
                <Row>
                    <div className="utility-Tokens-img wow fadeIn">
                        <div className="utility-title">
                            <span><h3>Utility Tokens</h3></span>
                            <span><h3>Security Tokens</h3></span>
                        </div>
                        <div className="utility-area ua-1">
                            <p>Utility Tokens Security Tokens Provide access to a service or utility. For example, Filecoin which provides users access to its decentralized cloud storage.</p>
                        </div>
                        <div className="utility-area ua-2">
                            <p>Not regulated, prone to scams</p>
                        </div>
                        <div className="utility-area ua-3">
                            <p>They are not investments, so there is no profit guarantee although their prices may appreciate</p>
                        </div>
                        <div className="utility-area ua-4">
                            <p>No powers or rights to investors</p>
                        </div>
                        <div className="utility-area ua-5">
                            <p>Provide ownership of the asset. For example, Aspen Coins allowed investors an indirect fragmented equity ownership stake in the property.</p>
                        </div>
                        <div className="utility-area ua-6">
                            <p>Regulated, considered a safe investment option</p>
                        </div>
                        <div className="utility-area ua-7">
                            <p>Being investments you can expect profits, especially in the long run</p>
                        </div>
                        <div className="utility-area ua-8">
                            <p>Investors can vote and decide how company works</p>
                        </div>
                    </div>
                </Row>
            </div>
        )
    }
}
