import React, { Component } from 'react';
import { Row, Col, Jumbotron} from 'reactstrap';
import { Link } from "react-router-dom";

class banner extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm={{size:8,offset:2}}>                       
                    <Jumbotron>
                        <div className="bannerdetails">
                            <h1>Hire comprehensive ICO Marketing services provider</h1>
                            <p className="bannersubline-one">Our expert marketing professionals will help you to achieve your target coin sale just like smooth sailing.</p>
                            
                        </div>
                    </Jumbotron>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default banner;