import React, { Component } from 'react';
import {Row,Col} from 'reactstrap';

import limitationimg1 from '../../Images/MarketingResearch/limitations-img-1.png';
import limitationimg2 from '../../Images/MarketingResearch/limitations-img-2.png';
import limitationimg3 from '../../Images/MarketingResearch/limitations-img-3.png';
import limitationimg4 from '../../Images/MarketingResearch/limitations-img-4.png';
import limitationimg5 from '../../Images/MarketingResearch/limitations-img-5.png';

export default class LimitationsSTEM extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col sm={12}>
                        <div className="LimitSTEM-title">
                            <span>Limitations in the current security token exchange market</span>
                        </div>
                    </Col>
                </Row>

                <Row className="limitations-box">
                    <Col sm={12} md={9} lg={10} className="order-2 order-md-1">
                        <span>Complex KYC and AML Laws</span>
                        <p>While stringent KYC and AML laws are necessary to avoid fraud, these laws also vary from nation to nation. Owing to their complexity, a small investor from one country often finds it hard to trade security tokens listed on an exchange in another country.</p>
                    </Col>
                    <Col sm={12} md={3} lg={2} className="order-md-2">
                        <img src={limitationimg1} alt="Complex KYC and AML Laws" title="Complex KYC and AML Laws" />
                    </Col>
                </Row>
                <Row className="limitations-box">
                    <Col sm={12} md={9} lg={10} className="order-2 order-md-1">
                        <span>Difficult in Obtaining the Required Licenses</span>
                        <p>Not many countries have specific laws for setting up legally compliant security token exchanges. While some countries such as Malta, Gibraltar, and Lithuania have set up the laws, getting the required license is tough. Currently, there are only a handful of licensed security token exchanges around the globe. However, the number will increase by the second or third quarter of 2019.</p>
                    </Col>
                    <Col sm={12} md={3} lg={2} className="order-md-2">
                        <img src={limitationimg2} alt="Difficult in Obtaining the Required Licenses" title="Difficult in Obtaining the Required Licenses" />
                    </Col>
                </Row>
                <Row className="limitations-box">
                    <Col sm={12} md={9} lg={10} className="order-2 order-md-1">
                        <span>Lack of Standardization</span>
                        <p>The traditional security exchanges have standardized application, issuance, and listing processes unlike the nascent STE market. Plus, most companies taking the STO route are small in size. Hence, they lack the legal and technical expertise required for listing their security tokens.</p>
                    </Col>
                    <Col sm={12} md={3} lg={2} className="order-md-2">
                        <img src={limitationimg3} alt="Lack of Standardization" title="Lack of Standardization" />
                    </Col>
                </Row>
                <Row className="limitations-box">
                    <Col sm={12} md={9} lg={10} className="order-2 order-md-1">
                        <span>Secondary Trading Restrictions</span>
                        <p>Most regulatory authorities are trying to fit security tokens into the existing security laws resulting in confusion among investors. For example, in the US, most companies follow Reg-D guidelines restricting themselves to only American accredited investors.</p>
                    </Col>
                    <Col sm={12} md={3} lg={2} className="order-md-2">
                        <img src={limitationimg4} alt="Secondary Trading Restrictions" title="Secondary Trading Restrictions" />
                    </Col>
                </Row>
                <Row className="limitations-box">
                    <Col sm={12} md={9} lg={10} className="order-2 order-md-1">
                        <span>Low Trading Volume</span>
                        <p>The secondary trading restrictions often lead to a low trading volume. In other words, most security tokens listed on the exchanges rarely see any action. However, crypto-native investors and small individual and institutional investors are stepping in to fill this gap in the security token exchange market. Their increasing participation is going to generate meaningful liquidity in the not so distant future.</p>
                    </Col>
                    <Col sm={12} md={3} lg={2} className="order-md-2">
                        <img src={limitationimg5} alt="Low Trading Volume" title="Low Trading Volume" />
                    </Col>
                </Row>
            </div>
        )
    }
}
