import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";
import Banner from './banner';
import Faq from './faq';

export default class index extends Component {

  componentDidMount() {
    ReactGA.initialize('UA-125025994-1')
    ReactGA.pageview(document.location.pathname)
    ReactGA.pageview(document.location.path)
  }
  
  scrollPositions = {}
  componentWillMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"
    }
    window.scrollTo(0, 0)
  }
  
  render() {
    return (

      <div>
        {this.props.children}
        <Helmet>
            <title>FAQ - Espay Exchange Development Company</title>
            <meta name="description" content="Your 101 for all things on cryptocurrency exchange. Get all information on centralized, decentralized, P2P, security tokens, and hybrid digital currency exchange."/>
            <meta property="og:title" content="FAQ - Espay Exchange Development Company" />
            <meta property="og:description" content="Your 101 for all things on cryptocurrency exchange. Get all information on centralized, decentralized, P2P, security tokens, and hybrid digital currency exchange." />
            <meta name="twitter:title" content="FAQ - Espay Exchange Development Company" />
            <meta name="twitter:description" content="Your 101 for all things on cryptocurrency exchange. Get all information on centralized, decentralized, P2P, security tokens, and hybrid digital currency exchange." />
            <link rel="canonical" href="https://www.espay.exchange/faq" />
        </Helmet>

        <Container fluid={true} className="faqbanner">
          <Container>
            <Banner />
          </Container>
        </Container>

        <Container>
          <Faq /> 
        </Container>   


        <ScrollUpButton className="ScrollUpButton"/>
      </div>
    )
  }
}