import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";

import Legally from "../../Images/STO/STO-legally.png";
import Support from "../../Images/STO/STO-Support.png";
import Development from "../../Images/STO/STO-Development.png";
import Marketing from "../../Images/STO/STO-marketing.png";

export default class StoSection2 extends Component {
  render() {
    return (
        <div> 
        <Row className="mt-30 mb-30">   
            <Col sm="12">
                <div className="stoadviceitle mt-30">
                    <h2>Our Services</h2>
                </div>
            </Col>

            <Col sm="6">
                <div className="Servicesbox">
                    <img src={Legally} alt="STO Legally" title="STO Legally" />
                    <h3>STO Legally</h3>
                    <p>Thorough legal advice is even more important for security token offering projects than classic ICOs.
                         We advise you in detail on the respective legal requirements of your STO and the requirements that your STO must meet. 
                         Furthermore, our STO agency has a broad partner network of law firms in the most suitable jurisdictions
                         for security token offerings, which can support you professionally from A to Z.</p>
                </div>
            </Col>
            <Col sm="6">
                <div className="Servicesbox">
                    <img src={Support} alt="STO Support" title="STO Support" />
                    <h3>STO Support</h3>
                    <p>As a full-service STO agency, crypto comparison deals with STO projects at every stage of development and from 
                        the beginning. Regardless of the area in which you need assistance, you can contact us: We have STO specialists and a 
                        wide network of STO law firms, financial experts, STO marketing experts,
                         as well as IPO experts and venture capital professionals.</p>
                </div>
            </Col>
            <Col sm="6">
                <div className="Servicesbox">
                    <img src={Development} alt="STO Development" title="STO Development" />
                    <h3>STO Development</h3>
                    <p>The technical STO programming covers many individual areas, from the creation of a legally compatible token, to the Wallet programming, the STO Dashboard programming incl. KYC and AML as well as many other Blockchain services. We can also advise you on the creation of STOs such as Equity Tokens, Reserve Assets Tokens and Debt Tokens, and fully support them as needed.</p>
                </div>
            </Col>
            <Col sm="6">
                <div className="Servicesbox">
                    <img src={Marketing} alt="STO Marketing" title="STO Marketing" />
                    <h3>STO Marketing</h3>
                    <p>STO Marketing Consulting is an important part of our STO consulting services. We take into account the peculiarities and the legal challenges facing STO projects in the marketing area and can develop and implement a marketing strategy developed individually for your STO.</p>
                </div>
            </Col>
        </Row>
    </div>
    )
  }
}
