import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";

class about_us_section1 extends Component {
    render() {
      return (
        <div className="mt-30 mb-30"> 
            <Row>   
                <Col sm="12">
                    <div className="Aboutustitle">
                        <h1>About Us</h1>
                        <p>Espay is a leading<strong> <Link title="Crypto Exchange Software Development" to="/centralized-enterprise-exchange-software">
                            Crypto Exchange Software Development</Link> </strong>
                             company based in Australia. We are fintech 
                            expert established in 2011 to deliver next-generation trading platforms to the businesses who want to 
                            take a deep dive into the crypto exchange field. We have a team of more than 100 highly experienced 
                            and skilled developers who are always ready to serve you at your conditions.</p>
                            <p>Profound knowledge of industry & entrepreneurial concepts for startups is at the basis of our business. The reliability, as well 
                                as the confidence our customers keep in us is a great deal of value 
                                that is what we assure to get every day.</p>
                    </div>
                </Col>
            </Row>
        </div>
      );
    }
}

export default about_us_section1;