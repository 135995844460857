import React, { Component } from 'react';
import {Row, Col} from 'reactstrap';
import RoundFinancialInstitutional from '../../Images/centralized/RoundFinancialInstitutional.png';
import RoundCorporateInvestors from '../../Images/centralized/RoundCorporateInvestors.png';
import Roundcoinowners from '../../Images/centralized/Roundcoinowners.png';
import RoundSolutions from '../../Images/centralized/RoundSolutions.png';
import Roundcrypto from '../../Images/centralized/Roundcrypto.png';
import RoundForexInvestor from '../../Images/centralized/RoundForexInvestor.png';

class solution_for_crypto_section4 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12">
                    <div className="IdealCentralizedTitle">
                        <h2>Ideal Centralized Exchange Solution For</h2>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm="2" className="IdealService">
                    <img src={RoundFinancialInstitutional} alt="Financial Institutional" title="Financial Institutional" />
                    <h3>Financial Institutional</h3>
                </Col>
                <Col sm="2" className="IdealService">
                    <img src={RoundCorporateInvestors}  alt="Corporate Investors" title="Corporate Investors" />
                    <h3>Corporate Investors</h3>
                </Col>
                <Col sm="2" className="IdealService">
                    <img src={Roundcoinowners} alt="New Coin Owners" title="New Coin Owners" />
                    <h3>New Coin Owners</h3>
                </Col>
                <Col sm="2" className="IdealService">
                    <img src={RoundSolutions} alt="Solutions for Startups" title="Solutions for Startups" />
                    <h3>Solutions for Startups</h3>
                </Col>
                <Col sm="2" className="IdealService">
                    <img src={Roundcrypto} alt="Niche Crypto exchangers" title="Niche Crypto exchangers" />
                    <h3>Niche Crypto exchangers</h3>
                </Col>
                <Col sm="2" className="IdealService">
                    <img src={RoundForexInvestor} alt="Forex Investor" title="Forex Investor" />
                    <h3>Forex Investor</h3>
                </Col>
            </Row>
        </div>
      );
    }
}

export default solution_for_crypto_section4;