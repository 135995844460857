import React, { Component, Fragment } from 'react';
import {Container,Row,Col} from 'reactstrap';
//import { Link } from "react-router-dom";

export default class WhitepaperBanner extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid={true} className="whitepaperbanner-bg">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className="bannerdetails">
                                    <h1>Espay-X Whitelabel Cryptocurrency Exchange  Software Whitepaper</h1>
                                    <p>The only cryptocurrency exchange software you will ever need</p>
                                    {/* <Link to="/talk-to-crypto-exchange-expert">Talk To Our Experts</Link> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Fragment>
        )
    }
}
