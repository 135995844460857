import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import HybridExchangeSoftware from '../../Images/hybrid/HybridExchangeSoftware.png';

class hybrid_exchange_software_section1 extends Component {
    render() {
      return (
        <div> 
            <Row> 
                <Col sm={6}>
                    <img className="img-fluid" src={HybridExchangeSoftware} alt="Why Develop Hybrid Exchange Software" 
                    title="Why Develop Hybrid Exchange Software" />
                </Col>  
                <Col sm={6}>
                    <div className="hybridtitle">
                        <h2>Why Develop Hybrid Exchange Software ?</h2>
                        <p>To solve the difficulties of <strong> CEX & DEX trading platforms, hybrid digital asset exchanges</strong> 
                             (HEX = Hybrid Exchange) is being established, which will be on the basis of functionalities and liquidity 
                            of centralized platforms, as well as the confidentiality and safety of decentralized platforms.</p>
                        <p><strong><Link to="/" title="Espay Exchange">Espay&nbsp;Exchange</Link> </strong>
                            is your home base if you want to setup a hybrid exchange software for your business. It will
                            offer your customers the most comfortable place to buy/sell and trade digital coins. Espay employs a huge
                            network of client support specialists who will respond your call any time, no matter what’s the
                            situation. We provide profound order books in different cryptocurrency market. It also offers smart
                            order routing to choose third-party exchanges which give high flexibility along with competitive
                            prices.</p>
                    </div>
                </Col>
            </Row>          
        </div>
      );
    }
}

export default hybrid_exchange_software_section1;