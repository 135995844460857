import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
//import { Link } from "react-router-dom";
import PerpetualContracts from "../../Images/Leverage/perpetualcontracts.png";
import RiskManagement from "../../Images/Leverage/riskmanagement.png";
import PartialCloseTrades from "../../Images/Leverage/Partialclosetrades.png";
import TradingBonus from "../../Images/Leverage/Tradingbonus.png";
import AffiliateMarketing from "../../Images/Leverage/Affiliatemarketing.png";
import FavoritePair from "../../Images/Leverage/Favouritecurrencypair.png";




export default class STOPlatform extends Component {
    render() {
        return (
            <div>
                <Row className="mt-30 mb-30">
                    <Col sm="12">
                        <div className="stoadviceitle mt-30">
                            <h2 className="text-center">Deliver a world-class experience with cutting-edge features</h2>
                            <p className="text-center">We build intuitive Bitcoin and crypto margin trading exchanges loaded with premium features that give you a competitive advantage.</p>
                        </div>
                    </Col>
                </Row>


                <Row className="Cutting-edge-section">
                    <Col sm="4">
                        <div className="row cuttingbox">
                            <div className="col-sm-3"><img src={PerpetualContracts} alt="PerpetualContracts" title="PerpetualContracts" className="img-fluid " /></div>
                            <div className="col-sm-9 overview-cont pr-0">
                                <h4>Perpetual Contracts</h4>
                                <p>Automatically liquidate traders’ positions once they hit the predefined bankruptcy limit.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm="4">
                        <div className="row cuttingbox">
                            <div className="col-sm-3"><img src={RiskManagement} alt="Risk Management" title="Risk Management" className="img-fluid " /></div>
                            <div className="col-sm-9 overview-cont pr-0">
                                <h4>Risk Management</h4>
                                <p>Allow traders to limit losses by using options like take-profit (TP) and Stop-loss (SL).</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm="4">
                        <div className="row cuttingbox">
                            <div className="col-sm-3"><img src={PartialCloseTrades} alt="Partial_Close_Trades" title="Partial_Close_Trades" className="img-fluid " /></div>
                            <div className="col-sm-9 overview-cont pr-0">
                                <h4>Partial Close Trades</h4>
                                <p>Permit traders to close orders partially to pocket profits and continue riding a bull run.</p>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className="Cutting-edge-section">
                    <Col sm="4">
                        <div className="row cuttingbox">
                            <div className="col-sm-3"><img src={TradingBonus} alt="Trading_Bonus" title="Trading_Bonus" className="img-fluid " /></div>
                            <div className="col-sm-9 overview-cont pr-0">
                                <h4>Trading Bonus</h4>
                                <p>Offer bonuses and rewards to new users joining the platform, thereby stimulating trade.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm="4">
                        <div className="row cuttingbox">
                            <div className="col-sm-3"><img src={AffiliateMarketing} alt="Affiliate_Marketing" title="Affiliate_Marketing" className="img-fluid " /></div>
                            <div className="col-sm-9 overview-cont pr-0">
                                <h4>Affiliate Marketing</h4>
                                <p>Encourage external operators to promote your brand by offering commissions.</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm="4">
                        <div className="row cuttingbox">
                            <div className="col-sm-3"><img src={FavoritePair} alt="Favorite_Pair_Currency" title="Favorite_Pair_Currency" className="img-fluid " /></div>
                            <div className="col-sm-9 overview-cont pr-0">
                                <h4>Favorite Pair / Currency</h4>
                                <p>Based on the latest data, your customers will find the best forex pair to trade having high liquidity. Espay helps you to offer best customer experience with its exchange development solutions.</p>
                            </div>
                        </div>
                    </Col>
                </Row>



            </div>
        )
    }
}
