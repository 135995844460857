import React, { Component } from 'react';
class map extends Component {
  render() {
    return (
      <div>
        <iframe width="100%" height="400" id="gmap_canvas" title="Map" 
        src="https://maps.google.com/maps?q=22%20Lambert%20Cres%2C%20Baulkham%20Hills%2C%20Sydney%2C%20NSW%2C%20Australia%202153&t=&z=13&ie=UTF8&iwloc=&output=embed">
        </iframe>
      </div>
    );
  }
}

export default map;
