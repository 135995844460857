import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import p2p  from '../../../../Images/p2pExchangeSoftware.png';
import hybrid  from '../../../../Images/HybridCryptoExchange.png';
import security  from '../../../../Images/Interestedsec.png';

class trading_section3 extends Component {
    render() {
      return (
        <div> 
            <Row>   
                <Col sm="12"> 
                    <div className="mobiletradingtitle">
                        <h2>You May Also Be Interested In</h2>
                    </div>                      
                </Col>
            </Row>  
            <Row>
                <Col sm="4">                         
                    <div className="mobiletrading-box">
                        <Link to="/p2p-exchange-software-development" title="">
                            <img className="img-fluid" src={p2p} alt="P2P Exchange Software" title="P2P Exchange Software" />
                            <span  className="interested-header">P2P Exchange Software</span>
                            <p  className="interested-caption">With P2P Exchange Software trading platforms, developers at Espay strive to preserve the real openness that the Blockchain Technology conveys. </p>
                        </Link>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="mobiletrading-box">
                        <Link to="/hybrid-crypto-exchange-software" title="Hybrid Crypto Exchange">
                            <img className="img-fluid" src={hybrid} alt="Hybrid Crypto Exchange" title="Hybrid Crypto Exchange" />
                            <span  className="interested-header">Hybrid Crypto Exchange</span>
                            <p  className="interested-caption">Espay is delivering robust Hybrid crypto Exchange platforms for the users who need automatization, complex order structures, as well as speed. </p>
                        </Link>
                    </div>
                </Col>
                <Col sm="4">                         
                    <div className="mobiletrading-box">
                        <Link to="/security-token-exchange" title="Security Token Exchange">
                            <img className="img-fluid" src={security} alt="Security Token Exchange" title="Security Token Exchange" />
                            <span href="#" Link="" className="interested-header">Security Token Exchange</span>
                            <p href="#" Link="" className="interested-caption">Espay offers a fully-compliant-focused security token and digital coin trading platforms that satisfy your active traders’ every demand.</p>
                        </Link>
                    </div>
                </Col>
            </Row> 
        </div>
      );
    }
}

export default trading_section3;