import React, { Component } from 'react';
import { Row, Col} from 'reactstrap';
import { Link } from "react-router-dom";
import DecentralizedExchangeOffering from '../../Images/decentralized/DecentralizedExchangeOffering.png';

class offering_standard_section4 extends Component {
    render() {
      return (
        <div> 
            <Row>
                <Col sm={12}>
                    <div className="Decentralizedtitle">
                        <h2>Decentralized Exchange Offering</h2>
                        <p>Our solid Smart Contract Development services will ensure that your Blockchain adheres to the right automation. Take a look at the features below</p>
                    </div>
                </Col>
            </Row>          
            <Row>
                <Col sm={6} className="mt-50">
                    <img className="decentralized_offerning img-fluid" src={DecentralizedExchangeOffering} 
                    alt="Enterprise Exchange Solution"
                    title="Enterprise Exchange Solution"
                    />
                </Col>
                <Col sm={6}>
                    <div className="decentralizedexchange">
                        <h3>Enterprise Exchange Solution</h3>
                        <hr/>
                        <ul>
                            <li>Digital Contract Architecture
                                <p>To work as expected, the computer-based protocol needs a top-notch workflow.</p>
                            </li>
                            <li>Smart Contract Design and Development
                                <p>State-of-the-art digital contract, with customizable features adaptable to any industry.</p>
                            </li>
                            <li>Smart Contract Auditing
                                <p>Meticulous smart contract auditing for a reliable and zero breach computer-based contract.</p>
                            </li>
                            <li>Smart Contracts Optimization
                                <p>An optimized contract can help you waste less Ethereum gas.</p>
                            </li>
                            <li>Build Decentralized Applications
                                <p>With Decentralized Applications and Smart Contracts, every type of software can be more trustworthy and functional.</p>
                            </li>
                        </ul>
                        <Link title="Enterprise Exchange Solution" to="/contact-us">Ask For Quote</Link>
                    </div>
                </Col>     
            </Row> 
        </div>
      );
    }
}

export default offering_standard_section4;