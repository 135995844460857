import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ScrollUpButton from "react-scroll-up-button";
import { Container } from 'reactstrap';
import {Helmet} from "react-helmet";

import Mainbanner from './banner';
import Mainvision from './VM-Section1';
import Mainmision from './VM-Section2';

export default class index extends Component {
  
  componentDidMount() {
    ReactGA.initialize('UA-125025994-1')
    ReactGA.pageview(document.location.pathname)
    ReactGA.pageview(document.location.path)
  }
  

  scrollPositions = {}
  componentWillMount() {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"
    }
    window.scrollTo(0, 0)
  }
  
  render() {
    return (

      <div>
        {this.props.children}
        <Helmet>
            <title>Vision And Mission - Espay Exchange</title>
            <meta name="description" content="Our Team Vision and Mission to achieve all clients needs in Exchange Software &amp; Forex Exchange &amp; Security Token services 
            for white label and enterprise customers with cost effective way proactive solutions."/>
            <meta property="og:title" content="Vision And Mission - Espay Exchange" />
            <meta property="og:description" content="Our Team Vision and Mission to achieve all clients needs in Exchange Software &amp; Forex Exchange &amp; Security Token services 
            for white label and enterprise customers with cost effective way proactive solutions." />
            <meta name="twitter:title" content="Vision And Mission - Espay Exchange" />
            <meta name="twitter:description" content="Our Team Vision and Mission to achieve all clients needs in Exchange Software &amp; Forex Exchange &amp; Security Token services 
            for white label and enterprise customers with cost effective way proactive solutions." />
            <link rel="canonical" href="https://www.espay.exchange/vision-mission" />
        </Helmet>

        <Container fluid={true} className="VisionMissionBanner">
          <Container>
            <Mainbanner />
          </Container>
        </Container>

        <Container>
          <Mainmision /> 
        </Container>   

      <Container fluid={true} className="Mission-bg">
        <Container>
          <Mainvision />
        </Container>
        </Container>
        <ScrollUpButton className="ScrollUpButton"/>
      </div>
    )
  }
}

