import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import WhiteLabelExchangeDesh from '../../../../Images/uidesign/WhiteLabelExchangeDesh.png';

class Exchange_Section1 extends Component {
    render() {
      return (
        <div> 
            <Row> 
                <Col sm={6} className="mt-30">
                    <img className="img-fluid" src={WhiteLabelExchangeDesh} alt="WhiteLabelExchangeDesh" title="WhiteLabelExchangeDesh" />
                </Col>
                <Col sm={6}>
                    <div className="exchange_UI">
                        <p>It is very useful to implement freely-flowing UI design while developing crypto currency exchange platform.</p>
                        <p>It aids to get more customers on the board from each corner of the world.</p>
                        <p>Creation of User Interface based on Customer Preference and Template Selection</p>
                    </div>
                </Col>
            </Row>          
        </div>
      );
    }
}

export default Exchange_Section1;